<div class="inner_wrapper">
	<app-grid-body 
		[refreshData]="refreshDataFunc" [updateParameters]="updateParametersFunc" [edit]="editFunc" [priceRange]="priceRangeFunc"
		[component]="component" [tableConstructor]="tableConstructor" [items]="items" [orderby]="orderby" [order]="order">
	</app-grid-body>

	<app-grid-footer  	
		[refreshData]="refreshDataFunc" [updateParameters]="updateParametersFunc"
		[component]="component" [itemsLimit]="itemsLimit" [pagination]="pagination" [page]="page" [pages]="pages" [total_items]="total_items" [itemsLimit_display]="itemsLimit_display">
	</app-grid-footer>
</div> 