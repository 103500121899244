import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-calendar-settings',
  templateUrl: './widget-calendar-settings.component.html',
  styleUrls: ['./widget-calendar-settings.component.css']
})
export class WidgetCalendarSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
