<div class="inner_wrapper">
	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="left">
		<h1>
			{{'Email'|translate}}
		</h1>
		</div>

		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
		</div>
	</div>
 
    <div class="body">
        <div class="tabs tabsHeader">
            <div class="tab" [ngClass]="{'active':selectedTab == 'content'}" (click)="switchTab('content')">
                <span>{{'Email content'|translate}}</span>
            </div>            
            <div *ngIf="!edit" class="tab" [ngClass]="{'active':selectedTab == 'connections'}" (click)="switchTab('connections')">
                <span>{{'Link to'|translate}}</span>
            </div>
        </div>
    

    <!--
	<form [formGroup]="eTalkForm" novalidate>
        <div class="lt50p">
            <div class="from"><fa-icon [icon]="['fad', 'inbox-in']" style="margin-right: 12px;"></fa-icon>{{item['email_name']}} <span>{{item['email']}}</span></div>
            <div *ngIf="item['email_time']!=''&&item['email_time']!=null" class="when"><fa-icon [icon]="['fad', 'calendar']" style="margin-right: 12px;"></fa-icon>{{item['email_time']}}</div>
            <div class="subject"><fa-icon [icon]="['fad', 'envelope']" style="margin-right: 12px;"></fa-icon><b>{{item['subject']}}</b></div>
            {{item['hasAttachment']}}
            <ng-container *ngIf="item['hasAttachments']==1">         
                <div style="float:left; width:100%;">
                    <div class="attachment"><fa-icon [icon]="['fad', 'paperclip']"></fa-icon>{{'Attachments'|translate}}</div>
                    <div class="attachment_items" style="float:left; width: 100%;">
                        <ng-container *ngFor="let item of eTalkAttachments"> 
                            <div class="att_item" (click)="download(item.filename, item.original_filename)"><fa-icon [icon]="['fad', 'file']"></fa-icon>{{item.original_filename}}</div>
                        </ng-container>
                    </div>
                </div> 
            </ng-container>
        </div>

        <div class="lt50p" *ngIf="!assiginToNewActivity && !assiginToExistingActivity">
            <button class="confirm" (click)="assiginToNewActivity=true">{{'Assign to new Activity'|translate}}</button>
            <button class="confirm" (click)="assiginToExistingActivity=true">{{'Assign to exiting Activity'|translate}}</button>
        </div>

        <div class="lt50p" *ngIf="assiginToExistingActivity">
            <div class="line  assignto">				
                <div class="field">                    
                    <input formControlName="activity_id" [(ngModel)]="activity_id" type="hidden" />
                    <input formControlName="activity" name="activity" [(ngModel)]="activity" (click)="selectActivity()" readonly />
                    <div><fa-icon [icon]="['fad', 'list']" (click)="selectActivity()"></fa-icon></div>
                </div>
                <div class="label">
                    {{'Attach to activity'|translate}}
                </div>
            </div>	            
        </div>

        <div class="lt50p" *ngIf="assiginToNewActivity" style="padding-left:24px; border-left:1px solid var(--dark-lvl2); margin-bottom:24px;">
            <div class="key_customer_administrator">
                <ng-container>
                    <div class="line" style="padding-bottom:12px;">
                        <ng-container *ngTemplateOutlet="activity_assigned_template; context:{ $implicit: $any(customer['client_administrators']) }"></ng-container>
                    </div>

                    <div class="line assignto">				
                        <div class="field">
                            <input formControlName="customer_id" [(ngModel)]="customer_id" type="hidden" />
                            <input formControlName="customer_name" name="customer_name" [(ngModel)]="customer_name" (click)="selectCustomer()" readonly />
                            <div><fa-icon [icon]="['fad', 'users']" (click)="selectCustomer()"></fa-icon></div>
                        </div>
                        <div class="label">
                            {{'Attached to customer'|translate}}
                        </div>
                    </div>

                    <div class="line assignto lt60p">
                        <div class="field">							
                            <select class="custom-select customselect" (change)="choose_category($event)" id="default_category" formControlName="default_category" name="default_category" [ngModel]="default_category">
                                <option value="-">-</option>								
                                <option *ngFor="let category of categories" value="{{category.entity}}">{{category.description}}</option>
                            </select>
                            <div><fa-icon [icon]="['fad', 'list']"></fa-icon></div>	
                        </div>
                        <div class="label" style="padding-bottom:6px;">
                            {{'Assign to activity'|translate}}
                        </div>
                    </div>
                    
                    <div class="line assignto lt40p">
                        <div class="field">							
                            <select class="custom-select customselect" id="default_priority_id" formControlName="default_priority_id" name="default_priority_id" [(ngModel)]="default_priority_id">
                                <option value="0">{{'No priority'|translate}}</option>
                                <option value="1">{{'Low'|translate}}</option>
                                <option value="2">{{'Middle'|translate}}</option>
                                <option value="3">{{'High'|translate}}</option>
                            </select>		
                            <div><fa-icon [icon]="['fad', 'flag']"></fa-icon></div>			
                        </div>
                        <div class="label" style="padding-bottom:6px;">
                            {{'Assign priority'|translate}}
                        </div>
                    </div>		
                    
                </ng-container>
            </div>
        </div>


    -->
    <div id="content" *ngIf="selectedTab=='content'" class="tab-container">
        <div class="block">
            <ng-container *ngIf="edit">
                <div class="inline w100">
                    <mat-form-field appearance="outline">
                        <mat-label>{{"Recipient's Email"|translate}}</mat-label>
                        <input type="text" matInput [(ngModel)]="this.item['recipient_email']">
                    </mat-form-field>
                </div>

                <div class="inline w100">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Subject'|translate}}</mat-label>                
                        <input type="text" matInput [(ngModel)]="this.item['subject']">
                    </mat-form-field>
                </div>        

                <quill-editor 
                    [styles]="{'min-height': '240px'}" 
                    (onEditorChanged)="changedEditor($event)"
                    (onEditorCreated)="created($event)"
                    [modules]="modules_extended"
                    id="extendedEditor" [ngModelOptions]="{standalone: true}" [(ngModel)]="item['body']" placeholder="{{'Email content...'|translate}}">
                </quill-editor>
            </ng-container>

            <ng-container *ngIf="!edit">
                <div class="inline w100 paddings">
                    {{"Recipient's Email"|translate}} : <b>{{this.item['recipient_email']}}</b>                    
                </div>

                <div class="inline w100 paddings">
                    {{'Subject'|translate}} : <b>{{this.item['subject']}}</b>                    
                </div>    

                <hr />

                <div class="inline w100 paddings" style="padding-top: 24px" [innerHTML]="CommonFunctions.URLtoHYPERLINK(item['body'])"></div>
                
                <hr />

                <ng-container *ngFor="let item of attachment | keyvalue">
                    <div class="w100 attachment" (click)="downloadFile(item.value['filename'],item.value['content'])">
                        <fa-icon [icon]="['fad', 'file-arrow-down']" (click)="selectActivity()"></fa-icon>
                        <span>{{item.value['filename']}}</span>
                    </div>
                </ng-container>

            </ng-container>
        </div>
    </div> 
    
    <div id="connections" *ngIf="selectedTab=='connections'" class="tab-container">
        <div class="block" [ngStyle]="{'padding': item['activity_id']!=null ? '0px 12px' : '36px 0px 0px 0px'}">
            
            <!--
            <div class="lt50p">
                <div class="from"><fa-icon [icon]="['fad', 'inbox-in']" style="margin-right: 12px;"></fa-icon>{{item['email_name']}} <span>{{item['email']}}</span></div>
                <div *ngIf="item['email_time']!=''&&item['email_time']!=null" class="when"><fa-icon [icon]="['fad', 'calendar']" style="margin-right: 12px;"></fa-icon>{{item['email_time']}}</div>
                <div class="subject"><fa-icon [icon]="['fad', 'envelope']" style="margin-right: 12px;"></fa-icon><b>{{item['subject']}}</b></div>
                {{item['hasAttachment']}}
                <ng-container *ngIf="item['hasAttachments']==1">         
                    <div style="float:left; width:100%;">
                        <div class="attachment"><fa-icon [icon]="['fad', 'paperclip']"></fa-icon>{{'Attachments'|translate}}</div>
                        <div class="attachment_items" style="float:left; width: 100%;">
                            <ng-container *ngFor="let item of eTalkAttachments"> 
                                <div class="att_item" (click)="download(item.filename, item.original_filename)"><fa-icon [icon]="['fad', 'file']"></fa-icon>{{item.original_filename}}</div>
                            </ng-container>
                        </div>
                    </div> 
                </ng-container>
            </div>
            -->
                
            <!--<div class="lt50p" *ngIf="(item['activity_id']==null || item['activity_id'] == '') && (assiginToNewActivity != true|| assiginToExistingActivity != true)">-->
            <div class="lt50p" *ngIf="(item['activity_id']==null || item['activity_id'] == '') && (assiginToNewActivity != true && assiginToExistingActivity != true)">
                <button class="confirm" style="margin-top:0px;margin-right:12px;" (click)="assiginToNewActivity=true">{{'Link to new Activity'|translate}}</button>
                <button class="confirm" style="margin-top:0px;" (click)="assiginToExistingActivity=true">{{'Link to exiting Activity'|translate}}</button>
            </div>
    
            
            <div class="lt50p">
                <ng-container *ngIf="assiginToExistingActivity">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Attach to activity'|translate}}</mat-label>                            
                        <input [(ngModel)]="activity_id" type="hidden" />
                        <input matInput [(ngModel)]="activity" (click)="selectActivity()" readonly />
                        <div><fa-icon style="position:absolute;right:0px;top:14px" [icon]="['fad', 'list']" (click)="selectActivity()"></fa-icon></div>
                    </mat-form-field>    
                </ng-container>        
                                
                <ng-container *ngIf="item['activity_id']!=null">
                    <div class="activity_outer">
                        <div class="activity_lt">
                            <fa-icon class="big" [icon]="['fad', 'list']"></fa-icon>
                        </div>
                        <div class="activity_rt">
                            <div class="activity_entity">
                                {{activityContent[entity]}}<br/>
                                <span style="background:{{activityContent['category_type_hex']}}; color:{{CommonFunctions.textColorFromBG(activityContent['category_type_hex'])}}">{{activityContent['category_type_description']}}</span>
                            </div>
                            <div class="activity_content">
                                <div class="activity_title">{{activityContent['title']}}</div>
                                <div class="activity_customer">{{activityContent['customer']}}</div>
                            </div>    
                        </div>
                    </div>                    
                </ng-container>
                <button *ngIf="item['activity_id']!=null" class="left cancel" style="margin-left: 39px; padding: 4px 12px;border-radius: 8px;" (click)="cancelConnection()">{{'Cancel Link'|translate}}</button>
            </div>
            
    
            <div class="lt50p" *ngIf="assiginToNewActivity" style="padding-left:12px; padding-right:12px; margin-bottom:24px;">
                <div class="key_customer_administrator">
                    <div class="title">{{'Create new Activity'|translate}}</div>
                    <ng-container>
                        <div class="line" style="padding-bottom:12px;">
                            <ng-container *ngTemplateOutlet="activity_assigned_template; context:{ $implicit: $any(customer['client_administrators']) }"></ng-container>
                        </div>    
                        
                        <mat-form-field appearance="outline" style="padding-top: 18px;">
                            <mat-label>{{'Attached to customer'|translate}}</mat-label>                            
                            <input [(ngModel)]="customer_id" type="hidden" />
                            <input matInput [(ngModel)]="customer_name" (click)="selectCustomer()" readonly />
                        </mat-form-field>                        
                        
                        <mat-form-field appearance="outline" style="padding-top: 8px;">
                            <mat-label>{{'Assign to activity'|translate}}</mat-label>
                            <mat-select [(ngModel)]="default_category">
                              <mat-option value="-">-</mat-option>
                              <mat-option *ngFor="let category of categories" value="{{category.entity}}">{{category.description}}</mat-option>
                            </mat-select>                          
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="padding-top: 8px;">
                            <mat-label>{{'Assign priority'|translate}}</mat-label>
                            <mat-select [(ngModel)]="default_priority_id">
                                <mat-option value="0">{{'No priority'|translate}}</mat-option>
                                <mat-option value="1">{{'Low'|translate}}</mat-option>
                                <mat-option value="2">{{'Middle'|translate}}</mat-option>
                                <mat-option value="3">{{'High'|translate}}</mat-option>
                            </mat-select>                          
                        </mat-form-field>                       
                        
                        <button class="confirm">{{'Quick create and link'|translate}}</button>

                    </ng-container>
                </div>
            </div>


        </div>
    </div>

    </div>

    <div class="modalActions">
        <button class="left" mat-button mat-dialog-close>{{'Close'|translate}}</button>

        <button *ngIf="item['status']!='sended' && !edit" style="margin-left: 36px;" class="right accept" mat-button mat-dialog-close>{{'Send Now'|translate}}</button>

        
        <button *ngIf="!edit" class="right accept" (click)="deleteEmailQueue()">{{'Delete'|translate}}</button>        

        <button *ngIf="!edit" class="right delete" (click)="edit=true; selectedTab='content'">{{'Edit'|translate}}</button>        
        <button *ngIf="edit" class="right accept" (click)="saveEmail()" mat-button mat-dialog-close>{{'Save'|translate}}</button>
        <button *ngIf="edit" class="right cancel" style="margin-right: 24px;" (click)="getEmail();edit=false">{{'Cancel'|translate}}</button>
    </div>
</div>

<!------------------------------------->
<ng-template #activity_assigned_template let-client_administrators>
	<div class="cell_inner">
		<ng-container *ngFor='let assigned_users_name of ShowUserInicials(client_administrators?.assigned_users_names)'>
			<span  class="user_bubble">
				{{assigned_users_name.initials}}
				<div class="tooltip">{{assigned_users_name.fullname}}</div>
			</span>
		</ng-container>
		<ng-container *ngFor='let assigned_group_name of ShowUserInicials(client_administrators?.assigned_group_names)'>
			<span  class="group_bubble">
				{{assigned_group_name.initials}}
				<div class="tooltip">{{assigned_group_name.fullname}}</div>
			</span>
		</ng-container>
		
		<span class="add_bubble" (click)="assignUserGroup()">
			<fa-icon [icon]="['fad', 'users']"></fa-icon>
			<div class="bubble_add_on">+</div>
		</span>
	</div>
</ng-template>