<div class="inner_wrapper">

	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="left">
			<h1>
			    {{'Contact type'|translate}}
		    </h1>
		</div>

		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
		</div>
		
	</div>

	<form [formGroup]="formData" (ngSubmit)="save()" novalidate>

		<div class="body">
			<div class="inline w100 wPadding">               
				<mat-form-field appearance="outline">
				  <mat-label>{{'Description'|translate}}</mat-label>
				  <input type="text" matInput formControlName="description" name="description" [(ngModel)]="rows['description']">
				  <!--<mat-hint>Error</mat-hint>-->
				</mat-form-field>
			</div>
		</div>

		<div class="modalActions" mat-dialog-actions>
			<button class="accept right" type="submit" mat-button mat-dialog-close>{{'Save'|translate}}</button>
			<button class="action left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
			<button *ngIf="rows['entity']!=0 && rows['entity']!=undefined && rows['entity']!=null" class="warrning right" (click)="delete()" mat-button mat-dialog-close>{{'Delete'|translate}}</button>
		</div>

	</form>

</div>