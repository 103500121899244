<div class="inner_wrapper">
	<div class="header">
	  <div class="left">
		<h1>{{'Select users/groups'|translate}}</h1>
	  </div>
	  <div class="right">
		<fa-icon class="icon_close" [icon]="['fad', 'times']" (click)="assignMemberGroup_cancel()"></fa-icon>
	  </div>
	</div>
	<div class="body">
			<div class="search">
				<input id="member_filter" placeholder="{{'Filter users and groups...'|translate}}" />
			</div>

			<div class="assign_users_groups_body">
				<div class="notselected">
					<div class="member_title title">{{'Members'|translate}}</div>
					<ng-container *ngFor="let member of data2.members">
						<ng-container *ngTemplateOutlet="member_template; context:{ $implicit: member }"></ng-container>
					</ng-container>
					
					<div class="group_title title">{{'Groups'|translate}}</div>
					<ng-container *ngFor="let group of data2.groups">
						<ng-container *ngTemplateOutlet="group_template; context:{ $implicit: group }"></ng-container>
					</ng-container>
				</div>	
			</div>
		
			<ng-template #member_template let-member>
				<div class="member" [ngClass]="{selected: data2.membersSelected.indexOf(member.entity) > -1}" (click)="toggleMember(member.entity)">
					<span class="user_bubble" [ngStyle]="{'background-color':member.hex}">						
						{{ShowUserInicials(member.username)[0].initials}}
					</span>		
					<span *ngIf="checkForSelectedGroup(member) !== -1" class="group_bubble_small" style="position: absolute; border-color:{{data2.groups[groupIndex].hex}}; color:{{data2.groups[groupIndex].hex}}">
						{{ShowUserInicials(data2.groups[groupIndex].member_group)[0].initials}}			
					</span>
					<span class="user_fullname">{{member.username}}</span>
				</div>
			</ng-template>
		
			<ng-template #group_template let-group>
				<div class="group" [ngClass]="{selected: data2.groupsSelected.indexOf(group.id) > -1}">
					<span class="group_bubble" [ngStyle]="{'border-color':group.hex, 'color':group.hex}" (click)="toggleGroup(group.id)">
						{{ShowUserInicials(group.member_group)[0].initials}}
					</span>
					<span class="group_fullname" (click)="toggleGroup(group.id)">{{group.member_group}}</span>		
					<span *ngIf="data2.groups.indexOf(group) ==  groupIndex" (click)="showUsers(group.entity, data2.groups.indexOf(group))" class="showUsers_helper" [ngClass]="{'group_selected':group.entity==groupEntitySelected}"><fa-icon [icon]="['fad', 'eye']"></fa-icon></span>
					<span (click)="showUsers(group.entity, data2.groups.indexOf(group))" class="showUsers" [ngClass]="{'group_selected':group.entity==groupEntitySelected}"><fa-icon [icon]="['fad', 'users']"></fa-icon></span>
				</div>
			</ng-template>
	</div>
	<div class="modalActions">
		<button class="accept right" (click)="assignMemberGroup()" style="float:right;margin-right:0;">{{'Confirm'|translate}}</button>
		<button class="action left" (click)="assignMemberGroup_cancel()">{{'Cancel'|translate}}</button>
	</div>
  </div>