import { Component, OnInit, Input } from '@angular/core';
import { DashboardComponent } from '../../dashboard/dashboard.component'
import { ActivityComponent } from '../../activities/activity/activity.component'
import { GlobalVariables } from '../../services/data.globals';
import { DataService } from '../../services/data.service';
import { CommonFunctions } from '../../services/common.functions'; 
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { EChartsOption } from 'echarts';

import type { EChartsOption } from 'echarts';
import { ThemeOption } from 'ngx-echarts';
import { NgxEchartsModule, NGX_ECHARTS_CONFIG } from 'ngx-echarts';
import { TranslateService } from '@ngx-translate/core';  //translate:START:END

import { WidgetActivitiesChartsSettingsComponent } from './widget-activities-charts-settings/widget-activities-charts-settings.component';

@Component({
  selector: 'app-widget-activities-charts',
  templateUrl: './widget-activities-charts.component.html',
  styleUrls: ['./widget-activities-charts.component.css'],
  providers: [
    {
      provide: NGX_ECHARTS_CONFIG,
      useFactory: () => ({ echarts: () => import('echarts') })
    },
  ]
})
export class WidgetActivitiesChartsComponent implements OnInit {


  public rows = [];
  public refresh_percent;
  public parameters;
  public parameters_json;
  public mouseover = false;
  public chartOn = false;


  theme: string | ThemeOption;

  public CoolTheme = { color: [] }; 
  
  options: EChartsOption;

  constructor(
  	private data_svc: DataService,
    public cFunction: CommonFunctions, 
    public WidgetActivitiesChartsSettingsDialog: MatDialog,
    public dialog: MatDialog,
    public Dashboard: DashboardComponent,
    public translate: TranslateService
    ) {
      translate.addLangs(['en', 'si']); //translate:START:END    
    } 

  @Input() id: string;  

  @Input() set refresh(refresh: string) {    
    if (parseInt(refresh) == 1000000) {      
      if (!this.mouseover) this.prepareDataAndLoad();
    }        
    this.refresh_percent = refresh;    
  }

  settings(id, settings = '{}') {    
        
    var WidgetActivitiesChartsSettings = this.WidgetActivitiesChartsSettingsDialog.open( WidgetActivitiesChartsSettingsComponent, {
      'width' : '800px',
      data: JSON.parse(settings),
      id: id
    });    

    WidgetActivitiesChartsSettings.afterClosed().subscribe(result => {   
      if (result.data) {
        this.parameters = result.data;
        this.parameters_json = JSON.stringify(result.data);
        this.loadData(JSON.parse(this.parameters_json));

        /* save member settings */
        this.data_svc.setMemberSettings({'parameter': id, 'value': this.parameters_json}).subscribe((res: Response) => {});
      }
    });

  }

  prepareDataAndLoad() {
    //console.log(GlobalVariables);
      this.parameters = GlobalVariables.memberSettings[this.id] != undefined ? JSON.parse(GlobalVariables.memberSettings[this.id]) : {title:this.translate.instant("Widget title..."),category:null,reference:'created',grouppedby:'this_month',featured:0,assigned:0,author:0,hidden:0,approvedbyme:0,approved:0,unapproved:0, removeold:0,chartType:'pie_area'};
      
      /* special workarround because of combining displayed data */      
      var parameters_helper = this.parameters;      
      if (parameters_helper.combinecustomer == true) {
        parameters_helper['order'] = "t3.entity, " + parameters_helper['order'];        
      }

      this.parameters_json = JSON.stringify(this.parameters);      
      //console.log(parameters_helper);
      this.loadData(parameters_helper);
  }



  loadData(parameters) {
    //console.log(parameters);   
    this.chartOn = false;
    var currentYear;
    var date;
    var firstDay;      
    var firstDay_local;
    var lastDay;
    var lastDay_local;
    
    if (parameters.grouppedby == 'yesterday') {
      firstDay = new Date(Date.now() - 86400000);      
      lastDay = new Date(Date.now() - 86400000);
      firstDay.setHours(0, 0, 0, 0);
      lastDay.setHours(0, 0, 0, 0);
    } else if (parameters.grouppedby == 'today') {
      firstDay = new Date();
      lastDay = new Date();
      firstDay.setHours(0, 0, 0, 0);
      lastDay.setHours(0, 0, 0, 0);
    } else if (parameters.grouppedby == 'previous_week') {
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      firstDay = new Date(today.setDate(today.getDate() - today.getDay() - 6));
      lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
      firstDay.setHours(0, 0, 0, 0);
      lastDay.setHours(0, 0, 0, 0);
    } else if (parameters.grouppedby == 'this_week') {
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
      lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
      firstDay.setHours(0, 0, 0, 0);
      lastDay.setHours(0, 0, 0, 0);
    } else if (parameters.grouppedby == 'previous_month') {
      date = new Date();
      date.setHours(0, 0, 0, 0);
      firstDay = new Date(date.getFullYear(), date.getMonth()-1, 1);
      lastDay = new Date(date.getFullYear(), date.getMonth()+0, 0);
      firstDay.setHours(0, 0, 0, 0);
      lastDay.setHours(0, 0, 0, 0);
    } else if (parameters.grouppedby == 'this_month') {
      date = new Date();
      date.setHours(0, 0, 0, 0);
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      firstDay.setHours(0, 0, 0, 0);
      lastDay.setHours(0, 0, 0, 0);
    } else if  (parameters.grouppedby == 'previous_year') {
      currentYear = new Date().getFullYear() - 1;
      firstDay = new Date(currentYear, 0, 1);       
      lastDay = new Date(currentYear, 11, 31);      
      firstDay.setHours(0, 0, 0, 0);
      lastDay.setHours(0, 0, 0, 0);
    } else {
      currentYear = new Date().getFullYear();
      firstDay = new Date(currentYear, 0, 1);       
      lastDay = new Date(currentYear, 11, 31);      
      firstDay.setHours(0, 0, 0, 0);
      lastDay.setHours(0, 0, 0, 0);
    }

    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    firstDay_local = firstDay.toLocaleDateString('sl-SI', options);
    lastDay_local = lastDay.toLocaleDateString('sl-SI', options);
    
    var usedDates = "";
    if (parameters.reference == "start") {
      parameters['filter_start_from_date_raw'] = firstDay.toISOString().slice(0, 19).replace('T', ' ');
      parameters['filter_start_to_date_raw'] = lastDay.toISOString().slice(0, 19).replace('T', ' ');
      usedDates = this.translate.instant("Based of start of activities");
    } else if (parameters.reference == "end") {
      parameters['filter_end_from_date_raw'] = firstDay.toISOString().slice(0, 19).replace('T', ' ');
      parameters['filter_end_to_date_raw'] = lastDay.toISOString().slice(0, 19).replace('T', ' ');
      usedDates = this.translate.instant("Based of end of activities");
    } else {
      parameters['filter_created_from_date_raw'] = firstDay.toISOString().slice(0, 19).replace('T', ' ');
      parameters['filter_created_to_date_raw'] = lastDay.toISOString().slice(0, 19).replace('T', ' ');
      usedDates = this.translate.instant("Based of create of activities");
    }

    this.data_svc.getActivitiesCharts(parameters).subscribe(
    (res: Response) => {
      this.rows = res['rows'];  
      //console.log(this.rows);

      /*********************************************************/
      /*********************************************************/
      /* Area */

      if (parameters.chartType == 'area') { //#_2

        var date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        this.options = {
          tooltip: { trigger: 'axis', axisPointer: { type: 'cross', label: { backgroundColor: '#6a7985' } } },
          title: { left: '50%', text: '', subtext: '', textAlign: 'center' },
          legend: { align: 'auto', bottom: 0, data: [] },
          grid: { left: '3%', right: '4%', bottom: '14%', containLabel: true },
          xAxis: [ { type: 'category', boundaryGap: false, data: [] } ],
          yAxis: [{ type: 'value' }],
          series: []
        };

        if (parameters.grouppedby == 'previous_month' || parameters.grouppedby == 'this_month') {
          this.options.xAxis[0].data = [];
          for (var i=this.cFunction.getWeekNumber(firstDay); i<this.cFunction.getWeekNumber(lastDay); i++) {
            (this.options.xAxis[0].data).push(i+". week");
          }
          var startWeek = this.cFunction.getWeekNumber(firstDay);
          var endWeek = this.cFunction.getWeekNumber(lastDay);
          var data_count = endWeek - startWeek;
        } else if (parameters.grouppedby == 'previous_week' || parameters.grouppedby == 'this_week') {
          this.options.xAxis[0].data = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
          var data_count = 7;
        } else {
          this.options.xAxis[0].data = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];
          var data_count = 12;
        }

        this.options.legend['data'] = []; //clear legend
        this.options.series = []; //clear series
        this.CoolTheme.color = []; //clear color
        
        if (parameters.categories.length == 1) {      
          /******************************************************************/
          /* category status view */
          this.options.title['text'] = this.translate.instant("Display statuses in activity");
          this.options.title['subtext'] = firstDay_local + " - " + lastDay_local + " (" + usedDates + ")";         

          var selected_category = parameters.categories[0]['category_id'];

          this.data_svc.getActivityTypes({}).subscribe(
            (res: Response) => {
              statuses = (<any>res).rows;	                    
              for (var j=0; j<statuses.length; j++) {                
                if (statuses[j].entity == selected_category) {
                  var status_text = (statuses[j].status_text).split(",");                  
                  this.options.title['text'] = this.options.title['text'] + " (" + statuses[j].description + ")";
                  for (var k=0; k<status_text.length; k++) {
                    var each_status = status_text[k].split('=');
                    var helper = each_status[1].split(';');

                    this.options.legend['data'].push(helper[0]);                
                    var color = helper[1] != undefined ? helper[1] : "#000000";
                    this.CoolTheme['color'].push(color);

                    if (this.options.series[k] == undefined) {
                      this.options.series[k] = {};                
                    }
                      
                    if (this.options.series[k]['name'] == undefined) { 
                      this.options.series[k]['name'] = helper[0];
                      this.options.series[k]['status_id'] = each_status[0];
                      this.options.series[k]['type'] = "line";
                      this.options.series[k]['stack'] = "count";
                      this.options.series[k]['areaStyle'] = {};                      
                      this.options.series[k]['data'] = [];
                      for (var x=0; x<12; x++) {
                        (this.options.series[k]['data']).push(0);
                      }
                    }                      
                  }        
                }
              }   
              
              for (var i=0; i<this.rows.length; i++) {                
                var status_id = this.rows[i].status_id == null ? 'Undefined' : this.rows[i].status_id;                
                var js_date = new Date((this.rows[i].created).replace( /[-]/g, '/' ));

                var series_length = status_text.length;
                var start = 0;
                if (parameters.grouppedby == 'previous_month' || parameters.grouppedby == 'this_month') {   
                  data_count = endWeek - startWeek; //this.cFunction.getWeekNumber(js_date);
                  start = this.cFunction.getWeekNumber(js_date) - this.cFunction.getWeekNumber(firstDay);
                } else if (parameters.grouppedby == 'previous_week' || parameters.grouppedby == 'this_week') {
                  start = this.cFunction.getDayNumber(js_date);
                  data_count = 7;
                } else {
                  data_count = 12;
                  series_length = status_text.length;
                  start = js_date.getMonth();
                }                
                //console.log(series_length);
                //console.log('NEW DATA ROW ------------------------------------');
                for (var j=0; j<series_length; j++) {       
                  //console.log(this.rows[i].entity);
                  //console.log(data_count + " | " + start + " | " + j + " | " + this.options.series[j]['status_id'] + " == " + status_id);
                  if (this.options.series[j]['status_id']==status_id) {
                    for (var k=start; k<data_count; k++) {
                      this.options.series[j]['data'][k]++;                    
                    }
                  }
                }                
              }      

              /* Final */
              //console.log(this.options.series);
              this.chartOn = true;
            }
          );

        } else {

          /* categories view */
          this.options.title['text'] = "Display categories";
          this.options.title['subtext'] = firstDay_local + " - " + lastDay_local + " (" + usedDates + ")";         

          this.data_svc.getActivityTypes({}).subscribe(
            (res: Response) => {
              statuses = (<any>res).rows;	                    
              for (var j=0; j<statuses.length; j++) {                
                  this.options.legend['data'].push(statuses[j]['description']);
                  var color = statuses[j]['hex'] != undefined ? statuses[j]['hex'] : "#000000";
                  this.CoolTheme['color'].push(color);

                  if (this.options.series[j] == undefined) {
                    this.options.series[j] = {};                
                  }
                    
                  if (this.options.series[j]['name'] == undefined) { 
                    this.options.series[j]['name'] = statuses[j]['description'];
                    this.options.series[j]['status_id'] = statuses[j]['entity'];
                    this.options.series[j]['type'] = "line";
                    this.options.series[j]['stack'] = "count";
                    this.options.series[j]['areaStyle'] = {};                      
                    this.options.series[j]['data'] = [0,0,0,0,0,0,0,0,0,0,0,0];
                  }
              }

              for (var i=0; i<this.rows.length; i++) {                
                var category_id = this.rows[i].message_type == null ? 'Undefined' : this.rows[i].message_type;
                var js_date = new Date((this.rows[i].created).replace( /[-]/g, '/' ));
                var month = js_date.getMonth();                
                
                for (var j=0; j<statuses.length; j++) {       
                  if (this.options.series[j]['status_id']==statuses[j]['entity']) {
                    for (var k=month; k<12; k++) {
                      this.options.series[j]['data'][k]++;                    
                    }
                  }
                }                
              }

              /* Final */
              //console.log(this.options);
              this.chartOn = true;
            }
          );
        }
      } //#_2

      /*********************************************************/
      /*********************************************************/
      /* PIE */

      if (parameters.chartType == 'pie_area' || parameters.chartType == 'pie_simple') { //#_1

        this.options = {
          title: { left: '50%', text: '', subtext: '', textAlign: 'center' },
          tooltip: { trigger: 'item', formatter: '{a} <br/>{b} : {c} ({d}%)' },
          legend: { align: 'auto', bottom: 0, data: [] },
          calculable: true,
          series: [{ name: 'Percent', type: 'pie', radius: [20, 110], roseType: 'area', data: [] }]
        };

        this.options.series[0]['Type'] = 'pie';
        if (parameters.chartType == 'pie_area') {
          this.options.series[0]['roseType'] = 'area';
        } else {
          this.options.series[0]['roseType'] = '';
        }
              
        var date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        if (parameters.categories.length == 1) {      
          
          this.options.title['text'] = this.translate.instant("Display statuses in activity");
          this.options.title['subtext'] = firstDay_local + " - " + lastDay_local + " (" + usedDates + ")";
          var results = {};
          this.options.series[0].data = []; //clear values
          this.options.legend['data'] = []; //clear legend
          var selected_category = parameters.categories[0]['category_id'];
          for (var i=0; i<this.rows.length; i++) {        
            var status_id = this.rows[i].status_id == null ? 'Undefined' : this.rows[i].status_id;
            if (results[this.rows[i].status_id] == undefined) {          
              results[status_id] = [];
              results[status_id]['value'] = 0;            
            }        
            results[status_id]['value']++;          
          }      
          var statuses;
          var status_legend = {};
          this.data_svc.getActivityTypes({}).subscribe(
            (res: Response) => {
              statuses = (<any>res).rows;	
              //console.log(statuses);
              for (var j=0; j<statuses.length; j++) {              
                if (statuses[j].entity == selected_category) {
                  var status_text = (statuses[j].status_text).split(",");                  
                    for (var k=0; k<status_text.length; k++) {
                      var each_status = status_text[k].split('=');
                      if (each_status[1] != undefined) {
                      var helper = each_status[1].split(';');
                        status_legend[each_status[0]] = {};
                        status_legend[each_status[0]]['hex'] = helper[1];
                        status_legend[each_status[0]]['name'] = helper[0];
                      } else {
                        status_legend[each_status[0]] = {};
                        status_legend[each_status[0]]['hex'] = "#009900";
                        status_legend[each_status[0]]['name'] = "No name";
                      }
                    }                    
                    var keys = Object.keys(results);
                    this.CoolTheme.color = [];
                    for (var i=0; i<keys.length; i++) {
                      var color = status_legend[keys[i]] != undefined ? status_legend[keys[i]]['hex'] : "#000000";
                      var name = status_legend[keys[i]] != undefined ? status_legend[keys[i]]['name'] : this.translate.instant("Undefined");
                      this.CoolTheme['color'].push(color);
                      this.options.legend['data'].push(name);
                      var helper2 = {};
                      helper2['value'] = results[keys[i]]['value'];                    
                      helper2['name'] = name;        
                      this.options.series[0]['data'].push(helper2);
                    }
                    /* Finish */
                    //console.log(this.options);
                    this.chartOn = true;            
                }
              }
            }
          );     
          
        } else {
          
          this.options.title['text'] = "Display selected activities";
          this.options.title['subtext'] = firstDay_local + " - " + lastDay_local + " (" + usedDates + ")";
          var results = {};          
          this.options.series[0].data = []; //clear values
          this.options.legend['data'] = []; //clear legend
          for (var i=0; i<this.rows.length; i++) {        
            var message_type_name = this.rows[i].message_type_name == null ? 'Undefined' : this.rows[i].message_type_name;
            if (results[this.rows[i].message_type_name] == undefined) {          
              results[message_type_name] = [];
              results[message_type_name]['value'] = 0;
              results[message_type_name]['hex'] = null;
            }        
            results[message_type_name]['value']++;
            results[message_type_name]['hex'] = this.rows[i]['message_type_hex'] == null ? '#000000' : this.rows[i]['message_type_hex'];
          }
                  
          var keys = Object.keys(results);
          this.CoolTheme.color = [];
          for (var i=0; i<keys.length; i++) {         
            this.CoolTheme['color'].push(results[keys[i]]['hex']);
            this.options.legend['data'].push(keys[i]);
            var helper = {};
            helper['value'] = results[keys[i]]['value'];
            helper['color'] = results[keys[i]]['hex'];
            helper['name'] = keys[i];        
            this.options.series[0]['data'].push(helper);
          }          
          //console.log(this.options);
          this.chartOn = true;
        }
      } //#_1    

      /*********************************************************/
      /*********************************************************/
      /* LINE */

    });
  }

  remove(id) {
    this.Dashboard.removeWidget(id);
  }

  ngOnInit(): void {    

    /* if mmeber settings in empty, load it again */
    if (GlobalVariables.memberSettings.length === 0) {      
        this.data_svc.getMemberSettings(null).subscribe(
          (res: Response) => {
            var memberSettingsArray = [];
            for (var i=0; i<res['rows'].length; i++) {
              memberSettingsArray[res['rows'][i].parameter] = res['rows'][i].value;              
            }
            GlobalVariables.memberSettings = memberSettingsArray;            
            this.prepareDataAndLoad();
          }
        );
    } else {
      this.prepareDataAndLoad();      
    }   

  }

}
