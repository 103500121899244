/* Angular */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';

/* Translate Service */
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-grid-configurator',
  templateUrl: './grid-configurator.component.html',
  styleUrl: './grid-configurator.component.css'
})

export class GridConfiguratorComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public injected_data,		
		public dialogRef: MatDialogRef<GridConfiguratorComponent>
  ) {}

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.injected_data['tableConstructor'], event.previousIndex, event.currentIndex);    
  }

  confirm() {
    let order = [];
    let columns = [];
    for (let i=0; i<(this.injected_data['tableConstructor']).length; i++) {
      order.push(this.injected_data['tableConstructor'][i]['entity']);
      if (this.injected_data['tableConstructor'][i]['enabled']==false) {
        columns.push(this.injected_data['tableConstructor'][i]['entity']);
      }
    }    
    this.injected_data['order'] = order;
    this.injected_data['columns'] = columns;

    console.log(this.injected_data);
    this.dialogRef.close(this.injected_data);
  }

  ngOnInit() {
    //console.log(this.injected_data['tableConstructor']);
  }

}
