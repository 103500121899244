import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { catchError, switchMap } from 'rxjs/operators';
import { throwError, of, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  
  private baseUrl = 'https://api.biliz.com';
  private cached_token;

  constructor(private http: HttpClient) { }

  uploadMedia(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    
    const customHeaders = new HttpHeaders({'Authorization': `Bearer ${this.token}`, 'Accepted-Encoding': 'application/json' });   
    const req = new HttpRequest('POST', `${this.baseUrl}/api/documents/uploadMedia.php`, formData, {      
      headers: customHeaders,
      reportProgress: true,
      responseType: 'json'        
    });
    return this.http.request(req);    
  }

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const customHeaders = new HttpHeaders({'Authorization': `Bearer ${this.token}`, 'Accepted-Encoding': 'application/json' });   
    const req = new HttpRequest('POST', `${this.baseUrl}/api/documents/uploadFile.php`, formData, {      
      headers: customHeaders,
      reportProgress: true,
      responseType: 'json'        
    });
    return this.http.request(req);    
  }

  uploadThumbnail(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const customHeaders = new HttpHeaders({'Authorization': `Bearer ${this.token}`, 'Accepted-Encoding': 'application/json' });   
    const req = new HttpRequest('POST', `${this.baseUrl}/api/documents/uploadThumbnail.php`, formData, {      
      headers: customHeaders,
      reportProgress: true,
      responseType: 'json'        
    });
    return this.http.request(req);    
  }

  private getEventMessage(event: HttpEvent<any>) {
    // We are now getting events and can do whatever we want with them!    
  }

  private catchError(event: HttpEvent<any>) {
    // We are now getting events and can do whatever we want with them!    
  }

  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/storage`);
  }

  /******************************/

  get token() {
    if (this.hasOwnProperty('cached_token')) { return this.cached_token; }    
    const storage_token = localStorage.getItem('AUTH_TOKEN');    
    if (storage_token) {
       this.cached_token = storage_token;
       return storage_token;
    }
  }

}
