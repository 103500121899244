/* Angular / Material */
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* Services */
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { TranslateService } from '@ngx-translate/core';

/* Common Functions */
import { CommonFunctions } from '../../../services/common.functions';

/* Modals */
import { ModalComponent } from '../../../modal/modal.component';

/* Other */
//import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';

@Component({
  selector: 'app-erp-richitem',
  templateUrl: './erp-richitem.component.html',
  styleUrl: './erp-richitem.component.css'
})

export class ErpRichitemComponent implements OnInit {

  public entity = "";
  public categories = [];
  public types = [];

  public item = {
    entity: 0,
    enabled: false,
    category: 'not_selected',
    type: 'not_selected',
    poz: '0',
    title: '',
    short_description: '',
    long_description: '',
    regular_price: "0",
    special_price: "0",
    low_price: "0"
  }

  /***************************/
  public editor = 'noeditor';

  /***************************/
  /*
  public modules_full = {
    toolbar: [['bold', 'italic', 'underline', 'strike'],['blockquote', 'code-block'],[{ 'header': 1 }, { 'header': 2 }],[{ 'list': 'ordered'}, { 'list': 'bullet' }],[{ 'script': 'sub'}, { 'script': 'super' }],[{ 'indent': '-1'}, { 'indent': '+1' }],[{ 'direction': 'rtl' }],[{ 'size': ['small', false, 'large', 'huge'] }],[{ 'header': [1, 2, 3, 4, 5, 6, false] }],[{ 'color': [] }, { 'background': [] }],[{ 'font': [] }],[{ 'align': [] }],['clean'],['link', 'image', 'video']]
  };

  public modules_extended = {
    toolbar: [['bold', 'italic', 'underline', 'strike'],[{ 'header': 1 }, { 'header': 2 }],[{ 'list': 'ordered'}, { 'list': 'bullet' }],[{ 'script': 'sub'}, { 'script': 'super' }],[{ 'indent': '-1'}, { 'indent': '+1' }],[{ 'direction': 'rtl' }],[{ 'color': [] }, { 'background': [] }],[{ 'font': [] }],[{ 'align': [] }],['clean'],['link']]
  };

  public modules_light = {
    toolbar: [['bold', 'italic', 'underline', 'strike'],[{ 'color': [] }, { 'background': [] }],[{ 'font': [] }]]
  };
  */

  constructor(
    private data: DataService,
    public cFunction: CommonFunctions,
    public translate: TranslateService,
    public dialog: MatDialog,
    public CommonFunctions: CommonFunctions
  ) {
    translate.addLangs(['en', 'si']);
  }


  /**************************/
  /*
  changedShortDescription(event: EditorChangeContent | EditorChangeSelection) {        
    if (event['event'] == 'text-change') { this.item.short_description = event['html']; }
  }

  createdShortDescription(event) {
    event.root.innerHTML = this.item.short_description;
  }
  
  changedLongDescription(event: EditorChangeContent | EditorChangeSelection) {        
    console.log(event['event']);
    if (event['event'] == 'text-change') { this.item.long_description = event['html']; }
  }

  createdLongDescription(event) {
    event.root.innerHTML = this.item.long_description;
  }
  */

  /**************************/
  updateType(value) {    
    console.log((value.target as HTMLSelectElement).value);
  }

  /**************************/
  stringToNumber(value) {    
    console.log(value, isNaN(value));
    if (isNaN(value)) {      
      return parseFloat(value.replace(".", "").replace(",", "."));
    } else {
      return parseFloat(value);      
    }
  }

  save() {    
    this.item['low_price'] = this.CommonFunctions.floatToMYSQL(this.CommonFunctions.parseStringToFloat(this.item['low_price']));
    this.item['regular_price'] = this.CommonFunctions.floatToMYSQL(this.CommonFunctions.parseStringToFloat(this.item['regular_price']));
    this.item['special_price'] = this.CommonFunctions.floatToMYSQL(this.CommonFunctions.parseStringToFloat(this.item['special_price']));    
    //console.log(this.item);
    this.data.saveQuoteItem({ data:this.item }).subscribe((res: Response) => {});
  }

  delete() {
    let content = this.translate.instant("Do you want to delete ERP item? Items on other documents will remain as they are.");
    let modalDialog = this.dialog.open(ModalComponent, {
      data: { inputText: null, content: content, yes: this.translate.instant("Delete") , no: null, cancel: this.translate.instant("Cancel") } 
    });

    modalDialog.afterClosed().subscribe(result => {          
      if (result['yes']!=undefined) {
        this.data.deleteQuoteItem( { entity : this.item.entity } ).subscribe( (res: Response) => {} );    
      }
    });    
  }

  /**************************/
  ngOnInit(): void {    
   
    this.data.getQuoteItemCategories({}).subscribe(
      (res: Response) => {          
        let items = (<any>res).rows;
        this.categories = [];
        this.types = [];
        for (let i=0; i<items.length; i++) {
          if (!this.categories.includes(items[i]['category'])) {
            this.categories.push(items[i]['category']);
            this.types[items[i]['category']] = [];
          }
        }

        for (let i=0; i<items.length; i++) {            
           this.types[items[i]['category']].push(items[i]['type']);
        }                
        
        this.item.entity = parseInt(this.entity);
        
        if (isNaN(this.item.entity)) {
          this.item.entity = null;
        } else {          
          this.data.getQuoteItems( { entity : this.item.entity } ).subscribe(
            (res: Response) => {
              //this.editor = GlobalVariables.memberSettings['editor'];
              this.item = (<any>res).rows[0];              
              this.item['low_price'] = this.CommonFunctions.localizeAnyNumberToString(this.item['low_price']);
              this.item['regular_price'] = this.CommonFunctions.localizeAnyNumberToString(this.item['regular_price']);
              this.item['special_price'] = this.CommonFunctions.localizeAnyNumberToString(this.item['special_price']);
            }
          );
        }        

      }
    );    
  } //oninit

}
