<div class="widget_settings">
    <div class="innerPopup header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="left">
            <h1>{{'Widget settings'|translate}}</h1>
        </div>
        <div class="right">
            <fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
        </div>
    </div>

    <div class="inner_content">
        
        <div class="body">
            <div class="line">
                <div class="field">
                    <input type="text" name="title" [(ngModel)]="settings.title" />
                    <div class="label" style="padding-top:6px;">{{'Widget title'|translate}}</div>
                </div>
            </div>

            <div class="multi_line" style="padding-top: 24px; margin-top:0px; padding-bottom: 0px; border-top: 1px solid var(--border-color)">
                <div class="field" style="width: 100%">
                    <label>{{'Available activities'|translate}}</label>

                    <div class="multiselect_checkbox categories">
                        <ng-container *ngFor="let category of categories">
                        <div class="checkbox_wrapper">
                            <label class="switch">
                                <input type="checkbox" class="dummy" [attr.rel]="category.entity" [checked]="category.selected">
                                <span class="slider round"></span>
                            </label>
                            <div class="label"><span class="category_bubble" style="background:{{category.hex}}"></span>{{category.description}}</div>
                        </div>
                        </ng-container>		

                    </div>
                </div>
            </div>			


            <div class="multi_line" style="padding-top: 24px; margin-top:0px; padding-bottom: 0px; border-top: 1px solid var(--border-color)">
                <div class="field" style="width: 100%">
                    <label>{{'Show activities for'|translate}}</label>

                    <div class="multiselect_checkbox categories">
                        <div class="field">
                            <label class="switch">
                                <input type="checkbox" [(ngModel)]="settings['today']">
                                <span class="slider round"></span>
                            </label>
                            <div class="label label-checkbox">{{'Today'|translate}}</div>
                        </div>			
                    
                        <div class="field">
                            <label class="switch">
                                <input type="checkbox" [(ngModel)]="settings['tomorrow']">
                                <span class="slider round"></span>
                            </label>
                            <div class="label label-checkbox">{{'Tomorrow'|translate}}</div>
                        </div>			
                    
                        <div class="field">
                            <label class="switch">
                                <input type="checkbox" [(ngModel)]="settings['week']">
                                <span class="slider round"></span>
                            </label>
                            <div class="label label-checkbox">{{'Next 7 days'|translate}}</div>
                        </div>			
                    </div>

                </div>
            </div>

        </div> <!-- body  -->

        <div class="footer">
            <button class="confirm" (click)="apply();" mat-button>{{'Confirm'|translate}}</button>
            <button class="cancel" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>				
        </div>
    </div>
</div>
