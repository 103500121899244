<div class="inner_wrapper"> 
    <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="left"><h1>{{'Choose cateogry'|translate}}</h1></div>
        <div class="right"><fa-icon class="icon_close" [icon]="['fas', 'times']" mat-button mat-dialog-close></fa-icon></div>
    </div>

    <div class="body">
		<ng-container *ngFor="let category of data.categories">
			<span *ngIf="category.create==1" class="category_option_style" [attr.category_entity]="category.entity" [attr.hex]="category.hex" [ngStyle]="{'background':category.hex,'color':CommonFunction.textColorFromBG(category.hex)}" (click)="assignCategory(category.entity, category.hex, category.description);">
				{{category.description}}
			</span>
		</ng-container>
	</div>

    <div class="modalActions">
        <button class="left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>        
    </div>
</div>