/* Angular */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* Services */
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';

/* Components */
//import { TopComponent } from '../../../top/top.component';

/* Translate */
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activity-type-edit',
  templateUrl: './activity-type-edit.component.html',
  styleUrls: ['./activity-type-edit.component.css']
})

export class ActivityTypeEditComponent implements OnInit {

	private sub : any;
	public entity;		
	public rows = {};
	public member_groups = [];
	public members = [];
	public parameters = {};
	public statuses = [];
	public useStatuses = false;
	public selectedColorPicker;
	public extended = false;
	public filterMembers = "";
	public filterGroups = "";
	
	formData: UntypedFormGroup; 

	/*******************************/

	constructor(
		private data: DataService,
		private route: ActivatedRoute,
		public translate: TranslateService,
		public dialogRef: MatDialogRef<ActivityTypeEditComponent>
	) {
		translate.addLangs(['en', 'si']);
	}

	/*******************************/

	makeid(length) {
	   var result           = '';
	   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567890';
	   var charactersLength = characters.length;
	   for ( var i = 0; i < length; i++ ) { result += characters.charAt(Math.floor(Math.random() * charactersLength)); }
	   return result;
	}

	get(id) {	
		this.data.getAccesses(null).subscribe(
			(res: Response) => { 
				this.member_groups = (<any>res).member_groups; 
				this.members = (<any>res).members;				
				
				this.data.getActivityType({id:id}).subscribe(
					(res: Response) => { 

						this.rows = (<any>res).rows[0];
						var statusesHelper = this.rows['status_text'].split(',');

						for (var i = 0; i < statusesHelper.length; i++) {
						    var statusHelper = statusesHelper[i].split(';');
						    var statusTextHelper = statusHelper[0].split('=');
						    if (!statusHelper[1]) { statusHelper[1] = '#f2f2f2'; }
						    this.statuses.push({'id':statusTextHelper[0], 'label':statusTextHelper[1], 'hex':statusHelper[1]});
						}				

						$('.main_data .color_picker').css({'background':this.rows['hex']});

						/*update accesses*/
						var access = this.rows['access'];							
						for (var i=0; i<access.length; i++) {					
							if (access[i].view=='true') { $('input[type=checkbox][allow=view][type_entity='+access[i].type_entity+'][accesstype='+access[i].type+']').prop( "checked", true); }
							if (access[i].edit=='true') { $('input[type=checkbox][allow=edit][type_entity='+access[i].type_entity+'][accesstype='+access[i].type+']').prop( "checked", true); }
							if (access[i].create=='true') {	$('input[type=checkbox][allow=create][type_entity='+access[i].type_entity+'][accesstype='+access[i].type+']').prop( "checked", true); }
						}						
					}
				);
			}
		);
	}

	
	save() {		
		this.parameters['data'] = this.rows;
	    var statuses_text = "";
	    $('#statuses .line').each(function() {
	    	var status_id = $(this).find('.status_id').val();
	    	var status_text = $(this).find('.status_text').val();
	    	var status_hex = $(this).find('.status_color').val();
	    	if (status_id != undefined) {
		    	if (statuses_text != '') statuses_text += ',';
		    	statuses_text += status_id + "=" + status_text + ";" + status_hex;
	    	}
	    });
	    
	    var attrs = [];
	    $('.access_line').each(function() {
	    	var attr_type = $(this).find('.access_view input').attr('accesstype');
	    	var attr_type_entity = $(this).find('.access_view input').attr('type_entity');
	    	var attr_view = $(this).find('.access_view input').is(':checked');
	    	var attr_create = $(this).find('.access_create input').is(':checked');
	    	var attr_edit = $(this).find('.access_edit input').is(':checked');
	    	var attr = {'type':attr_type,'type_entity':attr_type_entity,'view':attr_view,'create':attr_create,'edit':attr_edit}
	    	attrs.push(attr);
	    });
	    
	    this.parameters['data']['access'] = attrs;
	    this.parameters['data']['hex'] = $('input[name=hex]').val();
	    this.parameters['data']['status_text'] = statuses_text;
	    this.parameters['data']['one_status'] = true;
	    if (this.parameters['data']['status'] != true) this.parameters['data']['status'] = false;
    	    
	    this.data.saveActivityType(this.parameters).subscribe(
	      (res: Response) => {
			this.dialogRef.close();
		  }
	    );
	    	    	  
	 }

	delete() {				
		this.data.deleteActivityType({data:{'entity':this.rows['entity']}}).subscribe(
	      (res: Response) => {
			this.dialogRef.close();
		  }
	    );
	}
	
	addStatus() {
		this.statuses.push({'id':this.makeid(5), 'label':'', 'hex':'#f0f0f0'});
	}

	removeStatus(row) {
		this.statuses.splice(row,1);	
	}

	/*******************************/

	showExtendedMode() {}

	ngOnInit(): void {	

		var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    	this.translate.use(lang);
    
		const component = this;

		this.sub = this.route.params.subscribe(params => {
			var id = params['id'];
			if (id != null) {				
				this.get(id);
			}
			if (this.entity != null) {				
				this.get(this.entity);	
			}

			if (id == null && this.entity == null)
			this.data.getAccesses(null).subscribe(
				(res: Response) => { 
					this.member_groups = (<any>res).member_groups; 
					this.members = (<any>res).members;
				}
			);
		}); 

		/* jQuery */
    	$(document).ready(function() {
    		$(document).on('click', '.color_picker', function() {
    			$('#colorPicker').show();
    			component.selectedColorPicker = $(this);
    			var position = component.selectedColorPicker.offset();
				$('#colorPicker').css({'top':position.top+25+'px', 'left':position.left+'px'});
    		});

    		$(document).on('click', '.colorPickerChild', function() {
    			var rel = component.selectedColorPicker.attr('rel');
    			component.selectedColorPicker.attr('hex', $(this).attr('hex'));
    			component.selectedColorPicker.css({'background':$(this).attr('hex')});
    			$('[name='+rel+']').val($(this).attr('hex'));
    			$('#colorPicker').hide();
    		});
	   	});

  	}
}