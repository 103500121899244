<div class="inner_wrapper">
	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="left">
		<h1>
			{{'Knowledge record'|translate}}
		</h1>
		</div>

		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
		</div>
	</div>

	<ng-container *ngIf="!readOnly">
		<!-- EDITOR -->
		<form [formGroup]="formData" (ngSubmit)="save()" novalidate style="width:825px">

			<div class="body">
		
				<input type="hidden" formControlName="entity" name="entity" [(ngModel)]="rows['entity']" />

				<div *ngIf="rows['entity']!='' && rows['entity']!=null" class="field wPadding" style="margin-bottom: 24px;">
					<label class="switch" style="margin-top: 5px;">
						<input type="checkbox" name="deprecated" formControlName="deprecated" [checked]="rows['deprecated']" (change)="rows['deprecated']=!rows['deprecated']">
						<span class="slider round"></span>
					</label>  
					<div class="label label-checkbox">{{'Deprecated'|translate}}</div>
				</div>		

				<div *ngIf="rows['entity']!='' && rows['entity']!=null" class="inline w25 wPadding" style="float: left;">
					<mat-form-field appearance="outline">
						<mat-label>{{'System'|translate}}</mat-label>
						<mat-select formControlName="system" name="system" [(ngModel)]="rows['system']">
							<mat-option *ngFor="let item of systems" value="{{item.title}}">{{item.title}}</mat-option>                      
						</mat-select>
					</mat-form-field>
				</div>

				<div *ngIf="rows['entity']!='' && rows['entity']!=null" class="inline w25 wPadding" style="float: left;">
					<mat-form-field appearance="outline">
						<mat-label>{{'System version'|translate}}</mat-label>
						<input  matInput formControlName="version" name="version" [(ngModel)]="rows['version']">                    
					</mat-form-field>
				</div>

				<div *ngIf="rows['entity']!='' && rows['entity']!=null" class="line divider"></div>	

				<div class="inline w100 wPadding">
					<mat-form-field appearance="outline">
						<mat-label>{{'Title'|translate}}</mat-label>
						<input matInput formControlName="title" name="title" [(ngModel)]="rows['title']">                    
					</mat-form-field>
				</div>

				<div *ngIf="rows['entity']!='' && rows['entity']!=null" class="inline w100 wPadding">
					<mat-form-field appearance="outline">
						<mat-label>{{'Problem Description'|translate}}</mat-label>
						<textarea matInput formControlName="description" name="description" style="min-height:100px;" [(ngModel)]="rows['description']"></textarea>
					</mat-form-field>
				</div>

				<div *ngIf="rows['entity']!='' && rows['entity']!=null" class="inline w100 wPadding">
					<mat-form-field appearance="outline">
						<mat-label>{{'Solution'|translate}}</mat-label>
						<textarea matInput formControlName="solution" name="solution" style="min-height:180px;" [(ngModel)]="rows['solution']"></textarea>
					</mat-form-field>
				</div>

				<div *ngIf="rows['entity']!='' && rows['entity']!=null" class="line divider"></div>

				<div *ngIf="rows['entity']!='' && rows['entity']!=null" class="inline w50 wPadding" style="float: left;">
					<mat-form-field appearance="outline">
						<mat-label>{{'Keywords'|translate}}</mat-label>
						<input matInput formControlName="keywords" name="keywords" [(ngModel)]="rows['keywords']">                    
					</mat-form-field>
				</div>

				<div *ngIf="rows['entity']!='' && rows['entity']!=null" class="inline w30 wPadding" style="float: left;">
					<mat-form-field appearance="outline">
						<mat-label>{{'Tags'|translate}}</mat-label>
						<input matInput formControlName="tags" name="tags" [(ngModel)]="rows['tags']">                    
					</mat-form-field>
				</div>

				<div *ngIf="rows['entity']!='' && rows['entity']!=null && attachments.length > 0" class="line divider"></div>

				<ng-container *ngIf="rows['entity']!='' && rows['entity']!=null">
					<div *ngIf="attachments.length > 0" class="attachment_content">
						<ng-container *ngFor="let item of attachments"> 
							<span class="icon_delete" (click)="deleteAttachment(item.entity)">
								<fa-icon [icon]="['fad', 'times']"></fa-icon>
							</span>
							<div class="etalk_attachment_item" (click)="download(item.filename, item.original_filename)">
								<span class="icon">
									<fa-icon [icon]="['fad', 'file']"></fa-icon>
								</span>
								<span class="filename">
									<span class="folder">{{item.folder}}</span>/<span class="original_filename">{{item.original_filename}}</span>
								</span>
								<span class="dilesize">
									{{formatBytes(item.filesize, 1)}}													
								</span>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</div>

			<div class="modalActions" mat-dialog-actions>
				<button class="accept right" type="submit" mat-button>{{'Save'|translate}}</button>
				<button class="action left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
				<button *ngIf="rows['entity']!='' && rows['entity']!=null" class="warrning right" (click)="delete()" mat-button mat-dialog-close>{{'Delete'|translate}}</button>
			</div>
		</form>
	</ng-container>

	<ng-container *ngIf="readOnly">
		<!-- viewer -->
		<div class="readonly">
			<div class="line w20" style="margin-right: 24px">
				<div class="field">
					{{rows['system']}}
				</div>
				<div class="label">
					{{'System'|translate}}
				</div>
			</div>

			<div class="line w33">
				<div class="field">
					{{rows['version']}}
				</div>
				<div class="label">
					{{'Version'|translate}}
				</div>
			</div>

			<div class="line">
				<div class="field">
					{{rows['title']}}
				</div>
				<div class="label">
					{{'Title'|translate}}
				</div>
			</div>

			<div class="line">
				<div class="field">
					{{rows['description']}}
				</div>
				<div class="label">
					{{'Problem description'|translate}}
				</div>
			</div>

			<div class="line">
				<div class="field">
					{{rows['description']}}
				</div>
				<div class="label">
					{{'Solution'|translate}}
				</div>
			</div>

			<div class="attachment_content">
				<ng-container *ngFor="let item of attachments"> 
					<div class="etalk_attachment_item" (click)="download(item.filename, item.original_filename)">
						<div class="icon">
							<fa-icon [icon]="['fad', 'file']"></fa-icon>
						</div>
						<div class="filename">
							<span class="folder">{{item.folder}}</span>/<span class="original_filename">{{item.original_filename}}</span>
						</div>
						<div class="dilesize">
							{{formatBytes(item.filesize, 1)}}													
						</div>

					</div>
				</ng-container>
			</div>

		</div>
	</ng-container>

	<div *ngIf="rows['entity']!='' && rows['entity']!=null" class="file_container_knowledge" appDragAndDrop (fileDropped)="onFileDropped($event)" style="visibility:hidden; opacity:0">
		<input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target['files'])" />	
		<div class="dropdown_infocontainer center">
			<div *ngIf="files" class="info dropdowninfo" style="text-align: center;">
				{{'Drop files here.#3'|translate}}<br /><br />
				<fa-icon [icon]="['fad', 'times']" class="closeDropField" style="font-size: 48px" (click)="closeDropField_knowledge()"></fa-icon>
			</div>
			<div class="progress_container">
				<div *ngFor="let file of files" class="uploading_progress">
					<div class="progress_filename">
						{{file.name}} - {{formatBytes(file.size, 1)}}
					</div>
					<div class="progress">
						<div class="progress-value" style="width:{{file.progress}}%"></div>
					</div>
				</div>
			</div>
		</div>
	</div>

</div>