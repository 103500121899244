import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-widget-next-settings',
  templateUrl: './widget-next-settings.component.html',
  styleUrl: './widget-next-settings.component.css'
})
export class WidgetNextSettingsComponent implements OnInit {

  public categories = [];
  public settings = {title:'Activity Widget title...',category:null,order:'created',orderby:'asc',items:12,featured:0,assigned:0,author:1,hidden:0,approvedbyme:1,approved:0,unapproved:0,extended:1,created:0,date_from:0,date_to:0,status:1,priority:0,sub_activities:0,comments:0,comments_results:3,showauthor:1,showcustomer:1,showcomments:0,showprogress:0,removeold:0,combinecustomer:1,hidechild:0};
  
  constructor(
	  private data: DataService,
    public dialog: MatDialogRef<WidgetNextSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public injected_data,
    public translate: TranslateService
  ) {     
  }

  apply() {    
      let categories = [];
      $('.multiselect_checkbox.categories .switch').each(function() {
        var rel = $(this).find('input[type=checkbox]').attr('rel');
        var checked = $(this).find('input[type=checkbox]').is(':checked');
        //var category = {'category_id':rel,'status':checked};
        var category = {'category_id':rel};
        if (checked == true && rel != undefined) {
          categories.push(category);
        }
      });
      
      let parameters = [];
      parameters['data'] = [];
      parameters['data'] = this.settings;
      parameters['data']['categories'] = categories;
      console.log(parameters);
      this.dialog.close(parameters);
  }

  /*************************************************/
  ngOnInit(): void {

    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);

    this.settings = this.injected_data;
    /* fix */
    if (this.settings.order != undefined) {
      this.settings.order = (this.settings.order).replace('t3.entity, ', '');
    }

    this.data.getActivityTypes({}).subscribe(
      (res: Response) => {
        this.categories = (<any>res).rows;                
        if (this.settings['categories']) {
          for (var i=0; i<(this.categories).length; i++) {
            for (var j=0; j<(this.settings['categories']).length; j++) {
              if (this.settings['categories'][j].category_id == this.categories[i].entity) {
                this.categories[i]['selected'] = true;
              }             
            }
          }
        }                
      }
    );
  }

}
