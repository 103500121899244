<div class="inner_wrapper"> 
    <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="left"><h1>{{'Priority'|translate}}</h1></div>
		<div class="right" (click)="close()">			
			<fa-icon class="icon_close" [icon]="['fad', 'times']"></fa-icon>
		</div>
    </div>

    <div class="body">
		<div class="assign_priority_body">
			<span class="priority" [attr.priority_entity]="0" [attr.hex]="'#eee'" (click)="assignPriority(0)"><fa-icon class="priority0" [icon]="['fas', 'flag']"></fa-icon>&nbsp;&nbsp;{{'No priority'|translate}}</span>
			<span class="priority" [attr.priority_entity]="1" [attr.hex]="'#0f0'" (click)="assignPriority(1)"><fa-icon class="priority1" [icon]="['fas', 'flag']"></fa-icon>&nbsp;&nbsp;{{'Low'|translate}}</span>
			<span class="priority" [attr.priority_entity]="2" [attr.hex]="'#fab20a'" (click)="assignPriority(2)"><fa-icon class="priority2" [icon]="['fas', 'flag']"></fa-icon>&nbsp;&nbsp;{{'Middle'|translate}}</span>
			<span class="priority" [attr.priority_entity]="3" [attr.hex]="'#f00'" (click)="assignPriority(3)"><fa-icon class="priority3" [icon]="['fas', 'flag']"></fa-icon>&nbsp;&nbsp;{{'High'|translate}}</span>
		</div>
	</div>

    <div class="modalActions">
        <button class="cancel" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
    </div>
</div>