<div class="inner_wrapper">
	

    
	<div class="table_header enached_table_header">
        
        <div class="table_header_right">
            <div class="gantt_button" *ngFor="let view of views" [class.active]="view.value === selectedViewType" (click)="selectView(view.value)">
                {{ view.name }}
            </div>
        </div>

        <div class="table_header_right" style="margin-right: 24px">
            <span style="position:relative;">
                <fa-icon class="canban" (click)="canban_view()" [icon]="['fad', 'square-kanban']"></fa-icon>
                <div class="tooltip">{{'Kanban view'|translate}}</div>
            </span>
        </div>

        <div class="table_header_right" style="margin-right: 0px">
            <span style="position:relative;">
                <fa-icon class="canban active selected" (click)="gantt_view()" [icon]="['fad', 'chart-gantt']"></fa-icon>
                <div class="tooltip">{{'Gantt view'|translate}}</div>
            </span>
        </div>

        <div class="table_header_right" style="margin-right: 0px">
            <span style="position:relative;">
                <fa-icon class="canban" (click)="table_view()" [icon]="['fad', 'table-rows']"></fa-icon>
                <div class="tooltip">{{'Table view'|translate}}</div>
            </span>
        </div>
        
        <div class="table_header_left">
            <fa-icon [class.active]="hide_activity_name" [icon]="['fad', 'list-tree']" (click)="switch_hide_activity_name()"></fa-icon>
        </div>
        <div class="table_header_left">
            <fa-icon [class.active]="hide_activity_start" [icon]="['fad', 'arrow-right-from-line']" (click)="switch_hide_activity_start()"></fa-icon>
        </div>
        <div class="table_header_left">
            <fa-icon [class.active]="hide_activity_end" [icon]="['fad', 'arrow-right-to-line']" (click)="switch_hide_activity_end()"></fa-icon>
        </div>
        <!--
        <div class="table_header_left spacer">            
        </div>
        <div class="table_header_left">
            <fa-icon [class.active]="hide_activity_finished" [icon]="['fad', 'ballot-check']" (click)="switch_hide_activity_finished()"></fa-icon>
        </div>
        -->
    </div>	

    <div class="gantt_body">
    
        <ngx-gantt #gantt 
            [items]="items"
            [async]="true"
            [draggable]="true"
            [linkable]="true"
            [selectable]="true"
            [multiple]="false"    

            [baselineItems]="baselineItems"
            [viewType]="viewType"
            [showToolbar]="isShowToolbarChecked"
            [toolbarOptions]="toolbarOptions"

            (barClick)="barClick($event)"
            (viewChange)="viewChange($event)"
            (lineClick)="lineClick($event)"
            (dragMoved)="dragMoved($event)"
            (dragEnded)="dragEnded($event)"
            (selectedChange)="selectedChange($event)"
            (linkDragEnded)="linkDragEnded($event)"

            
            >

            <ngx-gantt-table>

                <ngx-gantt-column *ngIf="!hide_activity_name" name="Activity" width="180px">
                    <ng-template #cell let-item="item">
                        <div>
                            {{ item.title }}<br />
                            <span>{{ item.customer }}</span>
                        </div>
                    </ng-template>
                </ngx-gantt-column>

                <ngx-gantt-column *ngIf="!hide_activity_start" name="Start" width="140px">
                    <ng-template #cell let-item="item">
                        {{ item.start * 1000 | date: 'yyyy-MM-dd' }}
                    </ng-template>
                </ngx-gantt-column>

                <ng-template #bar let-item="item">
                    <span style="color: #fff">&nbsp;&nbsp;{{item.title}}</span>
                </ng-template>

                <ngx-gantt-column *ngIf="!hide_activity_end" name="End" width="140px">
                    <ng-template #cell let-item="item">
                        {{ item.end * 1000 | date: 'yyyy-MM-dd' }}
                    </ng-template>
                </ngx-gantt-column>

            </ngx-gantt-table> 

        </ngx-gantt>

    </div>