<div class="dashboard_space_wrapper">
  <ng-container *ngIf="emptyObject(dashboard)==0">
    <div class="emptyDashboard">            

      <div class="container">
        <div id="myclock"></div>
      </div>

      <h1>{{'Welcome to Dashboard.'|translate}}</h1>
      <span>{{'Click on Add Widget above and configure the dashboard to suit your needs'|translate}}</span>
      <br /><br />
      <img src="/assets/logo/ibilizz.png" style="opacity:0.2" />
    </div>
  </ng-container>
  <div class="dashboard_space" [ngStyle]="{'margin-left': layer_movement_percent, 'width': layer_container_width }">

    <ng-container *ngFor="let selected_layer of dashboard | keyvalue">
        <div class="dashboard_group" cdkDropListGroup> 

          <ng-container *ngFor="let column of selected_layer.value['columns'] | keyvalue">            
              <div class="dashboard_column" cdkDropList [cdkDropListData]="column.key" (cdkDropListDropped)="drop($event)">                  

                 <ng-container *ngFor="let widget of column.value | keyvalue">                    
                    <div class="dashboard_widget" *ngIf="widget.value['type']!='' && widget.value['type'] != undefined">                      
                      <app-widget-activities-charts *ngIf="widget.value['type']=='widget-activities-charts' && widget.value['id'] != null" id="{{widget.value['id']}}" refresh="{{widget.value['refresh']}}"></app-widget-activities-charts>
                      <app-widget-activities *ngIf="widget.value['type']=='widget-activities' && widget.value['id'] != null" id="{{widget.value['id']}}" refresh="{{widget.value['refresh']}}"></app-widget-activities>
                      <app-widget-calendar *ngIf="widget.value['type']=='widget-calendar' && widget.value['id'] != null" id="{{widget.value['id']}}"></app-widget-calendar>
                      <app-widget-last *ngIf="widget.value['type']=='widget-last' && widget.value['id'] != null" id="{{widget.value['id']}}"></app-widget-last>
                      <app-widget-postit *ngIf="widget.value['type']=='widget-postit' && widget.value['id'] != null" id="{{widget.value['id']}}"></app-widget-postit>
                      <app-widget-customers *ngIf="widget.value['type']=='widget-customers' && widget.value['id'] != null" id="{{widget.value['id']}}"></app-widget-customers>
                      <app-widget-weather *ngIf="widget.value['type']=='widget-weather' && widget.value['id'] != null" id="{{widget.value['id']}}"></app-widget-weather>
                      <app-widget-next *ngIf="widget.value['type']=='widget-next' && widget.value['id'] != null" id="{{widget.value['id']}}"></app-widget-next>
                    </div>                  
                  </ng-container>
                
                  <div class="column_widget_tool" [ngClass]="{'emptyDashboardColor':emptyObject(dashboard)==0}">
                    <span (click)="add(column.key)"><fa-icon class="big" [icon]="['fad', 'plus-circle']"></fa-icon><span>{{'Add widget'|translate}}</span></span>
                  </div>
                
              </div>
          </ng-container>

        </div>
    </ng-container> 

  </div>
</div>