import { Component, OnInit, Input } from '@angular/core';
import { GlobalVariables } from '../../services/data.globals';
import { DataService } from '../../services/data.service';
import { CommonFunctions } from '../../services/common.functions';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { ActivityComponent } from '../../activities/activity/activity.component';
import { WidgetNextSettingsComponent } from './widget-next-settings/widget-next-settings.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-widget-next',
  templateUrl: './widget-next.component.html',
  styleUrl: './widget-next.component.css'
})

export class WidgetNextComponent implements OnInit {

  public rows = [];
  public refresh_percent;
  public parameters = { title:"What's next?", categories:null, showcustomer:1, showauthor: 1, priority: 1, today: 1, tomorrow: 1, week: 0 };
  public parameters_json;
  public mouseover = false;

  public itemsTodayStart = [];
  public itemsTodayEnd = [];
  public itemsTomorrowStart = [];
  public itemsTomorrowEnd = [];
  public itemsWeekStart = [];
  public itemsWeekEnd = [];

  public dateToday = "";
  public dateTomorrow = "";  
  public dateWeek = "";  

  @Input() id: string;  

  @Input() set refresh(refresh: string) {    
    /*
    if (parseInt(refresh) == 1000000) {      
      if (!this.mouseover) this.prepareDataAndLoad();
    }        
    this.refresh_percent = refresh;    
    */
  }

  constructor(
  	private data: DataService,
    public cFunction: CommonFunctions, 
    public WidgetNextSettingsDialog: MatDialog,
    public dialog: MatDialog,
    public Dashboard: DashboardComponent,
    public translate: TranslateService  
  ) { }
  
  /******************************************/
  duration(start, end) {
    let startHelper = start.split(' ');
    let endHelper = start.split(' ');

    if (startHelper[0] == endHelper[0]) {
      if (startHelper[1] == '00:00:00' && (endHelper[1] == '23:45:00' || endHelper[1] == '00:00:00')) {
        return 'Whole day activity';
      } else if (startHelper[1] == endHelper[1]) {
        return startHelper[1].slice(0, -3);        
      } else {
        return startHelper[1].slice(0, -3) + " - " + endHelper[1].slice(0, -3);
      }      
    } else {
      return 'Multi day activity';
    }
  }  

  countObject(selectedObject) {
    if (selectedObject != null) {
      return Object.keys(selectedObject).length;
    } else {
      return 0;
    }
  }

  /******************************************/
  dateDisplay(start, end) {
    if (start == undefined || end == undefined) return "";
    let helperStart = start.split(" ")[0];
    let helperEnd = start.split(" ")[0];

    if (helperStart == helperEnd) {
      return this.cFunction.localDateOnlyFormat(helperStart) + " | ";
    } else {
      return this.cFunction.localDateOnlyFormat(helperStart) + " - " + this.cFunction.localDateOnlyFormat(helperEnd) + " | ";
    }

  }

  /******************************************/
  /******************************************/
  loadData(parameters) {
    
    let parametersHelpers = parameters;
    this.dateToday = "";
    this.dateTomorrow = "";
    this.dateWeek = "";
    
    if (parametersHelpers.today || (!parametersHelpers.today && !parametersHelpers.tomorrow && !parametersHelpers.week)) {
      let today = new Date()
      parametersHelpers['filter_start_from_date_raw'] = today.toISOString().split('T')[0] + " 00:00:00";
      parametersHelpers['filter_start_to_date_raw'] = today.toISOString().split('T')[0] + " 23:59:59";
      parametersHelpers['items'] = 50;
      parametersHelpers['order'] = "t1.message_start";
      parametersHelpers['orderby'] = "asc";
      parametersHelpers['page'] = 0;
      parametersHelpers['search'] = null;
      
      console.log(parametersHelpers);
      this.data.getActivities(parametersHelpers).subscribe(
        (res: Response) => {
          console.log((<any>res).rows);
          this.itemsTodayStart = (<any>res).rows;
        }
      );
    } else {
      this.itemsTodayStart = [];
    } //today
    
    if (parametersHelpers.tomorrow) {
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      parametersHelpers['filter_start_from_date_raw'] = tomorrow.toISOString().split('T')[0] + " 00:00:00";
      parametersHelpers['filter_start_to_date_raw'] = tomorrow.toISOString().split('T')[0] + " 23:59:59";
      this.data.getActivities(parametersHelpers).subscribe(
        (res: Response) => {
          console.log((<any>res).rows);
          this.itemsTomorrowStart = (<any>res).rows;
        }
      );
    } else {
      this.itemsTomorrowStart = [];
    } //tomorrow

    if (parametersHelpers.week) {
      let week = new Date();
      week.setDate(week.getDate() + 2);
      parametersHelpers['filter_start_from_date_raw'] = week.toISOString().split('T')[0] + " 00:00:00";
      week.setDate(week.getDate() + 5);
      parametersHelpers['filter_start_to_date_raw'] = week.toISOString().split('T')[0] + " 23:59:59";
      this.data.getActivities(parametersHelpers).subscribe(
        (res: Response) => {
          console.log((<any>res).rows);
          this.itemsWeekStart = (<any>res).rows;
          for (let i=0; i<this.itemsWeekStart.length; i++) {
            this.itemsWeekStart[i]['dateDisplay'] = this.dateDisplay(this.itemsWeekStart[i].message_start, this.itemsWeekStart[i].message_end);
          }
        }
      );
    } else {
      this.itemsWeekStart = [];
    } //tomorrow
    
  }
  
  prepareDataAndLoad() {    
    this.parameters = GlobalVariables.memberSettings[this.id] != undefined ? JSON.parse(GlobalVariables.memberSettings[this.id]) : { title:this.translate.instant("What's next?"), categories:null, showcustomer:1, showauthor: 1, priority: 1, today: 1, tomorrow: 1, week: 0 };
      
    /* special workarround because of combining displayed data */      
    this.parameters_json = JSON.stringify(this.parameters);    
    this.loadData(this.parameters);
  }

  /******************************************/
  /******************************************/
  edit(entity) {
    var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
    editDialog.componentInstance.entity = entity;
    editDialog.afterClosed().subscribe(result => {
      this.loadData(this.parameters);
    });
  }
  
  remove(id) {
    this.Dashboard.removeWidget(id);
  }

  settings(id, settings = "{}") {
        
    var WidgetNextSettings = this.WidgetNextSettingsDialog.open(WidgetNextSettingsComponent, {
      'width' : '800px',
      data: JSON.parse(settings),
      id: id
    });    

    WidgetNextSettings.afterClosed().subscribe(result => {   
      if (result != undefined) {
        this.parameters = result.data;
        this.parameters_json = JSON.stringify(result.data);
        //this.loadData(JSON.parse(this.parameters_json));
      
        this.data.setMemberSettings({'parameter': id, 'value': this.parameters_json}).subscribe((res: Response) => {});
      }
    });    
  }

  /******************************************/
  /******************************************/  
  ngOnInit(): void {    
    /* if mmeber settings in empty, load it again */
    if (GlobalVariables.memberSettings.length === 0) {      
      this.data.getMemberSettings(null).subscribe(
        (res: Response) => {
          var memberSettingsArray = [];
          for (var i=0; i<res['rows'].length; i++) {
            memberSettingsArray[res['rows'][i].parameter] = res['rows'][i].value;              
          }
          GlobalVariables.memberSettings = memberSettingsArray;            
          this.prepareDataAndLoad();
        }
      );
    } else {
      this.prepareDataAndLoad();      
    }   
  }

}
