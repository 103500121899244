import { Component } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-webcam-capture',
  templateUrl: './webcam-capture.component.html',
  styleUrls: ['./webcam-capture.component.css']
})
export class WebcamCaptureComponent {

  constructor(
    //private data: DataService,
    public dialog: MatDialogRef<WebcamCaptureComponent>,
    public translate: TranslateService 
  ) {
    translate.addLangs(['en', 'si']);
  }

  private trigger: Subject<any> = new Subject();
  
  public webcamImage!: WebcamImage;
  public pictureTaken = false;

  private nextWebcam: Subject<any> = new Subject();
  
  sysImage = '';
    
  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
 
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
    //console.info('got webcam image', this.sysImage);
    this.pictureTaken = true;
  }

  useImage() {
    this.dialog.close(this.webcamImage);
  }

  cancelImage() {
    this.dialog.close(null);
  }

  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

}
