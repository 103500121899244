<div class="current_month_header"  cdkDrag cdkDragRootElement=".dashboard_widget" cdkDragHandle>
	<div class="prev_month">
		<fa-icon [icon]="['fad', 'chevron-circle-left']" (click)="prevMonth()"></fa-icon>
	</div>
	<div class="current_month">
		{{months[current_month]}}, {{current_year}}
	</div>
	<div class="close">
		<fa-icon class="icon_medium" [icon]="['fad', 'times']" style="float:right;padding-top:12px;" (click)="remove(id)"></fa-icon>
	</div>
	<div class="divider_header"></div>
	<div class="next_month">
		<fa-icon [icon]="['fad', 'chevron-circle-right']" (click)="nextMonth()" style="padding-top:2px;"></fa-icon>
	</div>
</div>

<div class="selected_month_header" style="display:none">
	{{selected_month_start}} - {{selected_month_end}}
</div>

<div class="calendar_inner">
	<div class="calendar_day_legend"><div class="day">P</div></div>
	<div class="calendar_day_legend"><div class="day">T</div></div>
	<div class="calendar_day_legend"><div class="day">S</div></div>
	<div class="calendar_day_legend"><div class="day">Č</div></div> 
	<div class="calendar_day_legend"><div class="day">P</div></div>
	<div class="calendar_day_legend"><div class="day">S</div></div>
	<div class="calendar_day_legend"><div class="day">N</div></div>

	<div class="calendar_day" *ngFor='let day of calendar?.data'>
		<div class="day" [ngClass]="{'blank': day.date == null}" [attr.date]="day.date" (click)="selectDate(day.date, day.activity)">
			<div [ngClass]="{'blank': day.date == null}">
				{{day.day}}			
				<div>
					<ng-container *ngIf="day.activity!=null">
						<ng-container *ngIf="(day.activity).length > 4">
							<span style="font-size:14px;display:inline-block; padding-top:6px;">+</span>
						</ng-container>
						<ng-container *ngIf="(day.activity).length < 5">				
							<ng-container class="calendar_day" *ngFor='let activity of day?.activity'>
								<span style="color:{{activity}}">.</span>
							</ng-container>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
