/* Angular */
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

/* Common Functions */
import { CommonFunctions } from '../../services/common.functions';

/* Services */
import { NavigationService } from '../../services/navigation.service';
import { MembersettingsService } from '../../services/membersettings.service';
import { DataService } from '../../services/data.service';
import { EmailService } from '../../services/mail.service';

/* Translate.Service */
import { TranslateService } from '@ngx-translate/core';

/* Editor */
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import sanitizeHtml from 'sanitize-html';

/* Components */
import { AssignUserGroupComponent } from '../../activities/activity/assign-user-group/assign-user-group.component';
import { ModalComponent } from '../../modal/modal.component';
import { SelectCustomerComponent } from '../../customers/select-customer/select-customer.component';
import { ActivitySelectComponent } from '../../activities/activity-select/activity-select.component';

/*
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { ModalComponent } from '../../modal/modal.component';
import { faSlider } from '@fortawesome/pro-solid-svg-icons';
import sanitizeHtml from 'sanitize-html';
*/

@Component({
  selector: 'app-mail-view',
  templateUrl: './mail-view.component.html',
  styleUrls: ['./mail-view.component.css']
})
export class MailViewComponent implements OnInit {

  public entity;
  public selectedTab = "content";
  public edit = false;
  public attachment = {};
  public activityContent = {};
	
  public item = [];
  public activity_assigned_template;
  public default_category;
  public default_priority_id;
  
  public assiginToNewActivity = false;
  public assiginToExistingActivity = false;

  public activity;
  public activity_id;

  public categories = [];
  public customer = [];
  public customer_name;
  public customer_id;
  public members: any = [];
	public member_groups: any = [];
  public eTalkAttachments: any = [];

  public assigned_users_names_entity = [];
  public assigned_group_names_entity = [];

  //eTalkForm: UntypedFormGroup;  

  constructor(
    private data: DataService,
		public dialog: MatDialog,
    public modalDialog: MatDialog,
		public translate: TranslateService,
    public assignUserGroupDialog: MatDialog,
    public dialogRef: MatDialogRef<MailViewComponent>,
    public CommonFunctions: CommonFunctions,
    private EmailService: EmailService
  ) { 
    //this.eTalkForm = this.createFormGroup();
  }
  
  switchTab(value) {
		this.selectedTab = value;
	}   

  /*
  download(filename, original_filename) {   
    this.data.downloadDocument(filename, original_filename);   
  }
  */

  downloadFile(fileName,fileContent): void {
    // Pretvori vsebino v Blob objekt
    const blob = new Blob([fileContent], { type: 'application/octet-stream' });

    // Ustvari URL za Blob
    const url = URL.createObjectURL(blob);

    // Ustvari <a> element za prenos
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName; // Nastavi ime datoteke

    // Simulira klik za prenos
    a.click();

    // Po prenosu počisti URL
    URL.revokeObjectURL(url);
  }

  /**************************************/
	/* Common Functions */
	getInitials(str) {
		var FirstName = '';
		var LastName = '';

		if (str != undefined) {      
		FirstName = str.split(' ')[0];
		LastName = str.split(' ').reduceRight(a => a);
		}

		if (FirstName === LastName) { return FirstName.trim().substring(0, 2).toUpperCase(); }
		return [FirstName, LastName].map(name => name[0]).join('').toUpperCase();
	}


	ShowUserInicials(users) {
		var updated_users = [];
		if (users) {
			if (typeof users === 'string' || users instanceof String) {
				users = users.split(',');
				for (var i=0; i<users.length; i++) {
					var initials = this.getInitials(users[i]);
					var helper_users: any = {};        
					helper_users.initials = this.getInitials(users[i]);
					helper_users.fullname = users[i];
					updated_users.push(helper_users);
				}
			}
		}    
		return updated_users;
	}


/************************************************************/
	/* Member or Group Assignation */
	assignUserGroup(entity = null) {
		

		var assignUserGroupDialog = this.assignUserGroupDialog.open(AssignUserGroupComponent, {
			'width' : '480px',
			panelClass: 'user_group_selector',
			data: { membersSelected: this.assigned_users_names_entity, groupsSelected: this.assigned_group_names_entity, members: this.members, groups: this.member_groups, activity: null }
		});    
		
		assignUserGroupDialog.afterClosed().subscribe(result => {   
			console.log(result);
						
			var assigned_users_names = [];
			for (var i=0; i<this.members.length; i++) {
				//console.log(this.members[i].entity + " | " + assigned_users_names_entity.indexOf(this.members[i].entity));
				if (this.assigned_users_names_entity.indexOf(this.members[i].entity) > -1) assigned_users_names.push(this.members[i].username);          
			}
			//console.log(assigned_users_names);

			var assigned_group_names = [];
			//console.log(this.members);
			for (var i=0; i<this.member_groups.length; i++) {				
				if (this.assigned_group_names_entity.indexOf(this.member_groups[i].id) > -1) assigned_group_names.push(this.member_groups[i].member_group);          
			}
			
			if (this.customer['client_administrators'] == undefined) this.customer['client_administrators'] = [];
			this.customer['client_administrators']['assigned_users_names'] = assigned_users_names.join(',');
			this.customer['client_administrators']['assigned_group_names'] = assigned_group_names.join(',');

			this.customer['client_administrators']['assigned_users_names_id'] = result.membersSelected.join(',');
			this.customer['client_administrators']['assigned_group_names_id'] = result.groupsSelected.join(',');

			console.log(this.customer);

		});
		
		
	}


  /**************************/
  /* Quill */
  changedEditor(event: EditorChangeContent | EditorChangeSelection) {        
    if (event['event'] == 'text-change') { this.item['body'] = event['html']; }
  }







  cleanHTML(html) {    

    /*
    allowedTags: [ 'b', 'i', 'em', 'strong', 'a' ],
    allowedAttributes: {
      'a': [ 'href' ]
    },
    allowedTags: [
        "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
        "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
        "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
        "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
        "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
        "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
        "col", "colgroup"
      ],
    */

    return sanitizeHtml(html, {
      allowedTags: [ 'b', 'i', 'em', 'strong', 'a', 'hr', 'br', "h1", "h2", "h3", "h4", "h5", "h6", 'p', 'div', 'small', 'img' ],
      disallowedTagsMode: 'discard',
      allowedAttributes: {
        a: [ 'href', 'name', 'target' ],
        img: [ 'src', 'srcset', 'alt', 'title', 'width', 'height', 'loading' ]
      },      
      selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],      
      allowedSchemes: [ 'http', 'https', 'ftp', 'mailto', 'tel' ],
      allowedSchemesByTag: {},
      allowedSchemesAppliedToAttributes: [ 'href', 'src', 'cite' ],
      allowProtocolRelative: true,
      enforceHtmlBoundary: false
    });

  } 

  
  created(event) {    
    /*
    this.data.getETalk({ 'entity' : this.entity }).subscribe(
			(res: Response) => {
				this.item = (<any>res).rows[0];
        this.item['body'] = this.cleanHTML(this.item['body']);
        this.data.getDocuments({'items' : 10000, 'order' : 'doc_title', 'orderby' : 'asc', search: null, 'field' : 'etalk', 'value' : this.item['entity']}).subscribe(
          (res: Response) => {
            this.eTalkAttachments = (<any>res).rows;            
          }
        );
			}
		);
    */    
  }
  

  public modules_full = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
  
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
  
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
  
      ['clean'],                                         // remove formatting button
  
      ['link', 'image', 'video']                         // link and image, video
    ]
  };

  public modules_extended = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons      
  
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
  
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
  
      ['clean'],                                         // remove formatting button
  
      ['link']                                           // link and image, video
    ]
  };

  public modules_light = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],     
    ]
  };

  /************************************/
/*
  delete(entity) {
    this.data.deleteETalk({ 'entity' : this.entity }).subscribe(
			(res: Response) => {		
        this.dialogRef.close();
			}
		);
  }

  save() {
    this.data.updateETalk({ data : { 'entity' : this.entity, 'content' : this.item['body'] } }).subscribe(
			(res: Response) => {	
        //console.log(res);
        this.dialogRef.close(); 
			}
		);    
  }
*/
  /************************************/

  choose_category(value) {    
    this.default_category = value.target.value;
  }

  assignToActivity() {    
    var data = {};
    data['entity_eTalk'] = this.item['entity'];
    data['entity_activity'] = this.activity_id;    
		data['title'] = this.item['subject'];
		data['subtitle'] = this.item['body'];
    data['customer'] = this.customer_id;
		data['parent_id'] = null;				
    data['tasks'] = '{}';	
		data['default_activity_id'] = parseInt(this.default_category);
    data['default_priority_id'] = parseInt(this.default_priority_id);
    data['client_administrators_member_id'] = (this.assigned_users_names_entity).join(',');
    data['client_administrators_group_id'] = (this.assigned_group_names_entity).join(',');
    data['assiginToNewActivity'] = this.assiginToNewActivity;
    data['assiginToExistingActivity'] = this.assiginToExistingActivity;

    if (data['assiginToNewActivity']) {      
      if (data['default_activity_id'] == '' || data['default_activity_id'] == null) {
        var content = <any>("You need to choose at least activity type to proceed!");
        var modalDialog = this.modalDialog.open(ModalComponent, { data: { content: content, yes: null , no: null, cancel: 'Ok' } });
        modalDialog.afterClosed().subscribe(result => {      
          if (result.yes != undefined) { return true; } else { return true; }
        });
      }
    } else if (data['assiginToExistingActivity']) {      
      if (data['entity_activity'] == '' || data['entity_activity'] == null) {        
        var content = <any>("You need to choose activity to attach to eTallk!");
        var modalDialog = this.modalDialog.open(ModalComponent, { data: { content: content, yes: null , no: null, cancel: 'Ok' } });
        modalDialog.afterClosed().subscribe(result => {      
          if (result.yes != undefined) { return true; } else { return true; }
        });
      }
    }
    
    this.data.eTalkToActivity( data ).subscribe( (res: Response) => { this.dialogRef.close(); } );
  }

  selectActivity() {
		var ActivitySelectDialog = this.dialog.open(ActivitySelectComponent, {});
		ActivitySelectDialog.afterClosed().subscribe(result => {			      
			if (!isNaN(result.entity)) {        
				this.activity_id = result.entity;				
        this.item['activity_id'] = result.entity;     
        this.saveEmail();       
        this.assiginToExistingActivity = false;
			}
		});       
	}

  selectCustomer() {
		var selectCustomerDialog = this.dialog.open(SelectCustomerComponent, {});
		selectCustomerDialog.afterClosed().subscribe(result => {			
			if (!isNaN(result.entity)) {  
				this.customer_id = result.entity;
				this.customer_name = result.customer;
			}
		});        
	}

  cancelConnection() {
    this.assiginToNewActivity = false;
    this.assiginToExistingActivity = false;
    this.item['activity_id'] = null;
    this.saveEmail();
  }

  getEmail(entity = this.entity) {
    this.data.getEmail({ 'entity' : entity }).subscribe(
			(res: Response) => {
				this.item = (<any>res).rows[0];        
        this.attachment = JSON.parse(this.item['attachment']);                        
        this.getActivity(this.item['activity_id']);
			}
		);
  }

  deleteEmailQueue() {
    var content = <any>("Do you want to delete email log record? If email have status 'scheduled', this email will not be sended to recipient address.");
    var modalDialog = this.modalDialog.open(ModalComponent, { data: { content: content, yes: 'Delete' , no: null, cancel: 'Cancel' } });
    modalDialog.afterClosed().subscribe(result => {      
      if (result.yes != undefined) { 
        this.data.deleteEmailQueue({ 'entity':this.item['entity'] }).subscribe(	(res: Response) => { this.EmailService.update('scheduled'); this.dialogRef.close(); });        
      }
    });    
  }

  saveEmail() {
    //console.log({ 'entity':this.item['entity'], 'recipient_email':this.item['recipient_email'], 'subject':this.item['subject'], 'body':this.item['body'], 'attachment':this.item['attachment'], 'activity_id':this.item['activity_id'], 'status':this.item['status'] });    
    this.data.saveEmailQueue({ 'entity':this.item['entity'], 'recipient_email':this.item['recipient_email'], 'subject':this.item['subject'], 'body':this.item['body'], 'attachment':this.item['attachment'], 'activity_id':this.item['activity_id'], 'status':this.item['status'] }).subscribe(
			(res: Response) => {
				//this.item = (<any>res).rows[0];
        //this.attachment = JSON.parse(this.item['attachment']);       
        this.getActivity(this.item['activity_id']);
			}
		);    
  }

  getActivity(entity) {
    this.data.getActivity({id:entity}).subscribe(
      (res: Response) => {        
        this.activityContent = (<any>res).rows[0];                
      }
    );
  }

  ngOnInit(): void {    
    this.customer['client_administrators'] = null;
    this.customer['allowed_emails'] = null;
    this.customer['default_priority_id'] = null;

    this.default_category = null;
    
    this.data.getActivityTypes({}).subscribe( (res: Response) => { this.categories = (<any>res).rows; });
    this.data.getMembers({items : 10000, page : 0, order : 't1.username', orderby : 'asc', search : null, field: 'enabled', value: 1}).subscribe( (res: Response) => { this.members = (<any>res).rows; });			  
    this.data.getMemberGroups({items : 10000, page : 0, order : 't1.member_group', orderby : 'asc', search : null}).subscribe( (res: Response) => { this.member_groups = (<any>res).rows; });
    
    this.getEmail(this.entity);    
  }

}
