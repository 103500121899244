/* Angular */
import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* Translagte service */
import { TranslateService } from '@ngx-translate/core';

/* modal YES/NO window */
import { ModalComponent } from '../../modal/modal.component';

/* Common Functions */
import { CommonFunctions } from '../../services/common.functions';

@Component({
  selector: 'app-grid-body',
  templateUrl: './grid-body.component.html',
  styleUrl: './grid-body.component.css'
})
export class GridBodyComponent {

  @Input() component: any;

  @Input() order: any;
  @Input() orderby: any;
  @Input() items: any;
  @Input() tableConstructor: any;
  
  @Input() edit: Function;
  @Input() priceRange: Function; 

  @Input() updateParameters: Function;
  @Input() refreshData: Function;

  public lastSelected = -1;
  public itemsSelected = [];
  
  constructor(
	public translate: TranslateService,
	public dialog: MatDialog,
	public CommonFunctions: CommonFunctions
  ) {}

  /**************************************/
  /* Common Functions */
  getInitials(str) {
    var FirstName = '';
    var LastName = '';

    if (str != undefined) {      
      FirstName = str.split(' ')[0];
      LastName = str.split(' ').reduceRight(a => a);
    }

    if (FirstName === LastName) { return FirstName.trim().substring(0, 2).toUpperCase(); }
    return [FirstName, LastName].map(name => name[0]).join('').toUpperCase();
  }

  /**************************************/
  customSort(array: any[], property: string, sortType: string): any[] {
	return array
	  .map(item => {
		const value = item[property];
		const parts = value.split(' ');
		const lastWord = parts.pop();
		const firstWord = parseFloat(parts[0]) || null; // Pretvori prvo besedo v število, če je decimalno
  
		return {
		  ...item,
		  lastWord,
		  firstWord,
		  formattedValue: `${lastWord} ${parts.join(' ')}`,
		  originalValue: value, // Za ohranjanje originalnega niza
		};
	  })
	  .sort((a, b) => {
		switch (sortType) {
		  case 'asc_last':
			return a.lastWord.localeCompare(b.lastWord);
		  case 'desc_last':
			return b.lastWord.localeCompare(a.lastWord);
		  case 'asc_123':
			if (a.firstWord !== null && b.firstWord !== null) {
			  return a.firstWord - b.firstWord;
			}
			return a.firstWord === null ? 1 : -1;
		  default:
			throw new Error('Invalid sortType specified. Use "asc_last", "desc_last", or "asc_123".');
		}
	  })
	  .map(item => {
		// Premakni zadnjo besedo na začetek in jo ovij z <b> za "asc_last" in "desc_last"
		if (sortType === 'asc_last' || sortType === 'desc_last') {
		  const parts = item.originalValue.split(' ');
		  const lastWord = parts.pop();
		  item[property] = `<b>${lastWord}</b> ${parts.join(' ')}`;
		}
		return item;
	  });
  }

  changeOrder(order, notsortable = false) {
		if (!notsortable) {
			this.order = order;
			
			if (this.orderby == 'asc') {
				this.orderby = 'desc'
			} else if (this.orderby == 'desc') {
				this.orderby = 'asc_last'
			} else if (this.orderby == 'asc_last') {
				this.orderby = 'desc_last'
			} else if (this.orderby == 'desc_last') {
				this.orderby = 'asc_123'
				} else if (this.orderby == 'asc_123') {
				this.orderby = 'asc'
			} else {
				this.orderby = 'desc'
			}
			
			//this.orderby = this.orderby == 'asc' ? 'desc' : 'asc';			
			localStorage.setItem(this.component + '_tableOrder', this.order); 
			localStorage.setItem(this.component + '_tableOrderBy', this.orderby); 
			this.updateParameters();
			this.refreshData();
		} else {
			var content = this.translate.instant("Its not possible to sort data based on this column");
			var modalDialog = this.dialog.open(ModalComponent, {
			data: { content: content, yes: null , no: null, cancel: this.translate.instant("OK") }
			});
		}
	}	

  removeItemOnce(arr, value) {
		var index = arr.indexOf(value);
		if (index > -1) { arr.splice(index, 1); }
		return arr;
	}

  preEdit(entity, index, $event) {
		
		if ($event != undefined) {
			if ($event.shiftKey) {			
				this.itemsSelected = [];			
				if (this.lastSelected == -1) {
					this.items[index]['selected'] = this.items[index]['selected'] == true ? false : true;	
					this.itemsSelected.push(this.items[index]);
				} else {
					for (let i=0; i<(this.items).length; i++) {
						this.items[i]['selected'] = false;						
					}
					if (index > this.lastSelected) {
						for (let i=this.lastSelected; i<=index; i++) {
							this.items[i]['selected'] = true;
							this.itemsSelected.push(this.items[i]);
						}
					} else if (index < this.lastSelected) {
						for (let i=index; i<=this.lastSelected; i++) {
							this.items[i]['selected'] = true;
							this.itemsSelected.push(this.items[i]);
						}
					} else {
						this.items[index]['selected'] = this.items[index]['selected'] == true ? false : true;	
					}				
				}	
				
				this.lastSelected = index;

				if (window.getSelection) {
					if (window.getSelection().empty) {  // Chrome
						window.getSelection().empty();
					} else if (window.getSelection().removeAllRanges) {  // Firefox
						window.getSelection().removeAllRanges();
					}
				}
				return false;
			} if ($event.altKey) {
				if (this.items[index]['selected'] == true) {
					this.items[index]['selected'] = false;
					this.removeItemOnce(this.itemsSelected, this.items[index]);
				} else {
					this.items[index]['selected'] = true;
					this.itemsSelected.push(this.items[index]);
				}			
				this.lastSelected = index;
				return false;
			} else {
				this.edit(entity)
			}
		} else {
			this.edit(entity)
		}
		return true;
	}

}
 