/* Angular */
import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

/* Material */
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/*
import { ActivityComponent } from '../activities/activity/activity.component';
import { CustomerComponent } from '../customers/customer/customer.component';
import { TimetrackingEditComponent } from '../timetracking/timetracking-edit/timetracking-edit.component'
import { KnowledgeEditComponent } from '../knowledge/knowledge-edit/knowledge-edit.component'
import {ErrorStateMatcher} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { Action } from 'rxjs/internal/scheduler/Action';
*/

/* Services */
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../services/navigation.service';
import { MembersettingsService } from '../services/membersettings.service';
import { SpeechToTextService } from '../services/speech-to-text.service';

/* Common Functions & Variables */
import { CommonFunctions } from '../services/common.functions';
import { GlobalVariables } from '../services/data.globals';

import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  constructor(
  	private data: DataService,    
    public translate: TranslateService,
    public CommonFunctions: CommonFunctions,
    public NavigationService: NavigationService,
    public MembersettingsService: MembersettingsService,
    private SpeechToTextService: SpeechToTextService,
    public dialog: MatDialog
  ) {
    translate.addLangs(['en', 'si']);
  }

  public component = 'search';  
  public lastFunctionCallAt = Date.now(); 

  public search_term = "";
  public searchInterval;
  public bookmarks = [];
  public apps = { activity: true, customers: true, timetracking: true, archive: true };
  public memberSettingsGlobal;

  public searchResults = [];
  public numberOfItems = 10;

  public machineLearning = false;

  showFileIcon(ext) {
    if (ext == 'doc' || ext == 'docx') {
      return "file-word";
    } else if (ext == 'xls' || ext == 'xlsx') {
      return "file-spreadsheet";
    } else if (ext == 'zip' || ext == 'gz' || ext == 'rar') {
      return "file-archive";
    } else if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'svg' || ext == 'tif' || ext == 'gif') {
      return "file-image";
    } else if (ext == 'mov' || ext == 'mp4') {
      return "file-video";
    } else if (ext == 'ppt') {
      return "file-powerpoint";
    } else if (ext == 'pdf') {
      return "file-pdf";
    } else {
      return "file";
    }
  }

  icon(icon) {
    return this.CommonFunctions.icon(icon);
  }

  /*******************************/

  toggleApps(app) {
    this.apps[app] = this.apps[app] == false ? true : false; 
    console.log(this.apps[app]);
    this.MembersettingsService.setMemberSettings("searchApps", JSON.stringify(this.apps));
    this.searchExecute();
  }

  /*******************************/

  bookmark() {   
    let helper = this.search_term; 
    
    if ((this.bookmarks).length > 10) {
      let content = this.translate.instant("You are limited to up to 10 serch bookmarks.");
      let modalDialog = this.dialog.open(ModalComponent, {
        data: { content: content, yes: this.translate.instant("OK") , no: null, cancel: null } 
      });
      return false;
    }

    if (this.search_term == "" || (this.searchResults).length < 1) {
      let content = this.translate.instant("You can not bookmark search with no results.");
      let modalDialog = this.dialog.open(ModalComponent, {
        data: { content: content, yes: this.translate.instant("OK") , no: null, cancel: null } 
      });
      return false;
    }

    if (this.bookmarks.some(bookmarks => bookmarks.name === helper)) {
      let content = this.translate.instant("You can not bookmark search term twice.");
      let modalDialog = this.dialog.open(ModalComponent, {
        data: { content: content, yes: this.translate.instant("OK") , no: null, cancel: null } 
      });
      return false;
    }

    if (helper == "") helper = 'No name';
    this.bookmarks.push({
      name: helper,
      items: this.searchResults
    });  
    localStorage.setItem('globalBookmarks', JSON.stringify(this.bookmarks));
    return true;
  }

  useBookmark(index) {
    this.searchResults = this.bookmarks[index]['items'];
    this.search_term = this.bookmarks[index]['name'];
  }

  removeBookmark(searchTerm) {
    this.bookmarks = this.bookmarks.filter(bookmark => bookmark.name !== searchTerm);
    localStorage.setItem('globalBookmarks', JSON.stringify(this.bookmarks));
  }

  /*******************************/

  navigate(type, action, component, data) {
    this.NavigationService.navigate(type, action, component, data);
  }

  close() {
    document.getElementById("globalSearchIcon_close").click();
  }

  /*******************************/

  checkForMachineLearning(value) {
    if ((this.searchResults).length == 0 && this.machineLearning) {
      let content = this.translate.instant("<b>Search engine did not find any results, did I understand your search term properly?</b><br /><br />If you want, you can type it here and re-run search again.");
      let modalDialog = this.dialog.open(ModalComponent, {
        data: { inputText: value, content: content, yes: this.translate.instant("Search again") , no: null, cancel: this.translate.instant("Cancel") } 
      });

      modalDialog.afterClosed().subscribe(result => {          
        if (result['yes']!=undefined) {
          this.machineLearning = false;          

          //this.SpeechToTextService.learnSearchTerms(value, result['value']);
          this.search_term = result['value'];
          
          if (result['value'].length > 0) this.searchExecute();
        }
      });
    }
  }

  /*******************************/

  removeItem(index) {    
    this.searchResults.splice(index, 1);
  }

  /*
  download(filename, original_filename) {   
    this.data.downloadDocument(filename, original_filename);   
  }
  */

  displayWeightConvertor(weight) {
    return weight;
    return Math.floor(weight*1000)/10;
  }

  setResults(value) {
    this.numberOfItems = value;
  }

  searchExecute(value = this.search_term) {
    
    clearInterval(this.searchInterval);

    let numberOfSearchService = 3;
    this.searchResults = [];    
    let entityCounter = 0;
    
    if (value.length > 3) {

      /************************************************************/
      /************************************************************/
      /* Activities */
      if (this.apps['activity']) {
        var activity_parameters = { 'page' : 0, 'items' : this.numberOfItems, 'order' : 't1.entity', 'orderby' : 'desc', search: value };        
        this.data.getActivities(activity_parameters).subscribe(
          (res: Response) => {
            let items = (<any>res).rows;  	       
            
            let weight = 0;
            let weight_customer = 0;
            let weight_title = 0;
            let weight_subTitle = 0;

            for (let i=0; i<items.length; i++) {
              entityCounter++;
              weight_title = this.displayWeightConvertor(this.CommonFunctions.calculateSimilarity(value, this.CommonFunctions.stripHtml(items[i]['title_raw']))['score']);
              weight_subTitle = this.displayWeightConvertor(this.CommonFunctions.calculateSimilarity(value, this.CommonFunctions.stripHtml(items[i]['sub_title']))['score']);
              weight_customer = this.displayWeightConvertor(this.CommonFunctions.calculateSimilarity(value, this.CommonFunctions.stripHtml(items[i]['customer']))['score']);
              
              weight_customer = weight_customer * 2;
              weight_title = weight_title * 10;
              weight_subTitle = weight_subTitle * 5;

              weight = (weight_customer + weight_title + weight_subTitle) / 17 * 2;
              if (weight > 100) weight = 100;

              let weightIcon;
              if (weight > 75) {
                weightIcon = "fad,signal-bars";
              } else if (weight > 50) {
                weightIcon = "fad,signal-bars-good";
              } else if (weight > 20) {
                weightIcon = "fad,signal-bars-fair";
              } else {
                weightIcon = "fad,signal-bars-weak";
              } 

              this.searchResults.push({
                entity: entityCounter,                
                component: 'activity',
                customer: items[i]['customer'],
                customerColor: items[i]['customer_hex'],
                category: items[i]['message_type_name'],
                categoryColor: items[i]['message_type_hex'],
                type: "popup",
                action: "",
                actionData: items[i]['entity'],
                icon: "fad,list",
                iconColor: items[i]['message_type_hex'],
                dockable: true,
                title: this.CommonFunctions.stripHtml(items[i]['title_raw']),
                subTitle: this.CommonFunctions.stripHtml(items[i]['sub_title']),
                resultNumber: entityCounter,
                numberOfResults: (<any>res).total_rows,
                index: null,
                weight: weight,
                weightIcon: weightIcon
              });
              
            }  
            
            this.searchResults.sort((a, b) => b.weight - a.weight);
            numberOfSearchService--;
            if (numberOfSearchService == 0) {
              this.checkForMachineLearning(value);
            }
          }
        );
      }

      /************************************************************/
      /************************************************************/
      /* Customers */      
      if (this.apps['customers']) {
        var customer_parameters = { 'page' : 0, 'items' : this.numberOfItems, 'order' : 'company', 'orderby' : 'asc', search: value };
        this.data.getCustomers(customer_parameters).subscribe(
          (res: Response) => {
            let items = (<any>res).rows; 
            
            let weight_address = 0;
            let weight_company = 0;
            let weight_email = 0;
            let weight_vat = 0;
            let weight_city = 0;
            let weight = 0;

            for (let i=0; i<items.length; i++) {
              entityCounter++;

              weight_address = this.displayWeightConvertor(this.CommonFunctions.calculateSimilarity(value, this.CommonFunctions.stripHtml(items[i]['address1']))['score']);
              weight_company = this.displayWeightConvertor(this.CommonFunctions.calculateSimilarity(value, this.CommonFunctions.stripHtml(items[i]['company']))['score']);
              weight_email = this.displayWeightConvertor(this.CommonFunctions.calculateSimilarity(value, this.CommonFunctions.stripHtml(items[i]['email']))['score']);
              weight_vat = this.displayWeightConvertor(this.CommonFunctions.calculateSimilarity(value, this.CommonFunctions.stripHtml(items[i]['vat']))['score']);
              weight_city = this.displayWeightConvertor(this.CommonFunctions.calculateSimilarity(value, this.CommonFunctions.stripHtml(items[i]['city']))['score']);
                          
              weight_address = weight_address * 2;
              weight_company = weight_company * 100;
              weight_email = weight_email * 2;
              weight_vat = weight_vat * 5;
              weight_city = weight_city * 2;
                          
              weight = (weight_address + weight_company + weight_email + weight_vat + weight_city) / 111 * 3;
              if (weight > 100) weight = 100;
                
              let weightIcon = "";
              if (weight > 75) {
                weightIcon = "fad,signal-bars";
              } else if (weight > 50) {
                weightIcon = "fad,signal-bars-good";
              } else if (weight > 20) {
                weightIcon = "fad,signal-bars-fair";
              } else {
                weightIcon = "fad,signal-bars-weak";
              } 

              this.searchResults.push({
                entity: entityCounter,                
                component: 'customers',
                customer: null,
                customerColor: null,
                category: items[i]['status_description'],
                categoryColor: items[i]['status_color'],
                type: "popup",
                action: "",
                actionData: items[i]['entity'],
                icon: "fad,users",
                iconColor: items[i]['message_type_hex'],
                dockable: true,
                title: items[i]['company'],
                subTitle: items[i]['address1'] + ", " + items[i]['city'] + "<br />" + items[i]['phone'] + "<br />" + items[i]['email'],
                resultNumber: entityCounter,
                numberOfResults: (<any>res).total_rows,
                index: null,
                weight: weight,
                weightIcon: weightIcon
              });            
            }            
            this.searchResults.sort((a, b) => b.weight - a.weight);
            numberOfSearchService--;
            if (numberOfSearchService == 0) {
              this.checkForMachineLearning(value);
            }
          }
        );
      }
      
      /************************************************************/
      /************************************************************/
      /* Time Tracking */
      if (this.apps['archive']) {

        //console.log({'items' : 100, 'order' : 'doc_title', 'orderby' : 'asc', search: value, 'field' : null, 'value' : null});        
        this.data.getDocuments({'items' : this.numberOfItems, 'order' : 'doc_title', 'orderby' : 'asc', search: value, 'field' : null, 'value' : null}).subscribe(
          (res: Response) => {
            let items = (<any>res).rows; 
            console.log(items);   
            
            let weight_title = 0;
            let weight_original_filename = 0;
            let weight_keywords = 0;            
            let weight = 0;

            for (let i=0; i<items.length; i++) {
              entityCounter++;

              weight_title = this.displayWeightConvertor(this.CommonFunctions.calculateSimilarity(value, this.CommonFunctions.stripHtml(items[i]['title']))['score']);
              weight_original_filename = this.displayWeightConvertor(this.CommonFunctions.calculateSimilarity(value, this.CommonFunctions.stripHtml(items[i]['original_filename']))['score']);
              weight_keywords = this.displayWeightConvertor(this.CommonFunctions.calculateSimilarity(value, this.CommonFunctions.stripHtml(items[i]['customer']))['score']);
                          
              weight_title = weight_title * 10;
              weight_original_filename = weight_original_filename * 10;
              weight_keywords = weight_keywords * 10;
              
              weight = (weight_title + weight_original_filename + weight_keywords) / 3;
              if (weight > 100) weight = 100;
                
              let weightIcon = "";
              if (weight > 75) {
                weightIcon = "fad,signal-bars";
              } else if (weight > 50) {
                weightIcon = "fad,signal-bars-good";
              } else if (weight > 20) {
                weightIcon = "fad,signal-bars-fair";
              } else {
                weightIcon = "fad,signal-bars-weak";
              } 

              this.searchResults.push({
                entity: entityCounter,                
                component: 'document-center',
                customer: items[i]['customer'],
                customerColor: null,
                category: null,
                categoryColor: null,
                type: "localFunction",
                action: "downloadFileByEntity",
                actionData: items[i]['entity'],
                icon: "fad,box-archive",
                iconColor: null,
                dockable: true,
                title: this.CommonFunctions.stripHtml(items[i]['original_filename']),
                subTitle: this.CommonFunctions.stripHtml(items[i]['title']),
                resultNumber: entityCounter,
                numberOfResults: (<any>res).total_rows,
                index: null,
                weight: weight,
                weightIcon: weightIcon
              });
            }

            this.searchResults.sort((a, b) => b.weight - a.weight);
            numberOfSearchService--;
            if (numberOfSearchService == 0) {
              this.checkForMachineLearning(value);
            }

          }
        );
        
      }

      /************************************************************/
      /************************************************************/
      /* Time Tracking */
      if (this.apps['timetracking']) {
        var timetracking_parameters = { 'page' : 0, 'items' : this.numberOfItems, 'order' : 't1.entity', 'orderby' : 'asc', search: value };
        this.data.getTimeTrackings(timetracking_parameters).subscribe(
          (res: Response) => {
            let items = (<any>res).rows;
            console.log(items);

            let weight_activity_title = 0;
            let weight_comment = 0;
            let weight_customer = 0;
            let weight = 0;

            for (let i=0; i<items.length; i++) {
              entityCounter++;

              weight_activity_title = this.displayWeightConvertor(this.CommonFunctions.calculateSimilarity(value, this.CommonFunctions.stripHtml(items[i]['activity_title']))['score']);
              weight_comment = this.displayWeightConvertor(this.CommonFunctions.calculateSimilarity(value, this.CommonFunctions.stripHtml(items[i]['comment']))['score']);
              weight_customer = this.displayWeightConvertor(this.CommonFunctions.calculateSimilarity(value, this.CommonFunctions.stripHtml(items[i]['customer']))['score']);
                          
              weight_activity_title = weight_activity_title * 10;
              weight_comment = weight_comment * 2;
              weight_customer = weight_customer * 5;
              
              weight = (weight_activity_title + weight_comment + weight_customer) / 17;
              if (weight > 100) weight = 100;
                
              let weightIcon = "";
              if (weight > 75) {
                weightIcon = "fad,signal-bars";
              } else if (weight > 50) {
                weightIcon = "fad,signal-bars-good";
              } else if (weight > 20) {
                weightIcon = "fad,signal-bars-fair";
              } else {
                weightIcon = "fad,signal-bars-weak";
              } 

              this.searchResults.push({
                entity: entityCounter,                
                component: 'timetracking',
                customer: items[i]['customer'],
                customerColor: null,
                category: null,
                categoryColor: null,
                type: "popup",
                action: "",
                actionData: items[i]['entity'],
                icon: "fad,clock",
                iconColor: null,
                dockable: true,
                title: this.CommonFunctions.stripHtml(items[i]['activity_title']),
                subTitle: items[i]['date'] + "<br />" + this.CommonFunctions.stripHtml(items[i]['comment']),
                resultNumber: entityCounter,
                numberOfResults: (<any>res).total_rows,
                index: null,
                weight: weight,
                weightIcon: weightIcon
              });
            }
            this.searchResults.sort((a, b) => b.weight - a.weight);
            numberOfSearchService--;
            if (numberOfSearchService == 0) {
              this.checkForMachineLearning(value);
            }
          }
        );
      }
      
    }
  }

  search() {  	
    clearInterval(this.searchInterval);
    this.searchInterval = setInterval(() => {
      this.searchExecute();
    }, 500);
  }

  ngOnInit(): void {
    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);

    this.MembersettingsService.memberSettingsGlobal.subscribe(message => { 
      this.memberSettingsGlobal = message;       
      if (this.memberSettingsGlobal['searchApps']!=undefined) this.apps = JSON.parse(this.memberSettingsGlobal['searchApps']);
    });

    this.NavigationService.localFunction.subscribe(callLocalFunction => {      
			if (callLocalFunction[0] == this.component && callLocalFunction[1] == "localFunction" && this.lastFunctionCallAt < Date.now()) {
        if (callLocalFunction[2] == 'searchExecute') { this.machineLearning = true; } else { this.machineLearning = false; }
				this[callLocalFunction[2]](callLocalFunction[3]);      
				//this.NavigationService.prepareObserverForLocalFunction([]);        
				this.lastFunctionCallAt = Date.now()+500; //prevent calling function too fast by default 500mS between call
			}
		});

    if (localStorage.getItem('globalBookmarks')!=null) {
      this.bookmarks = JSON.parse(localStorage.getItem('globalBookmarks'));
    }

    /*
    this.timetracking_search = localStorage.getItem('timetracking_search') == 'false' ? false : true;
    this.activity_search = localStorage.getItem('activity_search') == 'false' ? false : true;
    this.customer_search = localStorage.getItem('customer_search') == 'false' ? false : true;
    this.document_search = localStorage.getItem('document_search') == 'false' ? false : true;
    this.knowledge_search = localStorage.getItem('knowledge_search') == 'false' ? false : true;
    */    
  }
}
