import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-widget-activities-charts-settings',
  templateUrl: './widget-activities-charts-settings.component.html',
  styleUrls: ['./widget-activities-charts-settings.component.css']
})
export class WidgetActivitiesChartsSettingsComponent implements OnInit {

  formData: UntypedFormGroup;

  constructor(
	  private data: DataService,
    public dialog: MatDialogRef<WidgetActivitiesChartsSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public injected_data,
    public translate: TranslateService
  ) { 
    this.formData = this.createFormGroup();
  }

  public categories = [];
  public settings = {title:'Widget title...',category:null,reference:'created',grouppedby:'this_month',featured:0,assigned:0,author:0,hidden:0,approvedbyme:0,approved:0,unapproved:0,removeold:0,chartType:'pie_area'};

  save() {
    var parameters = { data: Object.assign({}, this.formData.value) };

     /* get permissions */
     var categories = [];
     $('.multiselect_checkbox.categories .switch').each(function() {
       var rel = $(this).find('input[type=checkbox]').attr('rel');
       var checked = $(this).find('input[type=checkbox]').is(':checked');
       //var category = {'category_id':rel,'status':checked};
       var category = {'category_id':rel};
       if (checked == true) {
         categories.push(category);
       }
     });
     
     parameters.data.chartType = this.settings.chartType;
     parameters.data.categories = categories;
     this.dialog.close(parameters);

 };

 createFormGroup() {
  return new UntypedFormGroup({
    title: new UntypedFormControl(),
    categories: new UntypedFormControl(),
    reference: new UntypedFormControl(),
    grouppedby: new UntypedFormControl(),
    featured: new UntypedFormControl(),
    assigned: new UntypedFormControl(),
    author: new UntypedFormControl(),
    hidden: new UntypedFormControl(),
    approved: new UntypedFormControl(),
    approvedbyme: new UntypedFormControl(),
    unapproved: new UntypedFormControl(),
    created: new UntypedFormControl(),
    removeold: new UntypedFormControl()    
  });
}

  ngOnInit(): void {

    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);

    this.settings = this.injected_data;
    /* fix */
    //this.settings.order = (this.settings.order).replace('t3.entity, ', '');    

    this.data.getActivityTypes({}).subscribe(
      (res: Response) => {
        this.categories = (<any>res).rows;                

        if (this.settings['categories']) {
          for (var i=0; i<(this.categories).length; i++) {
            for (var j=0; j<(this.settings['categories']).length; j++) {
              if (this.settings['categories'][j].category_id == this.categories[i].entity) {
                this.categories[i]['selected'] = true;
              }             
            }
          }
        }
                
      }
    );

  }

}
 