import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { ActivitySelectComponent } from '../../activities/activity-select/activity-select.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivityComponent } from '../../activities/activity/activity.component';

@Component({
  selector: 'app-widget-calendar',
  templateUrl: './widget-calendar.component.html',
  styleUrls: ['./widget-calendar.component.css']
})
export class WidgetCalendarComponent implements OnInit {

  public calendar;
  public calendarConstructor = [];
  
  public selected_time_start = "15:30";
  public selected_time_end = "14:15";
  public selected_month_start = null;
  public selected_month_end = null;

  public current_month = null;
  public current_year = null;
  public datetime_from = null;
  public datetime_to = null;

  public months = [null, 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
  public hour_start = [];
  public hour_end = [];
  public minutes_start = [];
  public minutes_end = [];
  public minutes_available = [0,15,30,45];

  public time_start_defined = false;
  public time_end_defined = false;
  public time;
  public activities;

  @Input() id: string;

  constructor(
    private data: DataService,
    public Dashboard: DashboardComponent,
    public dialog: MatDialog,		
		//public dialogActivitySelectComponent_ref: MatDialogRef<ActivitySelectComponent>
  ) { }

  nextMonth() {
    this.current_month++
    if (this.current_month>12) {
      this.current_month = 1;
      this.current_year++;
    }    
    this.loadCalendar({ month : this.current_month, year : this.current_year });
  }

  prevMonth() {
    this.current_month--;
    if (this.current_month<1) {
      this.current_month = 12;
      this.current_year--;
    }
    this.loadCalendar({ month : this.current_month, year : this.current_year });
  }
  
  loadCalendar(parameters) {          
    this.data.getCalendar(parameters).subscribe(
      (res: Response) => {        
        this.calendar = (<any>res);
        
        /* Prepare activities to be published */
        this.activities = this.calendar;
        for (var i=0; i<this.activities.activities.rows.length; i++) {
          var start = (this.activities.activities.rows[i].message_start).split(' ');
          var end = this.activities.activities.rows[i].message_end.split(' ');
          
          for (var j=0; j<this.calendar.data.length; j++) {            
            if (new Date(this.calendar.data[j].date) >= new Date(start[0]) && new Date(this.calendar.data[j].date) <= new Date(end[0])) {
              if (this.calendar.data[j].activity == null) this.calendar.data[j].activity = [];
              this.calendar.data[j].activity.push(this.activities.activities.rows[i].message_type_hex);              
            }

          }
        }              
      }
    );
  }

  remove(id) {
    this.Dashboard.removeWidget(id);
  }

  selectDate(dayDate, activity_count) {   
    if (activity_count != null) {
      var data = { 'date_start': dayDate, 'date_end': dayDate, 'autosearch': true, 'calendar_restriction' : true };
      var ActivitySelectComponentDialog = this.dialog.open(ActivitySelectComponent, { data : data });
      ActivitySelectComponentDialog.afterClosed().subscribe(result => {
        this.loadCalendar({ month : this.current_month, year : this.current_year });
      });
    } else {
      this.add_activity();    
    }
  }

  add_activity() {		
		var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
		editDialog.componentInstance.entity = null;		
		editDialog.afterClosed().subscribe(result => {
      this.loadCalendar({ month : this.current_month, year : this.current_year });
    });		
  }

  ngOnInit(): void {
    var today = new Date();
    this.current_month = today.getMonth()+1;
    this.current_year = today.getFullYear();     
    var parameters = { day: null, month : (today.getMonth()+1), year : today.getFullYear() };
    this.loadCalendar(parameters);
  }

}
