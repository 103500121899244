import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from './data.service';


@Injectable({
    providedIn: 'root'
})

export class GlobalVariables {
    
    public static email: string = null;
    public static group: string = null;
    public static id: string = null;
    public static username: string = null;
    public static admin: string = null;   
    public static memberSettings = [];
    public static spinner = false;
    public static navigationCollapse = false;    

}