import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { GlobalVariables } from '../../services/data.globals';
import { CommonFunctions } from '../../services/common.functions';
import { Inject } from '@angular/core';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";

import { CustomerComponent } from '../../customers/customer/customer.component';
import { UserComponent } from '../../users/user/user.component';
import { NotesComponent } from '../../notes/notes.component';
import { SelectCustomerComponent } from '../../customers/select-customer/select-customer.component';
import { AssignCategoryComponent } from './assign-category/assign-category.component';
import { AssignUserGroupComponent } from './assign-user-group/assign-user-group.component';
import { AssignPriorityComponent } from './assign-priority/assign-priority.component';
import { ActivityFunctionsComponent } from './activity-functions/activity-functions.component';
import { AssignStatusComponent } from './assign-status/assign-status.component';
import { AssignDateTimeComponent } from './assign-date-time/assign-date-time.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TimetrackingEditComponent } from '../../timetracking/timetracking-edit/timetracking-edit.component'
import { ActivitySelectComponent } from '../../activities/activity-select/activity-select.component';
import { CommentComponent } from '../../comment/comment.component';
import { ModalComponent } from '../../modal/modal.component';
import { WebcamCaptureComponent } from './webcam-capture/webcam-capture.component';
import { ContactSelectComponent } from '../../customers/contact-select/contact-select.component';
import { DockService } from '../../services/dock.service';

/*import { faFilesMedical } from '@fortawesome/pro-solid-svg-icons';*/
import { jsPDF } from "jspdf";

import { HttpClient, HttpRequest } from '@angular/common/http';
import { UploadService } from '../../services/upload.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocumentEditComponent } from '../../document-center/document-edit/document-edit.component';

//import { emitDistinctChangesOnlyDefaultValue } from '@angular/compiler/src/core';
import { TranslateService } from '@ngx-translate/core';  //translate:START:END
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { DomSanitizer} from '@angular/platform-browser'
import { atcb_action, atcb_init } from 'add-to-calendar-button';
import { faArrowLeftRotate, faLeft } from '@fortawesome/pro-solid-svg-icons';

/* google map */
import { GoogleMapsModule } from '@angular/google-maps'

import * as echarts from 'echarts';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})

export class ActivityComponent implements OnInit {

  public component = "activity";
  
  public selectedTab = "summary";

  public lastScrollTop = 0;
  public direction = '';
  public commentsExpand = false;
  public isMobile = false;
  public category_id = null;
  public id = null;
  public entity;
  public addingActivity = false;
  private sub : any;
  public parameters: any = [];
  public activity: any = [];
  public activity_backup: any = [];
  public canEdit = false;
  public customers: any = [];
  public documents: any = [];
  public statuses: any = [];
  public activity_type = null;
  public value = null;
  public hours = null;
  public customer = null;
  public customer_id = null;
  public history: any = [];
  public TimeTracking: any = [];
  public Attachments: any = [];
  public activity_status_description = null;
  public activity_status_hex = null;
  public members: any = [];
  public member_groups: any = [];
  public categories: any = [];
  public author = null;
  public author_inicialke = null;
  public author_id = null;
  public addActivity_title = null;
  public addActivity_description = null;
  public sub_activity_edit = null;
  public sub_activity_assigned: any = [];    
  public allCommentsView = false;
  public allHistoryView = false;
  public allAttachmentView = false;
  public allTimeTrackingView = false;
  public commentsCount = "0 comments";
  public historyCount = "0 changes";
  public timetrackingCount = "0 records";
  public timetrackinglength = 0;
 
  public attachmentslength = 0;
  public comment = "";   
  public attachment = "";
  public comment_parent_object = null;
  public comment_parent_object_id = null;
  public comment_object = null;
  public comment_object_id = null;
  public assignation_request;
  public assigned_user_id = [];
  public Global_UserId;
  public show_history = true;
  public show_attachment = true;
  public extended_view = false;
  public timetracking_view = true;
  public attachment_view = true;
  public new_activity_show = false;
  public customer_change = false;
  
  public extended_view_left = false;
  public restriction_view = false;
  public subactivityCount = 0;
  public subactivityApproved = 0;
  public hideFinishedActivities = true;

  public DateOffset = false; 
  public notes_list;
  public editor = 'noeditor';

  public eTalk_email = null;
  public eTalk_cc = null;
  public eTalk_subject = null;
  public eTalk_content = null;
  public eTalk_footer = null;
  public etalk_advanced = false;
  public etalk = [];
  public etalk_send_view = false;
  public eTalkAttachments = null;
  public footer = "";
  public assigned_users_names_array = [];
  public assigned_users_array = []; 
  public assigned_users_names_hex_array = [];   
  public assigned_contacts = [];
  public activityPrefills = [];
  public storage = null;
  public add_popup = false;
  public commentsAttachments = null;
  public dropdown = false;
  
  public shortcuts = {};
  public grid = 2;
  public assigned_users_names = [];
  public assigned_group_names = [];
  public compactView = false;
  
  public allAssigneesView = false;
  public oldEntity = null;

  /* chart variables */
  public overallProgressText = "0";
  public chartData = {};
  private myChart: any = null;

  /* Task variables */
  public selectedGroup = "0"; //which group is selected
  public selectedGroupText = "n/a"; //selected group text
  public taskTeamplates = []; //available task templates
  public taskWizzardPopUp = false; //show/hide task vizzard
  public tasks_groupped = {}; //reformated structure of task (for grouped display)
  public taskGroup = [];
  public tasks_count = 0; //for all task
  public tasks_done = 0; //for all task
  public tasks_percent = "0";
  //public totalTasks = 0; //number of all tasks
  public hideFinishedTasks = true; //display or hide finished tasks
  public tasks_count_text = "0/0"; //display variablefor display finisked and all tasks
  public viewAddTask = false;
  public tasks = {}; //all task, no groupped
  public edit_task = false; //task in edit mode
  public tasksCount = 0; //number of all tasks
  public tasksDragAndDrop = {};
  public showTaskComments = false;
  public onlymine = false;
  public selectConditionTask = false;
  public taskForConditionalAssign = null;
  public tasksForConditionalAssign = [];
  public conditionalTasks = {};

  public activityWizzardPopup = false;
  public activityWizzards = [];
  public activeActivitiesWizzard = [];

  public chartInstance: any;
  public progressChart = true;


  public modules_full = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
  
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
  
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
  
      ['clean'],                                         // remove formatting button
  
      ['link', 'image', 'video']                         // link and image, video
    ]
  };

  public modules_extended = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons      
  
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
  
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
  
      ['clean'],                                         // remove formatting button
  
      ['link']                                           // link and image, video
    ]
  };

  public modules_light = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],     
    ]
  };

  public localInternalMenu = [
    { entity: "activity.camera", disabled: false, hide: false, exposed: false, component: "activity", title: "Take a Photo", type: "localFunction", action: "capturePhoto", actionData: null, icon: "fad,camera", iconColor: null, pinnable: true }, 
    { entity: "activity.microphone", disabled: false, hide: false, exposed: false, component: "activity", title: "Talk to Activity", type: "localFunction", action: null, actionData: null, icon: "fad,microphone", iconColor: null, pinnable: true },
    { entity: "splitter", disabled: false, hide: false, exposed: false, component: "activity", title: null, type: 'splitter', action: null, actionData: null, icon: null, iconColor: null, pinnable: false }, 

    { entity: "activity.timetracking", disabled: false, hide: false, exposed: false, component: "activity", title: "Time Tracking", type: "localFunction", action: "addTimeTracking", actionData: null, icon: "fad,stopwatch", iconColor: null, pinnable: true }, 
    { entity: "activity.tocalendar", disabled: false, hide: false, exposed: false, component: "activity", title: "Push to Calendar", type: "localFunction", action: "pushToCalendar", actionData: null, icon: "fad,calendar-circle-user", iconColor: null, pinnable: true },
    { entity: "splitter", disabled: false, hide: false, exposed: false, component: "activity", title: null, type: 'splitter', action: null, actionData: null, icon: null, iconColor: null, pinnable: false }, 

    { entity: "activity.edit", disabled: false, hide: false, exposed: false, component: "activity", title: "Edit activity details", type: "localFunction", action: "edit", actionData: null, icon: "fad,money-check-pen", iconColor: null, pinnable: true }, 
    { entity: "activity.print", disabled: false, hide: false, exposed: false, component: "activity", title: "Print Activity", type: "localFunction", action: "generatePDF", actionData: null, icon: "fad,print", iconColor: null, pinnable: true }, 
    { entity: "activity.markAsNotRead", disabled: false, hide: false, exposed: false, component: "activity", title: "Mark as not read", type: "localFunction", action: "markAsNotRead", actionData: null, icon: "fad,eye-slash", iconColor: null, pinnable: true }, 
    { entity: "activity.deleteActivity", disabled: false, hide: false, exposed: false, component: "activity", title: "Delete", type: "localFunction", action: "deleteActivity", actionData: null, icon: "fad,trash", iconColor: null, pinnable: true },
    { entity: "splitter", disabled: false, hide: false, exposed: false, component: "activity", title: null, type: 'splitter', action: null, actionData: null, icon: null, iconColor: null, pinnable: false }, 

    { entity: "activity.pin", disabled: false, hide: false, exposed: false, component: "activity", title: "Pin to Dock", type: "localFunction", action: "toDock", actionData: "(activity.entity, 'activity')", icon: "fad,thumbtack", iconColor: null, pinnable: true },
    { entity: "activity.toggleview", disabled: false, hide: false, exposed: false, component: "activity", title: "Compact/Groupped view", type: "localFunction", action: "compactViewToggle", actionData: null, icon: "fad,window", iconColor: null, pinnable: true },
    { entity: "activity.toggleProgressChart", disabled: false, hide: false, exposed: false, component: "activity", title: "View/Hide Progress Chart", type: "localFunction", action: "progressChartToggle", actionData: null, icon: "fad,arrow-rotate-right", iconColor: null, pinnable: true },
    { entity: "splitter", disabled: false, hide: false, exposed: false, component: "activity", title: null, type: 'splitter', action: null, actionData: null, icon: null, iconColor: null, pinnable: false }, 
      
    { entity: "activity.restriction", disabled: false, hide: false, exposed: false, component: "activity", title: "Restrictions", type: "localFunction", action: "restriction", actionData: null, icon: "fad,user-lock", iconColor: null, pinnable: true }, 
  ]

  /**************************************/
  /* Google map */
  display: any;
  center: google.maps.LatLngLiteral = {
      lat: 22.2736308,
      lng: 70.7512555
  };
  zoom = 6;
    
  moveMap(event: google.maps.MapMouseEvent) {
      if (event.latLng != null) this.center = (event.latLng.toJSON());
  }
    
  move(event: google.maps.MapMouseEvent) {
      if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  /*************************************/

  //@ViewChild('assign_users_groups', { static: false }) assign_users_groups: TemplateRef;
  //@ViewChild('assign_category', { static: false }) assign_category: TemplateRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public injectedData: any,
    private data: DataService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public cFunction: CommonFunctions,    
    public dialogRef: MatDialogRef<ActivityComponent>,
    public notesDialog: MatDialog,
    public assignCategoryDialog: MatDialog,
    public assignUserGroupDialog: MatDialog,
    public assignPriorityDialog: MatDialog,
    public activityFunctionsDialog: MatDialog,
    public activityStatusDialog: MatDialog,
    public modalDialog: MatDialog,
    public activityDateTimeDialog: MatDialog,
    public commendDialog: MatDialog,
    public CustomerDialog: MatDialog,
    public CustomerContactDialog: MatDialog,
    public WebcamCaptureDialog: MatDialog,
    public UserDialog: MatDialog,
    private UploadService: UploadService,
    private sanitizer: DomSanitizer,
    private routeto: Router,    
    private DockService: DockService,    
    public translate: TranslateService  //translate:START:END (pazi vejico)
  ) {
    translate.addLangs(['en', 'si']); //translate:START:END    
    /*
    setInterval(()=> {       
      if (document.querySelector('#progressChart') != null) {        
        if (document.querySelector('#progressChart canvas') == null) {          
          this.generateChart();
        }
      };
      
    }, 500);
    */
  } 





  secureHTML(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  
  private _openDialog() {
		if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) return;		
	}
   
  showTasks() {
    this.selectedTab = "checklist";
  }

  showSketches() {
    this.selectedTab = 'sketch';
  }

  showSubactivities() {
    this.selectedTab = 'subActivities';
  }

  showSummary() {    
    const timer = setInterval(() => {      
      const progressChart = document.querySelector('#progressChart');
      
      if (progressChart) { 
        //console.log('#1');        
        const canvas = progressChart.querySelector('canvas');
        
        if (!canvas) {
          clearInterval(timer);
          //console.log('#2');
          this.generateChart();
        } else {
          //this.redrawChart();
          //this.myChart.dispose();
          //this.myChart = null;
          //progressChart.querySelector('canvas').remove();
          //this.generateChart();
          //console.log('#4');
          clearInterval(timer);
        }
      } else {
        //console.log('#3');
        clearInterval(timer);
      }
    }, 100);
  }

  /**************************/
  /* Quill */
  changedEditor(event: EditorChangeContent | EditorChangeSelection) {    
    ////console.log('editor-change', event)
    if (event['event'] == 'text-change') { this.activity.sub_title = event['html']; this.activity.subtitle = event['html']; }
  }

  changedEditor_eTask(event: EditorChangeContent | EditorChangeSelection) {    
    ////////console.log('editor-change', event)
    //if (event['event'] == 'text-change') { this.eTalk_content = event['html']; }
  }

  created(event) {
   //console.log(this.activity.sub_title);
   event.root.innerHTML = this.activity.sub_title;
  }
  /**************************/


  makeid(length) {
    var result           = '';
    /*var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567890';*/
    var characters       = '01234567890';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

  calculateTimeToEvent_subactivity(start, end, duration) {
    var helper = this.cFunction.calculateTimeToEvent(start, end, duration);
    if (helper == '-' || helper == ' - ') {
      helper = "null";
    }
    //////console.log("*"+helper+"*");
    return helper;
  }

  calculateOffsetInSecconds(start, end) {
    end = end == null ? <any>new Date() : end;
    start = start == null ? <any>new Date() : start;
    return this.cFunction.calculateOffsetInSecconds(start, end, null);
  }

  
  test(message_start){
    return this.cFunction.calculateTimeToEvent(message_start, Date.now(), false);
  }
 
  /*************************************/
  /* file dropper */

  closeDropField_activity() {    
    const element = document.getElementsByClassName("file_container_activity")[0] as HTMLElement;
    element.style.visibility = "hidden";
    element.style.opacity = "0";    
    const element2 = document.getElementsByClassName("dropdowninfo")[0] as HTMLElement;
    element2.style.visibility = "";
    this.files = [];	
  }
  
  ngAfterViewInit() {
    atcb_init();
    //this.showSummary();
  }

  pushToCalendar() {    
      var start = (this.activity.message_start).split(" ");
      var end = (this.activity.message_end).split(" ");
      const config = {
        name: this.activity.title,
        description: this.activity.sub_title + "(" + this.activity.customer + ")",
        startDate: start[0],
        endDate: end[0],
        startTime: start[1],
        endTime: end[1],
        options: ["Apple", "Google", "iCal", "Microsoft365", "MicrosoftTeams", "Outlook.com", "Yahoo"],
        timeZone: "Europe/Berlin",
        trigger: "click",
        iCalFileName: "Reminder-Event",
      };
      
    ////console.log(<any>config);
    atcb_action(<any>config);
  }

  deletePrefill(line_data) {
    //console.log(this.activityPrefills['rows'][line_data]);
  }


     

  /*************************************/
  ngOnInit(): void {      

    atcb_init();
    this.editor = GlobalVariables.memberSettings['editor'];    
    
    this.isMobile = this.cFunction.isMobileDevice();    
    if (this.isMobile) { this.extended_view = true; }    

    this.DateOffset = localStorage.getItem('dateoffset') == 'true' ? true : false; 
    const component = this;
        
    this.hideFinishedTasks = localStorage.getItem('hideFinishedActivityView') == 'true' ? true : false;
    this.hideFinishedActivities = localStorage.getItem('hideFinishedSubActivityView') == 'true' ? true : false;
   
    this.data.getMemberSettings(null).subscribe(
      (res: Response) => {        
          var memberSettingsArray = [];        
          for (var i=0; i<res['rows'].length; i++) {          
              memberSettingsArray[res['rows'][i].parameter] = res['rows'][i].value;
          }        
      GlobalVariables.memberSettings = memberSettingsArray;      
      this.allAssigneesView = GlobalVariables.memberSettings['allAssigneesView'];
      this.allCommentsView = GlobalVariables.memberSettings['allCommentsView'];
      this.allHistoryView = GlobalVariables.memberSettings['allHistoryView'];
      this.allTimeTrackingView = GlobalVariables.memberSettings['allTimeTrackingView'];
      this.allAttachmentView = GlobalVariables.memberSettings['allAttachmentView'];
      this.showTaskComments = GlobalVariables.memberSettings['showTaskComments'];
      this.compactView = GlobalVariables.memberSettings['activityCompactView'];   
      this.progressChart = GlobalVariables.memberSettings['activityProgressChart'];      
      
      var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
      this.translate.use(lang);
	    this.extended_view_left = GlobalVariables.memberSettings['extended_view_left'] == undefined ? false : GlobalVariables.memberSettings['extended_view_left'];
            
    });    

    this.data.getActivityPrefills({}).subscribe(
      (res: Response) => {    
        this.activityPrefills = (<any>res);        
      }
    );

    this.data.getMembers({items : 10000, page : 0, order : 't1.username', orderby : 'asc', search : null, activity: this.id, field: 'enabled', value: 1}).subscribe(
      (res: Response) => {
        this.members = (<any>res).rows;        
        this.data.getMemberGroups({items : 10000, page : 0, order : 't1.member_group', orderby : 'asc', search : null, activity: this.id}).subscribe(
          (res: Response) => {
            this.member_groups = (<any>res).rows;            
            this.data.getCategories({items:10000, page:0, order:'t1.description', orderby:'asc', search:null}).subscribe(
              (res: Response) => {
                this.categories = (<any>res).rows;        
                if (this.category_id != null) {                  
                  for (var i=0; i<this.categories.length; i++) {                    
                    if (this.categories[i].entity == this.category_id) {                      
                      this.activity.message_type = this.category_id;
                      this.activity.category_type = this.category_id;
                      this.activity.category_type_description = this.categories[i].description;
                      this.activity.category_type_hex = this.categories[i].hex;
                    }
                  }
                }            

                /* get Activity */                
                this.sub = this.route.params.subscribe(params => {
                  this.id = +params['id'];                  
                  
                  this.data.getStorageID({}).subscribe((res: Response) => {                    
                      this.storage = res['storage'];                      
                      if (this.injectedData) {
                        this.getActivity(this.injectedData['entity']);
                      } else {
                        this.getActivity(this.id); //DEPRECATED
                      }
                  });
                });              

              }
            ); //getCategories
          }
        ); //getMemberGroups
      }
    ); //getMembers
    
    /* jQuery */    

    const focus = setInterval(() => {
      $(".autofocus").focus();
      $(".autofocus").removeClass('autofocus');
    }, 500);

    const scrollInterval = setInterval(() => {
      if ( $(".activity_bottom").height() < $(".activity_bottom_inner").height() ) {
        if ($('.activity_left_collapse').length == 0) {
          $('.activity_bottom .activity_left').addClass('scroll');
        } else {
          $('.activity_bottom .activity_left_collapse').addClass('scroll');
        }        
      } else {
        if ($('.activity_left_collapse').length == 0) {
          $('.activity_bottom .activity_left').removeClass('scroll');
        } else {
          $('.activity_bottom .activity_left_collapse').removeClass('scroll');
        }        
      }
    }, 100);
   
    $(document).on('click', '.activity_wizzard', function() {

      var rect = $(this).offset();
      var top = rect.top + $(this).outerHeight() - $('.wizzard_container').outerHeight();
      var left = rect.left + $(this).outerWidth() + 12;
      $('.wizzard_container').css({ left:left, top:top });
      $('.wizzard_container').show();
      
    })

    
    $(document).on('click', '.wizzard_item', function() {
      $('.wizzard_container').hide();
      /*
      var text = $(this).text();      
      $('#newactivity_title').val(text);      
      */
    });
    

    $(document).on('click', '.etalk_attachment fa-icon', function() {      
      var rel = $(this).attr('rel');      
      $(".etalk_attachment_container").hide();
      $(".etalk_attachment_container.etalk_rel_"+rel).show();
    });

    $(document).on('click', '.etalk_attachment_close', function() {
      $(".etalk_attachment_container").hide();      
    });


  }


  /**************************************/
  /* Common Functions */
  getInitials(str) {
    var FirstName = '';
    var LastName = '';

    if (str != undefined) {      
      FirstName = str.split(' ')[0];
      LastName = str.split(' ').reduceRight(a => a);
    }

    if (FirstName === LastName) { return FirstName.trim().substring(0, 2).toUpperCase(); }
    return [FirstName, LastName].map(name => name[0]).join('').toUpperCase();
  }


  ShowUserInicials(users) {    
    var updated_users = [];    
    if (users) {
      users = users.split(',');
      for (var i=0; i<users.length; i++) {
        var initials = this.getInitials(users[i]);
        var helper_users: any = {};        
        helper_users.initials = this.getInitials(users[i]);
        helper_users.fullname = users[i];
        updated_users.push(helper_users);    
      }
    }            
    return updated_users;
  }

  toggleDateOffset() {
    this.DateOffset = !this.DateOffset;
    var helper = this.DateOffset == true ? 'true' : 'false';
    localStorage.setItem('dateoffset', helper);    
  }

  toDock() {
    this.DockService.addToDock({ entity: this.activity.entity, type: 'activity', icon: 'fad,list', title: this.activity.title, navigateType: '', navigateAction: '', mavigateDate: '', color: '#ffffff' });
  }

  toInt(value) {
    return parseInt(value);
  }

  restriction() {
    this.restriction_view = this.restriction_view == true ? false : true;
    this.extended_view = true;
  }


  ShowHideFinishedActivities() {    
    this.hideFinishedActivities=!this.hideFinishedActivities;
    var helper = this.hideFinishedActivities == true ? 'true' : 'false;'    
    localStorage.setItem('hideFinishedSubActivityView', helper);
  }

  

  changeUserRestriction(key, member_entity, activity_entity, read_write, state) {
    if (state == undefined) state = false;
    this.members[key][read_write] = !state;
    //console.log(key, member_entity, activity_entity, read_write, state);
    this.data.setRestriction( { data : { messages_entity : activity_entity, type : 'user', type_entity : member_entity, read_write : read_write, state : !state }} ).subscribe(
      (res: Response) => {     
        //console.log(res);           
      }
    );
  }

  changeGroupRestriction(key, member_entity, activity_entity, read_write, state) {
    //console.log(this.member_groups);
    if (state == undefined) state = false;
    this.member_groups[key][read_write] = !state;
    //console.log(key, member_entity, activity_entity, read_write, state);
    this.data.setRestriction( { data : { messages_entity : activity_entity, type : 'group', type_entity : member_entity, read_write : read_write, state : !state }} ).subscribe(
      (res: Response) => {        
      }
    );
  }

  userLock() {
    this.restriction_view = !this.restriction_view;
  }

  removeLetters(textValue) {
    if (typeof textValue != 'string') return textValue;
    if (textValue == undefined) return false;
    if (textValue == null) return false;    
    return textValue.replace(/\D/g,'');
  }

  capturePhoto() {
    var webCamCaptureDialog = this.dialog.open(WebcamCaptureComponent, {
      panelClass: 'dialog-webCamCaptureDialog',
      //data: { entity : entity, comment : comment }
    });
    
    webCamCaptureDialog.afterClosed().subscribe(result => {
        
        if (result != null) {  
          var imageBase64 = result;

          //var editDialog = this.dialog.open(DocumentEditComponent, { 'width' : '800px', data : { files : $event, prefill : {call: 'activity', customer: this.activity.customer, customer_id: this.activity.customer_id, entity: this.activity.entity, title: this.activity.title, category: '?', category_id: '?'} } });
          //console.log({ 'width' : '800px', data : { prefill : {call: 'activity', customer: this.activity.customer, customer_id: this.activity.customer_id, entity: this.activity.entity, title: this.activity.title, category: '?', category_id: '?'} } });
          var editDialog = this.dialog.open(DocumentEditComponent, { 'width' : '800px', data : { prefill : {call: 'activity', customer: this.activity.customer, customer_id: this.activity.customer_id, entity: this.activity.entity, title: this.activity.title, category: '?', category_id: '?'} } });
          editDialog.afterClosed().subscribe(result => {	    
            if (result != undefined && result != '') {              
              this.document_details = result.value;
              //console.log(this.document_details);
              this.data.uploadMediaBase64({ data : this.document_details, image: imageBase64 }).subscribe(
                (res: Response) => {
                  const d = Date.now();
                  this.document_details['doc_file'] = res['original_name'];
                  this.document_details['doc_code'] = d.toString() + '.jpg';
                  //console.log(imageBase64['_imageAsDataUrl']);
                  //this.document_details['doc_size'] = atob(imageBase64).length;
                  this.document_details['doc_size'] = imageBase64['_imageAsDataUrl'].length;
                  
                  //console.log(this.document_details);
                  this.data.saveDocument(this.document_details).subscribe(
                    (res: Response) => {
                      this.getActivity(this.entity);
                    }
                  );                  

                }
              );                
            } else {
              const element = document.getElementsByClassName("file_container_activity")[0] as HTMLElement;
              element.style.visibility = "hidden";
              element.style.opacity = "0";    
              const element2 = document.getElementsByClassName("dropdowninfo_activity")[0] as HTMLElement;
              element2.style.visibility = "";
              this.files = [];	
            }
          });		     
        }

    });  
  }

  fixComment(entity, comment) {
    var commentDialog = this.dialog.open(CommentComponent, {
      panelClass: 'dialog-comment',
      data: { entity : entity, comment : comment }
    });
    
    commentDialog.afterClosed().subscribe(result => {
      this.getActivity(this.activity.entity);
    });  
  }

  deleteComment(entity) {    
    this.data.deleteComment({ entity : entity }).subscribe((res: Response) => {
      this.getActivity(null);
    });
  }

  exposeComments() {
    this.commentsExpand = !this.commentsExpand;
  }

  editNotes(entity = null) {    
    var helper = window.innerWidth + 'px';     
    //console.log(window.innerWidth);
    var notesDialog = this.dialog.open(NotesComponent, {
      panelClass: 'dialog-sketch',
      'width' : helper,
      data: {         
        entity : entity,
        customer_id : this.activity.customer_id,
        activity_id : this.activity.entity
      }
    });
    
    notesDialog.afterClosed().subscribe(result => {
      /*************************/
      /* get notes             */            
      this.data.getNotesList({ 'items' : 100, 'order' : 'entity', 'orderby' : 'asc', 'activity' : this.activity.entity }).subscribe(
        (res: Response) => {
          this.notes_list = <any>res['rows'];               
        }
      );
    });  

    this.selectedTab='sketch';
  }

  addComment(entity) {
    //console.log({ parent_object_id: this.activity.entity, parent_object: 'messages' , object_id: entity, object: 'comment-task' });
    var commentDialog = this.dialog.open(CommentComponent, {
      panelClass: 'dialog-comment',
      data: { parent_object_id: this.activity.entity, parent_object: 'messages' , object_id: entity, object: 'comment-task' }
    });
    
    commentDialog.afterClosed().subscribe(result => {
      this.getActivity(this.activity.entity);            
    });       
  }

  replyTask(entity) {    
    let content = this.translate.instant("Task reply text");
    
    let waitingText = this.translate.instant("Waiting for confirmation");
    let taskText = this.tasks[entity]['text'];
    let limitedTaskText = taskText.length > 100 ? taskText.substring(0, 100) + "..." : taskText;    
    let preText = `${waitingText}: ${limitedTaskText}`;

    let author = this.tasks[entity]['author'];
    let author_id = this.tasks[entity]['author_id'];
    let group_id = this.tasks[entity]['group_id'];
    let group_title = this.tasks[entity]['group_title'];

    var modalDialog = this.dialog.open(ModalComponent, {
      data: { content: content, yes: this.translate.instant("Save") , no: null, cancel: this.translate.instant("Cancel"), inputTextArea: preText }
    });

    modalDialog.afterClosed().subscribe(result => {
      //console.log(result);
      if (result['yes']!=undefined) {        
        this.addNewTask(this.activity.entity, this.tasks, result['value'], false, null, group_id, group_title, null, null, author, author_id); 
        this.groupTasks();
        this.saveTasks();
      }
    });          
  }
	
  progressTask(key) {
    console.log(this.tasks[key]['progress']);
    if (this.tasks[key]['progress'] == -1 || this.tasks[key]['progress'] == null) {
      this.tasks[key]['progress'] = 0;
    } else {
      var content = this.translate.instant("Would you like to discard information about task progress?");
      var modalDialog = this.dialog.open(ModalComponent, {
        data: { content: content, yes: this.translate.instant("Discard") , no: null, cancel: this.translate.instant("Cancel") }
      });
  
      modalDialog.afterClosed().subscribe(result => {
        //console.log(result);
        if (result['yes']!=undefined) {
          this.tasks[key]['progress'] = -1;
        }
      }); 
    }
    
  }
										

  addGeneralComment() {
    var commentDialog = this.dialog.open(CommentComponent, {
      panelClass: 'dialog-comment',
      data: { parent_object_id: this.comment_parent_object_id, parent_object: this.comment_parent_object , object_id: this.comment_object_id, object: this.comment_object }
    });
    
    commentDialog.afterClosed().subscribe(result => {
      this.getActivity(this.activity.entity);
      this.allCommentsView = true;
    });       
  }

  selectCustomer() {
    var selectCustomerDialog = this.dialog.open(SelectCustomerComponent, {});
    selectCustomerDialog.afterClosed().subscribe(result => {
      if (result != false) {  
        this.activity.customer_id = result.entity;
        this.activity.customer = result.customer;
        this.customer_change = true;
        //this.ActivityForm.pristine = false;
      }
    });       
  }

  selectContacts(company_id) {    
    var CustomerContactDialog = this.dialog.open(ContactSelectComponent, { 'width' : '468px', });
    CustomerContactDialog.componentInstance.entity = this.activity.entity;   
    CustomerContactDialog.componentInstance.customer_id = this.activity.customer_id;
    CustomerContactDialog.componentInstance.selected = this.activity['contacts'];
    
    CustomerContactDialog.afterClosed().subscribe(result => {
      //////console.log(result);
      if (result != false) {
        this.assigned_contacts = result;
        this.assignContacts();
      }
    });       
  }

  removeCompany() {
    this.activity.customer_id = undefined;
    this.activity.customer = this.translate.instant("Choose customer...");
    this.customer_change = true;
  }

  openCustomer() {
    var CustomerDialog = this.dialog.open(CustomerComponent, {});    
    CustomerDialog.componentInstance['entity'] = this.activity['customer_id'];
    CustomerDialog.afterClosed().subscribe(result => {      
      //this.getActivity(this.activity.entity);      
    });       
  }



  deleteActivity(entity = this.entity) {
    entity = entity == null ? this.entity : entity;
    var content = this.translate.instant("Do you want parmenently delete activity?");
    var modalDialog = this.dialog.open(ModalComponent, {
      data: { content: content, yes: this.translate.instant("Delete") , no: null, cancel: this.translate.instant("Cancel") }
    });

    modalDialog.afterClosed().subscribe(result => {
      //console.log(result);
      if (result['yes']!=undefined) {
        this.parameters = { entity: entity };
        this.data.deleteActivity(this.parameters).subscribe(
          (res: Response) => {
            this.dialogRef.close();
          }
        );    
      }
    });    
  }

  cancelChanges() {
    this.canEdit = false;
    this.activity = this.activity_backup;
    this.customer_change = false;
    this.showSummary();
  }

  unlink_etask(entity) {
    this.data.unlink_etask({ entity : entity}).subscribe(
      (res: Response) => {
        this.getActivity(this.activity.entity); 
      }
    );
  }

  edit() {
    this.selectedTab = 'summary';
    this.canEdit = true;
  }




  /***********************/
  /* delete Attachment */
  deleteAttachment(entity) {

    var content = "Do you want parmenently delete attachment?";
    var modalDialog = this.dialog.open(ModalComponent, {
      data: { content: content, yes: 'Delete' , no: null, cancel: 'Cancel' }
    });

    modalDialog.afterClosed().subscribe(result => {
      //console.log(result);
      if (result['yes']!=undefined) {
        //alert(entity);
        this.data.removeDocument({ 'entity' : entity }).subscribe(
          (res: Response) => {
            this.getActivity(this.activity.entity);
          }				
        );
      }
    });


    
  }



























  /************************************************************/
  /************************************************************/
  /************************************************************/
  PDFFooter(doc, page, lMargin) {
    doc.setFontSize(9);
    doc.text("Page #"+page, 180, 274);
    doc.addImage("/assets/logo/ibilizz_black.png", "PNG", lMargin, 268, 20, 8);
    doc.addImage("/assets/images/print.png", "PNG", lMargin+40, 270.5, 4, 4);

    var datetime = new Date();   
    var options = { hour12: false };       
    doc.text(datetime.toLocaleString("sl-SI", options), lMargin+45, 268+6);  
    return doc;
  }

  generatePDF() {
    
    ////console.log(this.activity);

    var lMargin=25; //left margin in mm
    var rMargin=15; //right margin in mm
    var pdfInMM=210;  // width of A4 in mm
    var normalSpace=4;
    var maxPageHeight=265;
    
    var page = 1;
    var contentHeight = 20;


    var doc = new jsPDF("p","mm",[210, 297]);
        
    doc.setLineHeightFactor(1.5);
    
    //doc.addFileToVFS('/assets/fonts/Roboto.ttf', base64);
    doc.addFont("./assets/fonts/Roboto-Regular.ttf", "Roboto", "normal");
    doc.addFont("./assets/fonts/Roboto-Bold.ttf", "Roboto-Bold", "normal");
    doc.setFont("Roboto");

    doc.setFontSize(9);
    
    /* tcontent*/
    /*
    var paragraph = this.activity.title;
    var content = doc.splitTextToSize(paragraph, (pdfInMM-lMargin-rMargin));
    var dim = doc.getTextDimensions(content);    
    doc.text(content, lMargin, contentHeight);
    contentHeight = contentHeight + dim.h + 10;
    */

    /* subtitle */
    /*
    var paragraph=this.activity.sub_title;
    var content = doc.splitTextToSize(paragraph, (pdfInMM-lMargin-rMargin));
    var dim = doc.getTextDimensions(content);    
    doc.text(content, lMargin, contentHeight);
    contentHeight = contentHeight + dim.h + 10;
    */

    /****************************/
    /* footer */
    this.PDFFooter(doc, page, lMargin);

    /* Author */
    doc.setLineWidth(0.5);
    doc.setDrawColor(255,255,255);
    doc.setFillColor(255,0,0);
    doc.circle(lMargin+5, contentHeight+1, 5, 'FD');
    doc.setFontSize(9);
    doc.setTextColor('#ffffff');
    doc.setFont("Roboto-Bold");
    doc.text('AM', lMargin+2.5, contentHeight+2);  
    doc.setTextColor('#000000');
    doc.setFont("Roboto");
    doc.setDrawColor(0,0,0);

    /****************************/
    /* Activity created/Updated */
    doc.setFontSize(8);
    doc.addImage("/assets/images/calendar.png", "PNG", lMargin+13, contentHeight-3, 4, 4);
    doc.text(this.cFunction.localDateFormat(this.activity.created), lMargin+18, contentHeight);
    doc.addImage("/assets/images/edit.png", "PNG", lMargin+13, contentHeight+1, 4, 4);
    doc.text(this.cFunction.localDateFormat(this.activity.updated), lMargin+18, contentHeight+4);

    /****************************/
    /* Assigned */
    var assignation_offset = 66;

    if (this.activity.assigned_users_names != null && this.activity.assigned_users_names != '') {
      var assigned_users_names = (this.activity.assigned_users_names).split(',');
      
      for (var i=0; i<assigned_users_names.length; i++) {
        var inicialke = this.getInitials(assigned_users_names[i]);

        assignation_offset = assignation_offset + 7.5;
        doc.setLineWidth(0.5);
        doc.setDrawColor(255,255,255);
        doc.setFillColor(255,0,0);
        doc.circle(assignation_offset+5, contentHeight+1, 5, 'FD');
        doc.setFontSize(9);
        doc.setTextColor('#ffffff');
        doc.setFont("Roboto-Bold");
        doc.text(inicialke, assignation_offset+2, contentHeight+2);  
        doc.setTextColor('#000000');
        doc.setFont("Roboto");
        doc.setDrawColor(0,0,0);
        doc.setLineWidth(0.1);
      }
    }

    /***********************/
    /* Priority */
    assignation_offset = assignation_offset + 16;
    if (this.activity.priority == "1") {
      doc.addImage("/assets/images/flag_green.png", "PNG", assignation_offset, contentHeight-4, 10, 10);
    } else if (this.activity.priority == "2") {
      doc.addImage("/assets/images/flag_yellow.png", "PNG", assignation_offset, contentHeight-4, 10, 10);
    } else if (this.activity.priority == "3") {
      doc.addImage("/assets/images/flag_red.png", "PNG", assignation_offset, contentHeight-4, 10, 10);
    } else {
      doc.addImage("/assets/images/flag_silver.png", "PNG", assignation_offset, contentHeight-4, 10, 10);
    }
    
    /****************************/
    /* Activity Start/Stop      */
    doc.setFontSize(8);
    doc.addImage("/assets/images/start.png", "PNG", lMargin+140, contentHeight-3, 4, 4);
    doc.text(this.cFunction.localDateFormat(this.activity.message_start), lMargin+145, contentHeight);
    doc.addImage("/assets/images/end.png", "PNG", lMargin+140, contentHeight+1, 4, 4);
    doc.text(this.cFunction.localDateFormat(this.activity.message_end), lMargin+145, contentHeight+4);

    contentHeight = contentHeight + 9;
    
    doc.setDrawColor(0,0,0);
    doc.line(lMargin, contentHeight, lMargin + 170, contentHeight);

    contentHeight = contentHeight + 7;
 
    /***********************/
    /* Company */
    if (this.activity.customer != '' && this.activity.customer != undefined && this.activity.customer != null) {
      doc.setFontSize(9);
      doc.setFont("Roboto-Bold");
      doc.addImage("/assets/images/user.png", "PNG", lMargin, contentHeight-4.5, 6, 6);
      doc.text(this.activity.customer, lMargin+7, contentHeight);
      doc.setFont("Roboto");
      var dim = doc.getTextDimensions(this.activity.customer);
    }
    
    /***********************/
    /* Status */
    if (this.activity.activity_status_text!=undefined) {
      doc.setFontSize(9);
      doc.setFont("Roboto-Bold");
    
      var dim = doc.getTextDimensions(this.activity.activity_status_text);
      doc.setTextColor(255,255,255);
      doc.setDrawColor(this.activity.activity_status_hex);
      doc.setFillColor(this.activity.activity_status_hex);    
      doc.rect(193-dim.w-2, contentHeight-dim.h-1, dim.w+4, dim.h+3, 'FD');    
      doc.text(this.activity.activity_status_text, 193-dim.w, contentHeight);
      doc.setTextColor(0,0,0);
    }

    contentHeight = contentHeight + dim.h + normalSpace*2;

    /***********************/
    /* Title */
    if (this.activity.title != '' && this.activity.title != undefined && this.activity.title != null) {
      doc.setFontSize(16);
      doc.setFont("Roboto-Bold");
      doc.text(this.activity.title, lMargin, contentHeight);    
      var dim = doc.getTextDimensions(this.activity.title);
      doc.setFont("Roboto");
      contentHeight = contentHeight + dim.h + normalSpace;
    }

    /***********************/
    /* Content */
    var Content = doc.splitTextToSize(this.activity.sub_title, 300);
    var pageHeight = doc.internal.pageSize.height;    
    doc.setFontSize(9);    
    for (var i = 0; i < Content.length; i++) {                
        if (contentHeight > maxPageHeight) {
            contentHeight = 20;
            page = page +1;
            doc.addPage();
            this.PDFFooter(doc, page, lMargin);
        }
        doc.text((Content[i]).replace(/(<([^>]+)>)/gi, ""), lMargin, contentHeight);
        //doc.fromHTML(Content[i], lMargin, contentHeight);
        contentHeight = contentHeight + 5;
    }    
    contentHeight = contentHeight + 10;

    /***********************/
    /* tasks */
    doc.setFontSize(11);
    doc.setFont("Roboto-Bold");
    doc.text('Activity Tasks', lMargin, contentHeight);
    doc.setFont("Roboto");
    var dim = doc.getTextDimensions('Activity Tasks');
    contentHeight = contentHeight + dim.h + normalSpace;
    doc.setFontSize(9);

    for (const [key, value] of Object.entries(this.tasks)) {
      doc.setDrawColor("#000000");
      doc.setFillColor("#ffffff"); 
      doc.setLineWidth(0.5);
      doc.rect(lMargin, contentHeight - 3.5, 3.5, 3.5);

      if (value['status']==true) {
        doc.line(lMargin, contentHeight-3.5, lMargin + 3.5, contentHeight-3.5+3.5);
        doc.line(lMargin, contentHeight-3.5+3.5, lMargin + 3.5, contentHeight-3.5);
      }

      doc.setLineWidth(0.5);

      var content = doc.splitTextToSize(value['text'], (pdfInMM-lMargin+6-rMargin));
      doc.text(content, lMargin+6, contentHeight-0.5);
      var dim = doc.getTextDimensions(content);
      contentHeight = contentHeight + dim.h + normalSpace;      

      if (contentHeight > maxPageHeight) {
        contentHeight = 20;
        page = page +1;
        doc.addPage();
        this.PDFFooter(doc, page, lMargin);
      }

      var assign = value['assign'];      
      if (this.activity['comments-task'] != undefined) {
        for (const [key, value] of Object.entries(this.activity['comments-task']?.rows)) {
          if (value['object_id']==assign && value['object']=='comment-task') {
            //////console.log(value);
            for (const [key1, value1] of Object.entries(this.activity['comments-task']?.rows)) {        
              var content = doc.splitTextToSize(value1['username'] + " | " + this.cFunction.localDateFormat(value1['created']), (pdfInMM-lMargin+6-rMargin));
      
              /* horizontalna črta */
              doc.setDrawColor(200, 200, 200);
              doc.line(lMargin + 6, contentHeight-4, lMargin + 170, contentHeight-4);
              
              /* Avtor in čas  */
              doc.text(content, lMargin + 6, contentHeight);
              var dim = doc.getTextDimensions(content);
              contentHeight = contentHeight + dim.h + normalSpace;
      
              /* vsebina komentarja, samo priprava podatkov */
              var content = doc.splitTextToSize(value1['comment'], (pdfInMM-lMargin-rMargin));
              
              /* preveri ali je potrebno narediti novo stran */
              if (contentHeight + dim.h > maxPageHeight) {
                page = page +1;
                doc.addPage();
                this.PDFFooter(doc, page, lMargin);
                contentHeight = 20;
              }
      
              doc.text(content, lMargin + 6, contentHeight);
              var dim = doc.getTextDimensions(content);
              contentHeight = contentHeight + dim.h + normalSpace;
            }      
          }        
        }
      }

    }

    contentHeight = contentHeight + 9;
    
    /***********************/
    /* Comments */
    if (this.activity.comments != undefined) {
      doc.setFontSize(11);
      doc.setFont("Roboto-Bold");
      doc.text('Activity Comments', lMargin, contentHeight);
      doc.setFont("Roboto");
      var dim = doc.getTextDimensions('Activity Comments');
      contentHeight = contentHeight + dim.h + normalSpace;
      doc.setFontSize(9);
      
      for (const [key, value] of Object.entries(this.activity.comments.rows)) {    
        
        var content = doc.splitTextToSize(value['username'] + " | " + this.cFunction.localDateFormat(value['created']), (pdfInMM-lMargin-rMargin));

        /* horizontalna črta */
        doc.setDrawColor(200, 200, 200);
        doc.line(lMargin, contentHeight-4, lMargin + 170, contentHeight-4);
        
        /* Avtor in čas  */
        doc.text(content, lMargin, contentHeight);
        var dim = doc.getTextDimensions(content);
        contentHeight = contentHeight + dim.h + normalSpace;

        /* vsebina komentarja, samo priprava podatkov */
        var content = doc.splitTextToSize(value['comment'], (pdfInMM-lMargin-rMargin));
        
        /* preveri ali je potrebno narediti novo stran */
        if (contentHeight + dim.h > maxPageHeight) {
          page = page +1;
          doc.addPage();
          this.PDFFooter(doc, page, lMargin);
          contentHeight = 20;
        }

        doc.text(content, lMargin, contentHeight);
        var dim = doc.getTextDimensions(content);
        contentHeight = contentHeight + dim.h + normalSpace;

        /* preveri ali obstajajo pod komentarji oziroma komentar na komentar*/
        if (value['comments']!=false) {
          doc.text("komenta ima podkomentarje", lMargin, contentHeight);
        
        }
      }

      contentHeight = contentHeight + normalSpace;
    }

    contentHeight = contentHeight + 10;

    /***********************/
    /* Attachments */
    doc.setFontSize(11);
    doc.setTextColor('#000000');
    doc.setFont("Roboto-Bold");
    doc.text('Activity Attachments', lMargin, contentHeight);
    doc.setFont("Roboto");
    var dim = doc.getTextDimensions('Activity Attachments');
    contentHeight = contentHeight + dim.h + normalSpace;

    for (const [key, value] of Object.entries(this.Attachments)) {    

      doc.setFontSize(9);
      doc.setTextColor('#000000');
      var content = doc.splitTextToSize(value['original_filename'] + " | " + value['filename'], (pdfInMM-lMargin-rMargin));

      if (contentHeight + dim.h > maxPageHeight) {
        page = page +1;
        doc.addPage();
        this.PDFFooter(doc, page, lMargin);
        contentHeight = 20;
      }

      doc.text(content, lMargin, contentHeight);
      var dim = doc.getTextDimensions(content);
      contentHeight = contentHeight + dim.h + normalSpace;

      doc.setTextColor('#c8c8c8');
      doc.setFontSize(9);
      var content = doc.splitTextToSize(this.cFunction.localDateFormat(value['updated']) + " | " + value['username'] + " | " + value['title'], (pdfInMM-lMargin-rMargin));

      if (contentHeight + dim.h > maxPageHeight) {
        page = page +1;
        doc.addPage();
        this.PDFFooter(doc, page, lMargin);
        contentHeight = 20;
      }     
      
      doc.text(content, lMargin, contentHeight);
      var dim = doc.getTextDimensions(content);
      contentHeight = contentHeight + dim.h + normalSpace;
      
    }
    contentHeight = contentHeight + normalSpace;


    
    /***********************/
    /* History */
    doc.setFontSize(11);
    doc.setTextColor('#000000');
    doc.setFont("Roboto-Bold");
    doc.text('Activity History', lMargin, contentHeight);
    doc.setFont("Roboto");    
    var dim = doc.getTextDimensions('Activity History');
    contentHeight = contentHeight + dim.h + normalSpace;

    for (const [key, value] of Object.entries(this.history)) {    

      doc.setFontSize(9);
      var content = doc.splitTextToSize(this.cFunction.localDateFormat(value['created']) + " | " + value['username'] + " | " + value['custom_title'], (pdfInMM-lMargin-rMargin));

      if (contentHeight + dim.h > maxPageHeight) {
        page = page +1;
        doc.addPage();
        this.PDFFooter(doc, page, lMargin);        
        contentHeight = 20;
      }
      
      doc.text(content, lMargin, contentHeight);
      var dim = doc.getTextDimensions(content);
      contentHeight = contentHeight + dim.h + normalSpace;
      
    }
    
    /* generate PDF */
    doc.save('activity.pdf');

  }






















  /*
  countObject(objectVar) {
    return Object.keys(objectVar).length
  }
  */


  /***********************************************************/
  /* save burgebar pin function */
  pinShortcut() {
    this.data.setMemberSettings({'parameter': 'activityShortcuts', 'value': JSON.stringify(this.shortcuts)}).subscribe((res: Response) => {}); 
  }

  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* Confirm */

  toggleConfirm(entity) {  
    this.data.updateConfirm({activity_entity:entity}).subscribe(
      (res: Response) => {         
        ////////console.log(res);
        //alert(entity);
        this.getActivity(null);
      }
    );  
  }

  assignContacts() {  
    var contacts = "";
    for (var i=0; i<this.assigned_contacts.length; i++) {
      if (this.assigned_contacts[i]['status'] == true) {
        if (contacts != '') contacts += ",";
        contacts += this.assigned_contacts[i]['entity'];
      }
    }
    this.activity['contacts'] = contacts;
    ////console.log({entity:this.activity.entity, contacts:contacts});
    this.data.assignContacts({entity:this.activity.entity, contacts:contacts}).subscribe(
      (res: Response) => {         
        //this.getActivity(null);
      }
    );  
  }

  removeConfirm(entity) {  
    this.data.removeConfirm({activity_entity:entity}).subscribe(
      (res: Response) => {         
        ////////console.log(res);
        //alert(entity);
        this.getActivity(null);
      }
    );  
  }

  /************************************************************/
  /* Mark as not read  */
  markAsNotRead() {
    this.activity.viewed = "0";
    let activityData = Object.assign({}, this.activity);    
    //console.log(activityData);
    this.data.markAsNotRead({ data: activityData }).subscribe( (res: Response) => { } );
  }

  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* Comments */

  saveComment(event) { 

    if (event == 13) {
      var params = { data:{
        parent_object_id:this.comment_parent_object_id,
        parent_object:this.comment_parent_object,
        object_id:this.comment_object_id,
        object:this.comment_object,
        comment:this.comment}
      }    
      
      this.data.saveComment(params).subscribe(
        (res: Response) => { 
          $('.add_new_comment').detach().appendTo('.activity_comments');
          this.getActivity(null);
        }
      );
      
    }

  }

  /*
  cancelComment() {
    this.comment_parent_object_id = this.activity.entity;
    this.comment_parent_object = 'messages';
    this.comment_object_id = this.activity.entity;
    this.comment_object ='comment';
    this.comment = "";

    $('.add_new_comment').detach().appendTo('.activity_comments');
    
  }
  */

  replyComment(comment_parent_object, comment_parent_object_id, comment_object, comment_object_id) {    
    this.comment_parent_object_id = comment_parent_object_id;
    this.comment_parent_object = comment_parent_object;
    this.comment_object_id = comment_object_id;
    this.comment_object = comment_object;
    this.comment = "";

    this.addGeneralComment();

    //$('.add_new_comment').detach().appendTo('div[rel='+comment_object_id+']');
  }

  toggleCommentView() {
    this.allCommentsView = !this.allCommentsView;
    this.data.setMemberSettings({'parameter': 'allCommentsView', 'value': this.allCommentsView}).subscribe((res: Response) => {});     
    //alert(this.allCommentsView);
  }

  toggleAssigneesView() {
    this.allAssigneesView = !this.allAssigneesView;
    this.data.setMemberSettings({'parameter': 'allAssigneesView', 'value': this.allAssigneesView}).subscribe((res: Response) => {});     
  }
 
  toggleHistoryView() {
    this.allHistoryView = !this.allHistoryView;
    this.data.setMemberSettings({'parameter': 'allHistoryView', 'value': this.allHistoryView}).subscribe((res: Response) => {});     
  }

  toggleTimeTrackingView() {
    this.allTimeTrackingView = !this.allTimeTrackingView;
    this.data.setMemberSettings({'parameter': 'allTimeTrackingView', 'value': this.allTimeTrackingView}).subscribe((res: Response) => {});     
  }

  toggleAttachmentView() {
    this.allAttachmentView = !this.allAttachmentView;
    this.data.setMemberSettings({'parameter': 'allAttachmentView', 'value': this.allAttachmentView}).subscribe((res: Response) => {});     
  }


  redirectToDocumentArchive() {    
    this.dialogRef.close();
    this.routeto.navigateByUrl('/document-center/activity_'+this.entity);
  }

  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* Activities Status */

  updateStatuses() {
    this.data.getActivityStatuses({ id : this.activity.message_type }).subscribe(
      (res: Response) => {
        this.statuses = (<any>res).rows; 

        this.activity_status_description = this.translate.instant("Status not selected");
        this.activity_status_hex = '#dddddd';

        for (var i=0; i<this.statuses.length; i++) {
          if (this.statuses[i].id == this.activity.status_id) {
            this.activity_status_description = this.statuses[i].title;
            this.activity_status_hex = this.statuses[i].color;
          }
        }
      }
    );
  }


  selectStatus(entity) {
    var activityStatusDialog = this.activityStatusDialog.open(AssignStatusComponent, {
      'width' : '450px',
      data: { activity: entity, status: this.activity.status_id, statuses: this.statuses }
    });    
    
    activityStatusDialog.afterClosed().subscribe(result => {           
      if (result.activity != null) {                 
        this.data.updateStatus({ id : result.activity, status : result.status, status_title: result.status_title }).subscribe(
          (res: Response) => { 
            this.getActivity(null);
          }
        );    
      }      
    });
    
  }



  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* Add Subactivity */

  addActivity() {
  
    const result: any = {};
  
    result.title = this.activity.sub_activity['new'].title;
    result.subtitle = this.activity.sub_activity['new'].description;
    result.parent_id = this.activity.entity;
    result.message_type = this.activity.sub_activity['new'].message_type;

    result.category_type_hex = this.activity.sub_activity['new'].category_type_hex;
    result.message_type = this.activity.sub_activity['new'].message_type;
    result.entity = "new";
    result.author = GlobalVariables.username;    

    result.assigned_group_names = this.activity.sub_activity['new'].assigned_group_names;
    result.assigned_group_names_entity = this.activity.sub_activity['new'].assigned_group_names_entity;
  
    //////console.log('****************************************************');
    //////console.log(this.activity.sub_activity['new']);
    result.assigned_users_names = this.activity.sub_activity['new'].assigned_users_names;
    result.assigned_users_names_entity = this.activity.sub_activity['new'].assigned_users_names_entity;
  
    result.priority = this.activity.sub_activity['new'].priority;
    result.customer_id = this.activity.customer_id;
    result.customer = this.activity.customer_id;
  
    //console.log(this.activity.sub_activity['new']);
    
    this.parameters = { data: result };
  
    ////////console.log(this.parameters);

    var callback_entity = null;
    //console.log(this.parameters);
    
    this.data.saveActivity(this.parameters).subscribe((res: Response) => {
      callback_entity = (<any>res).callback.entity;     
      
      this.data.updatePriority({ activity_entity : callback_entity, priority : result.priority }).subscribe((res: Response) => {});
      
      this.data.updateCategory({ activity_entity : callback_entity, message_type : this.activity.sub_activity['new'].category_id }).subscribe((res: Response) => {});

      if (this.activity.sub_activity['new'].user_id != undefined) {
        var helper_users = this.activity.sub_activity['new'].user_id == '' ? null : this.activity.sub_activity['new'].user_id.split(',');    
      }

      if (this.activity.sub_activity['new'].group_id != undefined) {
        var helper_groups = this.activity.sub_activity['new'].group_id == '' ? null : this.activity.sub_activity['new'].group_id.split(',');
      }

      var parameters = { activity_entity : callback_entity, users : helper_users, groups : helper_groups };     
      this.data.updateAssignation(parameters).subscribe((res: Response) => {});
    
      this.data.updateDateTime({ activity_entity : callback_entity, start_date : this.activity.sub_activity['new'].message_start, end_date : this.activity.sub_activity['new'].message_end }).subscribe((res: Response) => {}); 

      this.getActivity(null);
    });
   
  }

  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* Mark on Calendar */
  changeOnCalendar(entity) {
    this.activity.calendar = !this.activity.calendar;
        
    this.data.toggleCalendarActivity({ entity: entity, calendar : this.activity.calendar }).subscribe(
      (res: Response) => {}
    );    
  }


  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* Priorities */
  activityFunctions(entity, refresh) {
    
    var activityFunctionsDialog = this.activityFunctionsDialog.open(ActivityFunctionsComponent, {
      'width' : '280px',
      data: { activity: entity }
    });    

    
    activityFunctionsDialog.afterClosed().subscribe(result => {     
      if (result == true) {
        if (refresh != false) this.getActivity(null);
      }
    });
    
  }


  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* Set Extended view */
  set_extended(extended_view_left) {
		//this.editor == event.target.value;		
		//localStorage.setItem('editor', this.editor);    
    this.data.setMemberSettings({'parameter':'extended_view_left', 'value': extended_view_left}).subscribe((res: Response) => {});        
	}



  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* Priorities */
  assignDateTime(entity, start, end, refresh) {   
    if (this.activity.canEdit=='true') {
      var data = { activity: entity, start_date_raw : start, end_date_raw : end };
      var assignDateTimeDialog = this.assignPriorityDialog.open(AssignDateTimeComponent, {
        'width' : '450px',        
        data: data
      });    

      assignDateTimeDialog.afterClosed().subscribe(result => {
        var activity = result.activity;
        var start_date = result.start_date;
        var end_date = result.end_date;
        if (entity != 'new') {          
            var parameters = { activity_entity : result.activity, start_date : result.start_date, end_date : result.end_date };
            ////console.log(parameters);
            this.data.updateDateTime(parameters).subscribe((res: Response) => {
              if (refresh != false) this.getActivity(null);
            }); 
          } else {
            this.activity.sub_activity[entity].message_end = result.start_date;
            this.activity.sub_activity[entity].message_start = result.end_date;
            
        }
      });
    }

  }

  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* DateTime */
  assignPriority(entity, refresh) {
    if (this.activity.canEdit=='true') {
      var assignPriorityDialog = this.assignPriorityDialog.open(AssignPriorityComponent, {
        'width' : '250px',
        data: { activity: entity }
      });    

      assignPriorityDialog.afterClosed().subscribe(result => {     
        if (result.priority != null) {
          if (entity != 'new') {
            var parameters = { activity_entity : result.activity, priority : result.priority };
            this.data.updatePriority(parameters).subscribe((res: Response) => {
              if (refresh != false) this.getActivity(null);
            }); 
          } else {
            this.activity.sub_activity[entity].priority = result.priority;
          }
        }
      });
    }
  }

  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* Time tracking */
    
  addTimeTracking(comment = null) {    
    if (comment == null ) comment = this.activity.title;
    var editDialog = this.dialog.open(TimetrackingEditComponent, {            
      data: {
        activity_id: this.activity.entity,
        activity: this.activity.title,
        customer_id: this.activity.customer_id,
        customer: this.activity.customer,
        comment: comment
      }        
    });
    editDialog.afterClosed().subscribe(result => {
      //this.refreshData();
    });
  }

  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* Featured */
  toggleFavoritesActivity(entity, featured) {
    var parameters = { activity_entity : entity, featured : featured };
    this.data.toggleFavoritesActivity(parameters).subscribe((res: Response) => {
      //if (refresh != false) this.getActivity(null);
      //////console.log(res);
      this.activity.featured = res['status'];
    });
  }

  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* Hidden */
  toggleHideActivity(entity, hide) {
    var parameters = { activity_entity : entity, hide : hide };
    this.data.toggleHideActivity(parameters).subscribe((res: Response) => {
      //if (refresh != false) this.getActivity(null);
      //////console.log(res);
      this.activity.hide = res['status'];
    });
  }


  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* Types/Category */
  assignCategory(entity, refresh) {
    
    var assignCategoryDialog = this.assignCategoryDialog.open(AssignCategoryComponent, {
      'width' : '350px',
      data: { categories: this.categories, currentCategory: 1, activity: entity }
    });    

    assignCategoryDialog.afterClosed().subscribe(result => {      
      if (result.currentCategory != null) 
        if (entity != 'new') {
          if (entity != 0) {
            this.data.updateCategory({ activity_entity : result.activity, message_type : result.currentCategory }).subscribe((res: Response) => {            
              this.activity.category_type = result.currentCategory;
              //////console.log('#1');
              this.activity.category_type_description = result.description;
              this.activity.category_type_hex = result.hex;
              if (refresh != false) this.getActivity(null);
              ////////console.log(this.activity);
            });
          } else {
            //in case creating new activity
            this.activity.message_type = result.currentCategory;
            this.activity.category_type = result.currentCategory;
            //////console.log('#2');
            this.activity.category_type_description = result.description;
            this.activity.category_type_hex = result.hex;            
            ////////console.log(this.activity);
          }
        } else {          
          this.activity.sub_activity[entity].category_id = result.currentCategory;        
          for (var i=0;i<this.categories.length; i++) {
            if (this.categories[i].entity == result.currentCategory) {              
              this.activity.sub_activity[entity].category_type_description = this.categories[i].description;
              this.activity.sub_activity[entity].category_type_hex = this.categories[i].hex;
            }
          }
        }
    });

  }

  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* Select sub-activity */

  selectActivity() {
    var ActivitySelectDialog = this.dialog.open(ActivitySelectComponent, {});
    ActivitySelectDialog.componentInstance.params = { 'parent_id': null };
    ActivitySelectDialog.afterClosed().subscribe(result => {      
      if (!isNaN(result.entity)) {  
        /*alert(result.entity + "|" + this.entity);        */
        this.data.updateParentId({ activity_entity : result.entity, parent_id: this.entity }).subscribe((res: Response) => {            
              this.getActivity(null);           
        });
        
        /*
        this.activity_id = result.entity;
        this.activity = result.activity;
        if (this.customer_id == '' || this.customer_id == null) {
          this.customer_id = result.customer_id;
          this.customer = result.customer;      
        } 
        */       
      }
    });       
  }





  /************************************************************/
  /************************************************************/
  /************************************************************/














  /************************************************************/
  /************************************************************/
  /************************************************************/
  /* Member or Group Assignation */

  /* for tasks */
  assignUserGroupToTask(entity) {
    ////console.log(entity);
    ////console.log(this.tasks);
    var assigned_users_names_entity = [];
    var assigned_group_names_entity = [];

    this.tasks[entity]['assign'] = entity;

    if (this.tasks[entity].assigned_users_names_entity != undefined) assigned_users_names_entity = this.tasks[entity].assigned_users_names_entity.split(',').map(Number);
    if (this.tasks[entity].assigned_group_names_entity != undefined) assigned_group_names_entity = this.tasks[entity].assigned_group_names_entity.split(',').map(Number);
    
    //assigned_users_names_entity = assigned_users_names_entity.join(",").split(",").map(Number);
    //assigned_group_names_entity = assigned_group_names_entity.join(",").split(",").map(Number);
 
    var assignUserGroupDialog = this.assignUserGroupDialog.open(AssignUserGroupComponent, {
      'width' : '480px',
      data: { membersSelected: assigned_users_names_entity, groupsSelected: assigned_group_names_entity, members: this.members, groups: this.member_groups, activity: entity }
    });

    assignUserGroupDialog.afterClosed().subscribe(result => {   
      ////console.log(result);
      if (result != false) {
        var assigned_users_names = [];
        for (var i=0; i<this.members.length; i++) {
          if (assigned_users_names_entity.indexOf(this.members[i].entity) > -1) assigned_users_names.push(this.members[i].username);          
        }

        var assigned_group_names = [];
        for (var i=0; i<this.member_groups.length; i++) {
          ////////console.log(this.member_groups[i].entity);
          if (assigned_group_names_entity.indexOf(this.member_groups[i].id) > -1) assigned_group_names.push(this.member_groups[i].member_group);          
        }

        if (result.membersSelected.length > 0) this.tasks[entity].assigned_users_names_entity = result.membersSelected.join(',');
        if (result.groupsSelected.length > 0) this.tasks[entity].assigned_group_names_entity = result.groupsSelected.join(',');        
        this.tasks[entity].assigned_users_names = assigned_users_names.join(',');
        this.tasks[entity].assigned_group_names = assigned_group_names.join(',');
      }
    });
  }

  assignUserGroup(entity) {
    
    var assigned_users_names_entity = [];
    var assigned_group_names_entity = [];

    if (entity == undefined) {   
      if (this.activity.user_id != undefined) assigned_users_names_entity = this.activity.user_id.split(',').map(Number);
      if (this.activity.group_id != undefined) assigned_group_names_entity = this.activity.group_id.split(',').map(Number);
    } else {
      if (this.activity.sub_activity[entity].user_id != undefined) assigned_users_names_entity = this.activity.sub_activity[entity].user_id.split(',').map(Number);
      if (this.activity.sub_activity[entity].group_id != undefined) assigned_group_names_entity = this.activity.sub_activity[entity].group_id.split(',').map(Number);
    }

    ////////console.log({ membersSelected: assigned_users_names_entity, groupsSelected: assigned_group_names_entity, members: this.members, groups: this.member_groups, activity: entity });

    //console.log(this.members);

    var assignUserGroupDialog = this.assignUserGroupDialog.open(AssignUserGroupComponent, {
      'width' : '480px',
      panelClass: 'user_group_selector',
      data: { membersSelected: assigned_users_names_entity, groupsSelected: assigned_group_names_entity, members: this.members, groups: this.member_groups, activity: entity }
    });    

    
    assignUserGroupDialog.afterClosed().subscribe(result => {   
      ////////console.log(result);
      if (entity != 'new') {
        result.activity = result.activity != null ? result.activity : this.entity;
        var parameters = { activity_entity : result.activity, users : result.membersSelected, groups : result.groupsSelected };
        
        if (result.activity == null) { 
          alert('Error!!!!'); 
        } else {
          this.data.updateAssignation(parameters).subscribe((res: Response) => {
            //if (refresh != false) this.getActivity(null);
            this.getActivity(null);
            //alert('tuki 1');
          });
        }
      } else {
        /* At new activity only */
        //alert('tuki 2');
        var assigned_users_names = [];
        for (var i=0; i<this.members.length; i++) {
          if (assigned_users_names_entity.indexOf(this.members[i].entity) > -1) assigned_users_names.push(this.members[i].username);          
        }

        var assigned_group_names = [];
        for (var i=0; i<this.member_groups.length; i++) {
          ////////console.log(this.member_groups[i].entity);
          if (assigned_group_names_entity.indexOf(this.member_groups[i].id) > -1) assigned_group_names.push(this.member_groups[i].member_group);          
        }

        this.activity.sub_activity[entity].user_id = assigned_users_names_entity.join(',');
        this.activity.sub_activity[entity].group_id = assigned_group_names_entity.join(',');
        
        this.activity.sub_activity[entity].assigned_users_names = assigned_users_names.join(',');
        this.activity.sub_activity[entity].assigned_group_names = assigned_group_names.join(',');
      }
    });
    
    
  }

  /************************************************************/

  download(filename, original_filename) {   
    this.data.downloadDocument(filename, original_filename);   
  }
  /*
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  } 
  */

  localDateTimeFormat(datetime) {
    datetime = new Date(datetime);   
    var options = { hour12: false };   
    return datetime.toLocaleString("sl-SI", options).replace('. ', '.').replace('. ', '.');
  }

  showFileIcon(ext) {
    if (ext == 'doc' || ext == 'docx') {
      return "file-word";
    } else if (ext == 'xls' || ext == 'xlsx') {
      return "file-spreadsheet";
    } else if (ext == 'zip' || ext == 'gz' || ext == 'rar') {
      return "file-archive";
    } else if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'svg' || ext == 'tif' || ext == 'gif') {
      return "file-image";
    } else if (ext == 'mov' || ext == 'mp4') {
      return "file-video";
    } else if (ext == 'ppt') {
      return "file-powerpoint";
    } else if (ext == 'pdf') {
      return "file-pdf";
    } else {
      return "file";
    }
    //,fasFileDownload,,,,,,,fasFolder
  }


  

  

  /************************************************************/
  /************************************************************/
  /************************************************************/




  /******************************************************************/
	/******************************************************************/
	/******************************************************************/
	/* eTalk             				                                      */
  createETalkTimeStamp() {
    var helper = (Date.now());
    var helper_random = (Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000);
    var helper_string = helper.toString();
    var parts = helper_string.match(/[\s\S]{1,4}/g) || [];

    var etalk_id = parts[0] + "-" + parts[1] + "-" + parts[2] + "-" + helper_random;
    
    this.data.saveETalkID({'etalk':etalk_id, 'entity':this.activity['entity']}).subscribe(
      (res: Response) => {
        ////console.log(res);
      }
    );

  }

  getETalkAttachments(entity) {
   /*************************/
    /* get attachments */
    //this.parameters = { 'items' : 10000, 'order' : 'doc_title', 'orderby' : 'asc', search: null, 'field' : 'activity', 'value' : this.activity.entity };    
    this.data.getDocuments({'items' : 10000, 'order' : 'doc_title', 'orderby' : 'asc', search: null, 'field' : 'etalk', 'value' : entity}).subscribe(
      (res: Response) => {
        this.eTalkAttachments = (<any>res).rows;                        
        //////console.log(res);
      }
    );
  }

  sendETalk() {
    var data = {};

    ////console.log(this.activity);
        
    data['etalk_id'] = this.activity['etalk'];
    data['parent_entity'] = this.activity['entity'];
    data['email'] = this.eTalk_email;
    data['cc'] = this.eTalk_cc;
    data['direction'] = 'OUT';
    data['subject'] = this.eTalk_subject;
    data['body'] = this.eTalk_content;
    data['email_name'] = "Aljoša Motore";
    
    //////console.log(data);
    /* Add markers for following chats/etalks */
    var helper = "[--- Prosimo, da odgovarjate nad to črto in spodnje vsebine ne spreminjate ---]";
    helper += "<br />[#"+this.activity['etalk']+"]<br /><br />";
    data['body'] = helper + data['body'] + "<br /><br />" + this.footer;

    this.data.sendETalk({data:data, entity:this.activity['entity']}).subscribe(
      (res: Response) => {
        ////console.log(res);
          this.etalk_send_view = false;
          this.data.getETalk({ 'items' : 100, 'order' : 'entity', 'orderby' : 'asc', 'etalk' : this.activity.etalk }).subscribe(
            (res: Response) => {
              this.etalk = <any>res['rows'];
              this.eTalk_email = this.etalk[this.etalk.length-1].email;
              this.eTalk_cc = this.etalk[this.etalk.length-1].cc;
              this.eTalk_subject = this.etalk[this.etalk.length-1].subject;        
              this.eTalk_content = "";
            }
          );
        
      }
    );

  }

  /******************************************************************/
	/******************************************************************/
	/******************************************************************/
	/* Edit user 				                                              */
  edit_user(entity) {    
    ////console.log(entity);
    var UserDialog = this.dialog.open(UserComponent, {});
    UserDialog.componentInstance['entity'] = parseInt(entity);
    UserDialog.afterClosed().subscribe(result => {       
    });
  }


  /******************************************************************/
	/******************************************************************/
	/******************************************************************/
	/* Uploader 				                                              */

	public files: any[] = [];
	public selectedFiles: FileList;
  	public currentFile: File;
  	public progress = 0;  
  	public fileInfos: Observable<any>;
  	private document_details;

	prepareFilesList(files: Array<any>) {
    //console.log(files);
		for (const item of files) {
			item.progress = 0;
			this.files.push(item);
		}
		this.uploadFiles(0);
	}

	onFileDropped($event) {
    //console.log($event);
		this._openDialog();
		////////console.log($event);
		(document.getElementsByClassName("dropdowninfo")[0] as HTMLElement).style.visibility = "hidden";		
		var editDialog = this.dialog.open(DocumentEditComponent, { 'width' : '800px', data : { files : $event, prefill : {call: 'activity', customer: this.activity.customer, customer_id: this.activity.customer_id, entity: this.activity.entity, title: this.activity.title, category: '?', category_id: '?'} } });
		editDialog.afterClosed().subscribe(result => {	    
			if (result != undefined && result != '') {
        //console.log('#1');
				this.document_details = result.value;
				this.prepareFilesList($event);
			} else {
				const element = document.getElementsByClassName("file_container_activity")[0] as HTMLElement;
				element.style.visibility = "hidden";
				element.style.opacity = "0";    
				const element2 = document.getElementsByClassName("dropdowninfo_activity")[0] as HTMLElement;
				element2.style.visibility = "";
				this.files = [];	
			}
		});		     
	}

	closeDropField() {
		const element = document.getElementsByClassName("file_container_activity")[0] as HTMLElement;
		element.style.visibility = "hidden";
		element.style.opacity = "0";    
		const element2 = document.getElementsByClassName("dropdowninfo")[0] as HTMLElement;
		element2.style.visibility = "";
		this.files = [];    
	}

	fileBrowseHandler(files) {
		//////console.log('fileBrowseHandler');
		//this.prepareFilesList(files);
	}

	deleteFile(index: number) {
		this.files.splice(index, 1);
	}
  
  uploadFiles(index: number) {
		this.upload(this.files[index]);
		setTimeout(() => {
			if (index === this.files.length) {
				(document.getElementsByClassName("file_container_activity")[0] as HTMLElement).style.visibility = "hidden";
				(document.getElementsByClassName("file_container_activity")[0] as HTMLElement).style.opacity = "0";    
				(document.getElementsByClassName("dropdowninfo")[0] as HTMLElement).style.visibility = "";       
				this.files = [];
        this.getActivity(this.id);
				return;
			} else {
				const progressInterval = setInterval(() => {
					if (this.files[index] != undefined) {
						if (this.files[index].progress === 100) {
							clearInterval(progressInterval);	            
							this.uploadFiles(index + 1);            
						} else {
							this.files[index].progress = this.progress;	            
						}
					}
				}, 50);
			}
		}, 50);		
	}

	/* upload 1 file at once */
	upload(currentFile): void {    
		this.progress = 0;
    //console.log(currentFile);
		this.UploadService.upload(currentFile).subscribe(	    
			event => {
				if (event.type === HttpEventType.UploadProgress) {
					this.progress = Math.round(100 * event.loaded / event.total);	 	        
				} else if (event instanceof HttpResponse) {
					if (event.body.name != undefined) {
						this.document_details['doc_file'] = event.body['name'];
						this.document_details['doc_code'] = event.body['original_name'];
						this.document_details['doc_size'] = currentFile.size;	        
						//console.log(this.document_details);
						this.data.saveDocument(this.document_details).subscribe(
							(res: Response) => {}				
						);
					}
				}
			},
			err => {
				this.progress = 0;
				//this.message = 'Could not upload the file!';
				currentFile = undefined;
			}
		);
		this.selectedFiles = undefined; 
	}

	formatBytes(bytes, decimals) {
		if (bytes === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals || 2;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	} 
 
  showAttachment(attachment) {
    var filename = attachment.split('.');
    var helper = "https://api.biliz.com/storage/" + this.storage + "/" + attachment.charAt(0) + "/" + attachment.charAt(1) + "/" + attachment;	    
    return helper;
  }




































/****************************************/
/* Checked                              */
/****************************************/

/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/* Common functions */
  countObject(selectedObject) {
    return Object.keys(selectedObject).length;
  }

/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/* Activities */
countActivities() {
  //console.log('//countActivities');
  this.subactivityCount = 0;
  this.subactivityApproved = 0;
  if (this.activity.sub_activity != undefined) {
    this.subactivityApproved = 0;
    this.subactivityCount = 0; //Object.keys(this.activity.sub_activity).length;
    for (const [key, value] of Object.entries(this.activity.sub_activity)) {                
      if (key != 'new') {
        if (this.activity.sub_activity[key]['approved'] == 'true') {
          this.subactivityApproved++;                
        }
        this.subactivityCount++;
      }      
    }              
  } else {
    this.subactivityCount = 0;
  }
}

/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/* Charts */

  redrawChart(): void {
    if (this.myChart) {
      this.myChart.dispose();
    }
    this.generateChart();
  }

  generateChart() {
    
    //console.log(this.tasks);

    this.subactivityCount = 0;
    this.subactivityApproved = 0;
    this.tasks_count = 0;
    this.tasks_done = 0;

    this.countTasks();
    this.countActivities();
        
    let activityProgress = this.subactivityCount != 0 ? this.subactivityApproved / this.subactivityCount * 100 : 0;
    let taskProgress = this.tasks_count != 0 ? this.tasks_done / this.tasks_count * 100 : 0;
    
    let ChartCount = 0;
    if (activityProgress != 0) { ChartCount++; }
    if (taskProgress != 0) { ChartCount++; }
    
    if (ChartCount > 0) {
      let overallProgress = (activityProgress + taskProgress) / ChartCount;
      
      this.overallProgressText = overallProgress.toFixed(0);
      
      //console.log('//generateChart');
      //console.log(this.subactivityApproved, this.subactivityCount, activityProgress);
      //console.log(this.tasks_done, this.tasks_count, taskProgress);      
      
      let myChart: any = null;      
      if (document.getElementById('progressChart') != null) {      
        myChart = echarts.init((document.getElementById('progressChart')) as any);
        
        let chartData = {
          angleAxis: {
            max: 100,
            axisLabel: { color: "#cccccc", fontSize: 14 },
            axisLine: { lineStyle: { color: "#ffffff", width: 1, type: "dashed" } }
          },
          textStyle: { fontFamily: "'Poppins', sans-serif", fontSize: 14 },
          radiusAxis: { type: 'category', data: [], z: 10, show: true, axisLine: { lineStyle: { width: 3 } } },
          colorBy: "series",
          color: ["#4295c3", "#429589", "#f19a45", "#eeeeee"],   
          polar: {},   
          series: [
            { type: 'bar', data: [0, 0, taskProgress, 0, 0], roundCap: true, coordinateSystem: 'polar', name: 'Tasks', stack: 'main', emphasis: { focus: 'series' } },
            { type: 'bar', data: [0, 0, 0, activityProgress, 0], roundCap: true, coordinateSystem: 'polar', name: 'Sub Activities', stack: 'main', emphasis: { focus: 'series' } },
            { type: 'bar', data: [0, 0, 0, 0, overallProgress], roundCap: true, coordinateSystem: 'polar', name: 'Overall', stack: 'main', emphasis: { focus: 'series' } }
          ],
          legend: { show: true, data: ['Tasks', 'Sub Activities', 'Overall', 'Blank'] }
        };
        
        myChart.setOption(chartData);
        
      }
    }
  }


/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/* Activity Wizzard */

  public templateName = "";  
  public activitiesWizzardCreateMode = true;

  activityWizzardSetup() {
    this.activeActivitiesWizzard = this.activity['sub_activity'];
    this.activitiesWizzardCreateMode = true;
    this.activityWizzardPopup = true;
    this.taskWizzardPopUp = false;    
  }

  activityWizzardSave() {
    if (this.templateName == '') this.templateName = "Activity Teamplate";
    this.data.saveWizzard({data:{title:this.templateName, code:JSON.stringify(this.activeActivitiesWizzard)}}).subscribe((res: Response) => {
      this.data.getWizzard({}).subscribe(
        (res: Response) => { this.activityWizzards = (<any>res).rows; }
      );
    });
    this.activityWizzardPopup = false;
  }

  activityWizzardHide() {
    this.activityWizzardPopup = false;
  }

  activityWizzardUse(entity) {
    this.activeActivitiesWizzard = null;
    this.activitiesWizzardCreateMode = false;
    
    for (let i = 0; i < this.activityWizzards.length; i++) {
      if (this.activityWizzards[i]['entity']==entity) {
        this.activeActivitiesWizzard = JSON.parse(this.activityWizzards[i]['code']);
        //console.log(this.activeActivitiesWizzard);
        this.activityWizzardPopup = true;
        this.taskWizzardPopUp = false;
      }
    }    
  }

  activityWizzardApprove() {
    //console.log(this.activeActivitiesWizzard);
  }

  /*
  prepareWizzardActivity(activities, parent = null) {
    //console.log(activities);

    for (var key in activities) {
      if (activities.hasOwnProperty(key)) {
        //console.log(activities[key].entity, activities[key].title);        
      }
    }
  }
  */

  
  

  enableDisableWizzard(entity, event = null, tempActivities = this.activity['sub_activity'], parent = true) {    
    for (var key in tempActivities) {
      if (tempActivities.hasOwnProperty(key)) {
        if (tempActivities[key]['entity'] == entity) {          
          tempActivities[key]['enabled'] = event.target.checked;
        }
        
        if (tempActivities[key]['sub_activity'] != null) {
          if (Object.keys(tempActivities[key]['sub_activity']).length) {
            tempActivities[key]['sub_activity'] = this.enableDisableWizzard(entity, event, tempActivities[key]['sub_activity'], false);
          }
        }
        
      }
    }
    if (parent == true) {
      this.activity['sub_activity'] = tempActivities;
      //console.log(this.activity['sub_activity']);
    } else {
      return tempActivities;
    }
  }


/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/* Tasks */

  /******************/
  /* add procedures */
  addTask(group_id = 0, group_title = null) {
    if (group_title == null) group_title = this.translate.instant("New group...");    
    this.addNewTask(this.activity.entity, this.tasks, '', false, null, group_id, group_title);
    this.groupTasks();
    this.viewAddTask = true;    
  }

  addNewTaskGroup() {    
    this.selectedGroup = "0";
    if (Object.keys(this.tasks_groupped).length > 0) {      
      for (var key in this.tasks_groupped) {        
        if (parseInt(key) > parseInt(this.selectedGroup)) {
          this.selectedGroup = key;
        }
      }
    }
    let group_id = parseInt(this.selectedGroup)+10;
    let group_title = this.translate.instant("New checklist group...");
    this.selectedGroup = group_id.toString();    
    this.addNewTask(this.activity.entity, this.tasks, '', false, null, group_id, group_title);    
    this.groupTasks();          
    this.viewAddTask = true;     
  }

  removeTaskGroup(entity) {

    let content = this.translate.instant("Do you want to remove whole checklist group?");
    let modalDialog = this.dialog.open(ModalComponent, {
      data: { inputText: null, content: content, yes: this.translate.instant("Remove") , no: null, cancel: this.translate.instant("Cancel") } 
    });

    modalDialog.afterClosed().subscribe(result => {          
      if (result['yes']!=undefined) {
        let helper = this.tasks;
        for (const [key, value] of Object.entries(helper)) {
          if (helper[key]['group_id']==entity) {
            delete this.tasks[key];
          }
        }    
        this.groupTasks();    
        this.saveTasks();
      }
    });

    
  }

  addNewTask(entity, tasks = this.tasks, value = '', status = false, task_entity = null, group_id = 0, group_title = null, author = null, author_id = null, assigned_users_names = null, assigned_users_names_entity = null, assigned_group_names = null, assigned_group_names_entity = null, progress = null) {

    if (group_title == null) group_title = this.translate.instant("New group..."); 
    
    let id = 100; for (let key in this.tasks) { id = id <= parseInt(key) ? parseInt(key) + 1 : id; }

    let final_id = entity.toString() + "000" + id.toString();    
    final_id = entity.toString() + final_id.substr(final_id.length - 3);    

    let date = new Date();
    let current_date = date.getDate() + "." + (date.getMonth()+1) + "." + date.getFullYear();
    let current_time = date.getHours() + ":" + date.getMinutes();
    
    let time = current_date + " " + current_time;
    if (author == null) author = GlobalVariables.username;
    if (author_id == null) author_id = GlobalVariables.id;
    this.tasks[final_id] = { status : status, text : value, assign: final_id, edit : true, group_id : group_id, group_title : group_title, time : time, author : author, author_id : author_id, assigned_users_names : assigned_users_names, assigned_users_names_entity : assigned_users_names_entity, assigned_group_names : assigned_group_names, assigned_group_names_entity : assigned_group_names_entity, progress : progress};
    
    /* switdh activity tab */
    this.selectedTab = 'checklist';

    return this.tasks;
  }

  /*********************/
  /* delete procedures */
  removeTask(id) {
    delete this.tasks[id];
    if (Object.keys(this.tasks).length == 0) {
      this.selectedTab='summary';      
      this.edit_task=false; 
      this.viewAddTask=false;
    }    
    this.saveTasks();    
    this.groupTasks();        
  }

  confirm() {
    this.saveTasks();    
  }

  /*******************/
  /* save procedures */
  saveTasks(entity = this.activity.entity, tasks = this.tasks, comments_params = null, group_id = 0, group_title = "New checklist group...") {            

    this.data.getTask({id:entity}).subscribe(
      (res: Response) => {                
        let updatedTasks = JSON.parse(res['rows'][0]['tasks']);        

        if (updatedTasks == null) { updatedTasks = {}; }

        for (let key in tasks) {          
          if (tasks[key].edit != undefined) {
            if (updatedTasks[key] == undefined) {              
              if (tasks[key]['text']!="") {              
                updatedTasks[key] = {};
                updatedTasks[key] = tasks[key];
              }
            } else {              
              if (tasks[key]['text']!="") {                
                updatedTasks[key] = tasks[key];
              }
            }            
          }            
        }
                   
        /* remove "deleted" tasks */
        for (let key in updatedTasks) {          
          if (tasks[key] == undefined || tasks[key]['text']=="") {
            delete updatedTasks[key];
          }
        }
        
        /* hide all confirm all buttons on progress bar */        
        for (const key in updatedTasks) {
          if (updatedTasks.hasOwnProperty(key)) {
            updatedTasks[key]['progress_saved'] = true;
          }
        }

        /* form data model */
        let data = {};
        this.customer_change = false;
        data['tasks'] = JSON.stringify(updatedTasks);
        data['entity'] = entity;
        data['comments_params'] = comments_params;        
        
        /* save changes */    
        this.removeEmptyTasks();    
        this.data.saveTasks({ data: data }).subscribe( (res: Response) => { 
          this.countTasks();           
          /* for progress chart needs */
          data['tasks_count'] = this.tasks_done + "/" + this.tasks_count;
          this.tasks_count_text = data['tasks_count'];
          this.tasks_percent = (this.tasks_done / this.tasks_count * 100).toFixed(0);
          this.handleTaskConditions();
          this.handleTaskRestrictions();  
          this.groupTasks();
        });
      }
    );


  }

  saveTaskGroupTemplate(group_id, group_title) {
    let content = this.translate.instant("Input checklist group template name");
    let modalDialog = this.dialog.open(ModalComponent, {
      data: { inputText: group_title, content: content, yes: this.translate.instant("Save") , no: null, cancel: this.translate.instant("Cancel") } 
    });

    modalDialog.afterClosed().subscribe(result => {          
      if (result['yes']!=undefined) {
        let newTaskTitle = result['value'];
        if (newTaskTitle != "") {
          let templateTasks = {};
          let new_group_id = this.makeid(5);
          for (var key in this.tasks) {
            if (this.tasks[key]['group_id']==group_id) {
              this.tasks[key]['group_title'];
              templateTasks[key] = { status: false, text: this.tasks[key]['text'], assign: this.tasks[key]['assign'], group: new_group_id, group_title: newTaskTitle };
            }
          }       
          this.data.saveTaskTemplate( {data:{title: newTaskTitle, content: JSON.stringify(templateTasks)}}).subscribe( (res: Response) => {} );   
        }        
      }
    });
  }
 
  /********************/
  /* update functions */
  editTaskGroup(group_id, group_title) {
    var content = this.translate.instant("Input new checklist group name");
    var modalDialog = this.dialog.open(ModalComponent, {
      data: { inputText: group_title, content: content, yes: this.translate.instant("Change") , no: null, cancel: this.translate.instant("Cancel") } 
    });

    modalDialog.afterClosed().subscribe(result => {      
      if (result['yes']!=undefined) {
        let newTaskTitle = result['value'];
        if (newTaskTitle != "") {
          for (var key in this.tasks) {
            if (this.tasks[key]['group_id']==group_id) {
              this.tasks[key]['group_title'] = newTaskTitle;
            }
          }
          this.groupTasks();
          this.saveTasks();
        }        
      }
    }); 
  }

  /*************************/
  /* common task functions */
  countTasks() {
    //console.log('//countTasks');
    //console.log(this.tasks);
    this.tasks_count = 0;
    this.tasks_done = 0;    
    for (let key in this.tasks) {      
      if (this.tasks[key].status == true) this.tasks_done++;
      this.tasks_count++;
    }    
  }

  removeEmptyTasks() {
    let helper = this.tasks;    
    for (const [key, value] of Object.entries(helper)) {
      if (helper[key]['text']=='') {
        delete this.tasks[key];
      }
    }    
  }

  cancelTaskChange() {    
    let helper = this.tasks;
    let lastKey = null;
    
    if (this.viewAddTask) {
      for (const [key, value] of Object.entries(helper)) {    
        if (value['group_id']==this.selectedGroup) {
          lastKey = key;
        }
      } 

      if (lastKey != null) delete this.tasks[lastKey];
    }
    
    this.edit_task=false; 
    this.viewAddTask=false;
    this.removeEmptyTasks();
    this.groupTasks();
    this.countTasks();

    /* switch tab if there is no tasks */
    if (Object.keys(this.tasks).length == 0) { this.selectedTab='summary'; }    
  }

  checkTaskAssignation(assigned) {
    return (assigned == "" || assigned == null || assigned.includes(GlobalVariables['username']));
  }



  /******************************/
  /* popups / settings / widget */
  SwitchHideFinishedTasks() {    
    this.hideFinishedTasks=true;    
    localStorage.setItem('hideFinishedActivityView', 'true');
  } 

  SwitchOnlyMine() {
    this.onlymine = true;
    localStorage.setItem('showOnlyMine', 'true');
  }

  SwitchShowAllTasks() {
    this.onlymine = false;    
    localStorage.setItem('showOnlyMine', 'false');    
    this.hideFinishedTasks = false;    
    localStorage.setItem('hideFinishedActivityView', 'false');    
  }

  openTaskWizzard() {
    this.data.getTaskTemplate({data:{entity: null}}).subscribe(
      (res: Response) => {
        this.taskTeamplates = res['rows'];
        this.taskWizzardPopUp = true;        
      }
    );
  }

  addCheckListWidget() {
    this.add_popup=false;
    this.edit_task=false; 
    this.viewAddTask=true;     
    this.addNewTask(this.activity.entity);    
    this.groupTasks();    
  }

  showHideTaskComments() {
    this.showTaskComments = !this.showTaskComments;
    this.data.setMemberSettings({'parameter': 'showTaskComments', 'value': this.showTaskComments}).subscribe((res: Response) => {}); 
  }

  changeTaskText(event:any, id) {
    this.tasks[id].text = event.target.value;
    this.tasks[id]['edit'] = true;    
    
    if (event.which == 13) {           
      if (this.tasks[id].text == "") {
        //this.removeTask(id);
        this.cancelTaskChange(); 
        this.edit_task=false; 
        this.viewAddTask=false;
      } else {
        this.addNewTask(this.activity.entity, this.tasks, '', false, null, this.toInt(this.selectedGroup), this.selectedGroupText); 
        this.groupTasks();
        this.saveTasks();
      }
    }
  }

  changeTaskState(id) {
    this.tasks[id].status = !this.tasks[id].status;
    this.tasks[id]['edit'] = true;
    this.handleTaskConditions();
    this.handleTaskRestrictions();
    this.groupTasks();
    this.saveTasks();
  }

  groupTasks() {
    this.tasks_groupped = {};
    this.tasksDragAndDrop = {};
    if (this.tasks != null) {
      for (const [key, value] of Object.entries(this.tasks)) {
        //this.tasks[key]['assign'] = key;                
        
        if (this.tasks[key]['group_id'] == undefined) {
          this.tasks[key]['group_id'] = 0;
          this.tasks[key]['group_title'] = this.translate.instant("Default checklist group...");
        }
        
        if (this.tasks_groupped[this.tasks[key]['group_id']] == undefined) {
          this.tasks_groupped[this.tasks[key]['group_id']] = {};
          this.tasks_groupped[this.tasks[key]['group_id']]['title'] = this.tasks[key]['group_title'];
          this.tasks_groupped[this.tasks[key]['group_id']]['tasks'] = {};
        }

        //this.tasks_groupped[this.tasks[key]['group_id']][key] = {};
        this.tasks_groupped[this.tasks[key]['group_id']]['tasks'][key] = this.tasks[key];

        if (this.tasks[key]['status'] == true) { this.tasks_done++; }
        this.tasks_count++;      

        /*********************/
        /* Drag & Drop array */
        this.tasksDragAndDrop
        if (this.tasksDragAndDrop[this.tasks[key]['group_id']] == undefined) {
          this.tasksDragAndDrop[this.tasks[key]['group_id']] = [];          
        }
        this.tasksDragAndDrop[this.tasks[key]['group_id']].push(key);
      }      
    }
  }

  chooseTaskTemplate(entity) {    
    this.taskWizzardPopUp = false;
    let taskTemplate = {};
    for (let key in this.taskTeamplates) {
      if (this.taskTeamplates[key]['entity'] == entity) {        
        taskTemplate = JSON.parse(this.taskTeamplates[key]['content']);
      }
    }
 
    this.selectedGroup = "0";
    if (Object.keys(this.tasks_groupped).length > 0) {      
      for (var key in this.tasks_groupped) {        
        if (parseInt(key) > parseInt(this.selectedGroup)) {
          this.selectedGroup = key;
        }
      }
    }
    let group_id = parseInt(this.selectedGroup)+10;
    let group_title = this.translate.instant("Wizzard group...");
    let task_id = 100; for (let key in this.tasks) { task_id = task_id <= parseInt(key) ? parseInt(key) + 1 : task_id; }

    for (var key in taskTemplate) {
      task_id++;
      this.tasks[task_id] = { status : false, text : taskTemplate[key]['text'], assign: task_id, edit : false, group_id : group_id, group_title : group_title }; 
    }   
    
    this.groupTasks();
    this.saveTasks();
  }

  deleteTaskTemplate(entity) {
    var content = this.translate.instant("Do you want parmenently delete task template?");
    var modalDialog = this.dialog.open(ModalComponent, {
      data: { content: content, yes: this.translate.instant("Delete") , no: null, cancel: this.translate.instant("Cancel") }
    });

    modalDialog.afterClosed().subscribe(result => {
      if (result['yes']!=undefined) {
        this.data.deleteTaskTemplate( {data:{entity: entity}}).subscribe( (res: Response) => {
          this.openTaskWizzard();
        });    
      }
    });           
  }

  /**************************/
  /* TaskDropDown Functions */
  drop(event: CdkDragDrop<string[]>) {
    transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    //console.log('//taskMOve')
    //console.log(this.tasksDragAndDrop);

    /* fix group_id for all tasks */
    for (let key in this.tasksDragAndDrop) {      
      for (let i=0; i<this.tasksDragAndDrop[key].length; i++) {
        this.tasks[this.tasksDragAndDrop[key][i]]['group_id'] = key;
      }     
    }

    //this.activity['comments-task']['rows']

    //reorder inner groups
    let index = (this.entity*1000)+99;
    let tempTasks = {};
    let oldAssign = null;
    for (let key in this.tasksDragAndDrop) {      
      for (let i=0; i<this.tasksDragAndDrop[key].length; i++) {
        index++;
        tempTasks[index] = this.tasks[this.tasksDragAndDrop[key][i]];        
        oldAssign = tempTasks[index]['assign'];
        tempTasks[index]['assign'] = index;
        

        /* fix comments object_id */
        //console.log(key, i, index, tempTasks[index]['text'],this.tasksDragAndDrop[key][i]);
        if (this.activity['comments-task']) {
          for (let j=0; j<this.activity['comments-task']['rows'].length; j++) {
            //console.log(this.activity['comments-task']['rows'][j]['object_id']);
            if (this.activity['comments-task']['rows'][j]['object_id'] == this.tasksDragAndDrop[key][i] && this.activity['comments-task']['rows'][j]['moved'] == undefined) {
              this.activity['comments-task']['rows'][j]['object_id'] = index;
              this.activity['comments-task']['rows'][j]['moved'] = true;
              //console.log(this.activity['comments-task']['rows'][j]);
            }          
          }
        }       

      }     
    }
    
    /* remove edited/moved mark */
    if (this.activity['comments-task']) {
      for (let j=0; j<this.activity['comments-task']['rows'].length; j++) {
        //console.log(this.activity['comments-task']['rows'][j]['entity'], this.activity['comments-task']['rows'][j]['object_id']);
        this.data.updateObjectIDComment({ data: { entity:this.activity['comments-task']['rows'][j]['entity'], object_id:this.activity['comments-task']['rows'][j]['object_id']} }).subscribe( (res: Response) => { } );
        if (this.activity['comments-task']['rows'][j]['moved'] != undefined) {
          delete this.activity['comments-task']['rows'][j]['moved'];
        }
      }
    }
    //console.log(this.activity['comments-task']['rows']);
    /**/

    this.tasks = tempTasks;
    this.handleTaskConditions();
    this.handleTaskRestrictions();
    //console.log(this.tasks);
    //console.log(this.activity);
    this.groupTasks();
    this.saveTasks();
  }

  /*********************************/
  /* move task to another activity */
  moveTask(id) {    
    var copy_task = this.tasks[id];    

    var ActivitySelectDialog = this.dialog.open(ActivitySelectComponent, {});
    ActivitySelectDialog.componentInstance.params = { 'parent_id': null };
    ActivitySelectDialog.afterClosed().subscribe(result => {      
      if (!isNaN(result.entity)) {  
        
        //////console.log(copy_task);
        //////console.log(result.entity);    
        
        var text = copy_task.text;
        var status = copy_task.status;

        this.data.getTask({id:result.entity}).subscribe(
          (res: Response) => {         
            var destination_tasks = res['rows'][0]['tasks'];
            //////console.log(res);
            //console.log('OLD original:');
            //console.log(this.tasks);
            delete this.tasks[id];
            //console.log('OLD removed:');
            //console.log(this.tasks);
            //!this.saveTasks();

            if (destination_tasks != '' || destination_tasks != undefined || destination_tasks != null) {
              destination_tasks = JSON.parse(destination_tasks);
            } else {
              destination_tasks = {};
            }
            
            destination_tasks = this.addNewTask(result.entity, destination_tasks, text, copy_task.status, copy_task.assign);
            
            //////console.log(destination_tasks);

            //////console.log(Object.keys(destination_tasks).length);

            //////console.log('New activity_id: ' + result.entity);
            //////console.log('New task_id: ' + destination_tasks[Object.keys(destination_tasks).length-1].assign);

            //////console.log('Old activity_id: ' + id);
            //////console.log('Old task_id: ' + copy_task.assign);

            var comments_params = { new_activity_entity: result.entity, new_task_entity: destination_tasks[Object.keys(destination_tasks).length-1].assign, old_activity_entity: id, old_task_entity: copy_task.assign }
            //this.saveTasks(result.entity, destination_tasks, comments_params);

          }          
        );           
    
      }
    });       
    
  }

  conditionTask(id) {        
    this.conditionalTasks = this.tasks;
    //console.log(this.conditionalTasks);
    this.selectConditionTask = true;
    this.taskForConditionalAssign = id;
    this.tasksForConditionalAssign = this.tasks[id]['conditionalTasks'] != undefined ? this.tasks[id]['conditionalTasks'] : [];
  }

  checkArray(value) {
    if ((this.tasksForConditionalAssign).indexOf(value) > -1) { return true }
    return false;    
  }

  handleTaskRestrictions() {
    let helper = (GlobalVariables.group.replace(" ", "")).split(",");
    let group_array = helper.map(value => parseInt(value, 10));
    let assigned_users_names_entity_array = [];
    let assigned_group_names_entity_array = [];
    
    for (const [key, value] of Object.entries(this.tasks)) {     
      if (this.tasks[key]['assigned_users_names_entity'] == undefined && this.tasks[key]['assigned_group_names_entity'] == undefined) {
        this.tasks[key]['disabled'] = false;
        this.tasks[key]['disabled_reason'] = null;
      } else {        
        if (this.tasks[key]['assigned_users_names_entity']!=undefined) {          
          let stringValue = typeof this.tasks[key]['assigned_users_names_entity'] === 'number' ? this.tasks[key]['assigned_users_names_entity'].toString() : this.tasks[key]['assigned_users_names_entity'];
          assigned_users_names_entity_array = (stringValue.replace(" ", "")).split(",");
          assigned_users_names_entity_array = assigned_users_names_entity_array.map(value => parseInt(value, 10));
        } else {
          assigned_users_names_entity_array = [];
        }
        if (this.tasks[key]['assigned_group_names_entity']!=undefined) {
          let stringValue = typeof this.tasks[key]['assigned_group_names_entity'] === 'number' ? this.tasks[key]['assigned_group_names_entity'].toString() : this.tasks[key]['assigned_group_names_entity'];
          assigned_group_names_entity_array = ((this.tasks[key]['assigned_group_names_entity']).replace(" ", "")).split(",");
          assigned_group_names_entity_array = assigned_group_names_entity_array.map(value => parseInt(value, 10));
        } else {
          assigned_group_names_entity_array = [];
        }

        if (!assigned_users_names_entity_array.includes(GlobalVariables.id) && !group_array.some(value => assigned_group_names_entity_array.includes(value))) {
          this.tasks[key]['disabled'] = true;
          this.tasks[key]['disabled_reason'] = 1;
        }
      }      
    }
  }

  handleTaskConditions() {
    for (const [key, value] of Object.entries(this.tasks)) {
      let conditionalTasks = this.tasks[key]['conditionalTasks'] != undefined ? this.tasks[key]['conditionalTasks'] : [];
      //console.log(conditionalTasks);
      let disabled = false;
      for (let i=0; i<conditionalTasks.length; i++) {
        //console.log(">>>", conditionalTasks[i]);
        if (this.tasks[conditionalTasks[i]] != undefined) {
          if (this.tasks[conditionalTasks[i]]['status']==false) {
            disabled = true;                        
          }
        }
      }
      this.tasks[key]['disabled'] = disabled;      
      if (disabled == true) {
        this.tasks[key]['disabled_reason'] = 2;
      } else {
        this.tasks[key]['disabled_reason'] = null;
      }
    }
    //console.log(this.tasks);
  }

  toggleConditionTask(parentEntity, selectedEntity) {
    //console.log(this.tasks[parentEntity]);
    //console.log(this.tasks[selectedEntity]);
    
    if (this.tasks[parentEntity]['conditionalTasks']==undefined) {
      this.tasks[parentEntity]['conditionalTasks'] = [];
    }

    let index = this.tasks[parentEntity]['conditionalTasks'].indexOf(selectedEntity);
    if (index > -1) {
      (this.tasks[parentEntity]['conditionalTasks']).splice(index, 1);
    } else {
      (this.tasks[parentEntity]['conditionalTasks']).push(selectedEntity);
    }
      
    this.handleTaskConditions();
    this.handleTaskRestrictions();
    this.groupTasks();
    this.saveTasks();
    //console.log(this.tasks[parentEntity]);
  }


  /***********************************************/
  public percentage = 0;
  private initialWidth = 200;
  
  onTaskDragStart(event: MouseEvent, key) {
    event.preventDefault();

    const progressContainer = event.currentTarget as HTMLElement;
    const progressBar = progressContainer.querySelector('#task_'+key) as HTMLElement;
    this.updateProgress(event, progressBar, key);
    const onMouseMove = (moveEvent: MouseEvent) => {
      this.updateProgress(moveEvent, progressBar, key);
    };
    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  onClick(event: MouseEvent, key) {
    //console.log('#task_'+key);
    const progressBar = (event.currentTarget as HTMLElement).querySelector('#task_'+key) as HTMLElement;
    this.updateProgress(event, progressBar, key);
  }

  private updateProgress(event: MouseEvent, progressBar: HTMLElement, key) {
    const progressContainer = progressBar.parentElement as HTMLElement;
    const clickX = event.clientX - progressContainer.getBoundingClientRect().left;
    const newWidth = Math.max(0, Math.min(this.initialWidth, clickX));    
    this.tasks[key]['progress'] = Math.round((newWidth / this.initialWidth) * 100);
    this.tasks[key]['progress_saved'] = false;
    progressBar.style.width = `${newWidth}px`;
    this.updateProgressBarClass(progressBar, key);
  }

  private updateProgressBarClass(progressBar: HTMLElement, key) {    
    if (this.tasks[key]['progress'] > 0 && this.tasks[key]['progress'] < 100) {
      progressBar.classList.add('task-progress-active');
      progressBar.classList.remove('task-progress-zero');
    } else if (this.tasks[key]['progress'] === 100) {
      progressBar.classList.add('task-progress-full');
      progressBar.classList.remove('task-progress-active');
      progressBar.classList.remove('task-progress-zero');
    } else {
      progressBar.classList.add('task-progress-zero');
      progressBar.classList.remove('task-progress-active');
      progressBar.classList.remove('task-progress-full');
    }
  }





  /****************************************************************************************/
  /****************************************************************************************/
  /****************************************************************************************/
  /* load/create activity */
  getActivity(id) {       

    id = id == null ? id = this.entity : id;

    if (!isNaN(id)) {      
      this.entity = id;      
    } else if (this.entity != null) {
      id = this.entity;            
    } else {      
      this.sub = this.route.params.subscribe(params => { var id = params['id']; });        
    }

    /* predefined footer*/
    /*
    var entity_data = "etalk_footer";
    this.data.getSettings({entity : entity_data}).subscribe((res: Response) => { 
      this.footer = res['rows'][0]['value']; 
      this.footer = (this.footer).replace('{{user}}', GlobalVariables.username);
    });
    */
    
    /* prefill activity wizzard */    
    this.data.getWizzard({}).subscribe(
      (res: Response) => {          
        this.activityWizzards = (<any>res).rows;
        //console.log(this.activityWizzards);
      }
    );

    if (isNaN(id)) {           

      /* prepare initial data for new activity */
      if (this.activity.message_type==undefined || this.activity.message_type==null || this.activity.message_type == '') {
        this.activity = { rows: { 0: { entity: null, parent_activity: { count_rows: 0, rows: null }, comments: { count_rows: 0, rows: null } } } };
        this.activity.category_type_description = this.translate.instant("Choose category...");
        this.activity.message_type = null;
      }

      this.activity.entity = 0;
      this.activity.parent_activity = [];
      this.activity.title = "";
      this.activity.sub_title = "";      
      this.activity.author = GlobalVariables.username;      
      this.activity.customer = this.translate.instant("Choose customer...");
      this.activity.calendar = null;
      this.tasks = {};
      this.addingActivity = true;
      this.canEdit = true;
      this.activity_backup = this.activity;      

      if (this.customer_id != null) {
        this.activity.customer_id = this.customer_id;
        this.activity.customer = this.customer;                  
      }

      if (this.activity['message_type'] == null) {
        this.assignCategory(this.activity.entity, null);
      }

    } else { 
      
      this.data.getActivity( {id:id} ).subscribe(
        (res: Response) => {          
          this.activity = (<any>res).rows[0];

          //console.log('//loadActivity');          
          //console.log(this.activity);

          this.activity.viewed = parseInt(this.activity.viewed) + 1;

          if (this.activity.customer_id == null) {
            this.activity.customer = this.translate.instant("Choose customer...");
          }
          /*          
          //console.log(this.activity['comments-task']['rows']);
          //console.log(JSON.parse(this.activity['tasks'])); 
          */
                    
          if (this.activity == undefined) {

            var content = "You do not have permission to view this Activity";
            var modalDialog = this.dialog.open(ModalComponent, {
              data: { content: content, yes: null , no: null, cancel: 'Close' }
            });           
            
          } else {
                        
            /*if category is not defined */
            if (this.activity['message_type'] == null) {
              this.assignCategory(this.activity.entity, null);
            }

            if (this.activity['contacts']!=undefined) {
              var contacts = this.activity['contacts'].split(',');
                this.data.getCompanyContacts({ 'customer_id' : this.activity.customer_id }).subscribe(
                  (res: Response) => {
                    this.assigned_contacts = (<any>res).rows;
                    for(var i=0; i<this.assigned_contacts.length; i++) {                      
                      if (contacts.includes(this.assigned_contacts[i].entity.toString())) {
                        this.assigned_contacts[i].status = true;  
                      } else { 
                        this.assigned_contacts[i].status = false; 
                      }
                    }
                  }
                );
              };  

            this.assigned_users_names_array = this.activity.assigned_users_names != null ? (this.activity.assigned_users_names).split(',') : [];
            this.assigned_users_array = this.activity.assigned_users != null ? (this.activity.assigned_users).split(',') : [];
            this.assigned_users_names_hex_array = this.activity.assigned_users_names_hex != null ? (this.activity.assigned_users_names_hex).split(',') : [];

            /* fix */
            if (this.activity.title!=null) this.activity.title = (this.activity.title).replace(/<img[^>]*>/g,"");
            if (this.activity.sub_title!=null) this.activity.sub_title = (this.activity.sub_title).replace(/<img[^>]*>/g,"");
            
            this.assigned_users_names = this.ShowUserInicials(this.activity.assigned_users_names);
            this.assigned_group_names = this.ShowUserInicials(this.activity.assigned_group_names);

            this.activity.status = (this.activity.status_text).length > 10 ? 1 : 0;

            this.tasks = this.activity.tasks == "" || this.activity.tasks == null || this.activity.tasks == undefined ? {} : JSON.parse(this.activity.tasks);   
            
            for (const key in this.tasks) {              
              if (this.tasks[key] && !this.tasks[key].hasOwnProperty('progress')) {              
                this.tasks[key].progress = -1;
              }
            }          

            this.handleTaskConditions();
            this.handleTaskRestrictions();
            //console.log(this.tasks);
                        
            /* restriction */                    
            for ( var i = 0; i < this.members.length; i++ ) {              
              if (this.activity.restrictions_override.rows != undefined) {
                for ( var j = 0; j < this.activity.restrictions_override.rows.length; j++ ) {                
                  if (this.activity.restrictions_override.rows[j].type_entity == this.members[i].entity && this.activity.restrictions_override.rows[j].type == 'user') {
                    this.members[i]['view'] = this.activity.restrictions_override.rows[j].view == 'true' ? true : false;
                    this.members[i]['edit'] = this.activity.restrictions_override.rows[j].edit == 'true' ? true : false;                                   
                  }
                }
              }
            }
                        
            for ( var i = 0; i < this.member_groups.length; i++ ) {       
              if (this.activity.restrictions_override.rows != undefined) {       
                for ( var j = 0; j < this.activity.restrictions_override.rows.length; j++ ) {                
                  if (this.activity.restrictions_override.rows[j].type_entity == this.member_groups[i].entity && this.activity.restrictions_override.rows[j].type == 'group') {
                    //////console.log(this.activity.restrictions_override.rows[j].type_entity + '==' + this.member_groups[i].entity);
                    //////console.log(this.activity.restrictions_override.rows[j].view);
                    this.member_groups[i]['view'] = this.activity.restrictions_override.rows[j].view == 'true' ? true : false;
                    this.member_groups[i]['edit'] = this.activity.restrictions_override.rows[j].edit == 'true' ? true : false;                                   
                  }
                }
              }
            }            
            
            /* fix data retrieved from mysql */
            if (this.activity.calendar != '1') this.activity.calendar = null;
            if (this.activity.group_id != undefined) this.activity.group_id = [...new Set(this.activity.group_id.split(','))].toString();
            if (this.activity.assigned_group_names != undefined) this.activity.assigned_group_names = this.activity.assigned_group_names.replaceAll(', ', ',');
            if (this.activity.user_id != undefined)  this.activity.user_id = [...new Set(this.activity.user_id.split(','))].toString();
            if (this.activity.assigned_users_names != undefined) this.activity.assigned_users_names = this.activity.assigned_users_names.replaceAll(', ', ',');
            if (this.activity.parent_activity == undefined) this.activity.parent_activity = [];

            var formatter = new Intl.NumberFormat('sl-SI', { style: 'currency', currency: 'EUR' });
            this.activity['formated_value'] = formatter.format(this.activity.value);
            
            this.activity.category_type_description = this.activity.category_type_description == undefined ? this.translate.instant("Choose category...") : this.activity.category_type_description;
            this.commentsCount = this.activity['comments'] != undefined ? this.activity.comments.count_rows + " comments" : "0 comments";

            this.extended_view = this.activity.comments != undefined ? true : false;

            /* ovverride rule for extended view in mobile */
            this.isMobile = this.cFunction.isMobileDevice();    
            if (this.isMobile) { this.extended_view = true; }    
            
            /* get history           */
            this.data.getHistory({ entity : this.activity.entity }).subscribe(
              (res: Response) => {              
                this['history'] = 'rows' in res ? res['rows'] : null;
                if (this.history!=undefined) {
                  this.historyCount = this.history.length > 0 ? this.history.length + this.translate.instant(" changes") : this.history.length + this.translate.instant("0 changes");
                }
              }
            );
            
            /* get timetracking */
            this.data.getTimeTrackings({'field':'t1.message_id', 'value':this.activity.entity}).subscribe(
              (res: Response) => {                
                this['TimeTracking'] = 'rows' in res ? res['rows'] : null;     
                this.timetrackinglength = this['TimeTracking'].length;
                this.timetrackingCount = this['TimeTracking'].length > 0 ? this['TimeTracking'].length + this.translate.instant(" records") : this.translate.instant("0 records");
              }
            );       

            /* get attachments */
            this.data.getDocuments({'items' : 10000, 'order' : 'doc_title', 'orderby' : 'asc', search: null, 'field' : 'activity', 'value' : this.activity.entity}).subscribe(
              (res: Response) => {
                this.Attachments = (<any>res).rows;                     
                this.attachmentslength = this.Attachments.length;
                this.extended_view =  this.extended_view || this.attachmentslength > 0 ? true : false;
              }
            );

            /* get notes */
            this.data.getNotesList({ 'items' : 100, 'order' : 'entity', 'orderby' : 'asc', 'activity' : this.activity.entity }).subscribe(
              (res: Response) => {
                this.notes_list = <any>res['rows'];               
              }
            );

            /* get eTalk */            
            /*
            if (this.activity.etalk != '') {
              this.data.getETalk({ 'items' : 100, 'order' : 'entity', 'orderby' : 'desc', 'etalk' : this.activity.etalk }).subscribe(
                (res: Response) => {
                  this.etalk = <any>res['rows'];

                  for (var i=0; i<this.etalk.length; i++) {     
                    this.etalk[i].body = this.etalk[i].body; 
                  }

                  this.eTalk_email = this.etalk[this.etalk.length-1].email;
                  this.eTalk_cc = this.etalk[this.etalk.length-1].cc;
                  this.eTalk_subject = this.etalk[this.etalk.length-1].subject;
                  
                }
              );
            }
            */
            
            /* get comments attachments */            
            this.data.getCommentAttachments({ 'comment_ids' : this.activity['comments_ids'] }).subscribe(
            (res: Response) => {
              this.commentsAttachments = <any>res['rows'];                           
            });            

            /*************************/
            this.comment_parent_object_id = this.activity.entity;
            this.comment_parent_object = 'messages';
            this.comment_object_id = this.activity.entity;
            this.comment_object ='comment';
            this.comment = "";

            this.addingActivity = false;
            this.canEdit = false;
            this.activity_backup = this.activity;    

            /* add assignation+ button */
            const result: any = {};
            result.entity = 'new';
            result.priority = 0;
            result.parent_id = this.activity.entity;
            
            result.title = result.message_type = result.category_type_hex = result.message_type = result.author = null;
            result.assigned_group_names = result.assigned_users_names = result.assigned_group_names_entity = result.assigned_users_names_entity = null;

            if (!this.activity.sub_activity) this.activity.sub_activity = [];
            this.activity.sub_activity['new'] = result;
            
            //console.log('//activityData >');
            //console.log(this.activity);

            /* finalize */
            //console.log(this.tasks);
            this.updateStatuses();
            this.countTasks();
            this.countActivities();
            this.groupTasks();

            //if (!this.extended_view && this.activity.entity != id) { this.showSummary(); }
            //console.log(this.activity.entity);
            //console.log(id);
            if (this.oldEntity != id) { this.showSummary(); }
            this.oldEntity = this.activity.entity;
          }
        }      
      )
    }
  }

  /****************************************/
  saveActivity() {
    let activityData = Object.assign({}, this.activity);
    
    /* FIX.NEED: map company name to company.entity */
    activityData.customer = this.activity.customer_id;  
      
    /* Requirements */
    //console.log(activityData);
    if (activityData['message_type'] == undefined) {      
      let content = this.translate.instant("You need to choose activity category to be able to save");
      let modalDialog = this.dialog.open(ModalComponent, {
        data: { content: content, yes: this.translate.instant('OK'), no: null, cancel: null }
      });  
      return false;      
    }

    console.log(activityData);
    
    this.data.saveActivity({ data: activityData }).subscribe(
      (res: Response) => {        
        if (this.activity.entity == 0) {
          this.activity.entity = (<any>res).callback.entity;
          this.entity = this.activity.entity;          
          this.data.updateCategory({ activity_entity : this.activity.entity, message_type : this.activity.category_type }).subscribe((res: Response) => {
            this.getActivity(this.activity.entity);            
          });          
        }        
      }
    );
   
    this.canEdit = false;
    return true;
  }
















  /***********************************************************/
  /* Switch to Edit Mode */


  /***********************************************************/
  /* Switch to compact view or tab view */
  compactViewToggle() {
    this.selectedTab = 'summary';
    this.compactView = !this.compactView;
    this.data.setMemberSettings({'parameter': 'activityCompactView', 'value': this.compactView}).subscribe((res: Response) => {});    
    this.showSummary();
  }

  progressChartToggle() {
    this.progressChart = !this.progressChart;
    this.data.setMemberSettings({'parameter': 'activityProgressChart', 'value': this.progressChart}).subscribe((res: Response) => {});    
    this.showSummary();
  }


  /****************************************/
  /* function: save burgebar pin function */
  switchTab(value) {
    this.selectedTab = value;
    if (value == 'summary') this.showSummary();
  }

  /****************************************/
  /* function: execute local function     */
  get executeLocalFunctionFunc() { return this.executeLocalFunction.bind(this); }
  executeLocalFunction(localFunction, data) {    
    this[localFunction](data);
  }  

  /****************************************/
  onScroll(event: any): void {
		const scrollTop = event.target.scrollTop;
		if (scrollTop > this.lastScrollTop) {
			this.direction = 'scrollDown';
		} else {
			this.direction = 'scrollUp';
		}
		this.lastScrollTop = scrollTop;
		//console.log(this.lastScrollTop);
	}
















}
