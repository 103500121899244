import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MembersettingsService } from './membersettings.service';

@Injectable({
  providedIn: 'root'
})
export class DockService {

  public memberSettingsGlobal = null;

  private dockGlobalSource = new BehaviorSubject<Array<any>>([]);
  public dockGlobal = this.dockGlobalSource.asObservable();

  constructor(
    public MembersettingsService: MembersettingsService
  ) { }

  addToDock(item) {
    let helper = this.dockGlobalSource['_value'];    
    let entity = item['entity'];
    let type = item['type'];
    let newItem = false;
    //console.log(helper.length);
    for (let i=0; i<helper.length; i++) {      
      if (entity == helper[i]['entity'] && type == helper[i]['type']) {        
        newItem = true;
      }
    }
    if (newItem == false) { helper.push(item); }
    this.MembersettingsService.setMemberSettings("dock", JSON.stringify(helper));
    this.dockGlobalSource.next(helper);

    if (document.querySelector(".dockWrapper")!=null) document.querySelector(".dockWrapper").classList.add("refresh");
    setTimeout(() => {
      if (document.querySelector(".dockWrapper")!=null) document.querySelector(".dockWrapper").classList.remove("refresh");
    }, 2500);
  }

  removeFromDockByEntity(entity, type) {    
    let helper = this.dockGlobalSource['_value'];
    console.log(helper);
    alert(entity);
    alert(type);
    helper = helper.filter(item => !(item.entity === entity && item.type === type));
    console.log(helper);
    
    this.MembersettingsService.setMemberSettings("dock", JSON.stringify(helper));
    this.dockGlobalSource.next(helper);
    
    document.querySelector(".dockWrapper").classList.add("refresh");
    setTimeout(() => {
      document.querySelector(".dockWrapper").classList.remove("refresh");
    }, 2500);
  }  

  removeFromDock(type, entity) {
    let helper = [];    
    //console.log(helper.length);
    for (let i=0; i<this.dockGlobalSource['_value'].length; i++) {      
      if (entity != this.dockGlobalSource['_value'][i]['entity'] || type != this.dockGlobalSource['_value'][i]['type']) {        
        helper.push(this.dockGlobalSource['_value'][i]);
      }
    }
    this.MembersettingsService.setMemberSettings("dock", JSON.stringify(helper));
    this.dockGlobalSource.next(helper);
  }

  refreshDock(data) {    
    if (this.dockGlobalSource['_value'].length > 0) return false;
    if (data == undefined) data = [];
    this.dockGlobalSource.next(data);
    return true;
  }
}
 