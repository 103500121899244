import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http'; 
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
//import { Http, ResponseContentType } from "@angular/http";
import { HttpHeaders,HttpResponse } from '@angular/common/http';
import { GlobalVariables } from '../services/data.globals';

@Injectable({
  providedIn: 'root'
})

export class DataService {

	private cached_token;

	constructor(
		private http: HttpClient		
	) { }

	/* navigation menu desktop collapse option */
	public navigationCollapse = new BehaviorSubject<any>({ 
		state: false //means collapsed
    });


	/* Email services */
	/* Outlook */
	getToken_Outlook() {
		var url = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
		var params = [];
		params['client_id']      = '894a4bd9-17fb-45c5-a4c4-f4156c538c90';
		params['response_type']  = 'code';
		params['redirect_uri']   = 'https://api.biliz.com/api/mail/outlook_callBack.php';
		params['response_mode']  = 'query';
		params['state']          = 'Nothing';
		params['scope']          = 'https://outlook.office.com/mail.read'; 
		params['url']            = url;
	
		//header('Location: ' . $url . '?' . http_build_query(params));

		/*
		url = "https://www.google.com";

		console.log('#1');
		return this.http.get(url, {}).pipe(
			switchMap((response) => {	
				console.log('#2');
				console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
				console.log('#3');
				//if (1==1) //console.log('Error > Select Users > ' + err);
				return of(err);
		    })
		)
		*/	
		
	}

	/******************************/
	countEmailQueueByStatus(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/mail/countEmailQueueByStatus.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	getEmailQueueByStatus(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/mail/getEmailQueueByStatus.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	getEmail(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/mail/getEmail.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	getEmailsLog(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/mail/getEmailsLog.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	sendEmail(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/mail/sendEmail.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	sendDemoEmail(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/mail/sendDemoEmail.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	saveEmailQueue(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/mail/saveEmailQueue.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {	  	
	    		return of(response);
	  		}),
	  		catchError((err) => {	    		
	    		return of(err);
	  		})
		)
	}

	deleteEmailQueue(parameters) {		
		console.log(parameters);
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/mail/deleteEmailQueue.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	
	/******************************/
	getBase64DataImage(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/getBase64DataImage.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}
	
	uploadMediaBase64(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/uploadMediaBase64.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	/******************************/

	saveWizzard(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/saveWizzard.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	getWizzard(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getWizzard.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	/******************************/

		
	refreshMemberSettings() {
        this.getMemberSettings(null).subscribe(
            (res: Response) => {
                var memberSettingsArray = [];        
                for (var i=0; i<res['rows'].length; i++) {          
                    memberSettingsArray[res['rows'][i].parameter] = res['rows'][i].value;
                }        
            GlobalVariables.memberSettings = memberSettingsArray;        
        });
    }

	/******************************/
	/* quote					  */
	getQuoteTextTemplates(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/getQuoteTextTemplates.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	  	
				return of(response);
			}),
			catchError((err) => {	    		
				return of(err);
			})
		)
	};

	saveQuoteTextTemplate(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/saveQuoteTextTemplate.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	  	
				return of(response);
			}),
			catchError((err) => {	    		
				return of(err);
			})
		)
	};

	deleteTextTemplate(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/deleteTextTemplate.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	  	
				return of(response);
			}),
			catchError((err) => {	    		
				return of(err);
			})
		)
	};

	getQuoteItems(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/getQuoteItems.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	  	
				return of(response);
			}),
			catchError((err) => {	    		
				return of(err);
			})
		)
	};

	saveQuoteItem(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/saveQuoteItem.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	  	
				return of(response);
			}),
			catchError((err) => {	    		
				return of(err);
			})
		)
	};

	deleteQuoteItem(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/deleteQuoteItem.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	  	
				return of(response);
			}),
			catchError((err) => {	    		
				return of(err);
			})
		)
	};
	
	getQuoteItemCategories(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/getQuoteItemCategories.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	  	
				return of(response);
			}),
			catchError((err) => {	    		
				return of(err);
			})
		)
	};

	saveQuoteItemTypes(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/getQuoteItemTypes.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	  	
				return of(response);
			}),
			catchError((err) => {	    		
				return of(err);
			})
		)
	};

	getQuotes(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/getQuotes.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	  	
				return of(response);
			}),
			catchError((err) => {	    		
				return of(err);
			})
		)
	};

	saveQuote(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/saveQuote.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	  	
				return of(response);
			}),
			catchError((err) => {	    		
				return of(err);
			})
		)
	};

	deleteQuote(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/deleteQuote.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	  	
				return of(response);
			}),
			catchError((err) => {	    		
				return of(err);
			})
		)
	};

	getLastQuoteNr(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/getLastQuoteNr.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	  	
				return of(response);
			}),
			catchError((err) => {	    		
				return of(err);
			})
		)
	};

	/******************************/
	/* erp 						  */
	validateVat(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/validateVat.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	  	
				return of(response);
			}),
			catchError((err) => {	    		
				return of(err);
			})
		)
	};

	setErpPayment(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/setErpPayment.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	getDockErpDocuments(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/getDockErpDocuments.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	getErpLastDocumentDate(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/getErpLastDocumentDate.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	getErpLastDocumentEntity(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/getErpLastDocumentEntity.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	deleteErpDocument(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/deleteErpDocument.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	getErpLastDocumentNumber(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/getErpLastDocumentNumber.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	saveErpDocumentPrintLanguage(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/saveErpDocumentPrintLanguage.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	saveErpDocument(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/saveErpDocument.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	getErpDocuments(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/erp/getErpDocuments.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	/******************************/
	/* Weather		              */	
	
	RetrieveWeatherFromOpenMeteo(parameters) {
		//console.log(parameters);
		var url = 'https://api.open-meteo.com/v1/forecast?latitude='+parameters['lat']+'&longitude='+parameters['lng']+'&hourly=temperature_2m,precipitation&daily=weathercode,temperature_2m_max,temperature_2m_min,sunrise,sunset,precipitation_sum&timezone=Europe%2FBerlin';
		return this.http.get(url).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}


	GetWeather(parameters) {
		//console.log(parameters);
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/weather/GetWeather.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	SetWeather(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/weather/SetWeather.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	/******************************/
	/* eTalk		              */
	
	getKnowledgeSystems(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/knowledge/getKnowledgeSystems.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	getKnowledge(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/knowledge/getKnowledge.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	saveKnowledge(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/knowledge/saveKnowledge.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	deleteKnowledge(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/knowledge/deleteKnowledge.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	/******************************/
	/* eTalk		              */
	
	getUnassignedETalks(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/etalk/getUnassignedETalks.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	getUnsendETalks(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/etalk/getUnsedETalks.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}


	updateETalkAsSend(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/etalk/updateETalkAsSend.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	saveETalkAttachment(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/etalk/saveETalkAttachment.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	unlink_etask(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/etalk/unlink_etask.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	eTalkToActivity(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/etalk/eTalkToActivity.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	updateETalk(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/etalk/updateETalk.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	sendETalk(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/etalk/sendETalk.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	deleteETalk(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/etalk/deleteETalk.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	getETalk(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/etalk/getETalk.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	validateETalk(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/etalk/validateETalk.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	saveETalk(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/etalk/saveETalk.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	/******************************/
	/* System settings            */
	
	getSettings(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/system/get.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	setSettings(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/system/set.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}


	/******************************/
	/* Recovery                   */
	
	getRecoveries(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/recovery/getRecoveries.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	recoverRecovery(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/recovery/recoverRecovery.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	/******************************/
	/* document services  	      */

	getStorageID(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/getStorageID.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	getStickyNote(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/getStickyNote.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	saveStickyNote(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/saveStickyNote.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	deleteDocument(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/deleteDocument.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	renameFolder(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/renameFolder.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	deleteFolder(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/deleteFolder.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	getFolders(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/getFolders.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	getDocument(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/getDocument.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	getDocumentCategories(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/getCategories.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	downloadDocument(filename, original_filename): any {
	    const baseUrl = 'https://api.biliz.com/api/documents/downloadDocument.php?token='+filename;
	    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
	    this.http.get(baseUrl,{ headers, responseType: 'blob' }).subscribe(
	        (response: any) =>{
	        		//console.log(response);
	            let dataType = response.type;
	            let binaryData = [];
	            binaryData.push(response);
	            let downloadLink = document.createElement('a');
	            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
	            if (original_filename)
	                downloadLink.setAttribute('download', original_filename);
	            document.body.appendChild(downloadLink);
	            downloadLink.click();
	        }
	    )
	}

	getDocumentsByCompanyId(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		////console.log('getactivities: Call function');		
		return this.http.post('https://api.biliz.com/api/documents/getDocumentsByCompanyId.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {
				//console.log('getDocuemtnsByCompnayID: get Data');		
				return of(response);				
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get Activities > ' + err);
		      //console.log('getDocuemtnsByCompnayID: Error');
		      return of(err);
		    })
		)	
	}

	getDocuments(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/getDocuments.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	saveDocumentFolder(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/saveDocumentFolder.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	saveDocument(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/saveDocument.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	removeDocument(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/removeDocument.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	getDocumentsType(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/getDocumentsType.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	getDocumentType(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/getDocumentType.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	deleteDocumentType(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/deleteDocumentType.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	saveDocumentType(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/documents/saveDocumentType.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	/******************************/
	saveUser(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/user/saveUser.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	deleteUser(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/user/delete.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	selectUser(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/user/getUsers.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Users > ' + err);
		      return of(err);
		    })
		)	
	}

	/******************************/
	/* TimeTracking               */
	
	getTimeTrackingCategories(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/timetracking/getTimeTrackingCategories.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getTimeTracking');
	    		return of(err);
	  		})
		)
	}


	getTimeTracking(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/timetracking/getTimeTracking.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getTimeTracking');
	    		return of(err);
	  		})
		)
	}

	copyTimeTracking(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/timetracking/copyTimeTracking.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getTimeTracking');
	    		return of(err);
	  		})
		)
	}

	getMyOpenedTimetrackings(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/timetracking/getMyOpenedTimetrackings.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getTimeTracking');
	    		return of(err);
	  		})
		)
	}

	getMyLastTimetrackings(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/timetracking/getMyLastTimetrackings.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getTimeTracking');
	    		return of(err);
	  		})
		)
	}

	getTimeTrackings(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		//console.log('gettimetracking: Call function');		
		return this.http.post('https://api.biliz.com/api/timetracking/getTimeTrackings.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {
				//console.log('getTimeTracking: get Data');		
				return of(response);				
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get TimeTrackings > ' + err);
		      //console.log('getTimeTrackings: Error');
		      return of(err);
		    })
		)	
	}

	deleteTimeTracking(parameters) {
		////console.log(parameters);
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/timetracking/deleteTimeTracking.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveTimeTracking');
	    		return of(err);
	  		})
		)
	}

	saveTimeTracking(parameters) {
		////console.log(parameters);
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/timetracking/saveTimeTracking.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveTimeTracking');
	    		return of(err);
	  		})
		)
	}

	/******************************/
	/* Comments                   */
	getCommentAttachments(parameters) {
		////console.log(parameters);
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/comments/getCommentAttachments.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveComment');
	    		return of(err);
	  		})
		)
	}

	getLastComments(parameters) {
		////console.log(parameters);
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/comments/getLastComments.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveComment');
	    		return of(err);
	  		})
		)
	}

	updateComment(parameters) {
		////console.log(parameters);
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/comments/updateComment.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveComment');
	    		return of(err);
	  		})
		)
	}

	updateObjectIDComment(parameters) {
		////console.log(parameters);
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/comments/updateObjectIDComment.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveComment');
	    		return of(err);
	  		})
		)
	}

	saveComment(parameters) {
		////console.log(parameters);
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/comments/saveComment.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveComment');
	    		return of(err);
	  		})
		)
	}

	deleteComment(parameters) {
		////console.log(parameters);
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/comments/deleteComment.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveComment');
	    		return of(err);
	  		})
		)
	}

	getCustomerComments(parameters) {
		////console.log(parameters);
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/comments/getCustomerComments.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveComment');
	    		return of(err);
	  		})
		)
	}

	

    /******************************/
	/* Activity Data Manipulation */	
	getActivityPrefills(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getActivityPrefills.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Activity > ' + err);
		      return of(err);
		    })
		)	
	}

	setActivityPrefills(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/setActivityPrefills.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Activity > ' + err);
		      return of(err);
		    })
		)	
	}

	getActivityByETalkID(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getActivityByETalkID.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Activity > ' + err);
		      return of(err);
		    })
		)	
	}

	setRestriction(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/setRestriction.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Activity > ' + err);
		      return of(err);
		    })
		)	
	}

	selectActivity(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getActivities.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Activity > ' + err);
		      return of(err);
		    })
		)	
	}

	
	toggleCalendarActivity(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/toggleCalendarActivity.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Activity > ' + err);
		      return of(err);
		    })
		)	
	}


	toggleFavoritesActivity(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/toggleFavoritesActivity.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Activity > ' + err);
		      return of(err);
		    })
		)	
	}

	toggleHideActivity(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/toggleHideActivity.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Activity > ' + err);
		      return of(err);
		    })
		)	
	}

	getDockActivities(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getDockActivities.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	getActivity(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getActivity.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	searchActivity(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/searchActivity.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

    getHistory(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getHistory.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getHistory');
	    		return of(err);
	  		})
		)
	}
	

	updateLinks(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/updateLinks.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: updateConfirm');
	    		return of(err);
	  		})
		)
	}

    
	updateConfirm(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/updateConfirm.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: updateConfirm');
	    		return of(err);
	  		})
		)
	}

	removeConfirm(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/removeConfirm.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: updateConfirm');
	    		return of(err);
	  		})
		)
	}


	updateStatus(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/updateStatus.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: updateStatus');
	    		return of(err);
	  		})
		)
	}

	assignContacts(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/assignContacts.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: updateAssignation');
	    		return of(err);
	  		})
		)
	}

	updateAssignation(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/updateAssignation.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: updateAssignation');
	    		return of(err);
	  		})
		)
	}

	updateDateTime(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/updateDateTime.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: updateDateTime');
	    		return of(err);
	  		})
		)
	}

	updatePriority(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/updatePriority.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: updatePriority');
	    		return of(err);
	  		})
		)
	}

	updateCategory(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/updateCategory.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: updateCategory');
	    		return of(err);
	  		})
		)
	}

	updateParentId(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/updateParentId.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: updateCategory');
	    		return of(err);
	  		})
		)
	}


	saveETalkID(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/saveETalkID.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	saveActivity(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/saveActivity.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	markAsNotRead(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/markAsNotRead.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	markAsRead(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/markAsRead.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	getTaskTemplate(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getTaskTemplate.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	saveTaskTemplate(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/saveTaskTemplate.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	deleteTaskTemplate(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/deleteTaskTemplate.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	getTask(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getTask.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	saveTasks(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/saveTasks.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivity');
	    		return of(err);
	  		})
		)
	}

	deleteActivity(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/deleteActivity.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: deleteActivity');
	    		return of(err);
	  		})
		)
	}

	unlinkActivity(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/deleteActivity.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: deleteActivity');
	    		return of(err);
	  		})
		)
	}

	getCategories(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getCategories.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {		
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get Categories > ' + err);
		      return of(err);
		    })
		)	
	}

	getActivities(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		////console.log('getactivities: Call function');		
		return this.http.post('https://api.biliz.com/api/activities/getActivities.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {
				//console.log(response);
				////console.log('getActivities: get Data');		
				return of(response);				
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get Activities > ' + err);
		      ////console.log('getActivities: Error');
		      return of(err);
		    })
		)	
	}


	getActivitiesCharts(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		////console.log('getactivities: Call function');		
		return this.http.post('https://api.biliz.com/api/activities/getActivitiesCharts.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {
				//console.log(response);
				////console.log('getActivities: get Data');		
				return of(response);				
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get Activities > ' + err);
		      ////console.log('getActivities: Error');
		      return of(err);
		    })
		)	
	}


	getLastCount(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		////console.log('getactivities: Call function');		
		return this.http.post('https://api.biliz.com/api/activities/getLastCount.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {
				////console.log('getActivities: get Data');		
				return of(response);				
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get Activities > ' + err);
		      ////console.log('getActivities: Error');
		      return of(err);
		    })
		)	
	}


	getActivitiesByCompanyId(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		//console.log('getactivities: Call function');		
		return this.http.post('https://api.biliz.com/api/activities/getActivitiesByCompanyId.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {
				//console.log('getActivities: get Data');		
				return of(response);				
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get Activities > ' + err);
		      //console.log('getActivities: Error');
		      return of(err);
		    })
		)	
	}


	getActivityStatuses(parameters) {
	
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getActivityStatuses.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {		
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get ActivityStatuses > ' + err);
		      return of(err);
		    })
		)	
	}

	getCalendar(parameters) {
	
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getCalendar.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {		
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get Calendar > ' + err);
		      return of(err);
		    })
		)	
	}

	/******************************/

	getActivityTypes(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getActivityTypes.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivityTypes');
	    		return of(err);
	  		})
		)
	}

	getActivityType(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getActivityType.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivityType');
	    		return of(err);
	  		})
		)
	}

	saveActivityType(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/saveActivityType.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveActivityType');
	    		return of(err);
	  		})
		)
	}

	deleteActivityType(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/deleteActivityType.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: deleteActivityType');
	    		return of(err);
	  		})
		)
	}

	getAccesses(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/activities/getAccesses.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getAccesses');
	    		return of(err);
	  		})
		)
	}
	

	/******************************/
	/* Memberss Data Manipulation */
	getMemberSettings(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/members/getMemberSettings.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get Members > ' + err);
		      return of(err);
		    })
		)	
	}

	resetMemberSettings(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/members/resetMemberSettings.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get Members > ' + err);
		      return of(err);
		    })
		)	
	}

	getMemberData(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/members/getMemberData.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get Members > ' + err);
		      return of(err);
		    })
		)	
	}

	setMemberData(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/members/setMemberData.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get Members > ' + err);
		      return of(err);
		    })
		)	
	}

	setMemberSettings(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/members/setMemberSettings.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get Members > ' + err);
		      return of(err);
		    })
		)	
	}
	
	getMembers(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/members/getMembers.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get Members > ' + err);
		      return of(err);
		    })
		)	
	}

	getMemberGroups(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/members/getMemberGroups.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get MemberGroups > ' + err);
		      return of(err);
		    })
		)	
	}

	/***********************************/
	/* CustomerTypes Data Manipulation */
	getCustomerTypes(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customertypes/getCustomerTypes.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get CustomerTypes > ' + err);
		      return of(err);
		    })
		)	
	}

	getCustomerType(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customertypes/getCustomerType.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getCustomerType');
	    		return of(err);
	  		})
		)
	}

	saveCustomerType(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customertypes/saveCustomerType.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveCustomerType');
	    		return of(err);
	  		})
		)
	}

	deleteCustomerType(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customertypes/deleteCustomerType.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: deleteCustomerType');
	    		return of(err);
	  		})
		)
	}

	/******************************************/
	/* Customercustomfields Data Manipulation */
	getCustomFields(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customfields/getCustomFields.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get customfields > ' + err);
		      return of(err);
		    })
		)	
	}

	getCustomField(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customfields/getCustomField.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getContactType');
	    		return of(err);
	  		})
		)
	}

	saveCustomField(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customfields/saveCustomField.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveContactType');
	    		return of(err);
	  		})
		)
	}

	deleteCustomField(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customfields/deleteCustomField.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveContactType');
	    		return of(err);
	  		})
		)
	}

	/***********************************/
	/* CustomerTypes Data Manipulation */
	getContactTypes(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/contacttypes/getContactTypes.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get ContactTypes > ' + err);
		      return of(err);
		    })
		)	
	}

	getContactType(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/contacttypes/getContactType.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getContactType');
	    		return of(err);
	  		})
		)
	}

	saveContactType(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/contacttypes/saveContactType.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveContactType');
	    		return of(err);
	  		})
		)
	}

	deleteContactType(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/contacttypes/deleteContactType.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: deleteContactType');
	    		return of(err);
	  		})
		)
	}	

	/******************************/
	/* Customers Data Manipulation */
	getCustomers(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/getCustomers.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Get Customers > ' + err);
		      return of(err);
		    })
		)	
	}


	getTimeTrackStat(parameters) {
		////console.log(parameters);
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/getTimeTrackStat.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveComment');
	    		return of(err);
	  		})
		)
	}


	getDockCustomers(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/getDockCustomer.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getActivity');
	    		return of(err);
	  		})
		)
	}

	selectCustomer(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/selectCustomer.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {	
				////console.log(response);	
				return of(response);
	    	}),
		    catchError((err) => {
		      //if (1==1) //console.log('Error > Select Customers > ' + err);
		      return of(err);
		    })
		)	
	}

	getCustomer(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/getCustomer.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getCustomer');
	    		return of(err);
	  		})
		)
	}
	
	checkCustomerName(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/checkCustomerName.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getCustomer');
	    		return of(err);
	  		})
		)
	}

	checkCustomerVat(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/checkCustomerVat.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getCustomer');
	    		return of(err);
	  		})
		)
	}

	getCustomerStatuses(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/getCustomerStatuses.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getCustomerStatuses');
	    		return of(err);
	  		})
		)
	}

	getCustomerStat(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/getCustomerStat.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getCustomerStatuses');
	    		return of(err);
	  		})
		)
	}
	

	saveCustomer(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/saveCustomer.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveCustomer');
	    		return of(err);
	  		})
		)
	}

	deleteCustomer(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/deleteCustomer.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	  			////console.log(response);
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveCustomer');
	    		return of(err);
	  		})
		)
	}

	getContact(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/getContacts.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getContacts');
	    		return of(err);
	  		})
		)
	}

	getCompanyContacts(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/getCompanyContacts.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: getContacts');
	    		return of(err);
	  		})
		)
	}

	saveContact(parameters) {		
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/saveContacts.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: saveContacts');
	    		return of(err);
	  		})
		)
	}

	deleteContact(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/customers/deleteContacts.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: deleteContacts');
	    		return of(err);
	  		})
		)
	}

	/******************************/
	/* Notes */

	saveNote(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/notes/saveNote.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: deleteContacts');
	    		return of(err);
	  		})
		)
	}

	getNotes(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/notes/getNotes.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: deleteContacts');
	    		return of(err);
	  		})
		)
	}

	getNotesList(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/notes/getNotesList.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: deleteContacts');
	    		return of(err);
	  		})
		)
	}


	/******************************/
	/******************************/
	/******************************/
	/******************************/
	/* eMail Service */
	/* OutLook Service retrieve emails */
	getOutlookEmails(parameters) {
		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/mail/outlook_login.php', JSON.stringify(parameters), { headers }).pipe(
	  		switchMap((response) => {
	    		return of(response);
	  		}),
	  		catchError((err) => {
	    		//if (1==1) //console.log('Error: deleteContacts');
	    		return of(err);
	  		})
		)
	}

	/******************************/
	/******************************/
	/******************************/
	/******************************/



	/******************************/

	get token() {
	    //if (this.cached_token) {
	    if (this.hasOwnProperty('cached_token')) {
    	  return this.cached_token;
    	}
    	
    	const storage_token = localStorage.getItem('AUTH_TOKEN');
    	
    	if (storage_token) {
     		this.cached_token = storage_token;
     		return storage_token;
    	}
  	}


}