<div class="inner_wrapper" id="sketcher">
	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		
        <div class="left">
			<h1><fa-icon [icon]="['fad', 'users']"></fa-icon> | {{'Sketch'|translate}}</h1>
		</div>
		
        <div class="right">			
			
            <fa-icon [icon]="['fad', 'times']"></fa-icon>

            <!--
            <span class="spacer"></span>

            <fa-icon [icon]="['fad', 'save']" (click)="saveAndClose()"></fa-icon>
            
            <fa-icon [icon]="['fad', 'trash-alt']" (click)="clearCanvas()" class="clear tool_icon"></fa-icon>

            <fa-icon *ngIf="lock" [icon]="['fad', 'lock']" (click)="lock=false"></fa-icon>
            <fa-icon *ngIf="!lock" [icon]="['fad', 'lock-open']" (click)="lock=true"></fa-icon>
            -->

		</div>
	</div>	
		
	<div class="inner_content">
        <canvas id="sketchCanvas" width="400" height="400"></canvas>
    </div>

</div>
