import { Component } from '@angular/core';

@Component({
  selector: 'app-erp-richitem-type',
  templateUrl: './erp-richitem-type.component.html',
  styleUrl: './erp-richitem-type.component.css'
})
export class ErpRichItemTypeComponent {

}
