<form [formGroup]="formData" (ngSubmit)="save()" novalidate>	

	<div class="widget_settings">
		<div class="innerPopup header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
			<div class="left">
				<h1>{{'Customer widget settings'|translate}}</h1>
			</div>
			<div class="right">
				<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
			</div>
		</div>

        <div class="inner_content">
        	<div class="body">
				<div class="line">
					<div class="field">
						<input type="text" formControlName="title" name="title" [(ngModel)]="settings.title" />
						<div class="label" style="padding-top:6px;">{{'Widget title'|translate}}</div>
					</div>
				</div>
		    
				<div class="line">
					<div class="field">
						<label>{{'Content sorting'|translate}}</label>
						<select formControlName="order" name="order" [(ngModel)]="settings.order" style="float:left; margin-top:-6px;min-width:50px;">
							<option value="t1.entity">{{'By Added'|translate}}</option>
							<option value="last_activity_updated">{{'By Last Activity'|translate}}</option>
							<option value="t4.created">{{'By Last Comment'|translate}}</option>
						</select>
						<select style="margin-left:8px;float:left;margin-top:-6px;min-width:50px;" formControlName="orderby" name="orderby" [(ngModel)]="settings.orderby">
							<option value="asc">{{'Ascending'|translate}}</option>
							<option value="desc">{{'Descending'|translate}}</option>
						</select>
						<select style="margin-left:6px;float:left;margin-top:-6px;min-width:50px;" formControlName="items" name="items" [(ngModel)]="settings.items">
							<option value="6">6</option>
							<option value="12">12</option>
							<option value="24">24</option>
						</select>
					</div>
				</div>
				
				<div class="multi_line" style="padding-top: 24px; margin-top:6px; padding-bottom: 0px; border-top: 1px solid var(--border-color)">
					<label>{{'Show'|translate}}</label>
					
					<div class="field">
						<label class="switch">
							<input type="checkbox" formControlName="lastActivity" name="lastActivity" [(ngModel)]="settings.lastActivity">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Last Activity'|translate}}</div>	
					</div>	

					<div class="field">
						<label class="switch">
							<input type="checkbox" formControlName="lastComment" name="lastComment" [(ngModel)]="settings.lastComment">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Last Comment'|translate}}</div>				
					</div>
				</div>

				<div class="line" style="border-top: 1px solid var(--border-color);padding-top:18px; margin-top: 12px;">
					<div class="field">
						<label>{{'Extended filters'|translate}}</label>

						<div class="line_option">
							<!--<input formControlName="country" name="country" [(ngModel)]="customer['country']" />-->
							<select class="custom-select customselect" id="status" name="status">
								<option value="-">-</option>
								<option *ngFor="let status of statuses" [ngValue]="status.entity">{{status.description}}</option>
							</select>									
							<div class="label">
								{{'Company status'|translate}}
							</div>
						</div>
					</div>	
				</div>
				

				<div class="line" style="border-top: 1px solid var(--border-color);padding-top:18px; margin-top: 0px;">
					<div class="field">
						<label>{{'Customized filters'|translate}}</label>			

						<div class="line_option">
							<ng-container *ngFor="let field of custom_fields">					
							<div class="field">
								<div *ngIf="field.field_type=='check' || field.field_type=='dropdown'" class="label top">
									{{field.description}}								
								</div>			
			
								<div *ngIf="field.field_type=='check' || field.field_type=='dropdown'" class="checkbox_container">
									<div *ngFor="let option of field.field_default.split('|')" class="checkbox_field">									
										<label class="switch">									
											<input type="checkbox" [checked]="custom_field_data[field.entity-1][safe(option)]" (click)="checkboxfield_click(field.entity, safe(option), $event)" />
											<span class="slider round"></span>								
										</label>
										<div class="label">&nbsp;&nbsp;{{option}}</div>
									</div>
								</div>
								
							</div>
							<!--
							<div *ngIf="field.field_type!='check' || field.field_type=='dropdown'" class="label bottom">								
								{{field.description}}				
							</div>
							-->
							</ng-container>
						</div>	
					</div>
				</div>
			</div>
				
				

			<div class="footer">
				<button class="confirm" type="submit" mat-button>{{'Confirm'|translate}}</button>
				<button class="cancel" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
			</div>
		</div>
	</div>

</form>