<!--<div class="container_center" style="background: url(/assets/background/playfull.png);">-->
<div class="container_center" style="background: url(/assets/background/{{current_background}});">

	<input type="hidden" name="domain" [(ngModel)]="model.domain">

	<div *ngIf="!sendtoken && !token && !lostpass" class="login_wrapper">		
		<div class="logo" style="margin-top:-24px;margin-bottom:12px;"><img src="/assets/logo/co.png" /></div>
		<div class="form">			
			<form ngClass="login-form" (submit)="login()">			
				<!--<input type="text" name="name" [(ngModel)]="model.name" placeholder="{{'Username/Email...'|translate}}" />-->
				<!--<input type="password" name="password" [(ngModel)]="model.password" placeholder="{{'Password...'|translate}}" />-->

				<mat-form-field appearance="outline">
					<mat-label>{{'Username/Email'|translate}}</mat-label>
					<input type="text" matInput name="name" [(ngModel)]="model.name">	
				</mat-form-field>
								
				<mat-form-field appearance="outline">
					<mat-label>{{'Password' | translate}}</mat-label>
					<input [type]="showPassword ? 'text' : 'password'" matInput name="password" [(ngModel)]="model.password">					
					<fa-icon id="showPassword" style="position:absolute;top:13px;right:4px; cursor:pointer;" [icon]="showPassword ? ['fad', 'eye-slash'] : ['fad', 'eye']" (click)="togglePasswordVisibility()">
					</fa-icon>
				  </mat-form-field>
				  
				
				<input type="hidden" name="p" [(ngModel)]="model.p" />			
				<input type="submit" style="width: auto; margin: 0 auto;" value="{{'Login'|translate}}" (click)="hashPwd()" />
			</form>
		</div>
		<br />
		<div style="position:relative;width:100%;float:left;">
			<div style="float:left;width:100%;">
				<a class="lostpassword right" style="cursor:pointer;display: inline-block" (click)="token=' '">					
					{{'Log with token'|translate}}
				</a>
			</div>
			<div style="float:left;width:100%;padding-top:6px;">
				<span style="float:left;"><a href="/login?lang=si" class="selected">SI</a> | <a href="/login?lang=en">EN</a></span>		
				<a class="lostpassword right" style="cursor:pointer" (click)="lostpass=true">{{'Lost password'|translate}}</a>
			</div>
		</div>
	</div>

	<div *ngIf="!token && lostpass" class="login_wrapper">		
		<div class="logo" style="margin:-12px 0px 24px 0px;"><img style="width:100px;filter: grayscale(100%);" src="/assets/logo/co.png" /></div>		
		<div class="form">			
			{{'Provide the email address associated with your Colabrity account.'|translate}}<br /><br />
			{{'An activation link will be sent to you for the next step in password recovery.'|translate}}<br /><br />			
			<!--<input type="text" [(ngModel)]="model.name" placeholder="{{'Your email...'|translate}}" />-->
			<mat-form-field appearance="outline" style="margin-top:8px;">
				<mat-label>{{'Your email'|translate}}</mat-label>
				<input type="text" matInput name="password" [(ngModel)]="model.name">	
			</mat-form-field>
			<input type="submit" style="width: auto; margin: 20px auto 0 auto;" value="{{'Send'|translate}}" (click)="mailToken()" />			
			<br /><br />
			<a class="lostpassword" style="cursor:pointer" (click)="lostpass=false">{{'Back to Login'|translate}}</a>
		</div>		
	</div>

	<div *ngIf="sendtoken" class="login_wrapper">		
		<div class="logo" style="margin:-12px 0px 24px 0px;"><img style="width:100px;filter: grayscale(100%);" src="/assets/logo/co.png" /></div>		
		<div class="form">
			<ng-container *ngIf="!wait">
				{{'If your email is associated with the Colabrity network, you will receive an email with further instructions. Please check your spam folder, as the email may be moved there.'|translate}}<br /><br />		
			</ng-container>	
			<ng-container *ngIf="wait">
				{{'You still have a valid recovery link in your inbox. If you did not receive the email, check your spam folder. If you want to resend the invitation link, you can do so 15 minutes after the first attempt."'|translate}}<br /><br />		
			</ng-container>	
		</div>		
		<a class="lostpassword" style="cursor:pointer" (click)="lostpass=false;sendtoken=false;">{{'Back to Login'|translate}}</a>
	</div>

	<div *ngIf="token" class="login_wrapper">
		<div class="logo" style="margin:-12px 0px 24px 0px;"><img style="width:100px;filter: grayscale(100%);" src="/assets/logo/co.png" /></div>		
		<div class="form">			
			{{'Use your token from email and input your email address once again.'|translate}}<br /><br />
			{{'Email must be the same email where you received token and your valid email to log into Colabrity.'|translate}}<br /><br />			
			<b>{{'Each token can be used only once. After the first use, it becomes invalid.'|translate}}</b><br /><br />	
				<mat-form-field appearance="outline" style="margin-top:8px;">
					<mat-label>{{'Verification token'|translate}}</mat-label>
					<input type="text" matInput name="name" [(ngModel)]="model.token">	
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>{{'Your email'|translate}}</mat-label>
					<input type="text" matInput name="password" [(ngModel)]="model.name">	
				</mat-form-field>

				<input type="submit" value="{{'Login with Token'|translate}}" (click)="loginWithToken()" />
				<a class="lostpassword" style="cursor:pointer;margin-top:12px;" (click)="lostpass=false;token=''">{{'Back to Login'|translate}}</a>
		</div>		
	</div>

	<div class="login_foot">
		&nbsp;&nbsp;<img style="width: 110px;" src="/assets/logo/ibilizz.png" /><br /><b>Version 1.0.3</b>
	</div>
</div>