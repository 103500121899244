<div class="widget_wrapper" id="{{id}}" [attr.refresh]="refresh" [attr.parameters]="parameters_json" (mouseover)="mouseover=true" (mouseout)="mouseover=false">
	<div class="widget_header" cdkDrag cdkDragRootElement=".dashboard_widget" cdkDragHandle>
		<div class="widget_refresh_bar">
			<!--<div class="widget_refresh_bar_progress" style="width:{{refresh_percent}}%"></div>-->
		</div>
		<div class="widget_title">
			{{parameters?.title}}
		</div>
		<div class="widget_tools">			
			<fa-icon class="icon_medium" [icon]="['fad', 'times']" (click)="remove(id)" style="margin-top: 5px;"></fa-icon>
			<div class="divider_header"></div>
			<fa-icon [icon]="['fad', 'cog']" (click)="settings(id, parameters_json)"></fa-icon>
		</div>
	</div>

	<div class="widget_content">

        <div class="day_group">
            <div class="title">
                {{'Today'|translate}}
                <span>{{dateToday}}</span>
            </div>
            <div *ngIf="countObject(itemsTodayStart) > 0" class="content">
                <div class="widget_item {{item.entity}}" *ngFor="let item of itemsTodayStart; let i = index" (click)="edit(item.entity)">			
                    
                    <div class="time">
                        {{duration(item.message_start, item.message_end)|translate}}
                    </div>

                    <div class="category" style="background:{{item.message_type_hex}}">{{item.message_type_name}}</div>
                    <div class="activity_title" [innerHTML]="cFunction.URLtoHYPERLINK(item.title)"></div>
                    <fa-icon [icon]="['fad', 'flag']" class="priority_tag" [ngClass]="{'0':'priority0','1':'priority1','2':'priority2','3':'priority3'}[item.priority]" *ngIf="parameters.priority"></fa-icon>
                    <hr />

                    <div class="company" *ngIf="parameters.showcustomer && item.customer != undefined">
                        <fa-icon class="icon_small" [icon]="['fad', 'users']" style="font-size: 12px;"></fa-icon>
                        {{item.customer}}
                    </div>				
                    <div class="author_and_category" *ngIf="parameters.showauthor">
                        <fa-icon class="icon_small" [icon]="['fad', 'user']" style="font-size: 12px;margin:0px 4px 0px 2px"></fa-icon>
                        <span>{{item.author}}</span>						
                    </div>                                    
                </div>
            </div>	
            <div *ngIf="countObject(itemsTodayStart) == 0" class="content">
                <div class="emptyNotice">
                    {{'There is currently no activities based on your parameters.'|translate}}
                </div>
            </div>
        </div>	

        <div *ngIf="countObject(itemsTomorrowStart) > 0" class="day_group">
            <div class="title">{{'Tomorrow'|translate}}</div>
            <div class="content">
                <div class="widget_item {{item.entity}}" *ngFor="let item of itemsTomorrowStart; let i = index" (click)="edit(item.entity)">			
                    
                    <div class="time">
                        {{duration(item.message_start, item.message_end)|translate}}
                    </div>

                    <div class="category" style="background:{{item.message_type_hex}}">{{item.message_type_name}}</div>
                    <div class="activity_title" [innerHTML]="cFunction.URLtoHYPERLINK(item.title)"></div>
                    <fa-icon [icon]="['fad', 'flag']" class="priority_tag" [ngClass]="{'0':'priority0','1':'priority1','2':'priority2','3':'priority3'}[item.priority]" *ngIf="parameters.priority"></fa-icon>
                    <hr />

                    <div class="company" *ngIf="parameters.showcustomer && item.customer != undefined">
                        <fa-icon class="icon_small" [icon]="['fad', 'users']" style="font-size: 12px;"></fa-icon>
                        {{item.customer}}
                    </div>				
                    <div class="author_and_category" *ngIf="parameters.showauthor">
                        <fa-icon class="icon_small" [icon]="['fad', 'user']" style="font-size: 12px;margin:0px 4px 0px 2px"></fa-icon>
                        <span>{{item.author}}</span>						
                    </div>                                    
                </div>
            </div>	
        </div>	

        <div *ngIf="countObject(itemsWeekStart) > 0" class="day_group">
            <div class="title">{{'Next 7 days'|translate}}</div>
            <div class="content">
                <div class="widget_item {{item.entity}}" *ngFor="let item of itemsWeekStart; let i = index" (click)="edit(item.entity)">			
                    
                    <div class="time">
                        {{item.dateDisplay}} {{duration(item.message_start, item.message_end)|translate}}
                    </div>

                    <div class="category" style="background:{{item.message_type_hex}}">{{item.message_type_name}}</div>
                    <div class="activity_title" [innerHTML]="cFunction.URLtoHYPERLINK(item.title)"></div>
                    <fa-icon [icon]="['fad', 'flag']" class="priority_tag" [ngClass]="{'0':'priority0','1':'priority1','2':'priority2','3':'priority3'}[item.priority]" *ngIf="parameters.priority"></fa-icon>
                    <hr />

                    <div class="company" *ngIf="parameters.showcustomer && item.customer != undefined">
                        <fa-icon class="icon_small" [icon]="['fad', 'users']" style="font-size: 12px;"></fa-icon>
                        {{item.customer}}
                    </div>				
                    <div class="author_and_category" *ngIf="parameters.showauthor">
                        <fa-icon class="icon_small" [icon]="['fad', 'user']" style="font-size: 12px;margin:0px 4px 0px 2px"></fa-icon>
                        <span>{{item.author}}</span>						
                    </div>                                    
                </div>
            </div>	
        </div>	

    </div>

</div>
