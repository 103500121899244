<!-- Activities canban -->
<div class="inner_wrapper">
	<div class="table_header enached_table_header">
		<div class="left">
			<div class="table_header_left">
				<div class="table_title">
					<fa-icon [icon]="['fad', 'list']"></fa-icon> | {{'Activities'|translate}}
				</div>
				<div class="tableStatus" style="background:{{category_hex}}">{{category_title}}</div>
				<div class="table_functions">
				</div>
			</div>
		</div>
		<div class="right"></div>
	</div>	

	<div cdkDropListGroup>
		<div class="header_wrapper">
			<div *ngFor="let status of statuses_order" class="canban_wrapper header" style="width:{{status_width}}%">
				<h2 style="background:{{canban_data[status].status_hex}}">{{canban_data[status].status_title}}</h2>
				<div class="sumarize">
					<div class="label">{{'Total value of status'|translate}}</div>
					<div class="value">{{formatCurrency(activity_values[status])}}</div>
				</div>
			</div>
		</div>

		<div *ngFor="let status of statuses_order" class="canban_wrapper body" style="width:{{status_width}}%">
			<div cdkDropList [cdkDropListData]="canban_data[status].rows" class="example-list {{status}}" id="{{status}}" (cdkDropListDropped)="drop($event)">			
	      		<div class="example-box" *ngFor="let item of canban_data[status].rows" cdkDrag (click)="edit(item.entity)">
	      			<div class="activity_company">
	      				<span style="background:{{item.customer_hex}}"></span>{{item.customer}}
	      			</div>
	      			<div class="activity_author">
	      				{{item.author}}
	      			</div>
	      			<div class="activity_title">
	      				{{item.activity_title}}
	      			</div>
	      			<div class="activity_created">
	      				{{item.created}}	      				
	      			</div>
	      			<div class="activity_details">
	      				{{item.details}}
	      				<div *ngIf="item.value > 0" class="activity_value">
		      				{{item.formated_value}} | {{item.hours}}
		      			</div>
	      			</div>
	      		</div>      		
	    	</div>    
		</div>	
	</div>

</div>