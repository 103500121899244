import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  
  public inputTextValue = null;

  constructor(
    public dialog: MatDialogRef<ModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		public translate: TranslateService
  ) { }

  yes() {
    this.dialog.close({yes:true, value:this.inputTextValue});
  }

  no() {
    this.dialog.close({no:true});
  }

  cancel() {
    this.dialog.close({cancel:true});
  }

  ngOnInit(): void {    
    //console.log(this.data);
    if (this.data['inputText']!=undefined) this.inputTextValue = this.data['inputText'];
    if (this.data['inputTextArea']!=undefined) this.inputTextValue = this.data['inputTextArea'];
    
  }

}
