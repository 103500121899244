<div id="navWrapper">
	<div id="appSidebarNavigation">
		<ng-container *ngFor="let item of topNavigation">
			<ng-container *ngIf="item['entity']!='function:localSearch'">
				<ng-container *ngIf="item.entity=='apps'">
					<ng-container *ngFor="let subItem of item.submenu">
						<div class="submenuItem" class="{{subItem.type}}" [ngClass]="{'itemLeft':subItem.pinnable, 'exposed': item.exposed, 'wide':memberSettingsGlobal['showSidebar']==2}">
							<div id="{{subItem.entity}}" class="submenuItemInner" (click)="navigate(subItem.type, subItem.action, subItem.component, subItem.actionData);toggleOpenCloseSub(subItem.entity)" [ngClass]="{'subMenus':subItem.submenu != null}">
								<ng-container *ngIf="subItem.type != 'splitter' && subItem.type != 'text'">
									<fa-icon *ngIf="subItem.icon!=''" class="mainIcon" [icon]="[icon(subItem.icon)[0], icon(subItem.icon)[1]]"></fa-icon>
									<!--<fa-icon *ngIf="subItem.icon==''" class="mainIcon" [icon]="['fad', 'minus']" style="opacity:0.5"></fa-icon>-->
									<span>{{subItem.title}}</span>
									<ng-container *ngIf="subItem.submenu != null">
										<div *ngIf="(subItem.submenu) != null" class="subSubMenuWrapper"> 
											<ng-container *ngFor="let subSubItem of subItem.submenu">
												<div class="submenuItem" (click)="navigate(subSubItem.type, subSubItem.action, subSubItem.component, subSubItem.actionData)" class="{{subSubItem.type}}"  [ngClass]="{'exposed': subSubItem.exposed}">
													<div class="submenuItemInner subSubItemInner">
														<fa-icon *ngIf="subSubItem.icon!=null" class="mainIcon subSubItemIcon" [icon]="[icon(subSubItem.icon)[0], icon(subSubItem.icon)[1]]" [ngStyle]="{'color': subSubItem.iconColor}"></fa-icon>
														<span>{{subSubItem.title}}</span>
														<span *ngIf="subSubItem.subTitle !== ''" class="subTitle">{{subSubItem.subTitle}}</span>
													</div>
												</div>
											</ng-container>
										</div>
									</ng-container>										
								</ng-container>
							</div>
						</div>			
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
	</div>
</div>
