import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-assign-date-time',
  templateUrl: './assign-date-time.component.html',
  styleUrls: ['./assign-date-time.component.css']
})
export class AssignDateTimeComponent implements OnInit {

  constructor(
    private data: DataService,
    public dialog: MatDialogRef<AssignDateTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public injected_data,
    public translate: TranslateService 
  ) {
    translate.addLangs(['en', 'si']);
  }

  public calendar;
  public calendarConstructor = [];
  
  public selected_time_start = "15:30";
  public selected_time_end = "14:15";
  public selected_month_start = null;
  public selected_month_end = null;

  public current_month = null;
  public current_year = null;
  public datetime_from = null;
  public datetime_to = null;

  public months = [null, 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
  public hour_start = [];
  public hour_end = [];
  public minutes_start = [];
  public minutes_end = [];
  public minutes_available = [0,15,30,45];

  public time_start_defined = false;
  public time_end_defined = false;

  public time;
  public expand_view = false;
   
  loadCalendar(parameters) {          
    this.data.getCalendar(parameters).subscribe(
      (res: Response) => {        
        this.calendar = (<any>res);
        this.markSelectedDates();
      }
    );
  }


  nextMonth() {
    this.current_month++
    if (this.current_month>12) {
      this.current_month = 1;
      this.current_year++;
    }    
    this.loadCalendar({ month : this.current_month, year : this.current_year });
  }

  prevMonth() {
    this.current_month--;
    if (this.current_month<1) {
      this.current_month = 12;
      this.current_year--;
    }
    this.loadCalendar({ month : this.current_month, year : this.current_year });
  }

  markSelectedDates() {
    var start = new Date(Date.parse(this.selected_month_start.replace(/[-]/g,'/')));
    var end = new Date(Date.parse(this.selected_month_end.replace(/[-]/g,'/')));

    for(var i=0; i<this.calendar.data.length; i++) {      
      if (this.calendar.data[i].date != undefined) {
        var current = new Date(Date.parse(this.calendar.data[i].date.replace(/[-]/g,'/')));

        if (start.getTime() === end.getTime() && start.getTime() === current.getTime()) {
          this.calendar.data[i].selected = '4';
        } else if (start.getTime() === current.getTime()) {
          this.calendar.data[i].selected = '1';
        } else if ((start.getTime() < current.getTime() && end.getTime() > current.getTime() && end.getTime() !== current.getTime())) {
          this.calendar.data[i].selected = '2';
        } else if (end.getTime() === current.getTime()) {
          this.calendar.data[i].selected = '3';
        } else {
          this.calendar.data[i].selected = '0';
        }
      }
    }

    /*start = this.selected_time_start.split(':');
    end = this.selected_time_end.split(':');
*/
  }

  selectTimeHourStart(hour) {    
    var currentTime = this.selected_time_start.split(':');
    currentTime[0] = ("0" + hour).slice(-2);
    currentTime[1] = currentTime[1] == undefined || currentTime[1] == '' || currentTime[1] == '-' ? '00' : currentTime[1];
    this.selected_time_start = currentTime[0] + ':' + currentTime[1];
    for(var iHour = 0; iHour < 24; iHour++) {       
       this.hour_start[iHour].selected = iHour == hour ? 1 : 0;
    }   
    this.time_start_defined = true;  
  }

  selectTimeMinuteStart(minute) {
    var currentTime = this.selected_time_start.split(':');
    currentTime[1] = ("0" + minute).slice(-2); 
    currentTime[0] = currentTime[0] == undefined || currentTime[0] == '' || currentTime[0] == '-' ? '00' : currentTime[0];
    this.selected_time_start = currentTime[0] + ':' + currentTime[1];
    for(var iMinutes = 0; iMinutes < 60; iMinutes++) {       
       this.minutes_start[iMinutes].selected = iMinutes == minute ? 1 : 0;
    }
    this.time_start_defined = true;
  }

  selectTimeHourEnd(hour) {
    var currentTime = this.selected_time_end.split(':');
    currentTime[0] = ("0" + hour).slice(-2);
    currentTime[1] = currentTime[1] == undefined || currentTime[1] == '' || currentTime[1] == '-' ? '00' : currentTime[1];
    this.selected_time_end = currentTime[0] + ':' + currentTime[1];
    for(var iHour = 0; iHour < 24; iHour++) {       
       this.hour_end[iHour].selected = iHour == hour ? 1 : 0;
    }
    this.time_end_defined = true;
  }

  selectTimeMinuteEnd(minute) {
    var currentTime = this.selected_time_end.split(':');
    currentTime[1] = ("0" + minute).slice(-2);
    currentTime[0] = currentTime[0] == undefined || currentTime[0] == '' || currentTime[0] == '-' ? '00' : currentTime[0];
    this.selected_time_end = currentTime[0] + ':' + currentTime[1];
    for(var iMinutes = 0; iMinutes < 60; iMinutes++) {       
       this.minutes_end[iMinutes].selected = iMinutes == minute ? 1 : 0;
    }
    this.time_end_defined = true;
  }

  timeEndClear() {
    for(var iHour = 0; iHour < 24; iHour++) {       
       this.hour_end[iHour].selected = 0;
    }
    for(var iMinutes = 0; iMinutes < 60; iMinutes++) {       
       this.minutes_end[iMinutes].selected = 0;
    } 
    this.selected_time_end = "-";
    this.time_end_defined = false;
  }

  timeStartClear() {
    for(var iHour = 0; iHour < 24; iHour++) {       
       this.hour_start[iHour].selected = 0;
    }
    for(var iMinutes = 0; iMinutes < 60; iMinutes++) {       
       this.minutes_start[iMinutes].selected = 0;
    } 
    this.selected_time_start = "-";
    this.time_start_defined = false;
  }

  setSelectedOffset(offset) {
    console.log(this.selected_month_start);
    var current_date_time = new Date(this.selected_month_start);
    current_date_time.setDate(current_date_time.getDate() + offset);
    var helper_date = (current_date_time.toISOString().slice(0, 19).replace('T', ' ')).split(' ');
    
    //console.log(helper_date[0]);
    this.selectDate(helper_date[0]);    
  }

  setSelectedDate(offset) {
    var current_date_time = new Date();
    current_date_time.setDate(current_date_time.getDate() + offset);
    var helper_date = (current_date_time.toISOString().slice(0, 19).replace('T', ' ')).split(' ');
    
    //console.log(helper_date[0]);
    this.selectDate(helper_date[0]);
    this.selectDate(helper_date[0]);
  }

  selectDate(selectedDate) {   
    var t0 = selectedDate.split("-");
    var selectedDateJS = new Date(t0[0], t0[1] - 1, t0[2].substr(0,2));

    if (this.selected_month_start == null) this.selected_month_start = selectedDate;

    var t1 = this.selected_month_start.split("-");
    var startDate = new Date(t1[0], t1[1] - 1, t1[2].substr(0,2));

    if (this.selected_month_end == null) this.selected_month_end = selectedDate;

    var t2 = this.selected_month_end.split("-");
    var endDate = new Date(t2[0], t2[1] - 1, t2[2].substr(0,2));

    if (selectedDateJS.getTime() === startDate.getTime()) {
      this.selected_month_end = this.selected_month_start;      
    } else if (selectedDateJS.getTime() === endDate.getTime()) {      
      this.selected_month_start = this.selected_month_end;
    } else if (selectedDateJS.getTime() < startDate.getTime()) {
      this.selected_month_start = selectedDate;
    } else if (selectedDateJS.getTime() > endDate.getTime() || selectedDateJS.getTime() > startDate.getTime()) {
      this.selected_month_end = selectedDate;
    }  

    //alert(this.selected_month_start + ' | ' + this.selected_month_end);

    this.markSelectedDates();
  }

  confirm() {
    var data = { activity : this.injected_data.activity, start_date : this.selected_month_start + " " + this.selected_time_start, end_date : this.selected_month_end + " " + this.selected_time_end }
    this.dialog.close(data);   
  } 

  resize(state = null) {
    if (state == null) {
      this.expand_view=!this.expand_view;
      var value = this.expand_view ? 'true' : 'false';
      this.data.setMemberSettings({'parameter':'assignDateExpandView', 'value': value}).subscribe((res: Response) => {
        this.data.refreshMemberSettings();
      });            
    }
    
    if (this.expand_view == true) {
      this.dialog.updateSize('700px', 'auto');      
    } else {
      this.dialog.updateSize('450px', 'auto');      
    }
  }

  ngOnInit(): void {

    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.expand_view = GlobalVariables.memberSettings['assignDateExpandView'] == 'true' ? true : false;  
    this.resize(this.expand_view);

    //alert(lang + "|" + localStorage.getItem('assignDateExpandView'));

    this.translate.use(lang);

    /* prepare hour array */
    for(var iHour = 0; iHour < 24; iHour++) {
       var iHourHelper = { hour: iHour, display: true, selected: 0};
       this.hour_start.push(iHourHelper);
       var iHourHelper = { hour: iHour, display: true, selected: 0};
       this.hour_end.push(iHourHelper);
    }    

    /* prepare minute array */
    for(var iMinute = 0; iMinute < 60; iMinute++) {
      var iMinuteHelper = this.minutes_available.includes(iMinute) ? { minute: iMinute, display: true, selected: 0} : { minute: iMinute, display: false, selected: 0};
      this.minutes_start.push(iMinuteHelper);
      var iMinuteHelper = this.minutes_available.includes(iMinute) ? { minute: iMinute, display: true, selected: 0} : { minute: iMinute, display: false, selected: 0};
      this.minutes_end.push(iMinuteHelper);
    }    

    var d = new Date();
    this.current_month = d.getMonth()+1;
    this.current_year = d.getFullYear();

    this.datetime_from = this.injected_data.start_date_raw;
    this.datetime_to = this.injected_data.end_date_raw;
    this.time = this.injected_data.time;
    
    //alert(this.injected_data.start_date_raw + " > " + this.injected_data.end_date_raw);

    if (this.injected_data.start_date_raw != null && this.injected_data.start_date_raw != '0000-00-00 00:00:00') {      
      
      var date_helper = (this.injected_data.start_date_raw).split(' ');
      this.selected_month_start = date_helper[0];
      if (date_helper[1] == '00:00:00') {
        this.selected_time_start = '-';
        //this.selected_time_start = '00:00:00';
        this.time_start_defined = false;
      } else {
        this.selected_time_start = date_helper[1];
        this.time_start_defined = true;
      }
      
      var date_helper = (this.injected_data.end_date_raw).split(' ');
      this.selected_month_end = date_helper[0];
      if (date_helper[1] == '00:00:00') {
        this.selected_time_end = '-';
        //this.selected_time_end = '23:45:00';
        this.time_end_defined = false;
      } else {
        this.selected_time_end = date_helper[1];
        this.time_end_defined = true;
      }        
            
      //alert(this.selected_month_start + ' | ' + this.selected_month_end);

    } else {

      var date_helper = <any>(new Date().toISOString().slice(0, 19).replace('T', ' ')); 
      date_helper = date_helper.split(' ');      
      //this.selected_time_start = date_helper[1];
      this.selected_time_start = "00:00:00";
      this.selected_month_start = date_helper[0];
      //this.selected_time_end = '23:59';
      //this.selected_time_end = '-';
      this.selected_time_end = "23:45:00";
      this.selected_month_end = date_helper[0];

    }

    var today = new Date();    
    var parameters = { month : (today.getMonth()+1), year : today.getFullYear() };
    this.loadCalendar(parameters);

  }

}
