import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../services/data.service';
import { GlobalVariables } from '../services/data.globals';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonFunctions } from '../services/common.functions';
import { NavigationService } from '../services/navigation.service';

/* UPLOAD */
import { UploadService } from '../services/upload.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs'; 


@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {

  public component = 'comment';
  public lastFunctionCallAt = Date.now(); 
	

  public attachments = [];
  public attachmentsFullPath = [];
  public storage = null;

  constructor(
    private dataService: DataService,
    public dialog: MatDialogRef<CommentComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
    public translate: TranslateService,    
    public cFunction: CommonFunctions,
    private UploadService: UploadService, //UPLOAD
    public matDialog: MatDialog,
	private NavigationService: NavigationService
  ) {
    translate.addLangs(['en', 'si']);
  }

  addAttachment(data) {
	this.dataService.getStorageID({}).subscribe((res: Response) => {
		if (data != null) {
			this.storage = res['storage'];
			var helper = "https://api.biliz.com/storage/" + res['storage'] + "/" + data.charAt(0) + "/" + data.charAt(1) + "/" + data;			
			this.attachmentsFullPath.push(helper);
			this.attachments.push(data);
		}      
	});   
  }

  removeAttachment(index) {	
	this.attachmentsFullPath.splice(index, 1);
	this.attachments.splice(index, 1);
  }

 /* 
  closeDropField_comment() {    
    const element = document.getElementsByClassName("file_container_comment")[0] as HTMLElement;
    element.style.visibility = "hidden";
    element.style.opacity = "0";    
    const element2 = document.getElementsByClassName("dropdowninfo")[0] as HTMLElement;
    element2.style.visibility = "";
    this.files = [];	
  }
*/
  save() {    
    this.data['attachments'] = this.attachments;
	console.log(this.data);
    if (this.data.entity == undefined) {      
      this.dataService.saveComment({ data: this.data }).subscribe(
        (res: Response) => { 
          this.dialog.close(true);        
        }
      );    
    } else {
      this.dataService.updateComment({ data: this.data }).subscribe(
        (res: Response) => { 
          this.dialog.close(true);        
        }
      );
    }
  }

/*****************************************************************************************************************************/
	/*****************************************************************************************************************************/
	/*****************************************************************************************************************************/
	/* Uploader 				                                              */
/*
	public files: any[] = [];
	public selectedFiles: FileList;
  public currentFile: File;
  public progress = 0;  
  public fileInfos: Observable<any>;
  private document_details;

  private _openDialog() {
		if (!this.matDialog.openDialogs || !this.matDialog.openDialogs.length) return;		
	}

	prepareFilesList(files: Array<any>) {
		for (const item of files) {
			item.progress = 0;
			this.files.push(item);
		}
		this.uploadFiles(0);
	}

	onFileDropped($event) {
		this._openDialog();	
		this.prepareFilesList($event);		
	}

	closeDropField() {
		const element = document.getElementsByClassName("file_container_activity")[0] as HTMLElement;
		element.style.visibility = "hidden";
		element.style.opacity = "0";    
		const element2 = document.getElementsByClassName("dropdowninfo")[0] as HTMLElement;
		element2.style.visibility = "";
		this.files = [];    
	}

	fileBrowseHandler(files) {		
	}

	deleteFile(index: number) {
		this.files.splice(index, 1);
	}
  
  	uploadFiles(index: number) {
		this.upload(this.files[index]);
		setTimeout(() => {
			if (index === this.files.length) {
				(document.getElementsByClassName("progress_container")[0] as HTMLElement).style.visibility = "hidden";
				(document.getElementsByClassName("progress_container")[0] as HTMLElement).style.opacity = "0";    
				(document.getElementsByClassName("dropdowninfo")[0] as HTMLElement).style.visibility = "";       
				this.files = [];
        
				return;
			} else {
				const progressInterval = setInterval(() => {
					if (this.files[index] != undefined) {
						if (this.files[index].progress === 100) {
							clearInterval(progressInterval);	            
							this.uploadFiles(index + 1);            
						} else {
							this.files[index].progress = this.progress;	            
						}
					}
				}, 150);
			}
		}, 150);		
	}

	
	upload(currentFile): void {
		this.progress = 0;
		this.UploadService.upload(currentFile).subscribe(	    
			event => {
				if (event.type === HttpEventType.UploadProgress) {
					this.progress = Math.round(100 * event.loaded / event.total);	 	        
				} else if (event instanceof HttpResponse) {
					if (event.body.name != undefined) {
						this.document_details = [];
						this.document_details['category_id'] = -1;
						this.document_details['doc_file'] = event.body['name'];
						this.document_details['doc_code'] = event.body['original_name'];
						this.document_details['doc_size'] = currentFile.size;	        
						console.log(this.document_details);
						
						var entity = "company_logo";
						var value = this.document_details['doc_file']
						this.dataService.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});       
						
            			this.dataService.getStorageID({}).subscribe((res: Response) => {
							if (value != null) {
								this.storage = res['storage'];
								var helper = "https://api.biliz.com/storage/" + res['storage'] + "/" + value.charAt(0) + "/" + value.charAt(1) + "/" + value;	
								

								this.attachmentsFullPath.push(helper);
								this.attachments.push(value);
								console.log(this.attachments);
								this.closeDropField_comment();                                
							}      
						});   
            
					}
				}
			},
			err => {
				this.progress = 0;
				//this.message = 'Could not upload the file!';
				currentFile = undefined;
			}
		);
		this.selectedFiles = undefined;
	}

	formatBytes(bytes, decimals) {
		if (bytes === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals || 2;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	} 
*/

  /***********************************************************************/
  /***********************************************************************/

  ngOnInit(): void {

	/* Handle local function called form navigation service */
	this.NavigationService.localFunction.subscribe(callLocalFunction => {
		if (callLocalFunction[0] == this.component && callLocalFunction[1] == "localFunction" && this.lastFunctionCallAt < Date.now()) {
			this[callLocalFunction[2]](callLocalFunction[3]);
			//this.NavigationService.prepareObserverForLocalFunction([]);        
			this.lastFunctionCallAt = Date.now()+500; //prevent calling function too fast by default 500mS between call
		}
	});

    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);
		
	/* check for attachment */	
	this.dataService.getCommentAttachments({'comment_ids' : this.data['entity']}).subscribe((res: Response) => {		
		let items = (<any>res).rows;		
		for (let i=0; i<items.length; i++) {			
			this.addAttachment(items[i]['attachment']);
		}
	});	
  }

}
