import { Component } from '@angular/core';

@Component({
  selector: 'app-color-chooser',
  standalone: true,
  imports: [],
  templateUrl: './color-chooser.component.html',
  styleUrl: './color-chooser.component.css'
})
export class ColorChooserComponent {

}
