<div class="inner_wrapper">
	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="left">
		<h1>
			{{'Document group'|translate}}
		</h1>
		</div>

		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
		</div>
	</div>
	

	<!--<form [formGroup]="formData" (ngSubmit)="save()" novalidate style="width:825px">-->

		<div class="body">
	
			<input type="hidden"  name="entity" [(ngModel)]="rows['entity']" />

			<div class="main_data w50">
				

				<div class="inline w100 wPadding">               
					<mat-form-field appearance="outline">
					  <mat-label>{{'Document type description'|translate}}</mat-label>
					  <input type="text" matInput  name="doc_type" [(ngModel)]="rows['doc_type']">
					  <!--<mat-hint>Error</mat-hint>-->
					</mat-form-field>
				</div>

				<div class="inline w100 wPadding">				
					<ngx-colors ngx-colors-trigger [format]="'hex'" [(ngModel)]="rows['hex']"></ngx-colors>
					<div class="colorPickerText">{{'Document group color'|translate}}{{color}}</div>				
				</div>			
			</div>


			<div class="options w40">
				<div class="w100 left">				
					<div class="line-title">
						{{'Document options'|translate}}
					</div>				
				</div>

				<div class="w100 left">				
					<div class="field">
						<label class="switch">
							<input type="checkbox"  name="master" [(ngModel)]="rows['master']">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Master Document'|translate}}</div>						
					</div>			
				</div>

				<div class="w100 left">				
					<div class="field">
						<label class="switch">
							<input type="checkbox"  name="prevent_public" [(ngModel)]="rows['prevent_public']">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Private'|translate}}</div>
					</div>			
				</div>

				<div class="w100 left">				
					<div class="field">
						<label class="switch">
							<input type="checkbox"  name="document_with_value" [(ngModel)]="rows['document_with_value']">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Document with value'|translate}}</div>
					</div>			
				</div>

				<div class="w100 left">				
					<div class="field">
						<label class="switch">
							<input type="checkbox"  name="document_approval" [(ngModel)]="rows['document_approval']">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Document approval needed'|translate}}</div>
					</div>			
				</div>			
			</div>

			<div class="options" style="float:left;width:44%;padding-left:24px;margin-left:24px;border-left: none !important;">
				&nbsp;
			</div>

			<div class="inline w100 divider"></div>

			<div class="assigns wPadding">
				<div class="w100 left">				
					<div class="line-title">
						{{'Document access for Groups'|translate}}
					</div>				
				</div>

				<div class="w100 left">
					<div class="access_title">
						{{'Member group'|translate}}
					</div>
					<div class="access_view">
						{{'Allow View'|translate}}
					</div>
					<div class="access_create">
						{{'Allow Upload'|translate}}
					</div>
					<div class="access_edit">
						{{'Allow Modify'|translate}}
					</div>
				</div>

				<ng-container *ngFor="let member_group of member_groups">
					<div class="w100 left access_line">
						<div class="access_title">
							<span style="background:{{member_group.hex}}"></span>{{member_group.member_group}}
						</div>
						<div class="access_view">
							<!--<input class="content_access" allow="view" [attr.type_entity]="member_group.entity" accesstype="group" type="checkbox" />-->
							<label class="switch">
								<input type="checkbox" allow="view" [attr.type_entity]="member_group.entity" accesstype="group">
								<span class="slider round"></span>
							</label>
						</div>
						<div class="access_create">
							<!--<input class="content_access" allow="create" [attr.type_entity]="member_group.entity" accesstype="group" type="checkbox" />-->
							<label class="switch">
								<input type="checkbox" allow="create" [attr.type_entity]="member_group.entity" accesstype="group">
								<span class="slider round"></span>
							</label>
						</div>
						<div class="access_edit">
							<!--<input class="content_access" allow="edit" [attr.type_entity]="member_group.entity" accesstype="group" type="checkbox" />-->
							<label class="switch">
								<input type="checkbox" allow="edit" [attr.type_entity]="member_group.entity" accesstype="group">
								<span class="slider round"></span>
							</label>
						</div>
					</div>
				</ng-container>
			</div>
			
			<div class="inline w100 divider"></div>

			<div class="assigns wPadding">
				<div class="w60 left">
					<div class="line-title">
						{{'Document access for Members'|translate}}
					</div>
				</div>

				<div class="w40 left">
					<div class="line-title">
						text
					</div>
				</div>

				<div class="w100 left">
					<div class="access_title">
						{{'Member'|translate}}
					</div>
					<div class="access_view">
						{{'Allow View'|translate}}
					</div>
					<div class="access_create">
						{{'Allow Upload'|translate}}
					</div>
					<div class="access_edit">
						{{'Allow Modify'|translate}}
					</div>
				</div>

				<ng-container *ngFor="let member of members">
					<div class="w100 left access_line">
						<div class="access_title">
							{{member.username}}
						</div>
						<div class="access_view">
							<!--<input class="content_access" allow="view" [attr.type_entity]="member.entity" accesstype="user" type="checkbox" />-->
							<label class="switch">
								<input type="checkbox" allow="view" [attr.type_entity]="member.entity" accesstype="user">
								<span class="slider round"></span>
							</label>
						</div>
						<div class="access_create">
							<!--<input class="content_access" allow="create" [attr.type_entity]="member.entity" accesstype="user" type="checkbox" />-->
							<label class="switch">
								<input type="checkbox" allow="create" [attr.type_entity]="member.entity" accesstype="user">
								<span class="slider round"></span>
							</label>
						</div>
						<div class="access_edit">
							<!--<input class="content_access" allow="edit" [attr.type_entity]="member.entity" accesstype="user" type="checkbox" />-->
							<label class="switch">
								<input type="checkbox" allow="edit" [attr.type_entity]="member.entity" accesstype="user">
								<span class="slider round"></span>
							</label>
						</div>
					</div>
				</ng-container>
			</div>
		</div>

		<div class="modalActions" mat-dialog-actions>
			<button class="accept right" (click)="save()">{{'Save'|translate}}</button>
			<button class="warrning right" (click)="delete()">{{'Delete'|translate}}</button>
			<button class="action left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
		</div>

	<!--</form>-->

	<div id="colorPicker">
		<ng-container *ngFor="let color of colors">
			<div class="colorPickerChild" [attr.hex]="color" style="background:{{color}}"></div>		
		</ng-container>
	</div>
</div>