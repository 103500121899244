<div class="inner_wrapper">
	<app-grid-body 
		[refreshData]="refreshDataFunc" [updateParameters]="updateParametersFunc" [edit]="editFunc" [priceRange]="priceRangeFunc"
		[component]="component" [tableConstructor]="tableConstructor" [items]="items" [orderby]="orderby" [order]="order">
	</app-grid-body>

	<app-grid-footer  	
		[refreshData]="refreshDataFunc" [updateParameters]="updateParametersFunc"
		[component]="component" [itemsLimit]="itemsLimit" [pagination]="pagination" [page]="page" [pages]="pages" [total_items]="total_items" [itemsLimit_display]="itemsLimit_display">
	</app-grid-footer>
</div>

<div *ngIf="printOptions" class="printOptions mat-dialog-container">
	<div class="">
		<div class="inner_wrapper">

			<div class="header">
				<div class="left">
					<h1>
						{{'Auto generate functions'|translate}}
					</h1>
				</div>
		
				<div class="right">
					<fa-icon class="icon_close" [icon]="['fad', 'times']" (click)="printOptions=!printOptions"></fa-icon>
				</div>
			</div>
			
			<div class="inner_scroll body">

				<div class="inline w100 wPadding">
					<mat-form-field appearance="outline">
					  <mat-label>{{'Date'|translate}}</mat-label>
					  <input matInput [matDatepicker]="picker1" [(ngModel)]="documentDate">
					  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
					  <mat-datepicker #picker1></mat-datepicker>
					  <!--<mat-hint>Error</mat-hint>-->                    
					</mat-form-field>
				</div>

				<div class="inline w100 wPadding">
					<mat-form-field appearance="outline">
					  <mat-label>{{'Date of service'|translate}}</mat-label>
					  <input matInput [matDatepicker]="picker2" [(ngModel)]="documentService">
					  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
					  <mat-datepicker #picker2></mat-datepicker>
					  <!--<mat-hint>Error</mat-hint>-->                    
					</mat-form-field>
				</div>
	
				<div class="inline w90">
					<button class="action left" (click)="duplicateErpDocuments(documentDate, documentService)">{{'Duplicate selected documents'|translate}}</button>
				</div>
								
				<div class="inline w90">
					<button class="action left" (click)="generateSinglePDF()">{{'Print selected documents'|translate}}</button>
				</div>
			</div>
	
			<div class="modalActions">
				<button class="action left" (click)="printOptions=!printOptions">{{'Close'|translate}}</button> 
			</div>
		</div>
	</div>
</div>