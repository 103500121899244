import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core'; 
import { GlobalVariables } from '../services/data.globals';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.css']
})
export class WidgetsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<WidgetsComponent>,
    public translate: TranslateService 
  ) { 
     translate.addLangs(['en', 'si']);
   }

  add(widget) {
    this.dialogRef.close(widget);
  }

  ngOnInit(): void {
    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);
  }

}
