import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { ActivatedRoute } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TopComponent } from '../../../top/top.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponent } from '../../../modal/modal.component';

@Component({
  selector: 'app-document-type-edit',
  templateUrl: './document-type-edit.component.html',
  styleUrls: ['./document-type-edit.component.css']
})

export class DocumentTypeEditComponent implements OnInit {

	private sub : any;
	public color;

	public entity;		
	public rows = {};
	public member_groups = [];
	public members = [];
	public parameters = {};
	public statuses = [];
	public useStatuses = false;
	public selectedColorPicker;
	public extended = false;

	public colors = ['#f93939','#f14881','#8464c2','#364046','#9c24ac','#3453b1','#0097c8','#009789','#4ab05a','#8cc358','#ffe43f','#ffbf36','#ff9529','#ff6b2c','#724e45','#878988','#5e7e8a']

	/*******************************/

	constructor(
		private data: DataService,
		private route: ActivatedRoute,
		public translate: TranslateService,
		public dialog: MatDialog, 
		public dialogRef: MatDialogRef<DocumentTypeEditComponent>
	) {		
		translate.addLangs(['en', 'si']);
	}
	
	/*******************************/

	makeid(length) {
	   var result           = '';
	   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567890';
	   var charactersLength = characters.length;
	   for ( var i = 0; i < length; i++ ) {
	      result += characters.charAt(Math.floor(Math.random() * charactersLength));
	   }
	   return result;
	}

	get(id) {    		
		this.data.getAccesses(null).subscribe(
			(res: Response) => { 
				this.member_groups = (<any>res).member_groups; 
				this.members = (<any>res).members;				

				//console.log('*********************************************');
				//console.log(res);
				
				this.data.getDocumentType({entity:id}).subscribe(
					(res: Response) => { 

						this.rows = (<any>res).rows[0];
						var access = this.rows['access'];							
						for (var i=0; i<access.length; i++) {					
							if (access[i].view=='true') {
								$('input[type=checkbox][allow=view][type_entity='+access[i].type_entity+'][accesstype='+access[i].type+']').prop( "checked", true);
								//console.log('input[type=checkbox][allow=view][type_entity='+access[i].type_entity+'][accesstype='+access[i].type+']');
							}
							if (access[i].edit=='true') {
								$('input[type=checkbox][allow=edit][type_entity='+access[i].type_entity+'][accesstype='+access[i].type+']').prop( "checked", true);
								//console.log('input[type=checkbox][allow=edit][type_entity='+access[i].type_entity+'][accesstype='+access[i].type+']');						
							}
							if (access[i].create=='true') {
								$('input[type=checkbox][allow=create][type_entity='+access[i].type_entity+'][accesstype='+access[i].type+']').prop( "checked", true);
								//console.log('input[type=checkbox][allow=create][type_entity='+access[i].type_entity+'][accesstype='+access[i].type+']');						
							}
						}
						
					}
				);

			}
		);

	}


	save() {
		this.parameters = this.rows;

	    /* get permissions */ 	    
		var attrs = [];
	    $('.access_line').each(function() {
	    	var attr_type = $(this).find('.access_view input').attr('accesstype');
	    	var attr_type_entity = $(this).find('.access_view input').attr('type_entity');
	    	var attr_view = $(this).find('.access_view input').is(':checked');
	    	var attr_create = $(this).find('.access_create input').is(':checked');
	    	var attr_edit = $(this).find('.access_edit input').is(':checked');
	    	var attr = {'type':attr_type,'type_entity':attr_type_entity,'view':attr_view,'create':attr_create,'edit':attr_edit}
	    	attrs.push(attr);
	    });
		
		this.parameters['access'] = attrs;
	    
		this.data.saveDocumentType({'data':this.parameters}).subscribe(
	      (res: Response) => {
			this.dialogRef.close(null); 
		  }
	    );    	    	  
	 }

	delete() {				
		let content = this.translate.instant('Do you want to delete document group?<br /><br /><b>You will permanently lose all files in this group!</b>');

		let modalDialog = this.dialog.open(ModalComponent, {
		data: { content: content, yes: this.translate.instant('Delete'), no: null, cancel: this.translate.instant('Cancel') }
		});  

		modalDialog.afterClosed().subscribe(result => {      
			if (result['yes']!=undefined) {    
				this.parameters = this.rows;
				this.data.deleteDocumentType({'data':this.parameters}).subscribe(
				(res: Response) => {
					this.dialogRef.close(null); 
				}
				);
			}
		});
	}
	
	addStatus() {
		this.statuses.push({'id':this.makeid(5), 'label':'', 'hex':'#f0f0f0'});
	}

	removeStatus(row) {
		this.statuses.splice(row,1);	
	}

	/*******************************/

	showExtendedMode() {}

	ngOnInit(): void {	

		var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    	this.translate.use(lang);
    
		const component = this;

		this.sub = this.route.params.subscribe(params => {
			var id = params['id'];
			if (id != null) {
				if (1==1) //console.log("Load document_type: " + id);
				this.get(id);
			}
			if (this.entity != null) {
				if (1==1) //console.log("Modal load document_type: " + this.entity);
				this.get(this.entity);	
			}

			if (id == null && this.entity == null)
			this.data.getAccesses(null).subscribe(
				(res: Response) => { 
					this.member_groups = (<any>res).member_groups; 
					this.members = (<any>res).members;
				}
			);

		}); 

		/* jQuery */
    	$(document).ready(function() {
    		$(document).on('click', '.color_picker', function() {
    			$('#colorPicker').show();
    			component.selectedColorPicker = $(this);
    			var position = component.selectedColorPicker.offset();
				$('#colorPicker').css({'top':position.top+25+'px', 'left':position.left+'px'});
    		});

    		$(document).on('click', '.colorPickerChild', function() {
    			var rel = component.selectedColorPicker.attr('rel');
    			component.selectedColorPicker.attr('hex', $(this).attr('hex'));
    			component.selectedColorPicker.css({'background':$(this).attr('hex')});
    			$('[name='+rel+']').val($(this).attr('hex'));
    			$('#colorPicker').hide();
    		});
	   	});

  	}
}