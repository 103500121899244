import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from "@angular/router";
import { DataService } from '../services/data.service';
import { GlobalVariables } from '../services/data.globals';
import { CommonFunctions } from '../services/common.functions';
import { FormControl, FormGroup } from '@angular/forms';
import { EmailService } from '../services/mail.service';

/* UPLOAD */
import { UploadService } from '../services/upload.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.css']
})
export class SystemComponent implements OnInit {

  public selectedTab = "organisation";

  public etalk_email = "";
  public tentant = "";
  public app_client_id = "";
  public object_id = "";
  public client_secret_value = "";
  public outlook_enable = false;
  public etalk_footer = "";

  public company_vat;
  public company_organisation;
  public company_street;
  public company_zip;
  public company_city;
  public company_phone;
  public company_email;
  public company_url;
  public bank_account;
  public bank_swift;
  public company_logo;
  public api_GoogleMap;

  public emailServer = {};

  constructor(
    private data: DataService,    
    public cFunction: CommonFunctions,
    private UploadService: UploadService, //UPLOAD
    public dialog: MatDialog,
    private EmailService: EmailService
  ) { }

  sendTestEmail() {    
    /*
    const to = 'aljosa@petka.eu';
    const subject = 'Testna e-pošta';
    const html = '<h1>Pozdrav!</h1><p>To je HTML vsebina e-pošte.</p>';
    
    const file = new File(['To je vsebina testne datoteke.'], 'test.txt', { type: 'text/plain' });

    this.EmailService.sendEmail(to, subject, html, file).then(      
      (response) => {
        console.log('E-pošta poslana uspešno!');
        console.log('Odziv SMTP strežnika:', response);
      },
      (err) => console.error('Napaka pri pošiljanju e-pošte:', err)
    );
    */

    const to = 'aljosa@petka.eu';
    const subject = 'Test Email With PDF';
    const body = '<h1>Hi</h1><p>This email has a PDF attachment.</p>';

    // Simulacija PDF vsebine (lahko pridobite Base64 iz datoteke ali API-ja)
    const pdfContent = 'JVBERi0xLjEKJcKlwrHDqwoKMSAwIG9iagogIDw8IC9UeXBlIC9DYXRhbG9nCiAgICAgL1BhZ2VzIDIgMCBSCiAgPj4KZW5kb2JqCgoyIDAgb2JqCiAgPDwgL1R5cGUgL1BhZ2VzCiAgICAgL0tpZHMgWzMgMCBSXQogICAgIC9Db3VudCAxCiAgICAgL01lZGlhQm94IFswIDAgMzAwIDE0NF0KICA+PgplbmRvYmoKCjMgMCBvYmoKICA8PCAgL1R5cGUgL1BhZ2UKICAgICAgL1BhcmVudCAyIDAgUgogICAgICAvUmVzb3VyY2VzCiAgICAgICA8PCAvRm9udAogICAgICAgICAgIDw8IC9GMQogICAgICAgICAgICAgICA8PCAvVHlwZSAvRm9udAogICAgICAgICAgICAgICAgICAvU3VidHlwZSAvVHlwZTEKICAgICAgICAgICAgICAgICAgL0Jhc2VGb250IC9UaW1lcy1Sb21hbgogICAgICAgICAgICAgICA+PgogICAgICAgICAgID4+CiAgICAgICA+PgogICAgICAvQ29udGVudHMgNCAwIFIKICA+PgplbmRvYmoKCjQgMCBvYmoKICA8PCAvTGVuZ3RoIDU1ID4+CnN0cmVhbQogIEJUCiAgICAvRjEgMTggVGYKICAgIDAgMCBUZAogICAgKEhlbGxvIFdvcmxkKSBUagogIEVUCmVuZHN0cmVhbQplbmRvYmoKCnhyZWYKMCA1CjAwMDAwMDAwMDAgNjU1MzUgZiAKMDAwMDAwMDAxOCAwMDAwMCBuIAowMDAwMDAwMDc3IDAwMDAwIG4gCjAwMDAwMDAxNzggMDAwMDAgbiAKMDAwMDAwMDQ1NyAwMDAwMCBuIAp0cmFpbGVyCiAgPDwgIC9Sb290IDEgMCBSCiAgICAgIC9TaXplIDUKICA+PgpzdGFydHhyZWYKNTY1CiUlRU9GCg==';
    const attachments = [
      {
        filename: 'example.pdf',
        content: atob(pdfContent), // Pretvori Base64 v binarni niz
      },
    ];

    let parameters = { 'to' : to, 'subject' : subject, 'body' : body, 'attachments' : attachments };
    this.data.sendEmail(parameters).subscribe(
      (res: Response) => {
        //console.log(res);
        this.EmailService.update('scheduled');
      }
    );
        

    /*
    this.mailService.sendEmail(to, subject, body, attachments).subscribe({
      next: () => console.log('Email sent successfully with PDF attachment'),
      error: (err) => console.error('Error sending email', err),
    });
    */  
  }

  close() {}

  save() {

    var entity = null;
    var value = null;

    /*
    entity = "etalk_email";
    value = this.etalk_email;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "tentant";
    value = this.tentant;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "app_client_id";
    value = this.app_client_id;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "object_id";
    value = this.object_id;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "client_secret_value";
    value = this.client_secret_value;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "etalk_footer";
    value = this.etalk_footer;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "outlook_enable";
    value = this.outlook_enable;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});
    */

    entity = "emailServer";
    value = JSON.stringify(this.emailServer);
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "company_vat";
    value = this.company_vat;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "company_organisation";
    value = this.company_organisation;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "company_street";
    value = this.company_street;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "company_zip";
    value = this.company_zip;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "company_city";
    value = this.company_city;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "company_phone";
    value = this.company_phone;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "company_email";
    value = this.company_email;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "company_url";
    value = this.company_url;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "bank_account";
    value = this.bank_account;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "bank_swift";
    value = this.bank_swift;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "api_GoogleMap";
    value = this.api_GoogleMap;
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});  
  }

 
  switchTab(value) {
		this.selectedTab = value;
	}   

  /*****************************************************************************************************************************/
	/*****************************************************************************************************************************/
	/*****************************************************************************************************************************/
	/* Uploader 				                                              */

	public files: any[] = [];
	public selectedFiles: FileList;
  public currentFile: File;
  public progress = 0;  
  public fileInfos: Observable<any>;
  private document_details;

  private _openDialog() {
		if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) return;		
	}

	prepareFilesList(files: Array<any>) {
		for (const item of files) {
			item.progress = 0;
			this.files.push(item);
		}
		this.uploadFiles(0);
	}

	onFileDropped($event) {
		this._openDialog();	
		this.prepareFilesList($event);		
	}

	closeDropField() {
		const element = document.getElementsByClassName("file_container_activity")[0] as HTMLElement;
		element.style.visibility = "hidden";
		element.style.opacity = "0";    
		const element2 = document.getElementsByClassName("dropdowninfo")[0] as HTMLElement;
		element2.style.visibility = "";
		this.files = [];    
	}

	fileBrowseHandler(files) {		
	}

	deleteFile(index: number) {
		this.files.splice(index, 1);
	}
  
  uploadFiles(index: number) {
		this.upload(this.files[index]);
		setTimeout(() => {
			if (index === this.files.length) {
				(document.getElementsByClassName("progress_container")[0] as HTMLElement).style.visibility = "hidden";
				(document.getElementsByClassName("progress_container")[0] as HTMLElement).style.opacity = "0";    
				(document.getElementsByClassName("dropdowninfo")[0] as HTMLElement).style.visibility = "";       
				this.files = [];
        
				return;
			} else {
				const progressInterval = setInterval(() => {
					if (this.files[index] != undefined) {
						if (this.files[index].progress === 100) {
							clearInterval(progressInterval);	            
							this.uploadFiles(index + 1);            
						} else {
							this.files[index].progress = this.progress;	            
						}
					}
				}, 150);
			}
		}, 150);		
	}

	/* upload 1 file at once */
	upload(currentFile): void {
		this.progress = 0;
		this.UploadService.upload(currentFile).subscribe(	    
			event => {
				if (event.type === HttpEventType.UploadProgress) {
					this.progress = Math.round(100 * event.loaded / event.total);	 	        
				} else if (event instanceof HttpResponse) {
					if (event.body.name != undefined) {
            this.document_details = [];
            this.document_details['category_id'] = -1;
						this.document_details['doc_file'] = event.body['name'];
						this.document_details['doc_code'] = event.body['original_name'];
						this.document_details['doc_size'] = currentFile.size;	        
						console.log(this.document_details);
						//save upload **************************
            var entity = "company_logo";
            var value = this.document_details['doc_file']
            this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});       
            
            this.data.getStorageID({}).subscribe((res: Response) => {
              if (value != null) {
                var helper = "https://api.biliz.com/storage/" + res['storage'] + "/" + value.charAt(0) + "/" + value.charAt(1) + "/" + value;	
                document.getElementById('background_image').style.backgroundImage = 'url('+helper+')';
              }      
            });   
            //this.updateSettings();
					}
				}
			},
			err => {
				this.progress = 0;
				//this.message = 'Could not upload the file!';
				currentFile = undefined;
			}
		);
		this.selectedFiles = undefined;
	}

	formatBytes(bytes, decimals) {
		if (bytes === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals || 2;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	} 
  

  /*****************************************************************************************************************************/
	/*****************************************************************************************************************************/
	/*****************************************************************************************************************************/
	isJSONString(value: any): boolean {
    if (typeof value !== 'string') { return false; }      
    const trimmedValue = value.trim();
    return (trimmedValue.startsWith('{') && trimmedValue.endsWith('}')) || (trimmedValue.startsWith('[') && trimmedValue.endsWith(']'));
  }


  ngOnInit(): void {

    var entity = null;

    /*
    entity = "etalk_email";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.etalk_email = res['rows'][0]['value']; });

    entity = "tentant";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.tentant = res['rows'][0]['value']; });

    entity = "app_client_id";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.app_client_id = res['rows'][0]['value']; });

    entity = "object_id";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.object_id = res['rows'][0]['value']; });

    entity = "client_secret_value";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.client_secret_value = res['rows'][0]['value']; });

    entity = "etalk_footer";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.etalk_footer = res['rows'][0]['value']; });

    entity = "outlook_enable";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.outlook_enable = res['rows'][0]['value'] == 1 ? true : false; });
    */

    entity = "emailServer";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { 
      this.emailServer = this.isJSONString(res['rows'][0]['value']) ? JSON.parse(res['rows'][0]['value']) : {};
    });

    entity = "company_vat";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.company_vat = res['rows'][0]['value']; });

    entity = "company_organisation";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.company_organisation = res['rows'][0]['value']; });

    entity = "company_street";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.company_street = res['rows'][0]['value']; });

    entity = "company_zip";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.company_zip = res['rows'][0]['value']; });

    entity = "company_city";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.company_city = res['rows'][0]['value']; });

    entity = "company_phone";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.company_phone = res['rows'][0]['value']; });

    entity = "company_email";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.company_email = res['rows'][0]['value']; });

    entity = "company_url";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.company_url = res['rows'][0]['value']; });

    entity = "bank_account";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.bank_account = res['rows'][0]['value']; });

    entity = "bank_swift";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.bank_swift = res['rows'][0]['value']; });

    entity = "api_GoogleMap";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.api_GoogleMap = res['rows'][0]['value']; });

    this.data.getStorageID({}).subscribe((res: Response) => {
      var storage = res['storage'];
      entity = "company_logo";
      this.company_logo = null;
      this.data.getSettings({entity : entity}).subscribe((res: Response) => {         
        this.company_logo = res['rows'][0]['value']; 
        if (this.company_logo != null) {
          var helper = "https://api.biliz.com/storage/" + storage + "/" + this.company_logo.charAt(0) + "/" + this.company_logo.charAt(1) + "/" + this.company_logo;	
          console.log(helper);
          document.getElementById('logo_container').style.backgroundImage = 'url('+helper+')';
        }
      });
    });    

  }

}


