import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from './data.service';
import { GlobalVariables } from './data.globals'; 

@Injectable({
  providedIn: 'root'
})
export class MembersettingsService {

  private memberSettingsGlobalSource = new BehaviorSubject<Array<any>>([]);
  public memberSettingsGlobal = this.memberSettingsGlobalSource.asObservable();

  private memberAllowanceSource = new BehaviorSubject<Array<any>>([]);
  public memberAllowance = this.memberAllowanceSource.asObservable();

  constructor(        
    private data: DataService,
  ) {}

  refreshMemberSettings() {
    this.data.getMemberSettings(null).subscribe(
      (res: Response) => {    
        var memberSettingsArray = [];        
        for (var i=0; i<res['rows'].length; i++) {          
          memberSettingsArray[res['rows'][i].parameter] = res['rows'][i].value;
        }                
        //memberSettingsArray['apps'] = GlobalVariables['apps'];
        this.memberSettingsGlobalSource.next(memberSettingsArray);
        console.log('MemberSettings refreshed (Global)...');   
      }
    );
  }
  

  setMemberSettings(parameter, value) { 
    this.memberSettingsGlobal[parameter] = value;
    this.data.setMemberSettings({'parameter': parameter, 'value': value}).subscribe((res: Response) => { this.refreshMemberSettings(); });     
  }

  getMemberAllowance() {
    let entity = GlobalVariables['id'];    
    let data = [];
        
    this.data.getMembers({ items : 1, page : 0, order : 't1.entity', orderby : 'asc', search : null, field: 't1.entity', value : entity }).subscribe(
      (res: Response) => {               
        let user = (<any>res)?.rows?.[0] ?? null;

        if (user == undefined) {
          user = [];
        } else {
          data['admin_rights'] = user['admin_rights'] == 'true' ? true : false;
          data['user_group'] = user['user_group'] == null ? null : (user['user_group']).split(',');
          data['username'] = user['username'];
          data['apps'] = JSON.parse(user['apps']);
        }   
          
        this.memberAllowanceSource.next(data);        
      }
    );
    
  }

}
