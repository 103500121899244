<div class="inner_wrapper">
	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="left">
			<h1><fa-icon [icon]="['fad', 'books']"></fa-icon> | {{'Office Documents'|translate}} | {{'Filter'|translate}}</h1>
		</div>
		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
		</div>
	</div>

    <form [formGroup]="formData" novalidate>
        
        <div class="body">       

            <div class="left_data">
                <div class="line">				
                    <div class="field">
                        <input class="stickToRight" name="customer" formControlName="customer" [(ngModel)]="customer" (click)="selectCustomer()" readonly />
                        <input type="hidden" name="customer_id" formControlName="customer_id" [(ngModel)]="customer_id" (click)="selectCustomer()" readonly />
                        <button type="button" class="inlineButton stickToLeft">
                            <fa-icon [icon]="['fad', 'users']" (click)="selectCustomer()"></fa-icon>
                        </button>
                        <fa-icon class="remove_icon" [icon]="['fad', 'times']" (click)="removeCustomer()"></fa-icon>
                    </div>
                    <div class="label">
                        {{'Filter by customer'|translate}}                        
                    </div>
                </div>
                
                <div class="line">				
                    <div class="field">
                        <input type="hidden" class="from_to"  formControlName="start_date_raw" name="start_date_raw" [(ngModel)]="start_date_raw" readonly />
                        <input class="from_to" formControlName="start_date" name="start_date" (click)="assignDateTime(null, start_date, end_date, null, start_date_raw, end_date_raw)" [(ngModel)]="start_date" readonly />
                        -
                        <input type="hidden" class="from_to" formControlName="end_date_raw" name="end_date_raw" [(ngModel)]="end_date_raw" readonly />
                        <input class="from_to stickToRight" formControlName="end_date" name="end_date" (click)="assignDateTime(null, start_date, end_date, null, start_date_raw, end_date_raw)" [(ngModel)]="end_date" readonly />
                        <button type="button" class="inlineButton stickToLeft">
                            <fa-icon [icon]="['fad', 'clock']" class="clock" (click)="assignDateTime(null, start_date, end_date, null, start_date_raw, end_date_raw)"></fa-icon>
                        </button>
                        <fa-icon class="remove_icon" [icon]="['fad', 'times']" (click)="removeDateTime()"></fa-icon>
                    </div>
                    <div class="label">
                        {{'Start from - to date'|translate}}
                    </div>
                </div>

                <div class="line">				
                    <div class="field">
                        <input type="number" type="hidden" class="from_to" formControlName="price_from_raw" name="price_from_raw" />
                        <input type="number" class="from_to" formControlName="price_from" name="price_from" [(ngModel)]="price_from" />
                        -
                        <input type="number" type="hidden" class="from_to" formControlName="price_to_raw" name="price_to_raw" />
                        <input type="number" class="from_to stickToRight" formControlName="price_to" name="price_to" [(ngModel)]="price_to" />

                        <button type="button" class="inlineButton stickToLeft">
                            <fa-icon [icon]="['fad', 'coins']" class="clock"></fa-icon>
                        </button>
                        <fa-icon class="remove_icon" [icon]="['fad', 'times']" ></fa-icon>
                    </div>
                    <div class="label">
                        {{'Price from - to'|translate}}
                    </div>
                </div>	
            </div>

            <div class="right_data">
                <div class="label section_label">
                    {{'Hide Documents'|translate}}
                </div>

                <div class="line line-checkbox">				
                    <div class="field">
                        <label class="switch">
                            <input type="checkbox" id="completed" name="completed" formControlName="completed" [(ngModel)]="completed">
                            <span class="slider round"></span>
                        </label>
                        <div class="label label-checkbox">{{'Completed'|translate}}</div>
                    </div>			
                </div>

                <div class="label section_label">
                    {{'Show only activities'|translate}}
                </div>

                <div class="line line-checkbox">				
                    <div class="field">
                        <label class="switch">
                            <input type="checkbox" id="unpaid" name="unpaid" formControlName="unpaid" [(ngModel)]="unpaid">
                            <span class="slider round"></span>
                        </label>
                        <div class="label label-checkbox">{{'Unpaid'|translate}}</div>
                    </div>			
                </div>
            </div>
        </div>

        <div class="footer" mat-dialog-actions>			
			<button class="cancel" mat-button mat-dialog-close>{{'Close'|translate}}</button>
			<button class="confirm" (click)="confirm()">{{'Confirm'|translate}}</button>
		</div>

    </form>

</div>