import { Component, OnInit, Inject } from '@angular/core';

import { SelectCustomerComponent } from '../../customers/select-customer/select-customer.component';
import { ActivitySelectComponent } from '../../activities/activity-select/activity-select.component';
import { UserSelectComponent } from '../../users/user-select/user-select.component';
import { AssignDateTimeComponent } from '../../activities/activity/assign-date-time/assign-date-time.component';

import { DataService } from '../../services/data.service';
import { GlobalVariables } from '../../services/data.globals';
import { ActivatedRoute } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-timetracking-filter',
  templateUrl: './timetracking-filter.component.html',
  styleUrls: ['./timetracking-filter.component.css']
})
export class TimetrackingFilterComponent implements OnInit {

  public activity;
  public activity_id;
  public user;
  public user_id;
  public customer;
  public customer_id;

  public start_date;
  public start_date_raw;
  public end_date;
  public end_date_raw;
  public filter;

  formData: UntypedFormGroup; 

  constructor(
	private data: DataService,
	public dialog: MatDialog,
	public dialogRef: MatDialogRef<TimetrackingFilterComponent>,
	@Inject(MAT_DIALOG_DATA) public injected_data,
	public translate: TranslateService
  ) { 
  	this.formData = this.createFormGroup();
  	translate.addLangs(['en', 'si']);
  }

  	createFormGroup() {
		return new UntypedFormGroup({
			user: new UntypedFormControl(),
			user_id: new UntypedFormControl(),
			activity: new UntypedFormControl(),
			activity_id: new UntypedFormControl(),
			customer: new UntypedFormControl(),
			customer_id: new UntypedFormControl(),
			start_date: new UntypedFormControl(),
			start_date_raw: new UntypedFormControl(),
			end_date: new UntypedFormControl(),
			end_date_raw: new UntypedFormControl()
		});
	}

  	selectCustomer() {
		var selectCustomerDialog = this.dialog.open(SelectCustomerComponent, {});
		selectCustomerDialog.afterClosed().subscribe(result => {			
			if (!isNaN(result.entity)) {  
				this.customer_id = result.entity;
				this.customer = result.customer;
			}			
		});       
	}

	removeCustomer() {
		this.customer_id = null;
		this.customer = null;
	}

	selectActivity() {
		var ActivitySelectDialog = this.dialog.open(ActivitySelectComponent, {});
		ActivitySelectDialog.afterClosed().subscribe(result => {			
			if (!isNaN(result.entity)) {  
				this.activity_id = result.entity;
				this.activity = result.activity;
			}
		});       
	}

	removeActivity() {
		this.activity_id = null;
		this.activity = null;
	}

	selectUser() {
		var UserSelectDialog = this.dialog.open(UserSelectComponent, {});
		UserSelectDialog.afterClosed().subscribe(result => {			
			if (!isNaN(result.entity)) {  
				this.user_id = result.entity;
				this.user = result.user;
			}			
		});       
	}

	removeUser() {
		this.user_id = null;
		this.user = null;
	}

	localDateFormat(date) {
		var datetime = new Date(date);   
		var options = { hour12: false };   
		return datetime.toLocaleString("sl-SI", options).replace('. ', '.').replace('. ', '.');
	}

	assignDateTime(entity, start, end, refresh, start_raw, end_raw) {
		var assignDateTimeDialog = this.dialog.open(AssignDateTimeComponent, {
			'width' : '450px',		
			data: { activity: entity, start_date : start, end_date : end, start_date_raw : start_raw, end_date_raw : end_raw, time : false }
		});    

		assignDateTimeDialog.afterClosed().subscribe(result => {
			var entity = result.activity;
			var start_date = (result.start_date).split(' ');
			var end_date = (result.end_date).split(' ');
			var date = new Date(end_date[0]);
			this.start_date_raw = start_date[0];
			this.start_date = (this.localDateFormat(start_date[0]+"T00:00:00")).split(' ')[0];
			this.end_date_raw = end_date[0];
			this.end_date = (this.localDateFormat(end_date[0]+"T00:00:00")).split(' ')[0];
		});			
	}

	removeDateTime() {
		this.start_date_raw = null;
		this.start_date = null;
		this.end_date_raw = null;
		this.end_date = null;
	}


	confirm() {
		var data = {};

		if (this.start_date_raw != null) {
			data['start_date_raw'] = this.start_date_raw;
			data['end_date_raw'] = this.end_date_raw;
			data['start_date'] = this.start_date;
			data['end_date'] = this.end_date;
		}

		if (this.activity_id != null) {
			data['activity'] = this.activity;
			data['activity_id'] = this.activity_id;
		}

		if (this.user_id != null) {
			data['user'] = this.user;
			data['user_id'] = this.user_id;
		}

		if (this.customer_id != null) {
			data['customer'] = this.customer;
			data['customer_id'] = this.customer_id;
		}
 		
		this.dialogRef.close(data);   
	}

	ngOnInit(): void {	
	  var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);	
		this.user = this.injected_data['user'];
		this.user_id = this.injected_data['user_id'];
		this.customer = this.injected_data['customer'];
		this.customer_id = this.injected_data['customer_id'];
		this.activity = this.injected_data['activity'];
		this.activity_id = this.injected_data['activity_id'];
		this.start_date = this.injected_data['start_date'];
		this.start_date_raw = this.injected_data['start_date_raw'];
		this.end_date = this.injected_data['end_date'];
		this.end_date_raw = this.injected_data['end_date_raw'];
	}

}
