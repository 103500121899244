import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-grid-footer',  
  templateUrl: './grid-footer.component.html',
  styleUrl: './grid-footer.component.css'
})
export class GridFooterComponent {
  
  @Input() component: any;
  @Input() itemsLimit: any;
  @Input() pagination: any;
  @Input() page: any;
  @Input() pages: any;
  @Input() total_items: any;
  @Input() itemsLimit_display: any;

  @Input() updateParameters: Function;
  @Input() refreshData: Function;

  changePage(page) {	      
    localStorage.setItem(this.component + '_tablePage', (page).toString());    
		this.updateParameters();
		this.refreshData();		
	}

	changeLimit(limit) {
		this.itemsLimit = limit;    
    localStorage.setItem(this.component + '_tableLimit', (limit).toString());
    localStorage.setItem(this.component + '_tablePage', "1");    
		this.updateParameters(); 
		this.refreshData();
	}

}