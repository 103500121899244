<div class="inner_wrapper">
    <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="left">
        <h1>
            {{'Edit Form'|translate}}
        </h1>
        </div>

        <div class="right">            
            <fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
        </div>
    </div>

    <div class="inner_scroll">  

        <ng-container *ngIf="(formContent).length == 0">
            Add at least 1 item to proceed...
        </ng-container>

        <div cdkDropList class="items" (cdkDropListDropped)="drop($event)">
            <div class="item" *ngFor="let item of formContent; let i = index" cdkDrag>

                <div class="top">
                    <fa-icon (click)="removeItem(i)" [icon]="['fad', 'times']"></fa-icon>
                    <fa-icon [icon]="['fad', 'list-ol']"></fa-icon>


                    <select class="dropdown_function" name="function" [(ngModel)]="formContent[i].function">
                        <option value="text">{{'textfield'|translate}}</option>
                        <option value="textarea">{{'textarea'|translate}}</option>
                        <option value="checkbox">{{'checkbox'|translate}}</option>
                        <option value="options">{{'options'|translate}}</option>
                        <option value="dropdown">{{'dropdown'|translate}}</option>
                        <option value="fileupload">{{'fileupload'|translate}}</option>
                    </select>
                </div>

                <div class="item_title">
                    <input type="text" [(ngModel)]="formContent[i].title" placeholder="{{'Item form title...'|translate'}}" />
                </div>
                <div class="item_subtitle">
                    <input type="text" [(ngModel)]="formContent[i].subtitle" placeholder="{{'Item form subtitle...'|translate'}}" />
                </div>
                
                <div *ngIf="item.function=='checkbox'||item.function=='options'||item.function=='dropdown'" class="item_editfield">
                    <input type="text" [(ngModel)]="formContent[i].defaultValuesText" placeholder="{{'Split each options with '|translate'}}" />
                </div>
            </div>
        </div>
    </div>

    <div class="line actions">
        <button (click)="addItem()">{{'Add item'|translate}}</button>
        <button *ngIf="formValidated==false && formSaved==true" (click)="validate()">Validate/Lock</button>
        <button (click)="editSettings=!editSettings">{{'Settings'|translate}}</button>
        <button *ngIf="formSaved!=true" (click)="save()">{{'Save'|translate}}</button>
    </div>

</div>

<div *ngIf="editSettings" class="editSettings">
    <div class="inner_wrapper">

		<div class="header">
			<div class="left">
				<h1>
					{{'Settings'|translate}}
				</h1>
			</div>
	
			<div class="right">
				<fa-icon class="icon_medium" [icon]="['fad', 'times']" (click)="editSettings=!editSettings"></fa-icon>
			</div>
		</div>
		
        <div class="inner_scroll">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dapibus urna vitae orci porta malesuada. Cras at felis vitae tortor tristique pharetra. Ut ut dolor urna. Duis pulvinar orci vel diam luctus, ac mattis quam elementum. Donec euismod, massa sed sollicitudin viverra, ex urna rhoncus diam, eget tincidunt libero nisi non diam. Nulla posuere, risus convallis volutpat auctor, mi quam iaculis mi, non aliquet neque nisl eget lorem. Quisque pellentesque vel mi in consequat. Nam vestibulum semper efficitur.
            </p>
        </div>

        <div class="line actions">
            <button class="cancel" (click)="editSettings=!editSettings">{{'Close'|translate}}</button> 
        </div>
    </div>
</div>