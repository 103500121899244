import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { DataService } from '../../services/data.service';
import { GlobalVariables } from '../../services/data.globals';
import { ActivityComponent } from '../../activities/activity/activity.component'
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivitiesFilterComponent } from '../../activities/activities-filter/activities-filter.component';
import { TranslateService } from '@ngx-translate/core';


/* Services */
import { NavigationService } from '../../services/navigation.service'; //TM 27.3.2025
import { MembersettingsService } from '../../services/membersettings.service'; //TM 27.3.2025

@Component({
  selector: 'app-activities-canban',
  templateUrl: './activities-canban.component.html',
  styleUrls: ['./activities-canban.component.css']
})
export class ActivitiesCanbanComponent implements OnInit {

  activities = { 
      todo : [ 'Get to work', 'Pick up groceries', 'Go home', 'Fall asleep' ],
      done : [ 'Get up', 'Brush teeth', 'Take a shower', 'Check e-mail', 'Walk dog' ],
      krneki : [ 'Get up', 'Brush teeth', 'Take a shower', 'Check e-mail', 'Walk dog' ]
  }

  public component = 'activities-canban'; //TM 27.3.2025
  public category_id;
  public category_title = null;
  public category_hex = null;
  public items;
  public statuses;
  public statuses_order = [];
  public canban_data = [];
  public canban_data_object = [];
  public status_width = 0;
  public filter_values;   
  public parameters = { categories: null, items : 10000, page : 0, order : 't1.entity', orderby : 'asc', search : null };
  public activity_values = [];
  public lastFunctionCallAt = Date.now(); //TM 27.3.2025
  public memberSettingsGlobal = null; //TM 27.3.2025
  public memberAllowance = null; //TM 27.3.2025
  
  constructor(
    private data: DataService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    public NavigationService: NavigationService, //TM 27.3.2025
    public MembersettingsService: MembersettingsService, //TM 27.3.2025
    ) {
      this.route.paramMap.subscribe(params => { /*this.ngOnInit();*/ });
      translate.addLangs(['en', 'si']);
    }

  /*************************/

  public topNavigation = [		
    { entity: "activitiesView", exposed: false, component: this.component, title: "View", subTitle: null, subTitleColor: null, type: "link", action: "", actionData: null, icon: "", iconColor: null, pinnable: false,
			submenu: [
			  { entity: "activities.View.List", exposed: false, component: this.component, title: "List view", subTitle: null, subTitleColor: null, type: "localFunction", action: "activities", actionData: null, icon: "fad,table-rows", iconColor: null, pinnable: true, submenu: null	},
        { entity: "activities.View.Gantt", exposed: false, component: this.component, title: "Gantt view", subTitle: null, subTitleColor: null, type: "localFunction", action: "activities-gantt", actionData: null, icon: "fad,chart-gantt", iconColor: null, pinnable: true, submenu: null	},
        { entity: "activities.View.CanBan", exposed: false, component: this.component, title: "CanBan view", subTitle: null, subTitleColor: null, type: "localFunction", action: "switchToCanban", actionData: null, icon: "fad,square-kanban", iconColor: null, pinnable: true, submenu: null	}
			] 
		},
    { entity: "activitiesPreferences", exposed: false, component: this.component, title: "Preferences", subTitle: null, subTitleColor: null, type: "link", action: "", actionData: null, icon: "", iconColor: null, pinnable: false, 
      submenu: [
				{ entity: "activitiesPreferences.1", exposed: false, component: this.component, title: "Filter", subTitle: null, subTitleColor: null, type: "localFunction", action: "setFilter", actionData: null, icon: "fad,filter", iconColor: null, pinnable: true, submenu: null },
			]
		}		
	];

  /*************************/

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);      
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      var activity = JSON.parse(JSON.stringify(event.container.data[event.currentIndex]));
      var status = event.container.element.nativeElement.id;
      if (status == 'undefined_status') status = null;
      /* save change of status */
      this.data.updateStatus({id : activity.entity, status : status, status_title: null}).subscribe( (res: Response) => {} );  
      this.refreshData();
    }
  }

  edit(entity) {
    var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
    editDialog.componentInstance.entity = entity;
    editDialog.componentInstance['category_id'] = this.category_id;
    editDialog.afterClosed().subscribe(result => {
      this.refreshData();
    });   
  }

  canban_view() {    
    this.router.navigateByUrl('/activities-canban/'+this.category_id);
  }

  gantt_view() {    
    this.router.navigateByUrl('/activities-gantt/'+this.category_id);
  }

  table_view() {    
    this.router.navigateByUrl('/activities/'+this.category_id);
  }

  updateParameters() {
    var categories = [];    
    categories.push({ category_id: this.category_id });
    this.parameters = { categories: categories, items : 10000, page : 0, order : 't1.entity', orderby : 'asc', search : null };
  }

  updateFilters(filter_values = this.filter_values) {
      if (this.filter_values) {
      if (this.filter_values['assigned_id'] != undefined) this.parameters['filter_assigned_id'] = this.filter_values['assigned_id'];
      if (this.filter_values['customer_id'] != undefined) this.parameters['filter_customer'] = this.filter_values['customer_id'];      
      //if (this.filter_values['assigned_id'] != undefined) this.parameters['assigned_id'] = this.filter_values['assigned_id'];
      if (this.filter_values['start_from_date_raw'] != undefined) this.parameters['filter_start_from_date_raw'] = this.filter_values['start_from_date_raw'];
      if (this.filter_values['start_to_date_raw'] != undefined) this.parameters['filter_start_to_date_raw'] = this.filter_values['start_to_date_raw'];
      if (this.filter_values['end_from_date_raw'] != undefined) this.parameters['filter_end_from_date_raw'] = this.filter_values['end_from_date_raw'];
      if (this.filter_values['end_to_date_raw'] != undefined) this.parameters['filter_end_to_date_raw'] = this.filter_values['end_to_date_raw'];
      if (this.filter_values['created_from_date_raw'] != undefined) this.parameters['filter_created_from_date_raw'] = this.filter_values['created_from_date_raw'];
      if (this.filter_values['created_to_date_raw'] != undefined) this.parameters['filter_created_to_date_raw'] = this.filter_values['created_to_date_raw'];
      if (this.filter_values['hide_status'] != undefined) this.parameters['filter_hide_status'] = this.filter_values['hide_status'];
      if (this.filter_values['approved'] != undefined) this.parameters['approved'] = this.filter_values['approved'];
      if (this.filter_values['approvedbyme'] != undefined) this.parameters['approvedbyme'] = this.filter_values['approvedbyme'];
      if (this.filter_values['featured'] != undefined) this.parameters['featured'] = this.filter_values['featured'];
      if (this.filter_values['author'] != undefined) this.parameters['author'] = this.filter_values['author'];
      if (this.filter_values['hidden'] != undefined) this.parameters['hidden'] = this.filter_values['hidden'];
      //console.log(this.parameters);
    }
  }

  setFilter() {
    this.filter_values['category_id'] = this.category_id;
    
    var filterDialog = this.dialog.open(ActivitiesFilterComponent, {
      data : this.filter_values
    });
   
    filterDialog.afterClosed().subscribe(result => {        
      if (result != '' && result != null) {
        this.filter_values = result;    
        //alert('activities_'+this.category_id);
        localStorage.setItem('activities_'+this.category_id, JSON.stringify(this.filter_values));        
        this.updateParameters();
        this.updateFilters();      
        this.refreshData();        
      }
    });    
  }

  localDateFormat(date) {
    var datetime = new Date(date);   
    var options = { hour12: false };   
    return datetime.toLocaleString("sl-SI", options).replace('. ', '.').replace('. ', '.');
  }

  formatCurrency(value) {
    var formatter = new Intl.NumberFormat('sl-SI', {
      style: 'currency',
      currency: 'EUR',                   
    });   
    return formatter.format(value);
  }

  getSummary() {

  }

  refreshData() { 
    var categories = [];    
    categories.push({ category_id: this.category_id });  
    this.updateParameters();
    this.updateFilters();   

    this.data.getActivityType({id:this.category_id}).subscribe(
      (res: Response) => {
        this.statuses = (<any>res).rows?.[0]?.status_text ? (<any>res).rows[0].status_text.split(',') : [];

        /* prepare canban container */
        var helper;                     
        this.canban_data['undefined_status'] = { status_title: 'Undefined', status_hex: 'var(--dark-lvl2)', rows: [] };
        this.canban_data_object['undefined_status'] = [];
        this.activity_values['undefined_status'] = 0;
        this.statuses_order = [];
        this.statuses_order.push('undefined_status');
        for (var i=0; i<this.statuses.length; i++) {
          helper = this.statuses[i].replace('=', ';').split(';');  
          var exist = true;
          if (this.filter_values['hide_status'] !== undefined) {
            if (!this.filter_values['hide_status'].includes(helper[0])) exist = false;
          }          
          if (exist) {
            this.canban_data[helper[0]] = { status_title: helper[1], status_hex: helper[2], rows: [] };
            this.canban_data_object[helper[0]] = [];
            this.activity_values[helper[0]] = 0;
            this.statuses_order.push(helper[0]);
          }
        }      

        /* get activities */
        this.data.getActivities(this.parameters).subscribe(
          (res: Response) => {            
            this.status_width = 100 / (this.statuses_order.length);
            if ((<any>res).rows !== undefined) {
              this.items = (<any>res).rows;
              for (var i=0; i<this.items.length; i++) {               
                if (this.items[i].status_id == '' || this.items[i].status_id == null) this.items[i].status_id = 'undefined_status';              
                //if (this.statuses_order.includes(this.items[i].status_id)) {
                  this.items[i].created = this.localDateFormat(this.items[i].created);

                  var formatter = new Intl.NumberFormat('sl-SI', {
                    style: 'currency',
                    currency: 'EUR', 
                    /*minimumFractionDigits: 0,
                    maximumFractionDigits: 0,*/
                  });                  
                  
                  if (!isNaN(parseFloat(this.items[i].value)))
                    this.activity_values[this.items[i].status_id] = this.activity_values[this.items[i].status_id] + parseFloat(this.items[i].value);
                  this.canban_data[this.items[i].status_id].rows.push({ hours: this.items[i].hours, formated_value: formatter.format(this.items[i].value), value: this.items[i].value, entity: this.items[i].entity, customer_hex: this.items[i].customer_hex, customer: this.items[i].customer, activity_title: this.items[i].title, author: this.items[i].author, created: this.items[i].created, details: null });
                  this.canban_data_object[this.items[i].status_id].push({ hours: this.items[i].hours, formated_value: formatter.format(this.items[i].value), value: this.items[i].value, entity: this.items[i].entity, customer_hex: this.items[i].customer_hex, customer: this.items[i].customer, activity_title: this.items[i].title, author: this.items[i].author, created: this.items[i].created, details: null });
                //}
              }              
            }   
                        
          }          
        );        
      }
    );   
  }

  ngOnInit(): void {    
    /********************************************/ 
    /* START: Common procedures from table view */
    /********************************************/
    /* Navigation.Service */
    /* Handle local function called form navigation service */    
    this.NavigationService.localFunction.subscribe(callLocalFunction => {
      console.log(callLocalFunction);
      if (callLocalFunction[0] == this.component && callLocalFunction[1] == "localFunction" && this.lastFunctionCallAt < Date.now()) {
        //this.NavigationService.prepareObserverForLocalFunction(['blank', null, null, null]);
        this.lastFunctionCallAt = Date.now()+500; //prevent calling function too fast by default 500mS between call
        this[callLocalFunction[2]](callLocalFunction[3]);				
      }
    });
  
    /* Member.Service */
    /* Get and Wait for allowance */
    this.MembersettingsService.getMemberAllowance();
    this.MembersettingsService.memberAllowance.subscribe(message => this.memberAllowance = message);

    this.MembersettingsService.refreshMemberSettings();
    this.MembersettingsService.memberSettingsGlobal.subscribe(message => {
      this.memberSettingsGlobal = message;      
    });
  
    /* Wait for memberAllowance to be ready with timeout function  */    
    var maxExecutionTime = 5000;
    var executionInterval = 100;
    var navigationInterval = setInterval(() => {      
      maxExecutionTime = maxExecutionTime - executionInterval;      
      if (maxExecutionTime < 1) {
        clearInterval(navigationInterval);
        alert(this.component + '.component: Can not retrieve data (memberAllowance) from API. Please refresh application.');
        this.router.navigate(['/']);         
      }
      if (this.memberAllowance != null) {
        clearInterval(navigationInterval);
        /* Update top navigation */
        this.NavigationService.updateTopNavigation(this.topNavigation);
      }
    }, executionInterval);

    /*******************************************/
    /* AI */
    //const Helper = this.prepareAiInstruction();
    //this.AiService.setScope(Helper);

    /*******************************************/
    /* END: Common procedures fro table view   */
    /*******************************************/ 

    
    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);
    
    this.route.params.subscribe((params)=>{
      this.category_id = params.category_id;

      this.data.getCategories({ categories: null, items : 10000, page : 0, order : 't1.description', orderby : 'asc', search : null }).subscribe(
        (res: Response) => {
          var categories = (<any>res).rows;
          for(var i=0; i<categories.length; i++) {
            if (categories[i].entity == this.category_id) {
              this.category_title = categories[i].description;
              this.category_hex = categories[i].hex;
            }
          }
      });
      

      /* session settings */    
      this.filter_values = [];
      if (localStorage.getItem('activities_'+this.category_id) != '""' && localStorage.getItem('activities_'+this.category_id) != null) {           
        this.filter_values = JSON.parse(localStorage.getItem('activities_'+this.category_id));
      }

      this.refreshData();
    });       
  }

}
