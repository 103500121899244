/* Angular */
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* Translations */
import { TranslateService } from '@ngx-translate/core';

/* Services */
import { CommonFunctions } from '../../services/common.functions';
import { NavigationService } from '../../services/navigation.service';
import { MembersettingsService } from '../../services/membersettings.service';
import { DataService } from '../../services/data.service';
import { AiService } from '../../services/ai.service';
import { GlobalVariables } from '../../services/data.globals';

/* Components */
import { ModalComponent } from '../../modal/modal.component';
import { DockService } from '../../services/dock.service';
//import { TopComponent } from '../../top/top.component';
import { SelectCustomerComponent } from '../../customers/select-customer/select-customer.component';
import { ActivitySelectComponent } from '../../activities/activity-select/activity-select.component';
import { UserSelectComponent } from '../../users/user-select/user-select.component';
import { AssignDateTimeComponent } from '../../activities/activity/assign-date-time/assign-date-time.component';


@Component({
  selector: 'app-timetracking-edit',
  templateUrl: './timetracking-edit.component.html',
  styleUrls: ['./timetracking-edit.component.css']
})
export class TimetrackingEditComponent implements OnInit {

	private sub : any;

	public entity;	
	public ActivityId;
	public UserId;
	public CompanyId;
	public activity;
	public activity_id;
	public comment;
	public customer;
	public customer_id;
	public user;
	public user_id;
	public date;
	public date_raw;
	public start;
	public start_full;
	public start_full_raw;
	public end;
	public end_full;
	public end_full_raw;
	public rows;
	public extra;
	public subscription;
	public other;
	public pause = "00:00";
	public time_tracking = [];
	public time_tracking_stat = [];

	public onPause = false;
	public pauseTracking = new Date();
	public onPauseTimer = "";
	public intervalId;
	public manualPauseInput = false;
	public categories = [];
	public moreOptions = false;

	public month;
	public priorMonth;
	public year;

	public memberSettingsGlobal = null;
	public memberAllowance = null;	
	private emberAllowance = {};
	
	/* ai.instructions */
	private aiInstructions = ``;

	prepareAiInstruction() {
		return  ``;
	}

	formData: UntypedFormGroup; 

	/********************************/

	constructor(
		private data: DataService,
		public dialog: MatDialog,
		private route: ActivatedRoute,
		public assignDateTimeDialog: MatDialog,
		public translate: TranslateService,
		public cFunction: CommonFunctions,
		public dialogRef: MatDialogRef<TimetrackingEditComponent>,
		public modalDialog: MatDialog,
		public DockService: DockService,
		public NavigationService: NavigationService,
		public MembersettingsService: MembersettingsService,
		public DataService: DataService,
		public AiService: AiService,
		@Inject(MAT_DIALOG_DATA) public injectedData: any

	) { 
		this.formData = this.createFormGroup();
		translate.addLangs(['en', 'si']);
	}

	/********************************/

	createFormGroup() {
		return new UntypedFormGroup({
			entity: new UntypedFormControl(),
			user: new UntypedFormControl(),
			user_id: new UntypedFormControl(),
			activity: new UntypedFormControl(),
			activity_id: new UntypedFormControl(),
			comment: new UntypedFormControl(),
			customer: new UntypedFormControl(),
			customer_id: new UntypedFormControl(),
			date: new UntypedFormControl(),
			date_raw: new UntypedFormControl(),
			start: new UntypedFormControl(),
			end: new UntypedFormControl(),
			pause: new UntypedFormControl(),
			extra: new UntypedFormControl(),
			subscription: new UntypedFormControl(),
			other: new UntypedFormControl()
		});
	}

	/********************************/

	get(id, copy = false) {

		this.data.getTimeTracking({id:id}).subscribe(
			(res: Response) => { 				
				this.rows = (<any>res)['rows'][0];	

				if (this.rows['date'] != '0000-00-00') {
					this.date_raw = this.rows['date'];
					this.start_full_raw = this.rows['date'] + " " + this.rows['from_time'];
					this.end_full_raw = this.rows['date'] + " " + this.rows['to_time'];
					this.rows['date'] = this.localDateFormat(this.rows['date']);
					this.rows['date'] = ((this.rows['date'].split(' '))[0]).replace(',', '');	
				} else {
					this.rows['date'] = '';
				}
				
				this.entity = this.rows['entity'];
				this.activity = this.rows['activity_title'];
				this.activity_id = this.rows['message_id'];
				this.comment = this.rows['comment'];
				this.customer = this.rows['customer'];
				this.customer_id = this.rows['customer_id'];
				this.user = this.rows['user'];
				this.user_id = this.rows['user_id'];
				this.pause = this.rows['pause'] == '' ? "00:00" : this.rows['pause'];

				this.other = this.rows['other'] == "1" ? true : false;
				this.subscription = this.rows['subscription'] == "1" ? true : false;
				this.extra = this.rows['extra'] == "1" ? true : false;

				this.date = this.rows['date'];
				this.start = this.rows['from_time'] == '00:00:00' ? '-' : this.rows['from_time'];
				this.start_full = this.rows['date'] + " " + this.rows['from_time'];
				this.end = this.rows['to_time'] == '00:00:00' ? '-' : this.rows['to_time'];
				this.end_full = this.rows['date'] + " " + this.rows['to_time'];

				var helper = JSON.parse(this.rows['category']);

				if (helper != undefined && helper != null) {
					for (var i=0; i<this.categories.length; i++) {
						for (var j=0; j<helper.length; j++) {
							if (helper[j].entity == this.categories[i].entity && helper[j].checked != undefined) {									
								if (helper[j].checked == true) {
									this.categories[i]['checked'] = true;									
								}
							}
						}	
					}
				}

				this.getCustomerStatistics(this.customer_id);

				if (copy) {
					this.setStart(0);
					this.setUndefined();
					this.save();
				}
			}
		);		
	
	}

	delete() {
		var content = this.translate.instant("Do you want parmenently delete time tracking record?");
		var modalDialog = this.dialog.open(ModalComponent, {
		  data: { content: content, yes: this.translate.instant("Delete") , no: null, cancel: this.translate.instant("Cancel") }
		});
	
		modalDialog.afterClosed().subscribe(result => {		  
		  if (result['yes']!=undefined) {
			let parameters = { data : { entity: this.entity }};		
			this.DockService.removeFromDockByEntity(this.entity, 'timetracking');
			this.data.deleteTimeTracking(parameters).subscribe(
				(res: Response) => {					
					this.dialogRef.close();
				}
			);			
		  }
		});    
	}

	formatDate(input) {
		const cleanedInput = input.replace(/[^0-9.]/g, '');
		const [day, month, year] = cleanedInput.split('.');
		const formattedDay = day.padStart(2, '0');
		const formattedMonth = month.padStart(2, '0');
		return `${year}-${formattedMonth}-${formattedDay}`;
	}

	save(parameters = null) {
		if (parameters == null) {
			parameters = { data : { 
				entity: this.entity, 
				user:this.user, 
				user_id:this.user_id, 
				activity: this.activity, 
				activity_id: this.activity_id,
				customer: this.customer, 
				customer_id: this.customer_id,
				comment: this.comment,
				date_raw: this.formatDate(this.date),
				start: this.start,
				end: this.end,
				pause: this.pause
			}}
		}

		if (parameters == null) parameters = { data: Object.assign({}, this.formData.value) };		
		parameters['data']['category'] = JSON.stringify(this.categories);
		this.DockService.removeFromDockByEntity(parameters['data']['entity'], 'timetracking');
		this.data.saveTimeTracking(parameters).subscribe(
	      (res: Response) => {							
			if (parameters['data']['end'] == '-') {
				this.DockService.addToDock({ entity: parameters['data']['entity'], type: 'timetracking', icon: 'fad,stopwatch', title: parameters['data']['activity'], navigateType: '', navigateAction: '', mavigateDate: '', color: '#ff2222' });			
			} else {
				//this.DockService.removeFromDockByEntity(parameters['data']['entity'], 'timetracking');
			}
			this.dialogRef.close();
	      }
	    );
	}

	copyTimeTracking() {
		var parameters = { data: Object.assign({}, this.formData.value) };
		this.data.copyTimeTracking(parameters).subscribe(
	      (res: Response) => {	    
			  var new_entity = <any>res['callback']['entity'];
			  this.entity = new_entity;
			  this.get(new_entity, true);			  
	      }
	    );		
	}

	switch(rowIndex) {
		this.categories[rowIndex]['checked'] = !this.categories[rowIndex]['checked'];
	}

	localDateFormat(date) {
		var datetime = new Date(date);   
		var options = { hour12: false };   
		return datetime.toLocaleString("sl-SI", options).replace('. ', '.').replace('. ', '.');
	}

	getCustomerStatistics(customer) {
		this.data.getTimeTrackStat({ 'customer_id' : customer, 'month':new Date().getMonth() , 'year':new Date().getFullYear() }).subscribe(
			(res: Response) => {				
				this.time_tracking_stat = <any>res;	
				
				this.time_tracking_stat['rows_current_month'][0]['minutes'] = this.time_tracking_stat['rows_current_month'][0]['minutes'] != null ? Math.round((this.time_tracking_stat['rows_current_month'][0]['minutes'])/60*10)/10 : 0;
				this.time_tracking_stat['rows_current_year'][0]['minutes'] = this.time_tracking_stat['rows_current_year'][0]['minutes'] != null ? Math.round((this.time_tracking_stat['rows_current_year'][0]['minutes'])/60*10)/10 : 0;
				this.time_tracking_stat['rows_prev_month'][0]['minutes'] = this.time_tracking_stat['rows_prev_month'][0]['minutes'] != null ? Math.round((this.time_tracking_stat['rows_prev_month'][0]['minutes'])/60*10)/10 : 0;
				this.time_tracking_stat['rows_year_to_date_month'][0]['minutes'] = this.time_tracking_stat['rows_year_to_date_month'][0]['minutes'] != null ? Math.round((this.time_tracking_stat['rows_year_to_date_month'][0]['minutes'])/60*10)/10 : 0;
				
				this.time_tracking_stat['defaults']['hoursMonth'] = this.time_tracking_stat['defaults'][0]['hoursMonth'] != null ? Math.round((this.time_tracking_stat['defaults'][0]['hoursMonth'])*10)/10 : 'n/a';
				this.time_tracking_stat['defaults']['hoursYear'] = this.time_tracking_stat['defaults'][0]['hoursYear'] != null ? Math.round((this.time_tracking_stat['defaults'][0]['hoursYear'])*10)/10 : 'n/a';
				this.time_tracking_stat['defaults']['afterHourPrice'] = this.time_tracking_stat['defaults'][0]['afterHourPrice'] != null ? Math.round((this.time_tracking_stat['defaults'][0]['afterHourPrice'])*100)/100 : 'n/a';					

				this.time_tracking_stat['percentMonth'] = (this.time_tracking_stat['rows_current_month'][0]['minutes'] / this.time_tracking_stat['defaults']['hoursMonth'] * 100).toFixed(0);
				this.time_tracking_stat['percentMonthPrior'] = (this.time_tracking_stat['rows_prev_month'][0]['minutes'] / this.time_tracking_stat['defaults']['hoursMonth'] * 100).toFixed(0);
				this.time_tracking_stat['percentYear'] = (this.time_tracking_stat['rows_year_to_date_month'][0]['minutes'] / this.time_tracking_stat['defaults']['hoursYear'] * 100).toFixed(0);

				this.time_tracking_stat['percentMonth_Pie'] = this.time_tracking_stat['percentMonth'] > 100 ? 100 : this.time_tracking_stat['percentMonth'];
				this.time_tracking_stat['percentMonthPrior_Pie'] = this.time_tracking_stat['percentMonthPrior'] > 100 ? 100 : this.time_tracking_stat['percentMonthPrior'];
				this.time_tracking_stat['percentYear_Pie'] = this.time_tracking_stat['percentYear'] > 100 ? 100 : this.time_tracking_stat['percentYear'];

				this.time_tracking_stat['percentMonth_PieColor'] = this.time_tracking_stat['percentMonth'] > 50 ? '#f5a742' : 'lightgreen';				
				this.time_tracking_stat['percentMonthPrior_PieColor'] = this.time_tracking_stat['percentMonthPrior'] > 50 ? '#f5a742' : 'lightgreen';				
				this.time_tracking_stat['percentYear_PieColor'] = this.time_tracking_stat['percentYear'] > 50 ? '#f5a742' : 'lightgreen';

				this.time_tracking_stat['percentMonth_PieColor'] = this.time_tracking_stat['percentMonth'] > 50 ? '#990000' : this.time_tracking_stat['percentMonth_PieColor'];				
				this.time_tracking_stat['percentMonthPrior_PieColor'] = this.time_tracking_stat['percentMonthPrior'] > 50 ? '#990000' : this.time_tracking_stat['percentMonthPrior_PieColor'];				
				this.time_tracking_stat['percentYear_PieColor'] = this.time_tracking_stat['percentYear'] > 50 ? '#990000' : this.time_tracking_stat['percentYear_PieColor'];

			}
		);				
	}

	selectCustomer() {
		var selectCustomerDialog = this.dialog.open(SelectCustomerComponent, {});
		selectCustomerDialog.afterClosed().subscribe(result => {			
			if (!isNaN(result.entity)) {  
				this.customer_id = result.entity;
				this.customer = result.customer;
				this.getCustomerStatistics(this.customer_id); 
			}
		});
	}

	selectActivity() {
		var ActivitySelectDialog = this.dialog.open(ActivitySelectComponent, {});
		ActivitySelectDialog.afterClosed().subscribe(result => {			
			if (!isNaN(result.entity)) {  
				this.activity_id = result.entity;
				this.activity = result.activity;
				if (this.customer_id == '' || this.customer_id == null) {
					this.customer_id = result.customer_id;
					this.customer = result.customer;					
					this.getCustomerStatistics(this.customer_id);
				}				
			}
		});       
	}

	selectUser() {
		var UserSelectDialog = this.dialog.open(UserSelectComponent, {});
		UserSelectDialog.afterClosed().subscribe(result => {			
			if (!isNaN(result.entity)) {  
				this.user_id = result.entity;
				this.user = result.user;
			}
		});       
	}

	closeTimeTracking() {
		var today = new Date();
		this.end = today.getHours() + ":" + today.getMinutes();
	}

	assignDateTime(entity, start, end, refresh, start_raw, end_raw) {
		//alert(start + " | " + end + " | " + start_raw + " | " + end_raw);
		var assignDateTimeDialog = this.assignDateTimeDialog.open(AssignDateTimeComponent, {
			data: { activity: entity, start_date : start, end_date : end, start_date_raw : start_raw, end_date_raw : end_raw }
		});    

		assignDateTimeDialog.afterClosed().subscribe(result => {			
			if (result != '') {
				var entity = result.activity;
				var start_date = (result.start_date).split(' ');
				var end_date = (result.end_date).split(' ');
				var date = new Date(end_date[0]);
				this.date_raw = end_date[0];
				this.date = this.localDateFormat(end_date[0]+"T00:00:00");
				this.start = start_date[1];
				this.end = end_date[1];
			}
		});			
	}

	setUndefined() {
		this.end = '-';		
		this.formData.get('end').patchValue('-');	
	}

	setStart(offset) {
		var oldDateObj = new Date();
		var newDateObj = new Date();
		newDateObj.setTime(oldDateObj.getTime() + (offset * 60 * 1000));
		var options = { hour12: false };   
		var current = newDateObj.toLocaleString("sl-SI", options).replace('. ', '.').replace('. ', '.');		
		var current_array = current.split(' ');

		var today_raw = new Date(newDateObj);
		var dd = String(today_raw.getDate()).padStart(2, '0');
		var mm = String(today_raw.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today_raw.getFullYear();

		this.date = (current_array[0]).replace(',','');
		this.date_raw = yyyy + "-" + mm + "-" + dd;
		this.start = current_array[1];
		this.end = this.end == '' || this.end == undefined ? '-' : this.end;

		this.formData.get('start').patchValue(current_array[1]);	
		this.formData.get('date').patchValue(current_array[0]);	
	}

	setPause(offset) {
		var oldDateObj = new Date("2000-01-01T"+this.pause);
		var newDateObj = new Date();
		newDateObj.setTime(oldDateObj.getTime() + (offset * 60 * 1000));
		var options = { hour12: false };   
		var current = newDateObj.toLocaleString("sl-SI", options).replace('. ', '.').replace('. ', '.');		
		var current_array = current.split(' ');
		this.pause = current_array[1].slice(0, -3);
	}

	startPause() {
		this.onPause = true;
		this.pauseTracking = new Date();
	}

	endPause() {
		this.onPause = false;
		var offset = this.cFunction.timeToMinutes(this.onPauseTimer);
		this.setPause(offset);		
	}

	clearPause() {
		this.pause = "00:00";		
	}

	setEnd(offset) {
		var oldDateObj = new Date();
		var newDateObj = new Date();
		newDateObj.setTime(oldDateObj.getTime() + (offset * 60 * 1000));		
		var options = { hour12: false };   
		var current = newDateObj.toLocaleString("sl-SI", options).replace('. ', '.').replace('. ', '.');		
		var current_array = current.split(' ');

		var today_raw = new Date(newDateObj);
		var dd = String(today_raw.getDate()).padStart(2, '0');
		var mm = String(today_raw.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today_raw.getFullYear();

		this.end = current_array[1];

		if (this.start == '') {
			newDateObj.setTime(oldDateObj.getTime());
			var current = newDateObj.toLocaleString("sl-SI", options).replace('. ', '.').replace('. ', '.');		
			var current_array = current.split(' ');
			this.date = current_array[0];
			this.date_raw = yyyy + "-" + mm + "-" + dd;
			this.start = current_array[1];
		}		
	}

	approve() {
		this.extra = true;
	}
	
	approveCancel() {
		this.extra = false;
	}

	/*******************************/

	ngOnInit(): void {
		/********************************************/ 
		/* START: Common procedures from table view */
		/********************************************/
		
		/* Member.Service */
		/* Get and Wait for allowance */
		this.MembersettingsService.getMemberAllowance();
		this.MembersettingsService.memberAllowance.subscribe(message => this.memberAllowance = message);

		this.MembersettingsService.refreshMemberSettings();
		this.MembersettingsService.memberSettingsGlobal.subscribe(message => {
			this.memberSettingsGlobal = message;
			
			let lang = this.memberSettingsGlobal['lang'] == undefined ? 'en' : this.memberSettingsGlobal['lang'];
    		this.translate.use(lang);
		});
		

		/*******************************************/
		/* AI */
		const Helper = this.prepareAiInstruction();
		this.AiService.setScope(Helper);

		/*******************************************/
		/* END: Common procedures fro table view   */
		/*******************************************/  

		/*******************************************/
		/* Custom: Init */
		const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		const d = new Date();
		this.month = monthNames[d.getMonth()]
		this.year = d.getFullYear();
		this.priorMonth = monthNames[d.getMonth()-1 < 0 ? 11 : d.getMonth()-1];
		
		this.activity_id = this.ActivityId;
		this.pause = "00:00";
	
		
		if (this.ActivityId != null) {			
			this.user_id = this.UserId;
			this.customer_id = this.CompanyId;
			var parameters = { 'id' : this.ActivityId };
			this.data.getActivity({ 'id' : this.ActivityId }).subscribe(
				(res: Response) => {
					this.activity = (<any>res).rows[0].title; 
					this.customer = (<any>res).rows[0].customer;
					this.user = GlobalVariables.username;
					this.user_id = GlobalVariables.id;
					this.setStart(0);
				}
			);
		} else {
			this.user = GlobalVariables.username;
			this.user_id = GlobalVariables.id;			
			this.setStart(0);
		}
		
		this.data.getTimeTrackingCategories(null).subscribe(
			(resCategories: Response) => {				
				this.categories = (<any>resCategories)['rows'];
				if (this.entity!=undefined) {
					this.get(this.entity);
				} else {
					if (Object.keys(this.injectedData).length > 0) {		
						this.user = GlobalVariables.username;
						this.user_id = GlobalVariables.id;
						this.activity_id = this.injectedData['activity_id'] == undefined ? this.activity_id : this.injectedData['activity_id'];
						this.activity = this.injectedData['activity'] == undefined ? this.activity : this.injectedData['activity'];
						this.customer_id = this.injectedData['customer_id'] == undefined ? this.customer_id : this.injectedData['customer_id'];
						this.customer = this.injectedData['customer'] == undefined ? this.customer : this.injectedData['customer'];
						this.comment = this.injectedData['comment'] == undefined ? this.comment : this.injectedData['comment'];
						this.setStart(0);
					}
				}				
			}
		); 		
	
		
		const progressInterval = setInterval(() => {
			var oldDateObj = this.pauseTracking;
			var newDateObj = new Date();			
			this.onPauseTimer = this.cFunction.timeDifference(oldDateObj, newDateObj, null, 2);
		}, 1000);


	}

  

}
