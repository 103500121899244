import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctions } from '../services/common.functions';
import { ModalComponent } from '../modal/modal.component';
import { GlobalVariables } from '../services/data.globals';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.css']
})
export class RecoveryComponent implements OnInit {

  public items;
  public total_items;
  public page = 0;
  public pages = 1;
  public itemsLimit = 25;
  public order = 'entity';
  public orderby = 'asc';
  public search = '';
  public parameters = {};
  public pagination = [10, 25, 50, 100, 250];
  public apps;

  constructor(
    private data_svc: DataService,    
    private route: ActivatedRoute,
    public translate: TranslateService,
    public modalDialog: MatDialog,
    public cFunction: CommonFunctions
  ) {
    translate.addLangs(['en', 'si']); 
  }


  ngOnInit(): void {
    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);

    /* Get App Access/Restriction */
		this.apps = (GlobalVariables['apps'] != '' && GlobalVariables['apps'] != undefined) ? this.apps = JSON.parse(GlobalVariables['apps']) :	this.apps = undefined;

    this.route.params.subscribe((params)=>{      
      this.search = params.search;
      if (this.search == undefined) this.search = "";
      this.page = 0;      
    });

    this.updateParameters();
    this.getRecoveries(); 

    /* clear top custom navigation */
    $(".header_helper_left").html('');
  }

  updateParameters() {  
    var extended = false;
    //alert(this.apps['recovery']?.settings);
    if (GlobalVariables['admin_rights'] == true) {
      extended = true;
    } else if (this.apps['recovery'] != undefined) {
      if (this.apps['recovery']?.settings==true) extended = true;
    }
    
    this.parameters = { 'page' : this.page, 'items' : this.itemsLimit, 'order' : this.order, 'orderby' : this.orderby, search: this.search, extended:extended };
  }

  onSearch(event:any) { 
    this.page = 0;
    this.search = event.target.value;
    this.updateParameters();
    this.getRecoveries();
    //console.log(this.search);
  }

  changePage(page) {
    this.page = page * this.itemsLimit;
    this.updateParameters();
    this.getRecoveries();
    //this.renderer.addClass(event.target,"selected");
  }

  changeOrder(order) {
    this.order = order;
    this.orderby = this.orderby == 'asc' ? 'desc' : 'asc'; 
    this.updateParameters();
    this.getRecoveries();
  }

  changeLimit(limit) {
    this.itemsLimit = limit;
    this.updateParameters(); 
    this.getRecoveries();
  }

  
  recover(entity, comment, content) {

    var content = <any>("Do you want to recover " + comment + "(" + content + ")");
    var modalDialog = this.modalDialog.open(ModalComponent, {
      data: { content: content, yes: 'Recover' , no: null, cancel: 'Cancel' }
    });
    
    modalDialog.afterClosed().subscribe(result => {      
      if (result.yes != undefined) {
        this.data_svc.recoverRecovery({entity:entity}).subscribe(
          (res: Response) => {            
            this.updateParameters();
            this.getRecoveries(); 
          });
      }
    });

  }
  
  /************/
  /* get Data */

  getRecoveries() {
    this.data_svc.getRecoveries(this.parameters).subscribe(
      (res: Response) => {
        this.items = (<any>res).rows;        
        this.total_items = (<any>res).total_rows;
        this.pages = Math.ceil(this.total_items / this.itemsLimit);
        if (this.total_items < this.itemsLimit) this.itemsLimit = this.total_items;
        
        for(var i=0; i<this.items.length; i++) {
          if (this.items[i].comment == 'activity') {
            var helper = (this.items[i].sql_statement).split('VALUES(');
            helper = helper[1].split(',');
            this.items[i].content = helper[6].slice(1, -1)  + " / " + helper[7].slice(1, -1);
          }

          if (this.items[i].comment == 'customer') {
            var helper = (this.items[i].sql_statement).split('VALUES(');
            helper = helper[1].split(',');
            this.items[i].content = helper[3].slice(1, -1)  + " / " + helper[2].slice(1, -1);
          }

          if (this.items[i].comment == 'User') {
            var helper = (this.items[i].sql_statement).split('VALUES(');
            helper = helper[1].split(',');
            this.items[i].content = helper[2].slice(1, -1) + " / " + helper[3].slice(1, -1);
          }
          
          this.items[i].comment = this.inline_translation(this.items[i].comment);          
          this.items[i].deleted = this.cFunction.localDateFormat(this.items[i].deleted);
          
        }
      }
    );
  }

  inline_translation(inline_content) {
    return this.translate.instant(inline_content);    
  }

}

