/* Angular & Material */
import { Component, OnInit } from '@angular/core';
//import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* Services */
import { CommonFunctions } from '../../services/common.functions'; 
import { GlobalVariables } from '../../services/data.globals';
import { DataService } from '../../services/data.service';
import { NavigationService } from '../../services/navigation.service';
import { MembersettingsService } from '../../services/membersettings.service';

/* Translate */
import { TranslateService } from '@ngx-translate/core'; 

@Component({
  selector: 'app-quick-functions',
  templateUrl: './quick-functions.component.html',
  styleUrl: './quick-functions.component.css'
})
export class QuickFunctionsComponent implements OnInit {

  public categories;
  public open = true;
  public quickNavigationShortcuts = {};
  public memberSettingsGlobal = [];
  public topNavigation = [];	
	

  constructor(    		
    public CommonFunctions: CommonFunctions,
    private data: DataService,
    private NavigationService: NavigationService,
		private MembersettingsService: MembersettingsService
  ) {}

  navigate(type, action, component, data) {   
		this.NavigationService.navigate(type, action, component, data);
	}

  /*
  fixBottomQuickFunctions() {
    let height = document.getElementById('quickFunctions').clientHeight - 38;
    document.getElementById('quickFunctions').style.bottom = "-" + height + "px";
  }
  */

  /*******************************************************/

	icon(value) {
		if (value == null) return ['fad', 'circle-xmark'];
		let output = value.split(",");
		if (output.length != 2) {
			output = ['fad', 'circle-xmark'];
		}		
		return output;
	}

  /*******************************************************/

  ngOnInit(): void {
    this.data.getCategories({ categories: null, items : 10000, page : 0, order : 't1.description', orderby : 'asc', search : null }).subscribe(
      (res: Response) => {
        this.categories = (<any>res).rows;
        //console.log(this.categories);        
        /*setTimeout(() => { this.fixBottomQuickFunctions(); }, 250);*/        
    });

    this.NavigationService.topNavigation.subscribe(message => {
			this.topNavigation = message;      
		});

   	/* MemberSettings Service */		
		this.MembersettingsService.refreshMemberSettings();
		this.MembersettingsService.memberSettingsGlobal.subscribe(message => {
			this.memberSettingsGlobal = message;						
			if (this.memberSettingsGlobal['quickIcons'] != undefined) this.quickNavigationShortcuts = JSON.parse(this.memberSettingsGlobal['quickIcons']);			
      //setTimeout(() => { this.fixBottomQuickFunctions(); }, 1000);
		});

    /*
    const mouseTarget = document.querySelector(".wrapper");    
		mouseTarget.addEventListener("mouseleave", (e) => {			
		});
    */
  }

}
