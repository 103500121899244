import { Component, OnInit, Input, Inject, AfterViewInit, } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctions } from '../../../services/common.functions';
import { GlobalVariables } from '../../../services/data.globals';
import { DataService } from '../../../services/data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { SelectCustomerComponent } from '../../../customers/select-customer/select-customer.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserSelectComponent } from '../../../users/user-select/user-select.component';
import { ErpRichquotePrintComponent } from '../../erp-richquote/erp-richquote-print/erp-richquote-print.component';
import { ErpRichquoteItemComponent } from '../../erp-richquote/erp-richquote-item/erp-richquote-item.component';
import { ModalComponent } from '../../../modal/modal.component';

import { ActivitySelectComponent } from '../../../activities/activity-select/activity-select.component';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-erp-richquote',
  templateUrl: './erp-richquote.component.html',
  styleUrl: './erp-richquote.component.css'
})
export class ErpRichquoteComponent implements OnInit, AfterViewInit {
          
  
  public component = "erp-richquote";
  public summaryResults = {};
  public basic = { entity: null, category: "", comment_internal : "", comment_public : "", company_responsible : "", company_responsible_email : "", company_responsible_phone : "", customer : "", customer_id : "", date : "", general_dicount : 0, prepared_by : "", prepared_by_id : "", quote_id : "", responsible_by : "", responsible_by_id : "", valid : "", object: "", object_id: "", subtotal: 0, grandtotal: 0, entity_year: null, year: "", number: "", enable_override: false, purchase_order: "", override_template: "", price_list: null };
    
  public items = [];
  public static = { summary: "", timeSchedule: "", terms: "", pretext: "" };
  public staticCustom = [];
  public staticCustom_preDefined = [
    { pos: 0, type: "intro", title: "Introducing text", content: "Input Introducing text...", predefined: true },
    { pos: 1, type: "summary", title: "Quote summary", content: "Total value for whole Quote ({{category_list}}) is: {{category_summary}} In case of ordering all at once, we are offring you additional discount {{final_discount}} {{comment}}", predefined: true }
  ];


  public localInternalMenu = [
    { entity: "quoteedit.attachActivity", disabled: false, hide: false, exposed: false, component: "erp-richquote", title: "Attach Activity", type: "localFunction", action: "selectActivity", actionData: null, icon: "fad,link", iconColor: null, pinnable: true },
    { entity: "quoteedit.push", disabled: true, hide: false, exposed: false, component: "erp-richquote", title: "Push Document", type: "localFunction", action: "pushDocument", actionData: null, icon: "fad,right-left", iconColor: null, pinnable: true },
    { entity: "quoteedit.pin", disabled: true, hide: false, exposed: false, component: "erp-richquote", title: "Push Document", type: "localFunction", action: "pinDocument", actionData: null, icon: "fad,thumbtack", iconColor: null, pinnable: true },
    { entity: null, disabled: true, hide: false, exposed: false, component: "erp-richquote", title: null, type: "splitter", action: null, actionData: null, icon: null, iconColor: null, pinnable: false },
    { entity: "quoteedit.pdf", disabled: false, hide: false, exposed: false, component: "erp-richquote", title: "Export to PDF", type: "localFunction", action: "exportToPDF", actionData: null, icon: "fad,file-pdf", iconColor: null, pinnable: true },
    { entity: null, disabled: true, hide: false, exposed: false, component: "erp-richquote", title: null, type: "splitter", action: null, actionData: null, icon: null, iconColor: null, pinnable: false },
    { entity: "quoteedit.delete", disabled: false, hide: false, exposed: false, component: "erp-richquote", title: "Delete Document", type: "localFunction", action: "delete", actionData: null, icon: "fad,trash", iconColor: null, pinnable: true },
    { entity: "quoteedit.settings", disabled: false, hide: false, exposed: false, component: "erp-richquote", title: "Settings", type: "localFunction", action: "settings", actionData: null, icon: "fad,cog", iconColor: null, pinnable: false },
    { entity: null, disabled: true, hide: false, exposed: false, component: "erp-richquote", title: null, type: "splitter", action: null, actionData: null, icon: null, iconColor: null, pinnable: false },
    { 
      entity: "quoteedit.updateitems",
      disabled: false,
      hide: false,
      exposed: false,
      component: "erp-richquote",
      title: "Update Item List",
      type: "localFunction",
      action: "updateItemList",
      actionData: null,
      icon: "fad,arrows-rotate",
      iconColor: null,
      pinnable: true
    }
  ]


  public quote_discount = 0;  
  public quote_subTotal = 0;
  public general_dicount = "";
  public quote_grandTotal = 0;
  public erpSettings = {};
  public search = "";

  public dropdown = false;
  public linkWindow = false; 
  public hideEmpty = false;
  public showItemsSummary = false;
  public enableEdit = false;
  public popupWizzard = false;
  public shortcuts = {};
  public iDisplay = 0;
	
  public categories = [];
  public types = [];

  public selectedTab = 'basic';

  public saved = true;
  public textTexmplates = [];
  public templateChooser = false;
  public selectedIndex = 0;
  public summary = [];

  public activity_id = null;
  public activity = null;
  public fullActivity = null;

  constructor(
		private data: DataService,
		public cFunction: CommonFunctions, 
		public dialog: MatDialog,    
    public translate: TranslateService,
    public NavigationService: NavigationService,
    @Inject(MAT_DIALOG_DATA) public entity,
    public dialogRef: MatDialogRef<ErpRichquoteComponent>
	) { }

  createFormGroup() {
		return new UntypedFormGroup({
			customer: new UntypedFormControl(),
			customer_id: new UntypedFormControl(),
		});
	}

  /*
  icon(value) {
		if (value == null) return ['fad', 'circle-xmark'];
		let output = value.split(",");
		if (output.length != 2) {
			output = ['fad', 'circle-xmark'];
		}		
		return output;
	}
  */

  /***************************/
  public editor = 'extended';

  /***************************/
  public modules_full = {
    toolbar: [['bold', 'italic', 'underline', 'strike'],['blockquote', 'code-block'],[{ 'header': 1 }, { 'header': 2 }],[{ 'list': 'ordered'}, { 'list': 'bullet' }],[{ 'script': 'sub'}, { 'script': 'super' }],[{ 'indent': '-1'}, { 'indent': '+1' }],[{ 'direction': 'rtl' }],[{ 'size': ['small', false, 'large', 'huge'] }],[{ 'header': [1, 2, 3, 4, 5, 6, false] }],[{ 'color': [] }, { 'background': [] }],[{ 'font': [] }],[{ 'align': [] }],['clean'],['link', 'image', 'video']]
  };

  public modules_extended = {
    toolbar: [['bold', 'italic', 'underline', 'strike'],[{ 'header': 1 }, { 'header': 2 }],[{ 'list': 'ordered'}, { 'list': 'bullet' }],[{ 'script': 'sub'}, { 'script': 'super' }],[{ 'indent': '-1'}, { 'indent': '+1' }],[{ 'direction': 'rtl' }],[{ 'color': [] }, { 'background': [] }],[{ 'font': [] }],[{ 'align': [] }],['clean'],['link']]
  };

  public modules_light = {
    toolbar: [['bold', 'italic', 'underline', 'strike'],[{ 'color': [] }, { 'background': [] }],[{ 'font': [] }]]
  };

  /****************************************/
  /* Updating fresh item list */
  updateItemList() {
    let content = this.translate.instant("<b>Updating item list will override all item text and prices.</b><br /><br />New items will be added, removed items will not be removed, but marked with *.<br /><br />Are you sure to execute function?");

    let modalDialog = this.dialog.open(ModalComponent, {
      data: { content: content, yes: this.translate.instant('Update'), no: null, cancel: this.translate.instant('Cancel') }
    });  

    modalDialog.afterClosed().subscribe(result => {      
      if (result['yes']!=undefined) {      
        let parameters = { 'page' : 0, 'items' : 10000, 'order' : 'poz', 'orderby' : 'asc', search: null };          
        this.data.getQuoteItems(parameters).subscribe(
          (res: Response) => {
            let oldItems = {};
            for (let i=0; i<this.items.length; i++) {
              oldItems[this.items[i]['poz']] = this.items[i];
            }
            
            this.items = (<any>res).rows;	
            for (let i=0; i<this.items.length; i++) {              
              this.items[i]['regular_price_formated'] = this.cFunction.formatNumber(parseFloat(this.items[i]['regular_price']));
              
              console.log(this.items[i]['poz'], oldItems[this.items[i]['poz']], "*");
              if (oldItems[this.items[i]['poz']]==undefined) {
                this.items[i]['qty'] = 0;
                this.items[i]['discount'] = 0;
              } else {                
                this.items[i]['qty'] = oldItems[this.items[i]['poz']]['qty'];                
                this.items[i]['discount'] = oldItems[this.items[i]['poz']]['discount'];
              }              
              
              this.items[i]['showDescription'] = false;
            }
            
            //this.updateLines();
            //console.log(oldItems);
            //console.log(this.items);
          }
        )
      }
    });
  }

  /****************************************/
  /* function: execute local function     */
  get executeLocalFunctionFunc() { return this.executeLocalFunction.bind(this); }
  executeLocalFunction(localFunction, data) {    
    this[localFunction](data);
  }  

  /****************************************/
  /* function: save burgebar pin function */
  switchTab(value) {
    this.selectedTab = value;
    //
    if (value == 'summary') {
      this.summaryResults = {};

      this.items.forEach(item => {
        let totalPrice = item.regular_price * item.qty;
        let discountValue = (totalPrice * item.discount) / 100;
        let totalValue = totalPrice - discountValue;
      
        if (totalValue > 0) {        
          if (!this.summaryResults[item.category]) { this.summaryResults[item.category] = {}; }
          if (!this.summaryResults[item.category][item.type]) { this.summaryResults[item.category][item.type] = 0; }        
          this.summaryResults[item.category][item.type] += totalValue;
        }
      });
      
      this.summaryResults;
    }

  }

  getCategories(obj: any): string[] {
    return Object.keys(obj);
  }
  
  getTypes(categoryObj: any): string[] {
    return Object.keys(categoryObj);
  }  

  /****************************************/
  /* function: save burgebar pin function */  
  pinShortcut() {
    this.data.setMemberSettings({'parameter': 'erpQuoteShortcuts', 'value': JSON.stringify(this.shortcuts)}).subscribe((res: Response) => {}); 
  } 
 
  /****************************************/
  /* selective popups */
  selectCustomer() {
		var selectCustomerDialog = this.dialog.open(SelectCustomerComponent, {});
		selectCustomerDialog.afterClosed().subscribe(result => {			
			if (!isNaN(result.entity)) {  
				this.basic['customer_id'] = result.entity;
				this.basic['customer'] = result.customer;
        this.saved = false;
			}
		});
	}

  preparedBy() {
		var UserSelectDialog = this.dialog.open(UserSelectComponent, {});
		UserSelectDialog.afterClosed().subscribe(result => {			
			if (!isNaN(result.entity)) {  
        this.basic['prepared_by_id'] = result.entity;
				this.basic['prepared_by'] = result.user;
        this.saved = false;
			}
		});       
	}

  responsibleBy() {
		var UserSelectDialog = this.dialog.open(UserSelectComponent, {});
		UserSelectDialog.afterClosed().subscribe(result => {			
			if (!isNaN(result.entity)) {  
        this.basic['responsible_by_id'] = result.entity;
				this.basic['responsible_by'] = result.user;
        this.saved = false;
			}
		});       
	}

  erpSettingsChange(entity, value) {
    value = !value;
    this.erpSettings[entity] = value;
    //console.log(typeof value, entity, value);
    this.data.setSettings({entity : 'erpSettings', value : JSON.stringify(this.erpSettings)}).subscribe((res: Response) => {});
  }
 
  /****************************************/
  addCustomStaticText() {
    this.staticCustom.push({ pos: 100, type: "custom", title: "Custom text", content: "Input Custom text...", predefined: false, 'open':true });
    this.saved = false;
  }

  setPredefinedOptions() {
    this.staticCustom = this.staticCustom_preDefined;
  }

  setDefaultOptions() {
    let entity = "erpDefaultTextBlocks";
    this.data.getSettings({entity : entity}).subscribe((resSettings: Response) => {
      if (resSettings['rows'][0]!=undefined) this.staticCustom = JSON.parse(resSettings['rows'][0]['value']);
    });    
  }

  removeTextBlock(index) {
    this.staticCustom.splice(index, 1);
    this.saved = false;
  }

  chooseTemplate(index) {
    console.log(this.staticCustom);
    this.templateChooser = true;    
    this.data.getQuoteTextTemplates(null).subscribe(
      (res: Response) => {        
          this.textTexmplates = res['rows'];  
          this.selectedIndex = index;          
          //console.log(this.textTexmplates);
      }
    );    
  }

  deleteTextTemplate(entity, index) {
    let content = this.translate.instant('Do you want to delete quote template text parmanently?');

    let modalDialog = this.dialog.open(ModalComponent, {
      data: { content: content, yes: this.translate.instant('Delete'), no: null, cancel: this.translate.instant('Cancel') }
    });  

    modalDialog.afterClosed().subscribe(result => {      
      if (result['yes']!=undefined) {    
        this.textTexmplates.splice(index, 1);
        this.data.deleteTextTemplate( { entity : entity } ).subscribe( (res: Response) => { } );        
      }
    });
  }

  chooseTextTemplate(index) {
    this.staticCustom[this.selectedIndex]['title'] = this.textTexmplates[index]['title'];
    this.staticCustom[this.selectedIndex]['content'] = this.textTexmplates[index]['text'];
    this.templateChooser = false;
    this.saved = false;
  }

  saveTemplate(index) {    
    this.data.saveQuoteTextTemplate({entity:null, title:this.staticCustom[index]['title'], text:this.staticCustom[index]['content']}).subscribe( (res: Response) => { } );
    let content = this.translate.instant("Template text saved.");

    let modalDialog = this.dialog.open(ModalComponent, {
      data: { content: content, yes: null, no: null, cancel: this.translate.instant('OK') }
    });
  }

  setBlocksAsDefault() {    
    let entity = "erpDefaultTextBlocks";    
    let value = JSON.stringify(this.staticCustom);
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});
  }

  /****************************************/
  showDatePicker() {    
    document.getElementById("calendar").style.visibility="visible";
  }

  stringToNumber(value) {    
    //console.log(value, isNaN(value));
    if (typeof value == 'string') {
      //return parseFloat(value.replace(".", "").replace(",", "."));
      return parseFloat(value);
    } else {
      return value;
    }
  }

  /**************************/
  close() {
    if (!this.saved) {
      let content = this.translate.instant("Do you want to discart all changes?");

      let modalDialog = this.dialog.open(ModalComponent, {
        data: { content: content, yes: this.translate.instant('Discart'), no: null, cancel: this.translate.instant('Cancel') }
      });  

      modalDialog.afterClosed().subscribe(result => {      
        if (result['yes']!=undefined) {          
          this.dialogRef.close(null); 
        }
      });
    } else {
      this.dialogRef.close(null); 
    }
  }

  saveAndClose() {
    this.save();
    this.dialogRef.close(null);
  }

  save() {
    for (const [key, value] of Object.entries(this.items)) {
      this.items[key]['discount'] = this.stringToNumber(this.items[key]['discount']);
      this.items[key]['qty'] = this.stringToNumber(this.items[key]['qty']);
    }

    this.basic['general_dicount'] = this.cFunction.localizeStringToNumber(this.general_dicount);

    console.log(this.basic);
    let parameters = { basic : JSON.stringify(this.basic), items : JSON.stringify(this.items), static : JSON.stringify(this.staticCustom) }
    this.data.saveQuote(parameters).subscribe( (res: Response) => { } );
    this.saved = true;
  }

  delete() {
    let content = this.translate.instant('Are you sure that you want to parmenent delete quote ' + this.items['quote_id'] + " | " + this.basic['entity'] + "?");

    let modalDialog = this.dialog.open(ModalComponent, {
      data: { content: content, yes: this.translate.instant('Delete'), no: null, cancel: this.translate.instant('Cancel') }
    });  

    modalDialog.afterClosed().subscribe(result => {      
      if (result['yes']!=undefined) {        
        this.data.deleteQuote( { entity : this.basic['entity'] } ).subscribe( (res: Response) => { } );        
        this.dialogRef.close(null); 
      }
    });
  }

  /*****************************************************/
  /*****************************************************/
  /*****************************************************/

  exportToPDF() {
    var erpEntities = [];
    erpEntities[0] = this.entity;
    var PrintDialog = this.dialog.open(ErpRichquotePrintComponent, {		
			'width' : '450px',      
      data: erpEntities
		});   
    PrintDialog.afterClosed().subscribe(result => {});
  }

  settings() {  
    console.log('juhu');  
    this.switchTab('settings');
  }

  addQty(key) {    
    this.items[key].qty = this.items[key].qty + 1;
    this.saved = false;
  }

  subQty(key) {
    this.items[key].qty = this.items[key].qty - 1;
    this.saved = false;
  }

  toggleAllDescriptions() {
    let toggleDirection = !this.items[0]['showDescription'];
    for (var i=0; i<this.items.length; i++) {    
      this.items[i]['showDescription'] = toggleDirection;
    }    
  }

  toggleDescription(key) {
    this.items[key]['showDescription'] = !this.items[key]['showDescription'];
  }

  countDisplay(value) {
    this.iDisplay = value;
    return true;
  }

  editField() {
    this.saved = false;
  }

  newItem() {
    this.editItem(null, null, null, null, null, null, null);
    this.saved = false;
  }

  editItem(entity, category, type, short_description, poz, regular_price, title) {
    let data = { 
      entity:entity, 
      category:category, 
      type:type, 
      short_description:short_description, 
      poz:poz, 
      regular_price:this.cFunction.localizeStringToNumber(regular_price),
      regular_price_formated:regular_price,
      title:title
    };

    var itemEdit = this.dialog.open(ErpRichquoteItemComponent, { data:data, panelClass: 'ErpRichquoteItemComponent',  width: '900px', maxWidth: '92vw'});
    
		itemEdit.afterClosed().subscribe(result => {			
			//console.log(result);
      if (result != null) {
        let maxEntity = 0;
        for (var i=0; i<this.items.length; i++) {
          //console.log(this.items[i]);          
          if (this.items[i]['entity'] > maxEntity) maxEntity = this.items[i]['entity'] + 1;
          //console.log(this.items[i]);
          if (this.items[i]['entity'] == result['entity']) {
            this.items[i]['category'] = result['category'];
            this.items[i]['type'] = result['type'];
            this.items[i]['short_description'] = result['short_description'];
            this.items[i]['poz'] = result['poz'];
            this.items[i]['regular_price'] = this.cFunction.localizeStringToNumber(result['regular_price_formated']);
            this.items[i]['regular_price_formated'] = this.cFunction.formatNumberString(this.items[i]['regular_price'],2);            
            this.items[i]['title'] = result['title'];
            /*
            if (result['poz']=="40") {
              console.log(this.items[i]);
              console.log(this.items[i]['regular_price'], this.cFunction.localizeStringToNumber(this.items[i]['regular_price_formated']))
            }
            */
          }          
        }
        if (result['entity']==null) {                     
          let newItem = {
            category: result['category'],
            discount: 0,
            enabled: 1,
            entity: maxEntity,
            long_description: "",
            low_price: this.cFunction.formatNumberString(result['regular_price_formated'],2),
            poz: result['poz'],
            qty: 1,
            regular_price: result['regular_price'],
            regular_price_formated: this.cFunction.formatNumberString(result['regular_price_formated'],2),
            short_description: result['short_description'],
            showDescription: false,
            special_price: this.cFunction.formatNumberString(result['regular_price_formated'],2),
            title: result['title'],
            type: result['type']
          };          
          this.items.push(newItem);
        }
      }
      this.saved = false;
		});  
    
  }

  /****************************************/
  /* function: reorder items              */
  reorderItemsByPoz() {    
    //this.items.sort((a, b) => a.entity - b.entity);
    this.items.sort((a, b) => a.poz.localeCompare(b.poz));
    this.saved = false;
  }

  reorderItemsByCategory() {    
    //this.items.sort((a, b) => a.entity - b.entity);
    this.items.sort((a, b) => a.category.localeCompare(b.category));
    this.saved = false;
  }

  /****************************************/
  toggleStatic(index) {
    if (this.staticCustom[index]['open'] == undefined) {
      this.staticCustom[index]['open'] = true
    } else {
      this.staticCustom[index]['open'] = !this.staticCustom[index]['open'];
    }
  }

  closeStatic() {
    for (let i=0; i<this.staticCustom.length; i++) {
      this.staticCustom[i]['open'] = false;
    }    
  }

  /****************************************/
  toggleStatus(status) {
    this.basic['status'] = status;
    this.saved = false;
  }

  /**********************************************/
  /**********************************************/
  selectActivity() {
    var ActivitySelectDialog = this.dialog.open(ActivitySelectComponent, { 'width' : '650px', 'maxWidth' : '95vw' });
    ActivitySelectDialog.afterClosed().subscribe(result => {      
      if (!isNaN(result.entity)) {  
        this.basic['activity'] = result.entity;
        if (this.basic['activity']!=null) {
          this.data.getActivity( {id:this.basic['activity']} ).subscribe(
            (res: Response) => {          
              this.fullActivity = (<any>res).rows[0];
              this.saved = false;
              this.activity = this.fullActivity['entity'];
            }
          );                
        }        
      }
    });            
  }

  /****************************************/
  ngOnInit(): void {

    //alert(this.entity);

    this.basic['general_dicount'] = 0;
    this.basic['customer'] = "Input company...";
    this.basic['customer_id'] = "";

    this.basic['prepared_by'] = "Input user...";
    this.basic['prepared_by_id'] = "";

    this.basic['responsible_by'] = "Input user...";
    this.basic['responsible_by_id'] = "";

    this.basic['date'] = "";
    this.basic['valid'] = "";

    this.basic['status'] = "opened";
    this.basic['activity'] = null;
   
    this.data.getMemberSettings(null).subscribe(
      (res: Response) => {        
          var memberSettingsArray = [];        
          for (var i=0; i<res['rows'].length; i++) {          
              memberSettingsArray[res['rows'][i].parameter] = res['rows'][i].value;
          }        
          GlobalVariables.memberSettings = memberSettingsArray;     
          if (GlobalVariables.memberSettings['erpQuoteShortcuts'] != undefined) {
            this.shortcuts = JSON.parse(GlobalVariables.memberSettings['erpQuoteShortcuts']);     
          }
          
          var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
          this.translate.use(lang);          
      }
    );  
 
    let entity = "erpSettings";              
    this.data.getSettings({entity : entity}).subscribe((resSettings: Response) => {                 
      if (resSettings['rows'][0]!=undefined) this.erpSettings = JSON.parse(resSettings['rows'][0]['value']); 
      //console.log(resSettings['rows'][0]['value']);
    });

    /* Initial data  */
    this.data.getQuoteItemCategories({}).subscribe(
      (res: Response) => {          
        let items = (<any>res).rows;
        this.categories = [];
        this.types = [];
        for (let i=0; i<items.length; i++) {
          if (!this.categories.includes(items[i]['category'])) {
            this.categories.push(items[i]['category']);
            this.types[items[i]['category']] = [];
          }
        }

        for (let i=0; i<items.length; i++) {            
           this.types[items[i]['category']].push(items[i]['type']);
        }        

        if (this.entity == null) {
          let parameters = { 'page' : 0, 'items' : 10000, 'order' : 'poz', 'orderby' : 'asc', search: null };
          this.setPredefinedOptions();

          this.data.getLastQuoteNr(parameters).subscribe(
            (res: Response) => {
              console.log(res);
              const currentYear: number = new Date().getFullYear();
              if (res['rows'][0] == undefined) {                
                res['rows'][0] = [];
                res['rows'][0]['quote_id'] = currentYear + "/0001";
                res['rows'][0]['entity_incremental'] = 0;
                res['rows'][0]['year'] = currentYear;
              }
              this.basic['quote_id'] = res['rows'][0]['quote_id'];
              this.basic['entity_incremental'] = res['rows'][0]['entity_incremental'];
              this.basic['year'] = res['rows'][0]['year'];

              if (parseInt(this.basic['year']) == new Date().getFullYear() ) {
                this.basic['entity_incremental'] = this.basic['entity_incremental'] + 1;
                this.basic['year'] = String(new Date().getFullYear());
              } else {
                this.basic['entity_incremental'] = 1;                
                this.basic['year'] = String(new Date().getFullYear());
              }

              this.basic['quote_id'] = this.basic['year'] + "/" + ("0000" + String(this.basic['entity_incremental'])).slice(-3);
              console.log(this.basic);
            }
          );

          this.data.getQuoteItems(parameters).subscribe(
            (res: Response) => {
              this.items = (<any>res).rows;	
              for (let i=0; i<this.items.length; i++) {              
                this.items[i]['regular_price_formated'] = this.cFunction.formatNumber(parseFloat(this.items[i]['regular_price']));
                this.items[i]['qty'] = 0;
                this.items[i]['discount'] = 0;
                this.items[i]['showDescription'] = false;
              }
              //this.reorderItemsByPoz();
              
              /* check for predefined settings */
              let entity = "erpDefaultTextBlocks";              
              this.data.getSettings({entity : entity}).subscribe((resSettings: Response) => { 
                //console.log(resSettings);
                if (resSettings['rows'][0]!=undefined) this.staticCustom = JSON.parse(resSettings['rows'][0]['value']); 
                console.log(this.staticCustom);
              });             
            }
          );
        } else {
          let parameters = { entity: this.entity };

          this.data.getQuotes(parameters).subscribe(
            (res: Response) => {              
              this.items = JSON.parse((<any>res).rows[0]['items']);	              
              //this.reorderItemsByPoz();
              this.basic = (<any>res).rows[0];	
              console.log(this.basic);
              this.staticCustom = JSON.parse((<any>res).rows[0]['static_texts']);	
              console.log(this.staticCustom);
              let generalDiscountHelper = typeof this.basic['general_dicount'] == 'string' ? parseFloat(this.basic['general_dicount']) : this.basic['general_dicount'];
              this.basic['general_dicount'] = generalDiscountHelper;              
              this.general_dicount = (this.basic['general_dicount']).toLocaleString('sl-SI', {minimumFractionDigits: 2, maximumFractionDigits: 2});              
              
              this.ngAfterViewInit();
              this.switchTab('summary');   

              if (this.basic['activity']!=null) {
                this.data.getActivity( {id:this.basic['activity']} ).subscribe(
                  (res: Response) => {          
                    this.fullActivity = (<any>res).rows[0];
                    console.log(this.fullActivity);
                    this.activity = this.fullActivity['entity'];
                  }
                );                
              }        
            }
          );
        }        
        
      }
    );      

    /* Angular stuff */
    let angularVariables = this;

    function stringToNumber(value) {    
      if (value == undefined) {
        return 0;
      } else {
        if (typeof value == 'string') {          
          return parseFloat(value.replace(".", "").replace(",", "."));
          //return parseFloat(value);
        } else {
          return value;
        }
      }
    }

    function updateLines() {
      /* mark line with qty <> 0, calculate totals */
      $('[name=qty]').each(function() {
        let value = $(this).val() as string;        
        if (parseFloat(value)!=0) {
          $(this).parent().parent().addClass('selected');
        } else {
          $(this).parent().parent().removeClass('selected');
        }
      });

      let sub_total = 0;
      let general_dicount = stringToNumber($('[name=general_dicount]').val());      
      
      /* calculate */
      $('#elements .elements_line').each(function() {        
        let qty = stringToNumber($(this).find('[name=qty]').val() as string);
        let price = stringToNumber($(this).find('[name=price]').val() as string);
        let discount = stringToNumber($(this).find('[name=discount]').val() as string);
        let total = (qty * price) - (qty * price / 100 * discount);
        if (isNaN(total)) total = 0;
        sub_total += total;
        $(this).find('[name=total]').val(total.toLocaleString('sl-SI', {minimumFractionDigits: 2, maximumFractionDigits: 2}));        
      }); 
      
      let grand_total = sub_total - (sub_total / 100 * general_dicount);               
      $('[name=quote_subTotal]').val(sub_total.toLocaleString('sl-SI', {minimumFractionDigits: 2, maximumFractionDigits: 2}));      
      $('[name=quote_grandTotal]').val(grand_total.toLocaleString('sl-SI', {minimumFractionDigits: 2, maximumFractionDigits: 2}));
      
      //console.log(grand_total, grand_total.toLocaleString('sl-SI', {minimumFractionDigits: 2, maximumFractionDigits: 2}));

      angularVariables.basic['subtotal'] = sub_total;
      angularVariables.basic['grandtotal'] = grand_total;
      //angularVariables.basic['general_dicount'] = general_dicount;
    }

    $(document).ready(function() {
      $('[name=qty],[name=discount],[name=quote_discount]').on('keyup', function() {
        updateLines();
      });
      
    });

    /* timer fix */
    const updateTimer = setInterval(() => {
      updateLines();
    }, 250);

  }


  ngAfterViewInit(): void {            
    if (this.basic['quote_id']=='' || this.basic['category']=='') {      
      this.switchTab('basic');
    } else {
      this.switchTab('summary');
      this.hideEmpty = true;
    }
  }

}
