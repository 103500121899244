import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from './data.service';
import { GlobalVariables } from './data.globals';

@Injectable({
  providedIn: 'root'
})

export class CommonFunctions {

	constructor(
		public translate: TranslateService
	){}

	/*
	stringDistance(a, b) {
		var equivalency = 0;
    	var minLength = (a.length > b.length) ? b.length : a.length;    
    	var maxLength = (a.length < b.length) ? b.length : a.length;    
		for(var i = 0; i < minLength; i++) {
			if(a[i] == b[i]) {
				equivalency++;
			}
		}
    

    var weight = equivalency / maxLength;
	return weight;
    //return (weight * 100) + "%";
	}
	*/

	calculateSimilarity(str1 = "", str2 = "") {
		let longer = str1.trim();
		let shorter = str2.trim();
	
		let a1 = longer.toLowerCase().split(" ");
		let b1 = shorter.toLowerCase().split(" ");
		let result = a1.every((aa, i) => aa[0] === b1[i][0]);
	
		if (longer.length < shorter.length)  [longer,shorter] = [shorter,longer];
	
		var arr = [];
		let count = 0;
		for(var i = 0;i<longer.length;i++){
		  if(shorter && shorter.includes(longer[i])) {
			shorter = shorter.replace(longer[i],"")
			count++
		  };
		}
	
		return {
		  score : (count*100)/longer.length,
		  result
		}
	  }


	/****************************************************/
	/* split icon string to family and icon name */
	icon(value) {
		if (value == null) return ['fad', 'circle-xmark'];
		let output = value.split(",");
		if (output.length != 2) {
			output = ['fad', 'circle-xmark'];
		}		
		return output;
	}

	/****************************************************/
	/* Convert bites to KB, MB, GB */
	/* INPUT: bytes INTEGER  */
	/* OUTPUT: formeted Bytes KB, MB.... STRING */
	formatBytes(bytes, decimals) {
		if (bytes === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals || 2;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	} 

	/****************************************************/
	/* compare two dates, if second if null, current date is taken */
	/* INPUT: start, end in format 0000-00-00 00:00:00  */
	/* OUTPUT: true or false */
	checkIfPassDate(start, end) {
		var timeStart = <any>new Date(start)/1000;
		if (end == null) {
			var timeEnd = <any>new Date()/1000;  
		} else {
			var timeEnd = <any>new Date(end)/1000;  
		}

		var Diff = timeEnd - timeStart;

		if (Diff > 0) {
			return true;
		} else {
			return false;
		}
	}

	/****************************************************/
	/* calculate offset from two dates - in seconds     */
	calculateOffsetInSecconds(start, end, duration, type = null) {
    
		var timeStart = <any>new Date(start)/1000;
		if (end == null) {
			var timeEnd = <any>new Date()/1000;  
		} else {
			var timeEnd = <any>new Date(end)/1000;  
		}
		
		var Diff = timeStart - timeEnd;
		return Diff;

	}

	/****************************************************/
	/* calculate offset from two dates - in days */
	calculateOffsetInDays(start, end) {
    
		var timeStart = <any>new Date(start)/1000;
		if (end == null) {
			var timeEnd = <any>new Date()/1000;  
		} else {
			var timeEnd = <any>new Date(end)/1000;  
		}
		
		var hourDiff = Math.round((timeStart - timeEnd) / 60 / 60 / 24);
		return hourDiff;
	}

	/*******************************************************/
	/* calculate offset from two dates - in days and hours */
	calculateOffsetInDaysHours(start, end) {
    
		var timeStart = <any>new Date(start)/1000;
		if (end == null) {
			var timeEnd = <any>new Date()/1000;  
		} else {
			var timeEnd = <any>new Date(end)/1000;  
		}
		
		let output = "";
		let roundedDaysDiff = Math.floor(Math.abs((timeStart - timeEnd) / 60 / 60 / 24 ));
		if (isNaN(roundedDaysDiff)) {
			output = "";
		} else {
		let hourDiff = Math.floor((Math.abs((timeStart - timeEnd) / 60 / 60 / 24 ) - roundedDaysDiff) * 24);		
			if (roundedDaysDiff != 0) {
				output = roundedDaysDiff + "d";
			}
			if (hourDiff != 0) {
				if (output != '') output += " ";
				output += hourDiff + "h";
			}
		}
		return output;
	}

	/****************************************************/
	/* Convert time to mintes */
	/* INPUT: 00:00:00  */
	/* OUTPUT: (integer) caclulagted in minutes */
	timeToMinutes(time) {
		var timeArray = time.split(':');
		return (parseInt(timeArray[0])*60 + parseInt(timeArray[1]));
	}
    

	/****************************************************/
	/* calculate offset from two dates - human readable */
	/* INPUT: start, end in format 0000-00-00 00:00:00  */
	/* OUTPUT: (string) offset in days, minutes or hours or tomorrow and yesterday */
	calculateTimeToEvent(start, end, duration, type = null) {
    
		if (GlobalVariables.memberSettings['dates'] == "normal") {		
			return this.localDateFormat(start);
		}

		if (GlobalVariables.memberSettings['dates'] == "normalAndDay") {			
			//return this.getDayOfWeek(start) + " " + this.localDateFormat(start);
			return this.localDateFormat(start);
		}
		

		var timeStart = <any>new Date(start)/1000;
		if (end == null) {
			var timeEnd = <any>new Date()/1000;  
		} else {
			var timeEnd = <any>new Date(end)/1000;  
		}
		
		var Diff = timeStart - timeEnd;
		
		var tomorrow = new Date(new Date());
		tomorrow.setHours(0,0,0,0);    
		tomorrow.setDate(tomorrow.getDate() + 1);
		var hoursToEndOfTheDay = (<any>tomorrow/1000 - <any>new Date()/1000) /60 /60; //in hours
		
		var yesterday = new Date(new Date());
		yesterday.setHours(0,0,0,0);    
		yesterday.setDate(yesterday.getDate());
		var hoursFromStartOfTheDay = (<any>yesterday/1000 - <any>new Date()/1000) /60 /60; //in hours
	
		var start_no_hours = <any>new Date(start);
		start_no_hours.setHours(0,0,0,0);    
		start_no_hours.setDate(start_no_hours.getDate() + 1);
	
		var end_no_hours = <any>new Date(end);
		end_no_hours.setHours(0,0,0,0);    
		end_no_hours.setDate(end_no_hours.getDate() + 1);
	
		/************************************/
		/* Return - if some data is missing */
		if (isNaN(Diff)) return "-";
		
		var d = Math.floor(Diff / (3600 * 24));
		var h = Math.floor(Diff % (3600 * 24) / 3600);
		var m = Math.floor(Diff % 3600 / 60);
	
		var minutes = Diff / 60;
		var hours = Diff / 60 / 60;       
		var humanTimeHelper = '';
	
		if (duration) {
		  
		} else {
		  if (Math.abs(hours) > 24 || (hoursToEndOfTheDay < Math.abs(hours) && Diff > 0) || (hoursFromStartOfTheDay > hours && Diff < 0)) {
			if ((hoursToEndOfTheDay > Math.abs(hours) && d == 1) || (hoursToEndOfTheDay < Math.abs(hours) && d == 0)) {
			  humanTimeHelper = 'Tomorrow';
			} else if (d == -1) {
			  humanTimeHelper = 'Yesterday';
			} else {
			  //if (Math.abs(hours) > 24) d++;
			  humanTimeHelper = d < 0 ? Math.abs(d+1) + " " + this.translate.instant('days ago') : (d+1) + " " + this.translate.instant('days to'); 
			}
		  } else if (Math.abs(minutes) > 60) {
			humanTimeHelper = h < 0 ? Math.abs(h) + " " + this.translate.instant('hours ago') : h + " " + this.translate.instant('hours to');
		  } else if (Math.abs(minutes) > 0) {
			humanTimeHelper = m < 0 ? Math.abs(m) + " " + this.translate.instant('min ago') : m + " " + this.translate.instant('min to');
			if (m == 0 && h == 0) humanTimeHelper = this.translate.instant('passed');
		  } else {
			humanTimeHelper = this.translate.instant('Now');
		  }
	
		}
	
		return humanTimeHelper;
	  }
	

	/****************************************************/
	/* Get value from Array based on string path/key    */
	/* INPUT: object + path eg. "ponudbe/2022/draft"    */
	/* OUTPUT: value from object                        */
	getObjectValue<ObjectType>(object: ObjectType, path: string) {
		const keys = path.split('/');
		let result = object;		
		for (const key of keys) {
			result = result[key];
		}		
		return result;
	}
	
	/*********************************************************/
	/* Set value to Array based on string path/key           */
	/* INPUT: object + path eg. "ponudbe/2022/draft" + value */
	/* OUTPUT: no output                                     */
	setObjectValue(obj, path, value) {				
		var a = path.split('/')
		var o = obj;
		while (a.length - 1) {
			var n = a.shift()
			if (!(n in o)) o[n] = {}
			o = o[n]
		}
		o[a[0]] = value
	};

	/*********************************************************/
	/* Delete value from Array based on string path/key      */
	/* INPUT: object + path eg. "ponudbe/2022/draft"		 */
	/* OUTPUT: no output                                     */
	deleteObjectValue(obj, path) {

		if (!obj || !path) {
			return;
		}
		
		if (typeof path === 'string') {
			path = path.split('/');
		}
		
		for (var i = 0; i < path.length - 1; i++) {		
			obj = obj[path[i]];
			if (typeof obj === 'undefined') {
				return;
			}
		}
		
		delete obj[path.pop()];		  
	  };


	/*********************************************************/
	/* Choose FontAwesome icon based on ext of file          */
	/* INPUT: ext of file                            		 */
	/* OUTPUT: icon name                                     */
	showFileIcon(ext) {
		if (ext == 'doc' || ext == 'docx') {
		  return "file-word";
		} else if (ext == 'xls' || ext == 'xlsx') {
		  return "file-spreadsheet";
		} else if (ext == 'zip' || ext == 'gz' || ext == 'rar') {
		  return "file-archive";
		} else if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'svg' || ext == 'tif' || ext == 'gif') {
		  return "file-image";
		} else if (ext == 'mov' || ext == 'mp4') {
		  return "file-video";
		} else if (ext == 'ppt') {
		  return "file-powerpoint";
		} else if (ext == 'pdf') {
		  return "file-pdf";
		} else {
		  return "file";
		}
	  }
	
	/***************************************************/
	/* Check if user using mobile device               */
	isMobileDevice() {
		if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
			return true;
		} else {
			return false;
		}
	};

	/****************************************************/
	/* convert string to bollean EXTENDED			    */
	/* INPUT: string : "true", "false", ""				*/
	/* OUTPUT: boolean true/false or null               */
	booleanToStringExtended(value) {
		if (value == true) {
			return 'true';
		} else if (value == false) {
			return 'false';
		} else {
			return '';
		}
	}

	/****************************************************/
	/* convert string to bollean EXTENDED			    */
	/* INPUT: string : "true", "false", ""				*/
	/* OUTPUT: boolean true/false or null               */
	toBooleanExtended(value) {
		if (value == 'true') {
			return true;
		} else if (value == 'false') {
			return false;
		} else {
			return null;
		}
	}

	/****************************************************/
	/* Get week number for given date in JS			    */
	/* INPUT: date in JS format							*/
	/* OUTPUT: export week number                       */
	getWeekNumber(currentDate) {
		var startDate = new Date(currentDate.getFullYear(), 0, 1);
		var days = Math.floor((currentDate - <any>startDate) / (24 * 60 * 60 * 1000));
		return Math.ceil(days / 7) + 1;
	}

	/****************************************************/
	/* Get day number for given date in JS			    */
	/* INPUT: date in JS format							*/
	/* OUTPUT: export day number                       */
	getDayNumber(currentDate) {
		var dayNumber = currentDate.getDay();
		dayNumber--;
		if (dayNumber == -1) dayNumber = 6;
		return dayNumber;
	}

	/****************************************************/
	/* Find URL links in string and create a href tag   */
	/* INPUT: text										*/
	/* OUTPUT: export text with a href tags            */
	URLtoHYPERLINK(message) {
		if(!message) return;
	   
		var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
		return message.replace(urlRegex, function(url) {
			return '<a href="' + url + '">' + url + '</a>';
		});
		/*
		var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;		
		return message.replace(urlRegex, function (url) {
		  var hyperlink = url;
		  if (!hyperlink.match('^https?:\/\/')) {
			hyperlink = 'http://' + hyperlink;		  	
		  }
		  
		  url = (url.replace('http://','').replace('https://','').split('/')[0]);		  

		  return '<a class="converted_href" href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
		});
		*/
	  }


	/****************************************************/
	/* Show offset between two dates                    */
	/* INPUT: start, end in format 0000-00-00 00:00:00  */
	/* OUTPUT: calculateTimetoEvent                     */ 
	showTimeToEvent(start, end, displayType) {		
		if (start == null || start == '0000-00-00 00:00:00') return "";
		
		var current_helper = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
		var current = (new Date(Date.now() - current_helper)).toISOString().slice(0, 19).replace('T', ' ');

		if (end == null) end = current;

		if (new Date(current).getTime() < new Date(start).getTime()) {      
			return this.calculateTimeToEvent(start, current, null, 1);
		} else if (new Date(current).getTime() < new Date(end).getTime()) {      
			return this.calculateTimeToEvent(end, current, null, 1);
		} else {      
			return this.calculateTimeToEvent(end, current, null, 1);
		}

		return "";
	}

	/****************************************************/
	/* Show offset between two dates as icon            */
	/* INPUT: start, end in format 0000-00-00 00:00:00  */
	/* OUTPUT: (string) name of Font Awesome Icon       */
	showTimeToEventIcon(start, end, displayType) {
		if (start == null || start == '0000-00-00 00:00:00') return "question";		

		var current_helper = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
		var current = (new Date(Date.now() - current_helper)).toISOString().slice(0, 19).replace('T', ' ');

		if (end == null) end = current;

		if (new Date(current).getTime() < new Date(start).getTime()) {      
			return 'arrow-from-left';
		} else if (new Date(current).getTime() < new Date(end).getTime()) {      
			return 'arrow-to-right';
		} else {      
			return 'chevron-double-right';
		}

		return "question";
	}


	/****************************************************/
	/* Convert date to day					            */
	/* INPUT: date in yyyy-mm-yy format                 */
	/* OUTPUT: day name from date in string             */
	getDayOfWeek(dateString) {
		if (dateString == null || dateString == '0000-00-00 00:00:00') return "";
		dateString = dateString.split(" ");
		const days = [this.translate.instant("Sunday"), this.translate.instant("Monday"), this.translate.instant("Tuesday"), this.translate.instant("Wednesday"), this.translate.instant("Thuesday"), this.translate.instant("Friday"), this.translate.instant("Saturday")];
		const date = new Date(dateString[0]);
		const dayIndex = date.getDay();
		return days[dayIndex];		
	}



	/****************************************************/
	localDateFormat(date) {
		if (date == null || date == '0000-00-00 00:00:00' || date == '') return " - ";
		var datetime = new Date(date);   
		var options = { hour12: false };   
		return datetime.toLocaleString("sl-SI", options).replace('. ', '.').replace('. ', '.');
	}  

	localDateOnlyFormat(date) {
		date = this.localDateFormat(date);
		return (date.split(" ")[0]).replace(",", "");		
	}

	/****************************************************/
	getInitials(str) {
		var FirstName = '';
		var LastName = '';

		if (str != undefined) {      
			FirstName = str.split(' ')[0];
			LastName = str.split(' ').reduceRight(a => a);
		}

		if (FirstName === LastName) { return FirstName.trim().substring(0, 2).toUpperCase(); }
		return [FirstName, LastName].map(name => name[0]).join('').toUpperCase();
	}

	/****************************************************/
	formatDateMYSQL(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
	
		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;
	
		return [year, month, day].join('-');
	}

	/***************************************************/
	ConvertToCSV(objArray, headerList) {
    
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = '';
	
		for (let index in headerList) { 			
			row += headerList[index] + ';'; 
		}
	
		row = row.slice(0, -1);
		str += row + '\r\n';
	
		for (let i = 0; i < array.length; i++) {		 
		 let line = "";
		 for (let index in headerList) {
		  let head = headerList[index];		  
		  if (array[i][head] == undefined) array[i][head] = "-";
		  line += array[i][head] + ';'; 
		 }
		 str += line + '\r\n';
		}
	
		return str;
	
	  }

	  downloadFile(data, head, filename = 'export_') {

		var today = new Date();
		var date = today.getFullYear()+'_'+(today.getMonth()+1)+'_'+today.getDate();    
		
		filename = filename + date;
		
		let csvData = this.ConvertToCSV(data, head);
		
		let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		
		if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
			dwldLink.setAttribute("target", "_blank");
		}
	
		dwldLink.setAttribute("href", url);
		dwldLink.setAttribute("download", filename + ".csv");
		dwldLink.style.visibility = "hidden";
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	
	  }

	/****************************************************/
	/* Global parmanent variables - SET  	            */
	/* INPUT: index, value							    */
	/* OUTPUT: null								        */
	globalStorage_setItem(index, value) {
	
		return true;

	}

	/****************************************************/
	/* Global parmanent variables - SET  	            */
	/* INPUT: index									    */
	/* OUTPUT: (string) value					        */
	globalStorage_getItem(index) {

		return ""; 
	
	}

	/****************************************************/
	/* Convert string to float US or EU  	            */
	/* INPUT: string								    */
	/* OUTPUT: float							        */
	parseStringToFloat(value: string): number {
		//console.log(value);
		if (value == null) return 0;
		const cleanedValue = value.replace(/[.,](?=\d{3})/g, '');
		//console.log(cleanedValue);
		const normalizedValue = cleanedValue.replace(/[,\.](?=\d{2}$)/, '.');
		//console.log(normalizedValue);
		return parseFloat(normalizedValue);
	}

	/****************************************************/
	/* Convert float to mysql strin number              */
	/* INPUT: float	 								    */
	/* OUTPUT: string							        */
	floatToMYSQL(value: number): string {
		return value.toString();
	}

    /****************************************************/
	/* Convert Number to localised stringified Number   */
	/* INPUT: string  								    */
	/* OUTPUT: float							        */          
	localizeAnyNumberToString(value) {
		if (typeof value == 'string') {			
			return this.formatNumberString(value, 2);
		} else {
			return this.formatNumber(value, 2);
		}		
	}

	/****************************************************/
	/* Convert localised stringified Number to Number   */
	/* INPUT: string  								    */
	/* OUTPUT: float							        */
	localizeStringToNumber(value) {
		if (value == undefined) value = "0";
		value = value.replace('.', '').replace(',', '.');
		return parseFloat(value);
	}

	/****************************************************/
	/* Get formated number							    */
	/* INPUT: float value with dot						*/
	/* OUTPUT: string formated number                   */
	formatNumber(floatValue, digits = 2) {
		return floatValue.toLocaleString('sl-SI', {minimumFractionDigits: digits, maximumFractionDigits: digits});
	}

	/****************************************************/
	/* INPUT: string value with dot						*/
	/* OUTPUT: string formated number                   */
	formatNumberString(stringValue, digits = 2) {
		return parseFloat(stringValue).toLocaleString('sl-SI', {minimumFractionDigits: digits, maximumFractionDigits: digits});
	}

	/****************************************************/
	/* INPUT: string value html tags					*/
	/* OUTPUT: string value text	                    */
	stripHtml(html)	{
	   let tmp = document.createElement("div");
	   tmp.innerHTML = html;
	   return tmp.textContent || tmp.innerText || "";
	}

	/****************************************************/
	/* INPUT: string value hex							*/
	/* OUTPUT: string value hex, best contrast          */
	textColorFromBG(hexcolor) {
		//hexcolor = hexcolor == null ? '#ffffff' : hexcolor;
		if (hexcolor == null) return 'black';
		hexcolor = hexcolor.replace("#", "");
		var r = parseInt(hexcolor.substr(0,2),16);
		var g = parseInt(hexcolor.substr(2,2),16);
		var b = parseInt(hexcolor.substr(4,2),16);
		var yiq = ((r*299)+(g*587)+(b*114))/1000;		
		return (yiq >= 128) ? 'black' : 'white';
	}


	/****************************************************/
	/* INPUT: string with /r/n							*/
	/* OUTPUT: string with <br/>				        */
	ln2br(value) {
		return value != null ? value.replace(/\n/g, '<br>') : "";
	}

	/* check if some time is passed already */
	timeDifferencePass(dateToCheck) {
		var timeStart = <any>new Date(dateToCheck)/1000;
		var timeEnd = new Date(new Date()).getTime() / 1000;
		//console.log(timeStart + " / " + timeEnd);
		if (timeStart < timeEnd) {
			return true;
		} else {
			return false; 
		}
		
	}

	/* start: YYYY-MM-DD, end: YYYY-MM-DD, duration, type: true/false */
	timeDifference(start, end, duration, type = null) {

		if (GlobalVariables.memberSettings['dates'] == "normal") {
			return this.localDateFormat(start);
		}

		if (start != null && start != '0000-00-00 00:00:00') {
			var timeStart = <any>new Date(start)/1000;
			var timeEnd = <any>new Date(end)/1000;  
			
			var Diff = timeStart - timeEnd;

			if (isNaN(Diff)) return "-";

			if (Diff < 0) {
				var d = Math.ceil(Diff / (3600*24));
				var h = Math.ceil(Diff % (3600*24) / 3600);
				var m = Math.floor((Diff % 3600) / 60);
				var s = Math.floor((Diff % 3600) % 60);
				if (s < -59) {
					s = 0;
					m++;
				}
			} else {
				var d = Math.floor(Diff / (3600*24));
				var h = Math.floor(Diff % (3600*24) / 3600);
				var m = Math.floor(Diff % 3600 / 60);
				var s = Math.floor(Diff % 3600 % 60);				
				if (s > 59) {
					s = 0;
					m++;
				}
			}

			var humanTimeHelper = '';
			if (duration) {

				if (m == 0) {
					humanTimeHelper = 'Now'; 
				} else { 
					humanTimeHelper = m < 0 ? Math.abs(m) + " min" : Math.abs(m) + " min"; 
				}          
				if (h != 0) humanTimeHelper = h < 0 ? Math.abs(h) + " hours" : Math.abs(h) + " hours";
				if (d != 0) humanTimeHelper = d < 0 ? Math.abs(d) + " days" : Math.abs(d) + " days";

			} else {

				if (m == 0) {
					humanTimeHelper = 'Now'; 
				} else { 
					humanTimeHelper = m < 0 ? Math.abs(m) + " min ago" : m + " min to"; 
				}          

				if (type == 1) {
					if (h != 0) humanTimeHelper = h < 0 ? Math.abs(h) + " hours" : h + " hours";
					if (m != 0 && h < 0 && d < 0) humanTimeHelper = m < 0 ? Math.abs(h) + " min" : h + " min";
					if (d < 0) humanTimeHelper = d < 0 ? Math.abs(d) + " days" : d + " days"; 
				} else if (type == 2) {
					humanTimeHelper = Math.abs(h).toString().padStart(2, '0') + ":" + Math.abs(m+1).toString().padStart(2, '0') + ":" + Math.abs(s).toString().padStart(2, '0');					
				} else {
					if (h != 0) humanTimeHelper = h < 0 ? Math.abs(h) + " hours to" : h + " hours ago";
					if (m != 0 && h < 0 && d < 0) humanTimeHelper = m < 0 ? Math.abs(h) + " min to" : h + " min ago";
					if (d < 0) humanTimeHelper = d < 0 ? Math.abs(d) + " days to" : d + " days ago";  
				}
			
			}
		} else {
			humanTimeHelper = " - ";
		}

	    return humanTimeHelper;
	}

}