import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ActivitiesFilterComponent } from '../../activities/activities-filter/activities-filter.component';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctions } from '../../services/common.functions';
import { DataService } from '../../services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivityComponent } from '../../activities/activity/activity.component';

import {
  GanttBarClickEvent,
  GanttViewType,
  GanttDragEvent,
  GanttLineClickEvent,
  GanttLinkDragEvent,
  GanttItem,
  GanttPrintService,
  NgxGanttComponent,
  GanttSelectedEvent,
  GanttBaselineItem,
  GanttView,
  GanttToolbarOptions
} from '@worktile/gantt';

import { GANTT_GLOBAL_CONFIG } from '@worktile/gantt';

import { setDefaultOptions } from 'date-fns';
import { sl } from 'date-fns/locale';

setDefaultOptions({ locale: sl });

@Component({
  selector: 'app-activities-gantt',
  templateUrl: './activities-gantt.component.html',
  styleUrls: ['./activities-gantt.component.css']
})
export class ActivitiesGanttComponent implements OnInit {

    public hide_activity_name = false;
    public hide_activity_start = true;
    public hide_activity_end = true;
    public hide_activity_finished = true;   
    public activity_items = []; 

    views = [
      {
          name: 'Week',
          value: GanttViewType.day
      },
      /*{
          name: 'Week',
          value: GanttViewType.week
      },*/
      {
          name: 'Month',
          value: GanttViewType.month
      },
      {
          name: 'Quarter',
          value: GanttViewType.quarter
      },
      {
          name: 'Year',
          value: GanttViewType.year
      }
    ];










    viewOptions = {
      dateFormat: {
          month: 'Month'
      }
    };

    viewType: GanttViewType = GanttViewType.day;

    selectedViewType: GanttViewType = GanttViewType.day;

    isBaselineChecked = false;

    isShowToolbarChecked = false;

    items = [];

    /*
    items: GanttItem[] = [
        { id: '000000', title: 'Task 0', start: 1627729997, end: 1628421197, expandable: true },
        { id: '000001', title: 'Task 1', start: 1617361997, end: 1625483597, links: ['000003', '000004', '000000'], expandable: true },
        { id: '000002', title: 'Task 2', start: 1610536397, end: 1610622797 },
        { id: '000003', title: 'Task 3', start: 1628507597, end: 1633345997, expandable: true },
        { id: '000004', title: 'Task 4', start: 1624705997, expandable: true },
        { id: '000005', title: 'Task 5', start: 1628075597, end: 1629544397, color: '#709dc1' },
        { id: '000006', title: 'Task 6', start: 1641121997, end: 1645528397 },
        { id: '000007', title: 'Task 7', start: 1639393997, end: 1640862797 },
        { id: '000008', title: 'Task 8', end: 1628783999, color: '#709dc1' },
        { id: '000009', title: 'Task 9', start: 1639307597, end: 1640344397 },
        { id: '0000010', title: 'Task 10', start: 1609067597, end: 1617275597 },
        { id: '0000011', title: 'Task 11', start: 1611918797, end: 1611918797 },
        { id: '0000012', title: 'Task 12', start: 1627816397, end: 1631358797 },
        { id: '0000013', title: 'Task 13', start: 1625051597, end: 1630667597, links: ['0000012'] },
        { id: '0000014', title: 'Task 14', start: 1627920000, end: 1629129599 },
        { id: '0000015', title: 'Task 15', start: 1633259597, end: 1639480397 },
        { id: '0000016', title: 'Task 16', start: 1624965197, end: 1627211597 },
        { id: '0000017', title: 'Task 17', start: 1641035597, end: 1649157197 },
        { id: '0000018', title: 'Task 18', start: 1637061197, end: 1642677197 },
        { id: '0000019', title: 'Task 19', start: 1637925197, end: 1646305997 },
        { id: '0000020', title: 'Task 20', start: 1628334797, end: 1629889997 },
        { id: '0000021', title: 'Task 21', start: 1622891597, end: 1627643597 },
        { id: '0000022', title: 'Task 22', start: 1616238797, end: 1620731597 },
        { id: '0000023', title: 'Task 23', start: 1626693197, end: 1630149197 },
        { id: '0000024', title: 'Task 24', start: 1626174797, end: 1626952397 },
        { id: '0000025', title: 'Task 25', start: 1631013197, end: 1637493197 },
        { id: '0000026', title: 'Task 26', start: 1635937997, end: 1643886797 },
        { id: '0000027', title: 'Task 27', start: 1637665997, end: 1644059597 },
        { id: '0000028', title: 'Task 28', start: 1611400397, end: 1615547597 },
        { id: '0000029', title: 'Task 29', start: 1618053197, end: 1619176397 }
    ];
    */

    baselineItems: GanttBaselineItem[] = [];

    toolbarOptions: GanttToolbarOptions = {
      viewTypes: [GanttViewType.day, GanttViewType.month, GanttViewType.year]
    };

    constructor(
      private data: DataService,
      public dialog: MatDialog,
      private route: ActivatedRoute,
      private routeto: Router,
      public translate: TranslateService,
      public cFunction: CommonFunctions
    ) {     
      this.route.paramMap.subscribe(params => {
        this.ngOnInit();
        translate.addLangs(['en', 'si']);
      });
    }
  

    ngOnInit(): void {        
      this.route.params.subscribe((params)=>{      
        this.category_id = params.category_id;
        
        this.data.getCategories({ categories: null, items : 10000, page : 0, order : 't1.description', orderby : 'asc', search : null }).subscribe(
          (res: Response) => {
            var categories = (<any>res).rows;
            for(var i=0; i<categories.length; i++) {
              if (categories[i].entity == this.category_id) {
                this.category_title = categories[i].description;
                this.category_hex = categories[i].hex;
              }
            }
        });        
  
        /* session settings */    
        this.filter_values = [];
        if (localStorage.getItem('activities_'+this.category_id) != '""' && localStorage.getItem('activities_'+this.category_id) != null) {           
          this.filter_values = JSON.parse(localStorage.getItem('activities_'+this.category_id));
        }  
        this.refreshData();
      });
    }

    switchChange() {
      if (this.isBaselineChecked) {
          this.baselineItems = [
              { id: '000000', start: 1627728888, end: 1628421197 },
              { id: '000001', start: 1617361997, end: 1625483597 },
              { id: '000002', start: 1610536397, end: 1610622797 },
              { id: '000003', start: 1628507597, end: 1633345997 },
              { id: '000004', start: 1624705997 }
          ];
      } else {
          this.baselineItems = [];
      }
    }

    selectView(type: GanttViewType) {
      this.viewType = type;
      this.selectedViewType = type;
    }

    barClick(event: GanttBarClickEvent) {
      //console.log('Event: barClick');
      var entity = event.item.id;
      this.edit(entity);
    }

    lineClick(event: GanttLineClickEvent) {
      console.log('Event: lineClick');
    }

    dragMoved(event: GanttDragEvent) {}

    dragEnded(event: GanttDragEvent) {
        this.items = [...this.items];
        var start = (new Date((event.item.start+24*60*60) * 1000)).toISOString().slice(0, 19).replace('T', ' ');
        var end = (new Date((event.item.end) * 1000)).toISOString().slice(0, 19).replace('T', ' ');
        var helper = [];
        helper = start.split(" ");
        start = helper[0] + " 16:00:00";
        helper = end.split(" ");
        end = helper[0] + " 23:59:59";
        var parameters = { activity_entity : event.item.id, start_date : start, end_date : end };         
        this.data.updateDateTime(parameters).subscribe((res: Response) => {}); 
    }

    selectedChange(event: GanttSelectedEvent) {
      var entity = event.selectedValue['id'];      
      this.edit(entity);
    }

    linkDragEnded(event: GanttLinkDragEvent) {
        this.items = [...this.items];
        //console.log('Event: linkDragEnded');
        console.log(event);
        var links = JSON.stringify(event.source.links);
        var parameters = { entity : event.source.id, links : links };
        this.data.updateLinks(parameters).subscribe((res: Response) => {}); 
    }

    viewChange(event: GanttView) {
      console.log(event.viewType);
      this.selectedViewType = event.viewType;
    }
   
    switch_hide_activity_name() {
      this.hide_activity_name = !this.hide_activity_name;
    }

    switch_hide_activity_start() {
      this.hide_activity_start = !this.hide_activity_start;
    }

    switch_hide_activity_end() {
      this.hide_activity_end = !this.hide_activity_end;
    }

    switch_hide_activity_finished() {
      this.hide_activity_finished = !this.hide_activity_finished;
    }

    edit(entity) {    
      var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
      editDialog.componentInstance.entity = entity;
      //editDialog.componentInstance['category_id'] = this.category_id;
      editDialog.afterClosed().subscribe(result => {      
        this.updateParameters();
        this.updateFilters();
        this.refreshData();
      });    
    }

    canban_view() {    
      this.routeto.navigateByUrl('/activities-canban/'+this.category_id);
    }
  
    gantt_view() {    
      this.routeto.navigateByUrl('/activities-gantt/'+this.category_id);
    }
  
    table_view() {    
      this.routeto.navigateByUrl('/activities/'+this.category_id);
    }

    /**********************************************************/

    public category_id;
    public category_title;
    public category_hex;
    public filter_values;  
    public parameters = { categories: null, items : 10000, page : 0, order : 't1.entity', orderby : 'asc', search : null };

    updateParameters() {
      var categories = [];    
      categories.push({ category_id: this.category_id });
      this.parameters = { categories: categories, items : 10000, page : 0, order : 't1.entity', orderby : 'asc', search : null };
    }
  
    updateFilters(filter_values = this.filter_values) {
        if (this.filter_values) {
        if (this.filter_values['assigned_id'] != undefined) this.parameters['filter_assigned_id'] = this.filter_values['assigned_id'];
        if (this.filter_values['customer_id'] != undefined) this.parameters['filter_customer'] = this.filter_values['customer_id'];      
        //if (this.filter_values['assigned_id'] != undefined) this.parameters['assigned_id'] = this.filter_values['assigned_id'];
        if (this.filter_values['start_from_date_raw'] != undefined) this.parameters['filter_start_from_date_raw'] = this.filter_values['start_from_date_raw'];
        if (this.filter_values['start_to_date_raw'] != undefined) this.parameters['filter_start_to_date_raw'] = this.filter_values['start_to_date_raw'];
        if (this.filter_values['end_from_date_raw'] != undefined) this.parameters['filter_end_from_date_raw'] = this.filter_values['end_from_date_raw'];
        if (this.filter_values['end_to_date_raw'] != undefined) this.parameters['filter_end_to_date_raw'] = this.filter_values['end_to_date_raw'];
        if (this.filter_values['created_from_date_raw'] != undefined) this.parameters['filter_created_from_date_raw'] = this.filter_values['created_from_date_raw'];
        if (this.filter_values['created_to_date_raw'] != undefined) this.parameters['filter_created_to_date_raw'] = this.filter_values['created_to_date_raw'];
        if (this.filter_values['hide_status'] != undefined) this.parameters['filter_hide_status'] = this.filter_values['hide_status'];
        if (this.filter_values['approved'] != undefined) this.parameters['approved'] = this.filter_values['approved'];
        if (this.filter_values['approvedbyme'] != undefined) this.parameters['approvedbyme'] = this.filter_values['approvedbyme'];
        if (this.filter_values['featured'] != undefined) this.parameters['featured'] = this.filter_values['featured'];
        if (this.filter_values['author'] != undefined) this.parameters['author'] = this.filter_values['author'];
        if (this.filter_values['hidden'] != undefined) this.parameters['hidden'] = this.filter_values['hidden'];
        //console.log(this.parameters);
      }
    }

    refreshData() { 
      var categories = [];    
      categories.push({ category_id: this.category_id });  
      this.updateParameters();
      this.updateFilters();     
      
      console.log(this.parameters);
      this.data.getActivities(this.parameters).subscribe(
        (res: Response) => {
          this.activity_items = (<any>res).rows;
          console.log(this.activity_items);

          this.items = [];
                    
          for (var i=0; i<this.activity_items.length; i++) {            
            var start = (new Date((this.activity_items[i].message_start).replace(' ', 'T')).getTime())/1000;
            var end = (new Date((this.activity_items[i].message_end).replace(' ', 'T')).getTime())/1000;
            //if (isNaN(start) && isNaN(end)) { start = new Date(); end = null }
            //if (isNaN(start)) { start = null }
            //if (isNaN(end)) { end = null }
            if (this.activity_items[i].sub_activity.rows.length == 0) {
              this.items.push( { color: this.activity_items[i].message_type_hex, id: this.activity_items[i].entity, customer: this.activity_items[i].customer, title: this.activity_items[i].title, start: start, end: end, expandable: false } );
            } else {
              var children = [];
              var children_rows = this.activity_items[i].sub_activity.rows;
              var message_links = [];
              if (this.activity_items[i].links !='') {
                message_links = JSON.parse(this.activity_items[i].links);
              } 
              for (var j=0; j<children_rows.length; j++) {
                var child_start = (new Date((children_rows[j].message_start).replace(' ', 'T')).getTime())/1000;
                var child_end = (new Date((children_rows[j].message_end).replace(' ', 'T')).getTime())/1000;
                children.push( { color: children_rows[j].message_type_hex, id: children_rows[j].entity, customer: children_rows[j].customer, title: children_rows[j].title, start: child_start, end: child_end, expandable: false, links:message_links } );  
              }
              this.items.push( { color: this.activity_items[i].message_type_hex, id: this.activity_items[i].entity, customer: this.activity_items[i].customer, title: this.activity_items[i].title, start: start, end: end, expandable: true, children: children, links:message_links } );
            }

          
          }

        }
      );

    }

}