<div class="inner_wrapper">
	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [ngStyle]="{'border-color':documentColorHelper}">
		<div class="left">
            <h1>
                {{'Office document'|translate}} | {{type|translate}}            
            </h1>
		</div>

		<div class="right">
            <div *ngIf="entity" class="language_container">                
                <div class="iconWrapper">
                    <fa-icon class="big customIcons" [icon]="['fad', 'print']" (click)="generatePDFModal();"></fa-icon>
                    <div class="addOnIconText">PDF</div>
                </div>
                <span class="selected_language" (click)="settingsEdit=!settingsEdit;dropdown=false;">{{document['lang']?.toUpperCase()}}</span>
            </div>

            <fa-icon *ngIf="entity && entity==LastEntity && shortcuts['delete']" class="big customIcons" [icon]="['fad', 'trash']" (click)="delete_document();dropdown=false;"></fa-icon>
            
            <fa-icon *ngIf="shortcuts['statistics']" class="big customIcons" [icon]="['fad', 'chart-simple']" (click)="generateStartistics();statisticsWindow=!statisticsWindow;dropdown=false;"></fa-icon>
            <fa-icon *ngIf="entity && (shortcuts['link'] || (document['activity_id']!=null && document['activity_id']!=''))" class="big customIcons" [ngClass]="{'active' :  (document['activity_id']!=null && document['activity_id']!='')}" [icon]="['fad', 'link']" (click)="generateStartistics();statisticsWindow=!statisticsWindow;dropdown=false;"></fa-icon>
            <fa-icon *ngIf="entity && shortcuts['swap']" class="big customIcons" [icon]="['fad', 'swap-arrows']" (click)="swapDocumentWindow=!swapDocumentWindow;dropdown=false;"></fa-icon>
            <fa-icon *ngIf="entity && shortcuts['pdf']" class="big customIcons" [icon]="['fad', 'print']" (click)="generatePDFModal();dropdown=false;"></fa-icon>
            <fa-icon *ngIf="entity && shortcuts['pin']" class="big customIcons" [icon]="['fad', 'thumbtack']" (click)="toDock(entity);dropdown=false;"></fa-icon>

            <fa-icon [icon]="['fad', 'bars']" class="big headerIcon bars" (click)="dropdown = !dropdown"></fa-icon>
            <fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
		</div>
	</div>

    <div class="inner_scroll body"> 
        <!-- Drop down burgerbar -->
        <div class="dropdown" [ngClass]="{'active' : dropdown == true}">
            <span (click)="generateStartistics();statisticsWindow=!statisticsWindow;dropdown=false;">
                <fa-icon [icon]="['fad', 'chart-simple']"></fa-icon>{{'Time activity Statistic'|translate}}
                <fa-icon [icon]="['fad', 'circle-check']" class="icon_small check" [ngClass]="{'active' : shortcuts['statistics']}" (click)="$event.stopPropagation();shortcuts['statistics']=!shortcuts['statistics'];pinShortcut()"></fa-icon>	
            </span>
            <span (click)="linkWindow=!linkWindow;dropdown=false;">
                <fa-icon [icon]="['fad', 'link']"></fa-icon>{{'Linking'|translate}}
                <fa-icon [icon]="['fad', 'circle-check']" class="icon_small check" [ngClass]="{'active' : shortcuts['link']}" (click)="$event.stopPropagation();shortcuts['link']=!shortcuts['link'];pinShortcut()"></fa-icon>	
            </span>
            <span *ngIf="entity" (click)="swapDocumentWindow=!swapDocumentWindow;dropdown=false;">
                <fa-icon [icon]="['fad', 'swap-arrows']"></fa-icon>{{'Swap Document'|translate}}
                <fa-icon [icon]="['fad', 'circle-check']" class="icon_small check" [ngClass]="{'active' : shortcuts['swap']}" (click)="$event.stopPropagation();shortcuts['swap']=!shortcuts['swap'];pinShortcut()"></fa-icon>	
            </span>            
            <span *ngIf="entity" (click)="toDock(entity);dropdown=false;">
                <fa-icon [icon]="['fad', 'thumbtack']"></fa-icon>{{'Pin'|translate}}
                <fa-icon [icon]="['fad', 'circle-check']" class="icon_small check" [ngClass]="{'active' : shortcuts['pin']}" (click)="$event.stopPropagation();shortcuts['pin']=!shortcuts['pin'];pinShortcut()"></fa-icon>	
            </span>
            <hr />
            <span *ngIf="entity" (click)="generatePDFModal();dropdown=false;">                                
                <fa-icon class="pdf" [icon]="['fad', 'print']"></fa-icon>                    
                {{'Export PDF'|translate}}
                <fa-icon [icon]="['fad', 'circle-check']" class="icon_small check" [ngClass]="{'active' : shortcuts['pdf']}" (click)="$event.stopPropagation();shortcuts['pdf']=!shortcuts['pdf'];pinShortcut()"></fa-icon>	
            </span>

            <span *ngIf="entity && entity==LastEntity" (click)="delete_document();dropdown=false;">
                <fa-icon [icon]="['fad', 'trash']"></fa-icon>{{'Delete document'|translate}}
                <fa-icon [icon]="['fad', 'circle-check']" class="icon_small check" [ngClass]="{'active' : shortcuts['delete']}" (click)="$event.stopPropagation();shortcuts['delete']=!shortcuts['delete'];pinShortcut()"></fa-icon>	
            </span>

            <span *ngIf="entity" (click)="settingsEdit=!settingsEdit;dropdown=false;">
                <fa-icon [icon]="['fad', 'cog']"></fa-icon>{{'Settings'|translate}}
            </span>
        </div>
        <!-- /////////////////// -->       
        <div class="documentBody">

            <div class="head">
                <div class="head_left w45">    
                    
                    <div class="inline w100" style="margin-top: 18px">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'Document number'|translate}}</mat-label>
                            <input type="text" style="font-size: 24px; font-weight: 700;" matInput [(ngModel)]="document['document_number_text']" (click)="dropdown=false;" readonly disabled>
                            <!--
                            <input type="hidden" matInput [(ngModel)]="document['document_number']" readonly disabled>
                            <input type="hidden" matInput [(ngModel)]="document['document_year']" readonly disabled>
                            -->
                            <!--<mat-hint>Error</mat-hint>-->                    
                        </mat-form-field>
                    </div>

                    <div class="inline w100" style="margin-top: 18px;">
                        <mat-form-field appearance="outline">
                          <mat-label>{{'Document address'|translate}}</mat-label>
                          <fa-icon *ngIf="document['customer_id']==''" class="right" [icon]="['fad', 'users']" (click)="choose_customer('address')" style="position: absolute; right: 0px;"></fa-icon>
                          <fa-icon *ngIf="document['customer_id']!=''" class="right" [icon]="['fad', 'users-slash']" (click)="clear_customer()" style="position: absolute; right: 0px;"></fa-icon>
                          <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" [(ngModel)]="document['address']" (click)="dropdown=false;" (change)="save=false"></textarea>
                          <!--<mat-hint>Error</mat-hint>-->
                        </mat-form-field>
                    </div>

                    <div class="inline w100 wPadding">               
                        <mat-form-field appearance="outline" class="w60">
                          <mat-label>{{'VAT Number'|translate}}</mat-label>
                          <input type="text" matInput [(ngModel)]="document['vat']" (click)="dropdown=false;">
                          <!--<mat-hint>Error</mat-hint>-->
                        </mat-form-field>
                        <button class="action" (click)="validateVAT()">{{'Validate'|translate}}</button>
                    </div>

                    <div class="send_address">
                        <label class="switch">
                            <input type="checkbox" [(ngModel)]="document['address2_enabled']" (change)="save=false" />
                            <span class="slider round"></span>
                        </label>
                        <div class="label atSwitch">{{'Use different address to send document'|translate}}</div>
                        <div class="inline w100" style="margin-top:18px;" *ngIf="document['address2_enabled']">
                            <mat-form-field appearance="outline">
                              <mat-label>{{'Send document to...'|translate}}</mat-label>
                              <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" class="alternativeaddress" [(ngModel)]="document['address2']" (click)="dropdown=false;" (change)="save=false"></textarea>
                              <!--<mat-hint>Error</mat-hint>-->
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="email_address">
                        <label class="switch">
                            <input type="checkbox" [(ngModel)]="document['addressEmail_enabled']" (change)="save=false" />
                            <span class="slider round"></span>
                        </label>
                        <div class="label atSwitch">{{'Send document via email'|translate}}</div>
                        <div class="inline w100" style="margin-top:18px;" *ngIf="document['addressEmail_enabled']">               
                            <mat-form-field appearance="outline">
                              <mat-label>{{'Email address'|translate}}</mat-label>
                              <input type="text" matInput [(ngModel)]="document['email']">
                              <fa-icon *ngIf="document['customer_id']!=''" style="position:absolute;right:0px;" [icon]="['fad', 'at']" (click)="getEmail()"></fa-icon>
                              <!--<mat-hint>Error</mat-hint>-->
                            </mat-form-field>
                          </div>
                    </div>
                </div>

                <div class="head_right w45" style="max-width: 250px">
                    <div class="inline w100" style="float:right;margin-top: 18px;">
                        <mat-form-field appearance="outline">
                          <mat-label>{{'Document date'|translate}}</mat-label>
                          <mat-icon (click)="showDatePicker()"></mat-icon>
                          <input matInput [matDatepicker]="picker1" [(ngModel)]="document['document_date']">
                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                          <mat-datepicker #picker1></mat-datepicker>
                          <!--<mat-hint>Error</mat-hint>-->                    
                        </mat-form-field>
                    </div>

                    <div class="inline w100" style="float:right;">
                        <mat-form-field appearance="outline">
                          <mat-label>{{'Service date'|translate}}</mat-label>
                          <mat-icon (click)="showDatePicker()"></mat-icon>
                          <input matInput [matDatepicker]="picker2" [(ngModel)]="document['document_service']">
                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                          <mat-datepicker #picker2></mat-datepicker>
                          <!--<mat-hint>Error</mat-hint>-->                    
                        </mat-form-field>
                    </div>

                    <div class="inline w100" style="float:right;">
                        <mat-form-field appearance="outline">
                          <mat-label>{{'Payment date'|translate}}</mat-label>
                          <mat-icon (click)="showDatePicker()"></mat-icon>
                          <fa-icon class="dateSettings left" style="margin-left:-50px;" [icon]="['fad', 'gear']" (click)="dateEdit=!dateEdit"></fa-icon>
                          <input matInput [matDatepicker]="picker3" [(ngModel)]="document['document_payment']">
                          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                          <mat-datepicker #picker3></mat-datepicker>
                          <!--<mat-hint>Error</mat-hint>-->                    
                        </mat-form-field>
                    </div>

                    <div class="inline w100">               
                        <mat-form-field appearance="outline">
                          <mat-label>{{'Place of service'|translate}}</mat-label>
                          <input type="text" matInput [(ngModel)]="document['place']" (click)="dropdown=false;">
                          <!--<mat-hint>Error</mat-hint>-->
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div id="elements" (click)="dropdown=false;">
                <div class="elements_line_header">
                    <div *ngIf="colScreen['pos']" class="pos">{{'pos'|translate}}</div>
                    <div *ngIf="colScreen['sku']" class="sku">{{'sku'|translate}}</div>
                    <div class="description">{{'description'|translate}}</div>        
                    <div *ngIf="colScreen['qty']" class="qty">{{'qty'|translate}}</div>
                    <div *ngIf="colScreen['unit']" class="unit">{{'unit'|translate}}</div>
                    <div *ngIf="colScreen['tax']" class="tax">{{'tax'|translate}}</div>
                    <div *ngIf="colScreen['price']" class="price">{{'price'|translate}}</div>
                    <div *ngIf="colScreen['priceTax']" class="priceTax">{{'priceTax'|translate}}</div>
                    <div *ngIf="colScreen['discount']" class="discount">{{'discount'|translate}}</div>
                    <div *ngIf="colScreen['priceWithDiscount']" class="priceWithDiscount">{{'priceWithDiscount'|translate}}</div>
                    <div *ngIf="colScreen['priceTaxWithDiscount']" class="priceTaxWithDiscount">{{'priceTaxWithDiscount'|translate}}</div>
                    <div *ngIf="colScreen['total']" class="total">{{'total'|translate}}</div>
                    <div *ngIf="colScreen['totalWithTax']" class="totalWithTax">{{'totalWithTax'|translate}}</div>
                    <!--div class="more"></div---->
                </div>
                
                <div *ngFor="let item of current_document_lines_formated; let i=index;" class="elements_line">
                    <div *ngIf="colScreen['pos']" class="pos"><input name="pos" type="text" [(ngModel)]="item.pos" col="0" /></div>
                    <div *ngIf="colScreen['sku']" class="sku"><input name="sku" type="text" [(ngModel)]="item.sku" col="1" /></div>
                    <div class="description"><input name="description" type="text" [(ngModel)]="item.description" col="2" /></div>        
                    <div *ngIf="colScreen['qty']" class="qty"><input name="qty"  class="input_right" type="text" [(ngModel)]="item.qty" col="3" /></div>
                    <div *ngIf="colScreen['unit']" class="unit"><input name="unit" type="text" [(ngModel)]="item.unit" col="4" /></div>
                    <div *ngIf="colScreen['tax']" class="tax"><input name="tax" class="input_right" type="text" [(ngModel)]="item.tax" col="5" /></div>
                    <div *ngIf="colScreen['price']" class="price" [ngClass]="{'selected' : item.selectedPrice == 'price'}"><input name="price" class="input_right" type="text" [(ngModel)]="item.price" col="6" /></div>
                    <div *ngIf="colScreen['priceTax']" class="priceTax" [ngClass]="{'selected' : item.selectedPrice == 'priceTax'}"><input name="priceTax" class="input_right" type="text" [(ngModel)]="item.priceTax" col="7" /></div>
                    <div *ngIf="colScreen['discount']" class="discount" [ngClass]="{'selected' : item.selectedDiscount == 'discount'}"><input name="discount" class="input_right" type="text" [(ngModel)]="item.discount" col="8" /></div>
                    <div *ngIf="colScreen['priceWithDiscount']" class="priceWithDiscount" [ngClass]="{'selected' : item.selectedDiscount == 'priceWithDiscount'}"><input name="priceWithDiscount" class="input_right" type="text" [(ngModel)]="item.priceWithDiscount" col="9" /></div>
                    <div *ngIf="colScreen['priceTaxWithDiscount']" class="priceTaxWithDiscount" [ngClass]="{'selected' : item.selectedDiscount == 'priceTaxWithDiscount'}"><input name="priceTaxWithDiscount" class="input_right" type="text" [(ngModel)]="item.priceTaxWithDiscount" col="10" /></div>
                    <div *ngIf="colScreen['total']" class="total" [ngClass]="{'selected' : item.selectedDiscount == 'total'}"><input name="total" class="input_right" type="text" [(ngModel)]="item.total" col="11" /></div>
                    <div *ngIf="colScreen['totalWithTax']" class="totalWithTax" [ngClass]="{'selected' : item.selectedDiscount == 'totalWithTax'}"><input name="totalWithTax" class="input_right" type="text" [(ngModel)]="item.totalWithTax" col="12" /></div>
                    <!--div class="more"></div---->
                    <div class="delete_line"><fa-icon class="icon_small" [icon]="['fad', 'times']" (click)="delete_line(i)"></fa-icon></div>
                </div>                               
            </div>

            <ng-container *ngIf="current_document_lines_formated[(current_document_lines_formated).length-1]!=undefined">
                <div *ngIf="current_document_lines_formated[(current_document_lines_formated).length-1]['description']!=''" (click)="addNewLine()" class="add_new_line">
                    <fa-icon [icon]="['fad', 'plus-circle']"></fa-icon>{{'Add new line'|translate}}                
                </div>
            </ng-container>

            <div class="totals">
                <div class="totals_left">

                    <div class="inline w100">
                        <mat-form-field appearance="outline">
                          <mat-label>{{'Document comment...'|translate}}</mat-label>
                          <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" [(ngModel)]="document['document_comment']" (click)="dropdown=false;" (change)="save=false"></textarea>
                          <!--<mat-hint>Error</mat-hint>-->
                        </mat-form-field>
                      </div>
                </div>

                <div class="totals_right">
                    <div class="total_item">
                        <input *ngIf="summary_formated[0]" readonly type="text" [(ngModel)]="summary_formated[0]['total_without_tax']">   
                        <div class="label">{{'Total without tax'|translate}}</div>                    
                    </div>
                    <div class="total_item">
                        <input *ngIf="summary_formated[0]" readonly type="text" [(ngModel)]="summary_formated[0]['tax_total']">   
                        <div class="label">{{'Tax'|translate}}
                            <fa-icon class="tax_icon" (click)="taxSpecificationWindow=!taxSpecificationWindow" [icon]="['fad', 'circle-info']"></fa-icon>  
                        </div>           
                    </div>
                    <div class="total_item">
                        <input *ngIf="summary_formated[0]" readonly type="text" [(ngModel)]="summary_formated[0]['total_with_tax']">   
                        <div class="label">{{'Total with tax'|translate}}</div>                    
                    </div>
                    <div class="total_item" [ngClass]="{'red' : paymentCondition == 0, 'yellow': paymentCondition == 1, 'green': paymentCondition == 2}">
                        <input *ngIf="summary_formated[0]" readonly type="text" [(ngModel)]="summary_formated[0]['for_payment']">   
                        <div class="label">{{'For payment'|translate}}</div>                           
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modalActions" (click)="dropdown=false;">        
        <button *ngIf="!save" (click)="save_document()" class="accept right">{{'Save'|translate}}</button>
        <!--<button *ngIf="entity==LastEntity" class="delete" (click)="delete_document()">{{'Delete'|translate}}</button>-->
        <button class="action left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
        <button *ngIf="entity" class="action right" (click)="duplicateErpDocument()">{{'Duplicate'|translate}}</button>
        <!--<button class="submit" (click)="load_document()">{{'Load'|translate}}</button>-->
    </div>
</div>

<div *ngIf="settingsEdit" class="settingsEdit">
    <div class="inner_wrapper mat-dialog-container">
        <div class="header">
            <div class="left">  
                <h1>{{'Settings'|translate}}</h1>
            </div>
        </div>
        <div class="inner_scroll body">    
            
            
            <div class="tabs tabsHeader">
				<div class="tab" [ngClass]="{'active':selectedTab == 'settings'}" (click)="switchTab('settings')">
					<span>{{'Settings'|translate}}</span>
				</div>
                <div class="tab" [ngClass]="{'active':selectedTab == 'rowFields'}" (click)="switchTab('rowFields')">
					<span>{{'Row Fields'|translate}}</span>
				</div>				
				<div class="tab" [ngClass]="{'active':selectedTab == 'emailtemplate'}" (click)="switchTab('emailtemplate')">
					<span>{{'Email Template'|translate}}</span>
				</div>				
			</div>

            <div id="rowFields" *ngIf="selectedTab=='rowFields'" class="tab-container">
                <div class="block">
                    <ng-container *ngFor='let item of colScreen | keyvalue; let i = index'>
                        <div *ngIf="i==0" class="text">
                            {{'Screen | PDF'|translate}}
                        </div>
                        <div *ngIf="i==0" class="text">
                            {{'Screen | PDF'|translate}} 
                        </div>                        
                        <div class="field">
                            <label class="switch">
                                <input type="checkbox" [(ngModel)]="colScreen[item.key]" (click)="saveSettings();">
                                <span class="slider round"></span>
                            </label>
                            <label class="switch">
                                <input type="checkbox" [(ngModel)]="colPDF[item.key]" (click)="saveSettings();">
                                <span class="slider round"></span>
                            </label>
                            <div class="label label-checkbox">{{item.key|translate}}</div>				
                        </div>
                    </ng-container>
                </div>
            </div>

            <div id="settings" *ngIf="selectedTab=='settings'" class="tab-container">
                <div class="block">
                    <div class="inline w80">
                        <mat-form-field appearance="outline">
                        <mat-label>{{'Style and language'|translate}}</mat-label>
                        <mat-select name="lang" [(ngModel)]="document['lang']" (change)="save_document_language()">
                            <mat-option value="si">{{'Slovenian'|translate}}</mat-option>
                            <mat-option value="en">{{'English'|translate}}</mat-option>
                        </mat-select>
                        <!--<mat-hint">Error</mat-hint>-->
                        </mat-form-field>
                    </div>

                    <div class="inline w80 wPadding">
                        <mat-form-field appearance="outline">
                        <mat-label>{{'Default Tax Rate'|translate}}</mat-label>
                        <input type="text" matInput [(ngModel)]="defaultTaxRate" (click)="dropdown=false;">
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div id="emailtemplate" *ngIf="selectedTab=='emailtemplate'" class="tab-container">
                <div class="block">
                    email template
                </div>
            </div>

            

        </div>
        <div class="modalActions">
            <button class="action left"  (click)="settingsEdit=!settingsEdit">{{'Close'|translate}}</button> 
        </div>
    </div>
</div>

<div *ngIf="dateEdit" class="dateEdit">
    <div class="inner_wrapper">
        <div class="inner_scroll body">

            <div class="inline w100 wPadding">
                <mat-form-field appearance="outline">
                  <mat-label>{{'Recurring document'|translate}}</mat-label>
                  <mat-select name="options" id="options">
                        <mat-option value="never">{{'never'|translate}}</mat-option>
                        <mat-option value="1week">{{'1 week'|translate}}</mat-option>
                        <mat-option value="2week">{{'2 weeks'|translate}}</mat-option>
                        <mat-option value="1month">{{'1 month'|translate}}</mat-option>
                        <mat-option value="2months">{{'2 months'|translate}}</mat-option>
                        <mat-option value="3months">{{'3 months'|translate}}</mat-option>
                        <mat-option value="6months">{{'6 months'|translate}}</mat-option>
                        <mat-option value="1year">{{'1 year'|translate}}</mat-option>
                        <mat-option value="2years">{{'2 years'|translate}}</mat-option>
                        <mat-option value="3years">{{'3 years'|translate}}</mat-option>                  
                    </mat-select>
                <!--<mat-hint">Error</mat-hint>-->
                </mat-form-field>
              </div>
        </div>
        <div class="modalActions">
            <button class="action left"  (click)="dateEdit=!dateEdit">{{'Close'|translate}}</button> 
        </div>
    </div>
</div>

<div *ngIf="swapDocumentWindow" class="swapDocumentWindow mat-dialog-container">
    <div class="inner_wrapper">
        <div class="header">
            <div class="left">
                <h1>{{'Swap document'|translate}}</h1>
            </div>
        </div>
        <div class="inner_scroll body">

            <div class="inline w100 wPadding">
                <mat-form-field appearance="outline">
                  <mat-label>{{'Swap document to'|translate}}</mat-label>
                  <mat-select name="swapTo" id="swapTo" [(ngModel)]="swapTo">
                    <mat-option value="invoice">{{'Invoice'|translate}}</mat-option>
                    <mat-option value="creditnote">{{'Credit note'|translate}}</mat-option>
                  </mat-select>
                <!--<mat-hint">Error</mat-hint>-->
                </mat-form-field>
            </div>

        </div>
        <div class="modalActions">
            <button class="action left"  (click)="swapDocumentWindow=!swapDocumentWindow">{{'Close'|translate}}</button> 
            <button class="action right"  (click)="swapDocumentWindow=!swapDocumentWindow; swapDocument();">{{'Swap'|translate}}</button> 
        </div>
    </div>
</div>

<div *ngIf="taxSpecificationWindow" class="taxSpecificationWindow">
    <div class="inner_wrapper">
        <div class="inner_scroll">
            <div class="taxIndex column" style="font-weight: 600;">{{'Tax'|translate}}</div>
            <div class="taxBase column" style="font-weight: 600;">{{'Base'|translate}}</div>
            <div class="taxValue column" style="font-weight: 600;">{{'Value'|translate}}</div>
            <ng-container *ngFor="let taxItem of taxSpecification | keyvalue">
                <!--Key: <b>{{item.key}}</b> and Value: <b>{{item.value}}</b>-->
                <div class="taxIndex">{{taxItem.value.index}} %</div>
                <div class="taxBase">{{taxItem.value.base}} EUR</div>
                <div class="taxValue">{{taxItem.value.tax}} EUR</div>
            </ng-container>
        </div>
        <div class="line actions">
            <button class="cancel"  (click)="taxSpecificationWindow=!taxSpecificationWindow">{{'Close'|translate}}</button>
        </div>
    </div>
</div>

<div *ngIf="statisticsWindow" class="statisticsWindow mat-dialog-container">
    <div class="inner_wrapper">
        <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [ngStyle]="{'border-color':documentColorHelper}">
            <div class="left">
                <h1>
                    {{'Time activity statistic'|translate}} | {{type|translate}}            
                </h1>
            </div>
        </div>
        <div class="inner_scroll body">            
            <div class="line_statistics">	
                <div class="block">
                    <div class="w100 blockTitle">{{'Total time tracking usage'|translate}}</div>
                    <div class="wMonth left">
                        <span class="title">{{priorMonth}}<br/><span class="legend">{{'hours'|translate}}</span></span><br />
                        <div class="pie animate no-round" style="--p:{{time_tracking_stat['percentMonthPrior_Pie']}};--c:{{time_tracking_stat['percentMonthPrior_PieColor']}};--w:120px;--b:10px">{{time_tracking_stat['percentMonthPrior']}}%<br /><span>{{time_tracking_stat['rows_prev_month'][0]['minutes']}}/{{time_tracking_stat['defaults']['hoursMonth']}}</span></div>
                    </div>
                    <div class="wMonth left">
                        <span class="title">{{month}}<br/><span class="legend">{{'hours'|translate}}</span></span><br />
                        <div class="pie animate no-round" style="--p:{{time_tracking_stat['percentMonth_Pie']}};--c:{{time_tracking_stat['percentMonth_PieColor']}};--w:120px;--b:10px">{{time_tracking_stat['percentMonth']}}%<br /><span>{{time_tracking_stat['rows_current_month'][0]['minutes']}}/{{time_tracking_stat['defaults']['hoursMonth']}}</span></div>
                    </div>
                    <div class="wYear left">
                        <span class="title">{{year}}<br/><span class="legend">{{'hours'|translate}}</span></span><br/>
                        <div class="pie animate no-round" style="--p:{{time_tracking_stat['percentYear_Pie']}};--c:{{time_tracking_stat['percentYear_PieColor']}};--w:120px;--b:10px">{{time_tracking_stat['percentYear']}}%<br /><span>{{time_tracking_stat['rows_current_year'][0]['minutes']}}/{{time_tracking_stat['defaults']['hoursYear']}}</span></div>
                    </div>						
                </div>
                <div class="block">
                    <div class="w100 blockTitle">{{'Detailed time tracking usage'|translate}}</div>
                    <div class="wMonth left">
                        <div class="timeTrackingItemRecap" *ngFor="let item of time_tracking_stat_helper['rows_prev_month_by_categories']">
                            <span class="hours">{{item['timeHours']}} {{'hours'|translate}}</span>
                            <span class="description">
                                <ng-container *ngFor="let subItem of item['category']">
                                    <ng-container *ngIf="subItem.checked">
                                        <span>{{subItem.description}}</span>
                                    </ng-container>
                                </ng-container>
                            </span>
                        </div>
                    </div>
                    <div class="wMonth left">
                        <div class="timeTrackingItemRecap" *ngFor="let item of time_tracking_stat_helper['rows_current_month_by_categories']">
                            <span class="hours">{{item['timeHours']}} {{'hours'|translate}}</span>
                            <span class="description">
                                <ng-container *ngFor="let subItem of item['category']">
                                    <ng-container *ngIf="subItem.checked">
                                        <span>{{subItem.description}} {{'hours'|translate}}</span>
                                    </ng-container>
                                </ng-container>
                            </span>
                        </div>
                    </div>
                    <div class="wYear left">
                        <div class="timeTrackingItemRecap" *ngFor="let item of time_tracking_stat_helper['rows_current_year_by_categories']">
                            <span class="hours">{{item['timeHours']}} {{'hours'|translate}}</span>
                            <span class="description">
                                <ng-container *ngFor="let subItem of item['category']">
                                    <ng-container *ngIf="subItem.checked">
                                        <span>{{subItem.description}}</span>
                                    </ng-container>
                                </ng-container>
                            </span>
                        </div>
                    </div>						
                </div>
            </div>
        </div>
        <div class="modalActions">
            <button class="action left"  (click)="statisticsWindow=!statisticsWindow">{{'Close'|translate}}</button>             
        </div>
    </div>
</div>

<div *ngIf="linkWindow" class="linkWindow mat-dialog-container">
    <div class="inner_wrapper">
        <div class="header">
            <div class="left">
                <h1>{{'Linking'|translate}}</h1>
            </div>
        </div>
        <div class="inner_scroll body">

            <div class="inline w100 wPadding">
                <mat-form-field appearance="outline">
                  <mat-label>{{'Link to activity'|translate}}</mat-label>
                  <input [(ngModel)]="document['activity_id']" type="hidden" />
                  <input type="text" class="stickToRight" formControlName="activity" matInput [(ngModel)]="document['activity']" (click)="selectActivity()" readonly />
                  <fa-icon class="right" [icon]="['fad', 'list']" (click)="selectActivity()"></fa-icon>
                  <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>

        </div>
        <div class="modalActions">
            <button class="action left"  (click)="linkWindow=!linkWindow">{{'Close'|translate}}</button>
            <button class="action right"  (click)="removeActivity()">{{'Clear'|translate}}</button>                          
        </div>
    </div>
</div>