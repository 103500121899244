import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../services/data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  email: string;
}

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.css']
})
export class UserSelectComponent implements OnInit {

	constructor(
		private data_svc: DataService,
    	public dialogRef: MatDialogRef<UserSelectComponent>,
    	@Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

	public items;

	ngOnInit(): void {
		var parameters = { 'page' : 0, 'items' : 25, 'order' : 'username', search: '', 'orderby' : 'asc' };
		this.getUsers(parameters);
	}

	getUsers(parameters) {
		parameters['field'] = 'enabled';
		parameters['value'] = 1;
		
		this.data_svc.selectUser(parameters).subscribe(
			(res: Response) => {
				this.items = (<any>res).rows;
				console.log(this.items)
			}
		);
	} 

	onSearchUser(event:any) { 		
		var parameters = { 'page' : 0, 'items' : 25, 'order' : 'username', search: event.target.value };
		this.getUsers(parameters);
	}

	onSelect(selection) {		
		this.dialogRef.close(selection);
	}

}