import { Component, OnInit, ElementRef } from '@angular/core';
import { DataService } from '../../services/data.service';
import { GlobalVariables } from '../../services/data.globals';
import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TopComponent } from '../../top/top.component';
import { ContactEditComponent } from '../contact-edit/contact-edit.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SafeKeyedRead } from '@angular/compiler';
import { TranslateService } from '@ngx-translate/core';
import { ActivityComponent } from '../../activities/activity/activity.component';
import { AssignUserGroupComponent } from '../../activities/activity/assign-user-group/assign-user-group.component';
import { ModalComponent } from '../../modal/modal.component';

import * as echarts from 'echarts';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})

export class CustomerComponent implements OnInit {

	public id = null;
	public entity = null;
	private sub : any;
	public parameters = {};
	public customer = [];
	public categories = [];
	public contacts = [];
	public canEdit = false;
	public statuses = [];
	public disableDelete = true;
	public activity_items = [];
	public activity_count = 0;
	public document_items = [];
	public document_count = 0;
	public stats = []; 
	public custom_fields = [];
	public custom_field_data = [];
	public showall = false;
	public comment = "";
	public comment_entity = null;
	public vatExist = false;
	public nameExist = false;
	public similarCompany = "";
	public members: any = [];
	public member_groups: any = [];
	timeout: any = null;
	public default_category = 0;
	public customized_data_collapse = true;
	public key_customer_administrator_collapse = true;
	public timetracking_collapse = true;
	public selectedTab = "summary";

	public time_tracking_stat = [];
	public month;
	public priorMonth;
	public year;

	public colors = ['#f93939','#f14881','#8464c2','#364046','#9c24ac','#3453b1','#0097c8','#009789','#4ab05a','#8cc358','#ffe43f','#ffbf36','#ff9529','#ff6b2c','#724e45','#878988','#5e7e8a']
	
	private myChart: any = null; //graf



	CustomerForm: UntypedFormGroup;

	constructor(
		private data: DataService,
		private route: ActivatedRoute,
		public dialog: MatDialog,
		public assignUserGroupDialog: MatDialog,
		private router: Router,
		public dialogRef: MatDialogRef<CustomerComponent>,
		public translate: TranslateService,
		private elm:ElementRef //graf
	) {
		this.CustomerForm = this.createFormGroup();
		translate.addLangs(['en', 'si']);
	}
	

	

	createFormGroup() {
		return new UntypedFormGroup({
			entity: new UntypedFormControl(),
			vat: new UntypedFormControl(),
			company: new UntypedFormControl(),
			address1: new UntypedFormControl(),
			address2: new UntypedFormControl(),
			post: new UntypedFormControl(),
			country: new UntypedFormControl(),
			phone: new UntypedFormControl(),
			mobile: new UntypedFormControl(),
			email: new UntypedFormControl(),
			url: new UntypedFormControl(),
			status: new UntypedFormControl(),
			city: new UntypedFormControl(),
			default_activity_id: new UntypedFormControl(),
			client_administartors: new UntypedFormControl(),
			allowed_emails: new UntypedFormControl(),
			default_priority_id: new UntypedFormControl(),
			custom_field_data: new UntypedFormControl(),
			hoursMonth: new UntypedFormControl(),
			hoursYear: new UntypedFormControl(),
			afterHourPrice: new UntypedFormControl(),
			timeTrackingComment: new UntypedFormControl()
		});
	}

	minToHM(totalMinutes) {
		var hours = Math.floor(totalMinutes / 60);          
		var minutes = totalMinutes % 60;		

		var formattedhours = ("0" + hours).slice(-2);
		var formattedminutes = ("0" + minutes).slice(-2);

		return formattedhours + ":" + formattedminutes;
	}


	getCustomFields() {
		/* Get Fields */
		this.data.getCustomFields(this.parameters).subscribe(
			(res: Response) => {
				this.custom_fields = (<any>res).rows;
								
				for (var i=0; i<this.custom_fields.length; i++) {
					this.custom_field_data[this.custom_fields[i].entity] = '';
				}
				
				if (this.customer['custom_fields'] != null) {
					var json_helper = JSON.parse(this.customer['custom_fields']);
					for (var i=0; i<json_helper.length; i++) {
						if (json_helper[i] != null) this.custom_field_data[i] = json_helper[i];						
					}							
				}
				
			}
		);
	}

	switchTab(value) {
		this.selectedTab = value;
	}

	saveStickyNote() {
		clearTimeout(this.timeout);
		var comment_to_save = this.comment;
    	var $this = this;
		var entity = this.customer['entity'];		
		this.timeout = setTimeout(function () {      		  
		  $this.saveComment(entity, comment_to_save);
		}, 1000);
	}
	
	saveComment(entity, comment) {
		//console.log(entity);
		//console.log(comment);

		var comment_entity = this.comment_entity == '' ? null : this.comment_entity;

		var param = { entity: comment_entity, parent_object: 'customer', parent_object_id: entity, object: 'customer', object_id: entity, comment: comment };		
		this.data.saveComment( { data : param } ).subscribe((res: Response) => {
		  //console.log(<any>res);
		  if (res['callback'] != undefined) this.comment_entity = <any>res['callback']['entity'];
		});		
	}
	

	onTimeTrackingClick() {		
		this.switchTab('calculations');

		/* prepare container */
		var statWidth = ($('.inner_content').width()-48)/(Object.keys(this.stats['timetracking_stats']).length);		
		$('.ttGraphs').width(statWidth);

		setTimeout(() => {
			/* prepare data */
			for(let key in this.stats['timetracking_stats']) {			
				let year = this.stats['timetracking_stats'][key];
				var legendHelper_data = [];
				var seriesHelper_data = [];
				for (var i=0; i<year.length; i++) {
					legendHelper_data.push(year[i].monthname);
					let valueHelper = Math.ceil(parseFloat(year[i].totalMinutes)/6)/10;
					let value = {name: year[i].monthname, value:valueHelper, itemStyle: {color: this.colors[i]}};
					seriesHelper_data.push(value);
				}
				let option = {
					title: { left: 'center',	top: 5,	textStyle: { color: '#666', fontWeight: 'normal', fontSize: '12'} },
					tooltip: { trigger: 'item', formatter: "{a} <br/>{b}: {c} ({d}%)"},
					legend: { align: 'auto', bottom: 0, data: [] },
					series: [
						{				
							name:'Month',		
							type:'pie',
							radius : '75%',
							center: ['50%', '50%'],
							roseType: 'radius',
							avoidLabelOverlap: false,
							label: { normal: { textStyle: { color: 'rgba(44, 46, 79, 0.8)' } } },
							labelLine: { normal: { lineStyle: { color: 'rgba(44, 46, 79, 0.3)' }, smooth: 0.2, length: 5, length2: 10 } },
							itemStyle: { normal: { color: '#848AF0' } },			
							animationType: 'scale',
							animationEasing: 'elasticOut',
							animationDelay: function (idx) {
								return Math.random() * 200;
							},
							data: seriesHelper_data
						}
					]
				};
				this.myChart = echarts.init((document.getElementById('cart_'+key)) as any);
				this.myChart.setOption(option);			
			}			
		}, 500);		
	}

	OnGraphMouseEnter(graphId, dataId) {
		let myChart = echarts.init((document.getElementById('cart_'+graphId)) as any);
		myChart.dispatchAction({
			type: 'highlight',
			seriesIndex: 0,
			dataIndex: dataId
		});
	}

	OnGraphMouseLeave(graphId, dataId) {
		let myChart = echarts.init((document.getElementById('cart_'+graphId)) as any);
		myChart.dispatchAction({
			type: 'downplay',
			seriesIndex: 0,
			dataIndex: dataId
		});
	}

	formatTitle(text) {
		return text.replace('hours_', '');
	}

	ngOnInit(): void {

	  var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
	  this.translate.use(lang);		

		this.showall = localStorage.getItem('customer_showhidefinishedactivities') == 'true' ? true : false; 

		//alert(this.entity);
	    if (this.entity != null) {			
			this.getCustomer(this.entity);			
    	} else {    
			this.sub = this.route.params.subscribe(params => {					
				if (this.id == null) {									
					/*
					$('.tabs .tab, .tab-child').removeClass('active');
					$("#edit").addClass('active');	
					$(".tab[rel=edit]").addClass('active');	
					*/
					this.selectedTab = 'edit';
					this.getCustomFields();
					this.customer = [];
				} else {					
					if (1==1) //console.log("Load customer: " + this.id);			
					this.getCustomer(this.id);
				}
			}); 
		}

		////console.log(this.TopComponent.topCustomItems);
		/* get available statuses */
		this.data.getCustomerStatuses(this.parameters).subscribe(
			(res: Response) => {
				this.statuses = (<any>res).rows;
				////console.log(this.statuses);
			}
		);

		$(document).on('click', '#select_all', function() {
        	var status = $(this).prop('checked');
        	$('.col input[type=checkbox]').attr('checked', status);
    	});

















	  /*********************************************************/
      /*********************************************************/
      /* Area */
	  
		




































	}

	editContact(entity) {		
	    var editDialog = this.dialog.open(ContactEditComponent, { /*width: '50vw'*/ });
    	editDialog.componentInstance.entity = entity;
    	editDialog.componentInstance.customer_id = this.customer['entity']; //this.parameters['id'];
    	editDialog.afterClosed().subscribe(result => {
		  this.getCustomer(this.parameters['id']);
		});
	}


	safe(input) {
		let regex = /[^A-Za-z0-9]/g;
  		return input.replace(regex, "_");
	}

	isJson(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	checkboxfield_click(entity, option, state) {
		if (this.custom_field_data[entity] == '') this.custom_field_data[entity] = JSON.parse('{}');		
		//console.log(this.custom_field_data);
		if (this.custom_field_data[entity] == undefined) { this.custom_field_data[entity] = []; }
		this.custom_field_data[entity][option] = state.target.checked;
		//console.log(this.custom_field_data);
		
		//state.target.checked
		/*
		if (this.custom_field_data[entity] == '') this.custom_field_data[entity] = '{}';
		var helper = JSON.parse(this.custom_field_data[entity]);
		helper[this.safe(option)] = state.target.checked;
		//console.log(helper);
		this.custom_field_data[entity] = JSON.stringify(helper);
		*/
	}
	
	getCustomerStatistics(customer) {
		this.data.getTimeTrackStat({ 'customer_id' : customer, 'month':new Date().getMonth() , 'year':new Date().getFullYear() }).subscribe(
			(res: Response) => {
				this.time_tracking_stat = <any>res;	

				const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
				const d = new Date();
				this.month = monthNames[d.getMonth()]
				this.year = d.getFullYear();
				this.priorMonth = monthNames[d.getMonth()-1 < 0 ? 11 : d.getMonth()-1];
				
				this.time_tracking_stat['rows_current_month'][0]['minutes'] = this.time_tracking_stat['rows_current_month'][0]['minutes'] != null ? Math.round((this.time_tracking_stat['rows_current_month'][0]['minutes'])/60*10)/10 : 0;
				this.time_tracking_stat['rows_current_year'][0]['minutes'] = this.time_tracking_stat['rows_current_year'][0]['minutes'] != null ? Math.round((this.time_tracking_stat['rows_current_year'][0]['minutes'])/60*10)/10 : 0;
				this.time_tracking_stat['rows_prev_month'][0]['minutes'] = this.time_tracking_stat['rows_prev_month'][0]['minutes'] != null ? Math.round((this.time_tracking_stat['rows_prev_month'][0]['minutes'])/60*10)/10 : 0;
				this.time_tracking_stat['rows_year_to_date_month'][0]['minutes'] = this.time_tracking_stat['rows_year_to_date_month'][0]['minutes'] != null ? Math.round((this.time_tracking_stat['rows_year_to_date_month'][0]['minutes'])/60*10)/10 : 0;
				
				this.time_tracking_stat['defaults']['hoursMonth'] = this.time_tracking_stat['defaults'][0]['hoursMonth'] != null ? Math.round((this.time_tracking_stat['defaults'][0]['hoursMonth'])*10)/10 : 'n/a';
				this.time_tracking_stat['defaults']['hoursYear'] = this.time_tracking_stat['defaults'][0]['hoursYear'] != null ? Math.round((this.time_tracking_stat['defaults'][0]['hoursYear'])*10)/10 : 'n/a';
				this.time_tracking_stat['defaults']['afterHourPrice'] = this.time_tracking_stat['defaults'][0]['afterHourPrice'] != null ? Math.round((this.time_tracking_stat['defaults'][0]['afterHourPrice'])*100)/100 : 'n/a';					

				this.time_tracking_stat['percentMonth'] = (this.time_tracking_stat['rows_current_month'][0]['minutes'] / this.time_tracking_stat['defaults']['hoursMonth'] * 100).toFixed(0);
				this.time_tracking_stat['percentMonthPrior'] = (this.time_tracking_stat['rows_prev_month'][0]['minutes'] / this.time_tracking_stat['defaults']['hoursMonth'] * 100).toFixed(0);
				this.time_tracking_stat['percentYear'] = (this.time_tracking_stat['rows_year_to_date_month'][0]['minutes'] / this.time_tracking_stat['defaults']['hoursYear'] * 100).toFixed(0);

				this.time_tracking_stat['percentMonth_Pie'] = this.time_tracking_stat['percentMonth'] > 100 ? 100 : this.time_tracking_stat['percentMonth'];
				this.time_tracking_stat['percentMonthPrior_Pie'] = this.time_tracking_stat['percentMonthPrior'] > 100 ? 100 : this.time_tracking_stat['percentMonthPrior'];
				this.time_tracking_stat['percentYear_Pie'] = this.time_tracking_stat['percentYear'] > 100 ? 100 : this.time_tracking_stat['percentYear'];

				this.time_tracking_stat['percentMonth_PieColor'] = this.time_tracking_stat['percentMonth'] > 50 ? '#f5a742' : 'lightgreen';				
				this.time_tracking_stat['percentMonthPrior_PieColor'] = this.time_tracking_stat['percentMonthPrior'] > 50 ? '#f5a742' : 'lightgreen';				
				this.time_tracking_stat['percentYear_PieColor'] = this.time_tracking_stat['percentYear'] > 50 ? '#f5a742' : 'lightgreen';

				this.time_tracking_stat['percentMonth_PieColor'] = this.time_tracking_stat['percentMonth'] > 50 ? '#990000' : this.time_tracking_stat['percentMonth_PieColor'];				
				this.time_tracking_stat['percentMonthPrior_PieColor'] = this.time_tracking_stat['percentMonthPrior'] > 50 ? '#990000' : this.time_tracking_stat['percentMonthPrior_PieColor'];				
				this.time_tracking_stat['percentYear_PieColor'] = this.time_tracking_stat['percentYear'] > 50 ? '#990000' : this.time_tracking_stat['percentYear_PieColor'];

			}
		);				
	}

	getLastActivities5() {
		this.getActivitiesByCompanyId(this.entity, 5, null, null);
	}

	getLastActivities25() {
		this.getActivitiesByCompanyId(this.entity, 25, null, null);
	}

	getLastActivities100() {
		this.getActivitiesByCompanyId(this.entity, 100, null, null);
	}

	getLastActivitiesLastWeek() {		
		var to = (new Date().toISOString().slice(0, 19).replace('T', ' ')).split(" ");
		var fromHelper = new Date();
		fromHelper.setDate(fromHelper.getDate() - 7);
		var from = (fromHelper.toISOString().slice(0, 19).replace('T', ' ')).split(" ");		
		this.getActivitiesByCompanyId(this.entity, 100, from[0], to[0]+" 23:59:59");
	}

	getLastActivitiesLastMonth() {
		var to = (new Date().toISOString().slice(0, 19).replace('T', ' ')).split(" ");
		var fromHelper = new Date();
		fromHelper.setDate(fromHelper.getDate() - 30);
		var from = (fromHelper.toISOString().slice(0, 19).replace('T', ' ')).split(" ");		
		this.getActivitiesByCompanyId(this.entity, 100, from[0], to[0]+" 23:59:59");
	}

	getActivitiesByCompanyId(customer_id, items = 5, from = null, to = null) {		
		//console.log(from, to);		
		this.data.getActivitiesByCompanyId({'customer_id':customer_id, 'items':items, 'filter_created_from_date_raw':from, 'filter_created_to_date_raw':to}).subscribe(
			(res: Response) => {
				console.log((<any>res).rows);
				this.activity_items = (<any>res).rows;
				this.activity_count = (<any>res).count_rows;						
			}
		);
			
	}

		

	getCustomer(id) {
		this.disableDelete = id == 0 ? true : false;

		this.vatExist = false;

		if (id == 0) {
			$('.tabs .tab, .tab-child').removeClass('active');
			$("#edit").addClass('active');	
			$(".tab[rel=edit]").addClass('active');	
		}

		this.parameters = { id: id };
		this.canEdit = true;		

		this.data.getMembers({items : 10000, page : 0, order : 't1.username', orderby : 'asc', search : null, activity: this.id, field: 'enabled', value: 1}).subscribe(
			(res: Response) => {
			  this.members = (<any>res).rows;
			  			  
			  this.data.getActivityTypes({}).subscribe(
				(res: Response) => {
					this.categories = (<any>res).rows;
					console.log(this.categories);
			
					this.data.getMemberGroups({items : 10000, page : 0, order : 't1.member_group', orderby : 'asc', search : null, activity: this.id}).subscribe(
						(res: Response) => {
						this.member_groups = (<any>res).rows;
							
							/* finally get customer data */
							console.log(this.parameters);
							this.data.getCustomer(this.parameters).subscribe(
								(res: Response) => {				
									console.log(this.customer);
									this.customer = (<any>res).rows[0];  									

									this.default_category = parseInt(this.customer['default_activity_id']);
									console.log(this.default_category);
									this.entity = this.customer['entity'];
									this.contacts = (<any>res).contact_rows;		
											
									
									var assigned_users_names = [];
									if (this.customer['client_administrators_member_id']!=undefined) {
										var assigned_users_names_entity = (this.customer['client_administrators_member_id']).split(',').map(Number);									
									
										for (var i=0; i<this.members.length; i++) {
											if (assigned_users_names_entity.indexOf(this.members[i].entity) > -1) assigned_users_names.push(this.members[i].username);          
										}
									}
									
									var assigned_group_names = [];									
									if (this.customer['client_administrators_group_id']!=undefined) {
										var assigned_group_names_entity = (this.customer['client_administrators_group_id']).split(',').map(Number);
										for (var i=0; i<this.member_groups.length; i++) {				
											if (assigned_group_names_entity.indexOf(this.member_groups[i].id) > -1) assigned_group_names.push(this.member_groups[i].member_group);          
										}
									}
																	
									this.customer['client_administrators'] = [];
									if (assigned_users_names!=undefined) this.customer['client_administrators']['assigned_users_names'] = assigned_users_names.join(',');
									if (assigned_group_names!=undefined) this.customer['client_administrators']['assigned_group_names'] = assigned_group_names.join(',');
									if (assigned_users_names_entity!=undefined) this.customer['client_administrators']['assigned_users_names_id'] = assigned_users_names_entity.join(',');
									if (assigned_group_names_entity!=undefined) this.customer['client_administrators']['assigned_group_names_id'] = assigned_group_names_entity.join(',');
									
									this.getCustomFields();

									this.getCustomerStatistics(this.customer['entity']);
					
									/* get activities */								
									this.getActivitiesByCompanyId(this.customer['entity'], 5, null, null);
			
									/* get documents */								
									this.data.getDocumentsByCompanyId({'customer_id':this.customer['entity']}).subscribe(
										(res: Response) => {
											//console.log(res);
											this.document_items = (<any>res).rows;
											this.document_count = (<any>res).count_rows;																	
										}
									);
									
									/* get documents */								
									this.data.getCustomerStat({'customer_id':this.customer['entity']}).subscribe(
										(res: Response) => {											
											this.stats = (<any>res);
											console.log(this.stats);
										}
									);
					
									/* get comment */								
									this.data.getCustomerComments({'customer_id':this.customer['entity']}).subscribe(
										(res: Response) => {
											//console.log((<any>res));
											if (<any>res['rows'][0] != undefined) {
												this.comment = <any>res['rows'][0]['comment'] != undefined ? <any>res['rows'][0]['comment'] : [];
												this.comment_entity = <any>res['rows'][0]['entity'];
											}
										}
									);			
					
								}
							);
		
					}
					); //getMemberGroups
	  
				}
				);

			}
		  ); //getMembers
		 	
	}

	showFileIcon(ext) {
    if (ext == 'doc' || ext == 'docx') {
      return "file-word";
    } else if (ext == 'xls' || ext == 'xlsx') {
      return "file-spreadsheet";
    } else if (ext == 'zip' || ext == 'gz' || ext == 'rar') {
      return "file-archive";
    } else if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'svg' || ext == 'tif' || ext == 'gif') {
      return "file-image";
    } else if (ext == 'mov' || ext == 'mp4') {
      return "file-video";
    } else if (ext == 'ppt') {
      return "file-powerpoint";
    } else if (ext == 'pdf') {
      return "file-pdf";
    } else {
      return "file";
    }
    //,fasFileDownload,,,,,,,fasFolder
  }

	download(filename, original_filename) {   
    	this.data.downloadDocument(filename, original_filename);   
  	}

	showhidefinished(state) {
		this.showall = state;
		localStorage.setItem('customer_showhidefinishedactivities', state);
	}


  	close() {
		this.dialogRef.close(this.CustomerForm);
	}

	cancelCustomer() {		
		this.dialogRef.close(this.CustomerForm);
		/*
		this.canEdit = false;		
		this.parameters = { data: Object.assign({}, this.CustomerForm.value) };
		this.getCustomer(this.parameters['data'].entity);
		$('.tabs .tab, .tab-child').removeClass('active');
		$("#summary").addClass('active');
		*/
	}

	deleteCustomer() {		
		this.parameters = { data: Object.assign({}, this.CustomerForm.value) };		
		this.data.deleteCustomer(this.parameters).subscribe(
	      (res: Response) => {
	      	//this.router.navigate(['/customers']);
			this.close();
	      }
	    );	    
	}

	checkCustomerVat(event:any) {		
		var vat = event.target.value;
		this.parameters = { data: Object.assign({}, this.CustomerForm.value) };
		this.data.checkCustomerVat(this.parameters).subscribe(
	      (res: Response) => {
	      	//console.log(<any>res);	
			if (<any>res['data'][0]==undefined) {
				this.vatExist = false;
			} else {
				this.vatExist = true;
			}			
	      }
	    );
	}

	levenshteinDistance = (str1 = '', str2 = '') => {
		var track = Array(str2.length + 1).fill(null).map(() =>
		Array(str1.length + 1).fill(null));
		for (let i = 0; i <= str1.length; i += 1) {
		   track[0][i] = i;
		}
		for (let j = 0; j <= str2.length; j += 1) {
		   track[j][0] = j;
		}
		for (let j = 1; j <= str2.length; j += 1) {
		   for (let i = 1; i <= str1.length; i += 1) {
			  var indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
			  track[j][i] = Math.min(
				 track[j][i - 1] + 1, // deletion
				 track[j - 1][i] + 1, // insertion
				 track[j - 1][i - 1] + indicator, // substitution
			  );
		   }
		}
		return track[str2.length][str1.length];
	 };

	checkCustomerName(event:any) {		
		this.parameters = { data: Object.assign({}, this.CustomerForm.value) };				
		var name = String(this.parameters['data']['company']);
		//console.log(name, name.length);
		if (name.length < 5) {
			this.nameExist = false;
		} else {			
			this.data.checkCustomerName(this.parameters).subscribe(
				(res: Response) => {
					//console.log(<any>res);
					if (<any>res['data'][0] != undefined) {	
						this.similarCompany = <any>res['data'][0]['company'];

						//onsole.log(this.levenshteinDistance(name, this.similarCompany));

						if (<any>res['data']==undefined) {
							this.nameExist = false;
						} else {
							this.nameExist = true;
						}
					}		
				}
			);
		}
	}

	saveCustomer() {
	    var data = Object.assign({}, this.CustomerForm.value)
		data['custom_fields'] = JSON.stringify(this.custom_field_data);

		console.log(this.customer['client_administrators']);
		//data['client_administrators_id'] = this.customer['client_administrators'];

		data['client_administrators_group_id'] = this.customer['client_administrators'] != undefined ? this.customer['client_administrators']['assigned_group_names_id'] : "";
		data['client_administrators_member_id'] = this.customer['client_administrators'] != undefined ? this.customer['client_administrators']['assigned_users_names_id'] : "";
		
		this.parameters = { data: data };		
		console.log(this.parameters);
	    this.data.saveCustomer(this.parameters).subscribe(
	      (res: Response) => {			
			this.customer['entity'] = res['callback']['entity'];	      	
			$('.tabs .tab, .tab-child').removeClass('active');
		    $('.tabs .tab, .tab-child').removeClass('disabled');
			$("#summary, .tab[rel=summary]").addClass('active');		
			console.log(this.entity);	
			console.log(<any>res['callback']['entity']);
			var entity = this.entity == null ? <any>res['callback']['entity'] : this.entity;
			console.log('entity > ', entity);
			this.getCustomer(entity);
	      }	      
	    ); 

	    this.canEdit = false;		
	}	

	/*******************************/

	toDock(entity, type = 'customer') {
		var docker = localStorage.getItem('docker_customers');
	
		var array_docker = [];
		if (docker != '' && docker != undefined) {
		  array_docker = JSON.parse(docker);
	
		  var index = array_docker.indexOf(entity);
		  if (index !== -1) {
			array_docker.splice(index, 1);
		  }
		}
		
		array_docker.push(entity);
		docker = JSON.stringify(array_docker);
		
		//console.log(array_docker);
	
		localStorage.setItem('docker_customers', docker);
	  }

	/**************************************/
	/* Common Functions */
	getInitials(str) {
		var FirstName = '';
		var LastName = '';

		if (str != undefined) {      
		FirstName = str.split(' ')[0];
		LastName = str.split(' ').reduceRight(a => a);
		}

		if (FirstName === LastName) { return FirstName.trim().substring(0, 2).toUpperCase(); }
		return [FirstName, LastName].map(name => name[0]).join('').toUpperCase();
	}


	ShowUserInicials(users) {
		var updated_users = [];
		if (users) {
			if (typeof users === 'string' || users instanceof String) {
				users = users.split(',');
				for (var i=0; i<users.length; i++) {
					var initials = this.getInitials(users[i]);
					var helper_users: any = {};        
					helper_users.initials = this.getInitials(users[i]);
					helper_users.fullname = users[i];
					updated_users.push(helper_users);
				}
			}
		}    
		return updated_users;
	}

	/************************************************************/
	/* Member or Group Assignation */
	assignUserGroup(entity = null) {
		
		console.log(this.customer);

		var assigned_users_names_entity = [];
		this.customer['client_administrators'] = [];
		if (this.customer['client_administrators']['assigned_users_names_id'] != undefined) {
			if (this.customer['client_administrators']['assigned_users_names_id'] != '')
				assigned_users_names_entity = (this.customer['client_administrators']['assigned_users_names_id']).split(',').map(Number);
		}

		var assigned_group_names_entity = [];
		if (this.customer['client_administrators']['assigned_group_names_id'] != undefined) {
			if (this.customer['client_administrators']['assigned_group_names_id'] != '')
				assigned_group_names_entity = (this.customer['client_administrators']['assigned_group_names_id']).split(',').map(Number);
		}
				
		var assignUserGroupDialog = this.assignUserGroupDialog.open(AssignUserGroupComponent, {
			'width' : '450px',
			panelClass: 'user_group_selector',
			data: { membersSelected: assigned_users_names_entity, groupsSelected: assigned_group_names_entity, members: this.members, groups: this.member_groups, activity: null }
		});    
		
		assignUserGroupDialog.afterClosed().subscribe(result => {   
			console.log(result);
						
			var assigned_users_names = [];
			for (var i=0; i<this.members.length; i++) {
				console.log(this.members[i].entity + " | " + assigned_users_names_entity.indexOf(this.members[i].entity));
				if (result.membersSelected.indexOf(this.members[i].entity) > -1) assigned_users_names.push(this.members[i].username);          
			}
			
			var assigned_group_names = [];
			for (var i=0; i<this.member_groups.length; i++) {				
				if (result.groupsSelected.indexOf(this.member_groups[i].id) > -1) assigned_group_names.push(this.member_groups[i].member_group);          
			}
			
			if (this.customer['client_administrators'] == undefined) this.customer['client_administrators'] = [];
			this.customer['client_administrators']['assigned_users_names'] = assigned_users_names.join(',');
			this.customer['client_administrators']['assigned_group_names'] = assigned_group_names.join(',');

			this.customer['client_administrators']['assigned_users_names_id'] = result.membersSelected.join(',');
			this.customer['client_administrators']['assigned_group_names_id'] = result.groupsSelected.join(',');

			this.customer['client_administrators_member_id'] = result.membersSelected.join(',');
			this.customer['client_administrators_group_id'] = result.groupsSelected.join(',');

			console.log(this.customer);
		});
		
		
	}

	/*******************************/

	addActivity() {
		var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
		editDialog.componentInstance.entity = null;
		editDialog.componentInstance.customer_id = this.customer['entity'];
		editDialog.componentInstance.customer = this.customer['company'];
		editDialog.afterClosed().subscribe(result => {});
	}


	editActivity(entity) {    
		var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
		editDialog.componentInstance.entity = entity;					
		editDialog.afterClosed().subscribe(result => {
			this.getCustomer(this.id);
		});    
	}

	/****************************************/
	/* function validate and retrieve organisation name from VAT via VIES */
	/* input: null */
	/* output: null */
	public validateVAT() {		
		var countryCodes = ['AT','BE','BG','HR','CY','CZ','DK','EE','FI','FR','DE','GR','HU','IE','IT','LV','LT','LU','MT','NL','PL','PT','RO','SK','SI','ES','SE'];
		var vat = this.customer['vat'];
		var vatCountry = vat.replace(/[0-9]/g, '');
		var vatNumber = vat.replace(/\D/g, '');

		if (!countryCodes.includes(vatCountry) && vatNumber.length > 7) {

			var content = this.translate.instant('Entered VAT number is not valid EU VAT number');
			var modalDialog = this.dialog.open(ModalComponent, { data: { content: content, yes: this.translate.instant('Close') , no: null, cancel: null } });

		} else {

			this.data.validateVat({country: vatCountry, vat: vatNumber}).subscribe(
				async (res: Response) => {          
					var json = JSON.parse(<any>res)
					if (json['isValid']) {
						var content = this.translate.instant('Entered VAT number ') + this.customer['vat'] + this.translate.instant(' is valid EU VAT number.') + "<br /><br />" + this.translate.instant('Would you like to use organisation data on document?')+"<br/>"+json['name']+"<br/>"+json['address'];
						var modalDialog = this.dialog.open(ModalComponent, { data: { content: content, yes: this.translate.instant('Use data') , no: null, cancel: this.translate.instant('Cancel') } });

						modalDialog.afterClosed().subscribe(result => {
						if (result['yes']!=undefined) {        
							this.customer['company'] = json['name'];
							let address = (json['address']).split(',');
							let city = ((address[address.length-1]).replace(/\s+$/, '')).replace(/[0-9]/g, '');
							let zip = ((address[address.length-1]).replace(/\s+$/, '')).replace(/\D/g,'');
							this.customer['address1'] = (json['address']).replace("," + address[address.length-1], '');
							this.customer['post'] = zip.trimStart();
							this.customer['city'] = city.trimStart();
							//this.document['address'] = json['name'] + "\r\n" + json['address'].replace(",", "\r\n");
						}
						});

					} else {
						var content = this.translate.instant('Entered VAT number ') + this.customer['vat'] + this.translate.instant(' is not valid EU VAT number');
						var modalDialog = this.dialog.open(ModalComponent, { data: { content: content, yes: this.translate.instant('Close'), no: null, cancel: null } });            
					}
				}
			);  
		}
	} //validateVAT()

}