/***************************/
/* JQuery                  */
import * as $ from "jquery";
 
/***************************/
/* Angular Default         */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppRoutingModule } from './app-routing.module';

import { MatOptionModule } from '@angular/material/core'; // Uvozi potrebne Material module

import { GlobalVariables } from './services/data.globals';

/***************************/
/* Translations            */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/***************************/
/* Guard                   */
import { AuthGuard } from './auth.guard'; 

/***************************/
/* Components              */
import { AppComponent } from './app.component';

/***************************/
/* Search                  */
import { SearchComponent } from './search/search.component';

/* users */
import { UsersComponent } from './users/users.component';
import { UserComponent } from './users/user/user.component';
import { UserSelectComponent } from './users/user-select/user-select.component';

/* document center */
import { DocumentCenterComponent } from './document-center/document-center.component';
import { DocumentComponent } from './document-center/document/document.component';
import { DocumentTypeComponent } from './document-center/document-type/document-type.component';
import { DocumentFilterComponent } from './document-center/document-filter/document-filter.component';
import { DocumentSelectComponent } from './document-center/document-select/document-select.component';
import { DocumentAddComponent } from './document-center/document-add/document-add.component';
import { DocumentEditComponent } from './document-center/document-edit/document-edit.component';
import { AssignDocumentCategoryComponent } from './document-center/assign-document-category/assign-document-category.component';
import { DragAndDropDirective } from './drag-and-drop.directive';

/* customers */
import { CustomersComponent } from './customers/customers.component';
import { CustomerComponent } from './customers/customer/customer.component';
import { ContactTypeEditComponent } from './customers/contact-type/contact-type-edit/contact-type-edit.component';
import { ContactEditComponent } from './customers/contact-edit/contact-edit.component';
import { CustomFieldsComponent } from './customers/custom-fields/custom-fields.component';
import { TypeComponent } from './customers/type/type.component';
import { ContactTypeComponent } from './customers/contact-type/contact-type.component';
import { SelectCustomerComponent } from './customers/select-customer/select-customer.component';
import { EditComponent } from './customers/type/edit/edit.component';

/* activity */
import { ActivitySelectComponent } from './activities/activity-select/activity-select.component';
import { AssignCategoryComponent } from './activities/activity/assign-category/assign-category.component';
import { AssignStatusComponent } from './activities/activity/assign-status/assign-status.component';
import { AssignUserGroupComponent } from './activities/activity/assign-user-group/assign-user-group.component';
import { AssignPriorityComponent } from './activities/activity/assign-priority/assign-priority.component';
import { AssignDateTimeComponent } from './activities/activity/assign-date-time/assign-date-time.component';
import { ActivitiesFilterComponent } from './activities/activities-filter/activities-filter.component';
import { ActivitiesCanbanComponent } from './activities/activities-canban/activities-canban.component';

import { ActivityComponent } from './activities/activity/activity.component';
import { ActivitiesComponent } from './activities/activities.component';
import { ActivityTypeComponent } from './activities/activity-type/activity-type.component';
import { ActivityTypeEditComponent } from './activities/activity-type/activity-type-edit/activity-type-edit.component';
import { ActivityFunctionsComponent } from './activities/activity/activity-functions/activity-functions.component';

/* time tracking */
import { TimetrackingComponent } from './timetracking/timetracking.component';
import { TimetrackingEditComponent } from './timetracking/timetracking-edit/timetracking-edit.component';
import { TimetrackingFilterComponent } from './timetracking/timetracking-filter/timetracking-filter.component';

/* login */
import { LoginComponent } from './login/login.component';

/* framework */
import { TopComponent } from './top/top.component';
import { NavComponent } from './nav/nav.component';
import { GridHeaderComponent } from './framework/grid-header/grid-header.component';
import { GridBodyComponent } from './framework/grid-body/grid-body.component';
import { GridFooterComponent } from './framework/grid-footer/grid-footer.component';
import { GridConfiguratorComponent } from './framework/grid-configurator/grid-configurator.component';
import { GridPdfComponent } from './framework/grid-pdf/grid-pdf.component';
import { InternalmenuComponent } from './framework/internalmenu/internalmenu.component';
import { QuickFunctionsComponent } from './framework/quick-functions/quick-functions.component';
import { QuickViewComponent } from './framework/quick-view/quick-view.component';

/***************************/
import { AiChatComponent } from './ai/ai-chat/ai-chat.component';

/***************************/
/* PDF render */
import { PdfViewerModule } from 'ng2-pdf-viewer';

/***************************/
/* FontAwesome             */
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'; 
import { fas } from '@fortawesome/pro-solid-svg-icons'; 
import { fad } from '@fortawesome/pro-duotone-svg-icons'; 
import { far } from '@fortawesome/pro-regular-svg-icons'; 
/*
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faMailbox, faDivide, faTrashUndo, faShield, faLock, faLockOpen, faSave, faHandPointer, faArrowSquareRight, faClipboardCheck, faFileExport, faFileExcel, faStickyNote, faThumbtack, faFileExclamation, faFolderTree, faSun, faMoon, faLightbulb, faLightbulbOn, faDesktop, faChartPie, faUserLock, faVolume, faVolumeSlash, faBell, faBallotCheck, faTasks, faQuestion, faTools, faAlignLeft, faMinus, faCalendar, faCalendarWeek, faCalendarAlt, faNewspaper, faInboxIn,faInboxOut, faFilePdf, faCheck, faStar, faHome, faUser, faUserCrown, faUsers, faBars, faTimes, faSearch, faSearchPlus, faCalendarDay, faPencil, faTimesCircle, faCheckCircle, faSignOut, faPaperclip, faStopwatch, faEdit, faEye, faTrashAlt, faFlag, faCog, faChevronRight, faChevronLeft, faChevronCircleRight, faChevronCircleLeft, faChevronUp, faChevronDown, faLineHeight, faEllipsisH, faExclamationCircle, faArrowRight, faPlusCircle, faList, faUserPlus, faUsersCog, faUserTag, faMinusCircle, faLineColumns, faFilter, faUnlink, faReply, faHistory, faCommentsAlt, faClock, faUserClock, faEyeSlash, faArchive, faFileEdit, faFolder, faArrowFromLeft, faArrowToRight, faChevronDoubleRight, faChevronDoubleLeft, faSpiderWeb, faSitemap, faPen, faEraser, faArrowSquareLeft } from '@fortawesome/pro-regular-svg-icons';
import { faArrowSquareLeft as fasArrowSquareLeft, faQuestionCircle as fasQuestionCircle, faLayerGroup as fasLayerGroup, faCaretSquareLeft as fasCaretSquareLeft, faCaretSquareRight as fasCaretSquareRight, faLayerPlus as fasLayerPlus, faLayerMinus as fasLayerMinus,faSquare as fasSquare, faWindowMaximize as fasWindowMaximize, faThLarge as fasThLarge, faThList as fasThList, faStar as fasStar, faCheckCircle as fasCheckCircle, faTh as fasTh, faInfoCircle as fasInfoCircle, faFile as fasFile, faFileDownload as fasFileDownload, faFilePdf as fasFilePdf, faFileWord as fasFileWord, faFilePowerpoint as fasFilePowerpoint, faFileSpreadsheet as fasFileSpreadsheet, faFileImage as fasFileImage, faFileVideo as fasFileVideo, faFileArchive as fasFileArchive } from '@fortawesome/pro-solid-svg-icons';
*/

/***************************/
/* WebCam */
import { WebcamModule } from 'ngx-webcam';

/***************************/
/* color picker */
import { NgxColorsModule } from 'ngx-colors';

/***************************/
/* widgets */
import { DashboardComponent } from './dashboard/dashboard.component';
import { WidgetsComponent } from './widgets/widgets.component';
import { WidgetActivitiesComponent } from './widgets/widget-activities/widget-activities.component';
import { WidgetActivitiesSettingsComponent } from './widgets/widget-activities/widget-activities-settings/widget-activities-settings.component';
import { WidgetCalendarComponent } from './widgets/widget-calendar/widget-calendar.component';
import { WidgetCustomersComponent } from './widgets/widget-customers/widget-customers.component';
import { WidgetDocumentsComponent } from './widgets/widget-documents/widget-documents.component';
import { WidgetChangesComponent } from './widgets/widget-changes/widget-changes.component';
import { WidgetLastComponent } from './widgets/widget-last/widget-last.component';
import { WidgetLastSettingsComponent } from './widgets/widget-last/widget-last-settings/widget-last-settings.component';
import { WidgetCalendarSettingsComponent } from './widgets/widget-calendar/widget-calendar-settings/widget-calendar-settings.component';
import { WidgetNextComponent } from './widgets/widget-next/widget-next.component';
import { WidgetNextSettingsComponent } from './widgets/widget-next/widget-next-settings/widget-next-settings.component';
import { WidgetActivitiesChartsComponent } from './widgets/widget-activities-charts/widget-activities-charts.component';
import { WidgetActivitiesChartsSettingsComponent } from './widgets/widget-activities-charts/widget-activities-charts-settings/widget-activities-charts-settings.component';
import { WidgetWeatherComponent } from './widgets/widget-weather/widget-weather.component';


import { CommentComponent } from './comment/comment.component';
import { CustomFieldsEditComponent } from './customers/custom-fields/custom-fields-edit/custom-fields-edit.component';
import { PostitComponent } from './postit/postit.component';
import { WidgetPostitComponent } from './widgets/widget-postit/widget-postit.component';
import { UserSettingsComponent } from './users/user-settings/user-settings.component';

import { ModalComponent } from './modal/modal.component';
import { CustomerFilterComponent } from './customers/customer-filter/customer-filter.component';
import { ContactsComponent } from './customers/contacts/contacts.component';
import { ContactsFilterComponent } from './customers/contacts/contacts-filter/contacts-filter.component';
import { DocumentTypeEditComponent } from './document-center/document-type/document-type-edit/document-type-edit.component';
import { WidgetCustomersSettingsComponent } from './widgets/widget-customers/widget-customers-settings/widget-customers-settings.component';
import { NotesComponent } from './notes/notes.component';
import { WidgetNotesComponent } from './widgets/widget-notes/widget-notes.component';

/********************************/
/* Canvas drawing */
import { CanvasComponent } from './canvas.component';

/********************************/
/* Office 365 */
//outlook import { HTTP_INTERCEPTORS } from '@angular/common/http';
//outlook import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
//outlook import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
//import { FailedComponent } from './failed/failed.component';

import { QuillModule } from 'ngx-quill';
import { RecoveryComponent } from './recovery/recovery.component';
import { MailComponent } from './mail/mail.component';
import { SystemComponent } from './system/system.component';
import { MailViewComponent } from './mail/mail-view/mail-view.component';
import { KnowledgeComponent } from './knowledge/knowledge.component';
import { KnowledgeEditComponent } from './knowledge/knowledge-edit/knowledge-edit.component';
import { CalendarComponent } from './calendar/calendar.component';
/*import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';*/

/* charts */
import { NgxEchartsModule } from 'ngx-echarts';


import { WeatherComponent } from './weather/weather.component';
import { ErpDocumentComponent } from './erp/erp-document/erp-document.component';
import { ErpPaymentsComponent } from './erp/erp-payments/erp-payments.component';
import { ActivitiesGanttComponent } from './activities/activities-gantt/activities-gantt.component';
import { NgxGanttModule } from '@worktile/gantt';
import { GANTT_GLOBAL_CONFIG } from '@worktile/gantt';
import { ContactSelectComponent } from './customers/contact-select/contact-select.component';
import { ErpPrintComponent } from './erp/erp-print/erp-print.component';
import { ErpDocumentsComponent } from './erp/erp-documents/erp-documents.component';
import { ErpFilterComponent } from './erp/erp-documents/erp-filter/erp-filter.component';

import { ErpRichquotesComponent } from './erp/erp-richquote/erp-richquotes.component';
import { ErpRichquoteComponent } from './erp/erp-richquote/erp-richquote/erp-richquote.component';
import { ErpRichquoteItemComponent } from './erp/erp-richquote/erp-richquote-item/erp-richquote-item.component';
import { ErpRichItemTypeComponent } from './erp/erp-richquote/erp-richitem-type/erp-richitem-type.component';
import { ErpRichItemCategoryComponent } from './erp/erp-richquote/erp-richitem-category/erp-richitem-category.component';
import { ErpRichItemCategoryItemComponent } from './erp/erp-richquote/erp-richitem-category-item/erp-richitem-category-item.component';

import { ErpRichitemsComponent } from './erp/erp-richquote/erp-richitems/erp-richitems.component';
import { ErpRichitemsfilterComponent } from './erp/erp-richquote/erp-richitems-filter/erp-richitems-filter.component';
import { ErpRichitemComponent } from './erp/erp-richquote/erp-richitem/erp-richitem.component';
import { ErpRichquotePrintComponent } from './erp/erp-richquote/erp-richquote-print/erp-richquote-print.component';


import { ErpFormsComponent } from './erp/erp-forms/erp-forms.component';
import { ErpFormEditComponent } from './erp/erp-form-edit/erp-form-edit.component';
import { ErpFormSubmitComponent } from './erp/erp-form-submit/erp-form-submit.component';
import { WebcamCaptureComponent } from './activities/activity/webcam-capture/webcam-capture.component';
import { TimetrackingSettingsComponent } from './timetracking/timetracking-settings/timetracking-settings.component';
import { ActivitiesAnalyticsComponent } from './activities/activities-analytics/activities-analytics.component';
import { TimetrackingSettingsEditComponent } from './timetracking/timetracking-settings-edit/timetracking-settings-edit.component';

/***********************************/
import { NewComponent } from './new/new.component';

/***********************************/
/* Date picker field */
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';


/***********************************/
/* google map */
import { GoogleMapsModule } from '@angular/google-maps';

/***********************************/
/* Masonry */
import { NgxMasonryModule } from 'ngx-masonry';

//outlook
//outlook const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

const routes: Routes = [
  //{ path: '', component: AppComponent, canActivate: [AuthGuard] },
  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },  
  { path: 'login', component: LoginComponent },

  /* Colabrity */
  { path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard] },

  { path: 'activities', component: ActivitiesComponent, canActivate: [AuthGuard] },
  { path: 'activities/:category_id', component: ActivitiesComponent, canActivate: [AuthGuard] },
  { path: 'activities-canban/:category_id', component: ActivitiesCanbanComponent, canActivate: [AuthGuard] },
  { path: 'activities-gantt/:category_id', component: ActivitiesGanttComponent, canActivate: [AuthGuard] },
  { path: 'activity/:id', component: ActivityComponent, canActivate: [AuthGuard] },
  { path: 'activity-types', component: ActivityTypeComponent, canActivate: [AuthGuard] },

  { path: 'document-center', component: DocumentCenterComponent, canActivate: [AuthGuard] },
  { path: 'document-center/:searchTerm', component: DocumentCenterComponent, canActivate: [AuthGuard] },
  { path: 'document-types', component: DocumentTypeComponent, canActivate: [AuthGuard] },
  
  { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard] },
  { path: 'customer-contacts', component: ContactsComponent, canActivate: [AuthGuard] },
  { path: 'customers/:search', component: CustomersComponent, canActivate: [AuthGuard] },
  { path: 'custom_type', component: TypeComponent, canActivate: [AuthGuard] },
  { path: 'custom_fileds', component: CustomFieldsComponent, canActivate: [AuthGuard] },
  { path: 'contact_type', component: ContactTypeComponent, canActivate: [AuthGuard] },

  { path: 'timetracking', component: TimetrackingComponent, canActivate: [AuthGuard] },
  { path: 'time-settings', component: TimetrackingSettingsComponent, canActivate: [AuthGuard] },

  { path: 'office_erp_document/:document_id', component: ErpDocumentComponent, canActivate: [AuthGuard] },
  { path: 'office_erp_documents/:erpType', component: ErpDocumentsComponent, canActivate: [AuthGuard] },
  { path: 'office_erp_forms', component: ErpFormsComponent, canActivate: [AuthGuard] },
  { path: 'office_erp_form_edit/:erpType', component: ErpFormEditComponent, canActivate: [AuthGuard] },
  { path: 'office_erp_form_submit/:erpType', component: ErpFormSubmitComponent, canActivate: [AuthGuard] },  
  { path: 'office_erp_addon/richquote', component: ErpRichquotesComponent, canActivate: [AuthGuard] },
  { path: 'office_erp_addon/richquote/:quoteitemid', component: ErpRichquoteComponent, canActivate: [AuthGuard] },
  
  { path: 'office_erp_addon/richitems', component: ErpRichitemsComponent, canActivate: [AuthGuard] },
  { path: 'office_erp_addon/richitemtype', component: ErpRichItemTypeComponent, canActivate: [AuthGuard] },
  { path: 'office_erp_addon/richitemcategory', component: ErpRichItemCategoryComponent, canActivate: [AuthGuard] },

  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },

  { path: 'knowledge', component: KnowledgeComponent, canActivate: [AuthGuard] },

  { path: 'recovery', component: RecoveryComponent, canActivate: [AuthGuard] },

  { path: 'aichat', component: AiChatComponent, canActivate: [AuthGuard] },

  /* not processed yet... */
  { path: 'notes', component: NotesComponent, canActivate: [AuthGuard] },  
  { path: 'mail_logs', component: MailComponent, canActivate: [AuthGuard] },  
  { path: '**', redirectTo: '/' }
]



/**********************************/
/* AZURE  */
/*
export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {      
      clientId: '894a4bd9-17fb-45c5-a4c4-f4156c538c90',
      authority: 'https://login.microsoftonline.com/common',      
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}
*/

/*
"Mail.ReadWrite",
"User.ReadWrite.All",
"Mail.ReadBasic.All",
"User.Read.All",
"Mail.Read",
"Mail.Send",
"Mail.ReadBasic"
*/

//outlook 
/*
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {  
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read', 'Mail.ReadWrite', 'Mail.Read', 'Mail.Send']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read', 'Mail.ReadWrite', 'Mail.Read', 'Mail.Send']
    },
    loginFailedRoute: '/login-failed'
  };
}
*/




@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        /* canvas */
        CanvasComponent,
        /* users */
        UserComponent,
        UsersComponent,
        UserSelectComponent,
        /* time tracking */
        TimetrackingComponent,
        TimetrackingEditComponent,
        TimetrackingFilterComponent,
        /* Document center */
        DocumentCenterComponent,
        DocumentComponent,
        DocumentTypeComponent,
        DocumentFilterComponent,
        DocumentSelectComponent,
        DocumentAddComponent,
        DocumentEditComponent,
        AssignDocumentCategoryComponent,
        DragAndDropDirective,
        /* customer */
        CustomersComponent,
        CustomerComponent,
        ContactTypeComponent,
        ContactTypeEditComponent,
        ContactEditComponent,
        CustomFieldsComponent,
        TypeComponent,
        SelectCustomerComponent,
        EditComponent,
        /* activity */
        ActivityTypeComponent,
        ActivityTypeEditComponent,
        ActivitiesComponent,
        ActivityComponent,
        ActivitiesFilterComponent,
        ActivitiesCanbanComponent,
        ActivityFunctionsComponent,
        ActivitySelectComponent,
        AssignCategoryComponent,
        AssignStatusComponent,
        AssignUserGroupComponent,
        AssignPriorityComponent,
        AssignDateTimeComponent,
        ActivityTypeEditComponent,
        /* Widgets */
        DashboardComponent,
        WidgetsComponent,
        WidgetActivitiesComponent,
        WidgetActivitiesSettingsComponent,
        WidgetCalendarComponent,
        WidgetCustomersComponent,
        WidgetDocumentsComponent,
        WidgetChangesComponent,
        WidgetLastComponent,
        WidgetLastSettingsComponent,
        WidgetCalendarSettingsComponent,
        WidgetPostitComponent,
        WidgetCustomersSettingsComponent,
        WidgetNotesComponent,
        WidgetActivitiesChartsComponent,
        WidgetActivitiesChartsSettingsComponent,
        WidgetWeatherComponent,
        WidgetNextComponent,
        WidgetNextSettingsComponent,
        CommentComponent,
        CustomFieldsEditComponent,
        PostitComponent,
        UserSettingsComponent,
        ModalComponent,
        CustomerFilterComponent,
        ContactsComponent,
        ContactsFilterComponent,
        DocumentTypeEditComponent,
        NotesComponent,
        RecoveryComponent,
        MailComponent,
        SystemComponent,
        MailViewComponent,
        KnowledgeComponent,
        KnowledgeEditComponent,
        CalendarComponent,
        /*DocumentsComponent,*/
        WeatherComponent,
        ErpDocumentComponent,
        ErpPaymentsComponent,
        ActivitiesGanttComponent,
        ContactSelectComponent,
        ErpPrintComponent,
        ErpDocumentsComponent,
        ErpFilterComponent,
        ErpFormsComponent,
        ErpFormEditComponent,
        ErpFormSubmitComponent,
        WebcamCaptureComponent,
        TimetrackingSettingsComponent,
        ActivitiesAnalyticsComponent,
        TimetrackingSettingsEditComponent,
        ErpRichquotesComponent,
        ErpRichquoteComponent,
        ErpRichItemCategoryComponent,
        ErpRichItemTypeComponent,
        ErpRichitemsComponent,
        ErpRichitemComponent,
        ErpRichitemsfilterComponent,
        ErpRichquotePrintComponent,
        ErpRichquoteItemComponent,
        ErpRichItemCategoryItemComponent,
        NewComponent,
        /* AI */
        AiChatComponent,
        /* Framework */
        GridHeaderComponent,
        GridBodyComponent,
        GridFooterComponent,
        GridConfiguratorComponent,
        GridPdfComponent,
        InternalmenuComponent,
        QuickFunctionsComponent,
        QuickViewComponent,
        SearchComponent,
        TopComponent,
        NavComponent
    ],
    bootstrap: [AppComponent], imports: [CommonModule,
        PdfViewerModule,
        NgxColorsModule,
        NgxMasonryModule,
        WebcamModule,
        NgxGanttModule,
        BrowserModule,
        FormsModule,
        FontAwesomeModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        AppRoutingModule,
        RouterModule.forRoot(routes),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatDialogModule,
        BrowserAnimationsModule,
        DragDropModule,
        ReactiveFormsModule,
        GoogleMapsModule,
        BrowserModule.withServerTransition({ appId: 'ngx-quill-universal' }),
        QuillModule.forRoot({
            suppressGlobalRegisterWarning: true
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        //outlook MsalModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        //Date picker field
        MatIconModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule], providers: [
        {
            provide: GANTT_GLOBAL_CONFIG,
            useValue: {
                dateFormat: {
                    yearQuarter: `QQQ 'of' yyyy`,
                    month: 'LLLL',
                    yearMonth: `LLLL yyyy' ('w' .week)'`,
                    year: 'yyyy',
                }
            }
        },
        DashboardComponent
        //for AZURE
        //outlook 
        /*
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService
        */
        ,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fad);
    // Add multiple icons to the library
    /*
    library.addIcons( faMailbox, faDivide, faTrashUndo, faShield, faLock, faLockOpen, faSave, faHandPointer, faArrowSquareRight, faClipboardCheck, faFileExport, faFileExcel, faStickyNote, faThumbtack, faFileExclamation, faFolderTree, faMoon, faSun, faLightbulb, faLightbulbOn, faDesktop, faChartPie, faUserLock, faCalendar, faCalendarWeek, faFilePdf, faVolume, faVolumeSlash, faBell, faBallotCheck, faTasks, faQuestion, faTools, faAlignLeft, faMinus, faCalendarAlt, faNewspaper, faInboxIn, faInboxOut, faCheck, faStar, faHome, faUser, faUserCrown, faUsers, faBars, faTimes, faSearch, faSearchPlus, faCalendarDay, faPencil, faTimesCircle, faCheckCircle, faSignOut, faPaperclip, faStopwatch, faEdit, faEye, faTrashAlt, faFlag, faCog, faChevronRight, faChevronLeft, faChevronCircleRight, faChevronCircleLeft, faChevronUp, faChevronDown, faLineHeight, faEllipsisH, faExclamationCircle, faArrowRight, faPlusCircle, faList, faUserPlus, faUsersCog, faUserTag, faMinusCircle, faLineColumns, faFilter, faUnlink, faReply, faHistory, faCommentsAlt, faClock, faUserClock, faEyeSlash, faArchive, faFileEdit, faFolder, faArrowFromLeft, faArrowToRight, faChevronDoubleRight, faChevronDoubleLeft, faSpiderWeb, faSitemap, faPen, faEraser );
    library.addIcons( fasArrowSquareLeft, fasQuestionCircle, fasLayerGroup, fasCaretSquareLeft, fasCaretSquareRight, fasLayerPlus, fasLayerMinus, fasSquare, fasWindowMaximize, fasThLarge, fasThList, fasStar, fasCheckCircle, fasTh, fasInfoCircle, fasFile, fasFileDownload, fasFilePdf, fasFileWord, fasFilePowerpoint, fasFileSpreadsheet, fasFileImage, fasFileVideo, fasFileArchive );
    */
  }
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}





