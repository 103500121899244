<div class="inner_wrapper">

	<div class="table_header enached_table_header">
        
		<div class="left">
			<div class="table_header_left">
				<div class="table_title">
                    <div class="month_selector">
                        <fa-icon [icon]="['fad', 'circle-chevron-left']" (click)="change_to_prev_month()"></fa-icon>
                        <span>{{current_month}}, {{current_year}}</span>
                        <fa-icon [icon]="['fad', 'circle-chevron-right']" (click)="change_to_next_month()"></fa-icon>
                    </div>
				</div>				
			</div>
		</div>

        <div class="right mobile" (click)="showMenu=!showMenu" style="z-index: 100;">
            <fa-icon class="icon_medium" [icon]="['fad', 'sliders']"></fa-icon>
        </div>

		<div class="right desktop" [ngClass]="{'mobile' : showMenu == true}"> 
            <div *ngIf="!expand" class="table_header_right">
				<span style="position:relative;">
				    <fa-icon [icon]="['fad', 'arrows-maximize']" (click)="toggleExpand();"></fa-icon>
				    <div class="tooltip">{{'Expand calendar'|translate}}</div>
				</span>
			</div>
            <div *ngIf="expand" class="table_header_right">
				<span style="position:relative;">
				    <fa-icon [icon]="['fad', 'arrows-minimize']" (click)="toggleExpand();"></fa-icon>
				    <div class="tooltip">{{'Shrink calendar'|translate}}</div>
				</span>
			</div>

            <div class="table_header_right group_separator">
				<span style="position:relative;">
					<fa-icon [icon]="['fad', 'filter']" (click)="CalendarFilter=!CalendarFilter"></fa-icon>
					<div class="tooltip">{{'Set/Unset Filter'|translate}}</div>
				</span>
			</div>

            <div class="table_header_right group_separator" style="margin-right: 24px;">
                <ng-container *ngFor="let category of categories">                    
                    <span *ngIf="category.selected" class="category_bubble" style="background:{{category.hex}}">{{category.description}}</span>
                </ng-container>		            
            </div>
		</div>
	</div>	


    <div *ngIf="range=='dayly'" class="table_header enached_table_header">
        dayly
    </div>
    
    <div *ngIf="range!='dayly' && range!='weekly'" id="calendar_render">    
        <div *ngFor="let item of week_eu" class="calendar_day header expand_{{expand}} desktop">
            {{item|translate}}
        </div>
        
        <div *ngFor="let item of selected_month | keyvalue" class="calendar_day day expand_{{expand}} wday_{{$any(item).value.wday}}" [ngClass]="{'notCurrent' : $any(item).value.current==false, 'today' : $any(item).value.day==todaysDay && current_month_raw==todaysMonth && current_year==todaysYear, 'thisWeek': $any(item).value.week==todaysWeek}">       
            <div class="calnedar_header">
                <div class="day_number">
                    {{$any(item).value.day}}                
                </div>                
                <span class="day_name mobile">{{$any(item).value.wday_name|translate}}</span>
                <span class="totalLabel">{{'Today'|translate}}</span>        
                <fa-icon *ngIf="countObject(item.value['activities']) > 0" [icon]="['fad', 'calendar-lines-pen']" class="clickable" (click)="selectDate($any(item).key,$any(item).value.count)"></fa-icon>
                <fa-icon [icon]="['fad', 'circle-plus']" class="clickable" (click)="add_activity($any(item).key)"></fa-icon>
                <div class="week_number">
                    <ng-container *ngIf="$any(item).value.wday==1">
                        {{$any(item).value.week}}. {{'week'|translate}}
                    </ng-container>
                    &nbsp;
                </div>            
            </div>
            <div class="calendar_scroll">
                <ng-container *ngFor="let activity_item of item.value['activities'] | keyvalue">
                    <div *ngIf="activity_item['title'] != ''" class="calendar_activity" id="calendarList" (click)="activity(activity_item.key)">
                        <fa-icon [icon]="['fas', activity_item.value['icon']]" class="small" style="margin: 0px 3px 0px 0px; color:{{activity_item.value['hex']}}"></fa-icon>
                        <div class="activity_title">{{activity_item.value['title']}}</div>
                        <div class="activity_time">{{activity_item.value['start']}}</div>                        
                    </div>
                </ng-container>         
            </div>
        </div>        
    </div>
    

    <div *ngIf="range=='weekly'" id="calendar_render" class="weekly">    
        <div *ngFor="let item of week_eu" class="calendar_day header expand_{{expand}} desktop">
            {{item|translate}}
        </div>
        
        <ng-container *ngFor="let item of selected_month | keyvalue">       
            <div *ngIf="$any(item).value.week==todaysWeek" class="calendar_day day expand_{{expand}} wday_{{$any(item).value.wday}}" [ngClass]="{'notCurrent' : $any(item).value.current==false, 'today' : $any(item).value.day==todaysDay && current_month_raw==todaysMonth && current_year==todaysYear}">
                <div class="calnedar_header">
                    <div class="day_number">
                        {{$any(item).value.day}}                
                    </div>                
                    <span class="day_name mobile">{{$any(item).value.wday_name|translate}}</span>
                    <span class="totalLabel">{{'Today'|translate}}</span>        
                    <fa-icon [icon]="['fad', 'calendar-lines-pen']" class="clickable" (click)="selectDate($any(item).key,$any(item).value.count)"></fa-icon>
                    <fa-icon [icon]="['fad', 'circle-plus']" class="clickable" (click)="add_activity($any(item).key)"></fa-icon>
                    <div class="week_number">
                        <ng-container *ngIf="$any(item).value.wday==1">
                            {{$any(item).value.week}}. {{'week'|translate}}
                        </ng-container>
                        &nbsp;
                    </div> 
                </div>           
                
                <ng-container *ngFor="let hour of hours">
                    <div class="hour hour_{{hour}}">
                        {{hour}}
                        <div class="hour_container">
                            <ng-container *ngFor="let activity_item of item.value['activities'] | keyvalue">
                                <ng-container *ngIf="compareHours(hour,activity_item.value['start'])">
                                    <div *ngIf="activity_item['title'] != ''" class="calendar_activity" cdkDrag cdkDropList id="calendarList" (click)="activity(activity_item.key)">
                                        <fa-icon [icon]="['fas', activity_item.value['icon']]" class="small" style="margin: 0px 3px 0px 0px; color:{{activity_item.value['hex']}}"></fa-icon>
                                        <div class="activity_title">{{activity_item.value['title']}}</div>
                                        <div class="activity_time">{{activity_item.value['start']}}</div>                        
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>        
    </div>

</div>

<div *ngIf="CalendarFilter" class="calendarFilter mat-dialog-container" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="inner_wrapper">
		<div class="header">
			<div class="left">
				<h1><fa-icon [icon]="['fad', 'calendar-days']"></fa-icon> {{'Filter'|translate}}</h1>
			</div>
			<div class="right">
				<fa-icon class="icon_close" [icon]="['fad', 'times']" (click)="CalendarFilter=!CalendarFilter"></fa-icon>
			</div>
		</div>
		
        <div class="body">

            <div class="inline w100 wPadding">
                <mat-form-field appearance="outline">
                  <mat-label>{{'Monthly'|translate}}</mat-label>
                  <mat-select [(ngModel)]="range" name="range">
                    <mat-option value="monthly" rel="priority">Monthly</mat-option>
                  </mat-select>
                <!--<mat-hint">Error</mat-hint>-->
                </mat-form-field>
            </div>
            
            <div class="multi_line">
                <div class="field" style="width: 100%">
                    <div class="multiselect_checkbox categories">
                        <ng-container *ngFor="let category of categories">
                            <div class="checkbox_wrapper">
                                <label class="switch">
                                    <input type="checkbox" class="dummy" [attr.rel]="category.entity" [checked]="category.selected">
                                    <span class="slider round"></span>
                                </label>
                                <div class="label"><span class="category_bubble" style="background:{{category.hex}}"></span>{{category.description}}</div>
                            </div>
                        </ng-container>		
                    </div>
                </div>
            </div>

            <div class="inline w100 wPadding">
                <mat-form-field appearance="outline">
                  <mat-label>{{'Display mode'|translate}}</mat-label>
                  <mat-select [(ngModel)]="sorting" name="sorting">
                    <mat-option value="last">{{'Display only end date'|translate}}</mat-option>
                    <mat-option value="start">{{'Display only start date'|translate}}</mat-option>
                    <mat-option value="startstop">{{'Display start and end date'|translate}}</mat-option>
                    <mat-option value="all">{{'Display whole duration'|translate}}</mat-option>
                  </mat-select>
                <!--<mat-hint">Error</mat-hint>-->
                </mat-form-field>
            </div>
            
        </div>
        <div class="modalActions">
            <button class="accept right" style="float:right;margin-right:0;" (click)="applyFilter()">{{'Apply'|translate}}</button> 
        </div>
    </div>
</div>




