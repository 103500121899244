import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../../../services/data.globals';

@Component({
  selector: 'app-assign-priority',
  templateUrl: './assign-priority.component.html',
  styleUrls: ['./assign-priority.component.css']
})
export class AssignPriorityComponent implements OnInit {

	constructor(
		public dialog: MatDialogRef<AssignPriorityComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		public translate: TranslateService
	) { 
	  translate.addLangs(['en', 'si']);
	}

	ngOnInit(): void {
		var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);
	}

	assignPriority(priority) {
		this.data.priority = priority;
		this.dialog.close(this.data);
	}

	close() {
		this.data.priority = null;
		this.dialog.close(this.data);
	}

}
