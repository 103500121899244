import { Component, OnInit, Input, Inject } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctions } from '../../services/common.functions';
import { GlobalVariables } from '../../services/data.globals';
import { DataService } from '../../services/data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-erp-payments',
  templateUrl: './erp-payments.component.html',
  styleUrl: './erp-payments.component.css'
}) 
 
export class ErpPaymentsComponent implements OnInit {
 
  public paymentDetails = [];
  public entity = null;

  public date = "";
  public description = "";
  public amount = "";
  public totalAmount = 0;
  public totalPaid = 0;
  public totalDue = 0;
  
  constructor(
		public translate: TranslateService,
    private data: DataService, 
    public dialog: MatDialog,
    public cFunction: CommonFunctions,
    public dialogErpPayments: MatDialogRef<ErpPaymentsComponent>,
    @Inject(MAT_DIALOG_DATA) public injected_data
	) { 
		translate.addLangs(['en', 'si']);
	}
  
  /********************************************/
  toCurrency(value) {
    if (value == undefined) value = 0;
    return this.cFunction.formatNumber(value);
  }
  
  summarizeAmount() {
    this.totalPaid = 0;
    for (const [key, value] of Object.entries(this.paymentDetails)) {
      this.totalPaid += value.amount;
    }
    this.totalDue = this.totalAmount - this.totalPaid;
  }

  /********************************************/
  onDateKey(event:any) { 		
    if (event.key == "Enter") {
      if (this.date == "") {
        this.summarizeAmount();
        this.save();
      } else {
        document.getElementById("description").focus();
      }
    } else {
      //this.date = event.target.value;
    }
	}

  onDescriptionKey(event:any) { 		
    if (event.key == "Enter") {
      document.getElementById("amount").focus();
    } else {
      //this.description = event.target.value;
    }
	}

  onAmountKey(event:any) { 		
    if (event.key == "Enter") {
      this.addToObject();
      this.summarizeAmount();
      if (this.totalDue == 0) {
        this.save();
      }
    } else {
      //this.amount = event.target.value;
    }
	}

  addToObject() {
    if (this.amount != "") {
      this.amount = this.amount.replace(".", "").replace(",", ".");
      this.paymentDetails.push({ date: this.date, description: this.description, amount: parseFloat(this.amount) });
      this.date = "";
      this.description = "";
      this.amount = "";
      document.getElementById("date").focus();
      //console.log(this.paymentDetails);
    }

    if (this.amount == "" && this.description == "") {
      document.getElementById("date").focus();
    }
  }

  removePaymentItem(index) {
    this.paymentDetails.splice(index, 1);
    this.summarizeAmount();
  }

  /********************************************/
  fillPayment() {
    let currentDate = new Date();    
    this.summarizeAmount();
    this.paymentDetails.push({ date: currentDate.toISOString().split('T')[0], description: 'Auto paid', amount: this.totalAmount - this.totalPaid });
    this.summarizeAmount();
    this.dialogErpPayments.close({ entity: this.entity, paymentDetails: this.paymentDetails, totalPaid: this.totalPaid });
  }
  
  save() {
    this.dialogErpPayments.close({ entity: this.entity, paymentDetails: this.paymentDetails, totalPaid: this.totalPaid });
  }

  cancel() {
    this.dialogErpPayments.close();
  }

  /********************************************/
  ngOnInit(): void {

    this.data.getMemberSettings(null).subscribe(
      (res: Response) => {        
          var memberSettingsArray = [];        
          for (var i=0; i<res['rows'].length; i++) {          
              memberSettingsArray[res['rows'][i].parameter] = res['rows'][i].value;
          }        
          GlobalVariables.memberSettings = memberSettingsArray;      
          var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
          this.translate.use(lang);	  
      });  

      console.log(this.injected_data);

      this.paymentDetails = this.injected_data.paymentDetails;
      this.entity = this.injected_data.entity;
      this.totalAmount = this.injected_data.totalAmount;

      this.date = this.cFunction.localDateOnlyFormat(new Date);
      this.summarizeAmount();

  }

}
