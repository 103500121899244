import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../services/data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivityComponent } from '../../activities/activity/activity.component'
import { CommonFunctions } from '../../services/common.functions';

interface DialogData {
  email: string;
}

@Component({
  selector: 'app-activity-select',
  templateUrl: './activity-select.component.html',
  styleUrls: ['./activity-select.component.css']
})

export class ActivitySelectComponent implements OnInit {

	constructor(
		private data_svc: DataService,
		public dialog: MatDialog,
		public cFunction: CommonFunctions,
    	public dialogRef: MatDialogRef<ActivitySelectComponent>,
    	@Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

	public items;
	public params;
	public inSearch = false;
	public previousSearch = "";
	public parameters;
	public categories;
	public categoriesSelected = [];
	public categoriesHelper = [];
	public search;
	public watch_from_date;
	public watch_to_date;
	public autosearch = false; 
	public calendar_restriction;
	public advanced_search = false;
	public customer = null;
	public customer_id = null;

	ngOnInit(): void {
				
		this.advanced_search = localStorage.getItem('advanced_search_activity') == 'true' ? true : false;

		this.categoriesHelper = this.categoriesHelper = JSON.parse(localStorage.getItem('activities_select'));
		if (this.categoriesHelper!=null) {
			for (var i=0; i < this.categoriesHelper.length; i++) {
				this.categoriesSelected[this.categoriesHelper[i].category_id] = true;			
			}
		}
				
		////console.log('**************************************');
		////console.log(this.data);

		if (this.data != undefined) {
			this.watch_from_date = this.data['date_start'] != undefined ? this.data['date_start'] : null;
			this.watch_to_date = this.data['date_end'] != undefined ? this.data['date_end'] : null;
			this.calendar_restriction = this.data['calendar_restriction'] != undefined ? this.data['calendar_restriction'] : null;
		}

		var parameters = {};
		this.data_svc.getActivityTypes(parameters).subscribe(
			(res: Response) => {
				this.categories = (<any>res).rows;				
			}
		);

		if (this.data != undefined) {
			if (this.data['autosearch'] == true) {
				this.autosearch = true;
				if (this.calendar_restriction != null) {
					//autosearch_parameters = { 'calendar_restriction': true, 'categories' : null, 'page' : 0, 'items' : 25, 'order' : 'entity', search: null, 'orderby' : 'desc', calendar : true, filter_start : this.watch_from_date, filter_end : this.watch_to_date };
					this.getActivities({ 'calendar_restriction': true, 'categories' : null, 'page' : 0, 'items' : 25, 'order' : 'entity', search: null, 'orderby' : 'desc', calendar : true, filter_start : this.watch_from_date, filter_end : this.watch_to_date });
				} else {
					this.getActivities({ 'categories' : null, 'page' : 0, 'items' : 25, 'order' : 'entity', search: null, 'orderby' : 'desc', calendar : true, filter_start : this.watch_from_date, filter_end : this.watch_to_date });	
					//autosearch_parameters = { 'categories' : null, 'page' : 0, 'items' : 25, 'order' : 'entity', search: null, 'orderby' : 'desc', calendar : true, filter_start : this.watch_from_date, filter_end : this.watch_to_date };
				}
				
				////console.log('*** getActivities: activity_select:refresh');
				
			}
		}
	}

	reselectCategory(id) {		
		var categoriesHelper = [];
		this.categoriesSelected[id] = this.categoriesSelected[id] == true ? false : true;
		
		console.log(this.categoriesSelected);

		this.categoriesSelected.forEach(function (value, i) {			
			if (value == true) {
				var helper = {};
				helper['category_id'] = i;
				if (categoriesHelper == null) categoriesHelper = [];
				categoriesHelper.push(helper);				
			}
		});

		this.categoriesHelper = categoriesHelper;
		localStorage.setItem('activities_select', JSON.stringify(this.categoriesHelper));

		this.parameters = { 'categories' : this.categoriesHelper, 'page' : 0, 'items' : 25, 'order' : 'entity', search: this.search, 'orderby' : 'desc' };

		if (this.inSearch != true) {	
			//console.log('*** getActivities: activities:autosearch');		
			this.getActivities(this.parameters);
		}

	}

	getActivities(parameters) {
		this.inSearch = true;				
		this.data_svc.selectActivity(parameters).subscribe(
			(res: Response) => {				
				this.items = (<any>res).rows;
				console.log(this.items);
				this.inSearch = false;
			}
		);		
	}

	openActivity(entity) {		
		var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
		editDialog.componentInstance.entity = entity;
		//editDialog.componentInstance['category_id'] = this.category_id;
		editDialog.afterClosed().subscribe(result => {
			var autosearch_parameters = { 'categories' : null, 'page' : 0, 'items' : 25, 'order' : 'entity', search: null, 'orderby' : 'desc', calendar : true, filter_start : this.watch_from_date, filter_end : this.watch_to_date };
			//console.log('*** getActivities: activities-select:refresh');
			this.getActivities(autosearch_parameters);
		});
	}

	onSearchActivity(event:any) { 			
		console.log(event.target.value);
		console.log(this.categoriesHelper);
		this.parameters = { 'categories' : this.categoriesHelper, 'page' : 0, 'items' : 25, 'order' : 'entity', search: event.target.value, 'orderby' : 'desc' };

		if (this.inSearch != true) {	
			//console.log('*** getActivities: activity-select:search');
			this.getActivities(this.parameters);
		}
	}

	advanced_search_switch(param) {
		this.advanced_search = param;
		if (param) {
			localStorage.setItem('advanced_search_activity', 'true');
		} else {
			localStorage.setItem('advanced_search_activity', 'false');
		}
	}

	onSelect(selection) {		
		this.dialogRef.close(selection);
	}

	addActivity() {
		var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
		editDialog.componentInstance.entity = null;		
		editDialog.afterClosed().subscribe(result => {});
	}

}