import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../../services/data.globals';

/* Common Functions */
import { CommonFunctions } from '../../services/common.functions';


@Component({
  selector: 'app-assign-document-category',
  templateUrl: './assign-document-category.component.html',
  styleUrls: ['./assign-document-category.component.css']
})
export class AssignDocumentCategoryComponent implements OnInit {

  constructor(
    public dialog: MatDialogRef<AssignDocumentCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public translate: TranslateService,
    public CommonFunction: CommonFunctions
  ) {
     translate.addLangs(['en', 'si']); 
   }

  /***********************/
  /*
  textColorFromBG(hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    //console.log(r, g, b, yiq);
    return (yiq >= 128) ? 'black' : 'white';
  } 
    */
  /**********************/

  assignCategory(entity, hex, description) {
    this.data.currentCategory = entity;
    this.data.hex = hex;
    this.data.description = description;
    delete this.data.categories;
    this.dialog.close(this.data);
  }

  close() {
    delete this.data.categories;
    //delete this.data.activity;
    this.data.currentCategory = null;
    this.dialog.close(this.data);
  }

  ngOnInit(): void {     
    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);
        
    let allowToUpload = false;
    for (let i=0; i<this.data.length; i++) {
      if (this.data[i]['allowCreate']==true) allowToUpload = true;
    }
    if (!allowToUpload) {
      this.dialog.close(null);
    }    
  }

}
