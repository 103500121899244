import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-contacts-filter',
  templateUrl: './contacts-filter.component.html',
  styleUrls: ['./contacts-filter.component.css']
})
export class ContactsFilterComponent implements OnInit {

  constructor(
    private data: DataService,
    public translate: TranslateService,
		public dialogRef: MatDialogRef<ContactsFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public injected_data
  ) { }

  public item_types = [];
  public item_status = [];
  
  togleItemRestriction(entity) {
		for(var i=0; i<this.item_types.length; i++) {			
			if (this.item_types[i].entity == entity) {
				if (this.item_types[i]['selected'] == true) {
					this.item_types[i]['selected'] = false;
				} else {
					this.item_types[i]['selected'] = true;
				}
			}
		}		
	}

  togleStatusRestriction(entity) {
		for(var i=0; i<this.item_status.length; i++) {			
			if (this.item_status[i].entity == entity) {
				if (this.item_status[i]['selected'] == true) {
					this.item_status[i]['selected'] = false;
				} else {
					this.item_status[i]['selected'] = true;
				}
			}
		}		
	}

  confirm() {
		var data = {};
	
		/* status restrictions */
		for(var i=0; i<this.item_types.length; i++) {			
			if (this.item_types[i]['selected'] == true) {
				if (data['contact_type']==undefined) data['contact_type'] = "";
				data['contact_type'] += data['contact_type'] == "" ? "'" + this.item_types[i].entity + "'" : ",'" + this.item_types[i].entity + "'";
			} 	
		}
    
    for(var i=0; i<this.item_status.length; i++) {			
			if (this.item_status[i]['selected'] == true) {
				if (data['company_status']==undefined) data['company_status'] = "";
				data['company_status'] += data['company_status'] == "" ? "'" + this.item_status[i].entity + "'" : ",'" + this.item_status[i].entity + "'";
			} 	
		}
    
		this.dialogRef.close(data);   
	}

  ngOnInit(): void {   

    this.data.getContactTypes(null).subscribe(
      (res: Response) => {
        this.item_types = (<any>res).rows;
                
        if (this.injected_data['contact_type']!=undefined) {
		        var hide_ct = (this.injected_data['contact_type']).replaceAll("'", "").split(',');
		        for (var i=0; i<this.item_types.length; i++) {		        	
		        	for (var j=0; j<hide_ct.length; j++) {		        		
						    if (this.item_types[i].entity == hide_ct[j]) {
							    this.item_types[i]['selected'] = true;
						    }
			        }  
		        }		        
	    	}
      }
    );


    this.data.getCustomerTypes(null).subscribe(
      (res: Response) => {
        this.item_status = (<any>res).rows;        
        
        if (this.injected_data['company_status']!=undefined) {
		        var hide_ct = (this.injected_data['company_status']).replaceAll("'", "").split(',');
		        for (var i=0; i<this.item_status.length; i++) {		        	
		        	for (var j=0; j<hide_ct.length; j++) {		        		
						    if (this.item_status[i].entity == hide_ct[j]) {
							    this.item_status[i]['selected'] = true;
						    }
			        }  
		        }		        
	    	}
      }
    );


  }

}

