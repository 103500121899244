/* Angular */
import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

/* Material */
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* Services */
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  styleUrl: './quick-view.component.css'
})
export class QuickViewComponent implements OnInit {
  
  public files = [];
  public type = null;
  public storage = null;
  public documentType = null;

  pdfUrl: string = 'https://api.biliz.com/storage/Fe0eKh0TJjzyqPsM/m/Y/mYKKMroRlLuuDXa5OanJ.pdf';
  sanitizedPdfUrl: SafeResourceUrl;

  constructor(
    private data: DataService,
		@Inject(MAT_DIALOG_DATA) public injected_data,
    public translate: TranslateService,
    private sanitizer: DomSanitizer    
  ) {
    translate.addLangs(['en', 'si']); 
    
  }
    
  previewAvailable(file) {
    let ext = (file).split('.');
    let previewExt = ['jpg', 'JPG', 'jpeg', 'JPEG', 'gif', 'GIF', 'png', 'PNG', 'pdf', 'PDF'];
    return previewExt.includes(ext[ext.length-1]) ? true : false;
  }

  displayImage(filename) {    
    if (filename == null) return "";
    return "https://api.biliz.com/storage/" + this.storage + "/" + filename.charAt(0) + "/" + filename.charAt(1) + "/" + filename;	    
  } 

  ngOnInit(): void {    
    this.data.getStorageID({}).subscribe((res: Response) => {                    
      this.storage = res['storage'];    

      this.type = this.injected_data['type'];
      this.files = this.injected_data['value'].split('|');

      /* check extension */
      let ext = (this.files[0]).split('.');
      if (ext[ext.length-1]=='pdf'||ext[ext.length-1]=='PDF') {
        this.documentType = "pdf";
        console.log(this.documentType);
      }
      
      if (this.documentType == "pdf") {      
        this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.displayImage(this.files[0]));
        console.log(this.sanitizedPdfUrl);
      }
    });    
  }
}
