<div class="inner_wrapper">
    <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="left">
        <h1>
            {{'Submit Form'|translate}}
        </h1>
        </div>

        <div class="right">            
            <fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
        </div>
    </div>

    <div class="inner_scroll">
        <div class="items">
            <div class="item" *ngFor="let item of formContent; let i = index">
                <div class="item_title">
                    {{formContent[i].title}}
                </div>
                <div class="item_subtitle">
                    {{formContent[i].subtitle}}
                </div>
                
                <div *ngIf="item.function=='text'" class="item_editfield">
                    <input type="text" />
                </div>

                <div *ngIf="item.function=='textarea'" class="item_editfield">
                    <textarea></textarea>
                </div>
                
                <div *ngIf="item.function=='checkbox'" class="item_editfield">
                    <ng-container *ngFor="let checkbox of item.defaultValues">
                        <label class="switch">
                            <input type="checkbox" [checked]="checkbox['checked']" />
                            <span class="slider round"></span>
                        </label>
                        {{checkbox['text']}}
                    </ng-container>
                </div>

                <div *ngIf="item.function=='options'" class="item_editfield">
                    <fieldset>
                        <ng-container *ngFor="let checkbox of item.defaultValues">
                            <input type="radio" id="test" name="test" value="test"/> 
                            {{checkbox['text']}}
                        </ng-container>
                    </fieldset>
                </div>

                <div *ngIf="item.function=='dropdown'" class="item_editfield">
                    <select name="cars" id="cars">
                        <ng-container *ngFor="let checkbox of item.defaultValues">
                            <option value="{{checkbox['text']}}">{{checkbox['text']}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div class="line actions">
        <button>{{'Clear Form'|translate}}</button>
        <button>{{'Submit'|translate}}</button>
    </div>
</div>