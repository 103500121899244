import { Component, OnInit, Inject } from '@angular/core';
import { GlobalVariables } from '../../../services/data.globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SelectCustomerComponent } from '../../../customers/select-customer/select-customer.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AssignDateTimeComponent } from '../../../activities/activity/assign-date-time/assign-date-time.component';


@Component({
  selector: 'app-erp-filter',
  templateUrl: './erp-filter.component.html',
  styleUrls: ['./erp-filter.component.css']
})

export class ErpFilterComponent implements OnInit {

	public customer;
	public customer_id;
	public start_date_raw;
	public start_date;
	public end_date_raw;
	public end_date;
	public completed;
	public unpaid;
	public price_from;
	public price_to;	

	formData: UntypedFormGroup; 

  	constructor(
		@Inject(MAT_DIALOG_DATA) public injected_data,
		public translate: TranslateService,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<ErpFilterComponent>
	) { 
		this.formData = this.createFormGroup();
		translate.addLangs(['en', 'si']);
	}


	createFormGroup() {
		return new UntypedFormGroup({
			customer: new UntypedFormControl(),
			customer_id: new UntypedFormControl(),
			vat: new UntypedFormControl(),
			start_date: new UntypedFormControl(),
			start_date_raw: new UntypedFormControl(),
			end_date: new UntypedFormControl(),
			end_date_raw: new UntypedFormControl(),
			price_from_raw: new UntypedFormControl(),
			price_from: new UntypedFormControl(),
			price_to_raw: new UntypedFormControl(),
			price_to: new UntypedFormControl(),
			completed: new UntypedFormControl(),
			unpaid: new UntypedFormControl()
		});
	}

	selectCustomer() {
		var selectCustomerDialog = this.dialog.open(SelectCustomerComponent, {});		
		selectCustomerDialog.afterClosed().subscribe(result => {					
			if (!isNaN(result.entity)) {  
				console.log(result);
				this.customer = result.customer;
				this.customer_id = result.entity;
			}
		});		
	}

	removeCustomer() {
		this.customer = null;
		this.customer_id = null;
	}

	localDateFormat(date) {
		var datetime = new Date(date);   
		var options = { hour12: false };   
		return datetime.toLocaleString("sl-SI", options).replace('. ', '.').replace('. ', '.');
	}

	assignDateTime(entity, start, end, refresh, start_raw, end_raw) {
		var assignDateTimeDialog = this.dialog.open(AssignDateTimeComponent, {
			'width' : '400px',			
			data: { activity: entity, start_date : start, end_date : end, start_date_raw : start_raw, end_date_raw : end_raw, time : false }
		});    

		assignDateTimeDialog.afterClosed().subscribe(result => {
			//console.log(result);
			var entity = result.activity;
			var start_date = (result.start_date).split(' ');
			var end_date = (result.end_date).split(' ');
			var date = new Date(end_date[0]);
			this.start_date_raw = start_date[0];
			this.start_date = ((this.localDateFormat(start_date[0]+"T00:00:00")).split(' ')[0]).replace(',', '');			
			this.end_date_raw = end_date[0];
			this.end_date = ((this.localDateFormat(end_date[0]+"T00:00:00")).split(' ')[0]).replace(',', '');			
		});			
	}

	removeDateTime() {
		this.start_date_raw = null;
		this.start_date = null;
		this.end_date_raw = null;
		this.end_date = null;
	}

	confirm() {		

		var data = {};

		if (this.start_date_raw != null) {
			data['start_date_raw'] = this.start_date_raw;
			data['start_date'] = this.start_date; 
		}

		if (this.end_date_raw != null) {
			data['end_date_raw'] = this.end_date_raw;
			data['end_date'] = this.end_date;
		}

		if (this.customer != null) {
			data['customer'] = this.customer;
		}

		if (this.customer_id != null) {
			data['customer_id'] = this.customer_id;
		}

		if (this.completed == true) {
			data['completed'] = 1;
		}

		if (this.unpaid == true) {
			data['unpaid'] = 1;
		}

		if (this.price_to != null && this.price_to != "" && this.price_to != undefined) {
			data['price_to_raw'] = this.price_to;
			data['price_from_raw'] = this.price_from;
		}

		this.dialogRef.close(data);   
	}


	ngOnInit(): void {	
		this.start_date_raw = this.injected_data['start_date_raw'] != undefined ? this.injected_data['start_date_raw'] : null;
		this.start_date = this.injected_data['start_date'] != undefined ? this.injected_data['start_date'] : null;
		this.end_date_raw = this.injected_data['end_date_raw'] != undefined ? this.injected_data['end_date_raw'] : null;
		this.end_date = this.injected_data['end_date'] != undefined ? this.injected_data['end_date'] : null;

		this.customer = this.injected_data['customer'] != undefined ? this.injected_data['customer'] : null;
		this.customer_id = this.injected_data['customer_id'] != undefined ? this.injected_data['customer_id'] : null;
		
		this.completed = this.injected_data['completed'] != undefined ? this.injected_data['completed'] : null;
		this.unpaid = this.injected_data['unpaid'] != undefined ? this.injected_data['unpaid'] : null;

		this.price_to = this.injected_data['price_to_raw'] != undefined ? this.injected_data['price_to_raw'] : null;
		this.price_from = this.injected_data['price_from_raw'] != undefined ? this.injected_data['price_from_raw'] : null;
	}

}


