import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* Components */
import { ModalComponent } from '../../modal/modal.component';

/* Services */
import { NavigationService } from '../../services/navigation.service';

/* Translate Service */
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ai-chat',
  templateUrl: './ai-chat.component.html',
  styleUrls: ['./ai-chat.component.css']
})
export class AiChatComponent {

  constructor(    		
    private NavigationService: NavigationService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public translate: TranslateService
  ) {
    translate.addLangs(['en', 'si']);    
  }

  public output: string[] = ['<br />----------------------------------------<br /><b>Ai Chat / Helper tester and debugger.</b><br /><br />Write command or question bellow in field text.<br />For first step you can try with "help".<br/>Current active language: Slovenian'];
  public question: string[] = [];

  public chat = "";
  public simulate = false;

  private commandCombinations: { [key: string]: number } = { "open activities test": 3 };
  
  private commands = {    
    create: { keywords: ['dodaj', 'nov', 'ustvari', 'naredi', 'pripravi', 'kreiraj'], expectsParam: false },
    delete: { keywords: ['zbriši', 'odstrani', 'izbriši'], expectsParam: true },
    open: { keywords: ['odpri', 'pokaži', 'prikaži'], expectsParam: true },
    update: { keywords: ['posodobi', 'spremeni', 'uredi'], expectsParam: false },
  };

  private entities = {    
    customers: { phrases: ['partnerji','firme','podjetja','organizacije'], dependencyKeyword: true, expectsParam: false },
    activity: { phrases: ['aktivnost'], dependencyKeyword: true, expectsParam: true },
    activities: { phrases: ['aktivnosti'], dependencyKeyword: true, expectsParam: false },
    timetracking: { phrases: ['beleženje časa', 'merjenje časa'], dependencyKeyword: false, expectsParam: true },    
  };

  private dependencyKeyword = {
    customers: ['test'],
    activity: ['projekti', 'sestanki', 'test'], 
    activities: ['projekti', 'sestanki', 'test']    
  };

  private politePhrases = ['ali lahko', 'prosim', 'bi lahko', 'mogoče', 'takoj'];
  private unnecessaryWords = ['lep', 'hitri', 'izhodni'];

  private specialCommands = {
    detailedHelp: ['podrobna pomoč', 'detailna pomoč'],
    basicHelp: ['pomoč'],
    statistics: ['analiza', 'statistika', 'stat']
  };

  private navigations = {
    create : {
      timetracking: { type: 'popup', action: 'timetracking', component: null, data: null},
      activity: { type: 'popup', action: 'activity', component: null, data: null},      
    },
    open : {
      activities : { type: 'link', action: 'activities', component: null, data: null}
    }
  }

  private frequency = {open: 40, activity: 40, test: 30, customers: 1};

  /**************************************************/
  /**************************************************/
  /**************************************************/

  onExecute(value: string): void {
    const normalizedInput = value.trim().toLowerCase();
  
    if (this.isSpecialCommand(normalizedInput, this.specialCommands.detailedHelp)) {
      this.provideHelp(true);
      return;
    }
  
    if (this.isSpecialCommand(normalizedInput, this.specialCommands.basicHelp)) {
      this.provideHelp(false);
      return;
    }

    if (this.isSpecialCommand(normalizedInput, this.specialCommands.statistics)) {
      this.provideStatistics();
      return;
    }
  
    const commandData = this.recognizeCommand(normalizedInput);
    const words = normalizedInput.split(' ');
  
    if (commandData && (commandData.action || commandData.entity)) {

      const { action, entity, param1 } = commandData;
      const entityData = entity ? this.entities[entity] : null;
      
      /*
      if (action) { this.updateFrequency(action); }
      if (entity) { this.updateFrequency(entity); }  
      if (param1) { this.updateFrequency(param1); }
      */
   
      if (action && entity) {
        
        if (entityData?.dependencyKeyword && !param1) {
          this.output.unshift(`You need to specify available category for <b>${entity}</b>.`);
        } else if (this.commands[action]?.expectsParam && entityData?.expectsParam && !param1) {
          this.output.unshift(`You need to specify parameter for <b>${action} ${entity}</b>.`);
        } else {
          this.output.unshift(
            `Recognized command: <b>${action} ${entity} ${param1 ? param1 : ''}</b>`
          );
          console.log(this.frequency);
          if (this.navigations[action] && this.navigations[action][entity] !== undefined) {            
            this.updateCommandCombination(action, entity, param1);
            const navigationHelper = this.navigations[action][entity];
            this.navigate(navigationHelper.type, navigationHelper.action, navigationHelper.component, param1);
          }
        }

      } else if (entity && !action) {
        
        const availableCommands = this.findAvailableCommandsForEntity(entity);
        if (availableCommands.length > 0) {
          this.output.unshift(
            `What do you want to do with <b>"${entity}"</b>? You can for example: <b>${availableCommands.join(', ')}</b>.`
          );
          return;
        }
      }
      

    } else {

      const combinedMatch = this.findHighestFrequencyCombination(words);
      if (combinedMatch) {

        this.output.unshift(
          `Did you mean: <b>${combinedMatch}</b>? Please confirm to execute this command.`
        );        
        this.awaitConfirmation(combinedMatch);

      } else {
        
        const bestCombination = this.findBestCommandCombination(words);
        if (bestCombination) {
          const { action, entity, param1 } = bestCombination;
          this.output.unshift(
            `Did you mean: <b>${action} ${entity} ${param1 ? param1 : ''}</b>? Executing the most frequently used combination.`
          );
          this.updateCommandCombination(action, entity, param1);
          if (this.navigations[action] && this.navigations[action][entity] !== undefined) {
            this.updateCommandCombination(action, entity, param1);
            const navigationHelper = this.navigations[action][entity];
            this.navigate(navigationHelper.type, navigationHelper.action, navigationHelper.component, param1);
          }
          return;
        }
          
        const closestEntities = this.findClosestMatchesInEntities(words, this.entities);
        const closestCommands = this.findAllMatchesInCommands(words, this.commands);
  
        let suggestionMessage = 'I do not understand wht you mena with "'+value+'"';
        if (closestEntities.length > 0) {
          suggestionMessage += `Did you mean: ${closestEntities.join(', ')}?`;
        }
        if (closestCommands.length > 0) {
          suggestionMessage += ` Did you mean: ${closestCommands.join(', ')}?`;
        }        
        this.output.unshift(suggestionMessage);
      }
    }
  }
  
  /**************************************************/
  /**************************************************/
  /**************************************************/
    
  private updateFrequency(key: string): void {
    if (this.frequency[key]) {
      this.frequency[key]++;
    } else {
      this.frequency[key] = 1;
    }
  }

  private updateCommandCombination(action: string | null, entity: string | null, param1: string | null): void {
    if (action && entity && this.navigations[action] && this.navigations[action][entity]) {
      const combinationKey = `${action} ${entity} ${param1 || ''}`.trim();
      if (this.commandCombinations[combinationKey]) {
        this.commandCombinations[combinationKey]++;
      } else {
        this.commandCombinations[combinationKey] = 1;
      }
    }
    console.log( this.commandCombinations);
  }
    
  private findBestCommandCombination(words: string[]): { action: string; entity: string; param1: string | null } | null {
    let highestFrequency = 0;
    let bestCombination: { action: string; entity: string; param1: string | null } | null = null;
  
    for (const [key, value] of Object.entries(this.commandCombinations)) {
      const keyWords = key.split(' ');
      const commonWords = words.filter(word => keyWords.includes(word));
      if (commonWords.length > 0 && typeof value === 'number') {
        if (value > highestFrequency) {
          highestFrequency = value;
          const [action, entity, param1] = keyWords;
          bestCombination = { action, entity, param1: param1 || null };
        }
      }
    }
  
    return bestCombination;
  }
    
  private findHighestFrequencyCombination(words: string[]): string | null {
    let highestFrequency = 0;
    let bestCombination: string | null = null;
  
    for (const [key, value] of Object.entries(this.commandCombinations)) {
      const keyWords = key.split(' ');
      const commonWords = words.filter(word => keyWords.includes(word));
      if (commonWords.length > 0 && typeof value === 'number') {
        const [action, entity] = keyWords;
        if (this.navigations[action] && this.navigations[action][entity]) {
          if (value > highestFrequency) {
            highestFrequency = value;
            bestCombination = key;
          }
        }
      }
    }
  
    return bestCombination;
  }
  
  /**************************************************/
  /**************************************************/
  /**************************************************/
      
  private awaitConfirmation(command: string): void {
    var content = this.translate.instant( `Did you mean: <b>${command}</b>?`);
    var modalDialog = this.dialog.open(ModalComponent, {
      data: { content: content, yes: this.translate.instant("Confirm") , no: this.translate.instant("Discart"), cancel: this.translate.instant("Ignore") }
    });

    modalDialog.afterClosed().subscribe(result => {        
      if (result['no']!=undefined) {
        this.output.unshift(`Command "${command}" was not confirmed and will not be executed.`);
      }

      if (result['yes']!=undefined) {
        this.output.unshift(`Executing command: <b>${command}</b>`);
        this.updateFrequency(command);
        const [action, entity, ...paramParts] = command.split(' ');
        const param1 = paramParts.length > 0 ? paramParts.join(' ') : null;
        if (action && entity) {
          if (this.navigations[action] && this.navigations[action][entity]) {
            const navigationHelper = this.navigations[action][entity];
            this.navigate(navigationHelper.type, navigationHelper.action, navigationHelper.component, param1);
          }
        }
      }        
    });
  }     
  
  /**************************************************/
  /**************************************************/
  /**************************************************/
  
  findAllMatchesInCommands(words: string[], commands: any): string[] {
    const matches: string[] = [];
  
    for (const command in commands) {
      const commandWords = command.toLowerCase().split(' ');
        if (words.every((word) => commandWords.includes(word))) {
        matches.push(command);
      }
    }
  
    return matches;
  }
  
  /*
  private isRecognized(word: string): boolean {
    return (
      Object.values(this.commands).some(command => command.keywords.includes(word)) ||
      Object.values(this.entities).some(entity => entity.phrases.includes(word))
    );
  }
  
  private findEntitiesWithCategory(category: string): string[] {
    const matchingEntities: string[] = [];
  
    for (const [entityKey, categoryList] of Object.entries(this.dependencyKeyword)) {
      if (categoryList.includes(category)) {
        matchingEntities.push(entityKey);
      }
    }
  
    return matchingEntities;
  }
  */

  private findAvailableCommandsForEntity(entity: string): string[] {
    const availableCommands: string[] = [];
  
    for (const [command, commandData] of Object.entries(this.commands)) {
      if (this.navigations[command] && this.navigations[command][entity]) {
        availableCommands.push(command);
      }
    }
  
    return availableCommands;
  }

  /**************************************************/
  /**************************************************/
  /**************************************************/
  /* Excecute add - on */

  /****/
  private isSpecialCommand(input: string, commands: string[]): boolean {
    return commands.some(command => this.isSimilar(input.toLowerCase(), command));
  }

  /****/
  private recognizeCommand(input: string): { action: string | null, entity: string | null, param1: string | null } {
    const cleanedInput = this.cleanInput(input);
    const words = cleanedInput.split(' ');

    const action = this.findAction(words);
    const entity = this.findSimilarMatch(words, this.entities);
    const param1 = this.extractDependency(words, entity) || this.extractNumber(words);

    return { action, entity, param1 };
  }

  private findClosestMatchesInEntities(words: string[], target: { [key: string]: { phrases: string[] } }): string[] {
    return this.findClosestMatches(words, target, 'phrases');
  }

  private findClosestMatchesInCommands(words: string[], target: { [key: string]: { keywords: string[] } }): string[] {
    return this.findClosestMatches(words, target, 'keywords');
  }

  /* SubFunctions */

  private findClosestMatches(words: string[], target: { [key: string]: any }, key: string): string[] {
    const matches: { phrase: string, distance: number }[] = [];
  
    for (const item of Object.values(target)) {
      for (const phrase of item[key]) {
        for (const word of words) {
          const distance = this.levenshteinDistance(word, phrase);
          if (distance <= this.getTolerance(phrase)) {
            matches.push({ phrase, distance });
          }
        }
      }
    }
  
    matches.sort((a, b) => a.distance - b.distance);
    return matches.slice(0, 3).map(match => match.phrase);
  }

  private findAction(words: string[]): string | null {
    for (const word of words) {
      for (const [key, { keywords }] of Object.entries(this.commands)) {
        if (keywords.some(phrase => this.isSimilar(word, phrase))) {
          return key;
        }
      }
    }
    return null;
  }

  private findSimilarMatch(words: string[], target: { [key: string]: { phrases: string[]; dependencyKeyword?: boolean; expectsParam?: boolean } }): string | null {
    const matches: { key: string, distance: number }[] = [];
  
    for (const word of words) {
      for (const [key, { phrases }] of Object.entries(target)) {
        for (const phrase of phrases) {
          const distance = this.levenshteinDistance(word, phrase);
          if (distance <= this.getTolerance(phrase)) {
            matches.push({ key, distance });
          }
        }
      }
    }
  
    if (matches.length === 0) {
      return null;
    }
  
    matches.sort((a, b) => a.distance - b.distance);
    return matches[0].key;
  }
  
  private extractDependency(words: string[], entity: string | null): string | null {
    if (entity && this.entities[entity]?.dependencyKeyword) {
      const dependencyOptions = this.dependencyKeyword[entity.toLowerCase()];
      for (const word of words) {
        for (const option of dependencyOptions) {
          if (this.isSimilar(word, option)) {
            return option;
          }
        }
      }
    }
    return null;
  }


  /**************************************************/
  /**************************************************/
  /**************************************************/
  /* Common Helpers */

  private isSimilar(word: string, target: string): boolean {
    const tolerance = this.getTolerance(target);
    return this.levenshteinDistance(word, target) <= tolerance;
  }

  private cleanInput(input: string): string {
    let normalizedInput = input.toLowerCase().trim();
    
    this.politePhrases.forEach(phrase => {
      normalizedInput = normalizedInput.replace(new RegExp(`\\b${phrase}\\b`, 'gi'), '');
    });

    const cleanedWords = normalizedInput.split(' ').filter(word => !this.unnecessaryWords.includes(word));
    
    return cleanedWords.join(' ');
  }

  private extractNumber(words: string[]): string | null {
    const numberPattern = /\d+/;
    return words.find(word => numberPattern.test(word)) || null;
  }

  private levenshteinDistance(a: string, b: string): number {
    const matrix: number[][] = [];
    for (let i = 0; i <= a.length; i++) matrix[i] = [i];
    for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        const cost = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + cost
        );
      }
    }
    return matrix[a.length][b.length];
  }

  private getTolerance(word: string): number {
    if (word.length <= 4) {
      return 1;
    } else if (word.length <= 7) {
      return 2;
    } else {
      return 3;
    }
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  /**************************************************/
  /**************************************************/
  /**************************************************/
  /* Spectial functions */

  private provideHelp(detailed: boolean): void {
    let helpMessage = 'I can understand instruction like:<br />';
    
    Object.entries(this.commands).forEach(([command, { keywords }]) => {
      helpMessage += `- <b>${command}</b>`;
      if (detailed) {
        helpMessage += ` ( Keywords: ${keywords.join(', ')} )`;
      }
      helpMessage += `<br />`;
    });

    helpMessage += '<br />I can operate with entities like:<br />';

    Object.entries(this.entities).forEach(([entity, { phrases, dependencyKeyword }]) => {
      helpMessage += `- <b>${entity}</b>`;
      if (detailed) {
        helpMessage += ` (fraze: ${phrases.join(', ')})`;
      }
      if (dependencyKeyword) {
        const dependencies = this.dependencyKeyword[entity.toLowerCase()];
        helpMessage += ` (Available categories: ${dependencies.join(', ')})<br />`;
      }
    });
    
    this.output.unshift(helpMessage);
  }
  
  private provideStatistics() {
    let helpMessage = 'AI Statistics:<br /><br />';
    helpMessage += 'Phrase combiantion frequency:<br />';
       
    for (let key in this.commandCombinations) {
      if (this.commandCombinations.hasOwnProperty(key)) {
        helpMessage += `<b>${key} : ${this.commandCombinations[key]}</b><br />`;
      }
    }

    helpMessage += '<br />';
    helpMessage += 'Keyword frequency:<br />';

    for (let key in this.frequency) {
      if (this.frequency.hasOwnProperty(key)) {
        helpMessage += `<b>${key} : ${this.frequency[key]}</b><br />`;
      }
    }

    helpMessage += `<br />`;

    this.output.unshift(helpMessage);
  }

  /**************************************************/
  /**************************************************/
  /**************************************************/
  /* Navigation */

  navigate(type, action, component, data) {   
		if (this.simulate) this.NavigationService.navigate(type, action, component, data);    
    this.output.unshift(`Navigate > type=<b>${type}</b>; action=<b>${action}</b>; component=<b>${component}</b>; data:<b>${data}</b>`);
    this.chat = "";
	}

}