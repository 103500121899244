import { Component, OnInit, Input, Inject } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctions } from '../../../services/common.functions';

@Component({
  selector: 'app-erp-richitems-filter',
  templateUrl: './erp-richitems-filter.component.html',
  styleUrl: './erp-richitems-filter.component.css'
})
export class ErpRichitemsfilterComponent implements OnInit {

  public categories = [];
  public types = [];
  public filter = { category: null, type: null, enabled: null };

  constructor(
    private data: DataService,
    public cFunction: CommonFunctions,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public injected_data,		
		public dialogRef: MatDialogRef<ErpRichitemsfilterComponent>
  ) {
    translate.addLangs(['en', 'si']);
  }

  /**************************/
  updateType(value) {    
    //console.log((value.target as HTMLSelectElement).value);
  }
 
  /**************************/
  confirm() {    
    let exportFilterSettings = {};    
    for(let item in this.filter) {
      if (this.filter[item] != null) {        
        exportFilterSettings[item] = this.filter[item];
      }
    }    
    this.dialogRef.close(exportFilterSettings);
  }

  clear() {    
    this.dialogRef.close({});
  }

  /**************************/
  ngOnInit(): void {
    
    this.data.getQuoteItemCategories({}).subscribe(
      (res: Response) => {          
        let items = (<any>res).rows;
        this.categories = [];
        this.types = [];
        for (let i=0; i<items.length; i++) {
          if (!this.categories.includes(items[i]['category'])) {
            this.categories.push(items[i]['category']);
            this.types[items[i]['category']] = [];
          }
        }

        for (let i=0; i<items.length; i++) {            
           this.types[items[i]['category']].push(items[i]['type']);
        }               
        
        if (this.injected_data!=undefined) this.filter = this.injected_data;
      }
    );    

  }

}
