<div class="inner_wrapper">
	<form [formGroup]="formData" (ngSubmit)="save()" novalidate>	
		<div class="widget_settings">
			<div class="innerPopup header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
				<div class="left">
					<h1>{{'Widget settings'|translate}}</h1>
				</div>
				<div class="right">
					<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
				</div>
			</div>
			<div class="body">

				<div class="inline w100 wPadding">               
					<mat-form-field appearance="outline">
					  <mat-label>{{'Widget title'|translate}}</mat-label>
					  <input type="text" matInput formControlName="title" name="title" [(ngModel)]="settings.title">
					  <!--<mat-hint>Error</mat-hint>-->
					</mat-form-field>
				  </div>

				<div class="multi_line" style="padding-top: 24px; margin-top:0px; padding-bottom: 0px; border-top: 1px solid var(--border-color)">
					<div class="field" style="width: 100%">
						<label>{{'Available activities'|translate}}</label>
	
						<div class="multiselect_checkbox categories">
							<ng-container *ngFor="let category of categories">
							<div class="checkbox_wrapper">
								<label class="switch">
									<input type="checkbox" class="dummy" [attr.rel]="category.entity" [checked]="category.selected">
									<span class="slider round"></span>
								</label>
								<div class="label"><span class="category_bubble" style="background:{{category.hex}}"></span>{{category.description}}</div>
							</div>
							</ng-container>		
	
						</div>
					</div>
				</div>
	
				<div class="line" style="padding-top: 24px; margin-top:6px; padding-bottom: 12px; border-top: 1px solid var(--border-color)">
					<div class="field">
						<label class="w100 inline" style="padding-top:9px;">{{'Content sorting'|translate}}</label>

						<div class="inline w70 wPadding">
							<mat-form-field appearance="outline">
							  <mat-select formControlName="order" name="order" [(ngModel)]="settings.order">
								<mat-option value="updated">{{'Po zadnji spremembi aktivnosti'|translate}}</mat-option>
								<mat-option value="priority" class="selected" selected="" rel="priority">{{'Po prioriteti aktivnosti'|translate}}</mat-option>
								<mat-option value="message_start">{{'Po času začetka izvajanja aktivnosti'|translate}}</mat-option>
								<mat-option value="message_end">{{'Po času konca izvajanja aktivnosti'|translate}}</mat-option>                  
							  </mat-select>
							<!--<mat-hint">Error</mat-hint>-->
							</mat-form-field>
						</div>

						<div class="inline w20 wPadding">
							<mat-form-field appearance="outline">
							  <mat-select formControlName="orderby" name="orderby" [(ngModel)]="settings.orderby">
								<mat-option value="asc">{{'Ascending'|translate}}</mat-option>
								<mat-option value="desc">{{'Descending'|translate}}</mat-option>     
							  </mat-select>
							<!--<mat-hint">Error</mat-hint>-->
							</mat-form-field>
						</div>

						<div class="inline w10 wPadding">
							<mat-form-field appearance="outline">
							  <mat-select formControlName="items" name="items" [(ngModel)]="settings.items">
								<mat-option value="6">6</mat-option>
								<mat-option value="12">12</mat-option>
								<mat-option value="24">24</mat-option>          
							  </mat-select>
							<!--<mat-hint">Error</mat-hint>-->
							</mat-form-field>
						</div>
					</div>
				</div>
	
				<!-- Basic settings-->
				<ng-container *ngIf="settingsView=='basic'"> 
				</ng-container> <!-- Basic settings-->
	
				<!-- Advanced settings-->
				<ng-container *ngIf="settingsView=='advanced'"> 
					<div class="multi_line" style="padding-top: 24px; margin-top:6px; padding-bottom: 0px; border-top: 1px solid var(--border-color)">
						<label>{{'Show activities'|translate}}</label>
						
						<div class="field">
							<label class="switch">
								<input type="checkbox" formControlName="featured" name="featured" [(ngModel)]="settings.featured">
								<span class="slider round"></span>
							</label>
							<div class="label label-checkbox">{{'Featured only'|translate}}</div>	
						</div>	
	
						<div class="field">
							<label class="switch">
								<input type="checkbox" formControlName="assigned" name="assigned" [(ngModel)]="settings.assigned">
								<span class="slider round"></span>
							</label>
							<div class="label label-checkbox">{{'Assigned only'|translate}}</div>				
						</div>
	
						<div class="field">
							<label class="switch">
								<input type="checkbox" formControlName="author" name="author" [(ngModel)]="settings.author">
								<span class="slider round"></span>
							</label>
							<div class="label label-checkbox">{{'Created by me'|translate}}</div>
						</div>
					</div>
	
					<div class="multi_line" style="padding-top: 6px;">
						<label>&nbsp;</label>
						<div class="field">
							<label class="switch">
								<input type="checkbox" formControlName="hidden" name="hidden" [(ngModel)]="settings.hidden">
								<span class="slider round"></span>
							</label>
							<div class="label label-checkbox">{{'Hidden'|translate}}</div>
						</div>
						<div class="field">
							<label class="switch">
								<input type="checkbox" formControlName="unapproved" name="unapproved" [(ngModel)]="settings.unapproved">
								<span class="slider round"></span>
							</label>
							<div class="label label-checkbox">{{'Un-Approved by all'|translate}}</div>
						</div>
					</div>
	
	
					<div class="multi_line" style="padding-top: 24px; margin-top:0px; padding-bottom: 0px; border-top: 1px solid var(--border-color)">
						<label>{{'Hide activities by'|translate}}</label>
	
						<div class="field">
							<label class="switch">
								<input type="checkbox" formControlName="approvedbyme" name="approvedbyme" [(ngModel)]="settings.approvedbyme">
								<span class="slider round"></span>
							</label>
							<div class="label label-checkbox">{{'Approved by me'|translate}}</div>
						</div>
	
						<div class="field">
							<label class="switch">
								<input type="checkbox" formControlName="approved" name="approved" [(ngModel)]="settings.approved">
								<span class="slider round"></span>
							</label>
							<div class="label label-checkbox">{{'Approved by all'|translate}}</div>
						</div>
	
						<div class="field">
							<label class="switch">
								<input type="checkbox" formControlName="removeold" name="removeold" [(ngModel)]="settings.removeold">
								<span class="slider round"></span>
							</label>
							<div class="label label-checkbox">{{'Activity expired'|translate}}</div>
						</div>
					</div>
	
					<div class="multi_line" style="padding-top: 6px;">
						<label>&nbsp;</label>
						<div class="field">
							<label class="switch">
								<input type="checkbox" formControlName="hidechild" name="hidechild" [(ngModel)]="settings.hidechild">
								<span class="slider round"></span>
							</label>
							<div class="label label-checkbox">{{'Sub activities'|translate}}</div>
						</div>
					</div>
	
					<div class="multi_line" style="padding-top: 24px; margin-top:0px; padding-bottom: 0px; border-top: 1px solid var(--border-color)">
						<label>{{'Combine activities by'|translate}}</label>
	
						<div class="field">
							<label class="switch">
								<input type="checkbox" formControlName="combinecustomer" name="combinecustomer" [(ngModel)]="settings.combinecustomer">
								<span class="slider round"></span>
							</label>
							<div class="label label-checkbox">{{'Customer'|translate}}</div>
						</div>
									
					</div>
				</ng-container> <!-- advanced settings -->
	
				<ng-container> <!-- basic & advanced -->
					<div class="multi_line" style="padding-top: 24px; margin-top:6px; padding-bottom: 12px; border-top: 1px solid var(--border-color)">
						<label>{{'Show extended view'|translate}}</label>
						<label class="switch">
							<input type="checkbox" formControlName="extended" name="extended" [(ngModel)]="settings.extended">
							<span class="slider round"></span>
						</label>			
					</div>
	
					<ng-container *ngIf="settings.extended"> <!-- extended view -->
						<div class="multi_line" style="padding-top: 24px; margin-top:6px; padding-bottom: 12px; border-top: 1px solid var(--border-color)">
							<label>{{'Show dates'|translate}}</label>
							<div class="field">
								<label class="switch">
									<input type="checkbox" formControlName="created" name="created" [(ngModel)]="settings.created">
									<span class="slider round"></span>
								</label>				
								<div class="label label-checkbox">
									{{'Created'|translate}}
								</div>	
							</div>								
							<div class="field">
								<label class="switch">
									<input type="checkbox" formControlName="date_from" name="date_from" [(ngModel)]="settings.date_from">
									<span class="slider round"></span>
								</label>				
								<div class="label label-checkbox">
									{{'Activity Start'|translate}}
								</div>	
							</div>	
							<div class="field">
								<label class="switch">
									<input type="checkbox"  formControlName="date_to" name="date_to" [(ngModel)]="settings.date_to">
									<span class="slider round"></span>
								</label>				
								<div class="label label-checkbox">
									{{'Activity End'|translate}}
								</div>	
							</div>		
						</div>
	
						<div class="multi_line" style="padding-bottom: 12px;">
							<label>{{'Show other details'|translate}}</label>
	
							<label class="switch">
								<input type="checkbox" formControlName="status" name="status" [(ngModel)]="settings.status">
								<span class="slider round"></span>
							</label>				
							<div class="label label-checkbox">
								{{'Activity status'|translate}}
							</div>	
	
							<label class="switch">
								<input type="checkbox" formControlName="priority" name="priority" [(ngModel)]="settings.priority">
								<span class="slider round"></span>
							</label>				
							<div class="label label-checkbox">
								{{'Activity priority'|translate}}
							</div>		
						</div>
	
	
						<div class="multi_line">
							<label>&nbsp;</label>
	
							<label class="switch">
								<input type="checkbox" formControlName="showauthor" name="showauthor" [(ngModel)]="settings.showauthor">
								<span class="slider round"></span>
							</label>				
							<div class="label label-checkbox">
								{{'Author'|translate}}
							</div>	
	
							<label class="switch">
								<input type="checkbox" formControlName="showcustomer" name="showcustomer" [(ngModel)]="settings.showcustomer">
								<span class="slider round"></span>
							</label>				
							<div class="label label-checkbox">
								{{'Customer'|translate}}
							</div>	
						</div>
	
						<div class="multi_line">
							<label>&nbsp;</label>
	
							<label class="switch">
								<input type="checkbox" formControlName="showprogress" name="showprogress" [(ngModel)]="settings.showprogress">
								<span class="slider round"></span>
							</label>				
							<div class="label label-checkbox">
								{{'Progress'|translate}}
							</div>	
	
							<label class="switch">
								<input type="checkbox" formControlName="sub_activities" name="sub_activities" [(ngModel)]="settings.sub_activities">
								<span class="slider round"></span>
							</label>				
							<div class="label label-checkbox">
								{{'Sub-Activities'|translate}}
							</div>
						</div>
				
	
						<div class="multi_line" style="padding-top: 16px;border-top: 1px solid var(--border-color)">
							<label>{{'Show comments'|translate}}</label>
							<!--
							<div class="field">
								<input type="checkbox" formControlName="comments" name="comments" [(ngModel)]="settings.comments">
								<div class="label label-checkbox"></div>	
							</div>
							-->
							<!--
							#
							<select style="margin-left:6px;" formControlName="comments_results" name="comments_results" [(ngModel)]="settings.comments_results">
								<option value="1">Last 1</option>
								<option value="2">Last 2</option>
								<option value="3">Last 3</option>
								<option value="1000">All</option>
							</select>
							-->
							<label class="switch">
								<input type="checkbox" formControlName="showcomments" name="showcomments" [(ngModel)]="settings.showcomments">
								<span class="slider round"></span>
							</label>
						</div>
					</ng-container> <!-- extended view -->
				</ng-container> <!-- basic & advanced -->
	
			</div>
			<div class="modalActions">
				<button class="accept right" type="submit" mat-button>{{'Confirm'|translate}}</button>
	
				<button class="action left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
				<button *ngIf="settingsView=='basic'" class="action left" (click)="settingsView='advanced'">{{'Advanced'|translate}}</button>
				<button *ngIf="settingsView=='advanced'" class="action left" (click)="settingsView='basic'">{{'Basic'|translate}}</button>				
			</div>
		</div>
	</form>
</div>