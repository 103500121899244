import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-widget-last-settings',
  templateUrl: './widget-last-settings.component.html',
  styleUrls: ['./widget-last-settings.component.css']
})

export class WidgetLastSettingsComponent implements OnInit {

  formData: UntypedFormGroup;

  constructor(
	  private data: DataService,
    public dialog: MatDialogRef<WidgetLastSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public injected_data,
    public translate: TranslateService 
  ) { 
    this.formData = this.createFormGroup();
    translate.addLangs(['en', 'si']);
  }

  public settings = {title:'Widget title...',assigned:1,viewed:1,days:7}

  createFormGroup() {
    return new UntypedFormGroup({
      title: new UntypedFormControl(),
      assigned: new UntypedFormControl(),
      viewed: new UntypedFormControl(),
      days: new UntypedFormControl()
    });
  }

  save() {
     var parameters = { data: Object.assign({}, this.formData.value) };
     this.dialog.close(parameters);
  };

  ngOnInit(): void {
    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);
    this.settings = this.injected_data;
  }

}
