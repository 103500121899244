<div class="widget_wrapper" id="{{id}}" [attr.refresh]="refresh" [attr.parameters]="parameters_json" (mouseover)="mouseover=true" (mouseout)="mouseover=false">
	<div class="widget_header" cdkDrag cdkDragRootElement=".dashboard_widget" cdkDragHandle>
		<div class="widget_refresh_bar">
			<!--<div class="widget_refresh_bar_progress" style="width:{{refresh_percent}}%"></div>-->
		</div>
		<div class="widget_title">
			{{parameters?.title}}
		</div>
		<div class="widget_tools">			
			<fa-icon class="icon_medium" [icon]="['fad', 'times']" (click)="remove(id)" style="margin-top: 5px;"></fa-icon>
			<div class="divider_header"></div>
			<fa-icon [icon]="['fad', 'cog']" (click)="settings(id, parameters_json)"></fa-icon>
		</div>
	</div>

	<div class="widget_content" [ngClass]="{'simple_activity_view' : parameters?.extended != true, 'grouped':parameters?.combinecustomer==true}">

		<div *ngIf="rows.length==0" class="widget_item_empty" style="padding:20px 20px 10px 20px;color:var(--dark-lvl3);">
			{{'There is currently no activities based on your parameters.'|translate}}
		</div>

		<div class="widget_item {{item.entity}}" *ngFor="let item of rows; let i = index" [ngClass]="{'stick_up':parameters.combinecustomer==true&&rows[i-1]?.customer==rows[i]?.customer && rows[i-1]?.customer!=undefined}" (click)="edit(item.entity)">
			<div class="item_left" *ngIf="parameters.extended">								
				<div *ngIf="parameters.created" class="created"> 
					<ng-container>
						<fa-icon class="icon_small" style="float:left;" [icon]="['fad', 'calendar-day']"></fa-icon>
						<span style="float:left;width:calc(100% - 25px); padding-left: 10px; padding-top:2px;">{{localDateFormat(item.created)}}</span>						
					</ng-container>
				</div>

				<div *ngIf="item.tasks_count!=null && item.tasks_count!='' && parameters.showprogress" class="progress">
					<div class="progress_container">
						<div class="progres_percent" [style.width.%]="toPercent(item.tasks_count)"></div>
					</div>				
				</div>				

				<div class="status" *ngIf="item.activity_status_text!=''">
					<span [ngStyle]="{'background-color':item.activity_status_hex}">{{item.activity_status_text}}</span>
				</div>
				<div class="due_start" *ngIf="parameters.date_from && !cFunction.checkIfPassDate(item.message_start, null) && item.message_start != '0000-00-00 00:00:00'">
					<fa-icon class="columns icon_small" [icon]="['fad', 'arrow-from-left']" [ngClass]="{'blink' : item.due_start_pass && !item.due_end_pass}"></fa-icon>										
					{{cFunction.calculateTimeToEvent(item.message_start, null, null)}}						
				</div>
				<div class="due_end" *ngIf="parameters.date_to && item.message_end != '0000-00-00 00:00:00'">
					<fa-icon class="columns icon_small" [icon]="['fad', 'arrow-to-right']" [ngClass]="{'inzone' : cFunction.checkIfPassDate(item.message_start, null) && !cFunction.checkIfPassDate(item.message_end, null), 'outofzone' : cFunction.checkIfPassDate(item.message_start, null) && cFunction.checkIfPassDate(item.message_end, null), 'blink' : cFunction.checkIfPassDate(item.message_start, null)}"></fa-icon>					
					{{cFunction.calculateTimeToEvent(item.message_end, null, null)}}
				</div>

				<div class="assigned">
					<ng-container *ngTemplateOutlet="activity_assigned_template; context:{ $implicit: $any(item) }"></ng-container>
				</div>

				<div *ngIf="item.need_approval=='1'" class="activity_widget_tool">
					<fa-icon *ngIf="item.need_approval=='1' && item.approved_by_me=='1' && item.all_users_approved!='1'" [icon]="['fad', 'check-circle']" style="color:var(--approved)"></fa-icon>
					<span *ngIf="item.need_approval=='1' && item.approved_by_me=='1' && item.all_users_approved!='1'"> {{'Me'|translate}}</span>
					<fa-icon *ngIf="item.need_approval=='1' && item.all_users_approved=='1' && item.approved_by_me=='1'" [icon]="['fas', 'check-circle']" style="color:var(--approved)"></fa-icon>
					<span *ngIf="item.need_approval=='1' && item.all_users_approved=='1' && item.approved_by_me=='1'"> {{'All'|translate}}</span>
					<fa-icon *ngIf="item.need_approval=='1' && item.approved_by_me!='1'" [icon]="['fad', 'check-circle']" (click)="toggleConfirm(item.entity);$event.stopPropagation();"></fa-icon>
					<span *ngIf="item.need_approval=='1' && item.approved_by_me!='1'" (click)="toggleConfirm(item.entity);$event.stopPropagation();"> {{'Approve'|translate}}</span>
				</div>				
			</div>

			<div class="item_divider" [ngStyle]="{'background-color':item.message_type_hex}"></div>

			<div class="item_right">				
				<div class="right_limit_container">										
					<div class="company" *ngIf="parameters.showcustomer && item.customer != undefined">
						<fa-icon class="icon_small" [icon]="['fad', 'users']" style="font-size: 12px;"></fa-icon>
						{{item.customer}}
					</div>
					<div class="item_divider mobile" [ngStyle]="{'background-color':item.message_type_hex}"></div>
				
					<div class="author_and_category" *ngIf="parameters.showauthor">
						<fa-icon class="icon_small" [icon]="['fad', 'user']" style="font-size: 12px;margin:0px 4px 0px 2px"></fa-icon>
						<span>{{item.author}}</span>						
					</div>
					<div class="activity_title">
						<span [innerHTML]="cFunction.URLtoHYPERLINK(item.title)"></span>
						<fa-icon [icon]="['fad', 'flag']" class="priority_tag" [ngClass]="{'0':'priority0','1':'priority1','2':'priority2','3':'priority3'}[item.priority]" *ngIf="parameters.priority"></fa-icon>				
						<fa-icon [icon]="['fad', 'star']" *ngIf="item.featured != null"></fa-icon>
					</div>
					
					<div *ngIf class="activity_subtitle" [innerHTML]="cFunction.URLtoHYPERLINK(item.sub_title)"></div>
					
					<div *ngIf="parameters.showcomments && item.comments_array != undefined" class="subitem subcomments">						
						<div *ngFor="let comment of item?.comments_array | keyvalue" class="comment">
							<span style="background:{{(decodeCommentRaw(comment.value)[0]).split('|hex|')[1]}}">{{getInitials(decodeCommentRaw(comment.value)[0])}}</span>
							<span>{{localDateFormat(decodeCommentRaw(comment.value)[2])}}</span>
							<br />
							<span>{{decodeCommentRaw(comment.value)[1]}}</span>
							
						</div>
					</div>
										
					<!--<div class="activity_sub" *ngIf="parameters.extended && parameters.sub_activities">-->
						<!--
						<div class="subitem" *ngFor="let subitem of item?.sub_activity | keyvalue">
							<div class="approved">
								<fa-icon *ngIf="$any(subitem).value?.approved=='false'" [icon]="['fad', 'check-circle']" [ngClass]="{'checkedbyme' : $any(subitem).value.approved_by_me=='1'}" (click)="toggleConfirm($any(subitem).value.entity)"></fa-icon>
								<fa-icon *ngIf="$any(subitem).value?.approved=='true'" [icon]="['fad', 'check-circle']" [ngClass]="{'checkedbyme' : $any(subitem).value.approved=='true'}" (click)="toggleConfirm($any(subitem).value.entity)"></fa-icon>
							</div>						
							<div class="subactivity_title" (click)="edit($any(subitem).value.entity)">{{$any(subitem).value.title}}</div>
							<div class="subactivity_due">13 days</div>
							<div class="subactivity_cateogry" [ngStyle]="{'background-color':$any(subitem).value.category_type_hex}"></div>
						</div>
						-->
					<!--</div>-->
					
				</div>				
			</div>
		</div>
	</div>
</div>

<!-- *** -->
<ng-template #activity_assigned_template let-activity>
	<div class="cell_inner">
		<ng-container *ngFor='let assigned_users_name of ShowUserInicials(activity.assigned_users_names); let i = index'>		
			<span  class="user_bubble" style="background:{{activity?.assigned_users_names_hex?.split(',')[i]}}">
				{{assigned_users_name.initials}}
				<div class="tooltip">{{assigned_users_name.fullname}}</div>
			</span>
		</ng-container>
		<ng-container *ngFor='let assigned_group_name of ShowUserInicials(activity.assigned_group_names); let i = index'>
			<span  class="group_bubble" style="background:{{activity?.assigned_group_names_hex?.split(',')[i]}}">
				{{assigned_group_name.initials}}
				<div class="tooltip">{{assigned_group_name.fullname}}</div>
			</span>
		</ng-container>
	</div>
</ng-template> 