<!-- Recovery list -->
<div class="inner_wrapper">
	<div class="table_header enached_table_header">
		<div class="left">
			<div class="table_header_left">
				<div class="table_title">
					<fa-icon [icon]="['fad', 'trash-alt']"></fa-icon> | {{'Recovery options'|translate}}
				</div>
				<div class="table_functions">
				</div>
			</div>
		</div>
		<div class="right">
			<div class="table_header_right search_block icon">
				<span style="position:relative;">
				    <fa-icon [icon]="['fad', 'search']"></fa-icon>
				    <div class="tooltip">{{'Search'|translate}}</div>
				</span>
			</div>
			<div class="table_header_right search_block input">
				<input id="search" autocomplete="false" name="hidden" placeholder="{{'Search...'|translate}}" (keyup)="onSearch($event)" [value]="search" />
			</div>
		</div>
	</div>	

	<div class="table" name="contact-type">
		<div class="row header">
			<div class="col" name="deleted" description="deleted" (click)="changeOrder('deleted')">{{'Deleted'|translate}}</div>
            <div class="col" name="comment" description="comment" (click)="changeOrder('comment')">{{'Type'|translate}}</div>
            <div class="col" name="content" description="content">{{'Content'|translate}}</div>
            <div class="col" name="user" description="user" (click)="changeOrder('comment')">{{'User'|translate}}</div>
		</div>
		<div class="row header forSticky">
			<div class="col" name="deleted" description="deleted" (click)="changeOrder('deleted')">{{'Deleted'|translate}}</div>
            <div class="col" name="comment" description="comment" (click)="changeOrder('comment')">{{'Type'|translate}}</div>
            <div class="col" name="content" description="content">{{'Content'|translate}}</div>
            <div class="col" name="user" description="user" (click)="changeOrder('comment')">{{'User'|translate}}</div>
		</div>
		<div class="row" *ngFor="let item of items">			
			<div class="col" name="deleted" description="deleted" [attr.description]="inline_translation('deleted')" (click)="recover(item.entity, item.comment, item.content)">{{item.deleted}}</div>
            <div class="col" name="comment" description="comment" [attr.description]="inline_translation('comment')" (click)="recover(item.entity, item.comment, item.content)">{{item.comment}}</div>
            <div class="col" name="comment" description="content" [attr.description]="inline_translation('content')" (click)="recover(item.entity, item.comment, item.content)">{{item.content}}</div>
            <div class="col" name="user" description="user" [attr.description]="inline_translation('user')" (click)="recover(item.entity, item.comment, item.content)">{{item.user}}</div>			
		</div>
		<div class="row hidden">			
			<div class="col" name="deleted" description="deleted" (click)="changeOrder('deleted')">{{'Deleted'|translate}}</div>
            <div class="col" name="comment" description="comment" (click)="changeOrder('comment')">{{'Type'|translate}}</div>
            <div class="col" name="content" description="content">{{'Content'|translate}}</div>
            <div class="col" name="user" description="user" (click)="changeOrder('comment')">{{'User'|translate}}</div>
		</div>
	</div>

	<div class="rows_limit_wrapper">
		<span class="rows_limit" *ngFor="let item of pagination" [ngClass]="itemsLimit == item ? 'selected' : 'not-selected'" (click)="changeLimit(item)">{{item}}</span>
	</div>

	<div class="rows_view">{{'Show'|translate}} {{itemsLimit}} {{'of'|translate}} {{total_items}}</div>

	<div class="page_wrapper">
		<span class="page first" (click)="changePage(1)">
			<fa-icon [icon]="['fad', 'chevron-left']"></fa-icon>
		</span>
		<span class="page" *ngFor='let item of [].constructor(pages); let i = index' [ngClass]="page == i ? 'selected' : 'not-selected'" (click)="changePage(i)">{{i+1}}</span>
		<span class="page last" (click)="changePage(pages-1)">
			<fa-icon [icon]="['fad', 'chevron-right']"></fa-icon>
		</span>
	</div>
</div> 