import { Component } from '@angular/core';

@Component({
  selector: 'app-activities-analytics',
  templateUrl: './activities-analytics.component.html',
  styleUrls: ['./activities-analytics.component.css']
})
export class ActivitiesAnalyticsComponent {

}
