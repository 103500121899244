/*************************************/
/* Anglar                            */
import { Component, OnInit } from '@angular/core';

/*************************************/
/* Serives, Data manipulation        */
import { CommonFunctions } from '../services/common.functions';
import { LatestChangesService } from '../services/latest-changes.service';
import { NavigationService } from '../services/navigation.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrl: './new.component.css'
})
export class NewComponent {

  items: any[] = []; 
  public now = '2024-01-01 00:00:00';

  constructor(		
    public commonFunctions: CommonFunctions,
    private NavigationService: NavigationService,
    private LatestChangesService: LatestChangesService,
    private data: DataService
	) {	  
	}

  removeItem(index){
    this.items.splice(index, 1);

    const bellCounter = document.querySelector('.bellCounter');

    if (bellCounter) {
        let currentValue = parseInt(bellCounter.textContent, 10);
        if (!isNaN(currentValue)) {
            currentValue -= 1;
            bellCounter.textContent = currentValue.toString();
        } else {
          bellCounter.textContent = "?";
        }
    }
  }

  markAsRead(entity) {
    this.data.markAsRead({'data':{'entity':entity}}).subscribe((res: Response) => {});        
  }

  navigate(type, action, component, data) {
		this.NavigationService.navigate(type, action, component, data);
	}

  ngOnInit(): void { 

    const now = new Date();
    this.now = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
    
    /**************************************************/
		/* LatestChangesService */
		this.LatestChangesService.update();
		this.LatestChangesService.lastActivities.subscribe(message => {
			this.items = message;			
      console.log(this.items);
		});

  };

}
