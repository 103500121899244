import { Component, OnInit, Inject, Input } from '@angular/core';
import { GlobalVariables } from '../../../services/data.globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../../services/data.service';


@Component({
  selector: 'app-erp-richquote-item',
  templateUrl: './erp-richquote-item.component.html',
  styleUrl: './erp-richquote-item.component.css'
})
export class ErpRichquoteItemComponent {

  public categoriesLocal = [];
  public types = [];
  public categorySelected = "";

  constructor(
    private data: DataService,
		public dialogRef: MatDialogRef<ErpRichquoteItemComponent>,
		@Inject(MAT_DIALOG_DATA) public injected_data,
		public translate: TranslateService
	) { 		
		translate.addLangs(['en', 'si']);
	}

  save() {
    this.dialogRef.close(this.injected_data); 
  }

  cancel() {
    this.dialogRef.close(null); 
  }

  ngOnInit() {
		var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
   	this.translate.use(lang);   
    
    /* Initial data  */
    this.data.getQuoteItemCategories({}).subscribe(
      (res: Response) => {          
        let items = (<any>res).rows;
        this.categoriesLocal = [];
        this.types = [];
        for (let i=0; i<items.length; i++) {
          if (!this.categoriesLocal.includes(items[i]['category'])) {
            this.categoriesLocal.push(items[i]['category']);
            this.types[items[i]['category']] = [];
          }
        }

        for (let i=0; i<items.length; i++) {            
            this.types[items[i]['category']].push(items[i]['type']);
        }        
      }   
    );
  }		
	

  

}
