<div class="right rightNoWrap rightInternalMenu active">
    <div class="InternalMenuWrapper">
        <fa-icon [icon]="['fad', 'bars']" class="big headerIcon bars popupTrigger" [attr.rel]="id" [ngClass]="{'selected':open}"></fa-icon>
        <div class="dropdownInternalMenu popupPanel" id="{{id}}">
            <ng-container *ngFor="let item of localInternalMenu">
                <div *ngIf="item.type!='splitter'" class="InternalMenuOuterItem" [ngClass]="{'disabled':item.disabled}">
                    <div [ngClass]="{'itemLeft':item.pinnable, 'exposed': item.exposed, 'itemFull':!item.pinnable}" (click)="toggleMenu(); executeLocalFunction(item.action, item.actionData)">
                        <fa-icon [icon]="[icon(item.icon)[0], icon(item.icon)[1]]"></fa-icon>
                        <span>{{item.title|translate}}</span>
                    </div>                    
                    <div *ngIf="item.pinnable" [ngClass]="{'itemRight':item.pinnable}">
                        <fa-icon [icon]="['fad', 'square-up-right']" [ngClass]="{'active':isIconSelected(item.entity)}" (click)="pinLocalFunctionIcon(item.component, item.entity)"></fa-icon>                                                    
                    </div>
                </div>
                <div *ngIf="item.type=='splitter'" class="splitter"></div>
            </ng-container>
        </div>
    </div>
</div> 

<div class="localFunctionsShortCuts right">
    <ng-container *ngFor="let shortcut of localFuctionShortcuts | keyvalue">                    
        <ng-container *ngFor="let item of localInternalMenu">   
            <div class="localFunctionShortcut" *ngIf="shortcut.key  == item.entity">
                <fa-icon *ngIf="shortcut.value==true && item.icon!=''" class="big" [icon]="[icon(item.icon)[0], icon(item.icon)[1]]" (click)="executeLocalFunction(item.action, item.actionData)"></fa-icon>
                <div class="tooltip">{{item.title|translate}}</div>
            </div>                            
        </ng-container>
    </ng-container>
</div>        