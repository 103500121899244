import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-widget-activities-settings',
  templateUrl: './widget-activities-settings.component.html',
  styleUrls: ['./widget-activities-settings.component.css']
})
export class WidgetActivitiesSettingsComponent implements OnInit {

  formData: UntypedFormGroup;

  constructor(
	  private data: DataService,
    public dialog: MatDialogRef<WidgetActivitiesSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public injected_data,
    public translate: TranslateService
  ) { 
    this.formData = this.createFormGroup();
  }

  public categories = [];
  public settings = {title:'Activity Widget title...',category:null,order:'created',orderby:'asc',items:12,featured:0,assigned:0,author:1,hidden:0,approvedbyme:1,approved:0,unapproved:0,extended:1,created:0,date_from:0,date_to:0,status:1,priority:0,sub_activities:0,comments:0,comments_results:3,showauthor:1,showcustomer:1,showcomments:0,showprogress:0,removeold:0,combinecustomer:1,hidechild:0};
  public settingsView = 'basic';

  createFormGroup() {
    return new UntypedFormGroup({
      title: new UntypedFormControl(),
      categories: new UntypedFormControl(),
      order: new UntypedFormControl(),
      orderby: new UntypedFormControl(),
      items: new UntypedFormControl(),
      featured: new UntypedFormControl(),
      assigned: new UntypedFormControl(),
      author: new UntypedFormControl(),
      hidden: new UntypedFormControl(),
      approved: new UntypedFormControl(),
      approvedbyme: new UntypedFormControl(),
      unapproved: new UntypedFormControl(),
      extended: new UntypedFormControl(),
      created: new UntypedFormControl(),
      date_from: new UntypedFormControl(),
      date_to: new UntypedFormControl(),
      status: new UntypedFormControl(),
      priority: new UntypedFormControl(),
      sub_activities: new UntypedFormControl(),
      comments: new UntypedFormControl(),      
      showcustomer: new UntypedFormControl(),
      showauthor: new UntypedFormControl(),
      comments_results: new UntypedFormControl(),
      showcomments: new UntypedFormControl(),
      showprogress: new UntypedFormControl(),
      removeold: new UntypedFormControl(),
      combinecustomer: new UntypedFormControl(),
      hidechild: new UntypedFormControl()
    });
  }

  save() {
     var parameters = { data: Object.assign({}, this.formData.value) };

      /* get permissions */
      var categories = [];
      $('.multiselect_checkbox.categories .switch').each(function() {
        var rel = $(this).find('input[type=checkbox]').attr('rel');
        var checked = $(this).find('input[type=checkbox]').is(':checked');
        //var category = {'category_id':rel,'status':checked};
        var category = {'category_id':rel};
        if (checked == true) {
          categories.push(category);
        }
      });
      
      parameters.data.categories = categories;
      console.log(parameters);
      this.dialog.close(parameters);
 
  };

  ngOnInit(): void {

    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);

    this.settings = this.injected_data;
    /* fix */
    this.settings.order = (this.settings.order).replace('t3.entity, ', '');    

    this.data.getActivityTypes({}).subscribe(
      (res: Response) => {
        this.categories = (<any>res).rows;                
        if (this.settings['categories']) {
          for (var i=0; i<(this.categories).length; i++) {
            for (var j=0; j<(this.settings['categories']).length; j++) {
              if (this.settings['categories'][j].category_id == this.categories[i].entity) {
                this.categories[i]['selected'] = true;
              }             
            }
          }
        }                
      }
    );

  }

}

