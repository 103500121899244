
<div class="inner_wrapper">
    <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="left">
            <h1>{{'ERP items'|translate}}</h1>
        </div>
        <div class="right">
            <fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
        </div>
    </div>

    <div class="body">
        <div class="field right wPadding toggle">
            <div class="label label-checkbox">{{'Enabled'|translate}}</div>
            <label class="switch">
                <input type="checkbox" [(ngModel)]="item.enabled">
                <span class="slider round"></span>
            </label>  
        </div>

        <div class="inline w20 wPadding">
            <mat-form-field appearance="outline" class="important">
                <mat-label>{{'Position/Code'|translate}}</mat-label>
                <input name="title" type="text" matInput [(ngModel)]="item.poz">
                <!--<mat-hint>Error</mat-hint>-->                    
            </mat-form-field>
        </div>

        <div class="inline w100 divider" style="border-bottom: 1px solid var(--light-lvl1);"></div>

        <div class="inline w25 wPadding">
            <mat-form-field appearance="outline">
                <mat-label>{{'Category'|translate}}</mat-label>
                <mat-select name="category" id="category-select" (change)="updateType($event)" [(ngModel)]="item.category">
                    <mat-option *ngFor="let category of categories" value="{{category}}">{{category}}</mat-option>                      
                </mat-select>
                <!--<mat-hint>Error</mat-hint>-->
              </mat-form-field>
        </div>

        <div class="inline w25 wPadding">
            <mat-form-field appearance="outline">
                <mat-label>{{'Type'|translate}}</mat-label>
                <mat-select name="type" id="type-select" [(ngModel)]="item.type">
                    <mat-option *ngFor="let type of types[item.category]" value="{{type}}">{{type}}</mat-option>                      
                </mat-select>
                <!--<mat-hint>Error</mat-hint>-->
            </mat-form-field>
        </div>

        <div class="inline w100 divider"></div>

        <div class="left w60 wPadding">
            <div class="inline w100">
                <mat-form-field appearance="outline" class="important">
                    <mat-label>{{'Title'|translate}}</mat-label>
                    <input matInput name="title" [(ngModel)]="item.title">   
                    <!--<mat-hint>Error</mat-hint>-->                 
                </mat-form-field>
            </div>
            
            <div class="inline w100">
                <mat-form-field appearance="outline">
                    <mat-label>{{'Short description'|translate}}</mat-label>
                    <textarea matInput id="noEditor" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" [(ngModel)]="item.short_description" placeholder="{{'Short description...'|translate}}"></textarea>
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>

            <div class="inline w100">
                <mat-form-field appearance="outline">
                    <mat-label>{{'Long description'|translate}}</mat-label>
                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" id="noEditor" [(ngModel)]="item.long_description" placeholder="{{'long description...'|translate}}" style="min-height: 240px; margin-bottom: 16px;"></textarea>
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>
        </div>

        <div class="right w15 wPadding">
            <div class="inline w100">
                <mat-form-field appearance="outline" class="important">
                    <mat-label>{{'Regular price'|translate}}</mat-label>
                    <input matInput name="regular_price" [(ngModel)]="item.regular_price"> 
                    <!--<mat-hint>Error</mat-hint>-->                  
                </mat-form-field>
            </div>

            <div class="inline w100">
                <mat-form-field appearance="outline">
                    <mat-label>{{'Special price'|translate}}</mat-label>
                    <input matInput name="special_price" [(ngModel)]="item.special_price">   
                    <!--<mat-hint>Error</mat-hint>-->                 
                </mat-form-field>
            </div>

            <div class="inline w100">
                <mat-form-field appearance="outline">
                    <mat-label>{{'Lowest possible price'|translate}}</mat-label>
                    <input matInput name="low_price" [(ngModel)]="item.low_price">
                    <!--<mat-hint>Error</mat-hint>-->                  
                </mat-form-field>
            </div>

        </div>
    </div>

    <div class="modalActions">
        <button class="left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
        <button class="accept right" (click)="save();" mat-button mat-dialog-close>{{'Save'|translate}}</button>
        <button class="warrning right" (click)="delete();" mat-button mat-dialog-close>{{'Delete'|translate}}</button>
    </div>
</div>