/*************************************/
/* Angular                           */
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';  

/*************************************/
/* Voice recogmnitiomn */
import { VoiceRecognitionService } from '../services/voice-recognition.service'

/*************************************/
/* Serives, Data manipulation        */
import { DataService } from '../services/data.service';
import { AuthService } from '../services/auth.service';
import { GlobalVariables } from '../services/data.globals';

/* Spinner */
import { AppComponent } from '../app.component';

/*************************************/
/* Components */
import { SearchComponent } from '../search/search.component';
import { ActivityComponent } from '../activities/activity/activity.component';
import { TimetrackingEditComponent } from '../timetracking/timetracking-edit/timetracking-edit.component';
import { UserSettingsComponent } from '../users/user-settings/user-settings.component';
import { CustomerComponent } from '../customers/customer/customer.component';
import { MailComponent } from '../mail/mail.component';
import { TranslateService } from '@ngx-translate/core'; 
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { faSledding } from '@fortawesome/pro-solid-svg-icons';
import { ErpDocumentComponent } from '../erp/erp-document/erp-document.component';

type ProfileType = {
	givenName?: string,
	surname?: string,
	userPrincipalName?: string,
	id?: string
};

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css'] 
})
export class TopComponent implements OnInit {

	isIframe = false;
	loginDisplay = false;
	private readonly _destroying$ = new Subject<void>();

	constructor(		
		private auth_svc: AuthService,
		private router: Router,
		private data: DataService,
		public dialog: MatDialog,
		@Inject(DOCUMENT) private document: Document,
		public translate: TranslateService,		
		private http: HttpClient,
		public service : VoiceRecognitionService
		
	) {
	  translate.addLangs(['en', 'si']);
	  this.service.init(); 
	  this.data.navigationCollapse.subscribe({ 
		next: newValue => this.navigationCollapse = newValue.state
	  });
	}
	
	public outlook_enable = false;
	public activity_categories = [];
	public username = GlobalVariables.username;
	public user = GlobalVariables.id;
	public admin_rights = GlobalVariables['admin_rights'];
	public unread = false;
	public unreadItems = -1;
	public mute = false;
	isMobile = false;
	
	//public dock_items = [];
	//public dock_items_customers = [];
	//public dock_items_documents = [];
	//public dock_items_erpDocuments = [];
	public theme = 'dark';

	//public dockOld = '';

	public UnAssignedETalks = 0; 
	public UnsendETalks = 0;
	public UnSolvedETalks = 0;
	public outlook_isLoggedIn = false;
	public mail_enable = false;
 
	public navigationCollapse = GlobalVariables.navigationCollapse;

	public externalTranscript = this.service.externalTranscript;
	public externalTranscript_old = "";

	public recognizing = false;
	public listening = false;
	public spaces = false;
	public quickAdd = true;

	topCustomItems = [];

	topSystemItems = [
		{
			title:null, 
			i:"user", 
			action:"#",
			subItems: [{ 
				title: "User settings", 
				i:"cog", 
				action:"userSettings" 
			},
			{ 
				title: "Logout", 
				i:"sign-out", 
				action:"logout" 
			}
			]
		}
	];

	globalNewActivities() {		
	}

	checkEmails() {
	}


	globalSearch() {		
		var searchDialog = this.dialog.open(SearchComponent, {
			maxWidth: '100vw',
      		maxHeight: '100vh',
			height: '100%',
			width: '100%',      
			panelClass: 'dark',
		});
	  	searchDialog.afterClosed().subscribe(result => {});		
	}

	addTimeTracking() {		
		var editDialog = this.dialog.open(TimetrackingEditComponent, {});
		editDialog.componentInstance.entity = null;
		editDialog.afterClosed().subscribe(result => {}); 		
	}

	edit_erp_document(entity) {		
		var editDialog = this.dialog.open(ErpDocumentComponent, {panelClass: 'dialog-activity', disableClose: true});
		editDialog.componentInstance.entity = entity;		
		editDialog.afterClosed().subscribe(result => {});		
  	}

	add_activity(category_id) {		
		var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
		editDialog.componentInstance.entity = null;
		editDialog.componentInstance['category_id'] = category_id;
		editDialog.afterClosed().subscribe(result => {});		
  	}

	edit_activity(entity) {		
		var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
		editDialog.componentInstance.entity = entity;		
		editDialog.afterClosed().subscribe(result => {});		
  	}

	edit_customer(entity) {		
		var editDialog = this.dialog.open(CustomerComponent, {panelClass: 'dialog-activity', disableClose: true});
		editDialog.componentInstance.entity = entity;		
		editDialog.afterClosed().subscribe(result => {});		
  	}

	getUnSolvedETalks() {
		var entity = "outlook_enable";
		this.data.getSettings({entity : entity}).subscribe((res: Response) => { 
			this.mail_enable = res['rows'][0]['value'] == 1 ? true : false; 
		
			if (this.mail_enable) {
				this.data.getUnassignedETalks(null).subscribe(
					(res: Response) => {	  
						//this.UnAssignedETalks = res.
						//console.log(res);
						this.UnAssignedETalks = res['rows'].length;				

						this.data.getUnsendETalks(null).subscribe(
							(res: Response) => {	  
								//this.UnassignedETalksCount = res.
								//console.log(res);
								this.UnsendETalks = res['rows'].length;
								this.UnSolvedETalks = this.UnAssignedETalks + this.UnsendETalks;
							}
						);
					}
				);		
			}
		});
	}

  	getLastChangesCount() {		  
		var total_items = 0;		
		var date = <any>new Date();
		date.setDate(date.getDate() - 7);
		var date_format = date.toISOString().split("T")[0];
			
		var parameters_activities = { updated : date_format, items : 100, order : 'updated', orderby : 'desc', viewed : 1, days : 7, assigned: 1, last_change : 1};
		var parameters_comments = { created : date_format, items : 100, order : 'updated', orderby : 'desc', viewed : 1, days : 7, assigned: 1, last_change : 1};

		this.data.getLastCount(parameters_activities).subscribe(
			(res: Response) => {	  
				if (res['rows'] != undefined) total_items = total_items + (res['rows']).length;

				console.log(res['rows']);
				
				this.data.getLastComments(parameters_comments).subscribe(
					(res: Response) => {
						total_items = total_items + (res['rows']).length;
						if (this.unreadItems < total_items && this.unreadItems != -1 && this.mute == false) {
							var audio = new Audio();
							audio.src = "/assets/sounds/newItem.mp3";
							audio.load();
							audio.play();
						}
						this.unreadItems = total_items;
					}
				)
			}
		)
		
	}

	/*
	getDocker(items) {	
		//console.log(items)
		if (items.length > 0) {
			this.data.getDockActivities({items : items}).subscribe(
				(res: Response) => {	  		
					//console.log(res);	
					this.dock_items = (<any>res).rows;			
				}
			)
		}
	}

	getDockerCustomers(items) {	
		//console.log(items)
		if (items.length > 0) {
			this.data.getDockCustomers({items : items}).subscribe(
				(res: Response) => {	  		
					//console.log(res);	
					this.dock_items_customers = (<any>res).rows;			
				}
			)
		}
	}

	getDockErpDocuments(items) {	
		if (items.length > 0) {
			this.data.getDockErpDocuments({items : items}).subscribe(
				(res: Response) => {	  			
					this.dock_items_erpDocuments = (<any>res).rows;			
				}
			)
		}
	}

	fromDock(entity) {
		var docker = localStorage.getItem('docker');			
		var array_docker = [];
		if (docker != '' && docker != undefined) {
		  array_docker = JSON.parse(docker);
	
		  var index = array_docker.indexOf(entity);
		  if (index !== -1) {
			array_docker.splice(index, 1);
		  }
		}
		
		if (array_docker.length == 0) this.dock_items = [];

		docker = JSON.stringify(array_docker);
		localStorage.setItem('docker', docker);		
		this.refreshDocking();
	}

	fromDockCustomers(entity) {
		var docker = localStorage.getItem('docker_customers');
	
	
		var array_docker = [];
		if (docker != '' && docker != undefined) {
		  array_docker = JSON.parse(docker);
	
		  var index = array_docker.indexOf(entity);
		  if (index !== -1) {
			array_docker.splice(index, 1);
		  }
		}
		
		if (array_docker.length == 0) this.dock_items_customers = [];

		docker = JSON.stringify(array_docker);
		localStorage.setItem('docker_customers', docker);
		this.refreshDocking();
	}

	fromDockErpDocument(entity) {
		var docker = localStorage.getItem('docker_erpDocument');
		//console.log(docker);
	
		var array_docker = [];
		if (docker != '' && docker != undefined) {
		  array_docker = JSON.parse(docker);
	
		  var index = array_docker.indexOf(entity);
		  if (index !== -1) {
			array_docker.splice(index, 1);
		  }
		}
		
		if (array_docker.length == 0) this.dock_items_customers = [];

		docker = JSON.stringify(array_docker);
		localStorage.setItem('docker_erpDocument', docker);
		this.refreshDocking();
	}
	*/

	loadStyle(styleName: string) {
		const head = this.document.getElementsByTagName('head')[0];
	
		let themeLink = this.document.getElementById(
		  'client-theme'
		) as HTMLLinkElement;

		if (themeLink) {
		  themeLink.href = styleName;
		} else {
		  const style = this.document.createElement('link');
		  style.id = 'client-theme';
		  style.rel = 'stylesheet';
		  style.href = `${styleName}`;
	
		  head.appendChild(style);
		}
	}

	switchTheme() {
		this.theme = GlobalVariables.memberSettings['theme'];

		if (this.theme == "") this.theme = 'dark';

		this.data.setMemberSettings({'parameter': 'theme', 'value': this.theme}).subscribe((res: Response) => {}); 
		//console.log('Switch Theme: ' + this.theme);
		
		this.loadStyle(this.theme + '.css');
	}

	refreshMemberSettings() {
		//console.log('*** refreshMemberSettings ***');
		this.data.getMemberSettings(null).subscribe(
			(res: Response) => {
			var memberSettingsArray = [];        
			for (var i=0; i<res['rows'].length; i++) {          
				memberSettingsArray[res['rows'][i].parameter] = res['rows'][i].value;
			}        
		
			var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    		this.translate.use(lang);

			var theme = GlobalVariables.memberSettings['theme'] == undefined ? 'dark' : GlobalVariables.memberSettings['theme'];
			this.loadStyle(theme + '.css');
			}
		);
	}

	settings() {
		var entity = "outlook_enable";
    	this.data.getSettings({entity : entity}).subscribe((res: Response) => { this.outlook_enable = res['rows'][0]['value']; });
	}

	toggleMobileMenu() {
		if (document.querySelector<HTMLElement>('.openNavigation')) {
			var menu = document.querySelector<HTMLElement>('.openNavigation');
			menu.classList.remove("openNavigation");						
		}

		var menu = document.querySelector<HTMLElement>('#navigation');
		menu.classList.toggle("open");		
	}

	/*
	public refreshDocking() {
		let docker = localStorage.getItem('docker');
		var array_docker = [];
		if (docker != '' && docker != undefined) {
			if (docker != this.dockOld) {
				this.dockOld = docker;
				array_docker = JSON.parse(docker);
				this.getDocker(array_docker);
			}
		}
		docker = localStorage.getItem('docker_customers');		
		if (docker != '' && docker != undefined) {
			if (docker != this.dockOld) {
				this.dockOld = docker;
				array_docker = JSON.parse(docker);
				this.getDockerCustomers(array_docker);
			}
		}
		docker = localStorage.getItem('docker_erpDocument');		
		if (docker != '' && docker != undefined) {
			if (docker != this.dockOld) {
				this.dockOld = docker;
				array_docker = JSON.parse(docker);
				this.getDockErpDocuments(array_docker);
			}
		}
	}
		*/

	ngOnInit(): void {

		alert('hello');

		this.refreshMemberSettings();

		this.data.getCategories({ categories: null, items : 10000, page : 0, order : 't1.description', orderby : 'asc', search : null }).subscribe(
			(res: Response) => {
				this.activity_categories = (<any>res).rows;      
			}			
		);

		this.getLastChangesCount();
		//this.getUnSolvedETalks(); 	
		//this.settings();	

		const topLastitemsInterval = setInterval(() => { 
			this.getLastChangesCount(); 
			//this.getUnSolvedETalks(); 	
			//this.settings();			
		}, 2500);		

		const speech = setInterval(() => { 			
			if (this.externalTranscript_old != this.service.externalTranscript) {
				this.externalTranscript = this.service.externalTranscript;
				this.externalTranscript_old = this.service.externalTranscript;
			} else {
				this.externalTranscript = "";
			}

			this.recognizing = this.service.recognizing;
			this.listening = this.service.listening;
		}, 750);

		
		/*
		var dockOld = '';
		const dockInterval = setInterval(() => { 
			this.refreshDocking();
		}, 5000);
		*/
	}

	async loginPopup() {				
	}
		
	ngOnDestroy(): void {
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}

	profile!: ProfileType;

	filterEmail(content) {
		return content;
	}

	validateETalk() { 		
	}

	getProfile() {		

	}

	openETalk() {		
		this.router.navigate(['/etalk']);	
	}

	makeid(length) {
		var result           = '';
		var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for ( var i = 0; i < length; i++ ) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	masal_session_check() {
	}


	getETalk() {		
		
	}

	
	sendETalk() {
	}

	/* voice recognition */
	startVoiceService(){
		this.service.start();	
		this.listening = true;
		this.service.listening = true;
	}
	
	stopVoiceService(){
		this.service.stop();
		this.listening = false;
		this.service.listening = false;
	}


	fn_logout = "logout";
  	
	userSettings() {		
		var UserSettingsComponentDialog = this.dialog.open(UserSettingsComponent, {panelClass: 'usersettings'});
		//editDialog.componentInstance.entity = null;		
		UserSettingsComponentDialog.afterClosed().subscribe(result => {});		
	}

	logout() {
		this.auth_svc.logout();
  		this.router.navigate(['/login']);
	}

}