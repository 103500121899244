import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { GlobalVariables } from '../../services/data.globals';
import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TopComponent } from '../../top/top.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { sha512 } from 'js-sha512';
import { TranslateService } from '@ngx-translate/core';
//import { LEADING_TRIVIA_CHARS } from '@angular/compiler/src/render3/view/template';

import { ModalComponent } from '../../modal/modal.component';
import { ColorChooserComponent } from '../../framework/color-chooser/color-chooser.component';



@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  public selectedTab = 'summary';
  public accessDropDown = false;
  public userGroupsDropDown = false;

  public changePassword = false;
  public member_groups;
  public entity;
  //public hex = '';
  public user = {};
  public user_data;
  public user_group = [];
  public admin_rights = false;
  public selectedColorPicker;
  public user_color;
  public appRestriction = {
      activities : { settings : false, view : false },
      archives : { settings : false, view : false },
      customers : { settings : false, view : false },
      timetracking : { settings : false, view : false },
      users : { settings : false, view : false },
      system_settings : { settings : false, view : false },
      recovery : { settings : false, view : false },
      knowledge : { settings : false, view : false },
      erp : { settings : false, view : false },
      erp_invoices : { settings : false, view : false },
      erp_credit_notes : { settings : false, view : false },
      erp_quotes : { settings : false, view : false },
      erp_forms : { settings : false, view : false }
  };

  constructor(
    private data: DataService,    
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public userDialog: MatDialogRef<UserComponent>,
    public translate: TranslateService
  ) { 
    translate.addLangs(['en', 'si']); 
  }




  deleteUser() {    
    //console.log(this.user);    
    if (this.user_group == null) this.user_group = [];
    var data = { 'entity' : this.entity };
    var parameters = { data: data };

    console.log(parameters);   
    
    this.data.getMembers({ items : 1, page : 0, order : 't1.entity', orderby : 'asc', search : null, field: 't1.entity', value : this.entity }).subscribe(
      (res: Response) => {       
        this.user = (<any>res).rows[0];
        if (this.user['admin_rights'] == 'true') {          
          var content = 'You can not delete user with admin rights!';
          var modalDialog = this.dialog.open(ModalComponent, {
            data: { content: content, yes: null , no: null, cancel: 'Close', close_parent: false }
          });
          
          modalDialog.afterClosed().subscribe(result => {
            //console.log(result);
          });
        } else {
          var content = 'Are you want to delete user?';
          var modalDialog = this.dialog.open(ModalComponent, {
            data: { content: content, yes: 'Yes' , no: 'No', cancel: null, close_parent: false }
          });
          
          modalDialog.afterClosed().subscribe(result => {
            console.log(result);

            if (result.yes != undefined) {
              this.data.deleteUser(parameters).subscribe(
                (res: Response) => {   
                  this.userDialog.close();
                }
              );
            }
            
          });          

        }
      }
    );
  }



  changeAdminfRights(state) {
    this.admin_rights = state.target.checked;
  }

  changeAppRestriction(state, app, func) {    
    if (this.appRestriction == null) {
      this.appRestriction = {
        activities : { settings : false, view : false },
        archives : { settings : false, view : false },
        customers : { settings : false, view : false },
        timetracking : { settings : false, view : false },
        users : { settings : false, view : false },
        system_settings : { settings : false, view : false },
        recovery : { settings : false, view : false },
        knowledge : { settings : false, view : false },
        erp : { settings : false, view : false },
        erp_invoices : { settings : false, view : false },
        erp_credit_notes : { settings : false, view : false },
        erp_quotes : { settings : false, view : false },
        erp_forms : { settings : false, view : false }
      };      
    }

    if (this.appRestriction[app]!=undefined) {
      this.appRestriction[app][func] = state.target.checked;
    } else {      
      if (this.appRestriction[app]==undefined) { this.appRestriction[app] = {settings:false, view:false}; }
      //this.appRestriction[app].push({settings:false, view:false});
      this.appRestriction[app][func] = state.target.checked;
    }
    //console.log(this.appRestriction);
  }

  changeUserGroup(entity, state) {    
    if (this.user_group != undefined) {
      var index = this.user_group.indexOf(entity);
      if (index !== -1) {
        this.user_group.splice(index, 1);
      }
    } else {
      this.user_group = [];
    }
		
		if (state.target.checked) {
      this.user_group.push(entity);
    }		
	}

  chooseColor() {
    var colorDialog = this.dialog.open(ColorChooserComponent, {
      data: { color: '#ff00ff' }
    });    
    colorDialog.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

	
  ngOnInit(): void {

    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);

    const component = this;
    
    this.data.getMemberGroups({ items : 10000, page : 0, order : 't1.member_group', orderby : 'asc', search : null }).subscribe(
      (res: Response) => { this.member_groups = (<any>res).rows; }
    );

    this.data.getMembers({ items : 1, page : 0, order : 't1.entity', orderby : 'asc', search : null, field: 't1.entity', value : this.entity }).subscribe(
      (res: Response) => {       
        this.user = (<any>res).rows[0];        
        if (this.user == undefined) {
          this.selectedTab = 'edit';
          this.user = {};
          this.user['enabled'] = true;
        } else {
          this.admin_rights = this.user['admin_rights'] == 'true' ? true : false;
          this.user_group = this.user['user_group'] == null ? null : this.uniqeArray((this.user['user_group']).split(','));
          
          let stringArray = this.user_group;
          this.user_group = [];
          for (let i = 0; i < stringArray.length; i++) {
            this.user_group.push(Number(stringArray[i]));
          }

          this.appRestriction = JSON.parse(this.user['apps']);

          this.data.getMemberData({ user_id : this.entity }).subscribe(
            (res: Response) => {
              this.user_data = (<any>res).rows;
              for(var i=0; i<this.user_data.length; i++) {
                this.user[this.user_data[i]['fieldname']] = this.user_data[i]['data'];
              }              
            }
          );
        }        
      }
    );

  }





  /****************************************/
  uniqeArray(arr, value = "") {
    console.log(arr);
		arr = [...new Set(arr)];		
		let index = arr.indexOf(value);
		if (index !== -1) {
			arr.splice(index, 1);
		}
    console.log(arr);
		return arr;
	}

  saveUser() {    
    if (this.user['email'] == undefined || this.user['username'] == undefined || this.user['email'] == "" || this.user['username'] == "") {
      var content = 'You must to fill up neccessary fields!';
      var modalDialog = this.dialog.open(ModalComponent, {
        data: { content: content, yes: null , no: null, cancel: 'Close', close_parent: false }
      });
      return false;
    }
    
    this.user_group = this.uniqeArray(this.user_group.join(',').split(','));    
    var parameters = { data: this.user, apps : JSON.stringify(this.appRestriction), user_group : this.user_group.join(','), admin_rights : this.admin_rights };
    
    if (parameters['data']['password']!=null) {
      parameters['data']['password'] = sha512(parameters['data']['password']);    
    }   

    console.log(parameters);           
    this.data.saveUser(parameters).subscribe(
      (res: Response) => {   
        let entityCallBack = <any>res['callback']['entity'];
        if (this.user_group == null) this.user_group = [];    
        for (const key in this.user) {      
          if (key != 'password' && key != 'entity' && key != 'apps' && key != 'user_group' && key != 'username' && key != 'email' && key != 'admin_right' &&  key != 'admin_right') {
            console.log({'id': this.user['entity'], 'parameter': key, 'value': this.user[key]});
            this.data.setMemberData({'id': entityCallBack, 'parameter': key, 'value': this.user[key]}).subscribe(
              (res: Response) => {   
                console.log(res);
                this.userDialog.close();
              }
            );
          }
        }
        this.userDialog.close();
      }
    );
    
    return true;
  }

  /****************************************/
  close() {
    this.userDialog.close();
  }

  /****************************************/
  /* function: save burgebar pin function */
  switchTab(value) {
    this.selectedTab = value;
  }

}