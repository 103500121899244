import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-notes',
  templateUrl: './widget-notes.component.html',
  styleUrls: ['./widget-notes.component.css']
})
export class WidgetNotesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
