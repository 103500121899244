/* Angular & Material */
import { Component, Input, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* Services */
import { CommonFunctions } from '../../services/common.functions'; 
import { GlobalVariables } from '../../services/data.globals';

/* Translate */
import { TranslateService } from '@ngx-translate/core'; 

/* PDF relate stuff */
import { fontRoboto } from "../../services/fontRoboto";
import jsPDF from 'jspdf';
import 'jspdf-autotable';


@Component({
  selector: 'app-grid-pdf',
  templateUrl: './grid-pdf.component.html',
  styleUrl: './grid-pdf.component.css'
})

export class GridPdfComponent implements OnInit {


  constructor(
    @Inject(MAT_DIALOG_DATA) public injected_data,		
		public dialog: MatDialogRef<GridPdfComponent>,
    public CommonFunctions: CommonFunctions
  ) {}


  generatePDF(data, head, summarizedRow = false, settings) {
	
    //settings = {documentTitle: "Rich Quote Items", printDate: true, companyData: true, pagination: true, orientation: 'landscape', format: 'A4' };

		let contentHeight = 20;
		let lMargin = 15;
		let rMargin = 15;
		let normalSpace = 4;
		let items = [];
    let documentWidth = settings['orientation'] == 'landscape' ? 297 : 210;
    

		for(let i=0; i<data.length; i++) {			
			var dataHelper = [];			
      for(var j=0; j<head.length; j++) {			
			  dataHelper.push(data[i][head[j]]);
      }      
			items.push(dataHelper);  
		}
		
    head = [head];
    var doc = settings['orientation'] == 'landscape' ? new jsPDF("l","mm",[297, 210]) : new jsPDF("p","mm",[297, 210]);

		doc.addFileToVFS("Roboto-Light-normal.ttf", fontRoboto);
		doc.addFont('Roboto-Light-normal.ttf', 'Roboto-Light', 'normal');
				
    if (settings['documentTitle']!='') {
      doc.setFontSize(16);
      doc.setFont('Roboto-Light');
      doc.text(settings['documentTitle'], lMargin, contentHeight);
      contentHeight = contentHeight + 12;
    }

    /*
		doc.setFontSize(10);
		var PDFtext = 'Report details???';
		var dim = doc.getTextDimensions(PDFtext);
		doc.text(PDFtext, documentWidth-rMargin-dim.w, contentHeight);
		contentHeight = contentHeight + dim.h + normalSpace*0.5;
    */
		
		/*
		if (this.total_items > itemsLimit)
		var PDFtext = 'Items: ' + itemsLimit + " of " + this.total_items;
		var dim = doc.getTextDimensions(PDFtext);
		doc.text(PDFtext, documentWidth-rMargin-dim.w, contentHeight);

		
		var PDFtext = 'Print date: ' + this.cFunction.localDateFormat(new Date());
		doc.text(PDFtext, lMargin, contentHeight);				
		contentHeight = contentHeight + dim.h + normalSpace*0.25;	


		if (this.parameters.hasOwnProperty('filter_start_date_raw')) {
			var PDFtext = 'Date range: ' + this.cFunction.localDateFormat(this.parameters['filter_start_date_raw']) + ' - ' + this.cFunction.localDateFormat(this.parameters['filter_end_date_raw']);
			var dim = doc.getTextDimensions(PDFtext);
			doc.text(PDFtext, documentWidth-rMargin-dim.w, contentHeight);
			contentHeight = contentHeight + dim.h + normalSpace*0.25;
		}

		if (this.parameters['search'] != undefined) {
			var PDFtext = 'Search filter term: ' + this.parameters['search'];
			var dim = doc.getTextDimensions(PDFtext);
			doc.text(PDFtext, documentWidth-rMargin-dim.w, contentHeight);
			contentHeight = contentHeight + dim.h + normalSpace*0.25;
		}
*/

		const totalPagesExp = "{total_pages_count_string}";

		(doc as any).autoTable({
			head: head,
			body: items,
			theme: 'striped',
			startY: contentHeight,
			styles: {font: "Roboto-Light"},
			didDrawCell: data => {},			
			didParseCell: function (data) {
				var rows = data.table.body;        
				if (data.row.index === rows.length - 1 && summarizedRow == true) {
					data.cell.styles.fillColor = [69, 127, 181];					
					data.cell.styles.textColor = [255, 255, 255];
				}				
			},
			addPageContent: data => {
				if (settings['pagination'] == true) {
          let footerStr = "Page " + doc.getNumberOfPages();
          if (typeof doc.putTotalPages === 'function') {
            footerStr = footerStr + " of " + totalPagesExp;
          }
          doc.setFontSize(10);
          var dim = doc.getTextDimensions(footerStr);				
          doc.text(footerStr, documentWidth-rMargin-dim.w+39, doc.internal.pageSize.height - 5 + 3 - 5);
        }
        
        if (settings['printDate'] == true) {
          doc.setFontSize(10);
          let dateStr = this.CommonFunctions.localDateFormat(new Date());
          /*
          let now = new Date();
          let hours = now.getHours();
          let minutes = now.getMinutes();
          let seconds = now.getSeconds();
          var dim = doc.getTextDimensions(dateStr + " | " + hours + ":" + minutes + ":" + seconds);
          */
          var dim = doc.getTextDimensions(dateStr);
          doc.text(dateStr, lMargin, doc.internal.pageSize.height - 5 + 3 - 5);
        }

				/*				
				doc.addImage("/assets/logo/ibilizz_black.png", "PNG", lMargin, doc.internal.pageSize.height - 11 + 3 - 5, 20, 8);
				doc.addImage("/assets/images/print.png", "PNG", lMargin+40, doc.internal.pageSize.height - 8.5 + 3 - 5, 4, 4);			
				doc.text(this.cFunction.localDateFormat(new Date()), lMargin+45, doc.internal.pageSize.height - 5 + 3 - 5);				
        */
			  }
		})
		
		if (typeof doc.putTotalPages === 'function') {
		  doc.putTotalPages(totalPagesExp);
		}

		doc.save((settings['documentTitle']).replace(" ", "_")+'.pdf');  
    this.dialog.close();   
	}

  ngOnInit(): void {	
    console.log(this.injected_data);
    this.generatePDF(this.injected_data['data'], this.injected_data['head'], this.injected_data['summarizedRow'], this.injected_data['settings']);
  }

}
