import { Component, OnInit, Inject, Optional, ComponentFactoryResolver } from '@angular/core';
import { DataService } from '../../services/data.service';
import { GlobalVariables } from '../../services/data.globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SelectCustomerComponent } from '../../customers/select-customer/select-customer.component';
import { ActivitySelectComponent } from '../../activities/activity-select/activity-select.component';
import { AssignDocumentCategoryComponent } from '../assign-document-category/assign-document-category.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CommonFunctions } from '../../services/common.functions';
import { TranslateService } from '@ngx-translate/core';

import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

import { ModalComponent } from '../../modal/modal.component';
import { ActivatedRoute, Router } from '@angular/router';

import { Injectable } from '@angular/core';

/* UPLOADER : START */
import { HttpClient, HttpRequest, HttpHeaders, HttpEventType, HttpResponse } from '@angular/common/http';
import { UploadService } from '../../services/upload.service';
import { from, Observable } from 'rxjs';
/* UPLOADER : START */


@Component({
  selector: 'app-document-edit',
  templateUrl: './document-edit.component.html',
  styleUrls: ['./document-edit.component.css']
})
export class DocumentEditComponent implements OnInit {
  
  public customer;
  public customer_id;
  public activity;
  public activity_id;
  public category_id;
  public category;
  public title;
  public keywords;
  public valid;
  public value;
  public categories;
  public entity;
  public folder;
  private storage = null;
  public toggleFoldersView = false;
  public showMenu = false;
  
  /* file Browser */
  public folders = [];
  public groupedFolders = {};
  public folder_tree = false;
  public smart = true;  
  public order = 'desc';
  public files = [];
  public current_file = '';
  public asComponent = false;
  public sortBy = 'doc_title';
  
  /* sort anfd view */
  public selected = null;
  public grid = true;
  public opened = null;
  public byName = true;
  public byDate = false;
  public systemGroup = true;
  public smartGroup = false;

  public refreshPreData_field = null;
  public refreshPreData_value = null;
  public refreshPreData_items = 1000;
  public refreshPreData_search = null;
  public searchOpen = false;

  public imgUrl = null;
  public docUrl = null;

  /**************************************************/
  /**************************************************/
  /**************************************************/
  constructor(
    private data: DataService,
    public dialog: MatDialog,    
    public cFunction: CommonFunctions,
    @Optional() public dialogRef: MatDialogRef<DocumentEditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public injected_data,
    public translate: TranslateService,
    private UploadService: UploadService /* UPLOAD */,
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) {  
    translate.addLangs(['en', 'si']);
  }

  /* UPLOADER */
  private _openDialog() {
		if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) return;		
	}
  
    
  /**********************************************/
  /**********************************************/
  /* Functions */
  selectCategory(source = null) {        
    var assignDocumentCategoryDialog = this.dialog.open(AssignDocumentCategoryComponent, {
      'width' : '350px',
      data: { categories: this.categories, currentCategory: 1 }
    });

    assignDocumentCategoryDialog.afterClosed().subscribe(result => {      
      if (result == null) {
        let content = this.translate.instant('You are not authorized to upload files');
        let modalDialog = this.dialog.open(ModalComponent, {
          data: { content: content, yes: this.translate.instant('OK'), no: null, cancel: null }
        });
        this.dialogRef.close(null);
      } else if (!isNaN(result.currentCategory)) {  
        this.category_id = result.currentCategory;
        this.category = result.description;   
        if (result.folder == undefined) {             
          this.folder = (result.description).toLowerCase();
        } else {
          this.folder = this.folder;
        }        
        //this.current_file = this.folder + " / " + this.title;     
        /*
        this.clearSelected();
        this.cFunction.setObjectValue(this.folders, this.folder + "/_selected", true);
        this.cFunction.setObjectValue(this.folders, this.folder + "/_opened", true);
        */
      }
    });
  } // selectCategory
  
  selectCustomer() {
    var selectCustomerDialog = this.dialog.open(SelectCustomerComponent, {});
    selectCustomerDialog.afterClosed().subscribe(result => {      
      if (!isNaN(result.entity)) {  
        this.customer_id = result.entity;
        this.customer = result.customer;
      }
    });       
  } //selectCustomer





  /**********************************************/
  /**********************************************/
  selectActivity() {
    var ActivitySelectDialog = this.dialog.open(ActivitySelectComponent, { 'width' : '650px', 'maxWidth' : '95vw' });
    ActivitySelectDialog.afterClosed().subscribe(result => {      
      if (!isNaN(result.entity)) {  
        //console.log(result);
        this.activity_id = result.entity;
        this.activity = result.activity;
        this.customer_id = result.customer_id;
        this.customer = result.customer;
      }
    });            
  }

  /**********************************************/
  /**********************************************/
  add() {     
    let data = { customer:this.customer, customer_id:this.customer_id, activity:this.activity, activity_id:this.activity_id, category_id:this.category_id, category:this.category, title:this.title, keywords:this.keywords, entity:this.entity, value:this.value, folder:this.folder };        
    if (this.injected_data?.prefill['category_id']=='?' && this.injected_data?.prefill['call']=='activity') {
      if (data.folder == data.category) {
        data.folder = data.category + "/" + new Date().getFullYear();
      }
    }    
    this.dialogRef.close(data);
  }

  innerEdit(entity) {        
    //console.log(entity);
    this.data.getDocument({ entity : entity }).subscribe(
      (res: Response) => {
        var rows = (<any>res).rows;
        //console.log((<any>res).rows);
        this.customer = rows[0].customer;
        this.customer_id = rows[0].customer_id;
        this.activity = rows[0].activity; 
        this.activity_id = rows[0].activity_id;
        this.category_id = rows[0].doc_type_id;
        this.category = rows[0].doc_type;
        this.title = rows[0].doc_title;
        this.keywords = rows[0].doc_keywords;
        this.entity = rows[0].entity;       
        this.value = rows[0].doc_value;
        this.folder = rows[0].folder;
      }
    );
  }

  save() {            
    let data = { customer:this.customer, customer_id:this.customer_id, activity:this.activity, activity_id:this.activity_id, category_id:this.category_id, category:this.category, title:this.title, keywords:this.keywords, entity:this.entity, value:this.value, folder:this.folder };        
    this.data.saveDocument({data: data}).subscribe(
      (res: Response) => {
        this.dialogRef.close(null);
      }				
    );    
  }
  
  updateSettings() {
    this.data.refreshMemberSettings();
  }

  makeid(length) {
    var result           = '';
    /*var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567890';*/
    var characters       = '01234567890';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

  /*******************************************/
  /*******************************************/
  ngOnInit(): void {

    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);
    
    this.data.getStorageID({}).subscribe((res: Response) => {                    
      this.storage = res['storage'];    
    });

    /* prefiled data */
    if (this.injected_data != null) {  

      console.log('//injected_data for upload detected')
      console.log(this.injected_data);

      this.data.getDocumentCategories({}).subscribe(
        (res: Response) => {
          this.categories = (<any>res).rows;      
          console.log(this.categories);
          this.folder_tree = false;
          console.log('#1');
          if (this.injected_data?.files != undefined || this.injected_data?.prefill != undefined) {
            console.log('#2');
            console.log(this.injected_data);

            if (this.injected_data?.files != undefined) {
              this.title = this.injected_data?.files[0].name;
              //if (this.title == undefined) this.asComponent = true;
              this.keywords = this.title;
              this.current_file = this.title;        
            } else {
              this.title = 'file_' + this.makeid(12);
            }
            
            if (this.injected_data?.prefill != undefined) {
              console.log('#2b');
              this.customer_id = this.injected_data.prefill.customer_id;
              this.customer = this.injected_data.prefill.customer;
              this.activity_id = this.injected_data.prefill.entity;
              this.activity = this.injected_data.prefill.title;
              //this.category = this.injected_data.prefill.category;
              //this.category_id = this.injected_data.prefill.category_id;
              this.folder = this.injected_data?.prefill.folder;
            }
            if (this.injected_data.prefill.category_id == '?') this.selectCategory(this.injected_data.prefill.call);
          } else if (this.injected_data.entity != undefined) {  
            console.log('#3');            
            this.innerEdit(this.injected_data.entity);
          } /*else if (document.getElementsByClassName('document_center_wrapper').length > 0) {
            this.folder = this.injected_data?.prefill.folder;
            
          }*/
        }
      );
    }    
  } //oninit

}
