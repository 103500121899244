<div class="inner_wrapper">
	<div class="header">
	  <div class="left">
		<ng-container *ngIf="autosearch!=true">
			<h1><fa-icon [icon]="['fad', 'list']"></fa-icon> | {{'Select activity'|translate}}</h1>
		</ng-container>
		<ng-container *ngIf="autosearch==true">
			<h1><fa-icon [icon]="['fad', 'list']"></fa-icon> | {{'Available activities'|translate}}</h1>
		</ng-container>
	  </div>
	  <div class="right">
		<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
	  </div>
	</div>
	<div class="body">
		<div class="activity-search search">
			<ng-container *ngIf="autosearch!=true" class="wPadding">
				<input type="text" id="search" [(ngModel)]="search" (keyup)="onSearchActivity($event)" autocomplete="off" placeholder="{{'Search for activity...'|translate}}"/>
			
				<fa-icon *ngIf="advanced_search!=true" [icon]="['fad', 'chevron-down']" (click)="advanced_search_switch(true)"></fa-icon>
				<fa-icon *ngIf="advanced_search==true" [icon]="['fad', 'chevron-up']" (click)="advanced_search_switch(false)"></fa-icon>

				<div class="advanced_search" [ngClass]="{'advanced_search_show':advanced_search}">
					<div class="category_filter">
						<div> 
							{{'Filter by Category'|translate}}
						</div>
						<div>
							<ng-container *ngFor="let category of categories">
								<div class="each_category" [ngStyle]="{'background':category.hex}" (click)="reselectCategory(category.entity);" [ngClass]="{'enabled':categoriesSelected[category.entity] == true}">
									{{category.description}}
								</div>
							</ng-container>
						</div>
					</div>

					<div class="date_filter">
						<div>
							{{'Filter by start/stop'|translate}}
						</div>
						<div>
							<div>
								<div class="from_to">{{'From'|translate}}</div><input [(ngModel)]="watch_from_date" type="date"><div class="remove"><fa-icon [icon]="['fad', 'times']" (click)="watch_from_date=null"></fa-icon></div>
								<div class="from_to">{{'To'|translate}}</div><input [(ngModel)]="watch_to_date" type="date"><div class="remove"><fa-icon [icon]="['fad', 'times']" (click)="watch_to_date=null"></fa-icon></div>
							</div>
						</div>
					</div>
				</div>
			</ng-container>			
		</div>

		<div class="activity-results results">
			<ng-container *ngIf="autosearch!=true">
				<div *ngFor='let item of items' class="activity" (click)="onSelect({'entity':item.entity, 'activity': item.title, 'customer':item.customer, 'customer_id':item.customer_id, 'parent_id':item.parnet_id})">
					<div class="select_activity_lt">
						<div class="activity_id">{{item.entity}}</div>
					</div>
					<div class="select_activity_rt">
						<div class="customer">{{item.customer}}</div>
						<div class="activity_type" style="background:{{item.message_type_hex}}">{{item.message_type_name}}</div>
						<div class="title">{{item.title}}</div>
						<div class="subtitle">{{item.sub_title}}</div>
					</div>
				</div>		
			</ng-container>
			<ng-container *ngIf="autosearch==true">
				<div *ngFor='let item of items' class="activity" (click)="openActivity(item.entity)">
					<div class="select_activity_lt">
						<div class="activity_id">{{item.entity}}</div>
					</div>
					<div class="select_activity_rt">
						<div class="customer">{{item.customer}}</div>
						<div class="activity_type" style="background:{{item.message_type_hex}}">{{item.message_type_name}}</div>
						<div class="title">{{item.title}}</div>
						<div class="subtitle">{{item.sub_title}}</div>

						<div class="duration">
							<fa-icon class="small" [icon]="['fad', 'arrow-right-from-line']"></fa-icon>
							<span>{{cFunction.localDateFormat(item.message_start)}}</span>
							<fa-icon class="small" [icon]="['fad', 'arrow-right-to-line']"></fa-icon>
							<span>{{cFunction.localDateFormat(item.message_end)}}</span>
						</div>
					</div>
				</div>		
			</ng-container>
	</div>
  </div>