import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-widget-customers-settings',
  templateUrl: './widget-customers-settings.component.html',
  styleUrls: ['./widget-customers-settings.component.css']
})
export class WidgetCustomersSettingsComponent implements OnInit {

  public settings = {title:'Customer widget title...', order:'t1.entity', orderby:'asc', items:24, lastActivity: 1, lastComment: 1};
  public statuses;
  public custom_fields;
  public custom_field_data = {};

  formData: UntypedFormGroup;

  constructor(
    private data: DataService,
    public dialog: MatDialogRef<WidgetCustomersSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public injected_data,
    public translate: TranslateService
  ) { 
    this.formData = this.createFormGroup();
  }

  createFormGroup() {
    return new UntypedFormGroup({
      title: new UntypedFormControl(),
      order: new UntypedFormControl(),
      orderby: new UntypedFormControl(),
      items: new UntypedFormControl(),
      lastActivity: new UntypedFormControl(),
      lastComment: new UntypedFormControl()
    });
  }

  save() {
    var parameters = { data: Object.assign({}, this.formData.value) };
    parameters['data']['custom_fields'] = JSON.stringify(this.custom_field_data);
    //console.log(parameters);
    this.dialog.close(parameters);
  }

  getCustomFields() {
		/* Get Fields */
		this.data.getCustomFields(null).subscribe(
			(res: Response) => {
				this.custom_fields = (<any>res).rows;

        this.custom_field_data = this.settings['custom_fields'] == undefined ? {} : JSON.parse(this.settings['custom_fields']);

        for (var i=0; i<this.custom_fields.length; i++) {
          if (this.custom_field_data[this.custom_fields[i].entity-1] == undefined) this.custom_field_data[this.custom_fields[i].entity-1] = {};
          var options_helper = (this.custom_fields[i].field_default).split('|');
          
          for (var j=0; j<options_helper.length; j++) {
            if (this.custom_field_data[this.custom_fields[i].entity-1][options_helper[j]] == undefined) this.custom_field_data[this.custom_fields[i].entity-1][options_helper[j]] = false;
          }          
          
          //console.log(options_helper);
				}

        //console.log(this.custom_fields);
        //console.log(this.custom_field_data);

      }	
		);
	}

  safe(input) { 
		let regex = /[^A-Za-z0-9]/g;
  		return input.replace(regex, "_");
	}

	checkboxfield_click(entity, option, state) {  
    if (this.custom_field_data[entity-1] == '') this.custom_field_data[entity-1] = {};				
    if (this.custom_field_data[entity-1] == undefined) this.custom_field_data[entity-1] = {};    
    //if (this.custom_field_data[entity-1] == undefined) { this.custom_field_data[entity-1] = []; }
    ////console.log(this.custom_field_data[entity-1]);
		this.custom_field_data[entity-1][option] = state.target.checked;
		//console.log(this.custom_field_data);
	}

  ngOnInit(): void {

    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);

    this.settings = this.injected_data;

    //field.field_default
    //custom_field_data[field.entity][safe(option)]

		//this.showall = localStorage.getItem('customer_showhidefinishedactivities') == 'true' ? true : false; 

		//alert(this.entity);
	  this.getCustomFields();
		this.data.getCustomerStatuses(null).subscribe(
			(res: Response) => {
				this.statuses = (<any>res).rows;				
			}
		);

		$(document).on('click', '#select_all', function() {
        	var status = $(this).prop('checked');
        	$('.col input[type=checkbox]').attr('checked', status);
    	});

	}

}


