import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../services/data.service';
import { GlobalVariables } from '../../services/data.globals';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { CustomerComponent } from '../customer/customer.component';
import { TranslateService } from '@ngx-translate/core'; 

interface DialogData {
  email: string;
}

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.css']
})

export class SelectCustomerComponent implements OnInit {

	public search;

	constructor(
		private data_svc: DataService,
		public dialog: MatDialog,
    	public dialogRef: MatDialogRef<SelectCustomerComponent>,
    	@Inject(MAT_DIALOG_DATA) public data: DialogData,
    	public translate: TranslateService
    ) {
		   translate.addLangs(['en', 'si']);
	}

	public items;

	addCustomer() {
		var editDialog = this.dialog.open(CustomerComponent, {});
		editDialog.componentInstance.entity = null;
		editDialog.afterClosed().subscribe(result => {	 
			 if (result.value.company != undefined) {
				this.search = result.value.company; 
				this.onSearchCustomer();
			 } 
		}); 
	}

	ngOnInit(): void {
		var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    	this.translate.use(lang);
		var parameters = { 'page' : 0, 'items' : 25, 'order' : 'company', search: '' };
		this.getCustomers(parameters);		
	}

	getCustomers(parameters) {
		this.data_svc.selectCustomer(parameters).subscribe(
			(res: Response) => {
				this.items = (<any>res).rows;
				console.log(this.items);
			}
		);
	}

	/*onSearchCustomer(event:any) {*/
	onSearchCustomer() {
		/*var parameters = { 'page' : 0, 'items' : 25, 'order' : 'company', search: event.target.value };*/
		var parameters = { 'page' : 0, 'items' : 25, 'order' : 'company', search: this.search };
		this.getCustomers(parameters);
	}

	onSelect(selection) {		
		this.dialogRef.close(selection);
	}

}
