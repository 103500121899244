/*import { User as user_form_data } from './../login/User';*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { catchError, map, tap, switchMap } from 'rxjs/operators';
import { Router } from "@angular/router";
import { DataService } from '../services/data.service';

//import { GlobalVariables } from '../../../services/data.globals';
import { GlobalVariables } from './data.globals';
//import { MembersettingsService } from './membersettings.service';


import { HttpParams } from "@angular/common/http";

interface user_form_data {
	name:String;
	password:String;
  p:String;
  token:String
}

interface JWT_user {
  logged_in: boolean;
  user_name: string;
  name: string;
  user_id: string;
  id_email: string;
  user_group: string;
}

const current_user: JWT_user = {
  logged_in: false,
  user_name: 'guest',
  user_id: '0',
  name: 'Guest',
  user_group: null,
  id_email: null
};

@Injectable({
  providedIn: 'root'
})

export class AuthService {

	private cached_token;

	constructor(
    private http: HttpClient, 
    private router: Router,
    /*private data: DataService,*/
    
  ) {}

	public user_subject = new BehaviorSubject(current_user);
	user_info$: Observable<JWT_user> = this.user_subject.asObservable();

	loginWithToken(user: user_form_data): Observable<boolean>{    
    return this.http.post(
			'https://api.biliz.com/auth/authWithToken.php', 
			{ username: user.name, token:user.token }
		).pipe(			
			catchError((err: any) => {
    		return throwError(err);
			}),			
			map((response: JWT_user) => {      
        console.warn(response);
				const access_token = response['access_token'];
        if (access_token && access_token !== 'non_authorized') {
          this.token = access_token;
          return true;		          
        } else {
          return false;
        }
			})
		);
	}

  login(user: user_form_data): Observable<boolean>{
    return this.http.post(
			'https://api.biliz.com/auth/auth.php', 
			{ username: user.name, p:user.p }
		).pipe(			
			catchError((err: any) => {
    		return throwError(err);
			}),			
			map((response: JWT_user) => {
        ////console.log('LOGIN GRANTED');
				const access_token = response['access_token'];
        if (access_token && access_token !== 'non_authorized') {
          this.token = access_token;
          return true;		          
        } else {
          return false;
        }
			})
		);
	}

  checkUser(user: user_form_data): Observable<JWT_user> {
    return this.http.post<JWT_user>(
        'https://api.biliz.com/auth/checkUser.php', 
        { username: user.name, domain: window.location.hostname }
    ).pipe(
        catchError((err: any) => {
            return throwError(() => err);
        }),
        map((response: JWT_user) => {
            console.log(response);	
            return response;  // Vrne celoten objekt namesto boolean vrednosti
        })
    );
  }

	logout() {
		this.token = null;
		current_user.logged_in = false;
		current_user.user_name = 'guest';
    sessionStorage.clear();
    localStorage.removeItem('AUTH_TOKEN');		
    window.location.reload();
	}
 
  get_user_details(): Observable<JWT_user> {

    return this.http.get<JWT_user>('https://api.biliz.com/auth/info.php', { headers : { Authorization:`Bearer ${this.token}` } } ).pipe(        
      switchMap((response: JWT_user) => {          
        //console.log('?????????');
        GlobalVariables.email = response.id_email;
        GlobalVariables.username = response.name;
        GlobalVariables.group = response.user_group;
        GlobalVariables.id = response.user_id;
        GlobalVariables['admin_rights'] = response['admin_rights'];
        GlobalVariables['apps'] = response['apps'];

        /* get members data */
        /*
        this.data.getMemberSettings(null).subscribe(
        (res: Response) => {              
        var memberSettingsArray = [];
        for (var i=0; i<res['rows'].length; i++) {
          memberSettingsArray[res['rows'][i].parameter] = res['rows'][i].value;
        }
        GlobalVariables.memberSettings = memberSettingsArray;              
        //console.log(GlobalVariables.memberSettings);
        }
        );
        */          
        response.logged_in = true;
        this.user_subject.next(response);
        return of(response);
    }),
      catchError((err) => {
        this.user_subject.next(current_user);
        return of(current_user);
      })
    );

  }

	get token() {
    if (this.cached_token) {
      return this.cached_token;
    }
    	
  const storage_token = localStorage.getItem('AUTH_TOKEN');
    if (storage_token) {
      this.cached_token = storage_token;
      return storage_token;
    }
  }

  set token(value) {
    this.cached_token = value;
    if (value) {
      localStorage.setItem('AUTH_TOKEN', value);        
    } else {
      localStorage.removeItem('AUTH_TOKEN');        
    }
  }

}
