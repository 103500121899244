import { Component, OnInit, Input } from '@angular/core'
import { DataService } from '../../services/data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalVariables } from '../../services/data.globals';
import { CustomerComponent } from '../../customers/customer/customer.component';4
import { SelectCustomerComponent } from '../../customers/select-customer/select-customer.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ModalComponent } from '../../modal/modal.component';
import { ActivitySelectComponent } from '../../activities/activity-select/activity-select.component';

import { ErpPrintComponent } from '../../erp/erp-print/erp-print.component';

import { jsPDF } from "jspdf";
import { CommonFunctions } from '../../services/common.functions';

@Component({
  selector: 'app-erp-document', 
  templateUrl: './erp-document.component.html',
  styleUrls: ['./erp-document.component.css']
})

export class ErpDocumentComponent implements OnInit {
    
  public pos_show = true;
  public sku_show = true;
  public description_show = true;  
  public qty_show = true;
  public unit_show = true;
  public tax_show = true;
  public price_show = true;
  public priceTax_show = true;
  public discount_show = true;
  public priceWithDiscount_show = true;
  public priceTaxWithDiscount_show = true;
  public total_show = true;
  public totalWithTax_show = true;  
  public current_document_lines = [];
  public current_document_lines_formated = [];  
  public selectedLine = 0;
  public selectedCell = 0;
  public save = true;
  public settings = false;
  public settingsEdit = false;
  public dateEdit = false;
  public document = {};
  public summary = [];
  public summary_formated = [];
  public LastEntity = "-1";
  public swapDocumentWindow = false;
  public swapTo = null;
  public dropdown = false;
  public allowedDocuments = [ 'invoice', 'creditnote' ];
  public documentColor = { invoice:'#4ab05a', creditnote:'#ff6b2c' };
  public documentColorHelper = 'none';
  public taxSpecificationWindow = false;
  public taxSpecification = { 0: {index: 0, base: 0, tax: 0} };
  public statisticsWindow = false;
  public time_tracking_stat = [];
  public time_tracking_stat_helper = [];
  public month;
	public priorMonth;
	public year;
  public linkWindow = false;  
  public colPDF = {pos: true, sku: true, description: true, qty: true, unit: true, tax: true, price: true, priceTax: true, discount: true, priceWithDiscount: true, priceTaxWithDiscount: true, total: true, totalWithTax: true};
  public colScreen = {pos: true, sku: true, description: true, qty: true, unit: true, tax: true, price: true, priceTax: true, discount: true, priceWithDiscount: true, priceTaxWithDiscount: true, total: true, totalWithTax: true};
  public shortcuts = {};
  public paymentCondition = -1;
  public defaultTaxRate = "0";
  public selectedTab = "settings";
  

  @Input() entity: string;
  @Input() type: string;  

  constructor(
    private data: DataService,
    public dialog: MatDialog,
    public cFunction: CommonFunctions,
    public dialogErp: MatDialogRef<ErpPrintComponent>,
    public translate: TranslateService,
    public modalDialog: MatDialog    
  ) {
    translate.addLangs(['en', 'si']);
  }


  /****************************************/
  /* function: save burgebar pin function */
  /* input: null */
  /* output: null */
  pinShortcut() {
    this.data.setMemberSettings({'parameter': 'erpDocumentShortcuts', 'value': JSON.stringify(this.shortcuts)}).subscribe((res: Response) => {}); 
  } //pinShortcut()


  /****************************************/
  /* function validate and retrieve organisation name from VAT via VIES */
  /* input: null */
  /* output: null */
  public validateVAT() {    
    var countryCodes = ['AT','BE','BG','HR','CY','CZ','DK','EE','FI','FR','DE','GR','HU','IE','IT','LV','LT','LU','MT','NL','PL','PT','RO','SK','SI','ES','SE'];
    var vat = this.document['vat'];
    var vatCountry = vat.replace(/[0-9]/g, '');
    var vatNumber = vat.replace(/\D/g, '');

    if (!countryCodes.includes(vatCountry) && vatNumber.length > 7) {

      var content = this.translate.instant('Entered VAT number is not valid EU VAT number');
      var modalDialog = this.dialog.open(ModalComponent, { data: { content: content, yes: this.translate.instant('Close') , no: null, cancel: null } });

    } else {

      this.data.validateVat({country: vatCountry, vat: vatNumber}).subscribe(
        async (res: Response) => {          
          var json = JSON.parse(<any>res)
          if (json['isValid']) {
            var content = this.translate.instant('Entered VAT number ') + this.document['vat'] + this.translate.instant(' is valid EU VAT number.') + "<br /><br />" + this.translate.instant('Would you like to use organisation data on document?')+"<br/>"+json['name']+"<br/>"+json['address'];
            var modalDialog = this.dialog.open(ModalComponent, { data: { content: content, yes: this.translate.instant('Use data') , no: null, cancel: this.translate.instant('Cancel') } });

            modalDialog.afterClosed().subscribe(result => {
              if (result['yes']!=undefined) {        
                this.document['address'] = json['name'].trimStart() + "\r\n" + json['address'].replace(", ", "\r\n").trimStart();
              }
            });

          } else {
            var content = this.translate.instant('Entered VAT number ') + this.document['vat'] + this.translate.instant(' is not valid EU VAT number');
            var modalDialog = this.dialog.open(ModalComponent, { data: { content: content, yes: this.translate.instant('Close'), no: null, cancel: null } });            
          }
        }
      );  

    }    
  } //validateVAT()


  /****************************************/
  /* function load document by entity     */
  /* input: entity as integer */
  /* output: null */
  public load_document(entity) {     
      this.data.getErpDocuments({ items : 1, page : 0, order : 't1.entity', orderby : 'desc', search : null, entity: entity }).subscribe(
        async (res: Response) => {          
          this.document = (<any>res).rows[0];    
          console.log(this.document);      
          this.current_document_lines = JSON.parse(this.document['items']);
  
          for (var i=0; i<this.current_document_lines.length; i++) {           
            this.document_format_lines(this.current_document_lines, i, -1);
          }        
          
          /* open alternative address & email if there is value */
          if (this.document['address2']!='' && this.document['address2']!=null) { this.document['address2_enabled'] = 1; }
          if (this.document['email']!='' && this.document['email']!=null) { this.document['addressEmail_enabled'] = 1; }          

          /* if document do not have selected print/pdf primary language, select SI */
          if (this.document['lang']!='en' && this.document['lang']!='si' && this.document['lang']!='de') { this.document['lang'] = 'si'; }

          /* recalculate summary numbers of document */
          this.document_summary(this.current_document_lines);

          /* get last entity of current document type group */
          var parameters = {year: new Date().getFullYear(), type: this.document['type']};          
          this.documentColorHelper = this.documentColor[this.document['type']];
          
          this.data.getErpLastDocumentEntity(parameters).subscribe(async (res: Response) => {      
            this.LastEntity = (<any>res).rows[0]['entity'];            
          });              
        }
      )    
  } //load_document

  /****************************************/
  /* function convert/copy document to another document type     */
  /* input: null */
  /* output: null */
  public swapDocument() {
    var parameters = {year: new Date().getFullYear()};      
    parameters['type'] = this.swapTo;
    this.document['type'] = this.swapTo;
    this.documentColorHelper = this.documentColor[parameters['type']];    
    this.data.getErpLastDocumentNumber(parameters).subscribe(
      (res: Response) => {          
        this.entity = null;
        var document_number = '0000' + (<any>res['rows'][0]['last_document_number'] + 1).toString();
        document_number = document_number.substr(document_number.length - 4);
        this.document['document_number'] = <any>res['rows'][0]['last_document_number'] + 1;
        this.document['document_year'] = <any>res['parameters']['year'];
        this.document['document_number_text'] = this.document['document_year'] + "/" + document_number;
        this.document['document_date'] = new Date().toJSON().slice(0, 10);
        this.document['document_service'] = new Date().toJSON().slice(0, 10);
        this.document['document_payment'] = new Date().toJSON().slice(0, 10);     
        this.save = false;
      }
    );   
  } //swapDocument


  /****************************************/
  /* function save print language       */
  /* input: null */
  /* output: null */
  public save_document_language() {    
    this.document['entity'] = this.entity;        
    this.data.saveErpDocumentPrintLanguage({ data: this.document }).subscribe(
      (res: Response) => {             
        this.save = true;
      }
    );    
  } // save_document_language()


  /****************************************/
  /* function save current document       */
  /* input: null */
  /* output: null */
  public save_document() {    
    this.document['entity'] = this.entity;
    
    /* check additional address checkbox & eamil sending */
    this.document['address2'] = this.document['address2_enabled'] != true ? null : this.document['address2'];
    this.document['email'] = this.document['addressEmail_enabled'] != true ? null : this.document['email'];
    /* format neccessary data */        
    this.document['items'] = JSON.stringify(this.current_document_lines);
    this.document['items_formated'] = JSON.stringify(this.current_document_lines_formated);
    this.document['summary'] = JSON.stringify(this.summary);
    this.document['summary_formated'] = JSON.stringify(this.summary_formated);
    this.document['total'] = this.summary[0]['total_without_tax'];
    this.document['total_with_tax'] = this.summary[0]['total_with_tax'];
    this.document['tax'] = JSON.stringify(this.taxSpecification);
    var erp_addres = this.document['address'].split(/\r?\n/);
    this.document['customer'] = erp_addres[0];
    /* set document as not saved */
    this.save = false;
    
    this.data.saveErpDocument({ data: this.document }).subscribe(
      (res: Response) => {     
        this.dialogErp.close(); 
        this.save = true;
      }
    );    
  } // save_document()


  /****************************************/
  /* function save current document       */
  /* input: null */
  /* output: null */
  public clear_customer() {
    this.document['address'] = "";
    this.document['customer_id'] = "";
    this.document['vat'] = "";
  } //clear_customer()


  /****************************************/
  /* function add cusotmer to document    */
  /* input: where to pass (not in use at the moment) */
  /* output: null */
  public choose_customer(target) {
    var selectCustomerDialog = this.dialog.open(SelectCustomerComponent, {});    
		selectCustomerDialog.afterClosed().subscribe(result => {			
      console.log(result);
			if (!isNaN(result.entity)) { 
        this.document['address'] = result['customer'] != "" && result['customer'] != null ? result['customer'] : '';
        this.document['address'] = result['address'] != "" && result['address'] != null ? this.document['address'] + "\n" + result['address'] : this.document['address'];
        this.document['address'] = result['address2'] != "" && result['address2'] != null ? this.document['address'] + "\n" + result['address2'] : this.document['address'];
        this.document['address'] = result['city'] != "" && result['city'] != null ? this.document['address'] + "\n" + result['post'] + " " + result['city'] : this.document['address'];
        this.document['address'] = result['county'] != "" && result['county'] != null ? this.document['address'] + "\n" + result['country'] : this.document['address'];
       	this.document['customer_id'] = result['entity'];
        this.document['email'] = result['email'];
        this.document['vat'] = result['vat'];        
			}
		});
  } //choose_customer()


  public getEmail() {
    this.data.getCustomer({id:this.document['customer_id']}).subscribe(
      (res: Response) => {	
        let email = (<any>res).rows[0]['email'];

        if (email.length < 3) {
          var content = "Selected customer do not have valid email address.";
          var modalDialog = this.dialog.open(ModalComponent, {
            data: { content: content, yes: null, no: null, cancel: 'Ok' }
          });
        } else {
          this.document['email'] = email;
        }        
      }
    );
  }

  /****************************************/
  /* function prepare and calculate summary data */
  /* input: document rows content */
  /* output: null */
  public document_summary(current_document_lines) {
    this.summary[0] = {};
    this.summary[0]['total_without_tax'] = 0;
    this.summary[0]['tax_total'] = 0;
    this.summary[0]['total_with_tax'] = 0;
    this.summary[0]['for_payment'] = 0;
    
    this.taxSpecification = { 0: {index: 0, base: 0, tax: 0} };

    for (var i=0; i<current_document_lines.length; i++) {
      this.summary[0]['total_without_tax'] += this.current_document_lines[i]['total'];
      this.summary[0]['tax_total'] += this.current_document_lines[i]['totalWithTax'] - this.current_document_lines[i]['total'];
      this.summary[0]['total_with_tax'] += this.current_document_lines[i]['totalWithTax'];
      this.summary[0]['for_payment'] += this.current_document_lines[i]['totalWithTax'];

      /* tax recapitulation*/
      var helper = this.current_document_lines[i];
      var index = "id_" + this.current_document_lines[i]['tax'];     
      if (this.taxSpecification[index] == undefined) {        
        if (helper['total'] != 0) {
          this.taxSpecification[index] = {index: helper['tax'], base: (helper['total']).toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2}), tax:(helper['totalWithTax']-helper['total']).toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2})};
        }
      } else {
        var base = this.taxSpecification[index].base + helper['total'];
        var tax = this.taxSpecification[index].tax + helper['totalWithTax']-helper['total'];

        if (base != 0) {
          this.taxSpecification[index] = {index: helper['tax'], base: base.toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2}), tax: tax.toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2})};
        }
      }
    }

    this.summary[0]['for_payment'] = this.summary[0]['for_payment'] - this.document['paid'];

    if (this.summary[0]['for_payment'] < 0.0099 && this.summary[0]['for_payment'] > -0.0099) {
      this.paymentCondition = 2;
    } else if (this.summary[0]['for_payment'] > 0.0099) {
      this.paymentCondition = 0;
    } else if (this.summary[0]['for_payment'] < -0.0099) {
      this.paymentCondition = 1;
    }    

    this.summary_formated[0] = {};
    this.summary_formated[0]['total_without_tax'] = this.summary[0]['total_without_tax'].toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2});
    this.summary_formated[0]['tax_total'] = this.summary[0]['tax_total'].toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2});
    this.summary_formated[0]['total_with_tax'] = this.summary[0]['total_with_tax'].toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2});
    this.summary_formated[0]['for_payment'] = this.summary[0]['for_payment'].toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2});
  } //document_summary()


  /****************************************/
  /* function format ro lines except current cell */
  /* input: document row, row of current cell, col of current cell */
  /* output: null */
  public document_format_lines(current_document_lines, row, col) {
    for (var i=0; i<current_document_lines.length; i++) {        
        if (this.current_document_lines_formated[i] == undefined) {
          this.current_document_lines_formated[i] = {};
        }
        //console.log(i, row, col);
        if (i == row && col != 0) this.current_document_lines_formated[i]['pos'] = current_document_lines[i]['pos'];
        if (i == row && col != 1) this.current_document_lines_formated[i]['sku'] = current_document_lines[i]['sku'];
        if (i == row && col != 2) this.current_document_lines_formated[i]['description'] = current_document_lines[i]['description'];
        if (i == row && col != 4) this.current_document_lines_formated[i]['unit'] = current_document_lines[i]['unit'];
        if (i == row && col != 3) this.current_document_lines_formated[i]['qty'] = current_document_lines[i]['qty'] != 0 ? current_document_lines[i]['qty'].toLocaleString('sl-SI', {maximumFractionDigits: 1, minimumFractionDigits: 1}) : '';
        if (i == row && col != 5) this.current_document_lines_formated[i]['tax'] = current_document_lines[i]['tax'] != 0 ? current_document_lines[i]['tax'].toLocaleString('sl-SI', {maximumFractionDigits: 1, minimumFractionDigits: 1}) : '';
        if (i == row && col != 6) this.current_document_lines_formated[i]['price'] = current_document_lines[i]['price'] != 0 ? current_document_lines[i]['price'].toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '';
        if (i == row && col != 7) this.current_document_lines_formated[i]['priceTax'] = current_document_lines[i]['priceTax'] != 0 ? current_document_lines[i]['priceTax'].toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '';
        if (i == row && col != 8) this.current_document_lines_formated[i]['discount'] = current_document_lines[i]['discount'] != 0 ? current_document_lines[i]['discount'].toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '';
        if (i == row && col != 9) this.current_document_lines_formated[i]['priceWithDiscount'] = current_document_lines[i]['priceWithDiscount'] != 0 ? current_document_lines[i]['priceWithDiscount'].toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '';
        if (i == row && col != 10) this.current_document_lines_formated[i]['priceTaxWithDiscount'] = current_document_lines[i]['priceTaxWithDiscount'] != 0 ? current_document_lines[i]['priceTaxWithDiscount'].toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '';
        if (i == row && col != 11) this.current_document_lines_formated[i]['total'] = current_document_lines[i]['total'] != 0 ? current_document_lines[i]['total'].toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '';
        if (i == row && col != 12) this.current_document_lines_formated[i]['totalWithTax'] = current_document_lines[i]['totalWithTax'] != 0 ? current_document_lines[i]['totalWithTax'].toLocaleString('sl-SI', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '';

        this.current_document_lines_formated[i]['selectedPrice'] = current_document_lines[i]['selectedPrice'];
        this.current_document_lines_formated[i]['selectedDiscount'] = current_document_lines[i]['selectedDiscount'];
    }        
  } //document_format_lines


  /****************************************/
  /* function delete document row */
  /* input: index of row */
  /* output: null */
  public delete_line(index) {    
    var helper = [];
    var j = 0;
    for (var i=0; i<this.current_document_lines_formated.length; i++) {
      if (i != index) {
        helper[j] = this.current_document_lines_formated[i];
        j++;
      }
    }
    this.current_document_lines_formated = helper;
  } //delete_line()


  /****************************************/
  /* function delete current document */
  /* input: null */
  /* output: null */
  delete_document() {
    var content = "Do you want to delete document parmanently?";
    var modalDialog = this.dialog.open(ModalComponent, {
      data: { content: content, yes: "Yes" , no: "No", cancel: null }
    });

    modalDialog.afterClosed().subscribe(result => {
      if (result['yes']!=undefined) {        
        this.data.deleteErpDocument({entity:this.entity}).subscribe(
          (res: Response) => {
            this.dialogErp.close();
          });
      }
    });
  } //delete_document()


  /****************************************/
  /* function duplicate document */
  /* input: null */
  /* output: null */
  public duplicateErpDocument() {
    var parameters = {year: new Date().getFullYear(), type: this.document['type']};    
    this.documentColorHelper = this.documentColor[this.document['type']];
    
    this.data.getErpLastDocumentNumber(parameters).subscribe(
      (res: Response) => {    
        this.entity = null;
        var document_number = '0000' + (<any>res['rows'][0]['last_document_number'] + 1).toString();
        document_number = document_number.substr(document_number.length - 4);
        this.document['document_number'] = <any>res['rows'][0]['last_document_number'] + 1;
        this.document['document_year'] = <any>res['parameters']['year'];
        this.document['document_number_text'] = this.document['document_year'] + "/" + document_number;
        this.document['document_date'] = new Date().toJSON().slice(0, 10);
        this.document['document_service'] = new Date().toJSON().slice(0, 10);
        this.document['document_payment'] = new Date().toJSON().slice(0, 10);             
        this.save = false;
      }
    );    
  } //duplicateErpDocument()


  /****************************************/
  /* function add new document row */
  /* input: null */
  /* output: null */
  addNewLine() {
    this.current_document_lines[(this.current_document_lines).length] = {
      pos:null, sku:null, description:'', qty:0, unit:null, tax:0, price:0, priceTax:0, discount:0, priceWithDiscount:0, priceTaxWithDiscount:0, total:0, totalWithTax:0, selectedPrice: 'price', selectedDiscount: 'discount'
    };

    this.current_document_lines_formated[(this.current_document_lines_formated).length] = {
      pos:null, sku:null, description:'', qty:0, unit:null, tax:0, price:0, priceTax:0, discount:0, priceWithDiscount:0, priceTaxWithDiscount:0, total:0, totalWithTax:0, selectedPrice: 'price', selectedDiscount: 'discount'
    };   
  } //addNewLine()


  /****************************************/
  /* function get columnt settings */
  /* input: null */
  /* output: null */
  getSettings() {
    var entity = "erp_colPDF";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { 
      this.colPDF = res['rows'][0]['value'] != null && res['rows'][0]['value'] != ''? JSON.parse(res['rows'][0]['value']) : this.colPDF; 
    });

    var entity = "erp_colScreen";
    this.data.getSettings({entity : entity}).subscribe((res: Response) => { 
      this.colScreen = res['rows'][0]['value'] != null && res['rows'][0]['value'] != ''? JSON.parse(res['rows'][0]['value']) : this.colScreen; 
    });
  } //getSettings()


  /****************************************/
  /* function save columnt settings */
  /* input: null */
  /* output: null */
  saveSettings() {
    var entity = "erp_colPDF";
    var value = JSON.stringify(this.colPDF);
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});

    entity = "erp_colScreen";
    value = JSON.stringify(this.colScreen);
    this.data.setSettings({entity : entity, value : value}).subscribe((res: Response) => {});
  } //saveSettings()


  /****************************************/
  /* function pin document to dock */
  /* input: null */
  /* output: null */
	toDock(entity, type = 'erpDocument') {
		var docker = localStorage.getItem('docker_erpDocument');    

		var array_docker = [];
		if (docker != '' && docker != undefined) {
		  array_docker = JSON.parse(docker);
	
		  var index = array_docker.indexOf(entity);
		  if (index !== -1) {
			  array_docker.splice(index, 1);
		  }
		}
		
		array_docker.push(entity);
		docker = JSON.stringify(array_docker);	
		localStorage.setItem('docker_erpDocument', docker);
	} //toDock()

  
  /****************************************/
  /* function get time tracking statistics for customer */
  /* input: customer id, current month, prior moonth, year */
  /* output: null */
	getCustomerStatistics(customer, month, priorMonth, year) {
		this.data.getTimeTrackStat({ 'customer_id' : customer, 'month':new Date().getMonth() , 'year':new Date().getFullYear() }).subscribe(
			(res: Response) => {
				this.time_tracking_stat = <any>res;	

				const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
				const d = new Date();
				this.month = monthNames[d.getMonth()]
				this.year = d.getFullYear();
				this.priorMonth = monthNames[d.getMonth()-1 < 0 ? 11 : d.getMonth()-1];
				
				this.time_tracking_stat['rows_current_month'][0]['minutes'] = this.time_tracking_stat['rows_current_month'][0]['minutes'] != null ? Math.round((this.time_tracking_stat['rows_current_month'][0]['minutes'])/60*10)/10 : 0;
				this.time_tracking_stat['rows_current_year'][0]['minutes'] = this.time_tracking_stat['rows_current_year'][0]['minutes'] != null ? Math.round((this.time_tracking_stat['rows_current_year'][0]['minutes'])/60*10)/10 : 0;
				this.time_tracking_stat['rows_prev_month'][0]['minutes'] = this.time_tracking_stat['rows_prev_month'][0]['minutes'] != null ? Math.round((this.time_tracking_stat['rows_prev_month'][0]['minutes'])/60*10)/10 : 0;
				this.time_tracking_stat['rows_year_to_date_month'][0]['minutes'] = this.time_tracking_stat['rows_year_to_date_month'][0]['minutes'] != null ? Math.round((this.time_tracking_stat['rows_year_to_date_month'][0]['minutes'])/60*10)/10 : 0;
				
				this.time_tracking_stat['defaults']['hoursMonth'] = this.time_tracking_stat['defaults'][0]['hoursMonth'] != null ? Math.round((this.time_tracking_stat['defaults'][0]['hoursMonth'])*10)/10 : 'n/a';
				this.time_tracking_stat['defaults']['hoursYear'] = this.time_tracking_stat['defaults'][0]['hoursYear'] != null ? Math.round((this.time_tracking_stat['defaults'][0]['hoursYear'])*10)/10 : 'n/a';
				this.time_tracking_stat['defaults']['afterHourPrice'] = this.time_tracking_stat['defaults'][0]['afterHourPrice'] != null ? Math.round((this.time_tracking_stat['defaults'][0]['afterHourPrice'])*100)/100 : 'n/a';					

				this.time_tracking_stat['percentMonth'] = (this.time_tracking_stat['rows_current_month'][0]['minutes'] / this.time_tracking_stat['defaults']['hoursMonth'] * 100).toFixed(0);
				this.time_tracking_stat['percentMonthPrior'] = (this.time_tracking_stat['rows_prev_month'][0]['minutes'] / this.time_tracking_stat['defaults']['hoursMonth'] * 100).toFixed(0);
				this.time_tracking_stat['percentYear'] = (this.time_tracking_stat['rows_year_to_date_month'][0]['minutes'] / this.time_tracking_stat['defaults']['hoursYear'] * 100).toFixed(0);

				this.time_tracking_stat['percentMonth_Pie'] = this.time_tracking_stat['percentMonth'] > 100 ? 100 : this.time_tracking_stat['percentMonth'];
				this.time_tracking_stat['percentMonthPrior_Pie'] = this.time_tracking_stat['percentMonthPrior'] > 100 ? 100 : this.time_tracking_stat['percentMonthPrior'];
				this.time_tracking_stat['percentYear_Pie'] = this.time_tracking_stat['percentYear'] > 100 ? 100 : this.time_tracking_stat['percentYear'];

				this.time_tracking_stat['percentMonth_PieColor'] = this.time_tracking_stat['percentMonth'] > 50 ? '#f5a742' : 'lightgreen';				
				this.time_tracking_stat['percentMonthPrior_PieColor'] = this.time_tracking_stat['percentMonthPrior'] > 50 ? '#f5a742' : 'lightgreen';				
				this.time_tracking_stat['percentYear_PieColor'] = this.time_tracking_stat['percentYear'] > 50 ? '#f5a742' : 'lightgreen';

				this.time_tracking_stat['percentMonth_PieColor'] = this.time_tracking_stat['percentMonth'] > 50 ? '#990000' : this.time_tracking_stat['percentMonth_PieColor'];				
				this.time_tracking_stat['percentMonthPrior_PieColor'] = this.time_tracking_stat['percentMonthPrior'] > 50 ? '#990000' : this.time_tracking_stat['percentMonthPrior_PieColor'];				
				this.time_tracking_stat['percentYear_PieColor'] = this.time_tracking_stat['percentYear'] > 50 ? '#990000' : this.time_tracking_stat['percentYear_PieColor'];

        this.time_tracking_stat_helper['rows_prev_month_by_categories'] = [];
        for (var i=0; i<(this.time_tracking_stat['rows_prev_month_by_categories']).length; i++) {          
          this.time_tracking_stat_helper['rows_prev_month_by_categories'][i] = [];
          this.time_tracking_stat_helper['rows_prev_month_by_categories'][i]['category'] = JSON.parse(this.time_tracking_stat['rows_prev_month_by_categories'][i]['category']);
          this.time_tracking_stat_helper['rows_prev_month_by_categories'][i]['timeHours'] = Math.round((this.time_tracking_stat['rows_prev_month_by_categories'][i]['minutes'])/60*10)/10;
          this.time_tracking_stat_helper['rows_prev_month_by_categories'][i]['timeMinutes'] = this.time_tracking_stat['rows_prev_month_by_categories'][i]['minutes'];
        }

        this.time_tracking_stat_helper['rows_current_year_by_categories'] = [];
        for (var i=0; i<(this.time_tracking_stat['rows_current_year_by_categories']).length; i++) {          
          this.time_tracking_stat_helper['rows_current_year_by_categories'][i] = [];
          this.time_tracking_stat_helper['rows_current_year_by_categories'][i]['category'] = JSON.parse(this.time_tracking_stat['rows_current_year_by_categories'][i]['category']);
          this.time_tracking_stat_helper['rows_current_year_by_categories'][i]['timeHours'] = Math.round((this.time_tracking_stat['rows_current_year_by_categories'][i]['minutes'])/60*10)/10;
          this.time_tracking_stat_helper['rows_current_year_by_categories'][i]['timeMinutes'] = this.time_tracking_stat['rows_current_year_by_categories'][i]['minutes'];
        }

        this.time_tracking_stat_helper['rows_current_month_by_categories'] = [];
        for (var i=0; i<(this.time_tracking_stat['rows_current_month_by_categories']).length; i++) {          
          this.time_tracking_stat_helper['rows_current_month_by_categories'][i] = [];
          this.time_tracking_stat_helper['rows_current_month_by_categories'][i]['category'] = JSON.parse(this.time_tracking_stat['rows_current_month_by_categories'][i]['category']);
          this.time_tracking_stat_helper['rows_current_month_by_categories'][i]['timeHours'] = Math.round((this.time_tracking_stat['rows_current_month_by_categories'][i]['minutes'])/60*10)/10;
          this.time_tracking_stat_helper['rows_current_month_by_categories'][i]['timeMinutes'] = this.time_tracking_stat['rows_current_month_by_categories'][i]['minutes'];
        }

			}
		);				
	} //getCustomerStatistics()

  /**********************************************************************************************/
  generateStartistics() {    
    this.getCustomerStatistics(this.document['customer_id'], null, null, null);
  }


  /****************************************/
  /* function assign activity to document */
  /* input: null */
  /* output: null */
	selectActivity() {
		var ActivitySelectDialog = this.dialog.open(ActivitySelectComponent, {});
		ActivitySelectDialog.afterClosed().subscribe(result => {			
			if (!isNaN(result.entity)) {  
				this.document['activity_id'] = result.entity;
				this.document['activity'] = result.activity;				
			}
		});       
	} //selectActivity()


  /****************************************/
  /* function clear selected activity */
  /* input: null */
  /* output: null */
	removeActivity() {
		this.document['activity_id'] = null;
		this.document['activity'] = null;
	} //removeActivity()
  
  
  /****************************************/
  /* function create PDF of current document */
  /* input: null */
  /* output: null */
  generatePDFModal() {
    var erpEntities = [];
    erpEntities[0] = this.entity;
    var ErpPrintComponentDialog = this.dialog.open(ErpPrintComponent, {
      'width' : '450px',      
      data: erpEntities
    });
		ErpPrintComponentDialog.afterClosed().subscribe(result => {});			
  }

  /****************************************/
  /* function show date picker on icon click */
  /* input: null */
  /* output: null */
  showDatePicker() {
    //const inputDateElement = document.querySelector('input[type="date"]');
    //inputDateElement.showPicker();
    document.getElementById("calendar").style.visibility="visible";
  }

  /********************************************/
  /* Other small functions */
  switchTab(value) {
		this.selectedTab = value;
	}

  /********************************************/
  /********************************************/
  /********************************************/
  ngOnInit(): void {
  
    this.current_document_lines[0] = { pos:null, sku:null, description:'', qty:0, unit:null, tax:0, price:0, priceTax:0, discount:0, priceWithDiscount:0, priceTaxWithDiscount:0, total:0, totalWithTax:0, selectedPrice: 'price', selectedDiscount: 'discount' };
    this.summary[0] = { total_without_tax:0, tax_total:0, total_with_tax:0, for_payment:0 }
    this.document = { customer_id: '', address: '', vat: '', address2: '', document_number: '', document_year: '', document_number_text: '', document_date: '', document_service: '', document_payment: '', place: '', document_comment: '', items: null, items_formated: null, summary: null, summary_formated: null, total_with_tax: null, total: null, customer: null }
    
    this.getSettings();

    this.data.getMemberSettings(null).subscribe(
      (res: Response) => {        
          var memberSettingsArray = [];        
          for (var i=0; i<res['rows'].length; i++) {          
              memberSettingsArray[res['rows'][i].parameter] = res['rows'][i].value;
          }        
      GlobalVariables.memberSettings = memberSettingsArray;          
      if (GlobalVariables.memberSettings['erpDocumentShortcuts']!=undefined) {
        this.shortcuts = JSON.parse(GlobalVariables.memberSettings['erpDocumentShortcuts']);      
      } else { this.shortcuts = {}; }
      var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
      this.translate.use(lang);	    
    });

    if (this.entity != null) {			
			this.load_document(this.entity);      
    } else {      
      var parameters = {year: new Date().getFullYear()};      
      parameters['type'] = this.type;
      this.document['type'] = parameters['type'];
      this.documentColorHelper = this.documentColor[this.document['type']];
      this.data.getErpLastDocumentNumber(parameters).subscribe(
        (res: Response) => {            
          var document_number = '0000' + (<any>res['rows'][0]['last_document_number'] + 1).toString();
          document_number = document_number.substr(document_number.length - 4);
          this.document['document_number'] = <any>res['rows'][0]['last_document_number'] + 1;
          this.document['document_year'] = <any>res['parameters']['year'];
          this.document['document_number_text'] = this.document['document_year'] + "/" + document_number;
          this.document['document_date'] = new Date().toJSON().slice(0, 10);
          this.document['document_service'] = new Date().toJSON().slice(0, 10);
          this.document['document_payment'] = new Date().toJSON().slice(0, 10);      
        }
      );      
    }

    let angularVariables = this;

    function roundOf(n, p) {
      const n1 = n * Math.pow(10, p + 1);
      const n2 = Math.floor(n1 / 10);
      if (n1 >= (n2 * 10 + 5)) {
          return (n2 + 1) / Math.pow(10, p);
      }
      return n2 / Math.pow(10, p);
    }

    function formatNumber(number, digits) {
      return number != 0 ? number.toLocaleString('sl-SI', {minimumFractionDigits: digits}) : '';
    }  

     /* row navigation logic */
    $(document).ready(function() {

      angularVariables.document_format_lines(angularVariables.current_document_lines, 0, 0);
      angularVariables.document_summary(angularVariables.current_document_lines);

      /* get cell location */
      $(document).on('focus', '.elements_line input', function() {        
        angularVariables.selectedLine = $(this).parent().parent().index() - 1;        
        angularVariables.selectedCell = parseInt($(this).attr('col'));
      });
      
      $(document).on('keyup', 'input, textarea', function(e) { 
        var code = e.keyCode || e.which;
        if ((code >= 49 && code <= 220) || (code == 46) || (code == 8)) { 
          angularVariables.save = false;
        }
      });

      /* actions on key up */
      $(document).on('keyup', '.elements_line input', function(e) { 
        var calc = null;
        var name = $(this).attr('name');
        var code = e.keyCode || e.which;

        //console.log($('.elements_line input').length/(angularVariables.current_document_lines).length, angularVariables.selectedCell, (angularVariables.current_document_lines).length > angularVariables.selectedCell);

        if (code == 13) {
          if ($('.elements_line input').length/(angularVariables.current_document_lines).length+1 < angularVariables.selectedCell && angularVariables.current_document_lines_formated[(angularVariables.current_document_lines_formated).length-1]['description']!='') {          
            
            angularVariables.current_document_lines[(angularVariables.current_document_lines).length] = {
              pos:null, sku:null, description:'', qty:0, unit:null, tax:0, price:0, priceTax:0, discount:0, priceWithDiscount:0, priceTaxWithDiscount:0, total:0, totalWithTax:0, selectedPrice: 'price', selectedDiscount: 'discount'
            };

            angularVariables.current_document_lines_formated[(angularVariables.current_document_lines_formated).length] = {
              pos:null, sku:null, description:'', qty:0, unit:null, tax:0, price:0, priceTax:0, discount:0, priceWithDiscount:0, priceTaxWithDiscount:0, total:0, totalWithTax:0, selectedPrice: 'price', selectedDiscount: 'discount'
            };           

            angularVariables.selectedLine = (angularVariables.current_document_lines).length - 1;            
            var interval = setInterval(function() {
                $('.elements_line').eq(angularVariables.selectedLine).find('input').eq(0).focus().select();             
                clearInterval(interval);
            }, 250);

          } else {
            $(this).parent().next('div').find('input').focus().select();
          }
        
        } else if ((code >= 49 && code <= 105) || (code == 110) || (code == 188) || (code == 190) || (code == 46) || (code == 8) || (code == 107) || (code == 109)) {          
          
          $(this).parent().parent().find('input').removeClass('input');
         
          var helper = {
            pos:angularVariables.current_document_lines_formated[angularVariables.selectedLine]['pos'],
            sku:angularVariables.current_document_lines_formated[angularVariables.selectedLine]['sku'],
            description:angularVariables.current_document_lines_formated[angularVariables.selectedLine]['description'],
            unit:angularVariables.current_document_lines_formated[angularVariables.selectedLine]['unit'],
            qty:parseFloat((angularVariables.current_document_lines_formated[angularVariables.selectedLine]['qty']).toString().replace('.', '').replace(',', '.')),
            tax:parseFloat((angularVariables.current_document_lines_formated[angularVariables.selectedLine]['tax']).toString().replace('.', '').replace(',', '.')),
            price:parseFloat((angularVariables.current_document_lines_formated[angularVariables.selectedLine]['price']).toString().replace('.', '').replace(',', '.')),
            priceTax:parseFloat((angularVariables.current_document_lines_formated[angularVariables.selectedLine]['priceTax']).toString().replace('.', '').replace(',', '.')),
            discount:parseFloat((angularVariables.current_document_lines_formated[angularVariables.selectedLine]['discount']).toString().replace('.', '').replace(',', '.')),
            priceWithDiscount:parseFloat((angularVariables.current_document_lines_formated[angularVariables.selectedLine]['priceWithDiscount']).toString().replace('.', '').replace(',', '.')),
            priceTaxWithDiscount:parseFloat((angularVariables.current_document_lines_formated[angularVariables.selectedLine]['priceTaxWithDiscount']).toString().replace('.', '').replace(',', '.')),
            total:parseFloat((angularVariables.current_document_lines_formated[angularVariables.selectedLine]['total']).toString().replace('.', '').replace(',', '.')),
            totalWithTax:parseFloat((angularVariables.current_document_lines_formated[angularVariables.selectedLine]['totalWithTax']).toString().replace('.', '').replace(',', '.')),
            selectedPrice: angularVariables.current_document_lines_formated[angularVariables.selectedLine]['selectedPrice'],
            selectedDiscount: angularVariables.current_document_lines_formated[angularVariables.selectedLine]['selectedDiscount']
          };
          
          /* undefined control */
          helper['qty'] = !isNaN(helper['qty']) && helper['qty'] != undefined ? helper['qty'] : 0;
          helper['tax'] = !isNaN(helper['tax']) && helper['tax'] != undefined ? helper['tax'] : 0;
          helper['price'] = !isNaN(helper['price']) && helper['price'] != undefined ? helper['price'] : 0;
          helper['priceTax'] = !isNaN(helper['priceTax']) && helper['priceTax'] != undefined ? helper['priceTax'] : 0;
          helper['discount'] = !isNaN(helper['discount']) && helper['discount'] != undefined ? helper['discount'] : 0;
          helper['discount'] = !isNaN(helper['discount']) && helper['discount'] != undefined ? helper['discount'] : 0;
          helper['priceWithDiscount'] = !isNaN(helper['priceWithDiscount']) && helper['priceWithDiscount'] != undefined ? helper['priceWithDiscount'] : 0;
          helper['priceTaxWithDiscount'] = !isNaN(helper['priceTaxWithDiscount']) && helper['priceTaxWithDiscount'] != undefined ? helper['priceTaxWithDiscount'] : 0;
          helper['total'] = !isNaN(helper['total']) && helper['total'] != undefined ? helper['total'] : 0;
          helper['totalWithTax'] = !isNaN(helper['totalWithTax']) && helper['totalWithTax'] != undefined ? helper['totalWithTax'] : 0;

          /* calculation */
          if (name == 'price' || name == 'tax') {
            helper['priceTax'] = helper['tax'] != 0 && helper['tax'] != undefined ? helper['price'] * (helper['tax'] / 100 + 1) : helper['price'];
            helper['selectedPrice'] = 'price';
            helper['priceWithDiscount'] = helper['discount'] != 0 && helper['discount'] != undefined ? helper['price'] - helper['price'] * helper['discount'] / 100 : helper['price'];
            helper['priceTaxWithDiscount'] = helper['tax'] != 0 && helper['tax'] != undefined ? helper['priceWithDiscount'] * (helper['tax'] / 100 + 1) : helper['priceWithDiscount'];            
            calc = helper['selectedDiscount'];            
          }

          if (name == 'priceTax') {
            helper['price'] = helper['tax'] != 0 && helper['tax'] != undefined ? helper['priceTax'] / (helper['tax'] / 100 + 1) : helper['priceTax'];
            helper['selectedPrice'] = name;
            helper['priceWithDiscount'] = helper['discount'] != 0 && helper['discount'] != undefined ? helper['price'] - helper['price'] * helper['discount'] / 100 : helper['price'];
            helper['priceTaxWithDiscount'] = helper['tax'] != 0 && helper['tax'] != undefined ? helper['priceWithDiscount'] * (helper['tax'] / 100 + 1) : helper['priceWithDiscount'];
            calc = helper['selectedDiscount'];            
          }

          if (name == 'discount' || calc == 'discount') {            
            helper['priceWithDiscount'] = helper['discount'] != 0 && helper['discount'] != undefined ? helper['price'] - helper['price'] * helper['discount'] / 100 : helper['price'];
            helper['priceTaxWithDiscount'] = helper['tax'] != 0 && helper['tax'] != undefined ? helper['priceWithDiscount'] * (helper['tax'] / 100 + 1) : helper['priceWithDiscount'];
            helper['selectedDiscount'] = 'discount';            
          }

          if (name == 'priceWithDiscount' || calc == 'priceWithDiscount') {
            helper['discount'] = (helper['priceWithDiscount'] / helper['price'] - 1) * -100;
            helper['priceTaxWithDiscount'] = helper['tax'] != 0 && helper['tax'] != undefined ? helper['priceWithDiscount'] * (helper['tax'] / 100 + 1) : helper['priceWithDiscount'];
            helper['selectedDiscount'] = 'priceWithDiscount';
          }

          if (name == 'priceTaxWithDiscount' || calc == 'priceTaxWithDiscount') {
            helper['priceWithDiscount'] = helper['tax'] != 0 && helper['tax'] != undefined ? helper['priceTaxWithDiscount'] / (helper['tax'] / 100 + 1) : helper['priceTaxWithDiscount'];
            helper['discount'] = (helper['priceWithDiscount'] / helper['price'] - 1) * -100;
            helper['selectedDiscount'] = 'priceTaxWithDiscount';
          }

          if (name != 'total' && name != 'totalWithTax') {
            helper['total'] = helper['priceWithDiscount'] * helper['qty'];
            helper['totalWithTax'] = helper['priceTaxWithDiscount'] * helper['qty'];
          } else if (name == 'total') {
            helper['totalWithTax'] = helper['tax'] != 0 && helper['tax'] != undefined ? helper['total'] * (helper['tax'] / 100 + 1) : helper['total'];
            helper['priceWithDiscount'] = helper['total'] / helper['qty'];
            helper['priceTaxWithDiscount'] = helper['totalWithTax'] / helper['qty'];
            helper['discount'] = (helper['priceWithDiscount'] / helper['price'] - 1) * -100;
            helper['selectedDiscount'] = 'total';
          } else if (name== 'totalWithTax') {
            helper['total'] = helper['tax'] != 0 && helper['tax'] != undefined ? helper['totalWithTax'] / (helper['tax'] / 100 + 1) : helper['totalWithTax'];
            helper['priceWithDiscount'] = helper['total'] / helper['qty'];
            helper['priceTaxWithDiscount'] = helper['totalWithTax'] / helper['qty'];
            helper['discount'] = (helper['priceWithDiscount'] / helper['price'] - 1) * -100;
            helper['selectedDiscount'] = 'totalWithTax';
          }
         
          angularVariables.current_document_lines[angularVariables.selectedLine]['pos'] = helper['pos'];
          angularVariables.current_document_lines[angularVariables.selectedLine]['sku'] = helper['sku'];
          angularVariables.current_document_lines[angularVariables.selectedLine]['description'] = helper['description'];
          angularVariables.current_document_lines[angularVariables.selectedLine]['unit'] = helper['unit'];
          angularVariables.current_document_lines[angularVariables.selectedLine]['qty'] = helper['qty'] != 0 ? helper['qty'] : 0;
          angularVariables.current_document_lines[angularVariables.selectedLine]['tax'] = helper['tax'] != 0 ? helper['tax'] : 0;
          angularVariables.current_document_lines[angularVariables.selectedLine]['price'] = helper['price'] != 0 ? helper['price'] : 0;
          angularVariables.current_document_lines[angularVariables.selectedLine]['priceTax'] = helper['priceTax'] != 0 ? helper['priceTax'] : 0;
          angularVariables.current_document_lines[angularVariables.selectedLine]['discount'] = helper['discount'] != 0 ? helper['discount'] : 0;
          angularVariables.current_document_lines[angularVariables.selectedLine]['priceWithDiscount'] = helper['priceWithDiscount'] != 0 ? helper['priceWithDiscount'] : 0;
          angularVariables.current_document_lines[angularVariables.selectedLine]['priceTaxWithDiscount'] = helper['priceTaxWithDiscount'] != 0 ? helper['priceTaxWithDiscount'] : 0;
          angularVariables.current_document_lines[angularVariables.selectedLine]['total'] = helper['total'] != 0 ? helper['total'] : 0;
          angularVariables.current_document_lines[angularVariables.selectedLine]['totalWithTax'] = helper['totalWithTax'] != 0 ? helper['totalWithTax'] : 0;
          angularVariables.current_document_lines[angularVariables.selectedLine]['selectedPrice'] = helper['selectedPrice'];
          angularVariables.current_document_lines[angularVariables.selectedLine]['selectedDiscount'] = helper['selectedDiscount'];

          angularVariables.document_format_lines(angularVariables.current_document_lines, angularVariables.selectedLine, angularVariables.selectedCell);
          angularVariables.document_summary(angularVariables.current_document_lines);
          
        }      

      });
    }); //ready jQuery    
  } //ngOnInit()

}