<ul class="topNavigation" [ngClass]="{'collapse' : navigationCollapse == true}">
  <li [ngClass]="{'collapse' : navigationCollapse == true}">
    <fa-icon *ngIf="isMobile" [icon]="['fad', 'bars']" style="mobile-menu"></fa-icon>
    	<ul>
			  <ng-container *ngTemplateOutlet="topCustomMenu; context:{ $implicit: topCustomItems }"></ng-container>
      </ul>   

      <ul *ngIf="!isMobile" class="systenNavigation" style="float: right;">
        <ng-container *ngTemplateOutlet="topSystemMenu; context:{ $implicit: topSystemItems }"></ng-container>
      </ul>

      <ul class="top_username" style="float: right;">
        <li class="nohover"><i style="color: var(--dark-lvl2)">{{user}} | </i>{{username}}</li>
      </ul>    

      <ul class="global_new_activities" style="float: right;padding-right: 24px">
        <li (click)="unread=!unread; globalNewActivities()">
          <fa-icon [icon]="['fad', 'bell']"></fa-icon>
          <div *ngIf="unreadItems!=0 && unreadItems!=-1" class="bell_bubble">{{unreadItems}}</div>
        </li>
        

        <li *ngIf="!listening" (click)="startVoiceService()" class="speech">
          <fa-icon [icon]="['fad', 'microphone-slash']"></fa-icon>        
        </li>

        <li *ngIf="listening" (click)="stopVoiceService()" class="speech">
          <fa-icon [icon]="['fad', 'microphone']" class="blink" [ngClass]="{'cta' : recognizing}"></fa-icon>
          <div *ngIf="externalTranscript!=''" class="speechToText" [ngClass]="{'ctaDisabled' : !recognizing}">{{externalTranscript}}</div>
        </li>
      </ul>
       

      <ul class="global_search" style="float: right;padding-right: 24px">
        
        <!--
        <li (click)="sendETalk()">
          <fa-icon [icon]="['fad', 'inbox-out']"></fa-icon>
        </li>

        <li (click)="validateETalk()">
          <fa-icon [icon]="['fad', 'envelope-circle-check']"></fa-icon>
        </li>

        <li (click)="getProfile()">
          <fa-icon [icon]="['fad', 'inbox-in']"></fa-icon>
        </li>

        <li (click)="loginPopup()">
          <fa-icon [icon]="['fad', 'sun']"></fa-icon>
        </li>
        -->

        <li (click)="globalSearch()">
          <fa-icon [icon]="['fad', 'search-plus']"></fa-icon>        
        </li>

        <!--
        <li (click)="switchTheme()">
          <fa-icon *ngIf="theme=='dark'" [icon]="['fad', 'moon']"></fa-icon>
          <fa-icon *ngIf="theme=='light'" [icon]="['fad', 'sun']"></fa-icon>
        </li>
        -->
      </ul>  

    <!--
    <ul *ngIf="outlook_enable==true" class="eTalk_service" style="float: right;padding-right: 24px">    
      
      <li *ngIf="outlook_isLoggedIn" (click)="sendETalk()">
        <fa-icon [icon]="['fad', 'inbox-out']"></fa-icon>
        <div *ngIf="UnsendETalks!=0 && UnsendETalks!=-1" class="bell_bubble">{{UnsendETalks}}</div>
        <div class="tooltip">{{'Send unsend emails/eTalks'|translate}}</div>
      </li>

      <li *ngIf="outlook_isLoggedIn" (click)="getETalk()">
        <fa-icon [icon]="['fad', 'inbox-in']"></fa-icon>
        <div *ngIf="UnAssignedETalks!=0 && UnAssignedETalks!=-1" class="bell_bubble">{{UnAssignedETalks}}</div>
        <div class="tooltip">{{'Retrieve pending emails'|translate}}</div>
      </li>
      
      <li (click)="openETalk()" class="icon_etalk" style="padding-right:18px;">
        <fa-icon [icon]="['fad', 'envelope-circle-check']"></fa-icon>
        <div *ngIf="UnSolvedETalks!=0 && UnSolvedETalks!=-1" class="bell_bubble" style="right:6px;">{{UnSolvedETalks}}</div>
        <div class="tooltip">{{'Mails/eTalks'|translate}}</div>
      </li>

      <li class="spacer"></li>

      <li *ngIf="!outlook_isLoggedIn" (click)="loginPopup()">
        <fa-icon [icon]="['fad', 'arrow-right-to-arc']"></fa-icon>
        <div class="tooltip">{{'Log in'|translate}}</div>
      </li>

      <li *ngIf="outlook_isLoggedIn" (click)="logout_azure()">
        <fa-icon [icon]="['fad', 'arrow-up-left-from-circle']"></fa-icon>
        <div class="tooltip">{{'Log out'|translate}}</div>
      </li>
    </ul>
    -->
 
    <ul>
      <li class="nohover header_helper"></li>
    </ul>
    
    <ul style="float: left !important"> 
      <fa-icon class="burgerbar spaces" style="margin-top:12px;margin-left:32px;" (click)="spaces = !spaces; toggleMobileMenu();" [icon]="['fad', 'bars']"></fa-icon>
      <li class="nohover header_helper_left" (click)="spaces = false" [ngClass]="{'open' : spaces}"></li>
    </ul>

    <div id="logo" class="mobile_header_logo"><a href="/"><img src="/assets/logo/ibilizz.png" /></a></div>

	</li>
  
  <!--<li class="nohover header_helper" style="float:left;width: 100px;">test</li>-->
</ul>

<ul *ngIf="isMobile" class="systemNavigation">
  <ng-container *ngTemplateOutlet="topSystemMenu; context:{ $implicit: topSystemItems }"></ng-container>
</ul>

<div *ngIf="unread" class="unread">
  <div class="close_last" style="float:left;width:calc(100% - 24px);margin-left:12px;padding:12px 0px;border-bottom:1px solid var(--dark-lvl0);">
      <fa-icon (click)="unread=false;" style="cursor:pointer;color: var(--light-lvl0); float:left;" [icon]="['fad', 'times']"></fa-icon>
      <span>{{'Last not seen activities (7 days)'|translate}}</span>
      <fa-icon *ngIf="!mute" (click)="mute=true" style="cursor:pointer;color: var(--light-lvl0);float: right" [icon]="['fad', 'volume']"></fa-icon>
      <fa-icon *ngIf="mute" (click)="mute=false" style="cursor:pointer;color: var(--light-lvl0);float: right" [icon]="['fad', 'volume-slash']"></fa-icon>
  </div>
  <app-widget-last id="top"></app-widget-last>
</div>

<ng-template #topCustomMenu let-topCustomItems>
  <li *ngFor="let item of topCustomItems">
  	<fa-icon *ngIf="item.i" [icon]="['fad', item.i]"></fa-icon><span *ngIf="item.title">{{item.title}}</span>
    <ul *ngIf="item.subItems">
      <ng-container *ngTemplateOutlet="topCustomMenu; context:{ $implicit: item.subItems }"></ng-container>
    </ul>
  </li>
</ng-template>

<ng-template #topSystemMenu let-topSystemItems>
  <li *ngFor="let item of topSystemItems">
    <fa-icon *ngIf="item.i && item.i != 'user'" [icon]="['fad', item.i]"></fa-icon>
    <fa-icon *ngIf="item.i && item.i == 'user' && admin_rights" [icon]="['fad', 'user-crown']"></fa-icon>
    <fa-icon *ngIf="item.i && item.i == 'user' && !admin_rights" [icon]="['fad', 'user']"></fa-icon>

    <span *ngIf="item.title" (click)="this[item.action]()">{{item.title}}</span>
    <ul *ngIf="item.subItems">
      <ng-container *ngTemplateOutlet="topSystemMenu; context:{ $implicit: item.subItems }"></ng-container>
    </ul>
  </li>
</ng-template>
 
<div class="fast_activity_add_overlay">
</div>
<div class="fast_activity_add">
  <div class="add_activity_categories">
    <ng-container *ngFor="let item of activity_categories">
      <div *ngIf="item.create=='1' && item.subactivity!=1" class="add_item">
          <span (click)="add_activity(item.entity)" style="background:{{item.hex}}">{{item.description}}</span>
      </div>
    </ng-container>
  </div>
  <div class="add_activity_functions">
    <div class="item">
      <fa-icon class="icon_medium" [icon]="['fad', 'user-clock']" (click)="addTimeTracking()"></fa-icon>
    </div>
  </div>
  <div class="add_button">    
    <fa-icon *ngIf="quickAdd" [icon]="['fad', 'plus-circle']"></fa-icon>
    <fa-icon *ngIf="!quickAdd" [icon]="['fad', 'minus-circle']"></fa-icon> 
  </div>
</div>

<!-- mobile seach glass 
<div class="mobile_search" >
  <fa-icon [icon]="['fad', 'search-plus']"></fa-icon>
</div>

mobile bell 
<div class="global_new_activities mobile bell" style="float: right;padding-right: 24px" (click)="unread=!unread">
    <div (click)="globalNewActivities()">
      <fa-icon [icon]="['fad', 'bell']"></fa-icon>
      <div *ngIf="unreadItems!=0 && unreadItems!=-1" class="bell_bubble">{{unreadItems}}</div>
    </div>
</div>

-->
  
<!-- dock module -->
 <!--
<div class="dock_wrapper">
  <ng-container *ngFor="let item of dock_items">
    <div class="dock_item_wrapper">
      <div class="dock_type"><div><fa-icon [icon]="['fad', 'list']"></fa-icon></div></div>
      <div class="dock_item" style="border-color:{{item.message_type_hex}};" (click)="edit_activity(item.entity)">
        <div class="title">
          <span>{{item.title}}</span>
          <span>{{item.customer}}</span>
        </div>
        <div class="remove">
          <fa-icon [icon]="['fad', 'times']" (click)="fromDock(item.entity);$event.stopPropagation();"></fa-icon>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngFor="let item of dock_items_customers">
    <div class="dock_item_wrapper">
      <div class="dock_type"><div><fa-icon [icon]="['fad', 'users']"></fa-icon></div></div>
      <div class="dock_item" style="border-color:{{item.status_color}};" (click)="edit_customer(item.entity)"> 
        <div class="title">
          <span>{{item.entity}} | {{item.vat}}</span>
          <span>{{item.company}}</span>
        </div>
        <div class="remove">
          <fa-icon [icon]="['fad', 'times']" (click)="fromDockCustomers(item.entity);$event.stopPropagation();"></fa-icon>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngFor="let item of dock_items_erpDocuments">
    <div class="dock_item_wrapper">
      <div class="dock_type"><div><fa-icon [icon]="['fad', 'file-invoice']"></fa-icon></div></div>
      <div class="dock_item" style="border-color:{{item.status_color}};" (click)="edit_erp_document(item.entity)"> 
        <div class="title">
          <span>{{item.entity}} | {{item.document_number_text}}</span>
          <span>{{item.customer}}</span>
        </div>
        <div class="remove">
          <fa-icon [icon]="['fad', 'times']" (click)="fromDockErpDocument(item.entity);$event.stopPropagation();"></fa-icon>
        </div>
      </div>
    </div>
  </ng-container>
</div>
-->