import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErpFormEditComponent } from '../../erp/erp-form-edit/erp-form-edit.component';
import { ErpFormSubmitComponent } from '../../erp/erp-form-submit/erp-form-submit.component';

@Component({
  selector: 'app-erp-forms',
  templateUrl: './erp-forms.component.html',
  styleUrls: ['./erp-forms.component.css']
})
export class ErpFormsComponent {

	constructor(
		public dialog: MatDialog		
	) {}

  edit() {		
		var editDialog = this.dialog.open(ErpFormEditComponent, {});
	    //editDialog.componentInstance.entity = null;
	    editDialog.afterClosed().subscribe(result => {	      
	    }); 		
	}

  submit() {		
		var editDialog = this.dialog.open(ErpFormSubmitComponent, {});
	    //editDialog.componentInstance.entity = null;
	    editDialog.afterClosed().subscribe(result => {	      
	    }); 		
	}

}
