<form [formGroup]="formData" (ngSubmit)="save()" novalidate>	
	<div class="widget_settings">
		<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
			<div class="left">
				<h1>{{'Widget settings'|translate}}</h1>
			</div>
			<div class="right">
				<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
			</div>
		</div>

		<div class="inner_content">
			<div class="inner_scroll">
				<div class="line">
					<div class="field">
						<input type="text" formControlName="title" name="title" [(ngModel)]="settings.title" />
						<div class="label" style="padding-top:6px;">{{'Widget title'|translate}}</div>
					</div>
				</div>

				<div class="multi_line">
					<div class="field" style="width: 100%">
						<label>{{'Available activities'|translate}}</label>

						<div class="multiselect_checkbox categories">
							<ng-container *ngFor="let category of categories">
							<div class="checkbox_wrapper">
								<label class="switch">
									<input type="checkbox" class="dummy" [attr.rel]="category.entity" [checked]="category.selected">
									<span class="slider round"></span>
								</label>
								<div class="label"><span class="category_bubble" style="background:{{category.hex}}"></span>{{category.description}}</div>
							</div>
							</ng-container>		

						</div>
					</div>
				</div>		
				
				<div class="multi_line" style="margin:12px 0px;">
					<div class="field" style="width: 100%">
						<label>{{'Chart type'|translate}}</label>

						<div class="multiselect_checkbox categories">							
							<fa-icon class="chart_type" [icon]="['fad', 'chart-pie']" [ngClass]="{'selected' : settings.chartType == 'pie_area'}" (click)="settings.chartType='pie_area'"></fa-icon>

							<fa-icon class="chart_type" [icon]="['fad', 'circle-three-quarters']" [ngClass]="{'selected' : settings.chartType == 'pie_simple'}" (click)="settings.chartType='pie_simple'"></fa-icon>

							<fa-icon class="chart_type" [icon]="['fad', 'chart-area']" [ngClass]="{'selected' : settings.chartType == 'area'}" (click)="settings.chartType='area'"></fa-icon>

							<!--

							<fa-icon class="chart_type" [icon]="['fad', 'chart-simple']" [ngClass]="{'selected' : settings.chartType == 'bar'}" (click)="settings.chartType='bar'"></fa-icon>

							<fa-icon class="chart_type" [icon]="['fad', 'chart-line']" [ngClass]="{'selected' : settings.chartType == 'line'}" (click)="settings.chartType='line'"></fa-icon>

							

							-->
						</div>
					</div>
				</div>		

				<div class="line" style="margin-top: 12px;">
					<div class="field">
						<label>{{'Reference date'|translate}}</label>
						<select formControlName="reference" name="reference" [(ngModel)]="settings.reference" style="float:left; margin-top:-6px;min-width:50px;">
							<option value="created" class="selected" selected="selected">{{'Activity create'|translate}}</option>
					    	<option value="start">{{'Activity start'|translate}}</option>
							<option value="end">{{'Activity end'|translate}}</option>					    	
					    </select>
					    <select style="margin-left:8px;float:left;margin-top:-6px;min-width:50px;" formControlName="grouppedby" name="grouppedby" [(ngModel)]="settings.grouppedby">
					    	<option value="this_year">{{'This Year'|translate}}</option>
							<option value="previous_year">{{'Previous Year'|translate}}</option>
							<option value="this_month">{{'This Month'|translate}}</option>
							<option value="previous_month">{{'Previous Month'|translate}}</option>
					    	<option value="this_week">{{'This Week'|translate}}</option>
							<option value="previous_week">{{'Previous Week'|translate}}</option>
							<option value="today">{{'Today'|translate}}</option>
							<option value="yesterday">{{'Yesterday'|translate}}</option>
					    </select>					    
					</div>
				</div>

				<div class="multi_line" style="padding-top: 24px; margin-top:6px; padding-bottom: 0px; border-top: 1px solid var(--light-lvl1)">
					<label>{{'Include'|translate}}</label>
					
					<div class="field">
						<label class="switch">
							<input type="checkbox" formControlName="featured" name="featured" [(ngModel)]="settings.featured">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Featured only'|translate}}</div>	
					</div>	

					<div class="field">
						<label class="switch">
							<input type="checkbox" formControlName="assigned" name="assigned" [(ngModel)]="settings.assigned">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Assigned only'|translate}}</div>				
					</div>

					<div class="field">
						<label class="switch">
							<input type="checkbox" formControlName="author" name="author" [(ngModel)]="settings.author">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'As author'|translate}}</div>
					</div>
				</div>

				<div class="multi_line" style="padding-top: 6px;">
					<label>&nbsp;</label>
					<div class="field">
						<label class="switch">
							<input type="checkbox" formControlName="hidden" name="hidden" [(ngModel)]="settings.hidden">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Hidden'|translate}}</div>
					</div>
					<div class="field">
						<label class="switch">
							<input type="checkbox" formControlName="unapproved" name="unapproved" [(ngModel)]="settings.unapproved">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Un-Approved by all'|translate}}</div>
					</div>
				</div>


				<div class="multi_line" style="padding-top: 24px; margin-top:0px; padding-bottom: 0px; border-top: 1px solid var(--light-lvl1)">
					<label>{{'Not include'|translate}}</label>

					<div class="field">
						<label class="switch">
							<input type="checkbox" formControlName="approvedbyme" name="approvedbyme" [(ngModel)]="settings.approvedbyme">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Approved by me'|translate}}</div>
					</div>

					<div class="field">
						<label class="switch">
							<input type="checkbox" formControlName="approved" name="approved" [(ngModel)]="settings.approved">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Approved by all'|translate}}</div>
					</div>

					<div class="field">
						<label class="switch">
							<input type="checkbox" formControlName="removeold" name="removeold" [(ngModel)]="settings.removeold">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Activity finished'|translate}}</div>
					</div>
				</div>


			</div>
			<div class="multi_line" style="margin-top:6px;padding: 24px;border-top: 1px solid var(--light-lvl1)">
				<button class="confirm" type="submit" mat-button>{{'Confirm'|translate}}</button>
				<button class="cancel" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
			</div>
		</div>
	</div>
</form>