<div class="inner_wrapper"> 
    <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="left">
            <h1>{{'Quick File Preview'|translate}}</h1>
        </div>
        <div class="right">
            <fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
        </div>
    </div>

    <div class="body">        
        <ng-container *ngIf="previewAvailable(files[0])">
            <div *ngIf="documentType==null" class="imgContainer" [ngStyle]="{'background-image': 'url(' + displayImage(files[0]) + ')'}"></div>
            <embed *ngIf="documentType=='pdf'" [src]="sanitizedPdfUrl" type="application/pdf" width="100%" height="99%">        
        </ng-container>
        <div class="previewNotAvailable" *ngIf="!previewAvailable(files[0])">{{'Preview is not awailable for this file'||translate}}</div>
    </div>   
</div>