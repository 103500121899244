import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { ActivityComponent } from '../activities/activity/activity.component';
import { MatDialog } from '@angular/material/dialog';

declare var webkitSpeechRecognition: any;

@Injectable({
  providedIn: 'root'
})
export class VoiceRecognitionService {
    
  //recognition = new webkitSpeechRecognition();  
  isStoppedSpeechRecog = false;
  public text = '';
  tempWords;

  public listening = false;
  public recognizing = false;
  public activityCategories = [];
  public timer;
  public externalTranscript = "";

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private data: DataService
  ) { }


  executeCommand(transcript) {
    /*
    this.externalTranscript = transcript;
    this.recognizing = true;
    
    var multi_transcript = transcript.split(' '); 

    if (transcript == 'mic off') {
      this.recognizing = false;
      this.listening = false;
      this.stop();
    }
    
    if (this.recognizing && multi_transcript[0] == 'ustvari' && multi_transcript[0] == 'kreiraj') {
      var callActivity = multi_transcript[1] == 'me' ? multi_transcript[2] : multi_transcript[1];
      if (callActivity != '' && callActivity != 'new') {
        var id = null;        
        for (var i=0; i<this.activityCategories.length; i++) {
          console.log(this.activityCategories[i].description.toLowerCase() + "==" + callActivity.toLowerCase());
          if (this.activityCategories[i].description.toLowerCase() == callActivity.toLowerCase()) {
            var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity'});
            editDialog.componentInstance.entity = null;
            editDialog.componentInstance['category_id'] = this.activityCategories[i].entity;                          
          }
        }
      }      
    }

    if (this.recognizing && multi_transcript[0] == 'odpri') {      
      var callActivity = multi_transcript[1] == 'mi' ? multi_transcript[2] : multi_transcript[1];
      if (callActivity != '') {
        var id = null;        
        for (var i=0; i<this.activityCategories.length; i++) {
          console.log(this.activityCategories[i].description.toLowerCase() + "==" + callActivity.toLowerCase());
          if (this.activityCategories[i].description.toLowerCase() == callActivity.toLowerCase()) {            
            this.router.navigate(['/activities/'+this.activityCategories[i].entity]);            
          }
        }
      }      
    }

    if (this.recognizing && (transcript == 'pripravi novo aktivnost' || transcript == 'ustvari novo aktivnost' || transcript == 'nova aktivnost')) {
      var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity'});
      editDialog.componentInstance.entity = null;
      editDialog.componentInstance['category_id'] = null;        
    }

    if (this.recognizing && (transcript == 'pokaži aktivnosti' || transcript == 'pokaži vse aktivnosti' || transcript == 'odpri aktivnosti')) {
      this.router.navigate(['/activities']);
    }

    if (this.recognizing && (transcript == 'dashboard' || transcript == 'nadzorna plošča' || transcript == 'nadzorna plosca' || transcript == 'domov')) {
      this.router.navigate(['/']);
    }
    */
  }

  init() {

    /* preload some data */
    /*
    var parameters = { categories: null, items : 10000, page : 0, order : 't1.description', orderby : 'asc', search : null };   
    this.data.getCategories(parameters).subscribe( (res: Response) => { 
      this.activityCategories = (<any>res).rows; 
    });
      
    this.recognition.interimResults = true;
    this.recognition.lang = 'sl-SI';

    this.recognition.addEventListener('result', (e) => {
      var transcript = Array.from(e.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript;      
      
      var executeInterval = setInterval(() => {
            clearInterval(executeInterval);
            console.log(transcript);
            this.executeCommand(transcript);
      }, 1000);
            
    });
    */
  }

  start() {
    /*
    this.isStoppedSpeechRecog = false;
    this.recognition.start();
    console.log("Speech recognition started")
    this.recognition.addEventListener('end', (condition) => {
      if (this.isStoppedSpeechRecog) {
        this.recognition.stop();
        console.log("End speech recognition")
      } else {
        this.wordConcat()
        this.recognition.start();
      }
    });
    this.recognizing = false;
    */
  }

  stop() {
    /*
    this.isStoppedSpeechRecog = true;
    this.wordConcat()
    this.recognition.stop();
    console.log("End speech recognition")
    */
  }

  wordConcat() {
    /*
    this.text = this.text + ' ' + this.tempWords + '.';
    this.tempWords = '';
    */
  }

}