<div style="padding: 24px; text-align: center;">
    <div class="iconWrapper">
        <div class="infitiny-loader">      
            <svg class="infinity" viewBox="-2 -1 12 8">
              <defs><path id="infinite" d="M 3.77 2.006 C 3.726 1.624 3.1 1 2 1 a 2 2 0 0 0 0 4 c 1.1 0 1.799 -0.916 1.936 -1.262 c 0.187 -0.361 0.404 -0.909 1.147 -0.924 c 0.65 0.015 1.111 0.419 1.111 1.104 C 6.18 4.474 5.87 5.001 5.076 5.015 C 4.289 4.993 3.986 4.33 3.979 3.904"></path></defs>      
              <use href="#infinite" class="inside"></use>
            </svg>
        </div>
    </div>
    <p>{{'Preparing document'|translate}} {{currentDocumemntToPrint}}</p>    
</div>