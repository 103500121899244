/* Angular */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/* Data Services */
import { DataService } from '../services/data.service';

/* Other services */
import { GlobalVariables } from '../services/data.globals';

//declare let Email: any; // SMTP.js globalna funkcija

@Injectable({
  providedIn: 'root',
})

export class EmailService {
  
  constructor(
    private data: DataService
  ) { }

  private countEmailQueueByStatusSource = new BehaviorSubject<Array<any>>([]);
	public countEmailQueueByStatusVar = this.countEmailQueueByStatusSource.asObservable();

  private getEmailQueueByStatusSource = new BehaviorSubject<Array<any>>([]);
	public getEmailQueueByStatusVar = this.getEmailQueueByStatusSource.asObservable();

  update(status) {        
    this.data.countEmailQueueByStatus({'status':status}).subscribe(
      (res: Response) => {        
        //console.log(res['rows']);
        this.countEmailQueueByStatusSource.next(res['rows']);        
      }
    )
  }

  getEmailsByStatus(status) {        
    this.data.getEmailQueueByStatus({'status':status}).subscribe(
      (res: Response) => {        
        //console.log(res['rows']);
        this.getEmailQueueByStatusSource.next(res['rows']);        
      }
    )
  }


  /*
  private smtpConfig = {
    Username: 'petka@petka.eu', 
    Password: 'X5116hq0g#',     
    Host: 'smtp.office365.com', 
    Port: 587,                  
  };

  constructor() {}

  sendEmail(to: string, subject: string, html: string, attachment: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileContent = reader.result as string;

        
        Email.send({
          Host: this.smtpConfig.Host,
          Username: this.smtpConfig.Username,
          Password: this.smtpConfig.Password,
          To: to,
          From: this.smtpConfig.Username,
          Subject: subject,
          Body: html,
          Attachments: [
            {
              name: attachment.name,
              data: fileContent.split(',')[1],
            },
          ],
        }).then(
          (message: any) => resolve(message),
          (error: any) => reject(error)
        );
      };

      reader.onerror = () => reject('Napaka pri branju datoteke.');
      reader.readAsDataURL(attachment); 
    });
  }
    */
}



