import { Component } from '@angular/core';

@Component({
  selector: 'app-erp-form-submit',
  templateUrl: './erp-form-submit.component.html',
  styleUrls: ['./erp-form-submit.component.css']
})
export class ErpFormSubmitComponent {

  formContent = [
    {
      title: "custom title",
      subtitle: "custom subtitle",
      function: "text",
      defaultValues: null,
      defaultValuesText: null
    },
    {
      title: "custom title 2",
      subtitle: "custom subtitle 3",
      function: "textarea",
      defaultValues: null,
      defaultValuesText: null
    },
    {
      title: "custom title 3",
      subtitle: "custom subtitle 3",
      function: "checkbox",
      defaultValues: [
        {id:1234324, text:"value text1"},
        {id:1234324, text:"value text2"},
        {id:1234324, text:"value text3"},
        {id:1234324, text:"value text4"}
      ],
      defaultValuesText: null
    },
    {
      title: "custom title 4",
      subtitle: "custom subtitle 4",
      function: "options",
      defaultValues: [
        {id:1234324, text:"value text1"},
        {id:1234324, text:"value text2"},
        {id:1234324, text:"value text3"},
        {id:1234324, text:"value text4"}
      ],
      defaultValuesText: null
    },
    {
      title: "custom title 5",
      subtitle: "custom subtitle 5",
      function: "dropdown",
      defaultValues: [
        {id:1234324, text:"value text1"},
        {id:1234324, text:"value text2"},
        {id:1234324, text:"value text3"},
        {id:1234324, text:"value text4"}
      ],
      defaultValuesText: null
    }    
  ];

}
