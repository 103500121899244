<div class="inner_wrapper">
	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="left" style="padding-left: 18px;">
			<h1 *ngIf="user['entity']" style="float:left;">
				<span *ngIf="user['username']!=undefined" class="bullet_status" [ngStyle]="{'background': user['hex']}"></span>
				{{user['username']}}&nbsp;				
			</h1>
			<h1 *ngIf="!user['entity']">
				{{'Create new user'|translate}}
			</h1>
		</div>
		<div *ngIf="user['entity']" class="middle">
			<div class="tabs tabsHeader">
				<div class="tab" [ngClass]="{'active':selectedTab == 'summary'}" (click)="switchTab('summary')">
					<span>{{'Summary'|translate}}</span>
				</div>
				<!--
				<div class="tab" [ngClass]="{'active':selectedTab == 'documents'}" (click)="switchTab('documents')">
					<span>{{'Documents'|translate}}</span>
				</div>
				-->
				<div class="tab" [ngClass]="{'active':selectedTab == 'edit'}" (click)="switchTab('edit')">
					<span>{{'Edit'|translate}}</span>
				</div>				
			</div>
		</div>
		<div class="right">
			<div class="field">
				<div class="label label-checkbox" style="padding-right: 16px;">{{'Enabled'|translate}}</div>
				<label class="switch">
					<input type="checkbox" [(ngModel)]="user['enabled']">
					<span class="slider round" (click)="selectedTab = 'edit';"></span>
				</label>					
			</div>		
			<fa-icon class="icon_close" [icon]="['fad', 'times']"  (click)="close()"></fa-icon>
		</div>		
	</div>

	<div class="body">		
		<div id="summary" class="tab-child" [ngClass]="{'active':selectedTab == 'summary'}">
			{{'Summary'|translate}}
		</div>

		<div id="documents" class="tab-child" [ngClass]="{'active':selectedTab == 'documents'}">
			{{'Documents'|translate}}
			<!--
			<ul class="documents" rel="documents">
				<div class="ul_wrapper">
				<ng-container *ngFor="let document of document_items">
					<li (click)="download(document.filename,document.original_filename)">
						<span>									
							<fa-icon [icon]="['fas', showFileIcon(document.ext)]"></fa-icon><strong>{{document.original_filename}}</strong>
							<span>{{document.title}}</span>
							<div class="file_date">{{document.created}}</div>
						</span>
					</li>
				</ng-container>
				</div>
			</ul>
			-->
		</div>

		<div id="edit" class="tab-child" [ngClass]="{'active':selectedTab == 'edit'}">			
				<input type="hidden" name="entity" [(ngModel)]="user['entity']" />	
				<div class="user_left w50 lt60p">
					
					<div class="access_line access_line_header" style="padding-top:8px;">
						{{'Basic user data'|translate}}
					</div>
					<div class="inline w49">
						<mat-form-field appearance="outline" class="important">
						  <mat-label>{{'Email/Login'|translate}}</mat-label>
						  <input type="text" matInput name="email" [(ngModel)]="user['email']">
						  <!--<mat-hint>Error</mat-hint>-->
						</mat-form-field>
					</div>	
					<div class="inline w2">&nbsp;</div>
					<div class="inline w49">  
						<button *ngIf="user['entity'] && !changePassword" style="margin-top: 0px;" (click)="changePassword=true">{{'Change password'|translate}}</button>
						<ng-container *ngIf="!user['entity'] || changePassword">
							<mat-form-field appearance="outline">
							<mat-label>{{'Password'|translate}}</mat-label>
							<input type="text" matInput name="password" [(ngModel)]="user['password']">
							<!--<mat-hint>Error</mat-hint>-->
							</mat-form-field>
						</ng-container>
					</div>	
					<div class="inline w100 divider" style="margin-bottom: 24px;"></div>
					<div class="inline w100">  
						<mat-form-field appearance="outline" class="important">
						  <mat-label>{{'Name and surename'|translate}}</mat-label>
						  <input type="text" matInput name="username" [(ngModel)]="user['username']">
						  <!--<mat-hint>Error</mat-hint>-->
						</mat-form-field>
					</div>					
					<div class="inline w100 divider" style="margin-bottom: 24px;"></div>
					<div class="inline w100">  
						<mat-form-field appearance="outline">
						  <mat-label>{{'Street'|translate}}</mat-label>
						  <input type="text" matInput name="street" [(ngModel)]="user['street']">
						  <!--<mat-hint>Error</mat-hint>-->
						</mat-form-field>
					</div>					
					<div class="inline w38">  
						<mat-form-field appearance="outline">
						  <mat-label>{{'Zip'|translate}}</mat-label>
						  <input type="text" matInput name="post" [(ngModel)]="user['post']">
						  <!--<mat-hint>Error</mat-hint>-->
						</mat-form-field>
					</div>	
					<div class="inline w2">&nbsp;</div>
					<div class="inline w60">  
						<mat-form-field appearance="outline">
						  <mat-label>{{'City'|translate}}</mat-label>
						  <input type="text" matInput name="city" [(ngModel)]="user['city']">
						  <!--<mat-hint>Error</mat-hint>-->
						</mat-form-field>
					</div>
					<div class="inline w60">
						<mat-form-field appearance="outline">
						  <mat-label>{{'Country'|translate}}</mat-label>
						  <mat-select list="country" name="country" [(ngModel)]="user['country']">
							<mat-option value="Afghanistan">Afghanistan</mat-option>
							<mat-option value="Albania">Albania</mat-option>
							<mat-option value="Algeria">Algeria</mat-option>
							<mat-option value="Andorra">Andorra</mat-option>
							<mat-option value="Angola">Angola</mat-option>
							<mat-option value="Antigua and Barbuda">Antigua and Barbuda</mat-option>
							<mat-option value="Argentina">Argentina</mat-option>
							<mat-option value="Armenia">Armenia</mat-option>
							<mat-option value="Australia">Australia</mat-option>
							<mat-option value="Austria">Austria</mat-option>
							<mat-option value="Azerbaijan">Azerbaijan</mat-option>
							<mat-option value="Bahrain">Bahrain</mat-option>
							<mat-option value="Bangladesh">Bangladesh</mat-option>
							<mat-option value="Barbados">Barbados</mat-option>
							<mat-option value="Belarus">Belarus</mat-option>
							<mat-option value="Belgium">Belgium</mat-option>
							<mat-option value="Belize">Belize</mat-option>
							<mat-option value="Benin">Benin</mat-option>
							<mat-option value="Bhutan">Bhutan</mat-option>
							<mat-option value="Bolivia">Bolivia</mat-option>
							<mat-option value="Bosnia and Herzegovina">Bosnia and Herzegovina</mat-option>
							<mat-option value="Botswana">Botswana</mat-option>
							<mat-option value="Brazil">Brazil</mat-option>
							<mat-option value="Brunei">Brunei</mat-option>
							<mat-option value="Bulgaria">Bulgaria</mat-option>
							<mat-option value="Burkina Faso">Burkina Faso</mat-option>
							<mat-option value="Burundi">Burundi</mat-option>
							<mat-option value="Cabo Verde">Cabo Verde</mat-option>
							<mat-option value="Cambodia">Cambodia</mat-option>
							<mat-option value="Cameroon">Cameroon</mat-option>
							<mat-option value="Canada">Canada</mat-option>
							<mat-option value="Central African Republic">Central African Republic</mat-option>
							<mat-option value="Chad">Chad</mat-option>
							<mat-option value="Chile">Chile</mat-option>
							<mat-option value="China">China</mat-option>
							<mat-option value="Colombia">Colombia</mat-option>
							<mat-option value="Comoros">Comoros</mat-option>
							<mat-option value="Congo">Congo</mat-option>
							<mat-option value="Costa Rica">Costa Rica</mat-option>
							<mat-option value="Côte d’Ivoire">Côte d’Ivoire</mat-option>
							<mat-option value="Croatia">Croatia</mat-option>
							<mat-option value="Cuba">Cuba</mat-option>
							<mat-option value="Cyprus">Cyprus</mat-option>
							<mat-option value="Czech Republic">Czech Republic</mat-option>
							<mat-option value="Denmark">Denmark</mat-option>
							<mat-option value="Djibouti">Djibouti</mat-option>
							<mat-option value="Dominica">Dominica</mat-option>
							<mat-option value="Dominican Republic">Dominican Republic</mat-option>
							<mat-option value="East Timor (Timor-Leste)">East Timor (Timor-Leste)</mat-option>
							<mat-option value="Ecuador">Ecuador</mat-option>
							<mat-option value="Egypt">Egypt</mat-option>
							<mat-option value="El Salvador">El Salvador</mat-option>
							<mat-option value="Equatorial Guinea">Equatorial Guinea</mat-option>
							<mat-option value="Eritrea">Eritrea</mat-option>
							<mat-option value="Estonia">Estonia</mat-option>
							<mat-option value="Eswatini">Eswatini</mat-option>
							<mat-option value="Ethiopia">Ethiopia</mat-option>
							<mat-option value="Fiji">Fiji</mat-option>
							<mat-option value="Finland">Finland</mat-option>
							<mat-option value="France">France</mat-option>
							<mat-option value="Gabon">Gabon</mat-option>
							<mat-option value="The Gambia">The Gambia</mat-option>
							<mat-option value="Georgia">Georgia</mat-option>
							<mat-option value="Germany">Germany</mat-option>
							<mat-option value="Ghana">Ghana</mat-option>
							<mat-option value="Greece">Greece</mat-option>
							<mat-option value="Grenada">Grenada</mat-option>
							<mat-option value="Guatemala">Guatemala</mat-option>
							<mat-option value="Guinea">Guinea</mat-option>
							<mat-option value="Guinea-Bissau">Guinea-Bissau</mat-option>
							<mat-option value="Guyana">Guyana</mat-option>
							<mat-option value="Haiti">Haiti</mat-option>
							<mat-option value="Honduras">Honduras</mat-option>
							<mat-option value="Hungary">Hungary</mat-option>
							<mat-option value="Iceland">Iceland</mat-option>
							<mat-option value="India">India</mat-option>
							<mat-option value="Indonesia">Indonesia</mat-option>
							<mat-option value="Iran">Iran</mat-option>
							<mat-option value="Iraq">Iraq</mat-option>
							<mat-option value="Ireland">Ireland</mat-option>
							<mat-option value="Israel">Israel</mat-option>
							<mat-option value="Italy">Italy</mat-option>
							<mat-option value="Jamaica">Jamaica</mat-option>
							<mat-option value="Japan">Japan</mat-option>
							<mat-option value="Jordan">Jordan</mat-option>
							<mat-option value="Kazakhstan">Kazakhstan</mat-option>
							<mat-option value="Kenya">Kenya</mat-option>
							<mat-option value="Kiribati">Kiribati</mat-option>
							<mat-option value="Korea, North">Korea, North</mat-option>
							<mat-option value="Korea, South">Korea, South</mat-option>
							<mat-option value="Kosovo">Kosovo</mat-option>
							<mat-option value="Kuwait">Kuwait</mat-option>
							<mat-option value="Kyrgyzstan">Kyrgyzstan</mat-option>
							<mat-option value="Laos">Laos</mat-option>
							<mat-option value="Latvia">Latvia</mat-option>
							<mat-option value="Lebanon">Lebanon</mat-option>
							<mat-option value="Lesotho">Lesotho</mat-option>
							<mat-option value="Liberia">Liberia</mat-option>
							<mat-option value="Libya">Libya</mat-option>
							<mat-option value="Liechtenstein">Liechtenstein</mat-option>
							<mat-option value="Lithuania">Lithuania</mat-option>
							<mat-option value="Luxembourg">Luxembourg</mat-option>
							<mat-option value="Madagascar">Madagascar</mat-option>
							<mat-option value="Malawi">Malawi</mat-option>
							<mat-option value="Malaysia">Malaysia</mat-option>
							<mat-option value="Maldives">Maldives</mat-option>
							<mat-option value="Mali">Mali</mat-option>
							<mat-option value="Malta">Malta</mat-option>
							<mat-option value="Marshall Islands">Marshall Islands</mat-option>
							<mat-option value="Mauritania">Mauritania</mat-option>
							<mat-option value="Mauritius">Mauritius</mat-option>
							<mat-option value="Mexico">Mexico</mat-option>
							<mat-option value="Micronesia">Micronesia</mat-option>
							<mat-option value="Moldova">Moldova</mat-option>
							<mat-option value="Monaco">Monaco</mat-option>
							<mat-option value="Mongolia">Mongolia</mat-option>
							<mat-option value="Montenegro">Montenegro</mat-option>
							<mat-option value="Morocco">Morocco</mat-option>
							<mat-option value="Mozambique">Mozambique</mat-option>
							<mat-option value="Myanmar (Burma)">Myanmar (Burma)</mat-option>
							<mat-option value="Namibia">Namibia</mat-option>
							<mat-option value="Nauru">Nauru</mat-option>
							<mat-option value="Nepal">Nepal</mat-option>
							<mat-option value="Netherlands">Netherlands</mat-option>
							<mat-option value="New Zealand">New Zealand</mat-option>
							<mat-option value="Nicaragua">Nicaragua</mat-option>
							<mat-option value="Niger">Niger</mat-option>
							<mat-option value="Nigeria">Nigeria</mat-option>
							<mat-option value="North Macedonia">North Macedonia</mat-option>
							<mat-option value="Norway">Norway</mat-option>
							<mat-option value="Oman">Oman</mat-option>
							<mat-option value="Pakistan">Pakistan</mat-option>
							<mat-option value="Palau">Palau</mat-option>
							<mat-option value="Panama">Panama</mat-option>
							<mat-option value="Papua New Guinea">Papua New Guinea</mat-option>
							<mat-option value="Paraguay">Paraguay</mat-option>
							<mat-option value="Peru">Peru</mat-option>
							<mat-option value="Philippines">Philippines</mat-option>
							<mat-option value="Poland">Poland</mat-option>
							<mat-option value="Portugal">Portugal</mat-option>
							<mat-option value="Qatar">Qatar</mat-option>
							<mat-option value="Romania">Romania</mat-option>
							<mat-option value="Russia">Russia</mat-option>
							<mat-option value="Rwanda">Rwanda</mat-option>
							<mat-option value="Saint Kitts and Nevis">Saint Kitts and Nevis</mat-option>
							<mat-option value="Saint Lucia">Saint Lucia</mat-option>
							<mat-option value="Samoa">Samoa</mat-option>
							<mat-option value="San Marino">San Marino</mat-option>
							<mat-option value="Sao Tome and Principe">Sao Tome and Principe</mat-option>
							<mat-option value="Saudi Arabia">Saudi Arabia</mat-option>
							<mat-option value="Senegal">Senegal</mat-option>
							<mat-option value="Serbia">Serbia</mat-option>
							<mat-option value="Seychelles">Seychelles</mat-option>
							<mat-option value="Sierra Leone">Sierra Leone</mat-option>
							<mat-option value="Singapore">Singapore</mat-option>
							<mat-option value="Slovakia">Slovakia</mat-option>
							<mat-option value="Slovenia">Slovenia</mat-option>
							<mat-option value="Solomon Islands">Solomon Islands</mat-option>
							<mat-option value="Somalia">Somalia</mat-option>
							<mat-option value="South Africa">South Africa</mat-option>
							<mat-option value="Spain">Spain</mat-option>
							<mat-option value="Sri Lanka">Sri Lanka</mat-option>
							<mat-option value="Sudan">Sudan</mat-option>
							<mat-option value="Sudan, South">Sudan, South</mat-option>
							<mat-option value="Suriname">Suriname</mat-option>
							<mat-option value="Sweden">Sweden</mat-option>
							<mat-option value="Switzerland">Switzerland</mat-option>
							<mat-option value="Syria">Syria</mat-option>
							<mat-option value="Taiwan">Taiwan</mat-option>
							<mat-option value="Tajikistan">Tajikistan</mat-option>
							<mat-option value="Tanzania">Tanzania</mat-option>
							<mat-option value="Thailand">Thailand</mat-option>
							<mat-option value="Togo">Togo</mat-option>
							<mat-option value="Tonga">Tonga</mat-option>
							<mat-option value="Trinidad and Tobago">Trinidad and Tobago</mat-option>
							<mat-option value="Tunisia">Tunisia</mat-option>
							<mat-option value="Turkey">Turkey</mat-option>
							<mat-option value="Turkmenistan">Turkmenistan</mat-option>
							<mat-option value="Tuvalu">Tuvalu</mat-option>
							<mat-option value="Uganda">Uganda</mat-option>
							<mat-option value="Ukraine">Ukraine</mat-option>
							<mat-option value="United Arab Emirates">United Arab Emirates</mat-option>
							<mat-option value="United Kingdom">United Kingdom</mat-option>
							<mat-option value="United States">United States</mat-option>
							<mat-option value="Uruguay">Uruguay</mat-option>
							<mat-option value="Uzbekistan">Uzbekistan</mat-option>
							<mat-option value="Vanuatu">Vanuatu</mat-option>
							<mat-option value="Vatican City">Vatican City</mat-option>
							<mat-option value="Venezuela">Venezuela</mat-option>
							<mat-option value="Vietnam">Vietnam</mat-option>
							<mat-option value="Yemen">Yemen</mat-option>
							<mat-option value="Zambia">Zambia</mat-option>
							<mat-option value="Zimbabwe">Zimbabwe</mat-option>
						  </mat-select>
						<!--<mat-hint">Error</mat-hint>-->
						</mat-form-field>
					</div>
					
					<div class="inline w49">  
						<mat-form-field appearance="outline">
						  <mat-label>{{'Phone'|translate}}</mat-label>
						  <input type="text" matInput name="phone" [(ngModel)]="user['phone']">
						  <!--<mat-hint>Error</mat-hint>-->
						</mat-form-field>
					</div>					
					<div class="inline w2">&nbsp;</div>
					<div class="inline w49">
						<mat-form-field appearance="outline">
						  <mat-label>{{'Mobile phone'|translate}}</mat-label>
						  <input type="text" matInput name="mobile" [(ngModel)]="user['mobile']">
						  <!--<mat-hint>Error</mat-hint>-->
						</mat-form-field>
					</div>			
					<div class="inline w100 divider" style="margin-bottom: 24px;"></div>
					<div class="inline w50">
						<ngx-colors ngx-colors-trigger [(ngModel)]="user['hex']"></ngx-colors>
						<div class="colorPickerText">{{'User color'|translate}}</div>
					</div>

					<div class="inline w50 wpadding" style="padding-top: 16px;">
						<label class="switch">
							<input type="checkbox" [checked]="admin_rights" (click)="changeAdminfRights($event);" />
							<span class="slider round"></span>								
						</label>							
						<div class="name">&nbsp;&nbsp;&nbsp;&nbsp;{{'Superuser rights'|translate}}</div>
					</div>

				</div>
	
				<div class="user_right w50 lt40p">
					<div class="access">
						<div class="access_line access_line_header" style="padding-top:8px;" (click)="userGroupsDropDown=!userGroupsDropDown" [ngClass]="{'open':userGroupsDropDown}">
							{{'Assign to user groups'|translate}}
							<fa-icon *ngIf="!userGroupsDropDown" class="icon_close" [icon]="['fad', 'chevron-down']"></fa-icon>
							<fa-icon *ngIf="userGroupsDropDown" class="icon_close" [icon]="['fad', 'chevron-up']"></fa-icon>
						</div>
						<div *ngIf="userGroupsDropDown" class="dropDown">
							<ng-container *ngFor="let member_group of member_groups">
								<div class="access_line">	
									<div class="name">{{member_group['member_group']}}</div>
									<div class="settings">
										<label class="switch">
											<input type="checkbox" [checked]="user_group?.includes(member_group['entity'])" (click)="changeUserGroup(member_group['entity'], $event)" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
							</ng-container>
						</div>

						<div *ngIf="!admin_rights" class="access_line access_line_header" (click)="accessDropDown=!accessDropDown" [ngClass]="{'open':accessDropDown}">
							{{'App Accesses'|translate}}							
							<fa-icon *ngIf="!accessDropDown" class="icon_close" [icon]="['fad', 'chevron-down']"></fa-icon>
							<fa-icon *ngIf="accessDropDown" class="icon_close" [icon]="['fad', 'chevron-up']"></fa-icon>							
						</div>

						<div *ngIf="admin_rights" class="access_line access_line_header">
							{{'App Accesses'|translate}}							
						</div>

						<div *ngIf="admin_rights" class="dropDown" style="display:block;">
							<div class="access_line" style="padding-top:0px; margin-top: -10px;">
								{{'As super user you have right to access all appliactions and you can not disable any of them.'|translate}}
							</div>
						</div>

						<div *ngIf="accessDropDown && !admin_rights" class="dropDown">
							<div class="access_line_head">
								<div class="name">{{'Apps'|translate}}</div>
								<div class="settings" style="text-align: right;">{{'Extended'|translate}}</div>
								<div class="use" style="text-align: right;">{{'Use'|translate}}</div>
							</div>

							<div class="access_line">
								<div class="name">{{'Activities'|translate}}</div>
								<div class="settings">
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.activities?.settings" (click)="changeAppRestriction($event, 'activities', 'settings');" />
										<span class="slider round"></span>								
									</label>							
								</div>
								<div class="use">
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.activities?.view" (click)="changeAppRestriction($event, 'activities', 'view');" />
										<span class="slider round"></span>								
									</label>							
								</div>						
							</div>
		
							<div class="access_line">
								<div class="name">{{'e-Archive'|translate}}</div>
								<div class="settings">									
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.archives?.settings" (click)="changeAppRestriction($event, 'archives', 'settings');" />
										<span class="slider round"></span>								
									</label>									
									&nbsp;
								</div>
								<div class="use">
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.archives?.view" (click)="changeAppRestriction($event, 'archives', 'view');" />
										<span class="slider round"></span>								
									</label>							
								</div>	
							</div>
		
							<div class="access_line">
								<div class="name">{{'Customers'|translate}}</div>
								<div class="settings">
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.customers?.settings" (click)="changeAppRestriction($event, 'customers', 'settings');" />
										<span class="slider round"></span>								
									</label>							
								</div>
								<div class="use">
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.customers?.view" (click)="changeAppRestriction($event, 'customers', 'view');" />
										<span class="slider round"></span>								
									</label>							
								</div>	
							</div>
		
							<div class="access_line">
								<div class="name">{{'Time tracking'|translate}}</div>
								<div class="settings">							
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.timetracking?.settings" (click)="changeAppRestriction($event, 'timetracking', 'settings');" />
										<span class="slider round"></span>								
									</label>							
								</div>
								<div class="use">
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.timetracking?.view" (click)="changeAppRestriction($event, 'timetracking', 'view');" />
										<span class="slider round"></span>								
									</label>							
								</div>	
							</div>

							<div class="access_line">
								<div class="name">{{'Knowledge center'|translate}}</div>
								<div class="settings">							
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.knowledge?.settings" (click)="changeAppRestriction($event, 'knowledge', 'settings');" />
										<span class="slider round"></span>								
									</label>							
								</div>
								<div class="use">
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.knowledge?.view" (click)="changeAppRestriction($event, 'knowledge', 'view');" />
										<span class="slider round"></span>								
									</label>							
								</div>	
							</div>

							<div class="access_line splitter"></div>

							<div class="access_line">
								<div class="name"><b>{{'Erp Apps'|translate}}</b></div>
								<div class="settings">															
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.erp?.settings" (click)="changeAppRestriction($event, 'erp', 'settings');" />
										<span class="slider round"></span>								
									</label>									
								</div>						
								<div class="use">							
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.erp?.view" (click)="changeAppRestriction($event, 'erp', 'view');" />
										<span class="slider round"></span>								
									</label>							
								</div>	
							</div>

								<div class="access_line">
									<div class="name">&nbsp;&nbsp;&nbsp;{{'Invoices'|translate}}</div>											
									<div class="settings">															
										&nbsp;
									</div>
									<div class="use">							
										<label class="switch">
											<input type="checkbox" [checked]="appRestriction?.erp_invoices?.view" (click)="changeAppRestriction($event, 'erp_invoices', 'view');" />
											<span class="slider round"></span>								
										</label>							
									</div>	
								</div>

								<div class="access_line">
									<div class="name">&nbsp;&nbsp;&nbsp;{{'Credit Notes'|translate}}</div>
									<div class="settings">
										&nbsp;
									</div>
									<div class="use">
										<label class="switch">
											<input type="checkbox" [checked]="appRestriction?.erp_credit_notes?.view" (click)="changeAppRestriction($event, 'erp_credit_notes', 'view');" />
											<span class="slider round"></span>
										</label>
									</div>	
								</div>

								<div class="access_line">
									<div class="name">&nbsp;&nbsp;&nbsp;{{'Quotes'|translate}}</div>
									<div class="settings">
										&nbsp;
									</div>
									<div class="use">
										<label class="switch">
											<input type="checkbox" [checked]="appRestriction?.erp_quotes?.view" (click)="changeAppRestriction($event, 'erp_quotes', 'view');" />
											<span class="slider round"></span>
										</label>
									</div>	
								</div>

							<div class="access_line splitter"></div>


							<div class="access_line">
								<div class="name">{{'Users'|translate}}</div>
								<div class="settings">
									<!--
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.users?.settings" (click)="changeAppRestriction($event, 'users', 'settings');" />
										<span class="slider round"></span>
									</label>-->
									&nbsp;
								</div>
								<div class="use">
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.users?.view" (click)="changeAppRestriction($event, 'users', 'view');" />
										<span class="slider round"></span>								
									</label>							
								</div>	
							</div>

							<div class="access_line splitter"></div>
		
							<div class="access_line">
								<div class="name"><b>{{'System Settings'|translate}}</b></div>
								<div class="settings">															
									&nbsp;
								</div>						
								<div class="use">							
									<label class="switch">
										<input type="checkbox" [checked]="appRestriction?.system_settings?.view" (click)="changeAppRestriction($event, 'system_settings', 'view');" />
										<span class="slider round"></span>								
									</label>							
								</div>	
							</div>

								<div class="access_line">
									<div class="name">&nbsp;&nbsp;&nbsp;{{'Data recovery'|translate}}</div>
									<div class="settings">							
										<label class="switch">
											<input type="checkbox" [checked]="appRestriction?.recovery?.settings" (click)="changeAppRestriction($event, 'recovery', 'settings');" />
											<span class="slider round"></span>								
										</label>							
									</div>
									<div class="use">
										<label class="switch">
											<input type="checkbox" [checked]="appRestriction?.recovery?.view" (click)="changeAppRestriction($event, 'recovery', 'view');" />
											<span class="slider round"></span>								
										</label>							
									</div>	
								</div>
							
						</div>
					</div>
					
				</div>			
			
		</div>		
	</div>
	
	<div *ngIf="selectedTab == 'edit'" class="modalActions">
		<button class="accept right" (click)="saveUser()">{{'Save'|translate}}</button>
		<button class="action left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
		<button class="warrning right" (click)="deleteUser()">{{'Delete'|translate}}</button>		
	</div>
</div>