<div class="innerPopup" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
	<div class="left"><h1>{{'Activity functions'|translate}}</h1></div>
	<div class="right" (click)="close()">			
		<fa-icon class="icon_close" [icon]="['fad', 'times']"></fa-icon>
	</div>
</div>

<div class="body">
    <div class="activity_function delete" (click)="deleteActivity(this.data.entity)">
	    <fa-icon [icon]="['fad', 'trash-alt']"></fa-icon>{{'Delete activity'|translate}}
    </div>

    <div class="activity_function unlink" (click)="unlinkActivity(this.data.entity)">
	    <fa-icon [icon]="['fad', 'unlink']"></fa-icon>{{'Unlink activity'|translate}}
    </div>
</div>

