import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../services/data.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-filter',
  templateUrl: './customer-filter.component.html',
  styleUrls: ['./customer-filter.component.css']
})
export class CustomerFilterComponent implements OnInit {

  constructor(
    private data: DataService,
    public translate: TranslateService,
		public dialogRef: MatDialogRef<CustomerFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public injected_data
  ) { }

  public item_types = [];
  
  togleItemRestriction(entity) {
		for(var i=0; i<this.item_types.length; i++) {			
			if (this.item_types[i].entity == entity) {
				if (this.item_types[i]['selected'] == true) {
					this.item_types[i]['selected'] = false;
				} else {
					this.item_types[i]['selected'] = true;
				}
			}
		}		
	}

  confirm() {
		var data = {};
	
		/* status restrictions */
		for(var i=0; i<this.item_types.length; i++) {			
			if (this.item_types[i]['selected'] == true) {
				if (data['customer_type']==undefined) data['customer_type'] = "";
				data['customer_type'] += data['customer_type'] == "" ? "'" + this.item_types[i].entity + "'" : ",'" + this.item_types[i].entity + "'";
			} 	
		}    
    console.log(data);
		this.dialogRef.close(data);   
	}

  ngOnInit(): void {   

    this.data.getCustomerTypes(null).subscribe(
      (res: Response) => {
        this.item_types = (<any>res).rows;
        console.log(this.item_types);
        
        if (this.injected_data['customer_type']!=undefined) {
		        var hide_ct = (this.injected_data['customer_type']).replaceAll("'", "").split(',');
		        for (var i=0; i<this.item_types.length; i++) {		        	
		        	for (var j=0; j<hide_ct.length; j++) {		        		
						    if (this.item_types[i].entity == hide_ct[j]) {
							    this.item_types[i]['selected'] = true;
						    }
			        }  
		        }
		        console.log(this.item_types);
	    	}
        

      }
    );

  }

}

