<div class="inner_wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
	<div class="header">
		<div class="left">
			<h1>{{'Filter'|translate}}</h1>
		</div>
		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
		</div>
	</div>

	<div class="body">			
		
		<div class="left_data">						
			<div class="inline w100">
				<input [(ngModel)]="user_id" type="hidden" />
                <mat-form-field appearance="outline" class="custom-border">
                    <mat-label>{{'Assigned by user'|translate}}</mat-label>
                    <input type="text" matInput (click)="selectUser()" [(ngModel)]="user" readonly>                    
                </mat-form-field>
				<div class="buttonContainer">
					<button type="button" class="inlineButton stickToLeft action" (click)="removeUser()">
						<fa-icon [icon]="['fas', 'times']" class="big" style="color: var(--dark-lvl1)"></fa-icon>
					</button>
				</div>				
            </div>
			
			<div class="inline w100">
				<input [(ngModel)]="customer_id" type="hidden" />
                <mat-form-field appearance="outline">
                    <mat-label>{{'Filter by customer'|translate}}</mat-label>
                    <input type="text" matInput [(ngModel)]="customer" (click)="selectCustomer()" readonly>                    
                </mat-form-field>
				<div class="buttonContainer">
					<button type="button" class="inlineButton stickToLeft action" (click)="removeCustomer()">
						<fa-icon [icon]="['fas', 'times']" class="big" style="color: var(--dark-lvl1)"></fa-icon>
					</button>
				</div>				
            </div>

			<hr style="margin-bottom: 24px;" />

			<div class="inline w100">
				<div class="fieldTitle">{{'Activity timing'|translate}}</div>
				<div class="fieldLeft">
					<input type="hidden" [(ngModel)]="start_from_date_raw" readonly />
					<mat-form-field appearance="outline">
						<mat-label>{{'Start from'|translate}}</mat-label>
						<input type="text" matInput (click)="assignDateTimeStart(null, start_from_date, start_to_date, null, start_from_date_raw, start_to_date_raw)" [(ngModel)]="start_from_date" readonly>                    
					</mat-form-field>
				</div>
				
				<div class="fieldRight">
					<input type="hidden" [(ngModel)]="start_to_date_raw" class="from_to" readonly />
					<mat-form-field appearance="outline">
						<mat-label>{{'Start to'|translate}}</mat-label>
						<input type="text" matInput (click)="assignDateTimeStart(null, start_from_date, start_to_date, null, start_from_date_raw, start_to_date_raw)" [(ngModel)]="start_to_date" readonly>
					</mat-form-field>
				</div>

				<div class="buttonContainer">
					<button type="button" class="inlineButton stickToLeft action" (click)="removeDateTimeStart()">
						<fa-icon [icon]="['fas', 'times']" class="big" style="color: var(--dark-lvl1)"></fa-icon>
					</button>
				</div>	
            </div>
	
			<div class="inline w100">				
				<div class="fieldLeft">
					<input type="hidden" [(ngModel)]="end_from_date_raw" readonly />
					<mat-form-field appearance="outline">
						<mat-label>{{'End from'|translate}}</mat-label>
						<input type="text" matInput (click)="assignDateTimeEnd(null, end_from_date, end_to_date, null, end_from_date_raw, end_to_date_raw)" [(ngModel)]="end_from_date" class="from_to" readonly />
					</mat-form-field>
				</div>
				
				<div class="fieldRight">
					<input type="hidden" [(ngModel)]="end_to_date_raw" readonly />
					<mat-form-field appearance="outline">
						<mat-label>{{'End to'|translate}}</mat-label>
						<input type="text" matInput (click)="assignDateTimeEnd(null, end_from_date, end_to_date, null, end_from_date_raw, end_to_date_raw)" [(ngModel)]="end_to_date" readonly />
					</mat-form-field>
				</div>

				<div class="buttonContainer">
					<button type="button" class="inlineButton stickToLeft action" (click)="removeDateTimeEnd()">
						<fa-icon [icon]="['fas', 'times']" class="big" style="color: var(--dark-lvl1)"></fa-icon>
					</button>
				</div>	
            </div>

			<div class="inline w100">				
				<div class="fieldLeft">
					<input type="hidden" [(ngModel)]="created_from_date_raw" class="from_to" readonly />
					<mat-form-field appearance="outline">
						<mat-label>{{'Created from'|translate}}</mat-label>
						<input type="text" matInput (click)="assignDateTimeCreated(null, created_from_date, created_to_date, null, created_from_date_raw, created_to_date_raw)" [(ngModel)]="created_from_date" readonly />
					</mat-form-field>
				</div>
				
				<div class="fieldRight">
					<input type="hidden" [(ngModel)]="created_to_date_raw" class="from_to" readonly />
					<mat-form-field appearance="outline">
						<mat-label>{{'Created to'|translate}}</mat-label>
						<input type="text" matInput (click)="assignDateTimeCreated(null, created_from_date, created_to_date, null, created_from_date_raw, created_to_date_raw)" [(ngModel)]="created_to_date" readonly />
					</mat-form-field>
				</div>

				<div class="buttonContainer">
					<button type="button" class="inlineButton stickToLeft action" (click)="removeDateTimeCreated()">
						<fa-icon [icon]="['fas', 'times']" class="big" style="color: var(--dark-lvl1)"></fa-icon>
					</button>
				</div>	
            </div>
		</div>
						
		<div class="center_data">
			<div class="label section_label">
				{{'Hide activities'|translate}}
			</div>

			<div class="line line-checkbox">				
				<div class="field">
					<label class="switch">
						<input type="checkbox" id="approvedbyme" [(ngModel)]="approvedbyme">
						<span class="slider round"></span>
					</label>
					<div class="label label-checkbox">{{'Approved by me'|translate}}</div>
				</div>			
			</div>

			<div class="line line-checkbox">				
				<div class="field">
					<label class="switch">
						<input type="checkbox" id="approved" [(ngModel)]="approved">
						<span class="slider round"></span>
					</label>
					<div class="label label-checkbox">{{'Approved all'|translate}}</div>
				</div>			
			</div>
			
			<div class="label section_label" style="padding-top:24px;">
				{{'Show only activities'|translate}}
			</div>

			<div class="line line-checkbox">				
				<div class="field">
					<label class="switch">
						<input type="checkbox" id="author" name="author" [(ngModel)]="author">
						<span class="slider round"></span>
					</label>
					<div class="label label-checkbox">{{'As author'|translate}}</div>
				</div>			
			</div>

			<div class="line line-checkbox">				
				<div class="field">				
					<label class="switch">
						<input type="checkbox" id="hidden" name="hidden" [(ngModel)]="hidden">
						<span class="slider round"></span>
					</label>
					<div class="label label-checkbox">{{'Hidden'|translate}}</div>
				</div>			
			</div>

			<div class="line line-checkbox">				
				<div class="field">
					<label class="switch">
						<input type="checkbox" id="featured" name="featured" [(ngModel)]="featured">
						<span class="slider round"></span>
					</label>
					<div class="label label-checkbox">{{'Featured'|translate}}</div>
				</div>			
			</div>

		</div>
		
		<div class="right_data">
			<div class="label section_label">
				{{'Show only activities by status'|translate}}
			</div>

			<ng-container *ngFor='let status of statuses'>
				<div *ngIf="status.id!='' && status.id != null" class="line status_line">				
					<div class="field">
						<!--<input type="checkbox" formControlName="master" name="master" [(ngModel)]="rows['master']">-->
						
						<!--
						<input *ngIf="status['selected']" type="checkbox" id="{{status.id}}" name="{{status.id}}" (click)="togleStatusRestriction(status.id);" checked>
						<input *ngIf="!status['selected']" type="checkbox" id="{{status.id}}" name="{{status.id}}" (click)="togleStatusRestriction(status.id);">
						<div class="label label-checkbox status_label" style="background:{{status.color}}">
							{{status.title}}
						</div>	
						-->

						<label class="switch" style="float:left;">
							<input *ngIf="status['selected']" type="checkbox" id="{{status.id}}" name="{{status.id}}" (click)="togleStatusRestriction(status.id);" checked>
							<input *ngIf="!status['selected']" type="checkbox" id="{{status.id}}" name="{{status.id}}" (click)="togleStatusRestriction(status.id);">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox status_label" style="float:left; background:{{status.color}}" [ngStyle]="{'color':CommonFunctions.textColorFromBG(status.color)}">{{status.title}}</div>	
						
					</div>			
				</div>
			</ng-container>
		</div>

	</div>

	<div class="modalActions">
        <button class="left" (click)="close()">{{'Cancel'|translate}}</button>
        <button class="right action" (click)="confirm()">{{'Confirm'|translate}}</button>        
    </div>
</div>