import { Component, OnInit, Input } from '@angular/core';
import { GlobalVariables } from '../../services/data.globals';
import { DataService } from '../../services/data.service';
import { CommonFunctions } from '../../services/common.functions'; 
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerComponent } from '../../customers/customer/customer.component';
import { WidgetCustomersSettingsComponent } from './widget-customers-settings/widget-customers-settings.component';
import { DashboardComponent } from '../../dashboard/dashboard.component'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-widget-customers',
  templateUrl: './widget-customers.component.html',
  styleUrls: ['./widget-customers.component.css']
})
export class WidgetCustomersComponent implements OnInit {

  public rows = [];
  public refresh_percent;
  public parameters;
  public parameters_json;
  public mouseover = false;

  @Input() id: string;

  constructor(
    private data_svc: DataService,
    public cFunction: CommonFunctions, 
    public WidgetCustomersSettingsDialog: MatDialog,
    public dialog: MatDialog,
    public Dashboard: DashboardComponent,
    public translate: TranslateService
  ) { }

  settings(id, settings) {
    
    var WidgetCustomersSettings = this.WidgetCustomersSettingsDialog.open(WidgetCustomersSettingsComponent, {
      'width' : '800px',
      data: JSON.parse(settings),
      id: id
    });    

    WidgetCustomersSettings.afterClosed().subscribe(result => {   
      if (result.data) {
        this.parameters = result.data;
        this.parameters_json = JSON.stringify(result.data);
        this.loadData();

        /* save member settings */
        this.data_svc.setMemberSettings({'parameter': id, 'value': this.parameters_json}).subscribe((res: Response) => {});
      }
    });

  }

  remove(id) {
    this.Dashboard.removeWidget(id);
  }


  loadData() {
    this.parameters['widget'] = 1;    
    this.data_svc.getCustomers(this.parameters).subscribe(
      (res: Response) => {
        this.rows = res['rows'];          
      }
    );
  }

  snooze() {
    alert('snooze');
  }

  hide() {
    alert('hide');
  }


  edit(entity) {
    var editDialog = this.dialog.open(CustomerComponent, {});
    editDialog.componentInstance['entity'] = entity;
    editDialog.afterClosed().subscribe(result => {
       //this.getCustomers();
    });  
  }

  prepareDataAndLoad() {
    this.parameters = GlobalVariables.memberSettings[this.id] != undefined ? JSON.parse(GlobalVariables.memberSettings[this.id]) : { title:this.translate.instant("Customer Widget Title..."), items : 24, order : 'entity', orderby : 'desc'};       
    this.parameters_json = JSON.stringify(this.parameters);      
    this.loadData();
  }


  ngOnInit(): void {

    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);

    this.prepareDataAndLoad();

  }

}

