import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import { GlobalVariables } from '../../services/data.globals';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctions } from '../../services/common.functions';

@Component({
  selector: 'app-contact-select',
  templateUrl: './contact-select.component.html',
  styleUrls: ['./contact-select.component.css']
})
export class ContactSelectComponent implements OnInit {

  constructor(
		private data: DataService,
		public translate: TranslateService,
		public cFunction: CommonFunctions,
    public dialog: MatDialogRef<ContactSelectComponent>
	) { /* translate.addLangs(['en', 'si']); */ }


  public entity;
  public parameters = {};
  public customer_id;
  public items = [];
  public total_items = [];
  public selected;
  
  getCustomerContacts() {
    console.log({ company_id : this.customer_id });
    var contacts = this.selected;
    this.data.getCompanyContacts({ 'customer_id' : this.customer_id }).subscribe(
			(res: Response) => {
				this.items = (<any>res).rows;
        console.log(this.items);
				this.total_items = (<any>res).total_rows;				
				for(var i=0; i<this.items.length; i++) {
          if (contacts.includes(this.items[i].entity.toString())) {
					  this.items[i].status = true;
          } else {
            this.items[i].status = false;
          }
				}
        console.log(this.items);
			}
		);
  }

  cancel() {
		this.dialog.close(false);
	}

  close() {
		this.dialog.close(this.items);
	}

  ngOnInit(): void {    
    console.log(this.selected);
		this.getCustomerContacts();
  }

}
