<div class="widget_wrapper" id="{{id}}" [attr.parameters]="parameters_json" >
	<div *ngIf="parameters['title']!='#remove_header'" class="widget_header" cdkDrag cdkDragRootElement=".dashboard_widget" cdkDragHandle>
		<div class="widget_title">
			{{parameters?.title}}&nbsp;
		</div>
		<div class="widget_tools">				
			<fa-icon class="icon_medium" [icon]="['fad', 'times']" (click)="remove(id)" style="margin-top: 5px;"></fa-icon>
            <div class="divider_header"></div>
            <fa-icon [icon]="['fad', 'cog']" (click)="settings(id, parameters_json)"></fa-icon>         
        </div>
	</div>
	<div class="widget_content">		
        <div *ngFor="let item of last_items | keyvalue" class="last_item">
            <div class="item_date">
                <span class="author">                    
                    {{cFunction.getInitials(item.value['author'])}}
                </span>
                <span  class="date">
                    {{cFunction.calculateTimeToEvent(item.value['updated'], null, null)}}            
                </span>                
            </div>
            <div class="item_content">
                <div class="item_title" (click)="edit(item.value['message_entity'])">
                    <span>
                        <fa-icon class="icon_small" *ngIf="item.value['type']=='Comment'" [icon]="['fad', 'comments-alt']"></fa-icon>
                        <fa-icon class="icon_small" *ngIf="item.value['type']=='Message'" [icon]="['fad', 'list']" style="color:{{item.value['hex']}}"></fa-icon>
                    </span>                    
                    {{item.value['title']}}                    
                </div>
                <div *ngIf="item.value['last_change']!='' && item.value['last_change']!=null" class="item_last_change">
                    <fa-icon class="icon_small" [icon]="['fas', 'info-circle']"></fa-icon>
                    {{item.value['last_change']|translate}}
                </div>
                <div *ngIf="item.value['parent_title']!='' && item.value['parent_title']!=null" class="item_parent">
                    <fa-icon class="icon_small" *ngIf="item.value['type']=='Comment'" [icon]="['fad', 'list']"></fa-icon>
                    {{item.value['parent_title']}}
                </div>                
                <div class="item_spacer"></div>
                <div class="item_company" *ngIf="item.value['company']!=null">
                    <span>
                        <fa-icon class="icon_small" [icon]="['fad', 'users']" style="color:{{item.value['customer_hex']}}"></fa-icon>
                    </span>
                    {{item.value['company']}}
                </div>
                <div class="item_updated">            
                    {{cFunction.localDateFormat(item.value['updated'])}}<span *ngIf="item.value['viewed']!=0"> | <fa-icon class="icon_small" [icon]="['fad', 'eye']"></fa-icon> {{item.value['viewed']}}</span>
                </div>
            </div>    
        </div>
    </div>
</div>