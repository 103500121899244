<div class="tableFooter">
  <div class="rows_limit_wrapper">
      <span class="rows_limit" *ngFor="let item of pagination" [ngClass]="itemsLimit == item ? 'selected' : 'not-selected'" (click)="changeLimit(item)">{{ item }}</span>
  </div>

  <div class="rows_view">{{ 'Show' | translate }} {{ itemsLimit_display }} {{ 'of' | translate }} {{ total_items }}</div>

  <div class="page_wrapper">
      <span class="pages">{{ 'Pages' | translate }} {{ pages }}</span>

      <!-- Prvi gumb (skok na prvo stran) -->
      <span *ngIf="page != 1" class="page first" (click)="changePage(1)">
          <fa-icon class="big" [icon]="['fad', 'chevrons-left']"></fa-icon>
      </span>

      <!-- Dinamična paginacija -->
      <ng-container *ngFor="let i of [].constructor(pages); let index = index">
          <ng-container *ngIf="
              (page <= 3 && index < 5) || 
              (page >= pages - 2 && index >= pages - 5) || 
              (page > 3 && page < pages - 2 && index >= page - 3 && index <= page + 1)
          ">
              <span class="page" [ngClass]="page === index + 1 ? 'selected' : 'not-selected'" (click)="changePage(index + 1)">
                  {{ index + 1 }}
              </span>
          </ng-container>
      </ng-container>

      <!-- Zadnji gumb (skok na zadnjo stran) -->
      <span *ngIf="pages != page" class="page last" (click)="changePage(pages)">
          <fa-icon class="big" [icon]="['fad', 'chevrons-right']"></fa-icon>
      </span>
  </div>
</div>

