<div class="inner_wrapper">

	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="left">
		    <h1>
			    {{'Custom customer fields'|translate}}
		    </h1>
		</div>
		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
		</div>
	</div>

	<form [formGroup]="formData" (ngSubmit)="save()" novalidate>

		<div class="body">
			<div class="inline w100 wPadding">               
				<mat-form-field appearance="outline">
				  <mat-label>{{'Field ID'|translate}}</mat-label>
				  <input type="text" matInput formControlName="id_addon" name="id_addon" [(ngModel)]="rows['id_addon']">
				  <!--<mat-hint>Error</mat-hint>-->
				</mat-form-field>
			</div>

			<div class="inline w100 wPadding">               
				<mat-form-field appearance="outline">
				  <mat-label>{{'Field Description'|translate}}</mat-label>
				  <input type="text" matInput formControlName="description" name="description" [(ngModel)]="rows['description']">
				  <!--<mat-hint>Error</mat-hint>-->
				</mat-form-field>
			</div>

			<div class="inline w100 wPadding">
				<mat-form-field appearance="outline">
				  <mat-label>{{'Field Type'|translate}}</mat-label>
				  <mat-select formControlName="field_type" name="field_type" name_value="select" [(ngModel)]="rows['field_type']">
					<mat-option value="text">{{'Text field'|translate}}</mat-option>
					<mat-option value="textarea">{{'Textarea'|translate}}</mat-option>  
					<mat-option value="check">{{'Checkbox'|translate}}</mat-option>
					<mat-option value="dropdown">{{'Dropdown field'|translate}}</mat-option>                    
				  </mat-select>
				<!--<mat-hint">Error</mat-hint>-->
				</mat-form-field>
			</div>

			<div class="inline w100 wPadding">               
				<mat-form-field appearance="outline">
				  <mat-label>{{'Default value/Dropdown Options'|translate}}</mat-label>
				  <input type="text" matInput formControlName="field_default" name="field_default" [(ngModel)]="rows['field_default']">
				  <!--<mat-hint>Error</mat-hint>-->
				</mat-form-field>
			</div>
		</div>

		<div class="modalActions" mat-dialog-actions>
			<button class="accept right" type="submit" mat-button mat-dialog-close>{{'Save'|translate}}</button>
			<button *ngIf="rows['entity']!=0 && rows['entity']!=undefined && rows['entity']!=null" class="warrning left" (click)="delete()" mat-button mat-dialog-close>{{'Delete'|translate}}</button>
			<button class="action left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
		</div>

	</form>

</div>