import { Component, OnInit, Input } from '@angular/core';
import { GlobalVariables } from '../../services/data.globals';
import { DataService } from '../../services/data.service';
import { CommonFunctions } from '../../services/common.functions';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValue } from '@angular/common';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { TranslateService } from '@ngx-translate/core'; 

@Component({
  selector: 'app-widget-postit',
  templateUrl: './widget-postit.component.html',
  styleUrls: ['./widget-postit.component.css']
})
export class WidgetPostitComponent implements OnInit {

  @Input() id: string;

  timeout: any = null;
  public content = "";
  public content_a = "";
  public content_b = "";
  public extended = false;


  constructor(
    private data: DataService,
    public cFunction: CommonFunctions,     
    public dialog: MatDialog,
    public Dashboard: DashboardComponent,
    public translate: TranslateService 
  ) { 
     translate.addLangs(['en', 'si']);
   }

    /************************************/
  loadData() {
  }

  /*
  edit(entity) {
    var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity'});
    editDialog.componentInstance.entity = entity;
    editDialog.afterClosed().subscribe(result => {
      this.loadData();
    });
  }
  */

  remove(id) {
    this.Dashboard.removeWidget(id);
  }

  prepareDataAndLoad() {    
  }

  removeExtended(id, position) {
    this.extended = false;
    var content_helper = this.content.split('||');
    if (position == 1) {
      this.content = content_helper[0] + "||";
    } else {
      this.content = content_helper[1] + "||";
    }
    this.save(id, this.content);
  }

  saveStickyNote(id, event, position) {
    clearTimeout(this.timeout);
    var content_helper = this.content.split('||');
    content_helper[position] = event.target.value;
    content_helper[0] = content_helper[0] == undefined ? "" : content_helper[0];
    content_helper[1] = content_helper[1] == undefined ? "" : content_helper[1];
    this.content = content_helper[0] + "||" + content_helper[1];
    var content_to_save = this.content;
    var $this = this;
    this.timeout = setTimeout(function () {      
      //console.log(content_to_save);
      $this.save(id, content_to_save);      
    }, 1000);
  }

  save(id, content) {
    var data = { id: id, content: content };
    this.data.saveStickyNote(data).subscribe((res: Response) => {      
    });
  }

  prepareBlankRight(id) {    
    //console.log(id + "|" + this.content);
    this.placeContentonSticky();
    this.save(id, this.content);
    /*
    this.content = this.content + "2*";    
        
    this.placeContentonSticky();
    */
  }

  placeContentonSticky() {
    if (this.content==undefined || this.content == '') {      
      this.content = "||";
    }
    
    var helper = this.content.split('||');
    //console.log(helper);
    this.content_a = helper[0] != '' ? helper[0] : "";

    if (this.content == "||") {
      this.content_b = "";
    } else {
      this.content_b = helper[1] != '' ? helper[1] : "";
    }
    
    if (this.content_a == '' || this.content_b == '') {
      this.extended = false;
    } else {
      this.extended = true;
    }
    
    if (this.content_b != '') {
      this.content = this.content_a + "||" + this.content_b;
    } else {
      this.content = this.content_a;
    }
  }

  getContent() {    
    this.data.getStickyNote({ id : this.id }).subscribe((res: Response) => {                  
      this.content = res['rows'][0]['content'];
      this.placeContentonSticky();
    });
  }

  ngOnInit(): void {
    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);

    this.getContent();

    jQuery("textarea.stickynote").keyup(function(e) {
      while(jQuery(this).outerHeight() < this.scrollHeight + parseFloat(jQuery(this).css("borderTopWidth")) + parseFloat(jQuery(this).css("borderBottomWidth"))) {
          jQuery(this).height(jQuery(this).height()+1);
      };
    });
  }

}
