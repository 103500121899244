/* Angular / Material */
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'; // also required if you're using `<mat-label>` and `<input matInput>`

/* Services */
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { TranslateService } from '@ngx-translate/core';

/* Common Functions */
import { CommonFunctions } from '../../../services/common.functions';

/* Modals */
import { ModalComponent } from '../../../modal/modal.component';

/* Other */
//import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';

@Component({
  selector: 'app-erp-richitem-category-item',
  templateUrl: './erp-richitem-category-item.component.html',
  styleUrl: './erp-richitem-category-item.component.css'  
})
             
export class ErpRichItemCategoryItemComponent {

  public entity = null;

  constructor(
    private data: DataService,
    public cFunction: CommonFunctions,
    public translate: TranslateService,
    public dialog: MatDialog,
    public CommonFunctions: CommonFunctions
  ) {
    translate.addLangs(['en', 'si']);
  }

  save() {
  }

  delete() {
  }

  /**************************/
  ngOnInit(): void {    
   
  } //oninit
  
}
