<div class="widget_wrapper" id="{{id}}">
	<div class="sticky_container" [ngClass]="{'extended':extended==true}">
		<div  class="widget_header" cdkDrag cdkDragRootElement=".dashboard_widget" cdkDragHandle>
			<div class="widget_title">
				{{'Sticky note'|translate}}&nbsp;
			</div>
			<div class="widget_tools">			
				<!--<fa-icon [icon]="['fad', 'cog']" (click)="settings(id, parameters_json)"></fa-icon>-->
				<fa-icon *ngIf="extended!=true" class="icon_medium" [icon]="['fad', 'times']" (click)="remove(id)" style="margin-top: 2px;"></fa-icon>
				<fa-icon *ngIf="extended==true" class="icon_medium" [icon]="['fad', 'times']" (click)="removeExtended(id, 0)"></fa-icon>
				<div *ngIf="extended!=true && content_a!=''" class="divider_header"></div>
				<fa-icon *ngIf="extended!=true && content_a!=''" class="icon_medium divide" [icon]="['fad', 'divide']" (click)="extended=true; prepareBlankRight(id);" class="fa-rotate-90"></fa-icon>
			</div>
		</div>
		<div class="widget_content">		
			<textarea class="stickynote" name="stickyA_{{id}}" (keyup)='saveStickyNote(id, $event, 0)' [(ngModel)]="content_a"></textarea>		
		</div>
	</div>

	<div *ngIf="extended==true" class="sticky_container extended sticky_right">
		<div  class="widget_header" cdkDrag cdkDragRootElement=".dashboard_widget" cdkDragHandle>
			<div class="widget_title">
				{{'Sticky note'|translate}}&nbsp;
			</div>
			<div class="widget_tools">			
				<!--<fa-icon [icon]="['fad', 'cog']" (click)="settings(id, parameters_json)"></fa-icon>-->
				<fa-icon *ngIf="extended!=true" class="icon_medium" [icon]="['fad', 'times']" (click)="remove(id)"></fa-icon>
				<fa-icon *ngIf="extended==true" class="icon_medium" [icon]="['fad', 'times']" (click)="removeExtended(id, 1)"></fa-icon>
				<fa-icon *ngIf="extended!=true" [icon]="['fad', 'cog']" (click)="extended=true"></fa-icon>
			</div>
		</div>
		<div class="widget_content">		
			<textarea class="stickynote" name="stickyB_{{id}}" (keyup)='saveStickyNote(id, $event, 1)' [(ngModel)]="content_b"></textarea>		
		</div>
	</div>

</div>


