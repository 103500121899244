import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { GlobalVariables } from '../../services/data.globals';
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

import { HttpClient, HttpRequest } from '@angular/common/http';
import { UploadService } from '../../services/upload.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentEditComponent } from '../../document-center/document-edit/document-edit.component';

@Component({
  selector: 'app-knowledge-edit',
  templateUrl: './knowledge-edit.component.html',
  styleUrls: ['./knowledge-edit.component.css']
})

export class KnowledgeEditComponent implements OnInit {

  public entity;
  public system;
  public deprecated;
  public version;
  public title;
  public keywords;
  public tags;
  public description;
  public solution;
  public author;
  public used;
  public readOnly = false;
  public rows = [];
  public systems = [];
  public attachments;

  private _openDialog() {
		if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) return;		
	}

  formData: UntypedFormGroup; 

	/*******************************/

	constructor(
		private data: DataService,
		private route: ActivatedRoute,
    public dialog: MatDialog,
    private UploadService: UploadService,
		public translate: TranslateService,
    public dialogRef: MatDialogRef<KnowledgeEditComponent>
	) {
		this.formData = this.createFormGroup();
		translate.addLangs(['en', 'si']);
	}

	/*******************************/
	
	createFormGroup() {
		return new UntypedFormGroup({
      entity: new UntypedFormControl(),
      system: new UntypedFormControl(),
      deprecated: new UntypedFormControl(),
      version: new UntypedFormControl(),
      title: new UntypedFormControl(),
      keywords: new UntypedFormControl(),
      tags: new UntypedFormControl(),
      description: new UntypedFormControl(),
      solution: new UntypedFormControl(),
      author: new UntypedFormControl(),
      used: new UntypedFormControl()
		});
	}

  save() {
    var parameters = { data: Object.assign({}, this.formData.value) };
    
    this.data.saveKnowledge(parameters).subscribe(
      (res: Response) => {
        //console.log(res);
        //console.log(this.rows['entity']);
        if (this.rows['entity']==null) {
          //reload
          //console.log('reload');
          this.entity = res['callback']['entity'];
          this.ngOnInit();
        } else {
          //close
          this.dialogRef.close();
        }
      }
    );
  }

  delete() {    
    this.data.deleteKnowledge({ 'data' : {'entity' : this.entity }}).subscribe(
			(res: Response) => {				
			}
		);
  }

  ngOnInit(): void {    
    //console.log(this.entity);
    this.rows['entity'] = this.entity;
    //alert(this.entity);
    if (this.entity != null) {
      this.data.getKnowledge({ 'data' : {'entity' : this.entity }}).subscribe(
        (res: Response) => {
          this.rows = (<any>res).rows[0];
		  console.log(this.rows['entity']);
          
			this.data.getKnowledgeSystems({}).subscribe((res: Response) => {
				this.systems = (<any>res).rows;			
			});

			this.data.getDocuments({'items' : 10000, 'order' : 'doc_title', 'orderby' : 'asc', search: null, 'field' : 'knowledge', 'value' : this.rows['entity']}).subscribe((res: Response) => {
				this.attachments = (<any>res).rows;            
				console.log(this.attachments);
			});

        }
      );
    }    
  } 

  /***************************************************************/
  download(filename, original_filename) {   
    this.data.downloadDocument(filename, original_filename);   
  }

  deleteAttachment(entity) {   	
    this.data.deleteDocument(entity);
  }

  /******************************************************************/
	/******************************************************************/
	/******************************************************************/
	/* Uploader 				                                              */

	public files: any[] = [];
	public selectedFiles: FileList;
  public currentFile: File;
  public progress = 0;  
  public fileInfos: Observable<any>;
  private document_details;

  /*************************************/
  /* file dropper */

  closeDropField_knowledge() {    
    const element = document.getElementsByClassName("file_container_knowledge")[0] as HTMLElement;
    element.style.visibility = "hidden";
    element.style.opacity = "0";    
    const element2 = document.getElementsByClassName("dropdowninfo")[0] as HTMLElement;
    element2.style.visibility = "";
    this.files = [];	
  }

	prepareFilesList(files: Array<any>) {
		for (const item of files) {
			item.progress = 0;
			this.files.push(item);
		}
		this.uploadFiles(0);
	}

	onFileDropped($event) {
		this._openDialog();
		////console.log($event);
		(document.getElementsByClassName("dropdowninfo")[0] as HTMLElement).style.visibility = "hidden";		
    const d = new Date();    
		var folder = "knowledge/" + d.getFullYear();
    var editDialog = this.dialog.open(DocumentEditComponent, { 'width' : '800px', data : { files : $event, prefill : { knowledge : this.rows['entity'], folder: folder, call: 'knowledge', customer: null, customer_id: null,  entity: this.rows['entity'], title: this.rows['title'], category: '', category_id: ''} } });
		editDialog.afterClosed().subscribe(result => {	    
			if (result != undefined && result != '') {
				this.document_details = result.value;
				this.prepareFilesList($event);
			} else {
				const element = document.getElementsByClassName("file_container_knowledge")[0] as HTMLElement;
				element.style.visibility = "hidden";
				element.style.opacity = "0";    
				const element2 = document.getElementsByClassName("dropdowninfo_knowledge")[0] as HTMLElement;
				element2.style.visibility = "";
				this.files = [];	
			}
		});		     
	}

	closeDropField() {
		const element = document.getElementsByClassName("file_container_knowledge")[0] as HTMLElement;
		element.style.visibility = "hidden";
		element.style.opacity = "0";    
		const element2 = document.getElementsByClassName("dropdowninfo")[0] as HTMLElement;
		element2.style.visibility = "";
		this.files = [];    
	}

	fileBrowseHandler(files) {
		//console.log('fileBrowseHandler');
		//this.prepareFilesList(files);
	}

	deleteFile(index: number) {
		this.files.splice(index, 1);
	}
  
  uploadFiles(index: number) {
		this.upload(this.files[index]);
		setTimeout(() => {
			if (index === this.files.length) {
				(document.getElementsByClassName("file_container_knowledge")[0] as HTMLElement).style.visibility = "hidden";
				(document.getElementsByClassName("file_container_knowledge")[0] as HTMLElement).style.opacity = "0";    
				(document.getElementsByClassName("dropdowninfo")[0] as HTMLElement).style.visibility = "";       
				this.files = [];
        this.ngOnInit();
				return;
			} else {
				const progressInterval = setInterval(() => {
					if (this.files[index] != undefined) {
						if (this.files[index].progress === 100) {
							clearInterval(progressInterval);	            
							this.uploadFiles(index + 1);            
						} else {
							this.files[index].progress = this.progress;	            
						}
					}
				}, 50);
			}
		}, 50);		
	}

	/* upload 1 file at once */
	upload(currentFile): void {
		this.progress = 0;
		this.UploadService.upload(currentFile).subscribe(	    
			event => {
				if (event.type === HttpEventType.UploadProgress) {
					this.progress = Math.round(100 * event.loaded / event.total);	 	        
				} else if (event instanceof HttpResponse) {
					if (event.body.name != undefined) {
						this.document_details['doc_file'] = event.body['name'];
						this.document_details['doc_code'] = event.body['original_name'];
						this.document_details['doc_size'] = currentFile.size;	        
           				this.document_details['knowledge'] = this.rows['entity'];
						////console.log(this.document_details);
						this.data.saveDocument(this.document_details).subscribe(
							(res: Response) => {}				
						);
					}
				}
			},
			err => {
				this.progress = 0;
				//this.message = 'Could not upload the file!';
				currentFile = undefined;
			}
		);
		this.selectedFiles = undefined;
	}

	formatBytes(bytes, decimals) {
		if (bytes === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals || 2;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

}

/*
this.data.getDocuments({'items' : 10000, 'order' : 'doc_title', 'orderby' : 'asc', search: null, 'field' : 'etalk', 'value' : this.item['entity']}).subscribe(
  (res: Response) => {
    this.eTalkAttachments = (<any>res).rows;            
  }
);
*/