<div class="inner_wrapper">
	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<!-- Title -->
        <div class="left"> 
			<h1>{{'Quote'|translate}}
                <span>
                    <ng-container *ngIf="basic['quote_id']!=''">
                       &nbsp;&nbsp;|&nbsp;&nbsp;{{basic['quote_id']}}
                    </ng-container>
                    <ng-container *ngIf="basic['subject']!=''">
                        &nbsp;&nbsp;|&nbsp;&nbsp;{{basic['subject']}}
                    </ng-container>
                </span>
            </h1>
		</div>        
        <!-- Close -->
		<div class="right">                     
            <fa-icon class="icon_close" [icon]="['fad', 'times']"  mat-button mat-dialog-close></fa-icon>
		</div>
        <!-- Internal Functions Menu -->
        <app-internalmenu [component]="component" [localInternalMenu]="localInternalMenu" [executeLocalFunction]="executeLocalFunctionFunc"></app-internalmenu>
	</div>	

	<div class="body">
        <!-- tabs buttons --> 
        <div class="tabs headerTabs">
            <div class="tab" [ngClass]="{'active':selectedTab == 'summary'}" (click)="switchTab('summary')">
                <span>{{'Summary'|translate}}</span>
			</div>
			<div class="tab" [ngClass]="{'active':selectedTab == 'basic'}" (click)="switchTab('basic')">
                <span>{{'Basic data'|translate}}</span>
			</div>
			<div *ngIf="basic['category']!='' && basic['quote_id']!=''" class="tab" [ngClass]="{'active':selectedTab == 'items'}" (click)="switchTab('items')">
                <span>{{'Items'|translate}}</span>
            </div>
            <div *ngIf="basic['category']!='' && basic['quote_id']!=''" class="tab right" [ngClass]="{'active':selectedTab == 'static'}" (click)="switchTab('static')">
                <span>{{'Static texts'|translate}}</span>
            </div>            
		</div>

        <!-- tabs content --> 
        <!------------------------------------------------------->
        <div id="summary" class="tab-child" [ngClass]="{'active':selectedTab=='summary'}">
            <div class="summaryLeft">
                <div class="quotenr">
                    {{basic['quote_id']}}
                </div>
                <div class="subject">
                    {{basic['subject']}}
                </div>

                <div class="inline w100 divider" style="border-width: 6px;border-color:var(--dark-lvl3)"></div>

                <div class="pretext">
                    {{'Investitor'|translate}}
                </div>
                <div class="text">
                    {{basic['customer']}}<br />
                    {{basic['company_responsible']}}<br />
                    {{basic['company_responsible_phone']}}<br />
                    {{basic['company_responsible_email']}}
                </div>

                <div class="pretext">
                    {{'Datum ponudbe'|translate}}
                </div>
                <div class="text">
                    {{cFunction.localDateOnlyFormat(basic['date'])}}
                </div>

                <div class="pretext">
                    {{'Veljavnost ponudbe'|translate}}
                </div>
                <div class="text">
                    {{cFunction.localDateOnlyFormat(basic['valid'])}}
                </div>

                <div class="inline w100 divider" style="margin-top:18px;margin-bottom: 4px;border-width: 6px;border-color:var(--dark-lvl3)"></div>

                <div class="pretext">
                    {{'Vrednost ponudbe'|translate}}
                </div>
                <div class="text" style="font-size:1.4rem;font-weight:700;">
                    {{cFunction.formatNumber(basic['grandtotal'])}} €
                </div>

                <div style="float:left; width: 100%; padding-top: 24px;">
                    <button *ngIf="basic['status']!='closed'" class="opened" (click)="toggleStatus('closed')">{{'Opened'|translate}}</button>
                    <button *ngIf="basic['status']=='closed'" class="closed" (click)="toggleStatus('opened')">{{'Closed'|translate}}</button>
                </div>
            </div>
            <div class="summaryRight">
                <div class="details" style="margin-top:0px;">{{'Quote Content'|translate}}</div>
                <div class="summaryTable">
                    <div class="headerTable summaryTableRow" style="margin-bottom:10px;">
                        <div class="categoryTitle summaryTableCell">{{'Chapters'|translate}}</div>
                        <div class="categorySum summaryTableCell"></div>
                    </div>
                    <div class="summaryTableRow">                        
                        <ng-container *ngFor="let item of staticCustom; let i = index">
                            <div class="summaryTableRow clickable" (click)="switchTab('static');closeStatic();toggleStatic(i)">
                                <div class="categoryTitle summaryTableCell">
                                    <fa-icon [icon]="['fad', 'bars']" style="display:inline;margin-right:6px;margin-top:2px;float:left;"></fa-icon>
                                    <div style="display:inline-block;float:left;padding:0px 0px 0px 6px;">{{staticCustom[i]['title']}}</div>
                                </div>
                                <div class="categorySum summaryTableCell"></div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                
                <div class="details" style="margin-top:24px;">{{'Quote Deatils'|translate}}</div>
                <div class="summaryTable">
                    <div class="headerTable summaryTableRow" style="margin-bottom:10px;">
                        <div class="categoryTitle summaryTableCell">{{'Quote category details'|translate}}</div>
                        <div class="categorySum summaryTableCell">{{'Summary'|translate}}</div>
                    </div>
                    <ng-container *ngFor="let category of getCategories(summaryResults)">
                        <ng-container *ngFor="let type of getTypes(summaryResults[category])">
                            <div class="summaryTableRow clickable" (click)="switchTab('items')">
                                <div class="categoryTitle summaryTableCell">
                                    <fa-icon [icon]="['fad', 'calculator-simple']" style="display:inline;margin-right:6px;margin-top:2px;float:left;"></fa-icon>
                                    <div style="display:inline-block;float:left;padding:0px 0px 0px 6px;"><b>{{category}}</b> | {{type}}</div>
                                </div>
                                <div class="categorySum summaryTableCell">{{cFunction.formatNumber(summaryResults[category][type],2)}} EUR</div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <div class="summaryQuote summaryTableRow" style="margin-top:10px;">
                        <div class="categoryTitle summaryTableCell">{{'Quote total without tax + Possible discounts'|translate}}</div>
                        <div class="categorySum summaryTableCell">{{cFunction.formatNumber(basic['grandtotal'])}} EUR</div>
                    </div>
                </div>

                <ng-container *ngIf="fullActivity!=null">
                    <div class="details" style="margin-top:24px;">{{'Other Resources'|translate}}</div>
                    <div class="activity" (click)="NavigationService.navigate('popup', 'activity', null, fullActivity['entity']);" style="background:{{fullActivity['category_type_hex']}}; color:{{cFunction.textColorFromBG(fullActivity['category_type_hex'])}}">                        
                        <span>{{fullActivity['category_type_description']}}</span><br />
                        {{fullActivity['title']}}
                    </div>
                </ng-container>                
            </div>
            <!--
            <div class="left">
                <div class="label bold" style="background:var(--row-hover)"><span>{{'Številka ponudbe'|translate}}: </span><input type="text" (keypress)="editField();" value="{{basic['quote_id']}}" disabled></div>
                <div class="label"><span>{{'Predmet ponudbe'|translate}}: </span><input type="text" (keypress)="editField();" value="{{basic['subject']}}" disabled></div>
                <div class="label"><span>{{'Datum ponudbe'|translate}}: </span><input type="text" (keypress)="editField();" value="{{cFunction.localDateOnlyFormat(basic['date'])}}" disabled></div>
                <div class="label"><span>{{'Veljavnost ponudbe'|translate}}: </span><input type="text" (keypress)="editField();" value="{{cFunction.localDateOnlyFormat(basic['valid'])}}" disabled></div>
                <div class="label"><span>{{'Vrednost ponudbe'|translate}}: </span><input type="text" (keypress)="editField();" value="{{cFunction.formatNumber(basic['grandtotal'])}} EUR" disabled></div>                
                <div class="label bold" style="margin-top: 32px;"><span>{{'Investitor/naročnik'|translate}}: </span><input type="text" (keypress)="editField();" value="{{basic['customer']}}" disabled></div>
                <div class="label"><span>{{'Responsible by'|translate}}: </span><input type="text" (keypress)="editField();" value="{{basic['company_responsible']}}" disabled></div>
                <div class="label"><span>{{'Responsible by email'|translate}}: </span><input type="text" (keypress)="editField();" value="{{basic['company_responsible_email']}}" disabled></div>
                <div class="label"><span>{{'Responsible by phone'|translate}}: </span><input type="text" (keypress)="editField();" value="{{basic['company_responsible_phone']}}" disabled></div>
            </div>
            <div class="right">
                <div class="label bold"><span>{{'Category'|translate}}: </span><input type="text" (keypress)="editField();" value="{{basic['category']}}" disabled></div>
                <div class="label"><span>{{'Ponudbo pripravil'|translate}}: </span><input type="text" (keypress)="editField();" value="{{basic['prepared_by']}}" disabled></div>
                <div class="label"><span>{{'Odgovorna oseba'|translate}}: </span><input type="text" (keypress)="editField();" value="{{basic['responsible_by']}}" disabled></div>                
                <div class="label bold" style="margin-top:169px;background:var(--dark-lvl2);padding-top:22px;padding-bottom:22px;"><span style="width:100%;">{{'Quote internal comment'|translate}}: </span></div>
                <div class="labelTextField" style="border:none;"><span style="width:0;"></span><textarea disabled style="width: 100%; height: 182px; padding: 16px; overflow: visible; line-height: normal; resize: none;">{{basic['comment_internal']}}</textarea>
                </div>
            </div>
            -->
        </div>

        <!------------------------------------------------------->
        <input type="hidden" [(ngModel)]="basic['entity']" />
        <div id="basic" class="tab-child" [ngClass]="{'active':selectedTab=='basic'}">
            <div class="inline w30 wPadding">
                <mat-form-field appearance="outline">
                    <mat-label>{{'Category'|translate}}</mat-label>
                    <mat-select [(ngModel)]="basic['category']">
                        <mat-option (keypress)="editField();" value="all">All</mat-option>
                        <mat-option *ngFor="let category of categories" value="{{category}}">{{category}}</mat-option>                      
                    </mat-select>
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>

            <div class="inline w70 alignRight" style="padding-top: 12px;">
                <button *ngIf="basic['status']!='closed'" class="opened" (click)="toggleStatus('closed')">{{'Opened'|translate}}</button>
                <button *ngIf="basic['status']=='closed'" class="closed" (click)="toggleStatus('opened')">{{'Closed'|translate}}</button>
            </div>

            <div class="w100 inline divider" style="border-bottom: 1px solid var(--light-lvl1);"></div>

            <div class="inline w100 wPadding">               
                <mat-form-field appearance="outline" class="important">
                    <mat-label>{{'Quote subject'|translate}}</mat-label>
                    <input type="text" matInput (keypress)="editField();" [(ngModel)]="basic['subject']">
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>

            <div class="inline w100 divider" style="border-bottom: 1px solid var(--light-lvl1);"></div>

            <div class="inline w30 wPadding">
                <mat-form-field appearance="outline">
                    <mat-label>{{'Quote id'|translate}}</mat-label>
                    <input type="text" matInput (keypress)="editField();" [(ngModel)]="basic['quote_id']" readonly disabled>                    
                </mat-form-field>
            </div>

            <div class="inline w25 wPadding" style="float:right;">
                <mat-form-field appearance="outline">
                    <mat-label>{{'Valid'|translate}}</mat-label>
                    <mat-icon (click)="showDatePicker()"></mat-icon>
                    <input matInput (keypress)="editField();" [matDatepicker]="pickerValid" [(ngModel)]="basic['valid']">
                    <mat-datepicker-toggle matSuffix [for]="pickerValid"></mat-datepicker-toggle>
                    <mat-datepicker #pickerValid></mat-datepicker>
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>
            
            <div class="inline w25 wPadding" style="float:right;">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'Date' | translate }}</mat-label>
                    <mat-icon (click)="showDatePicker()"></mat-icon>
                    <input matInput (keypress)="editField();" [matDatepicker]="pickerDate" [(ngModel)]="basic['date']">
                    <mat-datepicker-toggle class="datePicker" matSuffix [for]="pickerDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDate></mat-datepicker>
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
              </div>

            <div class="inline w100 divider"></div>	         

            <div class="inline w30 wPadding">
                <mat-form-field appearance="outline">
                    <mat-label>{{'Company'|translate}}</mat-label>
                    <input [(ngModel)]="basic['customer_id']" type="hidden" />
                    <input type="text"  matInput (keypress)="editField();" (click)="selectCustomer()" [(ngModel)]="basic['customer']">                    
                    <fa-icon class="right" [icon]="['fad', 'users']" (click)="selectCustomer()"></fa-icon>
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>

            <div class="inline w10 wPadding"></div>

            <div class="inline w20 wPadding">
                <mat-form-field appearance="outline">
                    <mat-label>{{'Responsible by'|translate}}</mat-label>
                    <input type="text" matInput (keypress)="editField();" [(ngModel)]="basic['company_responsible']">
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>

            <div class="inline w20 wPadding">
                <mat-form-field appearance="outline">
                    <mat-label>{{'Responsible by email'|translate}}</mat-label>
                    <input type="text" matInput (keypress)="editField();" [(ngModel)]="basic['company_responsible_email']">  
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>

            <div class="inline w20 wPadding">
                <mat-form-field appearance="outline">
                    <mat-label>{{'Responsible by phone'|translate}}</mat-label>
                    <input type="text" matInput (keypress)="editField();" [(ngModel)]="basic['company_responsible_phone']">
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>

            <div class="inline w100 divider"></div>	

            <div class="inline w50 wPadding">
                <mat-form-field appearance="outline">
                    <mat-label>{{'Quote comment' | translate}}</mat-label>
                    <textarea matInput (keypress)="editField();" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" [(ngModel)]="basic['comment_public']"></textarea>
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>
            
            <div class="inline w50 wPadding">                
                <mat-form-field appearance="outline">
                    <mat-label>{{'Text area' | translate}}</mat-label>
                    <textarea matInput (keypress)="editField();" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" [(ngModel)]="basic['comment_internal']"></textarea>
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>

            <div class="inline w100 divider" style="border-bottom: 1px solid var(--light-lvl1);"></div>	

            <div class="inline w25 wPadding">
                <mat-form-field appearance="outline">
                <mat-label>{{'Prepared by'|translate}}</mat-label>
                <input [(ngModel)]="basic['prepared_by_id']" type="hidden" />
                <input matInput (keypress)="editField();"  name="prepared_by" [(ngModel)]="basic['prepared_by']" (click)="preparedBy()" readonly />
                <fa-icon class="right" [icon]="['fad', 'users']" (click)="preparedBy()"></fa-icon>
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>
            
            <div class="inline w25 wPadding">
                <mat-form-field appearance="outline">
                <mat-label>{{'Responsible by'|translate}}</mat-label>
                <input [(ngModel)]="basic['responsible_by_id']" type="hidden" />
                <input matInput (keypress)="editField();"  name="responsible_by" [(ngModel)]="basic['responsible_by']" (click)="responsibleBy()" readonly />
                <fa-icon class="right" [icon]="['fad', 'users']" (click)="responsibleBy()"></fa-icon>       
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>
        </div>

        <!------------------------------------------------------->
        <div *ngIf="basic['category']!='' && basic['quote_id']!=''" id="items" class="tab-child" [ngClass]="{'active':selectedTab=='items'}">  
            <div class="top">
                <div class="inline w20 wPadding">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Type'|translate}}</mat-label>
                        <mat-select [(ngModel)]="basic['type']">
                            <mat-option *ngFor="let type of types[basic['category']]" value="{{type}}">{{type}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="inline w20 wPadding">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Search'|translate}}</mat-label>
                        <input matInput (keypress)="editField();" type="text" id="search" name="search" [(ngModel)]="search">
                    </mat-form-field>
                </div>
                <div class="inline w20 wPadding right">
                    <button class="button_edit" (click)="newItem()">{{'Add Item'|translate}}</button>
                </div>
                <div class="inline w40 wPadding right" id="sort">
                    <span class="clickable" (click)="reorderItemsByPoz()">{{'By Position'|translate}}</span>
                    <span class="clickable" (click)="reorderItemsByCategory()">{{'By Category'|translate}}</span>
                    <span><fa-icon class="big" [icon]="['fad', 'layer-group']"></fa-icon></span>
                </div>
            </div>

            <div class="prebody">
                <div class="elements_line_header">
                    <div class="id">{{'ID'|translate}}</div>        
                    <div class="title">{{'Title'|translate}}</div>
                    <div class="qty">{{'Qty'|translate}}</div>
                    <div class="price">{{'Price'|translate}}</div>
                    <div class="discount">{{'Discount'|translate}}</div>
                    <div class="total">{{'Total'|translate}}</div>
                    <div class="more" (click)="toggleAllDescriptions()"><fa-icon class="icon_small" [icon]="['fad', 'chevrons-down']"></fa-icon></div>
                </div> 
            </div>

            <div class="body">
                <div id="elements">
                    <ng-container *ngFor="let item of items; let i = index">
                        <ng-container *ngIf="i==0">
                            <ng-container *ngIf="countDisplay(0)"></ng-container>
                        </ng-container>                        
                        <ng-container *ngIf="i!=0">
                            <div *ngIf="items[iDisplay].type!=item.type && ((hideEmpty == true && item.qty != 0) || hideEmpty != true) && (item.category==basic['category'] || basic['category'] == 'all') && (item.type==basic['type'] || basic['type'] == null ||  basic['type'] == '')" class="elements_line type_line">
                                <ng-container *ngIf="countDisplay(i)">
                                    <div class="group_wrapper">
                                        <b>{{item.category}}</b> | {{item.type}}
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="(search == '' || (item.title).includes(search)) && (hideEmpty != true || (hideEmpty == true && item.qty != 0)) && (item.category==basic['category'] || basic['category'] == 'all') && (item.type==basic['type'] || basic['type'] == null ||  basic['type'] == '')">    
                            <ng-container *ngIf="iDisplay==0">
                                <ng-container *ngIf="countDisplay(i)">
                                    <div class="elements_line type_line">
                                        <div class="group_wrapper">
                                            <b>{{item.category}}</b> | {{item.type}}
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <div class="elements_line">
                                <input type="hidden" name="category" [(ngModel)]="item.category" disabled />
                                <input type="hidden" name="type" [(ngModel)]="item.type" disabled />
                                <input type="hidden" name="entity" col="0" [(ngModel)]="item.entity" disabled />                    
                                <div class="id"><input name="poz" type="text" col="2" [ngClass]="{'enableEdit':enableEdit}" [(ngModel)]="item.poz" disabled/></div>        
                                <div class="title"><input name="title" type="text" col="3" [ngClass]="{'enableEdit':enableEdit}" [(ngModel)]="item.title" disabled/></div>
                                <div class="qty">
                                    <fa-icon *ngIf="item.qty < 1" [icon]="['fad', 'plus']" (click)="addQty(i)"></fa-icon>
                                    <fa-icon *ngIf="item.qty > 0" [icon]="['fad', 'minus']" (click)="subQty(i)"></fa-icon>                                
                                    <input name="qty" class="input_right" type="text" col="4" [ngClass]="{'enableEdit':enableEdit}" [(ngModel)]="item.qty" />
                                </div>
                                <div class="price"><input name="price" class="input_right" type="text" col="5" [ngClass]="{'enableEdit':enableEdit}" [(ngModel)]="item.regular_price_formated" disabled/></div>
                                <div class="discount"><input name="discount" class="input_right" type="text" col="6" [ngClass]="{'enableEdit':enableEdit}" [(ngModel)]="item.discount" /></div>
                                <div class="total"><input name="total" class="input_right" type="text" col="7" [ngClass]="{'enableEdit':enableEdit}" [(ngModel)]="item.total" disabled/></div>
                                <div class="more" (click)="toggleDescription(i)"><fa-icon class="icon_small" [icon]="['fad', 'chevron-down']"></fa-icon></div>
                                <div class="short_description" [ngClass]="{'showDescription':item.showDescription}"><b>{{item.category}}</b> | {{item.type}}</div>
                                <div class="short_description" style="padding-bottom: 24px;" [ngClass]="{'showDescription':item.showDescription}" [innerHTML]="cFunction.ln2br(item.short_description)"></div>
                                <fa-icon class="icon_medium edit" [icon]="['fad', 'pen-to-square']" [ngClass]="{'showDescription':item.showDescription}" (click)="editItem(item.entity, item.category, item.type, item.short_description, item.poz, item.regular_price_formated, item.title)"></fa-icon>
                            </div>
                        </ng-container>
                    </ng-container>  
                </div>
            </div>
                
            <div class="bottom">
                <div class="left">
                    <div class="field">
                        <label class="switch" style="margin-top: 5px;">
                            <input type="checkbox" [(ngModel)]="hideEmpty">
                            <span class="slider round"></span>
                        </label>  
                        <div class="label label-checkbox">{{'Hide empty'|translate}}</div>
                    </div>
                </div>
                <div class="totals_right">
                    <div class="total_item" [ngClass]="{'showSummary':showItemsSummary}">
                        <input readonly name="quote_subTotal" type="text" [(ngModel)]="basic['subtotal']" disabled />
                        <div class="label">                            
                            {{'Sub total without discount'|translate}}
                        </div>
                    </div> 

                    <div class="total_item" [ngClass]="{'showSummary':showItemsSummary}">
                        <input name="general_dicount" type="text" [(ngModel)]="general_dicount" />
                        <div class="label">{{'Discount general'|translate}} (%)</div>
                    </div> 

                    <div class="total_item" style="display:block !important;">
                        <fa-icon *ngIf="showItemsSummary" (click)="showItemsSummary = !showItemsSummary" [icon]="['fad', 'chevron-up']"></fa-icon>
                        <fa-icon *ngIf="!showItemsSummary" (click)="showItemsSummary = !showItemsSummary" [icon]="['fad', 'chevron-down']"></fa-icon>
                        <input name="quote_grandTotal" type="text" [(ngModel)]="basic['grandtotal']" disabled />
                        <div class="label" style="width:200px">{{'Total with discount'|translate}}</div>
                    </div> 
                </div>
            </div>            
        </div>

        <!------------------------------------------------------->
        <div *ngIf="basic['category']!='' && basic['quote_id']!=''" id="static" class="tab-child" [ngClass]="{'active':selectedTab=='static'}">

            <ng-container *ngFor="let item of staticCustom; let i = index">
                <div *ngIf="i!=0" class="inline w100 divider"></div>

                <div class="inline w100 wPadding" style="padding: 0;">
                    <div class="inline w70">
                        <ng-container *ngIf="staticCustom[i]['open']==true">
                            <mat-form-field appearance="outline" class="wPadding">
                                <mat-label>{{'Block Text Header'|translate}}</mat-label>
                                <input type="text" matInput (keypress)="editField();" [(ngModel)]="staticCustom[i]['title']">
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="staticCustom[i]['open']!=true">
                            <div class="customTextDisabled" (click)="toggleStatic(i)">
                                {{staticCustom[i]['title']}}
                            </div>
                        </ng-container>
                    </div>

                    <div class="inline w30" style="position:relative;">
                        <ng-container *ngIf="staticCustom[i]['open']==true">
                            <mat-form-field appearance="outline" class="wPadding" style="width: calc(100% - 30px)">
                                <mat-label>{{'Category'|translate}}</mat-label>
                                <mat-select [(ngModel)]="staticCustom[i]['predefined']">
                                    <mat-option [value]="true">{{'System block'|translate}}</mat-option>
                                    <mat-option [value]="false">{{'Custom block'|translate}}</mat-option>
                                </mat-select>
                                <!--<mat-hint>{{staticCustom[i]['predefined']}}</mat-hint>-->
                            </mat-form-field>
                        </ng-container>
                        <div class="icon_small open_close_static_text" [ngClass]="{'disabled':staticCustom[i]['open']!=true}" (click)="toggleStatic(i)">
                            <fa-icon [icon]="['fad', 'chevrons-down']"></fa-icon>
                        </div>                        
                    </div>

                    <ng-container *ngIf="staticCustom[i]['open']==true">
                        <mat-form-field appearance="outline" class="wPadding">
                            <mat-label>{{'Introducing text...'|translate}}</mat-label>
                            <textarea matInput (keypress)="editField();" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" [(ngModel)]="staticCustom[i]['content']"></textarea>
                        </mat-form-field>

                        <div class="actions wPadding specialFieldButtons">                        
                            <button class="right" (click)="chooseTemplate(i)">{{'Choose Template'|translate}}</button>
                            <button class="right" (click)="saveTemplate(i)">{{'Save as template'|translate}}</button>
                            <button class="left warrning" (click)="removeTextBlock(i)">{{'Remove Block'|translate}}</button>                            
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <div class="inline w100 divider"></div>

            <div class="field wPadding specialButtons">
                <button class="left" (click)="setPredefinedOptions();">{{'Reset to Predefined'|translate}}</button>
                <button class="left" (click)="setDefaultOptions();">{{'Reset to Default'|translate}}</button>

                <button class="right action" (click)="addCustomStaticText();">{{'Add Text Block'|translate}}</button>                
                <button class="right" (click)="setBlocksAsDefault();">{{'Set as Default'|translate}}</button>
            </div>

           
        </div>

        <!------------------------------------------------------->
        <div *ngIf="basic['category']!='' && basic['quote_id']!=''" id="settings" class="tab-child" [ngClass]="{'active':selectedTab=='settings'}">
            
            <div class="inline w100">
                <label class="switch" style="margin-top: 5px;">
                    <input type="checkbox" [checked]="erpSettings['showPrices']" (change)="erpSettingsChange('showPrices', erpSettings['showPrices'])">
                    <span class="slider round"></span>
                </label>  
                <div class="label label-checkbox">{{'Show item prices'|translate}}</div>
            </div>

            <div class="inline w100">
                <label class="switch" style="margin-top: 5px;">
                    <input type="checkbox" [checked]="erpSettings['quoteSummary']" (change)="erpSettingsChange('quoteSummary', erpSettings['quoteSummary'])">
                    <span class="slider round"></span>
                </label>  
                <div class="label label-checkbox">{{'Quote Summary'|translate}}</div>
            </div>

            <div class="inline w100">
                <label class="switch" style="margin-top: 5px;">
                    <input type="checkbox" [checked]="erpSettings['purchaseOrder']" (change)="erpSettingsChange('purchaseOrder', erpSettings['purchaseOrder'])">
                    <span class="slider round"></span>
                </label>  
                <div class="label label-checkbox">{{'Purchase Order'|translate}}</div>
            </div>

            <div class="inline w100">
                <label class="switch" style="margin-top: 5px;">
                    <input type="checkbox" [checked]="erpSettings['splitPage']" (change)="erpSettingsChange('splitPage', erpSettings['splitPage'])">
                    <span class="slider round"></span>
                </label>  
                <div class="label label-checkbox">{{'Each static text on separated page'|translate}}</div>
            </div>
            
        </div>
    </div>

    <div *ngIf="selectedTab!='settings'" class="modalActions">
        <button class="left" (click)="close()">{{'Close'|translate}}</button>
        <button *ngIf="basic['category']!='' && basic['quote_id']!=''" class="right accept" (click)="save()">{{'Save'|translate}}</button>      
        <button *ngIf="basic['category']!='' && basic['quote_id']!=''" class="right accept" (click)="saveAndClose()">{{'Save and Close'|translate}}</button>  
        <!--<button *ngIf="basic['entity']!=null" class="right warrning" (click)="delete()">{{'Delete'|translate}}</button>-->
    </div>
</div>

<!-- *** -->
<div *ngIf="templateChooser" class="wizzard_container">
	<div class="wizzard_selector">
		<div class="wizzard_top">
            <div class="wizzard_item cancel" (click)="templateChooser=false">{{'Cancel'|translate}}</div>
        </div>		
        <div class="wizzard_wrapper">
            <div class="wizzard_container_items">
                <ng-container *ngFor="let item of textTexmplates; let i = index">
                    <div class="wizzard_item" (click)="chooseTextTemplate(i)" [attr.index]="i">
                        {{item['title']}}<br />
                        <span>{{item['text']}}</span>
                    </div>
                    <div class="wizzard_item_delete">
                        <fa-icon [icon]="['fad', 'trash-alt']" style="font-size: 24px" (click)="deleteTextTemplate(item['entity'], i)"></fa-icon>
                    </div>
                </ng-container>		            
            </div>
        </div>
	</div>
</div>