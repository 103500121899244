/* Angular */
import { Component, OnInit, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CdkDragDrop, CdkDragMove } from "@angular/cdk/drag-drop";
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { UploadService } from '../services/upload.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';

/* Services */
import { DataService } from '../services/data.service';
import { GlobalVariables } from '../services/data.globals';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctions } from '../services/common.functions';
import { NavigationService } from '../services/navigation.service';
import { MembersettingsService } from '../services/membersettings.service';
//import { PdfConverterService } from '../services/pdf-converter.service';

/* Framework */
import { QuickViewComponent } from '../framework/quick-view/quick-view.component';

/* Sub/Child Components */
import { DocumentEditComponent } from './document-edit/document-edit.component';
import { AssignUserGroupComponent } from '../activities/activity/assign-user-group/assign-user-group.component';

/* Modal */
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-document-center', 
  templateUrl: './document-center.component.html',
  styleUrls: ['./document-center.component.css']
})
 
//@Injectable({providedIn: 'root'}); /* download */

export class DocumentCenterComponent implements OnInit {

  public member_groups = [];
  public members = [];
  public admin_rights = false;
  
  public imageSrc: string;  
  public sortType: "down_az" | "down_19" | "up_az" | "up_19" = "down_az";

  
  //files: any[] = [];
  public originalCategories = [];
  public document_categories = [];
  public activity_categories = [];
  public customer_categories = []; 
  public documents = []; 
  public search_documents = [];
  public search = null;
  public search_documents_found = 0;
  public hideEmpty = false;
  public files0 = {};
  public categories;
  public category_id;
  public category;  
  public levelPath = ['root'];  
  public items = {};
  public searchterm;
  private storage = null;
  public toggleFoldersView = false;
  
  public component = 'document-center';
  public memberAllowance = null;
	public lastFunctionCallAt = Date.now(); 
	public memberSettingsGlobal = null;

  /* file Browser */
  public folder = null;
  public folder_sorting = "down_az";
  public group = null;
  public folders = [];
  public groupedFolders = {
    'by activity' : { _edit: false, _icon: "list", _name: "", _opened: false, _path: 'by activity', _selected: false, _function: 'activity' },
    'by document' : { _edit: false, _icon: "file-circle-question", _name: "", _opened: false, _path: 'by document', _selected: false, _function: 'document' },
    'by customer' : { _edit: false, _icon: "file-user", _name: "", _opened: false, _path: 'by customer', _selected: false, _function: 'customer' },
    'by last' : { _edit: false, _icon: "timer", _name: "", _opened: false, _path: 'by last', _selected: false, _function: 'last' },
    'by search' : { _edit: false, _icon: "magnifying-glass", _name: "", _opened: false, _path: 'by search', _selected: false, _function: 'search' }
  }
  public folder_tree = false;
  public smart = true;  
  public order = 'desc';
  public files = [];
  public current_file = '';
  public asComponent = false;
  public sortBy = 'doc_title';

  /* sort anfd view */
  public selected = null;
  public grid = true;
  public opened = null;
  public byName = true;
  public byDate = false;
  public systemGroup = true;
  public smartGroup = false;

  public refreshPreData_field = null;
  public refreshPreData_value = null;
  public refreshPreData_items = 1000;
  public refreshPreData_search = null;
  public searchOpen = false;

  public imgUrl = null;
  public docUrl = null;

  public editable = false;
  public creatable = false;

  public dropToFolder = null;

  public folderFilterObject = {'by activity':'', 'by customer':'', 'by document': ''};

  public topNavigation = [
    { entity: "app.document-center.view", exposed: false, component: "document-center", title: "View", subTitle: null, subTitleColor: null, type: "link", action: "", actionData: null, icon: "", iconColor: null, pinnable: false, refreshable: false,
			submenu: [				
        { entity: "app.document-center.viewRefresh", exposed: false, component: "document-center", title: "Refresh View", subTitle: null, subTitleColor: null, type: "localFunction", action: "refresh", actionData: null, icon: "fad,arrows-rotate", iconColor: null, pinnable: true, refreshable: false }
			]
		},
    { entity: 'function:localSearch', enabled: true }
  ];

  /* UPLOADER */
  private _openDialog() {
		if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) return;		
	}

  constructor(
    private data: DataService,
    private http: HttpClient,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public translate: TranslateService,
    private UploadService: UploadService,
    public cFunction: CommonFunctions,
    private domSanitizer: DomSanitizer,
		public NavigationService: NavigationService,
		public MembersettingsService: MembersettingsService,
    public assignUserGroupDialog: MatDialog/*,    
    private pdfConverterService: PdfConverterService*/    
  ) {
    translate.addLangs(['en', 'si']); 
  }

  chekcForDocumentPreview(ext) {
    let previewExt = ['pdf', 'PDF'];
    return previewExt.includes(ext) ? true : false;
  }

  previewAvailable(ext) {
    let previewExt = ['jpg', 'JPG', 'jpeg', 'JPEG', 'gif', 'GIF', 'png', 'PNG', 'pdf', 'PDF'];
    return previewExt.includes(ext) ? true : false;
  }

  toString(value: unknown): string {
    return value != null ? String(value) : '';
  }

  showFilesOrder() {
   // console.log(this.files);
  }

  /*****************************************/
  /* Moving Panel */

  leftPaneWidth = 15;
  private isDragging = false;

  onDragStartPanels(event: MouseEvent) { this.isDragging = true; event.preventDefault(); }

  @HostListener('window:mousemove', ['$event']) 
  onDragMovePanels(event: MouseEvent) {
    if (this.isDragging) {      
      const containerWidth = window.innerWidth;
      const newLeftPaneWidth = (event.clientX / containerWidth) * 100;
      if (newLeftPaneWidth > 10 && newLeftPaneWidth < 50) { 
        this.leftPaneWidth = newLeftPaneWidth;        
      }      
    }
  }

  @HostListener('window:mouseup') onDragEndPanels() { 
    this.isDragging = false;
    this.MembersettingsService.setMemberSettings('documentmanagerSeparator', this.leftPaneWidth);
  }

  /***************************************/
  /* Helpers */
  checkIfParameter(test) {
    if (test.charAt(0) == '_') { return false; } else { return true; }
  } //checkIfParameter

  /***************************************/
  /* FILES manipulation functions */
  downloadFile(filename, original_filename) {   
    this.data.downloadDocument(filename, original_filename);   
  } //download
    
  /***************************************/
  /* FILES favorite functions */
  favorite(filename) {
   // console.log(filename);
  } //download

  /***************************************/
  /* FOLDER manipulation functions */
  mouseOverFolder(path) {
    this.dropToFolder = path;
  }

  haveSubFolders(referenceFolder: any) {    
    return Object.keys(referenceFolder.value).filter(key => !key.startsWith('_')).length > 0 ? true : false;    
  };

  clearFolderSelection(folders) {
    for (const key in folders) {      
      if (folders[key]['_selected']!=undefined) { folders[key]['_selected'] = false; }
      if (folders[key]['_edit']!=undefined) { folders[key]['_edit'] = false; }
      if (typeof folders[key] === "object" && folders[key] !== null) { folders[key] = this.clearFolderSelection(folders[key]); }      
    }   
    return folders;      
  } //clearFolderSelection
  
  onFolderClick(path, skip=false) {        
    this.group = null;
    if (path == 'undefined' || path == 'Undefined' || path == this.translate.instant('Undefined') || path == this.translate.instant('Undefined')) {
      path = "undefined";
    }    
    
    let pathHelper = path.split('/');    
    if (this.folders[pathHelper[0]]['_creatable']==true) {
      this.MembersettingsService.setMemberSettings('documentmanager', "folder|" + path);      
    } else {
      this.MembersettingsService.setMemberSettings('documentmanager', "restricted|" + path);      
    }
    
    this.clearFolderSelection(this.folders);
    this.clearGroupSelected();

    var pathArray = path.split('/');
    for (let i=pathArray.length-2; i>=0; i--) {      
      let finalHelper = "";
      for (let j=0; j<=i; j++) { finalHelper += finalHelper != "" ? "/"+pathArray[j] : pathArray[j]; }
      this.cFunction.setObjectValue(this.folders, finalHelper+'/_selected', false);      
      if (skip != true) {
        this.cFunction.setObjectValue(this.folders, finalHelper+'/_opened', true);
      }
    }

    this.selected = !this.cFunction.getObjectValue(this.folders, path+'/_selected');
    this.cFunction.setObjectValue(this.folders, path+'/_selected', this.selected);

    if (skip != true) {      
      this.opened = !this.cFunction.getObjectValue(this.folders, path+'/_opened');
      this.cFunction.setObjectValue(this.folders, path+'/_opened', this.opened);    
    }
    this.folder = path;

    let topLevel = (this.folder).split('/')[0];

    this.editable = this.folders[topLevel]['_editable'];
    this.creatable = this.folders[topLevel]['_creatable'];
        
   // console.log('******************');
   // console.log(path, skip);
   // console.log(this.folders);
   // console.log(this.folder);

    this.refreshFiles('folder', path, 10000, null);   
    //return true; 
  } //onFolderClick

  /********/
  clearGroupSelected() {   
    for (const key in this.groupedFolders) {
      this.groupedFolders[key]['_opened'] = this.groupedFolders[key]['_selected'] = this.groupedFolders[key]['_edit'] = false;      
    }    
  } 

  clearGroupSelectedDataOnly(path) {    
    Object.keys(this.groupedFolders[path]).forEach(key => {        
      if (!key.startsWith('_')) {                
        if (this.groupedFolders[path][key]['_selected'] !== undefined) { this.groupedFolders[path][key]['_selected'] = false; }
      }
    });    
  } 


  onGroupClickSelector(_path) {        
    this.selected = !this.cFunction.getObjectValue(this.groupedFolders, _path+'/_selected');    
    this.cFunction.setObjectValue(this.groupedFolders, _path+'/_selected', this.selected);    
    this.folder = _path;
  } 
  
  onGroupClick(_path, _function, _entity, _directCall = false, refreshGroup = false) {    
    
    let pathArray = _path.split('/'); 
    let pathHelper = _path.split('/');

    if (!refreshGroup) {            
      let statusHelper = this.cFunction.getObjectValue(this.groupedFolders, _path+'/_opened');
      this.folder = null;
      if (_path.indexOf('/') > -1) {
        this.group = _path.replace("/", " / ");
      } else {
        this.group = null;
      }    
      this.refreshPreData_field = "group";
      let helper = "group|"+_path+"|"+_function+"|"+_entity;
      this.data.setMemberSettings({'parameter': 'documentmanager', 'value': helper}).subscribe((res: Response) => {
        this.updateSettings();      
      });	

      if (pathArray.length == 1) this.clearGroupSelected();
      if (pathArray.length > 1) this.clearGroupSelectedDataOnly(pathArray[0]);
      this.clearFolderSelection(this.folders);

      for (let i=pathArray.length-2; i>=0; i--) {      
        var finalHelper = "";
        for (let j=0; j<=i; j++) {
          finalHelper += finalHelper != "" ? "/" + pathArray[j] : pathArray[j];        
        }            
        if (pathArray.length == 1) this.cFunction.setObjectValue(this.groupedFolders, finalHelper+'/_selected', false);
      }
    
     // console.log(pathArray.length);

      if (pathArray.length == 1) this.onGroupClickSelector(_path);
        this.cFunction.setObjectValue(this.groupedFolders, _path+'/_opened', !statusHelper);
      
      if (_function == 'last') {            
        this.refreshFiles(null, null, 50);
      }      
    }

    /* by document type */
    if (_function == 'document') {
      if (_entity == undefined || _directCall || refreshGroup) {        
        this.data.getDocumentsType({ 'page' : 0, 'items' : 10000, 'order' : 'doc_type', 'orderby' : 'desc', search: this.folderFilterObject['by document'], filter : null }).subscribe(
          (res: Response) => {
            let documents = (<any>res).rows;	            
            for (let i=0; i<documents.length; i++) {
              this.groupedFolders['by document'][documents[i]['doc_type']] = {};
              this.groupedFolders['by document'][documents[i]['doc_type']] = { _edit: false, _icon: "file-lines", _name: "", _opened: false, _path: 'by document/'+documents[i]['doc_type'], _selected: false, _function: 'document', _entity: documents[i]['entity'] };
              if (documents[i]['doc_type'] == pathArray[1]) _entity = documents[i]['entity'];
            }            
            if (_directCall) this.refreshFiles('t1.doc_type', _entity);
            if (refreshGroup) {
              this.onGroupClick(_path, _function, null);              
            }
            document.getElementById(_function).focus();
          }
        )
      } else {
        this.refreshFiles('t1.doc_type', _entity);
        this.groupedFolders[pathHelper[0]][pathHelper[1]]['_selected'] = true;
      }
    }

    /* by activity type */    
    if (_function == 'activity') {
      if (_entity == undefined || _directCall || refreshGroup) {        
        this.data.getActivityTypes({ 'page' : 0, 'items' : 10000, 'order' : 'description', 'orderby' : 'desc', search: this.folderFilterObject['by activity'], filter : null }).subscribe(
          (res: Response) => {
            let activities = (<any>res).rows;	                           
            for (let i=0; i<activities.length; i++) {
              this.groupedFolders['by activity'][activities[i]['description']] = {};
              this.groupedFolders['by activity'][activities[i]['description']] = { _edit: false, _icon: "file-lines", _name: "", _opened: false, _path: 'by activity/'+activities[i]['description'], _selected: false, _function: 'activity', _entity: activities[i]['entity'] };
              if (activities[i]['description'] == pathArray[1]) {                
                _entity = activities[i]['entity'];
              }
            }            
            if (_directCall) this.refreshFiles('t4.message_type', _entity);
            if (refreshGroup) {
              this.onGroupClick(_path, _function, null);                
            } 
            document.getElementById(_function).focus();
          }
        )
      } else {        
        this.refreshFiles('t4.message_type', _entity);
        this.groupedFolders[pathHelper[0]][pathHelper[1]]['_selected'] = true;        
      }
    }
    
    /* by customer */
    if (_function == 'customer') {
      ////console.log(this.folderFilterObject['by customer']);
      if (_entity == undefined || _directCall || refreshGroup) {
        this.data.getCustomers({ 'page' : 0, 'items' : 10000, 'order' : 't1.company', 'orderby' : 'desc', search: this.folderFilterObject['by customer'], filter : null }).subscribe(
          (res: Response) => {
            let customers = (<any>res).rows;            
            if (refreshGroup) this.groupedFolders['by customer'] = { _edit: false, _icon: "file-user", _name: "", _opened: false, _path: 'by customer', _selected: false, _function: 'customer' };
            for (let i=0; i<customers.length; i++) {
              this.groupedFolders['by customer'][customers[i]['company']] = {};
              this.groupedFolders['by customer'][customers[i]['company']] = { _edit: false, _icon: "file-user", _name: "", _opened: false, _path: 'by customer/'+customers[i]['company'], _selected: false, _function: 'customer', _entity: customers[i]['entity'] };
              if (customers[i]['company'] == pathArray[1]) _entity = customers[i]['entity'];
            }               
            if (_directCall) this.refreshFiles('t1.customer', _entity);
            if (refreshGroup) {
              this.onGroupClick(_path, _function, null);              
            }
            document.getElementById(_function).focus();
          }          
        )
      } else {        
        this.refreshFiles('t1.customer', _entity);
        this.groupedFolders[pathHelper[0]][pathHelper[1]]['_selected'] = true;
      }
    }
    //console.log(this.groupedFolders);
  } //onGroupClick

  setupFolder() {
    if (!this.folders[(this.folder.split("/"))[0]]?.['_editable']) {
      let content = this.translate.instant("You do not have rights to edit this folder?");      
      let modalDialog = this.dialog.open(ModalComponent, {
        data: { content: content, yes: this.translate.instant('OK'), no: null, cancel: null }
      });
      return false;
    } 

    if (this.folder.split("/").length<2) {
      let content = this.translate.instant("Krovne mape ne moreš preimenovati?");      
      let modalDialog = this.dialog.open(ModalComponent, {
        data: { content: content, yes: this.translate.instant('OK'), no: null, cancel: null }
      });
      return false;
    }

    let map = this.folder;
    let currentMap = this.folder.split("/").pop();
    let currentMapUnchanged = this.folder.split("/").slice(0, -1).join("/");
    
    let modalDialog = this.dialog.open(ModalComponent, {
      data: { content: this.translate.instant('Change folder name of current map:')+' '+currentMap, yes: this.translate.instant('Rename'), no: null, cancel: this.translate.instant('Cancel'), inputText: currentMap }
    });  

    modalDialog.afterClosed().subscribe(result => {      
      if (result['yes']!=undefined) {    
        if (result['value']!=undefined) {
          let newmap = map.replace(currentMap, result['value']);
          //alert(map + " > " + newmap);
          this.data.renameFolder({'folderOld' : map, 'folderNew' : newmap}).subscribe((res: Response) => {
            this.getFolders();
            setTimeout(() => { this.onFolderClick(currentMap); }, 2000); 
          });        
        } else {
          let content = this.translate.instant("Napaka pri nzaivu mape");      
          let modalDialog = this.dialog.open(ModalComponent, {
            data: { content: null, yes: this.translate.instant('OK'), no: null, cancel: null }
          });      
        }
      }      
    });    

    return true;
  }

  deleteFolder() {    
    if (!this.folders[(this.folder.split("/"))[0]]?.['_editable']) {
      let content = this.translate.instant("You do not have rights to delete this folder?");      
      let modalDialog = this.dialog.open(ModalComponent, {
        data: { content: null, yes: this.translate.instant('OK'), no: null, cancel: null }
      });  
      return false;
    }    

    let content = "";
    if ((this.folder).includes('/')) {
      let folder = (this.folder).split('/');
      content = this.translate.instant('Are you sure that you want to permanently remove the folder ') + folder[folder.length-1] + "?";
    } else {
      content = this.translate.instant('Are you sure that you want to permanently empty the folder ') + this.folder + this.translate.instant(" (All files will be parmanently deleted)? ") + this.translate.instant("<br />The actual folder will remain as it cannot be removed. You need to remove the e-Archive category for that.");
    }

    let modalDialog = this.dialog.open(ModalComponent, {
      data: { content: content, yes: this.translate.instant('Delete'), no: null, cancel: this.translate.instant('Cancel') }
    });  

    modalDialog.afterClosed().subscribe(result => {      
      if (result['yes']!=undefined) {        
        this.data.deleteFolder({data: { folder: this.folder } }).subscribe(
          (res: Response) => {
            let folder = (this.folder).split('/');
            if (folder.length > 1) folder.splice(-1, 1);
            this.getFolders();
            this.onFolderClick(folder.join("/"));
            //this.refreshFiles('folder', folder.join("/"), 10000, null);            
          }				
        );     
      }
      return false;
    });

    return true;
  }

  folderFilter(id, event) {
    this.onGroupClick(id, id.split(' ')[1], null, false, true);
  }

  checkIfPathExists(path: string, obj: any = this.folders): boolean {
    const parts = path.split('/');
    let current = obj;
  
    for (const part of parts) {
      if (current[part] === undefined) {
        return false; 
      }
      current = current[part]; 
    }
  
    return true;
  }

  addFolder(folderName = this.translate.instant('New folder')) {    
        
    if (!this.folders[(this.folder).split('/')[0]]['_creatable']) {
      let content = this.translate.instant("You do not have rights to create new folder");      
      let modalDialog = this.dialog.open(ModalComponent, {
        data: { content: content, yes: this.translate.instant('OK'), no: null, cancel: null }
      });  
      return false;
    } 
    
        
    let content = this.translate.instant('Input name of new sub folder');
    let modalDialog = this.dialog.open(ModalComponent, {
      data: { content: content, yes: this.translate.instant('Create'), no: null, cancel: this.translate.instant('Cancel'), inputText: folderName }
    });

    modalDialog.afterClosed().subscribe(result => {      
      if (result['yes']!=undefined) {
        if (result['value']!=undefined) {          
          this.clearFolderSelection(this.folders);
          let helper = {};
          let name = result['value'];
          helper['_selected'] = helper['_opened'] = true;
          helper['_edit'] = false;
          helper['_icon'] = "folder-plus";
          helper['_path'] = this.refreshPreData_value == '' ? name : this.refreshPreData_value + "/" + name;
          helper['_name'] = name;
          helper['_editable'] = true;
          helper['_creatable'] = true;

          if (!this.checkIfPathExists(helper['_path'])) {
            if (this.refreshPreData_value == '') {
              this.cFunction.setObjectValue(this.folders, name, helper);
            } else {
              this.cFunction.setObjectValue(this.folders, this.refreshPreData_value + "/" + name, helper);
            }             
            this.onFolderClick(helper['_path']);
          } else {
            let content = this.translate.instant('Folder with this name already exist.') + " (" + name + ")";
            let modalDialogError = this.dialog.open(ModalComponent, {
              data: { content: content, yes: this.translate.instant('Change'), no: null, cancel: this.translate.instant('Cancel') }
            });
            modalDialogError.afterClosed().subscribe(result => {      
              if (result['yes']!=undefined) {
                this.addFolder(name);
              }
            });
          }
        }
      }
    });

    return true;
  } /* addFolder */

  saveFolder(event, path) {    
    this.clearFolderSelection(this.folders);
    var helper = this.cFunction.getObjectValue(this.folders, path);  
    this.cFunction.deleteObjectValue(this.folders, path);    
    var old_name_helper = (helper['_path']).split('/');
    var old_name = old_name_helper[old_name_helper.length-1];
    var old_path = path;     
    var new_path = path.replace(old_name, event.target.value);  
    helper['_edit'] = false;
    helper['_selected'] = true;
    helper['_opened'] = false;
    helper['_name'] = event.target.value;
    helper['_path'] = new_path;        
    this.cFunction.setObjectValue(this.folders, new_path, helper);
    this.folder = new_path;    

    this.current_file = new_path.replace('/', ' / ') + " / ";
    var parth_array = new_path.split('/');

    for (var i=0; i<this.categories.length; i++) {
      if (parth_array[0].toLowerCase() == this.categories[i].description.toLowerCase()) {
        this.category_id = this.categories[i].entity;
        this.category = this.categories[i].description;  
      }      
    }
  } /* savefolder */

  editFolder() {
    this.clearFolderSelection(this.folders);
    this.cFunction.setObjectValue(this.folders, this.folder+'/_edit', true);
  } //editFolder

  /****************************************/
  /****************************************/
  displayImage(filename) {    
    if (filename == null) return "";
    return "https://api.biliz.com/storage/" + this.storage + "/" + filename.charAt(0) + "/" + filename.charAt(1) + "/" + filename;	    
  } //displayImage

  displayThumbnail(filename) {    
    if (filename == null) return "";
    let filenameHelper = filename.split('.');
    filename = filenameHelper.slice(0, -1).join('.') + ".jpg";
    return "https://api.biliz.com/storage/" + this.storage + "/thumbnails/" + filename.charAt(0) + "/" + filename.charAt(1) + "/" + filename;	    
  } //displayImage

  imgPreview(url) {    
    //document.getElementById("mainView").style.zIndex = "9999";
    //this.imgUrl = this.displayImage(url);
  } //imgPreview

  closeImgPreview() {
    //this.imgUrl = null;
    //document.getElementById("mainView").style.zIndex = "1";
  } //closeImgPreview

  refresh() {
    this.refreshFiles('folder', this.folder, 10000, null);    
  }

  /****************************************/
  /****************************************/
  docPreview(url) {        
    //console.log(url);
    var quickViewDialog = this.dialog.open(QuickViewComponent, { 
      'minWidth' : '92vw',      
      'minHeight' : '92vh' ,
      data : { 
        type: 'url',
        value: url,
        values: null                
      } 
    });
		quickViewDialog.afterClosed().subscribe(result => {			
		});    
    /*
    if (ext == 'pdf' || ext == 'PDF') {      
      document.getElementById("mainView").style.zIndex = "9999";
      this.docUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.displayImage(url));
    }
    */
  } //docPreview

  closeDocPreview() {
    /*
    this.docUrl = null;
    document.getElementById("mainView").style.zIndex = "1";
    */
  } //closeDocPreview

  /******************************************************************/
  /******************************************************************/
  public getPdfThumbnail(pdfUrl: string) {
    let imgThumbnail = pdfUrl.replace('.pdf', '.png');
   // console.log(pdfUrl);
  } //getPdfThumbnail

  /***********************************/
  updateSettings() {
    this.data.refreshMemberSettings();
  } //updateSettings

  switchSearch() {
    this.searchOpen = !this.searchOpen;
  } //switchSearch

  countObject(objectVariable) {      
    var countObjectKeys = 0;
    for (const key in objectVariable['value']) {      
      if (key.substring(0, 1) != "_") {
        countObjectKeys++;
      }
    }
    return countObjectKeys;
  } //countObject

  fileViewType(value) {
    this.data.setMemberSettings({'parameter': 'fileViewType', 'value': this.cFunction.booleanToStringExtended(value)}).subscribe((res: Response) => {});	 
  } //fileViewType
  
  setFolderSorting() {    
    if (this.sortType == "down_az") {
      this.sortType = "down_19";
    } else if (this.sortType == "down_19") {
      this.sortType = "up_az";
    } else if (this.sortType == "up_az") {
      this.sortType = "up_19";
    } else {
      this.sortType = "down_az"
    }
    this.getFolders();
    this.data.setMemberSettings({'parameter': 'treeFolderSorting', 'value': this.sortType}).subscribe((res: Response) => {});	 
  }


  editFile(entity) {          
    var editDialog = this.dialog.open(DocumentEditComponent, { 'width' : '800px', data : { entity: entity } });
		editDialog.afterClosed().subscribe(result => {	    
			this.refreshFiles('folder', this.folder, 10000, null);
		});    
  }

  deleteFile(key) {
    var content = this.translate.instant('Do you want parmenently delete file?');
    var modalDialog = this.dialog.open(ModalComponent, {
      data: { content: content, yes: this.translate.instant('Delete') , no: null, cancel: this.translate.instant('Cancel') }
    });

    modalDialog.afterClosed().subscribe(result => {
      if (result['yes']!=undefined) {
        var entity = this.files[key].entity; 
        var folder = this.files[key].folder;
        this.files.splice(key,1);
        this.data.deleteDocument({ entity : entity }).subscribe(
          (res: Response) => {        
            this.data.getDocuments({'items' : 10000, 'order' : 'doc_title', 'orderby' : 'asc', search: null, 'field' : 'folder', 'value' : folder}).subscribe(
              (res: Response) => {
                this.files = (<any>res).rows;    
                console.log(this.files);
              }
            );
          }
        );
      }
    });
  } //deleteFile

  /*******************/
  /* search for file */
  searchForFile(event: any) {    
    if ((event.target.value).length > 3) {      
      this.refreshFiles(null, null, 50, event.target.value);
      this.onGroupClick('by search', 'search', null);
      this.folder = this.translate.instant('Search results') + ' > ' + event.target.value;
      this.systemGroup = false;
      this.smartGroup = true;
    }
  }

  /*****************/
  /* Decimal sorting */
  sortByDecimalClassificationAsc(items: any[]): any[] {
    const getDecimalPart = (original_filename: string | null | undefined): number | null => {
      if (!original_filename) return null; // Zaščita pred undefined/null
  
      const match = original_filename.match(/^\d+(\.\d+)?/); // Ujemi tudi decimalke
      return match ? parseFloat(match[0]) : null; // Vrne številko ali `null`
    };
  
    // Razdeli na dve skupini: številčne in črkovne
    const numericItems = items.filter(item => getDecimalPart(item.original_filename) !== null);
    const textItems = items.filter(item => getDecimalPart(item.original_filename) === null);
  
    // Sortiraj številčne elemente po številki
    numericItems.sort((a, b) => getDecimalPart(a.original_filename)! - getDecimalPart(b.original_filename)!);
  
    // Sortiraj črkovne elemente po abecedi
    textItems.sort((a, b) => a.original_filename.localeCompare(b.original_filename, undefined, { numeric: true }));
  
    // Združi sezname: najprej številčni, nato črkovni
    return [...numericItems, ...textItems];
  }
  
    

  sortByDecimalClassificationDesc(items: any[]): any[] {

      const getDecimalPart = (original_filename: string | null | undefined): number | null => {
        if (!original_filename) return null; // Zaščita pred undefined/null
    
        const match = original_filename.match(/^\d+(\.\d+)?/); // Ujemi tudi decimalke
        return match ? parseFloat(match[0]) : null; // Vrne številko ali `null`
      };
    
      // Razdeli na dve skupini: številčne in črkovne
      const numericItems = items.filter(item => getDecimalPart(item.original_filename) !== null);
      const textItems = items.filter(item => getDecimalPart(item.original_filename) === null);
    
      // Sortiraj številčne elemente po številki
      numericItems.sort((a, b) => getDecimalPart(a.original_filename)! - getDecimalPart(b.original_filename)!);
    
      // Sortiraj črkovne elemente po abecedi
      textItems.sort((a, b) => a.original_filename.localeCompare(b.original_filename, undefined, { numeric: true }));
    
      // Združi sezname: najprej številčni, nato črkovni
      return [...numericItems, ...textItems];
    }
    
    

  /*****************/
  /* refresh files */
  refreshFiles(field = null, value = null, items = 10000, search = null) {   

    this.MembersettingsService.setMemberSettings('documentmanagerOrder', this.order);
    this.MembersettingsService.setMemberSettings('documentmanagersortBy', this.sortBy);

    if (value == 'undefined') { value = null; field = null }
    if (field == 'group') field = null;
    this.refreshPreData_field = field;
    this.refreshPreData_value = value;
    this.refreshPreData_items = items;
    this.refreshPreData_search = search;

    if (search != null) {
      let searchInstruction = search.split("_");
      if (searchInstruction[0] == 'activity') {
        value = searchInstruction[1];
        field = 'activity';
      }
    }      
       
    //console.log({'items' : items, 'order' : this.sortBy, 'orderby' : this.order, search: search, 'field' : field, 'value' : value});
    this.data.getDocuments({'items' : items, 'order' : (this.sortBy).split('|')[0], 'orderby' : this.order, search: search, 'field' : field, 'value' : value}).subscribe(
      (res: Response) => {
        this.files = (<any>res).rows;     
        console.log(this.files);
        //alert(this.sortBy);
        if ((this.sortBy).split('|')[1] != undefined) {
          if ((this.sortBy).split('|')[1] == "decimal") {            
            if (this.order == 'asc') {              
              this.files = this.sortByDecimalClassificationAsc(this.files);           
            } else {
              this.files = this.sortByDecimalClassificationDesc(this.files);           
            }
          }
        }        
      }
    );
  } /* refresh files */



  /******************************************************************/
	/******************************************************************/
	/* Uploader 			        	                                      */
	public files_for_upload: any[] = [];
	public selectedFiles: FileList;
  public currentFile: File;
  public progress = 0;  
  public fileInfos: Observable<any>;
  private document_details;

	prepareFilesList(files_for_upload: Array<any>) {
		for (const item of files_for_upload) {
			item.progress = 0;
			this.files_for_upload.push(item);
		}
		this.uploadFiles(0);
	}

	onFileDropped($event) {    
		this._openDialog();
		    
    const element = document.getElementsByClassName("file_container_document_center")[0] as HTMLElement;
    element.style.visibility = "hidden";
    element.style.opacity = "0";    
    const element2 = document.getElementsByClassName("dropdowninfo")[0] as HTMLElement;
    element2.style.visibility = "";  
    
		var editDialog = this.dialog.open(DocumentEditComponent, { 'width' : '800px', data : { files_for_upload : $event, prefill : { folder: this.folder, call: 'filemanager', customer: null, customer_id: null, entity: null, title: null, category: '?', category_id: '?'} } });
		editDialog.afterClosed().subscribe(result => {	    
			if (result != undefined && result != '') {        
        this.document_details = result.value;
        this.document_details['folder'] = this.folder; //force to upload in current folder
				this.prepareFilesList($event);
			} else {				
				this.files_for_upload = [];	
			}
		});		     
	}

	closeDropField() {
		const element = document.getElementsByClassName("file_container_document_center")[0] as HTMLElement;
		element.style.visibility = "hidden";
		element.style.opacity = "0";    
		const element2 = document.getElementsByClassName("dropdowninfo")[0] as HTMLElement;
		element2.style.visibility = "";
		this.files = [];    
	}

	fileBrowseHandler(files) {		
	}

	delete(index: number) {
		this.files.splice(index, 1);
	}
  
  uploadFiles(index: number) {
    
		this.upload(this.files_for_upload[index]);
		setTimeout(() => {
			if (index === this.files_for_upload.length) {
				(document.getElementsByClassName("file_container_document_center")[0] as HTMLElement).style.visibility = "hidden";
				(document.getElementsByClassName("file_container_document_center")[0] as HTMLElement).style.opacity = "0";    
				(document.getElementsByClassName("dropdowninfo")[0] as HTMLElement).style.visibility = "";       
				this.files_for_upload = [];
        //this.getActivity(this.id);
        this.data.getDocuments({'items' : 10000, 'order' : 'doc_title', 'orderby' : 'asc', search: null, 'field' : 'folder', 'value' : this.folder}).subscribe(
          (res: Response) => {
            this.files = (<any>res).rows;        
          }
        );
				return;
			} else {
				const progressInterval = setInterval(() => {
					if (this.files_for_upload[index] != undefined) {
						if (this.files_for_upload[index].progress === 100) {
							clearInterval(progressInterval);	            
							this.uploadFiles(index + 1);            
						} else {
							this.files_for_upload[index].progress = this.progress;	            
						}
					}
				}, 50);
			}
		}, 50);		
	}

	/* upload 1 file at once */
	upload(currentFile): void {
		this.progress = 0;
		this.UploadService.upload(currentFile).subscribe(	    
			event => {
				if (event.type === HttpEventType.UploadProgress) {
					this.progress = Math.round(100 * event.loaded / event.total);	 	        
				} else if (event instanceof HttpResponse) {
					if (event.body.name != undefined) {
						this.document_details['doc_file'] = event.body['name'];
						this.document_details['doc_code'] = event.body['original_name'];
						this.document_details['doc_size'] = currentFile.size;	        						
						this.data.saveDocument(this.document_details).subscribe(
							(res: Response) => {}				
						);
					}
				}
			},
			err => {
				this.progress = 0;
				//this.message = 'Could not upload the file!';
				currentFile = undefined;
			}
		);
		this.selectedFiles = undefined;
	}

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  } 

  /*******************************************/
  /*******************************************/
  /* drag and drop */
  drop(event: CdkDragDrop<string[]>) {    
    ////console.log(this.folders[this.folder]['_creatable']);    
    let masterFolder = this.dropToFolder.split('/');    
    let folder = this.folder.split('/');    

    ////console.log(folder[0]);
    ////console.log(this.folders[folder[0]]['_creatable']);

    if (!this.folders[masterFolder[0]]['_creatable'] || !this.folders[folder[0]]['_creatable']) {
      let content = this.translate.instant("You do not have rights to upload/remove file to/from selected folder ("+this.dropToFolder+" / "+this.folder+")");      
      let modalDialog = this.dialog.open(ModalComponent, {
        data: { content: content, yes: this.translate.instant('OK'), no: null, cancel: null }
      });  
      return false;
    }
    
    if (this.dropToFolder != null) {      
      this.data.saveDocumentFolder({data: { entity: this.files[event.previousIndex]['entity'], folder: this.dropToFolder} }).subscribe(
        (res: Response) => {
          this.refreshFiles('folder', this.folder, 10000, null);          
        }				
      );        
    }     
    return true;
  }

  /***********************************/

  /*
  sortData(data: Record<string, any>, sortType: 'decimal' | 'alphabet'): Record<string, any> {    
    const entries = Object.entries(data);
    
    if (sortType === 'decimal') {        
        entries.sort(([keyA], [keyB]) => {
            const parseDecimal = (key: string) => key.split('.').map(Number).filter(num => !isNaN(num));
            const decA = parseDecimal(keyA.trim());
            const decB = parseDecimal(keyB.trim());

            for (let i = 0; i < Math.max(decA.length, decB.length); i++) {
                const a = decA[i] || 0;
                const b = decB[i] || 0;
                if (a !== b) return a - b;
            }
            return 0;
        });        
    } else {
        entries.sort(([keyA], [keyB]) => keyA.localeCompare(keyB, 'sl', { numeric: false }));
    }

    return Object.fromEntries(entries);
  }
  */


  /* get folders */  
  keepOrder = (a: { key: string; value: any }, b: { key: string; value: any }): number => {
    const isNumber = (key: string) => /^\d+$/.test(key); // Samo številke
    const isLetter = (key: string) => /^[a-zA-Z]+$/.test(key); // Samo črke

    const aIsNumber = isNumber(a.key);
    const bIsNumber = isNumber(b.key);

    // 🟢 **Ločimo številke in črke**
    if (aIsNumber && !bIsNumber) return this.sortType.includes("_19") ? -1 : 1; // Številke pred črkami pri `_19`, obratno pri `_az`
    if (!aIsNumber && bIsNumber) return this.sortType.includes("_19") ? 1 : -1;

    if (this.sortType === "up_19") {
        // 🔹 Številke padajoče, črke padajoče
        if (aIsNumber && bIsNumber) return parseInt(b.key, 10) - parseInt(a.key, 10);
        if (!aIsNumber && !bIsNumber) return b.key.localeCompare(a.key, 'sl', { numeric: false });
    } 

    if (this.sortType === "down_19") {
        // 🔹 Številke naraščajoče, črke naraščajoče
        if (aIsNumber && bIsNumber) return parseInt(a.key, 10) - parseInt(b.key, 10);
        if (!aIsNumber && !bIsNumber) return a.key.localeCompare(b.key, 'sl', { numeric: false });
    } 

    if (this.sortType === "down_az") {
        // 🔹 Črke naraščajoče, številke naraščajoče
        if (!aIsNumber && !bIsNumber) return a.key.localeCompare(b.key, 'sl', { numeric: false });
        if (aIsNumber && bIsNumber) return parseInt(a.key, 10) - parseInt(b.key, 10);
    } 

    if (this.sortType === "up_az") {
        // 🔹 Črke padajoče, številke padajoče
        if (!aIsNumber && !bIsNumber) return b.key.localeCompare(a.key, 'sl', { numeric: false });
        if (aIsNumber && bIsNumber) return parseInt(b.key, 10) - parseInt(a.key, 10);
    }

    return 0;
};



  
  

  matchDocType(input: string, categories: { doc_type: string }[]): string {
    const found = categories.find(cat => cat.doc_type?.toLowerCase() === input.toLowerCase());
    return found ? found.doc_type : input;
  }

  getFolders() {
    let parameters = { items : 10000 };
    this.data.getDocumentsType(parameters).subscribe( (res: Response) => { this.originalCategories = (<any>res).rows; } );

    this.data.getDocumentCategories(parameters).subscribe(
      (res: Response) => {        
        this.categories = (<any>res).rows;

        console.log(this.categories);
        
        this.categories.push({entity: null, description: this.translate.instant('Undefined'), hex: '#999999', selected: 0});
        
        //fix true/false boolean/string
        for (const key in this.categories) {
          //console.log(this.categories[key]);
          this.categories[key]['allowCreate'] = this.categories[key]['allowCreate'] == 'true' || this.admin_rights ? true : false;
          this.categories[key]['allowEdit'] = this.categories[key]['allowEdit'] == 'true' || this.admin_rights ? true : false;
          this.categories[key]['allowWiew'] = this.categories[key]['allowWiew'] == 'true' || this.admin_rights ? true : false;
        }
       // console.log(this.categories);
        //console.log(this.categories);

        /* update category tree */
        
        this.data.getFolders(null).subscribe(
          (res: Response) => {
            this.folders = JSON.parse(res['folders']);                      
            for (let i=0; i<this.categories.length; i++) {
              //console.log(this.categories[i]);
              let category_helper = this.categories[i].description;
              category_helper = category_helper.toLowerCase();

              if (this.folders == null) { 
                this.folders = []; 
              }
                            
              if (this.folders[category_helper] == undefined) {                
                let helper = {};
                helper['_selected'] = false;
                helper['_opened'] = false;
                helper['_path'] = category_helper;
                helper['_name'] = category_helper;
                helper['_edit'] = false;
                
                if (this.memberAllowance['admin_rights']) {
                  helper['_editable'] = true;
                  helper['_creatable'] = true;
                } else {
                  helper['_editable'] = this.categories[i]['allowEdit'];
                  helper['_creatable'] = this.categories[i]['allowCreate'];
                }
                this.cFunction.setObjectValue(this.folders, category_helper, helper);
              } else {
                //console.log(this.folders[category_helper]);
              }                   
            } 
            
            let helper = (GlobalVariables.memberSettings['documentmanager']).split('|');                        
           // console.log(helper);
            if (helper[0] != undefined) {                
              if (helper[0]=="group") {
                this.systemGroup = false;
                this.smartGroup = true;  
                let helperPath = helper[1].split('/');                
                this.onGroupClick(helperPath[0], helper[2], null, true);                
                if (helperPath[1] != undefined) {       
                  setTimeout(() => {
                    this.onGroupClick(helper[1], helper[2], parseInt(helper[3]), false);
                  }, 1000);                  
                } 
                               
              }
              if (helper[0]=="folder") {                
                this.systemGroup = true;
                this.smartGroup = false;
               // console.log(this.folders);
                if (this.checkIfPathExists(helper[1])) {
                  this.onFolderClick(helper[1]);
                } else {
                  this.onFolderClick(Object.keys(this.folders)[0]);                  
                }
              }
            }            

          }

        );  
      }      
    ); //get custom folders
  }


  /*******************************************/
  /*******************************************/
  ngOnInit(): void {

    /*******************************************/
		/* START: Common procedures fro table view */
		/*******************************************/
		/* Navigation.Service */
		/* Handle local function called form navigation service */
		this.NavigationService.localFunction.subscribe(callLocalFunction => {
			if (callLocalFunction[0] == this.component && callLocalFunction[1] == "localFunction" && this.lastFunctionCallAt < Date.now()) {
				this[callLocalFunction[2]](callLocalFunction[3]);
				//this.NavigationService.prepareObserverForLocalFunction([]);        
				this.lastFunctionCallAt = Date.now()+500; //prevent calling function too fast by default 500mS between call
			}
		});
		
		/* Handle search called from navigation service */
		this.NavigationService.localSearch.subscribe(search => {
			if (this.lastFunctionCallAt < Date.now()) {
				if (search.length > 0) this.searchForFile({target:{value:search}}); //this.onSearch(search);
				this.lastFunctionCallAt = Date.now()+100; //prevent calling function too fast by default 100mS between call
			}
		});
    
 		/* Member.Service */
		/* Get and Wait for allowance */
		this.MembersettingsService.getMemberAllowance();
		this.MembersettingsService.memberAllowance.subscribe(message => { this.memberAllowance = message;});

    /* Member settings */
		this.MembersettingsService.refreshMemberSettings();
		this.MembersettingsService.memberSettingsGlobal.subscribe(message => {
			this.memberSettingsGlobal = message;			
      this.leftPaneWidth = this.memberSettingsGlobal['documentmanagerSeparator'];            
      
      const allowedSortTypes: ("down_az" | "down_19" | "up_az" | "up_19")[] = ["down_az", "down_19", "up_az", "up_19"];
      const defaultSort = "down_az";
      const savedSort = this.memberSettingsGlobal['treeFolderSorting'];
      this.sortType = allowedSortTypes.includes(savedSort) ? savedSort : defaultSort;

      this.order = this.memberSettingsGlobal['documentmanagerOrder'] == undefined ? 'asc' : this.memberSettingsGlobal['documentmanagerOrder'];
      this.sortBy = this.memberSettingsGlobal['documentmanagersortBy'] == undefined ? 'doc_title' : this.memberSettingsGlobal['documentmanagersortBy'];
		});
	
		/* Wait for memberAllowance to be ready with timeout function  */    
		var maxExecutionTime = 5000;
		var executionInterval = 100;
		var navigationInterval = setInterval(() => {      
		  maxExecutionTime = maxExecutionTime - executionInterval;      
		  if (maxExecutionTime < 1) {
        this.admin_rights = this.memberAllowance['admin_rights'];
        clearInterval(navigationInterval);
        alert(this.component + '.component: Can not retrieve data (memberAllowance) from API. Please refresh application.');
		  }
		  if (this.memberAllowance != null) {        
			  clearInterval(navigationInterval);
			  this.NavigationService.updateTopNavigation(this.topNavigation);    
		  }
		}, executionInterval);
		/*******************************************/
		/* END: Common procedures fro table view   */
		/*******************************************/		

    /* language setup */    
    //this.translate.use(GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang']);
    
    /* set user storage folder */
    this.data.getStorageID({}).subscribe((res: Response) => {                    
      this.storage = res['storage'];    
    }); //set user storage folder

    /* get member settings */
    this.data.getMemberSettings(null).subscribe(
      (res: Response) => {
        var memberSettingsArray = [];        
        for (var i=0; i<res['rows'].length; i++) { memberSettingsArray[res['rows'][i].parameter] = res['rows'][i].value; }        
        GlobalVariables.memberSettings = memberSettingsArray;        
        this.grid = this.cFunction.toBooleanExtended(GlobalVariables.memberSettings['fileViewType']);        
    }); //get member settings
      


    /* search called by different filters */
    this.route.params.subscribe((params)=>{
      let searchterm = params.searchTerm;
      if (searchterm != undefined) {
        let searchCondition = (searchterm).split('_');
        if (searchCondition[0] == 'activity') {        
          this.searchForFile( {target:{value:searchterm}} );         
        }
      }
    }); //search called by different filters

    /* get custom folders */
    this.getFolders();

    this.data.getMembers({items : 10000, page : 0, order : 't1.username', orderby : 'asc', search : null, activity: null, field: 'enabled', value: 1}).subscribe(
      (res: Response) => { this.members = (<any>res).rows; }
    );
        
    this.data.getMemberGroups({items : 10000, page : 0, order : 't1.member_group', orderby : 'asc', search : null, activity: null}).subscribe(
      (res: Response) => { this.member_groups = (<any>res).rows; }
    );
    

  } //oninit

  /* Drag and drop movement fix*/
  onDragMove(event: CdkDragMove<any>): void {    
    const previewElement = document.querySelector('.cdk-drag-preview') as HTMLElement;    

    if (previewElement) {
      const { x, y } = event.pointerPosition;
      previewElement.style.left = `${x + 24}px`;
      previewElement.style.top = `${y}px`;
      previewElement.style.transform = 'translateY(-50%)';
    }
  }

  /*****************************************/
  assign(entity) {
    let assigned_users_names_entity = [51];
    let assigned_group_names_entity = [10];

    var assignUserGroupDialog = this.assignUserGroupDialog.open(AssignUserGroupComponent, {
      'width' : '480px',
      data: { membersSelected: assigned_users_names_entity, groupsSelected: assigned_group_names_entity, members: this.members, groups: this.member_groups, activity: entity }
    });

    assignUserGroupDialog.afterClosed().subscribe(result => { if (result != false) { } });
  }

  async previewPdfAsImage(url: string) {
    //this.imageSrc = await this.pdfConverterService.convertPdfToImage(url);
  }

  /***************************************/


}
