import { Component, OnInit, Input } from '@angular/core';
import { GlobalVariables } from '../../services/data.globals';
import { DataService } from '../../services/data.service';
import { CommonFunctions } from '../../services/common.functions'; 
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WidgetActivitiesSettingsComponent } from './widget-activities-settings/widget-activities-settings.component';
import { DashboardComponent } from '../../dashboard/dashboard.component'
import { ActivityComponent } from '../../activities/activity/activity.component'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-widget-activities',
  templateUrl: './widget-activities.component.html',
  styleUrls: ['./widget-activities.component.css']
})
export class WidgetActivitiesComponent implements OnInit {
 
  public rows = [];
  public refresh_percent;
  public parameters;
  public parameters_json;
  public mouseover = false;

  @Input() id: string;  

  @Input() set refresh(refresh: string) {    
    if (parseInt(refresh) == 1000000) {      
      if (!this.mouseover) this.prepareDataAndLoad();
    }        
    this.refresh_percent = refresh;    
  }

  constructor(
  	private data_svc: DataService,
    public cFunction: CommonFunctions, 
    public WidgetActivitiesSettingsDialog: MatDialog,
    public dialog: MatDialog,
    public Dashboard: DashboardComponent,
    public translate: TranslateService
    ) { }
   
  /**************************************/
  /* Common Functions */
  ShowUserInicials(users) {
    var updated_users = [];
    if (users) {
      users = users.split(',');
      for (var i=0; i<users.length; i++) {
        var initials = this.getInitials(users[i]);
        var helper_users: any = {};        
        helper_users.initials = this.getInitials(users[i]);
        helper_users.fullname = users[i];
        updated_users.push(helper_users);
      }
    }    
    return updated_users;
  }

  localDateFormat(date) {
    var datetime = new Date(date);   
    var options = { hour12: false };   
    return datetime.toLocaleString("sl-SI", options).replace('. ', '.').replace('. ', '.');
  }  

  decodeCommentRaw(value) {
    var array = value.split('=>');
    return array;
  }

  toPercent(value) {
    var tasks_count = value.split('/');
    return parseFloat(tasks_count[0]) / parseFloat(tasks_count[1]) * 100;
  }

  getInitials(str) {
    var FirstName = '';
    var LastName = '';

    if (str != undefined) {      
      FirstName = str.split(' ')[0];
      LastName = str.split(' ').reduceRight(a => a);
    }

    if (FirstName === LastName) { return FirstName.trim().substring(0, 2).toUpperCase(); }
    return [FirstName, LastName].map(name => name[0]).join('').toUpperCase();
  }

  settings(id, settings) {
    
    var WidgetActivitiesSettings = this.WidgetActivitiesSettingsDialog.open(WidgetActivitiesSettingsComponent, {
      'width' : '800px',
      data: JSON.parse(settings),
      id: id
    });    

    WidgetActivitiesSettings.afterClosed().subscribe(result => {   
      if (result.data) {
        this.parameters = result.data;
        this.parameters_json = JSON.stringify(result.data);
        this.loadData(JSON.parse(this.parameters_json));

        /* save member settings */
        this.data_svc.setMemberSettings({'parameter': id, 'value': this.parameters_json}).subscribe((res: Response) => {});
      }
    });

  }


  loadData(parameters) {

    parameters['widget'] = 1;
    //parameters['combinecustomer'] = null;
    //parameters = {};
    //parameters.items = 1;
    console.log(parameters);
      this.data_svc.getActivities(parameters).subscribe(
      (res: Response) => {
        this.rows = res['rows'];  
        //console.log(this.rows);
                
        const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };

        for (var i=0; i<this.rows.length; i++) {        
          var t = this.rows[i].created.split(/[- :]/);
          
          if (this.rows[i].last_comment_raw!=undefined) this.rows[i].comments_array = (this.rows[i].last_comment_raw).split('||').reverse();
          
          var current = new Date().toISOString().slice(0, 19).replace('T', ' ');
          var helper = (this.rows[i].message_end).split("-");
          if (helper[0] != '0000') {          
            var helper = <any>(new Date(helper[0], helper[1] - 1, helper[2].substr(0,2)));
            this.rows[i].due_end = this.cFunction.timeDifference(current, helper, null, false);
            this.rows[i].due_end_pass = this.cFunction.timeDifferencePass(helper);
          } else {
            this.rows[i].due_end = null;
            this.rows[i].due_end_pass = false;
          }

          var current = new Date().toISOString().slice(0, 19).replace('T', ' ');
          var helper = (this.rows[i].message_start).split("-");
          if (helper[0] != '0000') {    
            var helper = <any>(new Date(helper[0], helper[1] - 1, helper[2].substr(0,2)));
            this.rows[i].due_start = this.cFunction.timeDifference(current, helper, null, false);
            this.rows[i].due_start_pass = this.cFunction.timeDifferencePass(helper);
          } else {
            this.rows[i].due_start = null;
            this.rows[i].due_start_pass = false;
          }
        }        

      }
    );

  }


  edit(entity) {
    var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
    editDialog.componentInstance.entity = entity;
    editDialog.afterClosed().subscribe(result => {
      this.loadData(this.parameters);
    });
  }

  remove(id) {
    this.Dashboard.removeWidget(id);
  }

  toggleConfirm(entity) {      
    this.data_svc.updateConfirm({activity_entity:entity}).subscribe(
      (res: Response) => {         
        this.loadData(JSON.parse(this.parameters_json));
      }
    );
  }


  prepareDataAndLoad() {
    //console.log(GlobalVariables);
      this.parameters = GlobalVariables.memberSettings[this.id] != undefined ? JSON.parse(GlobalVariables.memberSettings[this.id]) : {title:this.translate.instant('Activity view Widget...'), priority:1, sub_activities:0, comments:0, comments_results:3, extended:1, date_start:0, date_end:0, created:0, items : 6, order : 'updated', orderby : 'desc', combinecustomer:0,approvedbyme:1,assigned:1,date_from:1,showauthor:1,showcustomer:1};
      

      
      /* special workarround because of combining displayed data */      
      var parameters_helper = this.parameters;      
      if (parameters_helper.combinecustomer == true) {
        parameters_helper['order'] = "t3.entity, " + parameters_helper['order'];        
      }

      this.parameters_json = JSON.stringify(this.parameters);      
      //console.log(parameters_helper);
      this.loadData(parameters_helper);
  }


  ngOnInit(): void {    

    /* if mmeber settings in empty, load it again */
    if (GlobalVariables.memberSettings.length === 0) {      
        this.data_svc.getMemberSettings(null).subscribe(
          (res: Response) => {
            var memberSettingsArray = [];
            for (var i=0; i<res['rows'].length; i++) {
              memberSettingsArray[res['rows'][i].parameter] = res['rows'][i].value;              
            }
            GlobalVariables.memberSettings = memberSettingsArray;            
            this.prepareDataAndLoad();
          }
        );
    } else {
      this.prepareDataAndLoad();      
    }   

  }


}
