<div class="inner_wrapper">
    <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="left">
            <h1>{{'ERP items'| translate}}</h1>
        </div>

    </div>

    <div class="body">
       

        <div class="inline wPadding">
            <mat-form-field appearance="outline">
                <mat-label>{{'group'| translate}}</mat-label>
                <input name="title" type="text" matInput >
                <!--<mat-hint>Error</mat-hint>-->                    
            </mat-form-field>
        </div>

        <div class="inline wPadding">
            <mat-form-field appearance="outline">
                <mat-label>{{'category'| translate}}</mat-label>
                <input name="title" type="text" matInput >
                <!--<mat-hint>Error</mat-hint>-->                    
            </mat-form-field>
        </div>
    </div>
       

    <div class="modalActions">
        <button class="left" mat-button mat-dialog-close>{{'Cancle'| translate}}</button>
        <button class="accept right" (click)="save();" mat-button mat-dialog-close>{{'Save'| translate}}</button>
        <button class="warrning right" (click)="delete();" mat-button mat-dialog-close>{{'Delete'| translate}}</button>
    </div>
</div>