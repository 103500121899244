/* Angular Stuff */
import { Injectable } from '@angular/core';
import { NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

/* Services */
import { NavigationService } from './navigation.service';
import { AiService } from '../services/ai.service';

@Injectable({
  providedIn: 'root'
})
export class SpeechToTextService {

  constructor(
    private _ngZone: NgZone,
    private NavigationService: NavigationService,
	private AiService: AiService
  ) { }

  public waitingTime = 5;
  public defaultWaitingTime = 5;
  
  /***********************************/
	/* speech recognition */
	private transcriptSubscription: Subscription;
	public voiceRecognition = false;
	public voice: String = "";
	public speech: String = "";

	getTranscript({ locale = 'sl-SI' }: { locale?: string } = {}): Observable<string> {
		return new Observable(observer => {
		  const SpeechRecognition = window['webkitSpeechRecognition'];
		  const speechRecognition = new SpeechRecognition();
		  speechRecognition.continuous = true;
		  speechRecognition.interimResults = true;
		  speechRecognition.lang = locale;
	
		  speechRecognition.onresult = (speechRecognitionEvent) => {
        var interim_transcript = '';
        for (var i = speechRecognitionEvent.resultIndex; i < speechRecognitionEvent.results.length; ++i) {
          if (speechRecognitionEvent.results[i].isFinal) {
            this.voiceRecognition = true;
            this._ngZone.run(() => observer.next(speechRecognitionEvent.results[i][0].transcript.trim()));
          } else {
            this.voiceRecognition = false;
            interim_transcript += speechRecognitionEvent.results[i][0].transcript;
            this._ngZone.run(() => observer.next(interim_transcript.trim()));
          }
        }
		  };
	
		  speechRecognition.start();		  
		  return () => speechRecognition.abort();
		});
	}
	
  voiceRecognitionStart() {		
    this.voiceRecognition = true;
    this.transcriptSubscription = this.getTranscript().subscribe(transcript => {
      if (transcript !== '' && this.voiceRecognition) {
        this.voice = this.voice + ' ' + transcript;
        console.log('Voice to Text > ' + this.voice);

		this.AiService.onExecute(String(this.voice));
		this.voice = "";

      } else {
        this.speech = transcript;
      }
    });
    console.log('Voice recognition started.');
  }
    
  voiceRecognitionStop() {
    this.voiceRecognition = false;
    if (this.transcriptSubscription) {
      this.transcriptSubscription.unsubscribe();
      this.voice = "";
      console.log('Voice recognition stopped.');
    }
  }
	

}
