<div class="widget_customer" id="{{id}}">
	
    <div  class="widget_header" cdkDrag cdkDragRootElement=".dashboard_widget" cdkDragHandle>
        <div class="widget_title">
            {{parameters?.title}}&nbsp;
        </div>
        <div class="widget_tools">				
			<fa-icon class="icon_medium" [icon]="['fad', 'times']" (click)="remove(id)" style="margin-top: 5px;"></fa-icon>
            <div class="divider_header"></div>
            <fa-icon [icon]="['fad', 'cog']" (click)="settings(id, parameters_json)"></fa-icon>         
        </div>
    </div>

    <div class="widget_content">		
        <div class="widget_item" *ngFor="let item of rows; let i = index" (click)="edit(item.entity)">
            <div class="company">
                <span *ngIf="item.status_color != undefined" class="hex_round" style="background:{{item.status_color}}"></span>
                <span *ngIf="item.status_color == undefined" class="hex_round" style="background:var(--dark-lvl3)"></span>
                {{item.company}}
            </div>            
            <div *ngIf="parameters?.order=='t1.entity'" class="last_activity_ago">
                <fa-icon [icon]="['fad', 'eye']"></fa-icon>                
                {{cFunction.calculateTimeToEvent(item.company_updated, null, false)}}
            </div>
            <div *ngIf="parameters?.order=='last_activity_updated'" class="last_activity_ago">
                <fa-icon [icon]="['fad', 'eye']"></fa-icon>
                {{cFunction.calculateTimeToEvent(item.last_activity_updated, null, false)}}
            </div>
            <div *ngIf="parameters?.order=='t4.created'" class="last_activity_ago">
                <fa-icon [icon]="['fad', 'eye']"></fa-icon>
                {{cFunction.calculateTimeToEvent(item.comment_created, null, false)}}
            </div>

            <div style="width:100%; float:left;height:1px"></div>

            <div *ngIf="item.last_activity!=undefined && parameters?.lastActivity==1" class="last_activity">
                <span class="hex_sq" style="background:{{item.last_activity_hex}}"></span>
                {{item.last_activity}}
                <span>{{cFunction.localDateFormat(item.last_activity_updated)}}</span>
            </div>

            <div class="tools" *ngIf="item.last_activity!=undefined && parameters?.lastActivity==1 && parameters?.order=='last_activity_updated'">
                <fa-icon [icon]="['fad', 'clipboard-check']" (click)="snooze(); $event.preventDefault()"></fa-icon>
                <fa-icon [icon]="['fad', 'eye-slash']" (click)="hide(); $event.preventDefault()"></fa-icon>
            </div>            

            <div *ngIf="item.comment!='' && item.comment!=undefined && parameters?.lastComment==1" class="comment">
                <fa-icon [icon]="['fad', 'comments-alt']"></fa-icon>&nbsp;&nbsp;
                {{item.comment}}
            </div>
        </div>
    </div>
    
</div>