/* Angular */
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

/* Components */
import { ActivityComponent } from '../activities/activity/activity.component';
import { ActivitiesFilterComponent } from '../activities/activities-filter/activities-filter.component';

/* Framework */
import { GridConfiguratorComponent } from '../framework/grid-configurator/grid-configurator.component';
import { GridPdfComponent } from '../framework/grid-pdf/grid-pdf.component';

/* Common Functions */
import { CommonFunctions } from '../services/common.functions';

/* Services */
import { NavigationService } from '../services/navigation.service';
import { MembersettingsService } from '../services/membersettings.service';
import { DataService } from '../services/data.service';
import { AiService } from '../services/ai.service';

/* Translate.Service */
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activities', 
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})

export class ActivitiesComponent implements OnInit {

  public component = 'activities';
  public componentId = '';
	public items = [];
	public itemsSelected = [];
 	public total_items;
	public page = 0;
	public pages = 1;
	public itemsLimit = 25;
	public order = 't1.entity';
	public orderby = 'asc';
	public itemsLimit_display;
	public search = ''; 
	public parameters = {};
	public pagination = [5, 10, 25, 50, 100, 250];
	public filter;
	public lastSelected = -1;
	public orderGlobal = null;
	public columnsGlobal = null;	

	public memberAllowance = null;
	public lastFunctionCallAt = Date.now(); 
	public memberSettingsGlobal = null;

  public category_id;
  public injected_data = null;

  /**/
  /* ai.instructions */
  private aiInstructions = ``;
  private currentCategoryId = null;

  prepareAiInstruction() {
    const filteredAndTranslatedTable = this.tableConstructor
      .filter(column => !column.notsortable) // Filtriranje
      .map(column => ({
        [column.title]: column.entity
      }));

    return  `
       - Valid sorting columna: ${JSON.stringify(filteredAndTranslatedTable)}

       - If the user wants to sort table return: 
        { "type" : "localFunction", "command": "changeOrder", "parameter": "<category_id>" } 
        Keywords: ["sortiraj", "razvrsti", "posortiraj"]
        If the category is not in the allowed list, return: 
        { "command": "invalid_category", "parameter": "<category>" } 
        and respond: "Sortiranje po '<category>' ni mogoče."
      `;
  }
  

  /*************************/

  public topNavigation = [
		{ entity: "activities", exposed: false, component: "activities", title: "Activities", subTitle: null, subTitleColor: null, type: "link",	action: "", actionData: null, icon: "", iconColor: null, pinnable: false,
			submenu: [
				{ entity: "activities.add", exposed: false, component: "activities", title: "Add new ...", subTitle: null, subTitleColor: null, type: "localFunction", action: "edit", actionData: null, icon: "fad,plus-circle", iconColor: null, pinnable: true, submenu: null },
        { entity: "activities.recent", exposed: false, component: "activities", title: "Recent Activities", subTitle: null, subTitleColor: null, type: "", action: "", actionData: null, icon: "fad,clock-rotate-left", iconColor: null, pinnable: false,
          submenu: [
            { entity: "activities.1", exposed: false, component: "activities", title: "Lorem ipusm 1", subTitle: null, subTitleColor: null, type: "action", action: "", actionData: null, icon: "fad,list", iconColor: null, pinnable: false, submenu: null },
            { entity: "activities.2", exposed: false, component: "activities", title: "Lorem ipusm 2", subTitle: null, subTitleColor: null, type: "action", action: "", actionData: null, icon: "fad,list", iconColor: null, pinnable: false, submenu: null },
            { entity: "activities.3", exposed: false, component: "activities", title: "Lorem ipusm 3", subTitle: null, subTitleColor: null, type: "action", action: "", actionData: null, icon: "fad,list", iconColor: null, pinnable: false, submenu: null }
          ]
				},
				{ entity: "activities.splitter", exposed: false, component: "activities", title: null, subTitle: null, subTitleColor: null, type: "splitter", action: null, actionData: null, icon: null, iconColor: null, pinnable: false, submenu: null },
				{ entity: "activities.pdf", exposed: false, component: "activities", title: "Print (PDF)", subTitle: null, subTitleColor: null, type: "localFunction", action: "generatePDF", actionData: null, icon: "fad,print", iconColor: null, pinnable: true, submenu: null },
        { entity: "activities.csv", exposed: false, component: "activities", title: "Export (CSV)", subTitle: null, subTitleColor: null, type: "localFunction", action: "downloadFile", actionData: null, icon: "fad,file-csv", iconColor: null, pinnable: true, submenu: null }				
			]
		},
    { entity: "activitiesView", exposed: false, component: "activities", title: "View", subTitle: null, subTitleColor: null, type: "link", action: "", actionData: null, icon: "", iconColor: null, pinnable: false,
			submenu: [
			  { entity: "activities.View.List", exposed: false, component: "activities", title: "List view", subTitle: null, subTitleColor: null, type: "localFunction", action: "activities", actionData: null, icon: "fad,table-rows", iconColor: null, pinnable: true, submenu: null	},
        { entity: "activities.View.Gantt", exposed: false, component: "activities", title: "Gantt view", subTitle: null, subTitleColor: null, type: "localFunction", action: "activities-gantt", actionData: null, icon: "fad,chart-gantt", iconColor: null, pinnable: true, submenu: null	},
        { entity: "activities.View.CanBan", exposed: false, component: "activities", title: "CanBan view", subTitle: null, subTitleColor: null, type: "localFunction", action: "switchToCanban", actionData: null, icon: "fad,square-kanban", iconColor: null, pinnable: true, submenu: null	}
			] 
		},
    { entity: "activitiesPreferences", exposed: false, component: "activities", title: "Preferences", subTitle: null, subTitleColor: null, type: "link", action: "", actionData: null, icon: "", iconColor: null, pinnable: false, 
      submenu: [
				{ entity: "activitiesPreferences.1", exposed: false, component: "activities", title: "Filter", subTitle: null, subTitleColor: null, type: "localFunction", action: "setFilter", actionData: null, icon: "fad,filter", iconColor: null, pinnable: true, submenu: null },
        { entity: "activitiesPreferences.2", exposed: false, component: "activities", title: "Columns", subTitle: null, subTitleColor: null, type: "localFunction", action: "gridPreferences", actionData: null, icon: "fad,line-columns", iconColor: null, pinnable: true, submenu: null }
			]
		},
		{ entity: 'function:localSearch', enabled: true }
	];

  /*************************/

	public tableConstructor = [
		{ entity: 'entity', sort: 0, enabled: true, item: 'entity', title: 'ID', headerClass: 'col', rowClass: 'col', type: 'text' },
    { entity: 'message_type_name', sort: 1, enabled: true, item: 'message_type_name', title: 'Type', headerClass: 'col', rowClass: 'col', type: 'badge', typeParameter: "message_type_hex" },
    { entity: 't1.title', sort: 2, enabled: true, item: 'title', title: 'Title', headerClass: 'col', rowClass: 'col', type: 'text' },
    { entity: 'cusotmer', sort: 3, enabled: true, item: 'customer', title: 'Customer', headerClass: 'col', rowClass: 'col', type: 'text' },
    { entity: 'message_start', sort: 4, enabled: true, item: 'message_start', title: 'Start', headerClass: 'col', rowClass: 'col', type: 'text' },
    { entity: 'message_end', sort: 5, enabled: true, item: 'message_end', title: 'End', headerClass: 'col', rowClass: 'col', type: 'text' },
    { entity: 'duration', sort: 6, enabled: true, item: 'duration', title: 'Duration', headerClass: 'col', rowClass: 'col', type: 'text', notsortable: true },
    { entity: 'assigned', sort: 7, enabled: true, item: 'assigned', title: 'Assigned to', headerClass: 'col', rowClass: 'col', type: 'assigned_users', notsortable: true },
    { entity: 'comments', sort: 8, enabled: true, item: 'comments', title: 'Comments', headerClass: 'col', rowClass: 'col', type: 'html', notsortable: true },
    { entity: 'activity_status_text', sort: 8, enabled: true, item: 'activity_status_text', title: 'Status', headerClass: 'col', rowClass: 'col', type: 'badge', typeParameter: "activity_status_hex" },
    { entity: 'priority', sort: 8, enabled: true, item: 'priority', title: 'Priority', headerClass: 'col', rowClass: 'col', type: 'priority', typeParameter: "message_type_hex" },

    { entity: 'subactivities', sort: 8, enabled: true, item: 'subactivities', title: 'Sub Activities', headerClass: 'col', rowClass: 'col', type: 'badge', typeParameter: "#ddd", notsortable: true },
    { entity: 'tasks', sort: 8, enabled: true, item: 'tasks', title: 'Tasks', headerClass: 'col', rowClass: 'col', type: 'badge', typeParameter: "#ddd", notsortable: true },

    { entity: 'author', sort: 9, enabled: true, item: 'author', title: 'Author', headerClass: 'col', rowClass: 'col', type: 'text' },
    { entity: 'created', sort: 10, enabled: true, item: 'created', title: 'Created', headerClass: 'col', rowClass: 'col', type: 'text' },
    { entity: 'updated', sort: 11, enabled: true, item: 'updated', title: 'Updated', headerClass: 'col', rowClass: 'col', type: 'text' },
	]

  constructor(
    private route: ActivatedRoute,
    private router: Router,    
    private data: DataService,
		public CommonFunction: CommonFunctions, 
		public dialog: MatDialog,    
		public translate: TranslateService,
		public NavigationService: NavigationService,
		public MembersettingsService: MembersettingsService,
    private AiService: AiService
    //@Inject(MAT_DIALOG_DATA) public injected_data
  ) {           
    translate.addLangs(['en', 'si']);    

    const navigation = this.router.getCurrentNavigation();    
    if (navigation?.extras?.state?.data) {
      this.injected_data = navigation.extras.state.data;      
    }
  }

  /********************************/
  switchToCanban() {    
    this.router.navigate(['/activities-canban/'+this.currentCategoryId]);    
  }

  switchToGantt() {    
    this.router.navigate(['activities-gantt/17']);    
  }

  /********************************/
  /* need to move to common functions !!!!!!!!! */

  ShowUserInicials(users) {
    var updated_users = [];
    if (users) {
      users = users.split(',');
      for (var i=0; i<users.length; i++) {
        var initials = this.getInitials(users[i]);
        var helper_users: any = {};        
        helper_users.initials = this.getInitials(users[i]);
        helper_users.fullname = users[i];
        updated_users.push(helper_users);
      }
    }    
    return updated_users;
  }

  timeDifference(start, end, duration) {

    var timeStart = <any>new Date(start)/1000;
    var timeEnd = <any>new Date(end)/1000;

    var Diff = timeStart - timeEnd;

    if (isNaN(Diff)) return "-";

    var d = Math.floor(Diff / (3600*24));
    var h = Math.floor(Diff % (3600*24) / 3600);
    var m = Math.floor(Diff % 3600 / 60);

    var humanTimeHelper = '';
    if (duration) {
      if (m == 0) {
        humanTimeHelper = this.translate.instant('Now'); 
      } else { 
        humanTimeHelper = m < 0 ? Math.abs(m) + " min" : Math.abs(m) + " min"; 
      }          
      if (h != 0) humanTimeHelper = h < 0 ? Math.abs(h) + " " + this.translate.instant('hours') : Math.abs(h) + " hours";
      if (d != 0) humanTimeHelper = d < 0 ? Math.abs(d) + " " + this.translate.instant('days') : Math.abs(d) + " days";
    } else {
      if (m == 0) {
        humanTimeHelper = 'Now'; 
      } else { 
        humanTimeHelper = m < 0 ? Math.abs(m) + " min ago" : m + " min to"; 
        //if (m == 0) humanTimeHelper = "passed";
      }          
      if (h != 0) humanTimeHelper = h < 0 ? Math.abs(h) + " " + this.translate.instant('hours ago') : h + " " + this.translate.instant('hours to');
      if (d != 0) humanTimeHelper = d < 0 ? Math.abs(d) + " " + this.translate.instant('days ago') : d + " " + this.translate.instant('days to');
    }

    return humanTimeHelper;
  }

  localDateFormat(date) {
    var datetime = new Date(date);   
    var options = { hour12: false };   
    return datetime.toLocaleString("sl-SI", options).replace('. ', '.').replace('. ', '.');
  }

  getInitials(str) {
    var FirstName = '';
    var LastName = '';

    if (str != undefined) {      
      FirstName = str.split(' ')[0];
      LastName = str.split(' ').reduceRight(a => a);
    }

    if (FirstName === LastName) { return FirstName.trim().substring(0, 2).toUpperCase(); }
    return [FirstName, LastName].map(name => name[0]).join('').toUpperCase();
  }

  /*********************************/
  
  get updateParametersFunc() { return this.updateParameters.bind(this); }
  updateParameters() {    
    let categories = [];
    if (this.category_id != null) categories.push({ category_id: this.category_id });    
    this.order = localStorage.getItem(this.component + '_tableOrder') != null ? localStorage.getItem(this.component + '_tableOrder') : this.order;
		this.orderby = localStorage.getItem(this.component + '_tableOrderBy') != null ? localStorage.getItem(this.component + '_tableOrderBy') : this.orderby;    
		this.itemsLimit = localStorage.getItem(this.component + '_tableLimit') != null ? parseInt(localStorage.getItem(this.component + '_tableLimit')) : this.itemsLimit;
		this.page = localStorage.getItem(this.component + '_tablePage') != null ? parseInt(localStorage.getItem(this.component + '_tablePage')) : this.page;
		if (this.page < 1) this.page = 1;
    let page = (this.page - 1) * this.itemsLimit;
		this.search = localStorage.getItem(this.component + '_tableSearch') != null ? localStorage.getItem(this.component + '_tableSearch') : this.search;		
		this.parameters = { 'filter': this.parameters?.['filter'], 'categories': categories, 'page' :page, 'items' : this.itemsLimit, 'order' : this.order, 'orderby' : this.orderby, search: this.search };		
  }

	onSearch(search) { 
		this.page = 1;
		this.search = search;		
		this.updateParameters();		
		this.refreshData();		
	}

  /*************************/
	/* data manipulation     */

  get refreshDataFunc() { return this.refreshData.bind(this); }  
  refreshData() {              
        
    /* fix filter format */    
    const filter = this.parameters['filter'];
    const newFilter = {};
    for (const key in filter) { if (filter.hasOwnProperty(key)) { newFilter[`filter_${key}`] = filter[key]; }}
    //this.parameters['filter'] = newFilter;
    
    //console.log(this.parameters);

    const parameters = { ...this.parameters, ...newFilter };
    
    //console.log(parameters);
    
    this.data.getActivities(parameters).subscribe(
      (res: Response) => {
        this.items = (<any>res).rows;          
        //console.log(this.items);
        this.total_items = (<any>res).total_rows;        
        this.itemsLimit_display = this.itemsLimit > this.total_items ? this.total_items : this.itemsLimit;                  
        this.pages = Math.ceil(this.total_items / this.itemsLimit);            
        
        for (var i=0; i<this.items.length; i++) {
          this.items[i].created = this.localDateFormat(this.items[i].created);
          this.items[i].updated = this.localDateFormat(this.items[i].updated);

          this.items[i]['subactivities'] = this.items[i].sub_activity?.['count_rows'];          
          if (this.items[i]?.['tasks']!=null) {
            this.items[i]['tasks'] = Object.keys(JSON.parse(this.items[i]?.['tasks'])).length;
          } else {
            this.items[i]['tasks'] = 0;
          }

          if (this.items[i].activity_status_text == undefined || this.items[i].activity_status_text == '' || this.items[i].activity_status_text == null) {
            this.items[i].activity_status_text = this.translate.instant('Undefined');
            this.items[i].activity_status_hex = '#000';
          }   

          if (this.items[i].message_type_name == undefined || this.items[i].message_type_name == '' || this.items[i].message_type_name == null) {
            this.items[i].message_type_name = this.translate.instant('Undefined');
            this.items[i].message_type_hex = '#000';
          }   

          if (this.items[i]['last_comment'] != null) {
            this.items[i]['comments'] = (this.items[i]['last_comment']).replaceAll("||", '<br/>');
          } else {
            this.items[i]['comments'] = "";
          }

          
          this.items[i]['assigned_users_array']  = this.items[i]['assigned_users'] != null ? this.items[i]['assigned_users'].split(', ') : [];
          this.items[i]['assigned_users_names_array'] = this.items[i]['assigned_users_names'] != null ? this.items[i]['assigned_users_names'].split(', ') : [];
          this.items[i]['assigned_users_names_hex_array'] = this.items[i]['assigned_users_names_hex'] != null ? this.items[i]['assigned_users_names_hex'].split(', ') : [];
          

          if (this.memberSettingsGlobal['dates'] == "normalAndDay") {
            this.items[i].message_start = this.CommonFunction.getDayOfWeek(this.items[i].message_start) + " " + this.CommonFunction.localDateFormat(this.items[i].message_start);
            this.items[i].message_end = this.CommonFunction.getDayOfWeek(this.items[i].message_end) + " " + this.CommonFunction.localDateFormat(this.items[i].message_end);
            this.items[i].duration = this.CommonFunction.showTimeToEvent(this.items[i].message_start, this.items[i].message_end, null);
            //return this.localDateFormat(start);
          } else if(this.memberSettingsGlobal['dates'] == "normal") {
            this.items[i].message_start = this.CommonFunction.localDateFormat(this.items[i].message_start);
            this.items[i].message_end = this.CommonFunction.localDateFormat(this.items[i].message_end);
            this.items[i].duration = this.CommonFunction.showTimeToEvent(this.items[i].message_start, this.items[i].message_end, null);
          } else if(this.memberSettingsGlobal['dates'] == "datestoW") { 
            this.items[i].message_start = this.CommonFunction.getDayOfWeek(this.items[i].message_start) + " " + this.CommonFunction.showTimeToEvent(this.items[i].message_start, Date.now(), null);
            this.items[i].message_end = this.CommonFunction.getDayOfWeek(this.items[i].message_end) + " " + this.CommonFunction.showTimeToEvent(this.items[i].message_end, Date.now(), null);
            this.items[i].duration = this.CommonFunction.showTimeToEvent(this.items[i].message_start, this.items[i].message_end, null);            
          } else {
            this.items[i].message_start = this.CommonFunction.showTimeToEvent(this.items[i].message_start, Date.now(), null);
            this.items[i].message_end = this.CommonFunction.showTimeToEvent(this.items[i].message_end, Date.now(), null);
            this.items[i].duration = this.CommonFunction.showTimeToEvent(this.items[i].message_start, this.items[i].message_end, null);
          }       
          
          var earliest_message_end = null;
          var earliest_message_start = null;
          this.items[i]['earliest_message_end'] = null;
          var dOld = null;
          for (var iSub=0; iSub<this.items[i]['sub_activity']['rows'].length; iSub++) {

            var t = (this.items[i]['sub_activity']['rows'][iSub]['message_end']).split(/[- :]/);
            var d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
            dOld = d;

            if (d > new Date() && dOld >= d) {        
              this.items[i]['earliest_message_end'] = this.CommonFunction.showTimeToEvent(this.items[i]['sub_activity']['rows'][iSub]['message_end'], Date.now(), null);
              this.items[i]['earliest_message_end_hex'] = this.items[i]['sub_activity']['rows'][iSub]['message_type_hex'];
              if (this.items[i]['earliest_message_end_hex'] == null) this.items[i]['earliest_message_end_hex'] = "#ddd"; 
            }
          }
        }
      }
    );
    
  } 

  setFilter() {        
    if (typeof this.parameters['filter'] !== 'object' || this.parameters['filter'] === null) {
      this.parameters['filter'] = { 'category_id': this.category_id };
    } else {
      this.parameters['filter']['category_id'] = this.category_id;
    }
        
    var filterDialog = this.dialog.open(ActivitiesFilterComponent, {
			data : this.parameters['filter'], maxWidth: '95vw', width: '1160px'
		});

		filterDialog.afterClosed().subscribe(result => {						      ;
      if (result != null) {
        this.updateParameters();
        this.parameters['filter'] = result;        
        localStorage.setItem(this.component + '_' + this.category_id + '_filter', JSON.stringify(this.parameters));
        this.refreshData();        
      }
		});    
	}

  /*************************************/

  get editFunc() { return this.edit.bind(this); }
  edit(entity) {    
    var editDialog = this.dialog.open(ActivityComponent, { panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
    editDialog.componentInstance.entity = entity;
    editDialog.componentInstance['category_id'] = this.category_id;
    editDialog.afterClosed().subscribe(result => {       
      this.updateParameters();    
      this.refreshData();
    });    
  }

	/*******************************************************/
	/* common functions */
	gridPreferences() {		
		var configuratorDialog = this.dialog.open(GridConfiguratorComponent, {
			data : { tableConstructor: this.tableConstructor, component: this.component },
			width: '100%',
        	maxWidth: '450px'
		});

		configuratorDialog.afterClosed().subscribe(result => {			
			if (result['tableConstructor'] != null) {
				this.tableConstructor = result['tableConstructor'];
				this.MembersettingsService.setMemberSettings(this.component + "_gridorder", JSON.stringify(result['order']));
				this.MembersettingsService.setMemberSettings(this.component + "_gridcolumns", JSON.stringify(result['columns']));				
			}			
		});
	}
	
	replaceDataTopNavigation (entity, property, value, object) {
		for(let i = 0; i < object.length; i++) {
		object[i][property] = object[i].entity == entity ? value : object[i][property];
		object[i].submenu != null ? this.replaceDataTopNavigation(entity, property, value, object[i].submenu) : undefined;
		}
		return object
	}

  /*************************************/
	/* Exports                           */
  /* Common */
	exportCSV() {
		let head = [];
		let data = this.itemsSelected.length > 0 ? this.itemsSelected : this.items;		
		for (let i=0; i<(this.tableConstructor).length; i++) { head.push(this.tableConstructor[i]['entity']); }		
		this.CommonFunction.downloadFile(data, head, this.component+".csv");
	}

  /* Common */
	exportPDF() {
		let head = [];
		let data = [];
		let settings = {documentTitle: "Rich Quote Items", printDate: true, companyData: true, pagination: true, orientation: 'landscape', format: 'A4' };
		let dataHelper = this.itemsSelected.length > 0 ? this.itemsSelected : this.items;
		for (let i=0; i<(this.tableConstructor).length; i++) { head.push(this.tableConstructor[i]['entity']); }		
		let pdfData = {
			head: head,
			data: dataHelper,
			summarizedRow: false,
			settings: settings
		}
		var gridPDFDialog = this.dialog.open(GridPdfComponent, {
			data : pdfData
		});

		gridPDFDialog.afterClosed().subscribe(result => {			
		});
	}

	/*************************************/
  	
  approvedComponent() {
    
  }

  ngOnInit(): void {    
    /********************************************/ 
		/* START: Common procedures from table view */
		/********************************************/
		/* Navigation.Service */
		/* Handle local function called form navigation service */    
		this.NavigationService.localFunction.subscribe(callLocalFunction => {
      //console.log(callLocalFunction);
			if (callLocalFunction[0] == this.component && callLocalFunction[1] == "localFunction" && this.lastFunctionCallAt < Date.now()) {
				//this.NavigationService.prepareObserverForLocalFunction(['blank', null, null, null]);
        this.lastFunctionCallAt = Date.now()+500; //prevent calling function too fast by default 500mS between call
        this[callLocalFunction[2]](callLocalFunction[3]);				
			}
		});
		
		/* Handle search called from navigation service */
		this.NavigationService.localSearch.subscribe(search => {
			if (this.lastFunctionCallAt < Date.now()) {
				if (search.length > 0)	this.onSearch(search);
				this.lastFunctionCallAt = Date.now()+100; //prevent calling function too fast by default 100mS between call
			}
		});

		/* Member.Service */
		/* Get and Wait for allowance */
		this.MembersettingsService.getMemberAllowance();
		this.MembersettingsService.memberAllowance.subscribe(message => this.memberAllowance = message);

		this.MembersettingsService.refreshMemberSettings();
		this.MembersettingsService.memberSettingsGlobal.subscribe(message => {
			this.memberSettingsGlobal = message;
			this.orderGlobal = this.memberSettingsGlobal[this.component + '_gridorder'] != undefined ? JSON.parse(this.memberSettingsGlobal[this.component + '_gridorder']) : null;
			this.columnsGlobal = this.memberSettingsGlobal[this.component + '_gridcolumns'] != undefined ? JSON.parse(this.memberSettingsGlobal[this.component + '_gridcolumns']) : null;
			for (let i=0; i<(this.tableConstructor).length; i++) {			
				if (this.orderGlobal != null) this.tableConstructor[i].sort = this.orderGlobal.indexOf(this.tableConstructor[i].entity);
				if (this.columnsGlobal != null) this.tableConstructor[i].enabled = this.columnsGlobal.indexOf(this.tableConstructor[i].entity) > -1 ? false : true;
			}
			this.tableConstructor.sort((a, b) => a.sort - b.sort);						
		});
	
		/* Wait for memberAllowance to be ready with timeout function  */    
		var maxExecutionTime = 5000;
		var executionInterval = 100;
		var navigationInterval = setInterval(() => {      
		  maxExecutionTime = maxExecutionTime - executionInterval;      
		  if (maxExecutionTime < 1) {
			  clearInterval(navigationInterval);
			  alert(this.component + '.component: Can not retrieve data (memberAllowance) from API. Please refresh application.');
        this.router.navigate(['/']);         
		  }
		  if (this.memberAllowance != null) {
			  clearInterval(navigationInterval);
        /* Update top navigation */
			  this.NavigationService.updateTopNavigation(this.topNavigation);
		  }
		}, executionInterval);

    /*******************************************/
    /* AI */
    const Helper = this.prepareAiInstruction();
    this.AiService.setScope(Helper);

		/*******************************************/
		/* END: Common procedures fro table view   */
		/*******************************************/  

    /*******************************************/
    /* Custom: Init */
    //console.log(this.injected_data);

    this.route.params.subscribe(params=>{
      //console.log(params);
      //console.log(this.injected_data);
     
      if (this.injected_data != null) {        
        this.data.getActivityTypes({ search: this.injected_data }).subscribe(
          (res: Response) => {	
            let items = (<any>res).rows;
            //console.log(items);
            this.category_id = items[0]['entity']; 
            this.replaceDataTopNavigation('activities', 'subTitle', items[0]['description'], this.topNavigation);
            this.replaceDataTopNavigation('activities', 'subTitleColor', items[0]['hex'], this.topNavigation);
            this.currentCategoryId = items[0]['entity'];
            this.NavigationService.updateTopNavigation(this.topNavigation);
            this.updateParameters();
            this.refreshData();      
          }
        );
        return;
      }

      if (params != null) {
        this.category_id = params['category_id'];            
        this.parameters = JSON.parse(localStorage.getItem(this.component + '_' + this.category_id + '_filter'));    
  
        this.updateParameters();
        this.refreshData();   
  
        this.data.getActivityType({id:this.category_id}).subscribe(
          (res: Response) => {
            let items = (<any>res).rows;          
            this.replaceDataTopNavigation('activities', 'subTitle', items[0]['description'], this.topNavigation);
            this.replaceDataTopNavigation('activities', 'subTitleColor', items[0]['hex'], this.topNavigation);
            this.currentCategoryId = items[0]['entity'];
            this.NavigationService.updateTopNavigation(this.topNavigation);          
          }			
        );  
        return;
      }

      this.updateParameters();
      this.refreshData();      
		});

    
  } //oninit

}