<div class="quickTabBody" style="float:left;" [ngClass]="{'notActive':open==false}">
    <div class="content" [ngClass]="{'open':open}">
        <div class="leftShortcuts">
            <ng-container *ngFor="let item of categories">
                <div class="item" [ngStyle]="{'background-color':item['hex']}" (click)="open=false;navigate('popup', 'activity', null, item['entity'])">
                    {{item['description']}}
                </div>                
            </ng-container>            
        </div>

        <div class="rightShortcuts">
            <ng-container *ngFor="let shortcut of quickNavigationShortcuts | keyvalue">                
                <ng-container *ngFor="let item of topNavigation">                    
                    <ng-container *ngIf="item['entity']=='apps'">                         
                        <ng-container *ngIf="(item.submenu).length > 0">
                            <ng-container *ngFor="let subItem of item.submenu">
                                <!--pinnable here for upper level-->
                                <!--{{subItem.title}}-->
                                    <ng-container *ngIf="subItem?.submenu">
                                        <ng-container *ngIf="(subItem?.submenu).length > 0">                                        
                                            <ng-container *ngFor="let subSubItem of subItem.submenu">                                                
                                                <ng-container *ngIf="shortcut.key == subSubItem.entity && shortcut.value">                                                    
                                                    <fa-icon *ngIf="subSubItem.icon!=''" class="extraBig" [icon]="[icon(subSubItem.icon)[0], icon(subSubItem.icon)[1]]" (click)="navigate(subSubItem.type, subSubItem.action, subSubItem.component, subSubItem.actionData)"></fa-icon>
                                                </ng-container>
                                            </ng-container>                                        
                                        </ng-container>
                                    </ng-container>                            
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
            <!--<fa-icon class="extraBig" [icon]="['fad', 'user-clock']" (click)="open=false;navigate('popup', 'timetracking', null, null)"></fa-icon>-->
            <fa-icon class="extraBig" [icon]="['fad', 'cloud-arrow-up']" (click)="open=false;navigate('localFunction', 'openFileUploadDialog', 'app', null)"></fa-icon>
        </div>

    </div>
</div>





