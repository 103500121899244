<div class="inner_wrapper asmodal" style="z-index:9999">
		
	<div class="connection_data_extended">

		<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
			<div class="left">
				<h1 *ngIf="smart">{{'Add/Edit document data'|translate}}</h1>
				<h1 *ngIf="!smart">{{'Choose folder to store file'|translate}}</h1>			
				<!--<span class="current_file">{{this.current_file}}</span>-->
			</div>
			<div class="right">
				<fa-icon class="icon_medium" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
			</div>
		</div>

		<div class="body" [ngClass]="{ 'asmodal': !asComponent }">
			<div class="basic_data">
				<input type="hidden" name="entity" [(ngModel)]="entity" />
				<div class="inline w100 wPadding">
					<!--
					<mat-form-field appearance="outline">
						<mat-label>{{'Category' | translate}}</mat-label>
						
						<ng-container *ngIf="folder">
							<input type="text" matInput name="category" [(ngModel)]="folder" readonly>
						</ng-container>
						
						<ng-container *ngIf="!folder">
							<input type="hidden" name="category_id" [(ngModel)]="category_id" readonly>
							<input type="hidden" name="folder" [(ngModel)]="folder" readonly>
							<input type="text" matInput id="category" name="category" [(ngModel)]="category" (click)="selectCategory()">
						</ng-container>						
						
					</mat-form-field>
					-->

					<input type="hidden" name="category_id" [(ngModel)]="category_id">
					<input type="hidden" name="folder" [(ngModel)]="folder">

					<ng-container *ngIf="folder">
						<mat-form-field appearance="outline">
							<mat-label>{{'Folder' | translate}}</mat-label>
							<input type="text" matInput name="category" [(ngModel)]="folder" readonly>
						</mat-form-field>
					</ng-container>

					<ng-container *ngIf="!folder && !entity">
						<mat-form-field appearance="outline">
							<mat-label>{{'Category' | translate}}</mat-label>
							<input type="text" matInput id="category" name="category" [(ngModel)]="category" (click)="selectCategory()">
						</mat-form-field>
					</ng-container>
				</div>
					
				<div class="inline w100 wPadding">               
					<mat-form-field appearance="outline">
						<mat-label>{{'Document title'|translate}}</mat-label>
						<input type="text" matInput name="title" [(ngModel)]="title">
						<!--<mat-hint>Error</mat-hint>-->
					</mat-form-field>
				</div>

				<div class="inline w100 wPadding">
					<mat-form-field appearance="outline">
						<mat-label>{{'Keywords / Description' | translate}}</mat-label>
						<textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5" name="keywords" [(ngModel)]="keywords"></textarea>
						<!--<mat-hint>Error</mat-hint>-->
					</mat-form-field>
				</div>

			</div>
			
			<div class="connection_data">

				<div class="inline w100 wPadding">
					<mat-form-field appearance="outline">
						<mat-label>{{'Attached to activity'|translate}}</mat-label>
						<input [(ngModel)]="activity_id" type="hidden" />
						<input type="text" class="stickToRight" matInput (click)="selectActivity()" [(ngModel)]="activity" readonly />
						<fa-icon class="right" [icon]="['fad', 'list']" (click)="selectActivity()"></fa-icon>
						<!--<mat-hint>Error</mat-hint>-->
					</mat-form-field>
				</div>

				<div class="inline w100 wPadding">
					<mat-form-field appearance="outline">
						<mat-label>{{'Attached to customer'|translate}}</mat-label>
						<input [(ngModel)]="customer_id" type="hidden" />
						<input type="text" class="stickToRight" matInput (click)="selectCustomer()" [(ngModel)]="customer" readonly />
						<fa-icon class="right" [icon]="['fad', 'users']" (click)="selectCustomer()"></fa-icon>
						<!--<mat-hint>Error</mat-hint>-->
					</mat-form-field>
				</div>

				<!--
				<div class="inline w100 wPadding" style="float:right;">
					<mat-form-field appearance="outline">
						<mat-label>{{'Valid to'|translate}}</mat-label>
						<mat-icon (click)="selectCustomer()"></mat-icon>
						<input matInput formControlName="valid" name="valid" [(ngModel)]="valid" readonly (click)="selectCustomer()">
						<mat-datepicker-toggle matSuffix [for]="pickerValid"></mat-datepicker-toggle>
						<mat-datepicker #pickerValid></mat-datepicker>					
					</mat-form-field>
				</div>

				<div class="inline w100 wPadding">               
					<mat-form-field appearance="outline">
						<mat-label>{{'Document value'|translate}}</mat-label>
						<input type="text" matInput formControlName="value" name="value" [(ngModel)]="value">						  
					</mat-form-field>
				</div>
				-->
			</div>
		</div>

		<div class="modalActions">				
			<button class="action right" *ngIf="entity==undefined" (click)="add()" mat-button mat-dialog-close>{{'Upload file'|translate}}</button>
			<button class="accept right" *ngIf="entity!=undefined" (click)="save()">{{'Save'|translate}}</button>
			<button class="action left" style="float:left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
		</div>
		

	</div>
</div>


