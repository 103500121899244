<div class="cameraContainer">   

  <div *ngIf="!pictureTaken" class="webImagePlace">
      <webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)"></webcam>
    </div>

    <div *ngIf="pictureTaken" class="captureResult">      
      <img [src]="webcamImage?.imageAsDataUrl" />
    </div>    

    <div *ngIf="!pictureTaken" class="functions">
      <span>
        <fa-icon [icon]="['fad', 'camera']" (click)="getSnapshot()"></fa-icon>
      </span>
      <span>
        <fa-icon [icon]="['fad', 'camera-slash']" (click)="cancelImage()"></fa-icon>
      </span>
    </div>

    <div *ngIf="pictureTaken" class="functions">
      <span>
        <fa-icon [icon]="['fad', 'check']" (click)="useImage()"></fa-icon>
      </span>
      <span>
        <fa-icon [icon]="['fad', 'times']" (click)="cancelImage()"></fa-icon>
      </span>
    </div>    

</div>
