/* Angular */
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

/* Data Services */
import { DataService } from '../services/data.service';
import { InitService } from '../services/init.service';

/* Other services */
import { GlobalVariables } from '../services/data.globals';
import { NavigationService } from '../services/navigation.service';

@Injectable({
  providedIn: 'root'
})

export class AiService {
  private apiUrl = 'https://api.openai.com/v1/chat/completions';
  private output: Array<any> = [];
  private sharableData: Array<any> = [];

  private aiLogSource = new BehaviorSubject<Array<any>>([]);
  public aiLog = this.aiLogSource.asObservable();

  private scopeSource = new BehaviorSubject<string>("");
  public scope = this.scopeSource.asObservable();
  private updateScope = "";

  constructor(
    private http: HttpClient,
    private data: DataService,
    private NavigationService: NavigationService,
    private InitService: InitService
  ) {}

  setScope(scope) {
    this.scopeSource.next(scope);
  }

  onExecute(input: string) {    

    console.log(this.scope);

    this.scopeSource.subscribe(value => {      
      console.log("Nova vrednost:", value);
      this.updateScope = value;
    });

    let validCategories = [];
    this.InitService.sherableData.subscribe((data) => {      
      if (data?.activityCategories) { // Preverimo, če podatki obstajajo
        const formattedData = data.activityCategories.reduce((acc, item) => {
          acc[item.entity] = item.description;
          return acc;
        }, {} as Record<number, string>); // Ključi so števila (entity), vrednosti so stringi (description)
    
        this.sharableData['activityCategories'] = formattedData; // Shrani v spremenljivko
    
        console.log(this.sharableData['activityCategories']);
      } else {
        console.warn("activityCategories še ni na voljo");
      }      
      console.log(this.sharableData);
    });

    this.output.push({ type: 1, text: input });
    this.aiLogSource.next(this.output);

    this.onRecognize(input, validCategories).subscribe(result => {
      console.log("API Response:", result);

      try {
        const content = JSON.parse(result.choices[0].message.content);
        console.log(result);
        let function_with_param = "";

        /******************************************/
        /* activities */
        if (content.command === "activities" && content.parameter) {
          function_with_param = `activities(${content.parameter})`;
          this.NavigationService.navigate('link', '/activities/'+content.parameter, null, null);
        } 

        /******************************************/
        /* customers */
        else if (content.command === "customer" && content.parameter) {
          
          this.data.getCustomers({ search: content.parameter }).subscribe(
            (res: Response) => {	              
              const customer_id = ((<any>res).rows[0]['entity']);
              content.parameter = customer_id;
              function_with_param = `customer(${content.parameter})`;    
              if (Number.isInteger(customer_id)) {                
                this.output.push({ type: 3, text: 'Opening customer <b>'+(<any>res).rows[0]['company']+'</b>' });
                this.aiLogSource.next(this.output);
                this.NavigationService.navigate('popup', 'customer', null, customer_id);
              } else {
                this.output.push({ type: 3, text: 'Can not find any customer named <b>'+content.parameter+'</b>' });
                this.aiLogSource.next(this.output);
              }
            }
          );
        }

        /******************************************/
        /* activity */
        else if (content.command === "activity" && content.parameter) {
          //function_with_param = `activity(${content.parameter})`;

          if (/^\d+$/.test(content.parameter)) {
            this.data.getActivity({ id: content.parameter }).subscribe(
              (res: Response) => {	              
                console.log((<any>res).rows);
                if ((<any>res).rows[0] != undefined) {
                  let entity = (<any>res).rows[0]['entity'];
                  this.output.push({ type: 3, text: 'Opening activity <b>'+(<any>res).rows[0]['entity'] + ' - ' + (<any>res).rows[0]['title']+'</b>' });
                  this.aiLogSource.next(this.output);
                  this.NavigationService.navigate('popup', 'activity', null, entity);
                }
              });
          } else {
            this.data.searchActivity({ search: content.parameter }).subscribe(
              (res: Response) => {	              
                console.log((<any>res).rows);
                if ((<any>res).rows[0] != undefined) {
                  let entity = (<any>res).rows[0]['entity'];
                  this.output.push({ type: 3, text: 'Opening activity <b>'+(<any>res).rows[0]['entity'] + ' - ' + (<any>res).rows[0]['title']+'</b>' });
                  this.aiLogSource.next(this.output);
                  this.NavigationService.navigate('popup', 'activity', null, entity);
                }
              });
          }
        } 



        else if (content.command === "find" && content.parameter) {
          this.NavigationService.navigate('localFunction', 'openGlobalSearch', 'app', null);
          this.NavigationService.navigate('localFunction', 'searchExecute', 'search', content.parameter);
          function_with_param = `find(${content.parameter})`;
        } 
        else if (content.command === "filemanager") {
          function_with_param = `filemanager()`;
        }
        else if (content.type === "localFunction") {
          //function_with_param = `run localFunction(content.parameter)`;
          this.NavigationService.navigate('localFunction', content.command, null, content.parameter);
          this.output.push({ type: 3, text: 'Executing local function <b>'+content.command+'('+content.parameter+')</b>' });
          this.aiLogSource.next(this.output);
        }

        /******************************/
        /* Human answer */
        else if (content.type === 'system', content.command === 'answer') {
          //function_with_param = `run localFunction(content.parameter)`;
          this.NavigationService.navigate('localFunction', content.command, null, content.parameter);          
          this.output.push({ type: 4, text: 'Answer:' });
          this.aiLogSource.next(this.output);
          this.output.push({ type: 6, text: content.response["choices"][0]["message"]["content"] });
          this.aiLogSource.next(this.output);          
        } 
        else {
          console.log(content.type, content.parameter);
          function_with_param = "Ukaz ni prepoznan.";
        }

        this.output.push({ type: 4, text: result.choices[0].message.content });
        this.aiLogSource.next(this.output);
        
        this.output.push({ type: 3, text: function_with_param });
        this.aiLogSource.next(this.output);
        
      } 
      catch (error) {
        this.logMessage("Napaka pri obdelavi odgovora.");
      }
    }, error => {
      console.error("API error:", error);
      this.logMessage("Napaka pri povezavi z API-jem.");
    });
  }

  onRecognize(userInput: string, validCategories): Observable<any> {
    const requestBody = {
      model: "gpt-3.5-turbo",
      messages: [
        { 
          role: "system", 
          content: `You are an assistant that extracts structured commands from user input. 
                    The user may type in any language, but you always return a structured JSON response in English. 
    
                    - Valid activity categories: ${JSON.stringify(this.sharableData['activityCategories'])}
    
                    - If the user wants to list categories of activities (e.g. projects, meetings), return: 
                      { "type" : "link", "command": "activities", "parameter": "<category_id>" } 
                      Keywords: ["odpri aktivnosti", "prikaži aktivnosti", "seznam aktivnosti", "pokaži aktivnosti", "aktivnosti"]
                      If the category is not in the allowed list, return: 
                      { "command": "invalid_category", "parameter": "<category>" } 
                      and respond: "Kategorija '<category>' ne obstaja. Veljavne so: ${Object.keys(this.sharableData['activityCategories']).join(", ")}."
    
                    - If the user wants to open a specific activity, return: 
                      { "type" : "popup", "command": "activity", "parameter": "<activity>" }
                      Keywords: ["odpri aktivnost", "uredi aktivnost", "pokaži aktivnost", "aktivnost"]

                    - If the user wants to open a specific customer, return: 
                      { "type" : "popup", "command": "customer", "parameter": "<customer>" }
                      Keywords: ["odpri podjetje", "uredi podjetje", "pokaži podjetje", "podjetje", "odpri partnerja", "uredi partnerja", "pokaži partnerja", "partner", "odpri kupca", "uredi kupca", "pokaži kupca", "kupec"]
    
                    - If the user wants to find something, return: 
                      { "type" : "popup", "command": "find", "parameter": "<search_term>" } 
                      Keywords: ["najdi", "poišči", "išči", "kaj imamo o", "prikaži podatke o"]
    
                    - If the user wants to manage files (view, edit, etc.), return: 
                      { "type" : "link", "command": "filemanager" } 
                      Keywords: ["odpri dokumente", "uredi dokumente", "preglej datoteke", "file manager", "prikaži datoteke"]
    
                    - If the user only provides one word, determine if it refers to any category. If it matches return command coresponded to category.                   
                     
                    - if user want help, return:
                      { "type" : "system", "command": "answer" }                      
                      Keywords: ["pomoč", "kako mi lahko pomagaš", "kaj lahko počnem", "kako uporabljam ai", "kako uporabljam app"]

                    - If the request is unrelated, return: 
                      { "type" : "null", "command": "unknown", "parameter": "" }

                    ${this.updateScope}

                    Always return JSON format, no explanations.` 
        },
        { 
          role: "user", 
          content: userInput 
        }
      ],
      max_tokens: 50
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${environment.openAiApiKey}`
    });

    return this.http.post(this.apiUrl, requestBody, { headers });
  }

  logMessage(message: string) {
    console.log("Log:", message);
    this.output.push({ type: 3, text: message });
    this.aiLogSource.next(this.output);
  }
}