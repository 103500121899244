<div class="inner_wrapper">
	
	<div class="header">
	  <div class="left">
		<h1>{{'Select customer'|translate}}</h1>
	  </div>
	  <div class="right">
		<fa-icon class="icon_medium" style="margin-top:2px;display:inline-block;vertical-align: top;" [icon]="['fad', 'plus-circle']" (click)="addCustomer();"></fa-icon>&nbsp;&nbsp;
		<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
	  </div>
	</div>

	<div class="body">
		<div class="customer-search search">
			<!--<input type="text" id="search" (keyup)="onSearchCustomer($event)" placeholder="Search for customer..."/>-->
			<!--<input type="text" id="search" (keyup)="onSearchCustomer()" placeholder="{{'Search for customer...'|translate}}" [(ngModel)]="search" />-->
			<!--<div class="inline w100 wPadding">-->
                <mat-form-field appearance="outline">
                    <mat-label>{{'Search for customer...'|translate}}</mat-label>
                    <input type="text" matInput (keyup)="onSearchCustomer()" [(ngModel)]="search">
                    <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            <!--</div>-->
		</div>
		<div class="customer-results results">
			<div *ngFor='let item of items' class="customer" (click)="onSelect({'email':item.email, 'entity':item.entity, 'customer': item.company, 'address': item.address1, 'address2': item.address2, 'post': item.post, 'city': item.city, 'country': item.country, 'vat':item.vat })">
				<div class="status" style="background:{{item.status_color}};">{{item.status_description}}</div>
				<div class="name">{{item.company}}</div>
				<div class="vat">{{item.vat}}</div>
			</div>		
		</div>
	</div>

	<div class="modalActions">
		<button class="action left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
	</div>
  </div>