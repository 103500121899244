import { Component, OnInit, Input } from '@angular/core';
import { GlobalVariables } from '../../services/data.globals';
import { DataService } from '../../services/data.service';
import { CommonFunctions } from '../../services/common.functions';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValue } from '@angular/common';

import { WidgetLastSettingsComponent } from './widget-last-settings/widget-last-settings.component';
import { DashboardComponent } from '../../dashboard/dashboard.component'
import { ActivityComponent } from '../../activities/activity/activity.component'
import { TranslateService } from '@ngx-translate/core';  //translate:START:END

@Component({
  selector: 'app-widget-last',
  templateUrl: './widget-last.component.html',
  styleUrls: ['./widget-last.component.css']
})
export class WidgetLastComponent implements OnInit {

  @Input() id: string;

  constructor(
    private data_svc: DataService,
    public cFunction: CommonFunctions,     
    public dialog: MatDialog,
    public Dashboard: DashboardComponent,
    public WidgetLastSettingsDialog: MatDialog,
    public translate: TranslateService
    ) {
      translate.addLangs(['en', 'si']); //translate:START:END    
    }

  public rows_activities = [];
  public rows_comments = [];
  public last_items = [];
  public parameters;
  public parameters_json;
  public parameters_activities = { updated : '2022-01-20', items : 100, order : 'updated', orderby : 'desc', viewed : 1, days : 7, last_change : 1, 'view_count' : true};
  public parameters_comments = { created : '2022-01-20', items : 100, order : 't1.created', orderby : 'desc', viewed : 1, days : 7};

  /************************************/
  loadData() {
    var total_items = 0;
    var date = <any>new Date();    
    date.setDate(date.getDate() - parseInt(this.parameters['days']));
    var date_format = date.toISOString().split("T")[0];
        
    this.parameters_activities['viewed'] = this.parameters['viewed'];
    this.parameters_activities['days'] = this.parameters['days'];
    this.parameters_activities['assigned'] = this.parameters['assigned'];    
    this.parameters_activities['updated'] = date_format;
    this.parameters_comments['viewed'] = this.parameters['viewed'];
    this.parameters_comments['days'] = this.parameters['days'];
    this.parameters_comments['assigned'] = this.parameters['assigned'];
    this.parameters_comments['created'] = date_format;
    
    this.last_items = [];

    //console.log('*** getActivities: widget-last');
    this.data_svc.getActivities(this.parameters_activities).subscribe(
      (res: Response) => {
        this.rows_activities = res['rows'];  
        console.log(res);

        //this.last_items = res['rows'];

        var updated_helper = 0;
        /******************************/
        /* create common list of last items */        
        total_items = total_items + this.rows_activities.length;
        for (var i=0; i<this.rows_activities.length; i++) {
          var updated = this.rows_activities[i]['updated'];
          var updated_helper = (new Date(updated).getTime() - new Date('2022-01-01 00:00:00').getTime())/1000;
          //updated_helper++;          
          this.last_items[updated_helper] = [];
          this.last_items[updated_helper]['type'] = 'Message';
          this.last_items[updated_helper]['updated'] = this.rows_activities[i]['updated'];
          this.last_items[updated_helper]['category'] = this.rows_activities[i]['message_type_name'];
          this.last_items[updated_helper]['hex'] = this.rows_activities[i]['message_type_hex'];
          this.last_items[updated_helper]['title'] = this.rows_activities[i]['title'];
          this.last_items[updated_helper]['parent_title'] = null;
          this.last_items[updated_helper]['author'] = this.rows_activities[i]['editorname'] == null ? this.rows_activities[i]['author'] : this.rows_activities[i]['editorname'];
          this.last_items[updated_helper]['company'] = this.rows_activities[i]['customer'];
          this.last_items[updated_helper]['company_hex'] = this.rows_activities[i]['customer_hex'];
          this.last_items[updated_helper]['message_entity'] = this.rows_activities[i]['entity'];
          this.last_items[updated_helper]['viewed'] = this.rows_activities[i]['viewed'];
          this.last_items[updated_helper]['last_change'] = this.rows_activities[i]['last_change'];
        }        

        this.data_svc.getLastComments(this.parameters_comments).subscribe(
          (res: Response) => {
            this.rows_comments = res['rows'];  
            //console.log(this.rows_comments);
            /******************************/
            /* create common list of last items */    
            total_items = total_items + this.rows_activities.length;
            for (var i=0; i<this.rows_comments.length; i++) {
              var updated = this.rows_comments[i]['updated'];
              var updated_helper = (new Date(updated).getTime() - new Date('2022-01-01 00:00:00').getTime()) / 1000;
              this.last_items[updated_helper] = [];
              this.last_items[updated_helper]['type'] = 'Comment';
              this.last_items[updated_helper]['updated'] = this.rows_comments[i]['updated'];
              this.last_items[updated_helper]['category'] = null;
              this.last_items[updated_helper]['hex'] = null;
              this.last_items[updated_helper]['title'] = this.rows_comments[i]['comment'];
              this.last_items[updated_helper]['parent_title'] = this.rows_comments[i]['activity_title'];
              this.last_items[updated_helper]['author'] = this.rows_comments[i]['username'];
              this.last_items[updated_helper]['company'] = this.rows_comments[i]['company'];              
              this.last_items[updated_helper]['company_hex'] = this.rows_comments[i]['company_hex'];
              this.last_items[updated_helper]['message_entity'] = this.rows_comments[i]['parent_object_id']; 
              this.last_items[updated_helper]['viewed'] = this.rows_comments[i]['viewed'];   
              this.last_items[updated_helper]['last_change'] = null;
            }
            
            /* reorder object */            
            var last_items_reorder = [];
            var property_count = Object.keys(this.last_items).length;            
            for (var key in this.last_items) {
              if (this.last_items.hasOwnProperty(key)) {                  
                  last_items_reorder[("0000000000" + property_count).substr(-5)] = this.last_items[key];
                  property_count--;
              }
            }
            this.last_items = last_items_reorder;
            //console.log(this.last_items);

          });

      }
    );  
  }

  edit(entity) {
    var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
    editDialog.componentInstance.entity = entity;
    editDialog.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  remove(id) {
    this.Dashboard.removeWidget(id);
  }

  settings(id, settings) {    
    if (settings == undefined) settings = "{}";

    var WidgetLastSettings = this.WidgetLastSettingsDialog.open(WidgetLastSettingsComponent, {
      'width' : '800px',
      data: JSON.parse(settings),
      id: id
    });    

    WidgetLastSettings.afterClosed().subscribe(result => {   
      if (result.data) {
        this.parameters = result.data;
        this.parameters_json = JSON.stringify(result.data);

        /* save member settings */
        this.data_svc.setMemberSettings({'parameter': id, 'value': this.parameters_json}).subscribe((res: Response) => {           
          //console.log(this.parameters);
          //console.log(this.parameters_json);
          this.loadData();
        });
      }
    });

  }

  prepareDataAndLoad() {    
    if (this.id == 'top') {      
      this.parameters = { title:'#remove_header', assigned: 1, viewed : 1, days : 7 };  
    } else {
      this.parameters = GlobalVariables.memberSettings[this.id] != undefined ? JSON.parse(GlobalVariables.memberSettings[this.id]) : { title: this.translate.instant("Widget Last..."), assigned: 1, viewed : 1, days : 7};      
    }
    this.parameters_json = JSON.stringify(this.parameters);
    this.loadData();

  }

  ngOnInit(): void {
    this.prepareDataAndLoad();    
  }

}
