<!-- Activity -->
<div class="inner_wrapper"> 
 
	<input type="hidden" name="entity" [(ngModel)]="activity['entity']" />

	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<input [(ngModel)]="activity.title" type="hidden" id="activityTitle" />
		<input [(ngModel)]="activity.entity" type="hidden" id="activityEntity" />
        <div class="left" style="height:60px;">
			<div class="last_breadchumbs">
				<span class="activity_category small category_activity_"  [ngStyle]="{'background-color':activity.category_type_hex}" [ngClass]="{'nocategory' : activity.message_type==null || (activity.message_type=='0' && activity.entity != 0)}" (click)="assignCategory(activity.entity, null)">{{activity.category_type_description}}</span>
			</div>
			<h1 class="activity_breadchumbs_title">{{activity.title}}</h1>
        </div>

		<div *ngIf="activity.entity!=0" class="middle">
			<div class="tabs tabsHeader">
				<ng-container *ngIf="!compactView">
					<div class="tab" *ngIf="tasks_count > 0 || subactivityCount > 0" [ngClass]="{'active':selectedTab == 'summary'}" (click)="switchTab('summary')">
						<span>{{'Summary'|translate}}</span>
					</div>
					<div class="tab" *ngIf="tasks_count > 0" [ngClass]="{'active':selectedTab == 'checklist'}" (click)="switchTab('checklist')">
						<span>{{'Checklist'|translate}}</span>
					</div>
					<div class="tab" *ngIf="subactivityCount > 0" [ngClass]="{'active':selectedTab == 'subactivities'}" (click)="switchTab('subActivities')">
						<span>{{'Sub Activities'|translate}}</span>
					</div>				
				</ng-container>
				<div class="new_widgetHeader" *ngIf="!canEdit && (tasks_count == 0 || subactivityCount == 0)" (click)="add_popup=!add_popup">						
					<!--<span *ngIf="tasks_count == 0 && subactivityCount == 0">{{'Add widget'|translate}}</span>-->
					<span style="float:right; padding-top:4px;">{{'Add widget'|translate}}</span>
					<fa-icon class="big" [icon]="['fad', 'grid-2-plus']"></fa-icon>
				</div>
			</div>
		</div>

		<!-- Close --> 
		<div class="right">                     
			<fa-icon class="icon_close" [icon]="['fad', 'times']"  mat-button mat-dialog-close></fa-icon>
		</div>
		<!-- Internal Functions Menu -->
		<ng-container *ngIf="activity.entity!=0">
			<app-internalmenu [component]="component" [localInternalMenu]="localInternalMenu" [executeLocalFunction]="executeLocalFunctionFunc"></app-internalmenu>
		</ng-container>

		<!-- /////////////////// -->
		<!-- Activity Approval -->
		<div *ngIf="!canEdit && assigned_users_names_array.length > 0" class="header_approval">
			<fa-icon class="icon_medium" *ngIf="(activity.approved=='true') || (activity.approved_by_me=='1'&&activity.approved=='false')" [icon]="['fad', 'times-circle']" (click)="removeConfirm(activity.entity)"></fa-icon>
			<fa-icon class="icon_medium" *ngIf="activity.approved=='false'&&activity.approved!='false'" [icon]="['fad', 'check-circle']" [ngClass]="{'checkedbyme' : activity.approved_by_me=='1'}" (click)="toggleConfirm(activity.entity)"></fa-icon>
			<fa-icon class="icon_medium" *ngIf="activity.approved=='true'&&activity.approved_by_me=='1'" [icon]="['fad', 'check-circle']" [ngClass]="{'checkedbyme' : activity.approved=='true'}" (click)="toggleConfirm(activity.entity)"></fa-icon>
			<fa-icon class="icon_medium" *ngIf="activity.approved!='true'&&activity.approved_by_me!='1'" [icon]="['fad', 'check-circle']" style="color:var(--cta-hover)" (click)="toggleConfirm(activity.entity)"></fa-icon>
			<span *ngIf="activity.approved_by_me=='1'&&activity.approved=='false'">{{'Approved by me'|translate}}</span>
			<span *ngIf="activity.approved=='true'">{{'Approved All'|translate}}</span>
			<span *ngIf="activity.approved!='true'&&activity.approved_by_me!='1'">{{'Not approved'|translate}}</span>
		</div>
    </div>


<!--<div *ngIf="activity!=undefined" class="inner_wrapper" [ngClass]="{'newActivityContainer' : activity.entity < 1}" stlye="width:200px">-->
		<!--		
		<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
			<div class="left">
			</div>

			<div *ngIf="!canEdit && !compactView" class="centerHeader desktop">
				<div *ngIf="(!canEdit || selectedTab=='summary') && activity.entity!=0" class="headerWidget" [ngClass]="{'active' : selectedTab=='summary'}" (click)="showSummary()">
					{{'Summary'|translate}}
				</div>				
				<div *ngIf="(tasks_count > 0 || selectedTab=='checklist')" class="headerWidget" [ngClass]="{'active' : selectedTab=='checklist'}" (click)="showTasks()">
					{{'Checklist'|translate}} <span>({{tasks_done}}/{{tasks_count}})</span>
				</div>
				<div *ngIf="(subactivityCount > 0 || new_activity_show) || selectedTab=='subActivities'" class="headerWidget" [ngClass]="{'active' : selectedTab=='subActivities'}" (click)="showSubactivities()">
					{{'Sub activities'|translate}} <span>({{subactivityApproved}}/{{subactivityCount}})</span>					
				</div>
				<div *ngIf="(notes_list?.length > 0 || selectedTab=='sketch')" class="headerWidget" [ngClass]="{'active' : selectedTab=='sketch'}" (click)="showSketches()">
					{{'Sketches'|translate}} <span>({{notes_list?.length}})</span>
				</div>
			</div>
			
			<div class="right">				
				<div class="actions" [ngClass]="{'actions_collapse' : !extended_view}">	
					<fa-icon *ngIf="!canEdit" [icon]="['fad', 'bars']" class="big headerIcon bars mobile" (click)="dropdown = !dropdown"></fa-icon>					
					<div class="header_mobile_inner">
						<span *ngIf="!canEdit" class="shortCuts">
							<fa-icon [icon]="['fad', 'thumbtack']" *ngIf="shortcuts['pin'] && activity.entity!=0"  class="big headerIcon" (click)="toDock(activity.entity, 'activity')"></fa-icon>
							<fa-icon [icon]="['fad', 'calendar-circle-user']" *ngIf="shortcuts['calendar'] && activity.entity!=0 && activity.message_start!=null && activity.message_start!=undefined && activity.message_start!='' && activity.message_start!='0000-00-00 00:00:00'" (click)="pushToCalendar()" class="big headerIcon"></fa-icon>
							<fa-icon [icon]="['fad', 'stopwatch']" *ngIf="shortcuts['timetracking'] && activity.entity!=0"  (click)="addTimeTracking(activity.entity, Global_UserId, activity.customer_id)" class="big headerIcon"></fa-icon>
							<fa-icon [icon]="['fad', 'camera']" *ngIf="shortcuts['capture'] && activity.entity!=0" (click)="capturePhoto();"  class="big headerIcon"></fa-icon>	
							<fa-icon [icon]="['fad', 'microphone']" *ngIf="shortcuts['dictation'] && activity.entity!=0"  class="big headerIcon"></fa-icon>					
							<fa-icon [icon]="['fad', 'print']" *ngIf="shortcuts['pdf'] && activity.entity!=0" (click)="generatePDF()"  class="big headerIcon"></fa-icon>
							<fa-icon [icon]="['fad', 'edit']" *ngIf="shortcuts['edit'] && !canEdit && activity.entity!=0" (click)="canEdit = !canEdit;selectedTab='summary';extended_view=false"  class="big headerIcon"></fa-icon>					
							<fa-icon [icon]="['fad', 'user-lock']" *ngIf="!canEdit && activity.entity!=0 && restriction_view==true" class="big headerIcon active" (click)="restriction_view=false;"></fa-icon>
						</span>

						<div class="centerHeader mobile">							
							<div class="headerWidget" *ngIf="(!canEdit || selectedTab=='summary') && activity.entity!=0" [ngClass]="{'active' : selectedTab=='summary'}" (click)="showSummary()">
								<fa-icon class="big" [icon]="['fad', 'clipboard-list']"></fa-icon>
							</div>							
							<div *ngIf="!canEdit && (tasks_count > 0 || selectedTab=='checklist')" class="headerWidget" [ngClass]="{'active' : selectedTab=='checklist'}" (click)="showTasks()">
								<fa-icon class="big" [icon]="['fad', 'list-check']"></fa-icon>
							</div>
							<div *ngIf="(subactivityCount > 0 || new_activity_show) || selectedTab=='subActivities'" class="headerWidget" [ngClass]="{'active' : selectedTab=='subActivities'}" (click)="showSubactivities">
								<fa-icon class="big" [icon]="['fad', 'list-tree']"></fa-icon>
							</div>
							<div *ngIf="notes_list?.length > 0 || selectedTab=='sketch'" class="headerWidget" [ngClass]="{'active' : selectedTab=='sketch'}" (click)="showSketches">
								<fa-icon class="big" [icon]="['fad', 'pen-swirl']"></fa-icon>
							</div>
						</div>
					</div>
					
					<fa-icon [icon]="['fad', 'times']" style="float:right !important" class="big icon_close" mat-button mat-dialog-close></fa-icon>	
					<fa-icon [icon]="['fad', 'grid-2-plus']" *ngIf="activity.canEdit=='true' && (subactivityCount == 0 || notes_list?.length == 0 || ((!new_activity_show && !edit_task) || viewAddTask) && activity.entity!=0 && (tasks | keyvalue)?.length)" class="big headerIcon add" (click)="add_popup=!add_popup"></fa-icon>				
					<fa-icon [icon]="['fad', 'bars']" *ngIf="!canEdit" class="big headerIcon bars desktop" (click)="dropdown = !dropdown"></fa-icon>
				</div>
			</div>
		</div> 

		<div *ngIf="activity?.parent_id" class="full_breadchrumbs">
			<ng-container *ngFor='let parent_breadchumbs of activity.parent_activity?.rows'>				
				<span class="activity_breadchumbs_title" (click)="getActivity(parent_breadchumbs.entity)">{{parent_breadchumbs.title}}</span>	
				<span class="activity_breadchumbs_chevron"><fa-icon class="small" style="padding:2px 0px 2px 0px" [icon]="['fad', 'chevron-right']"></fa-icon></span>
			</ng-container>
			<span class="activity_breadchumbs_title last" style="float:left;padding-top:3px;color:var(--dark-lvl0)" (click)="getActivity(activity.entity)">{{activity.title}}</span>
		</div>

		-->

	<div class="body" style="padding-top: 0px;" (scroll)="onScroll($event)">


		<div class="activity_top activity_top_{{direction}}">
			<div class="activity_left" [ngClass]="{'activity_left_full_width' : activity.entity==0, 'activity_left_collapse' : !extended_view}">
				<div class="activity_header" *ngIf="activity.entity!=0">

					<!-- Author nad asignees  -->
					<div class="header_user">
						<div class="user_bubble_large" style="background:{{activity.author_hex}}">
							{{getInitials(activity.author)}}
							<div class="tooltip">{{activity.author}}</div>
						</div>
					</div>
					<div class="header_users assign_to_activity" [attr.activity_entity]="activity.entity">
						<span class="user_bubble" *ngFor='let assigned_users_name of assigned_users_names; let i = index' style="background:{{activity.assigned_users_names_hex?.split(',')[i]}}">
							{{assigned_users_name.initials}}
							<div class="tooltip">{{assigned_users_name.fullname}}</div>
			    			<div class="bubble_add_on"></div>
						</span>
						<span class="group_bubble" *ngFor='let assigned_group_name of assigned_group_names; let i = index' style="background:{{activity.assigned_group_names_hex?.split(',')[i]}}">
							{{assigned_group_name.initials}}
							<div class="tooltip">{{assigned_group_name.fullname}}</div>
			    			<div class="bubble_add_on"></div>
						</span>
						<span *ngIf="activity.canEdit=='true'" class="add_bubble" (click)="assignUserGroup(null)">
							<fa-icon [icon]="['fad', 'users']"></fa-icon>
			    			<div class="tooltip">{{'Add/Remove Users or Groups'|translate}}</div>
			    			<div class="bubble_add_on">+</div>
						</span>
					</div>
					
					<!-- /////////////////// -->
					<!-- Priority flag  -->
					<div class="header_priority">						
						<fa-icon *ngIf="activity.entity!=0" [icon]="['fad', 'flag']" [ngClass]="{'notClickable_pointer':activity.canEdit!='true'}" [ngClass]="{'0':'priority0','1':'priority1','2':'priority2','3':'priority3'}[activity.priority]" class="priority_activity icon_medium" [attr.activity_entity]="activity.entity" (click)="assignPriority(activity.entity, null)"></fa-icon>
						<div class="tooltip">{{'Priority'|translate}}</div>
					</div>

					<!-- /////////////////// -->
					<!-- Activity timer -->
					<div class="header_due">
						<span *ngIf="activity.entity!=0" [ngClass]="{'null':'datetime_not_selected'}[calculateTimeToEvent_subactivity(activity.message_start, activity.message_end, null)]" (click)="assignDateTime(activity.entity, activity.message_start, activity.message_end, null)" class="datetime_activity" [attr.activity_entity]="activity.entity">		
							<fa-icon [icon]="['fad', 'clock']" class="clock icon_medium" [ngClass]="{'notClickable':activity.canEdit!='true'}"></fa-icon>
							<fa-icon class="icon_medium" [icon]="['fad', 'arrow-from-left']" [ngClass]="{'arrow-from-left':'display'}[cFunction.showTimeToEventIcon(activity.message_start, activity.message_end, null)]"></fa-icon>
							<fa-icon class="icon_medium" [icon]="['fad', 'arrow-to-right']" [ngClass]="{'arrow-to-right':'display'}[cFunction.showTimeToEventIcon(activity.message_start, activity.message_end, null)]"></fa-icon>
							<fa-icon class="icon_medium" [icon]="['fad', 'arrow-to-right']" [ngClass]="{'chevron-double-right':'display'}[cFunction.showTimeToEventIcon(activity.message_start, activity.message_end, null)]"></fa-icon>
							<span class="hours" [ngClass]="{'blink outofzone' : cFunction.checkIfPassDate(activity.message_end, null)}">
								{{cFunction.showTimeToEvent(activity.message_start, activity.message_end, null)}}
							</span>
						</span>
					</div>

					<!-- /////////////////// -->
					<!-- Extend/Collapse view switcher -->
					<div *ngIf="!extended_view && !canEdit">
						<fa-icon *ngIf="activity.entity!=0" [icon]="['fad', 'chevron-double-left']" class="activity_view_toggle icon_medium" (click)="showSummary();extended_view=true;"></fa-icon>
					</div>
					<span *ngIf="extended_view && !canEdit">
					    <fa-icon *ngIf="activity.entity!=0" [icon]="['fad', 'chevron-double-right']" class="activity_view_toggle icon_medium" (click)="showSummary();extended_view=false;"></fa-icon>
					</span>

					
				</div>
			</div>


			<div *ngIf="activity?.parent_id" class="full_breadchrumbs">
				<ng-container *ngFor='let parent_breadchumbs of activity.parent_activity?.rows'>				
					<span class="activity_breadchumbs_title" (click)="getActivity(parent_breadchumbs.entity)">{{parent_breadchumbs.title}}</span>	
					<span class="activity_breadchumbs_chevron"><fa-icon class="small" style="padding:2px 0px 2px 0px" [icon]="['fad', 'chevron-right']"></fa-icon></span>
				</ng-container>
				<span class="activity_breadchumbs_title last" style="float:left;padding-top:3px;color:var(--dark-lvl0)" (click)="getActivity(activity.entity)">{{activity.title}}</span>
			</div>
			<div *ngIf="!activity?.parent_id && activity.entity!=0" class="full_breadchrumbs empty"></div>
		</div>


		<div class="activity_bottom" (click)="dropdown = false;" [ngClass]="{'hideBottomFunctions' : activity.canEdit!='true'}">
			<div class="activity_bottom_inner">
				
				<ng-container *ngIf="commentsExpand">
					<div class="activity_left">
						<div *ngIf="activity.entity!=0" class="activity_comments activity_addon">
							<div class="activity_comments_header">
								<div class="title">
									<fa-icon [icon]="['fad', 'comments-alt']"></fa-icon>
									{{'Activity comments'|translate}}
									<fa-icon [icon]="['fad', 'expand']" class="expand" (click)="exposeComments();"></fa-icon>
								</div>
								<div class="tools">
									<fa-icon [icon]="['fad', 'chevron-up']" [ngClass]="{'open' : allCommentsView}" (click)="toggleCommentView()"></fa-icon>
									<fa-icon [icon]="['fad', 'chevron-down']" [ngClass]="{'open' : !allCommentsView}" (click)="toggleCommentView()"></fa-icon>
								</div>
								<div class="comments_count" [ngClass]="{'red' : activity['comments'] != undefined}">
									{{commentsCount}}									
								</div>
							</div>
							<ng-container *ngIf="activity.comments">
								<ng-container *ngFor="let comments of activity.comments.rows">
									<ng-container *ngTemplateOutlet="activity_comments_template; context:{ $implicit: comments }"></ng-container>
								</ng-container>					
							</ng-container>
					
							<!-- **** -->
							<div class="new_task" (click)="addGeneralComment()">
								<fa-icon [icon]="['fad', 'circle-plus']"></fa-icon>
								&nbsp;{{'Add new general comment'|translate}}
							</div>								
						</div>
					</div>
				</ng-container>
				
				<ng-container *ngIf="!commentsExpand">					
					<div class="activity_left" [ngClass]="{'activity_left_full_width' : activity.entity==0, 'activity_left_collapse' : !extended_view}">

						<!-- /////////////////// -->
						<!-- Summary -->

						<!--
						<google-map height="500px"
							width="900px"
							[center]="center"
							[zoom]="zoom"
							(mapClick)="moveMap($event)"
							(mapMousemove)="move($event)">
						</google-map>
			
						<div>Latitude: {{display?.lat}}</div>
						<div>Longitude: {{display?.lng}}</div>
						-->

						<ng-container *ngIf="selectedTab=='summary' || compactView">
							<div class="summary_left" [ngStyle]="{'padding-right': activity.entity === 0 ? '0px' : '24px'}"  [ngClass]="{'summaryExtended' :  !canEdit && !extended_view && activity.entity!=0 && (tasks_count > 0 || subactivityCount > 0) && progressChart}">

								<div *ngIf="compactView" class="left_left" [ngClass]="{'noAddOn':progressChart==false && extended_view==false}">

									<!------------------------------>

									<div *ngIf="canEdit" class="title" style="padding: 12px 0px 4px 16px;">
										<fa-icon [icon]="['fas', 'subtitles']"></fa-icon>
										<span>{{'Activity Title'|translate}}</span>
									</div>

									<div class="activity_title">				
										<span *ngIf="!canEdit" [innerHTML]="cFunction.URLtoHYPERLINK(activity.title)"></span>
										<span *ngIf="canEdit"><input [(ngModel)]="activity.title" placeholder="{{'Input Activity title...'|translate}}" type="text" /></span>
										<fa-icon *ngIf="canEdit" class="icon_medium microphone" [icon]="['fad', 'microphone']"></fa-icon>
										<!--<div *ngIf="canEdit" class="label">{{'Activity title'|translate}}</div>-->
									</div>

									<!------------------------------>

									<div *ngIf="activity.status==1" class="activity_status">
										<span [ngStyle]="{'background': activity_status_hex}" (click)="selectStatus(activity.entity)">
											{{this.activity_status_description}}
										</span>
									</div>

									<!------------------------------>

									<div class="splitter"></div>

									<!------------------------------>

									<div style="float:left;width:100%">
										<div *ngIf="canEdit" class="activity_company" [ngClass]="{'clickable' : canEdit}" (click)="selectCustomer()">
											<fa-icon [icon]="['fad', 'user']"></fa-icon>
											{{activity.customer}} #1
										</div>
									</div>
									
									<fa-icon *ngIf="canEdit && activity.customer_id != undefined && activity.customer_id != null && activity.customer_id != ''" [icon]="['fad', 'times']" class="remove_company" (click)="removeCompany()"></fa-icon>

									<div style="float:left;width:100%">
										<div *ngIf="!canEdit" class="activity_company" style="cursor: pointer;" (click)="selectCustomer()">
											<fa-icon [icon]="['fad', 'user']"></fa-icon>
											{{activity.customer}}
										</div>
									</div>

									<ng-container *ngIf="!canEdit">
										<ng-container *ngFor='let eachitem of assigned_contacts  | keyvalue; let i = index'>
											<div *ngIf="assigned_contacts[i]['status']" class="contact_item" [ngClass]="{'selected' : assigned_contacts[i]['status']}">																
												<div class="name">{{assigned_contacts[i]['name']}} {{assigned_contacts[i]['surename']}},&nbsp;<span class="function">{{assigned_contacts[i]['contact_type_name']}}&nbsp;</span></div>
												<span class="phone">{{assigned_contacts[i]['phone']}}</span>
												<span *ngIf="assigned_contacts[i]['phone']!='' && assigned_contacts[i]['phone']!=undefined" class="phone">&nbsp;|&nbsp;</span>									
												<span class="phone">{{assigned_contacts[i]['mobile']}}</span>
											</div>						
										</ng-container>
									</ng-container>

									<fa-icon *ngIf="!canEdit" class="add-contact" [icon]="['fad', 'phone-plus']" (click)="selectContacts(activity.customer_id)"></fa-icon>

									<!------------------------------>									

									<div *ngIf="!canEdit" class="splitter"></div>

									<!------------------------------>

									<div *ngIf="!canEdit" class="summary_dates">						
										<ng-container *ngIf="activity.message_start!='0000-00-00 00:00:00'">								
											<span><fa-icon [icon]="['fad', 'arrow-from-left']"></fa-icon><span>{{cFunction.localDateFormat(activity.message_start)}}</span></span>
											<br />
											<span><fa-icon [icon]="['fad', 'arrow-to-right']"></fa-icon><span>{{cFunction.localDateFormat(activity.message_end)}}</span></span>
											<br />
											<br />							
										</ng-container>	
										<span><fa-icon [icon]="['fad', 'calendar-day']"></fa-icon><span>{{cFunction.localDateFormat(activity.created)}}</span></span>
										<br />
										<span><fa-icon [icon]="['fad', 'pencil']"></fa-icon><span>{{cFunction.localDateFormat(activity.updated)}}</span></span>				
										<br />
										<span><fa-icon [icon]="['fad', 'eye']"></fa-icon><span>{{activity.viewed}} {{'Times'|translate}}</span></span>
									</div>

								</div>

								<div [ngClass]="{'left_right':compactView, 'noAddOn':progressChart==false && extended_view==false}">
									<input [(ngModel)]="activity.customer_id" type="hidden" id="activityCustomerId" />
									<input [(ngModel)]="activity.customer" type="hidden" id="activityCustomer" />

									<!------------------------------>

									<div *ngIf="!compactView" class="activity_company_and_status">						
										<div *ngIf="canEdit" class="activity_company" [ngClass]="{'clickable' : canEdit}" (click)="selectCustomer()">
											<fa-icon [icon]="['fad', 'user']"></fa-icon>
											{{activity.customer}} #2								
										</div>
										
										<fa-icon *ngIf="canEdit && activity.customer_id != undefined && activity.customer_id != null && activity.customer_id != ''" [icon]="['fad', 'times']" class="remove_company" (click)="removeCompany()"></fa-icon>

										<div *ngIf="!canEdit" class="activity_company" style="cursor: pointer;" (click)="openCustomer()">
											<fa-icon [icon]="['fad', 'user']"></fa-icon>
											{{activity.customer}} #3		
										</div>			

										<ng-container *ngIf="!canEdit">
											<ng-container *ngFor='let eachitem of assigned_contacts  | keyvalue; let i = index'>
												<div *ngIf="assigned_contacts[i]['status']" class="contact_item" [ngClass]="{'selected' : assigned_contacts[i]['status']}">																
													<div class="name">{{assigned_contacts[i]['name']}} {{assigned_contacts[i]['surename']}},&nbsp;<span class="function">{{assigned_contacts[i]['contact_type_name']}}&nbsp;</span></div>
													<span class="phone">{{assigned_contacts[i]['phone']}}</span>
													<span *ngIf="assigned_contacts[i]['phone']!='' && assigned_contacts[i]['phone']!=undefined" class="phone">&nbsp;|&nbsp;</span>									
													<span class="phone">{{assigned_contacts[i]['mobile']}}</span>
												</div>						
											</ng-container>
										</ng-container>

										<fa-icon *ngIf="!canEdit" class="add-contact" [icon]="['fad', 'phone-plus']" (click)="selectContacts(activity.customer_id)"></fa-icon>

										<div *ngIf="activity.status==1" class="activity_status">
											<span [ngStyle]="{'background': activity_status_hex}" (click)="selectStatus(activity.entity)">
												{{this.activity_status_description}}
											</span>
										</div>
									</div>

									<!------------------------------>

									<div *ngIf="!compactView" class="activity_title">				
										<span *ngIf="!canEdit" [innerHTML]="cFunction.URLtoHYPERLINK(activity.title)"></span>
										<span *ngIf="canEdit"><input [(ngModel)]="activity.title" placeholder="{{'Input Activity title...'|translate}}" type="text" /></span>
										<fa-icon *ngIf="canEdit" class="icon_medium microphone" [icon]="['fad', 'microphone']"></fa-icon>
										<div *ngIf="canEdit" class="label">{{'Activity title'|translate}}</div>									
									</div>
												

									<div *ngIf="compactView" class="title">
										<fa-icon [icon]="['fas', 'subtitles']"></fa-icon>
										<span>{{'Activity description'|translate}}</span>
									</div>

									<div *ngIf="!canEdit" class="activity_subtitle">
										<span [innerHTML]="cFunction.URLtoHYPERLINK(activity.sub_title)"></span>
									</div>
									<div *ngIf="canEdit" class="activity_subtitle">							
										<span [ngClass]="{'hideNoEditor':(editor != 'noeditor' && editor != null && editor != '')}">							
											<textarea id="noEditor" [(ngModel)]="activity.sub_title" placeholder="{{'Input Activity description...'|translate}}"></textarea>
										</span>
										<fa-icon class="icon_medium microphone" [icon]="['fad', 'microphone']"></fa-icon>

										<ng-container *ngIf="canEdit && editor == 'light'">
											<quill-editor 								
												[styles]="{'min-height': '240px'}" 
												(onEditorChanged)="changedEditor($event)"
												(onEditorCreated)="created($event)"
												[modules]="modules_light"									
												id="lightEditor" placeholder="{{'Input Activity description...'|translate}}">
											</quill-editor>
										</ng-container>

										<ng-container *ngIf="canEdit && editor == 'extended'">
											<quill-editor 
												[styles]="{'min-height': '240px'}" 
												(onEditorChanged)="changedEditor($event)"
												(onEditorCreated)="created($event)"
												[modules]="modules_extended"									
												id="extendedEditor" placeholder="{{'Input Activity description...'|translate}}">
											</quill-editor>
										</ng-container>
										
										<input [(ngModel)]="activity.parent_id" type="hidden" />
									</div>
									

									<div *ngIf="canEdit && activity.has_value" class="block_title">
										<fa-icon [icon]="['fad', 'info-circle']"></fa-icon> {{'Activity details'|translate}}
									</div>

									<div *ngIf="canEdit && activity.has_value" class="activity_subtitle input_inline" style="margin-right: 12px;">			
										<span><input style="max-width:250px" [(ngModel)]="activity.value" placeholder="eg. 599,99" type="text" /></span>
										<div class="label">{{'Activity value (EUR)'|translate}}</div>
									</div>

									<div *ngIf="canEdit && activity.has_value" class="activity_subtitle input_inline">
										<span><input style="max-width:250px" [(ngModel)]="activity.hours" placeholder="eg. 10:15 (hh:mm)" type="text" /></span>
										<div class="label">{{'Estimated activity time (hh:mm)'|translate}}</div>
									</div>	
		
									<div *ngIf="canEdit" class="activity_save_wrapper" [ngStyle]="{'padding-right': activity.entity === 0 ? '0px' : 'initial'}">	
										<button *ngIf="activity.entity!=0" style="margin-top:0px" class="left warrning" (click)="deleteActivity()">{{'Delete'|translate}}</button>
										<button *ngIf="activity.entity != 0" style="margin-top:0px" class="right accept" (click)="saveActivity()">{{'Save'|translate}}</button>
										<button *ngIf="activity.entity == 0" style="margin-top:0px" class="right accept" (click)="saveActivity()">{{'Next'|translate}}</button>
										<button style="margin-top:0px" class="right" *ngIf="activity.entity!=0" (click)="cancelChanges()">{{'Cancel'|translate}}</button> 
										<button style="margin-top:0px" class="right" *ngIf="activity.entity==0" mat-dialog-close>{{'Cancel'|translate}}</button>
									</div>
									
									<!-- //////////////////////////// -->
									<!-- show widgets in compact view -->
									<!-- //////////////////////////// -->
									<div *ngIf="!canEdit && compactView" class="companctViewWidgets">								
										<ng-container *ngTemplateOutlet="tasks"></ng-container>
										<ng-container *ngTemplateOutlet="subActivities"></ng-container>
									</div>							

									<!-- Shortcuts -->
									<div class="shortcutsWrapper" *ngIf="!canEdit && !compactView">
										<div class="shortcuts">								
											<!--<span *ngIf="(!canEdit) && activity.entity!=0" (click)="selectedTab='summary'"><fa-icon [icon]="['fad', 'square-chevron-right']"></fa-icon><span>{{'Summary'|translate}}</span></span>-->
											<span *ngIf="!canEdit && tasks_count > 0" (click)="selectedTab='checklist'"><fa-icon [icon]="['fad', 'square-chevron-right']"></fa-icon><span>{{'Checklist'|translate}}</span></span>
											<span *ngIf="(subactivityCount > 0 || new_activity_show)" (click)="selectedTab='subActivities'"><fa-icon [icon]="['fad', 'square-chevron-right']"></fa-icon><span>{{'Sub activities'|translate}}</span></span>
											<!--<span *ngIf="notes_list?.length > 0" (click)="selectedTab='sketch'"><fa-icon [icon]="['fad', 'square-chevron-right']"></fa-icon><span>{{'Sketches'|translate}}</span></span>-->
										</div>
									</div>
									
									<!-- dates -->
									<div *ngIf="!addingActivity && !canEdit && !compactView" class="summary_dates">						
										<ng-container *ngIf="activity.message_start!='0000-00-00 00:00:00'">								
											<span><fa-icon [icon]="['fad', 'arrow-from-left']"></fa-icon><span>{{cFunction.localDateFormat(activity.message_start)}}</span></span>
											<span><fa-icon [icon]="['fad', 'arrow-to-right']"></fa-icon><span>{{cFunction.localDateFormat(activity.message_end)}}</span></span>
											<br />							
										</ng-container>	
										<span><fa-icon [icon]="['fad', 'calendar-day']"></fa-icon><span>{{cFunction.localDateFormat(activity.created)}}</span></span>
										<span><fa-icon [icon]="['fad', 'pencil']"></fa-icon><span>{{cFunction.localDateFormat(activity.updated)}}</span></span>				
									</div>
								</div>
							</div>
							
							<!--<div class="new_widget" *ngIf="activity.canEdit=='true' && (subactivityCount == 0 || notes_list?.length == 0 || ((!new_activity_show && !edit_task) || viewAddTask) && activity.entity!=0 && (tasks | keyvalue)?.length)" (click)="add_popup=!add_popup">	-->

							<ng-container *ngIf="activity.entity!=0 && (tasks_count > 0 || subactivityCount > 0) && !canEdit && progressChart">								
								<div *ngIf="!extended_view" [ngClass]="{'summaryExtended' : !extended_view}" class="summary_right">
									<div class="task_progress {{direction}}">			
										<div class="progress" (click)="showSummary()">
											{{'Activity progress'|translate}}	
											<!--<fa-icon [icon]="['fad', 'arrows-rotate']" (click)="generateChart()"></fa-icon>-->
										</div>							
										<div class="mGraph-wrapper">
											<div class="mGraph" id="progressChart"></div>
										</div>
										<div class="overallProgressPercent">{{'Activity completed'|translate}} {{overallProgressText}}%</div>
										
									</div>
								</div>						
							</ng-container>
						</ng-container>
						<!-- /////////////////// -->
						

						<!-- /////////////////// -->					
						<!-- Tasks -->						
						<ng-container *ngIf="selectedTab=='checklist' && !canEdit && countObject(tasks) > 0 && !compactView">
							<ng-container *ngTemplateOutlet="tasks"></ng-container>
						</ng-container>					
						<!-- /////////////////// -->
											
						<!-- /////////////////// -->
						<!-- SubActivities -->
						<ng-container *ngIf="selectedTab=='subActivities' && !compactView">
							<ng-container *ngTemplateOutlet="subActivities"></ng-container>
						</ng-container>					
						<!-- /////////////////// -->
						
						<!-- /////////////////// -->
						<!-- Sketch -->
						<ng-container *ngIf="selectedTab=='sketch' '' !compactView">
							<!--
							<div *ngIf="notes_list?.length > 0 || new_activity_show" class="title sketches">
								<fa-icon [icon]="['fad', 'pen-swirl']"></fa-icon>
								<span>{{'Sketches'|translate}}</span>
							</div>
							-->
							<!--div class="file_folder_toolbar" style="float:right;">			
								<fa-icon [icon]="['fad', 'grid-2']" (click)="grid=2;"></fa-icon>
								<fa-icon [icon]="['fad', 'grid']" (click)="grid=4;"></fa-icon>
							</div-->

							<ng-container *ngIf="activity.canEdit=='true' && new_activity_show==false && !canEdit">
								<div *ngIf="notes_list?.length > 0" class="notes_list">
									<div *ngFor="let note of notes_list | keyvalue" class="note_item" [ngClass]="{'big' : grid == 2, 'small' : grid == 4}">
										<div class="file_icon">
											<fa-icon [icon]="['fas', 'pen-swirl']"></fa-icon>
										</div>
										<div class="file_title" (click)="editNotes(note.value['entity'])">
											<strong>{{note.value['title']}}</strong><br>
										</div>
										<div class="file_photo imgPreviewClick"></div>
										<div class="file_hover">
											<div class="tools">
												<fa-icon [icon]="['fad', 'download']"></fa-icon>
												<fa-icon [icon]="['fad', 'trash-alt']"></fa-icon>
											</div>				
										</div>
				
										<div class="file_date">
											<span>{{note.value['created']}}</span>
										</div>
									</div>
								</div>
							</ng-container>
							<!--div class="add_sketch">
								<fa-icon [icon]="['fad', 'plus-circle']" style="margin-right: 8px;"></fa-icon>{{'Add new Sketch'|translate}}
							</div-->
						</ng-container>
						<!--<div *ngIf="notes_list?.length > 0 && new_activity_show==false && !canEdit" class="activity_funtion_data_divider"></div>-->
						<!-- /////////////////// -->
						


						<!-- /////////////////// -->
						<!-- ecommunicator -->
						<div *ngIf="activity.etalk != null" class="ecommunicator">
							<div class="title">
								eTALK <span>[#{{activity.etalk}}]</span>
								<div class="reply" (click)="etalk_send_view = !etalk_send_view;">
									<ng-container *ngIf="!etalk_send_view"><fa-icon [icon]="['fad', 'reply']"></fa-icon>{{'Reply'|translate}}</ng-container>
								</div>
							</div>
							
							<div *ngIf="etalk_send_view" class="eTalk_send">
								<div class="etalk_Advanced" (click)="etalk_advanced = !etalk_advanced">								
									{{'Advanced/Simple view'|translate}}
									<fa-icon *ngIf="!etalk_advanced" [icon]="['fad', 'chevron-down']"></fa-icon>
									<fa-icon *ngIf="etalk_advanced" [icon]="['fad', 'chevron-up']"></fa-icon>
								</div>

								<input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="eTalk_email" [ngClass]="{'hide' : etalk_advanced == false}" id="eTalk_email" placeholder="{{'Add custom emails, divided by comma...'|translate}}" />
								<div class="label" [ngClass]="{'hide' : etalk_advanced == false}">{{'Recipients email'|translate}}</div>

								<input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="eTalk_cc" [ngClass]="{'hide' : etalk_advanced == false}" id="eTalk_email" placeholder="{{'Add copy emails, divided by comma...'|translate}}" />
								<div class="label" [ngClass]="{'hide' : etalk_advanced == false}">{{'Copy to email'|translate}}</div>

								<input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="eTalk_subject" [ngClass]="{'hide' : etalk_advanced == false}" id="eTalk_subject" placeholder="{{'Add custom subject...'|translate}}" />
								<div class="label" [ngClass]="{'hide' : etalk_advanced == false}">{{'Subject'|translate}}</div>
																					
								<quill-editor 
									[styles]="{'min-height': '240px'}" 
									(onEditorChanged)="changedEditor_eTask($event)"								
									[modules]="modules_extended"
									[ngModelOptions]="{standalone: true}" [(ngModel)]="eTalk_content" placeholder="{{'eTalk to cusomter...'|translate}}">
								</quill-editor>
								<div class="label" style="width:50% !important; float:left;">{{'eTalk Content'|translate}}</div>
							
					
								<textarea id="eTalk_footer" [ngClass]="{'hide' : etalk_advanced == false}" placeholder="{{'eTalk custom footer...'|translate}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="footer"></textarea>
								<div class="label" [ngClass]="{'hide' : etalk_advanced == false}" style="width:50%; float:left;">{{'eTalk Footer'|translate}}</div>	
								
								<div class="submit" (click)="sendETalk()">{{'Send message via eTalk'|translate}}</div>
							</div>
	
					
							<ng-container *ngFor="let item of etalk">
								<div class="c_item receive readed">
									<div *ngIf="item.direction=='IN'" class="from from_in"><fa-icon [icon]="['fad', 'inbox-in']" style="margin-right: 12px;"></fa-icon>{{item.email_name}} <span>{{item.email}}</span></div>
									<div *ngIf="item.direction=='OUT'" class="from from_out"><fa-icon [icon]="['fad', 'inbox-out']" style="margin-right: 12px;"></fa-icon>{{item.email_name}} <span>{{item.email}}</span></div>

									<div class="when"><fa-icon [icon]="['fad', 'calendar']" style="margin-right: 12px;"></fa-icon>{{item.email_time}}</div>
									<div class="unlink"><fa-icon [icon]="['fad', 'link-slash']" (click)="unlink_etask(item.entity)"></fa-icon></div>
									<div *ngIf="item.hasAttachments==1" class="etalk_attachment"><fa-icon [attr.rel]="item.entity" [icon]="['fad', 'paperclip']" [ngClass]="{'active_icon' : attachmentslength > 0}" (click)="getETalkAttachments(item.entity)"></fa-icon></div>
									<div class="body" [innerHTML]="item.body"></div>

									<!-- *** -->
									<div class="etalk_attachment_container etalk_rel_{{item.entity}}">
										<div class="etalk_attachment_close">
											<fa-icon [icon]="['fad', 'xmark']"></fa-icon>
										</div>
										<div class="attachment_content">
											<ng-container *ngFor="let item of eTalkAttachments"> 
												<div class="etalk_attachment_item" (click)="download(item.filename, item.original_filename)">
													<div class="icon">
														<fa-icon [icon]="['fad', 'file']"></fa-icon>
													</div>
													<div class="filename">
														<span class="folder">{{item.folder}}</span>/<span class="original_filename">{{item.original_filename}}</span>
													</div>
													<div class="dilesize">
														{{formatBytes(item.filesize, 1)}}													
													</div>

												</div>
											</ng-container>
										</div>
									</div>
								</div>
							</ng-container>
						</div>					
						<!-- /////////////////// -->
					</div> 
				</ng-container>

				<div class="activity_right" *ngIf="activity.entity!=0" [ngClass]="{'activity_right_collapse' : !extended_view}" >
					
					<div class="activity_right_inner {{direction}}" *ngIf="!restriction_view && extended_view">						

						<!--------------------------------------------------------------------->

						<div *ngIf="activity.entity!=0 && extended_view && activity.has_value && (activity.hours != null && activity.formated_value != '“0,00')" class="activity_value activity_addon">
							<div class="title">
								<fa-icon [icon]="['fad', 'info-circle']"></fa-icon>{{'Activity values'|translate}}
							</div>
							<div class="tools">
								<!--
								<fa-icon [icon]="['fad', 'chevron-up']"></fa-icon>
								<fa-icon [icon]="['fad', 'chevron-down']"></fa-icon>
								-->
							</div>	
							<div class="activity_value_details">
								<div class="value_label">{{'Total activity value'|translate}}</div><div class="value_value">{{activity.formated_value}}</div>
								<div class="value_label">{{'Estimated time (hh:mm)'|translate}}</div><div class="value_value">{{activity.hours}}</div>
							</div>
						</div>	

						<!--------------------------------------------------------------------->

						<div *ngIf="activity.entity!=0 && extended_view && !commentsExpand" class="activity_comments activity_addon">
							<div class="activity_comments_header">
								<div class="title">
									<fa-icon [icon]="['fad', 'comments-alt']"></fa-icon>
									{{'Activity comments'|translate}}
									<fa-icon [icon]="['fad', 'expand']" class="expand" (click)="exposeComments();"></fa-icon>
								</div>
								<div class="tools">
									<fa-icon [icon]="['fad', 'chevron-up']" [ngClass]="{'open' : allCommentsView}" (click)="toggleCommentView()"></fa-icon>
									<fa-icon [icon]="['fad', 'chevron-down']" [ngClass]="{'open' : !allCommentsView}" (click)="toggleCommentView()"></fa-icon>
								</div>
								<div class="comments_count" [ngClass]="{'red' : activity['comments'] != undefined}">
									{{commentsCount}}									
								</div>
							</div>
							<ng-container *ngIf="activity.comments">
								<ng-container *ngFor="let comments of activity.comments.rows">
									<ng-container *ngTemplateOutlet="activity_comments_template; context:{ $implicit: comments }"></ng-container>
								</ng-container>					
							</ng-container>
					
							<!-- **** -->
							<div class="new_task" (click)="addGeneralComment()">
								<fa-icon [icon]="['fad', 'circle-plus']"></fa-icon>
								&nbsp;{{'Add new general comment'|translate}}
							</div>								
						</div>	

						<!--------------------------------------------------------------------->

						<div *ngIf="(attachmentslength > 0 && extended_view) || (activity.entity!=0 && attachment_view!=false && extended_view)&&Attachments.length>0" class="activity_attachment activity_addon">
							<div class="activity_attachment_header">
								<div class="title">
									<fa-icon [icon]="['fad', 'paperclip']"></fa-icon>{{'Attachments'|translate}}
								</div>
								<div class="attachment_count">
									<span>{{Attachments.length}} {{'Items'|translate}}</span>

									<div for="fileDropRef" (click)="fileDropRef.click()" style="float:right;" class="uploadButton">
										<fa-icon [icon]="['fad', 'cloud-arrow-up']"></fa-icon>
									</div>
									<div style="float:right;" class="archiveButton" (click)="redirectToDocumentArchive()">
										<fa-icon [icon]="['fad', 'box-archive']"></fa-icon>
									</div>
								</div>
								<div class="tools">									
									<fa-icon [icon]="['fad', 'chevron-up']" [ngClass]="{'open' : allAttachmentView}" (click)="toggleAttachmentView()"></fa-icon>
									<fa-icon [icon]="['fad', 'chevron-down']" [ngClass]="{'open' : !allAttachmentView}" (click)="toggleAttachmentView()"></fa-icon>									
								</div>
							</div>
							<ng-container *ngIf="Attachments && allAttachmentView">					
								<ng-container *ngFor="let eachAttachment of Attachments">
									<ng-container *ngTemplateOutlet="activity_attachment_template; context:{ $implicit: eachAttachment }"></ng-container>
								</ng-container>					
							</ng-container>
						</div>

						<!--------------------------------------------------------------------->

						<div *ngIf="assigned_users_names_array.length>0" class="activity_comments activity_addon block_line">
							<div class="activity_assignees_header">
								<div class="title">
									<fa-icon [icon]="['fad', 'users']"></fa-icon>{{'Activity assignees'|translate}}
								</div>
								<div class="tools">
									<fa-icon [icon]="['fad', 'chevron-up']" [ngClass]="{'open' : allAssigneesView}" (click)="toggleAssigneesView();"></fa-icon>
									<fa-icon [icon]="['fad', 'chevron-down']" [ngClass]="{'open' : !allAssigneesView}" (click)="toggleAssigneesView();"></fa-icon>
								</div>								
							</div>
							<ng-container *ngIf="allAssigneesView">
								<ng-container *ngFor="let aseegnee of assigned_users_names_array | keyvalue; let i = index">
									<div class="assignees_full_name" (click)="edit_user(assigned_users_array[i])"><span style="background:{{assigned_users_names_hex_array[i]}}"></span>{{assigned_users_names_array[i]}}</div>
								</ng-container>
							</ng-container>
						</div>					

						<!--------------------------------------------------------------------->

						<div *ngIf="activity.entity!=0 && timetracking_view!=false && extended_view && timetrackinglength > 0" class="activity_timetracking activity_addon">
							<div class="activity_timetracking_header">
								<div class="title">
									<fa-icon [icon]="['fad', 'user-clock']"></fa-icon>{{'Timetracking report'|translate}}
								</div>
								<div class="tools">
									<fa-icon [icon]="['fad', 'chevron-up']" [ngClass]="{'open' : allTimeTrackingView}" (click)="toggleTimeTrackingView()"></fa-icon>
									<fa-icon [icon]="['fad', 'chevron-down']" [ngClass]="{'open' : !allTimeTrackingView}" (click)="toggleTimeTrackingView()"></fa-icon>
								</div>
								<div class="timetracking_count">
									{{timetrackingCount}}
								</div>
							</div>
							<ng-container *ngIf="TimeTracking">					
								<ng-container *ngFor="let eachTimeTracking of TimeTracking">
									<ng-container *ngTemplateOutlet="activity_timetracking_template; context:{ $implicit: eachTimeTracking }"></ng-container>
								</ng-container>					
							</ng-container>
						</div>

						<!--------------------------------------------------------------------->
			
						<div *ngIf="activity.entity!=0 && show_history!=false && extended_view && this.history.length > 1" class="activity_history activity_addon">
							<div class="activity_history_header">
								<div class="title">
									<fa-icon [icon]="['fad', 'history']"></fa-icon>{{'Activity history'|translate}}
								</div>
								<div class="tools">
									<fa-icon [icon]="['fad', 'chevron-up']" [ngClass]="{'open' : allHistoryView}" (click)="toggleHistoryView()"></fa-icon>
									<fa-icon [icon]="['fad', 'chevron-down']" [ngClass]="{'open' : !allHistoryView}" (click)="toggleHistoryView()"></fa-icon>
								</div>
								<div class="history_count">
									{{historyCount}}
								</div>
							</div>
							<ng-container *ngIf="history">					
								<ng-container *ngFor="let eachHistory of history">
									<ng-container *ngTemplateOutlet="activity_history_template; context:{ $implicit: eachHistory }"></ng-container>
								</ng-container>					
							</ng-container>
						</div>

					</div>	

					<div *ngIf="activity.entity!=0 && restriction_view && extended_view" class="activity_restriction">
						<div class="activity_attachment_header">
							<div class="title">
								<fa-icon [icon]="['fad', 'user-lock']"></fa-icon>{{'Activity restriction override'|translate}}
							</div>						
						</div>						

						<ng-container>
							<div class="restriction_item_header">
								<div class="user_item">
									{{'Member'|translate}}
								</div>
								<div class="allow_to_read">
									{{'View'|translate}}
								</div>
								<div class="allow_to_edit">
									{{'Edit'|translate}}
								</div>
							</div>
							
							<ng-container *ngFor="let member of members; let i = index">
								<div class="restriction_item">
									<div class="user_item">
										{{member.username}} {{member['view']}}
									</div>
									<div class="allow_to_read">
										<label class="switch" (click)="changeUserRestriction(i, member.entity, activity.entity, 'view', member['view'])">
											<input type="checkbox" [checked]="member['view']" />
											<span class="slider round"></span>
										</label>
									</div>
									<div class="allow_to_edit">
										<label class="switch" (click)="changeUserRestriction(i, member.entity, activity.entity, 'edit', member['edit'])">
											<input type="checkbox" [checked]="member['edit']" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
							</ng-container>					
						</ng-container>

						<div class="line"></div>

						<ng-container>
							<div class="restriction_item_header">
								<div class="user_item">
									{{'Member Group'|translate}}
								</div>
								<div class="allow_to_read">
									{{'Read'|translate}}
								</div>
								<div class="allow_to_edit">
									{{'Edit'|translate}}
								</div>
							</div>
							<ng-container *ngFor="let group of member_groups; let i = index">
								<div class="restriction_item">
									<div class="user_item">
										{{group.member_group}}										
									</div>
									<div class="allow_to_read">
										<label class="switch" (click)="changeGroupRestriction(i, group.entity, activity.entity, 'view', group['view'])">
											<input type="checkbox" [checked]="group['view']" />
											<span class="slider round"></span>
										</label>
									</div>
									<div class="allow_to_edit">
										<label class="switch" (click)="changeGroupRestriction(i, group.entity, activity.entity, 'edit', group['edit'])">
											<input type="checkbox" [checked]="group['edit']" />
											<span class="slider round"></span>
										</label>
									</div>
								</div>
							</ng-container>	
						</ng-container>						

					</div>
				</div>
			</div> <!-- activity bottom inner -->
		</div> <!-- activity bottom -->
	<!--</form>-->


	<div class="file_container_activity" appDragAndDrop (fileDropped)="onFileDropped($event)" style="visibility:hidden; opacity:0">
		<input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target['files'])" />	
		<div class="dropdown_infocontainer center">
			<div *ngIf="files" class="info dropdowninfo" style="text-align: center;">
				{{'Drop files here.#6'|translate}}<br /><br />
				<fa-icon [icon]="['fad', 'times']" class="closeDropField" style="font-size: 48px" (click)="closeDropField_activity()"></fa-icon>
			</div>
			<div class="progress_container">
				<div *ngFor="let file of files" class="uploading_progress">
					<div class="progress_filename">
						{{file.name}} - {{formatBytes(file.size, 1)}}
					</div>
					<div class="progress">
						<div class="progress-value" style="width:{{file.progress}}%"></div>
					</div>
				</div>
			</div>
		</div> 
	</div>



	</div><!-- body -->

</div>























<!-- ***************************** -->

<!-- *** -->
<div class="wizzard_container">
	<div class="wizzard_selector">
		<button class="wizzard_item add" (click)="activityWizzardSetup()">{{'Save current setup'|translate}}</button>
		<button class="wizzard_item cancel0">{{'Cancel'|translate}}</button>
		
		<div class="wizzard_container_items">
			<ng-container *ngFor="let item of activityWizzards">
				<div class="wizzard_item" (click)="activityWizzardUse(item['entity'])" [attr.index]="item['entity']">{{item['title']}}</div>
				<div class="wizzard_item_delete">
					<fa-icon [icon]="['fad', 'trash-alt']" style="font-size: 24px" (click)="deletePrefill(item['entity'])"></fa-icon>
				</div>
			</ng-container>		
		</div>
	</div>
</div>

<div *ngIf="activityWizzardPopup" class="wizzard_container_approve">
	<div *ngIf="activitiesWizzardCreateMode" class="templateNameTitle">{{'Template name'|translate}}</div>
	<div *ngIf="activitiesWizzardCreateMode" class="templateName"><input [(ngModel)]="templateName"></div>
	<div class="activity_container_header">
		<div class="activity_enable">#</div>
		<div class="activity_title">{{'Activity title'|translate}}</div>
		<div class="activity_assignation">{{'Assigned to'|translate}}</div>
		<div class="activity_priority"></div>
		<div class="activity_duration">{{'Duration'|translate}}</div>
		<div class="activity_category">{{'Category'|translate}}</div>		
	</div>
	<ng-container *ngTemplateOutlet="activityWizzard; context:{ $implicit: activeActivitiesWizzard }"></ng-container>
	<div class="actions">
		<div *ngIf="activitiesWizzardCreateMode" class="button" style="margin-right:0px;" (click)="activityWizzardSave();">
			{{'Save'|translate}}
		</div>
		<div *ngIf="!activitiesWizzardCreateMode" class="button" style="margin-right:0px;" (click)="activityWizzardApprove();">
			{{'Use'|translate}}
		</div>
		<div class="button" (click)="activityWizzardHide();">
			{{'Cancel'|translate}}
		</div>
	</div>
</div>

<ng-template #activityWizzard let-activities>
	<ng-container *ngFor="let activity of activities | keyvalue">
		<ng-container *ngIf="activity.value['title']!=undefined">
			<div *ngIf="activity.value['entity']!='new'" class="activity_container">
				<div class="activity_enable">
					<label class="switch">
						<input type="checkbox" [(ngModel)]="activity.value['enabled']" (click)="enableDisableWizzard(activity.value['entity'], $event)"/>
						<span class="slider round"></span>
					</label>	
				</div>
				<div class="activity_title">
					{{activity.value['title']}}
				</div>
				<div class="activity_assignation">			
					<div class="cell_inner">
						<ng-container *ngFor='let assigned_users_name of ShowUserInicials(activity.value["assigned_users_names"])'>
							<span  class="user_bubble">
								{{assigned_users_name.initials}}
								<!--<div class="tooltip">{{assigned_users_name.fullname}}</div>-->
							</span>
						</ng-container>
						<ng-container *ngFor='let assigned_group_name of ShowUserInicials(activity.value["assigned_group_names"])'>
							<span  class="group_bubble">
								{{assigned_group_name.initials}}
								<!--<div class="tooltip">{{assigned_group_name.fullname}}</div>-->
							</span>
						</ng-container>				
					</div>
				</div>
				<div class="activity_priority">
					<fa-icon [icon]="['fad', 'flag']" [ngClass]="{'0':'priority0','1':'priority1','2':'priority2','3':'priority3'}[activity.value['priority']]" class="priority_activity"></fa-icon>		
				</div>
				<div class="activity_duration">
					{{cFunction.calculateOffsetInDaysHours(activity.value['message_start'], activity.value['message_end'])}}
				</div>
				<div class="activity_category">			
					<span [ngStyle]="{'background-color':activity.value['category_type_hex']}">{{activity.value['category_type_description']}}</span>
				</div>			
				
				<ng-container *ngFor="let sub_activity of activity.value['sub_activity'] | keyvalue">				
					<ng-container *ngTemplateOutlet="activityWizzard; context:{ $implicit: sub_activity }"></ng-container>
				</ng-container>
				
			</div>
		</ng-container>
	</ng-container>
</ng-template>

<!-- *** -->
<ng-template #activity_attachment_template let-attachment>
	<div class="browserITEM browserITEMfile">
		<div class="lt_rt_wrapper">	
			<div class="lt_rt">								
				<fa-icon [icon]="['fad', showFileIcon(attachment.ext)]" (click)="download(attachment.filename,attachment.original_filename)"></fa-icon>
			</div>
			<div class="lt_rt">
				<div class="lt_rt_content" (click)="download(attachment.filename,attachment.original_filename)">
					<div class="filename"><strong>{{attachment.original_filename}} ({{formatBytes(attachment.filesize,0)}})</strong></div>
					<div class="title">{{attachment.title}}</div>
					<div *ngIf="attachment.doc_type_name" class="category" [ngStyle]="{'background-color':attachment.doc_type_hex}">{{attachment.doc_type_name}}</div>
					<div class="created">{{cFunction.localDateFormat(attachment.created)}}</div>
				</div>
				<div class="lt_rt_tools">
					<fa-icon [icon]="['fad', 'trash-can']" (click)="deleteAttachment(attachment.entity)"></fa-icon>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #activity_history_template let-history>
	<div class="history_container" [ngClass]="{'open' : allHistoryView}">
		<div class="simple_history">
		    <div class="history_body">{{history.app_action_name}}</div>
		    <div class="history_additional_text">{{history.custom_title}}</div>
		    <div class="user_name">{{history.username}}<span>{{test(history.created)}}</span></div>
		</div>		
	</div>
</ng-template>

<!-- *** -->
<ng-template #activity_timetracking_template let-timetracking>
	<div class="timetracking_container" [ngClass]="{'open' : allTimeTrackingView}">
		<div class="simple_timetracking">
			<div class="timetracking_comment">{{timetracking.comment}}</div>
		    <div class="timetracking_member">{{timetracking.username}}</div>
		    <div class="timetracking_time">{{timetracking.date}} | {{timetracking.from_time}} - {{timetracking.to_time}} | <span>x hours</span></div>		    
		</div>		
	</div>
</ng-template>


<!-- *** -->
<ng-template #activity_comments_template let-comments>
	<div class="comments_container"  [ngClass]="{'open' : allCommentsView}" [attr.rel]="comments.entity">
		<div class="simple_comment">
		    <div class="comment_head">
		    	<div class="author_left">
		    		<span class="user_bubble" style="background:{{comments.author_hex}}">
		    			{{ShowUserInicials(comments.username)[0].initials}}
		    			<div class="tooltip">{{comments.username}}</div>
		    		</span>
		    		<span class="user_fullname"><strong>{{comments.username}}</strong> | </span>
					<span *ngIf="DateOffset" class="show_date_type" (click)="toggleDateOffset()">{{cFunction.localDateFormat(comments.created)}}</span>
					<span *ngIf="!DateOffset" class="show_offset_type" (click)="toggleDateOffset()">{{test(comments.created)}}</span>
		    	</div>
	    		<!--<div class="author_right">{{test(comments.created)}}</div>-->
	    	</div>
		    <div class="comment_body" [innerHTML]="cFunction.URLtoHYPERLINK(comments.comment)"></div>
		    <div class="comment_actions">
				<fa-icon *ngIf="calculateOffsetInSecconds(null, comments.created) < 3600" [icon]="['fad', 'trash']" (click)="deleteComment(comments.entity)"></fa-icon>
		    	<fa-icon *ngIf="calculateOffsetInSecconds(null, comments.created) < 3600" [icon]="['fad', 'edit']" (click)="fixComment(comments.entity, comments.comment)"></fa-icon>
				<fa-icon [icon]="['fad', 'reply']" (click)="replyComment('comment',activity.entity,'comment',comments.entity)"></fa-icon>
		    </div>  			
			<div class="comment_attachments">
				<ng-container *ngFor='let attachment of commentsAttachments'>					
					<ng-container *ngIf="comments.entity==attachment['comment_id']">
						<div class="comment_attachment" (click)="download(attachment['attachment'],attachment['attachment'])" style="background: url({{showAttachment(attachment['attachment'])}});"></div>
					</ng-container>
				</ng-container>
			</div>
		</div>	
		
		<ng-container *ngFor="let sub_comment of comments.comments?.rows">
			<ng-container *ngTemplateOutlet="activity_comments_template; context:{ $implicit: sub_comment }"></ng-container>
		</ng-container>
		
	</div>
</ng-template>

<!-- *** -->
<ng-template #activity_title_template let-activity>
	<div class="cell_inner">
	    <div class="author user_bubble">
	    	{{ShowUserInicials(activity.author)[0].initials}}	
			<div class="tooltip">{{activity.author}}</div>
	    </div>		
		<div class="sub_title" (click)="getActivity(activity.entity)">{{activity.title}}</div>
		<!--<div class="sub_title" [routerLink]="['/activities', activity.entity]">{{activity.title}}</div>-->
		<div class="sub_icons">
			
		</div>

		<ng-container *ngFor="let sub_activity of activity.sub_activity">
			<ng-container *ngTemplateOutlet="activity_title_template; context:{ $implicit: sub_activity }"></ng-container>
		</ng-container>
	</div>	
</ng-template>


<!-- *** -->
<ng-template #activity_assigned_template let-activity>	
	<div class="cell_inner">
		<ng-container *ngFor='let assigned_users_name of ShowUserInicials(activity.assigned_users_names)'>
			<span  class="user_bubble">
				{{assigned_users_name.initials}}
				<div class="tooltip">{{assigned_users_name.fullname}}</div>
			</span>
		</ng-container>
		<ng-container *ngFor='let assigned_group_name of ShowUserInicials(activity.assigned_group_names)'>
			<span  class="group_bubble">
				{{assigned_group_name.initials}}
				<div class="tooltip">{{assigned_group_name.fullname}}</div>
			</span>
		</ng-container>
		
		<span class="add_bubble" (click)="assignUserGroup(activity.entity)">
			<fa-icon [icon]="['fad', 'users']"></fa-icon>
			<div class="tooltip">{{'Add/Remove Users or Groups'|translate}}</div>
			<div class="bubble_add_on">+</div>
		</span>
	</div>	
</ng-template>


<!-- *** -->
<ng-template #task_assigned_template let-task>
	<div class="cell_inner">
		<ng-container *ngFor='let assigned_users_name of ShowUserInicials(task.assigned_users_names)'>
			<span  class="user_bubble_small">
				{{assigned_users_name.initials}}
				<div class="tooltip">{{assigned_users_name.fullname}}</div>
			</span>
		</ng-container>
		<ng-container *ngFor='let assigned_group_name of ShowUserInicials(task.assigned_group_names)'>
			<span  class="group_bubble_small">
				{{assigned_group_name.initials}}
				<div class="tooltip">{{assigned_group_name.fullname}}</div>
			</span>
		</ng-container>				
		<span *ngIf="activity.canEdit=='true'" class="add_bubble_small" (click)="assignUserGroupToTask(task['assign'])">
			<fa-icon [icon]="['fad', 'users']"></fa-icon>
			<div class="tooltip">{{'Add/Remove Users or Groups'|translate}}</div>
			<div class="bubble_add_on">+</div>
		</span>		
	</div>
</ng-template>

<!-- *** -->
<ng-template #activity_priority_template let-activity>
	<div class="cell_inner">
		<fa-icon [icon]="['fad', 'flag']" [ngClass]="{'0':'priority0','1':'priority1','2':'priority2','3':'priority3'}[activity.priority]" class="priority_activity" (click)="assignPriority(activity.entity, null)"></fa-icon>		
		<ng-container *ngFor="let sub_activity of activity.sub_activity">
			<ng-container *ngTemplateOutlet="activity_priority_template; context:{ $implicit: sub_activity }"></ng-container>
		</ng-container>
	</div>
</ng-template>

<!-- *** -->
<ng-template #activity_datetime_template let-activity>
	<div class="cell_inner">
		<span [ngClass]="{'null':'datetime_not_selected'}[calculateTimeToEvent_subactivity(activity.message_start, activity.message_start, null)]" (click)="assignDateTime(activity.entity, activity.message_start, activity.message_end, null)" class="datetime_activity" [attr.activity_entity]="activity.entity">
			<fa-icon [icon]="['fad', 'clock']" class="clock"></fa-icon>
			<!--<fa-icon [icon]="['fad', {{cFunction.showTimeToEventIcon(activity.message_start, activity.message_end, null)}}]"></fa-icon>-->					
			<fa-icon [icon]="['fad', 'arrow-from-left']" [ngClass]="{'arrow-from-left':'display'}[cFunction.showTimeToEventIcon(activity.message_start, activity.message_end, null)]"></fa-icon>
			<fa-icon [icon]="['fad', 'arrow-to-right']" [ngClass]="{'arrow-to-right':'display'}[cFunction.showTimeToEventIcon(activity.message_start, activity.message_end, null)]"></fa-icon>
			<fa-icon [icon]="['fad', 'arrow-to-right']" [ngClass]="{'chevron-double-right':'display'}[cFunction.showTimeToEventIcon(activity.message_start, activity.message_end, null)]"></fa-icon>
			<span>						
				{{cFunction.showTimeToEvent(activity.message_start, activity.message_end, null)}}				
			</span>
		</span>
		<ng-container *ngFor="let sub_activity of activity.sub_activity">
			<ng-container *ngTemplateOutlet="activity_datetime_template; context:{ $implicit: sub_activity }"></ng-container>
		</ng-container>
	</div>
</ng-template>

<!-- *** -->
<ng-template #activity_category_template let-activity>
	<div class="cell_inner">
		<span [ngStyle]="{'background-color':activity.category_type_hex}" (click)="assignCategory(activity.entity, null)" class="category_activity" [attr.activity_entity]="activity.entity"></span>

		<ng-container *ngFor="let sub_activity of activity.sub_activity">
			<ng-container *ngTemplateOutlet="activity_category_template; context:{ $implicit: sub_activity }"></ng-container>
		</ng-container>
	</div>
</ng-template>

<!-- *** -->
<ng-template #activity_actions_template let-activity>
	<div class="cell_inner">
		<fa-icon class="icon_medium" [icon]="['fad', 'ellipsis-h']" (click)="activityFunctions(activity.entity, null)"></fa-icon>		

		<ng-container *ngFor="let sub_activity of activity.sub_activity">
			<ng-container *ngTemplateOutlet="activity_actions_template; context:{ $implicit: sub_activity }"></ng-container>
		</ng-container>
	</div>
</ng-template>



<div  *ngIf="taskWizzardPopUp" class="add_popup">	
	<div class="inner_wrapper" cdkDrag cdkDragRootElement=".add_popup" cdkDragHandle> 
		<div class="header">
			<div class="left"><h1>{{'Choose task template'|translate}}</h1></div>
			<div class="right">
				<fa-icon class="icon_close" [icon]="['fad', 'times']" (click)="taskWizzardPopUp=!taskWizzardPopUp"></fa-icon>
			</div>
		</div>
		
		<div class="body"> 
			<ng-container *ngFor='let item of taskTeamplates'>
				<div class="template_title" (click)="chooseTaskTemplate(item['entity'])">{{item['title']}}
					<div class="template_delete" (click)="$event.stopPropagation();deleteTaskTemplate(item['entity'])"><fa-icon [icon]="['fad', 'times']"></fa-icon></div>
				</div>
			</ng-container>		
		</div>
		

		<div class="modalActions">
			<button class="left" (click)="taskWizzardPopUp=!taskWizzardPopUp">{{'Close'|translate}}</button> 
		</div>
	</div>
</div>



<div *ngIf="!new_activity_show && !edit_task && add_popup && (subactivityCount == 0 || notes_list?.length == 0 || (viewAddTask) && activity.entity!=0 && (tasks | keyvalue)?.length)" class="add_popup">
	<div class="inner_wrapper"> 
		<div class="header">
			<div class="left"><h1>{{'Add Widget'|translate}}</h1></div>
			<div class="right">
				<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
			</div>
		</div>
	
		<div class="body">
			<ng-container *ngIf="activity.canEdit=='true' || viewAddTask">
				<div *ngIf="((!new_activity_show && !edit_task) || viewAddTask) && activity.entity!=0 && !countObject(tasks_groupped)" class="widget new_task" (click)="addCheckListWidget();">
					<fa-icon [icon]="['fad', 'toggle-off']"></fa-icon>
					<span>{{'Add Checklist'|translate}}</span>
				</div>
			</ng-container>
			
			<ng-container *ngIf="activity.canEdit=='true'">
				<div *ngIf="subactivityCount == 0" class="widget new_task" (click)="add_popup=false; new_activity_show=true; selectedTab='subActivities'">
					<fa-icon [icon]="['fad', 'list']"></fa-icon>
					<span>{{'Add subActivity'|translate}}</span>
				</div>
			</ng-container> 

			<!--
			<ng-container *ngIf="activity.canEdit=='true'">
				<div *ngIf="notes_list?.length == 0" class="widget new_notes" (click)="editNotes(null)">
					<fa-icon [icon]="['fad', 'pen-swirl']"></fa-icon>
					<span>{{'Add sketch'|translate}}</span>
				</div>
			</ng-container>
			-->
			
			<!--
			<div *ngIf="activity.etalk == null && new_activity_show==false && !canEdit && !edit_task" class="open_add_activity_wrapper" (click)="createETalkTimeStamp()">
				<div class="open_add_activity" [ngClass]="{'toLeft' : activity.sub_activity != null}">
					<fa-icon [icon]="['fad', 'plus-circle']"></fa-icon>
					{{'Add eTalk'|translate}}
				</div>
			</div>
			-->
		</div>

		<div class="modalActions">
			<button class="left" (click)="add_popup=!add_popup">{{'Cancel'|translate}}</button>			
		</div>
	</div>	

</div>




<!--
<div class="inner_wrapper" cdkDrag cdkDragRootElement=".add_popup" cdkDragHandle> 
	<div class="header">
		<div class="left"><h1>{{'Choose task template'|translate}}</h1></div>
		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" (click)="taskWizzardPopUp=!taskWizzardPopUp"></fa-icon>
		</div>
	</div>
	
	<div class="body"> 
		<ng-container *ngFor='let item of taskTeamplates'>
			<div class="template_title" (click)="chooseTaskTemplate(item['entity'])">{{item['title']}}
				<div class="template_delete" (click)="$event.stopPropagation();deleteTaskTemplate(item['entity'])"><fa-icon [icon]="['fad', 'times']"></fa-icon></div>
			</div>
		</ng-container>		
	</div>
	

	<div class="modalActions">
		<button class="left" (click)="taskWizzardPopUp=!taskWizzardPopUp">{{'Close'|translate}}</button> 
	</div>
</div>
-->


<div *ngIf="selectConditionTask" class="selectConditionTask">
	<div class="inner_wrapper" cdkDrag cdkDragRootElement=".add_popup" cdkDragHandle> 
		<div class="header">
			<div class="left"><h1>{{'Choose conditional check item'|translate}}</h1></div>
			<div class="right">
				<fa-icon class="icon_close" [icon]="['fad', 'times']" (click)="selectConditionTask=false"></fa-icon>
			</div>
		</div>
		
		<div class="body"> 			
			<ng-container *ngFor='let item of conditionalTasks | keyvalue'>
				<div class="conditionalTaskItem" [attr.taskId]="item.key" [ngClass]="{'selected':checkArray(item.key)}"  (click)="toggleConditionTask(taskForConditionalAssign, item.key)">				
					<!--{{conditionalTasks[item.key]['text']}}		-->
					{{ conditionalTasks[item.key]['text'] | slice:0:100 }}{{ (conditionalTasks[item.key]['text']).length > 100 ? '...' : '' }}		
				</div>
			</ng-container>		
		</div>
		
	
		<div class="modalActions">
			<button class="left" (click)="selectConditionTask=false">{{'Close'|translate}}</button> 
		</div>
	</div>

<!--	
	<div class="innerPopup" cdkDrag cdkDragRootElement=".selectConditionTask" cdkDragHandle>	
		<div class="left">
			<h1>{{'Choose conditional check item'|translate}}</h1>
		</div>
		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" (click)="selectConditionTask=false"></fa-icon>
		</div>
	</div>

	<div class="body">
		<ng-container *ngFor='let item of conditionalTasks | keyvalue'>
			<div class="conditionalTaskItem" [attr.taskId]="item.key" [ngClass]="{'selected':checkArray(item.key)}"  (click)="toggleConditionTask(taskForConditionalAssign, item.key)">				
				{{conditionalTasks[item.key]['text']}}				
			</div>
		</ng-container>
	</div>

	<div class="footer" style="border:none !important;">
		<button class="cancel0" (click)="selectConditionTask=false">{{'Close'|translate}}</button> 
	</div>
-->
</div>

<!-- *********************************** -->
<!-- *********************************** -->
<!-- *********************************** -->
<!-- SubActivity template -->
<ng-template #subActivities>
	<div class="block">
		<div *ngIf="subactivityCount > 0 || new_activity_show" class="title">
			<fa-icon [icon]="['fad', 'list-tree']"></fa-icon>
			<span>{{'Sub activities'|translate}}</span>
		</div>

		<div *ngIf="activity.entity!=0 && !canEdit && (subactivityCount > 0 || new_activity_show)" class="activity_sub_activity_table table">
			
			<ng-container *ngFor='let sub_activity of activity.sub_activity | keyvalue; let i = index'>
				
				<ng-container *ngIf="(!(($any(sub_activity).value.approved=='true') || ($any(sub_activity).value.approved_by_me=='1')) && hideFinishedActivities) || !hideFinishedActivities">								
					<div *ngIf="$any(sub_activity).value.entity!='new'" class="activity_sub_activity table_row" [attr.activity_entity]="$any(sub_activity).value.entity">
						<div class="sub_activity_checkbox table_cell">
							<fa-icon *ngIf="$any(sub_activity).value.approved=='false'" [icon]="['fad', 'check-circle']" [ngClass]="{'checkedbyme' : $any(sub_activity).value.approved_by_me=='1'}" (click)="toggleConfirm($any(sub_activity).value.entity)"></fa-icon>
							<fa-icon *ngIf="$any(sub_activity).value.approved=='true'" [icon]="['fad', 'check-circle']" [ngClass]="{'checkedbyme' : $any(sub_activity).value.approved=='true'}" (click)="toggleConfirm($any(sub_activity).value.entity)"></fa-icon>
						</div>
			
						<div class="sub_activity_title table_cell">
							<ng-container *ngTemplateOutlet="activity_title_template; context:{ $implicit: $any(sub_activity).value }"></ng-container>
						</div>

						<div class="sub_activity_assigned table_cell assign_to_activity" [attr.activity_entity]="$any(sub_activity).value.entity">
							<ng-container *ngTemplateOutlet="activity_assigned_template; context:{ $implicit: $any(sub_activity).value }"></ng-container>
						</div>

						<div class="sub_activity_priority table_cell">
							<ng-container *ngTemplateOutlet="activity_priority_template; context:{ $implicit: $any(sub_activity).value }"></ng-container>
						</div>

						<div class="sub_activity_due table_cell">
							<ng-container *ngTemplateOutlet="activity_datetime_template; context:{ $implicit: $any(sub_activity).value }"></ng-container>
						</div>

						<div class="sub_activity_category table_cell">
							<ng-container *ngTemplateOutlet="activity_category_template; context:{ $implicit: $any(sub_activity).value }"></ng-container>
						</div>
						
						<div class="sub_activity_actions table_cell">
							<ng-container *ngIf="activity.canEdit=='true'">
								<ng-container *ngTemplateOutlet="activity_actions_template; context:{ $implicit: $any(sub_activity).value }"></ng-container>
							</ng-container>
						</div>
						
					</div>
				</ng-container>						

				<ng-container *ngIf="new_activity_show==true">
					<ng-container *ngIf="$any(sub_activity).value.entity=='new'">									
						<div class="activity_sub_activity table_row add_row" [attr.activity_entity]="$any(sub_activity).value.entity">
							<div class="sub_activity_checkbox table_cell">
								<fa-icon [icon]="['fad', 'plus-circle']" (click)="selectActivity()"></fa-icon>
							</div>
				
							<div class="sub_activity_title table_cell">
								<input [(ngModel)]="activity.sub_activity['new'].title" placeholder="{{'Activity title...'|translate}}" id="newactivity_title" type="text" />
							</div>

							<div class="sub_activity_assigned table_cell assign_to_activity" [attr.activity_entity]="$any(sub_activity).value.entity">
								<ng-container *ngTemplateOutlet="activity_assigned_template; context:{ $implicit: $any(sub_activity).value }"></ng-container>
							</div>

							<div class="sub_activity_priority table_cell">
								<ng-container *ngTemplateOutlet="activity_priority_template; context:{ $implicit: $any(sub_activity).value }"></ng-container>
							</div>

							<div class="sub_activity_due table_cell">
								<ng-container *ngTemplateOutlet="activity_datetime_template; context:{ $implicit: $any(sub_activity).value }"></ng-container>
							</div>

							<div class="sub_activity_category table_cell">
								<ng-container *ngTemplateOutlet="activity_category_template; context:{ $implicit: $any(sub_activity).value }"></ng-container>
							</div>									
							
							<div class="sub_activity_actions table_cell"></div>																				
						</div> 
					</ng-container>
				</ng-container>						
			</ng-container>	
		</div>
		<div *ngIf="new_activity_show" class="add_row_description"><textarea autosize [(ngModel)]="activity.sub_activity['new'].description" placeholder="{{'Add activity description...'|translate}}"></textarea></div>

		<div *ngIf="!new_activity_show && activity.entity!=0 && !edit_task && ((subactivityCount > 1 || new_activity_show)) && hideFinishedActivities != false" class="new_task" (click)="ShowHideFinishedActivities()">
			<fa-icon [icon]="['fad', 'eye']" style="margin-right: 8px; float:right;"></fa-icon>{{'Show all'|translate}}
		</div>
		<div *ngIf="!new_activity_show && activity.entity!=0 && !edit_task && ((subactivityCount > 1 || new_activity_show)) && hideFinishedActivities == false" class="new_task" (click)="ShowHideFinishedActivities()">
			<fa-icon [icon]="['fad', 'eye']" style="margin-right: 8px; float:right;"></fa-icon>{{'Hide finished'|translate}}
		</div>

		<ng-container *ngIf="activity.canEdit=='true'">
			<div *ngIf="new_activity_show==true && !canEdit && !edit_task" style="float:right;" class="add_cancel_activity_wrapper" (click)="addActivity(); new_activity_show=false">
				<button class="add_cancel_activity accept">{{'Add'|translate}}</button>
			</div>			
		</ng-container>

		<div *ngIf="new_activity_show==true && !canEdit && !edit_task" class="add_cancel_activity_wrapper" style="margin-right: 12px;float:right;" (click)="new_activity_show=false;canEdit=false">
			<button class="cancel0">{{'Cancel'|translate}}</button>
		</div>	

		<ng-container *ngIf="activity.canEdit=='true' && subactivityCount > 0">
			<div *ngIf="new_activity_show==false && !canEdit && !edit_task" class="open_add_activity_wrapper" style="float:right;">
				<div (click)="new_activity_show=true" class="open_add_activity" [ngClass]="{'toLeft' : activity.sub_activity != null}">
					<fa-icon [icon]="['fad', 'plus-circle']" style="margin-right: 8px;"></fa-icon>{{'Add new subActivity'|translate}}
				</div>
				<div class="activity_wizzard">
					<fa-icon [icon]="['fad', 'wand-magic-sparkles']"></fa-icon>{{'Use wizzard'|translate}}
				</div>
			</div>
		</ng-container>
	</div>
</ng-template>

<!-- *********************************** -->
<!-- *********************************** -->
<!-- *********************************** -->
<!-- Task template -->
<ng-template #tasks>
	<div *ngIf="countObject(tasks_groupped)" class="block" cdkDropListGroup>
		<div class="title">
			<fa-icon [icon]="['fad', 'list-check']"></fa-icon>
			<span>{{'Checklist'|translate}}</span>
			<fa-icon *ngIf="!showTaskComments" class="right" (click)="showHideTaskComments()" [icon]="['fad', 'comment-slash']"></fa-icon>
			<fa-icon *ngIf="showTaskComments" class="right" (click)="showHideTaskComments()" [icon]="['fad', 'comment']"></fa-icon>
		</div>

		<!-- /////////////////// -->	
		<!-- Task group container -->													
		<ng-container *ngFor='let group_item of tasks_groupped | keyvalue; let i_group = index'>
			<div class="taskGroup" [ngClass]="{'nogroups':countObject(tasks_groupped) == 1, 'last': i_group==countObject(tasks_groupped)-1}" cdkDropList [cdkDropListData]="tasksDragAndDrop[group_item.key]" (cdkDropListDropped)="drop($event)">
						
				<!-- Show only if there is more than 1 group : group title -->		
				<div *ngIf="countObject(tasks_groupped) > 1" class="taskGroupTitle">
					<div>
						<p>{{tasks_groupped[group_item.key]['title']}}</p>
					</div>
					<div>
						<span>											
							<fa-icon [icon]="['fad', 'pen-to-square']" (click)="editTaskGroup(group_item.key, tasks_groupped[group_item.key]['title']);"></fa-icon>
							<fa-icon [icon]="['fad', 'down-to-bracket']" (click)="saveTaskGroupTemplate(group_item.key, tasks_groupped[group_item.key]['title']);"></fa-icon>

							<fa-icon [icon]="['fad', 'trash']" (click)="removeTaskGroup(group_item.key);"></fa-icon>
						</span>
					</div>
				</div>

				<!-- /////////////////// -->	
				<!-- Each task of each group group -->	
									
				<ng-container *ngFor="let item of tasks_groupped[group_item.key]['tasks'] | keyvalue; let i = index">
					<!-- separator : line -->
					<div *ngIf="(viewAddTask && group_item.key == selectedGroup) && i==countObject(tasks_groupped[group_item.key]['tasks'])-1 && countObject(tasks_groupped[group_item.key]['tasks'])!=1" class="task_item_separator"></div>

					<!-- task/checkbox item container -->
					<ng-container *ngIf="(!onlymine || (onlymine && checkTaskAssignation(item.value['assigned_users_names'])))">
						<div *ngIf="(!hideFinishedTasks || (hideFinishedTasks && !item.value['status'])) && (i!=countObject(tasks_groupped[group_item.key]['tasks'])-1 || ((i==countObject(tasks_groupped[group_item.key]['tasks'])-1) && group_item.key == selectedGroup) || group_item.key != selectedGroup)" class="task_item" cdkDrag [cdkDragDisabled]="hideFinishedTasks==true">							
							<ng-container *ngIf="!item.value['disabled']">
								<ng-container *ngIf="(!edit_task && activity.canEdit=='true') || group_item.key != selectedGroup">
									<label *ngIf="!(viewAddTask && i==countObject(tasks_groupped[group_item.key]['tasks'])-1) || group_item.key != selectedGroup" class="switch" (click)="changeTaskState(item.key);">
										<input type="checkbox" [checked]="item.value['status']" />
										<span class="slider round"></span>
									</label>											
								</ng-container>
							</ng-container>
							<ng-container *ngIf="item.value['disabled']">
								<ng-container *ngIf="(!edit_task && activity.canEdit=='true') || group_item.key != selectedGroup">
									<label *ngIf="!(viewAddTask && i==countObject(tasks_groupped[group_item.key]['tasks'])-1) || group_item.key != selectedGroup" class="switch">
										<input type="checkbox" [checked]="item.value['status']" disabled />
										<span class="slider round disabledCheckItem"></span>
									</label>
								</ng-container>
							</ng-container>


							<!--<ng-container *ngIf="group_item.key == selectedGroup">-->
							<!--<ng-container *ngIf="i==countObject(tasks_groupped[group_item.key]['tasks'])-1 || (viewAddTask && i==countObject(tasks_groupped[group_item.key]['tasks'])-1)">-->	
						
							<!--<ng-container *ngIf="!edit_task && !viewAddTask">-->
								<div *ngIf="((!edit_task) || (edit_task && i!=countObject(tasks_groupped[group_item.key]['tasks'])-1) || group_item.key != selectedGroup)" class="task_text">																
									<ng-container *ngIf="!edit_task && !viewAddTask"> <!-- here -->
										<div *ngIf="!(viewAddTask && i==countObject(tasks_groupped[group_item.key]['tasks'])-1) || group_item.key != selectedGroup" class="task_assigns">																							
											<ng-container *ngTemplateOutlet="task_assigned_template; context:{ $implicit: item.value }"></ng-container>
										</div>
									</ng-container>
									<div *ngIf="(!viewAddTask && !edit_task) || (viewAddTask && i!=countObject(tasks_groupped[group_item.key]['tasks'])-1) || (group_item.key != selectedGroup)" class="task_text_inner">													
										<span [ngClass]="{'disabledCheckItem':item.value['disabled']}">
											<span [attr.entity]="item.key" [ngStyle]="{'cursor' : hideFinishedTasks==false ? 'grab':'default'}" [innerHTML]="cFunction.URLtoHYPERLINK(item.value['text'])"></span>
											<span *ngIf="item.value['disabled_reason']==1">({{'Not eligible to confirm. Only Assignet memebers'|translate}})</span>
											<span *ngIf="item.value['disabled_reason']==2">({{'Prior confirmation required for confirmation'|translate}})</span>											
										</span>										
									</div>

									<div *ngIf="!viewAddTask || (viewAddTask && i!=countObject(tasks_groupped[group_item.key]['tasks'])-1) || (group_item.key != selectedGroup)" class="task_tool">
										<div *ngIf="!edit_task"><fa-icon [icon]="['fad', 'comments-alt']" (click)="addComment(item.key)"></fa-icon></div>
										<div *ngIf="!edit_task"><fa-icon [icon]="['fas', 'reply']" (click)="replyTask(item.key)"></fa-icon></div>
										<div *ngIf="!edit_task"><fa-icon [icon]="['fas', 'bars-progress']" (click)="progressTask(item.key)"></fa-icon></div>
										<div *ngIf="!edit_task"><fa-icon [icon]="['fas', 'stopwatch']" (click)="addTimeTracking(cFunction.URLtoHYPERLINK(item.value['text']))"></fa-icon></div>
										<div *ngIf="edit_task"><fa-icon [icon]="['fad', 'right-left']" (click)="moveTask(item.key)"></fa-icon></div>
										<div *ngIf="edit_task"><fa-icon [icon]="['fad', 'link-simple']" (click)="conditionTask(item.key)"></fa-icon></div>
										<div *ngIf="!edit_task">{{item.value['time']}} <span>{{getInitials(item.value['author'])}}</span></div>
									</div>

									<ng-container *ngIf="item.value['progress']!=-1 && item.value['progress']!=null">
										<div class="task-progress-wrapper">
											<div class="task-progress-numeric">{{item.value['progress']}}%</div>
											<div class="task-progress-container" (mousedown)="onTaskDragStart($event, item.key)" (click)="onClick($event, item.key)">
												<div class="task-progress-background"></div>
												<div class="task-progress" id="task_{{item.key}}" [ngStyle]="{ 'width.%': item.value['progress'] }"></div>
											</div>		
											<button *ngIf="item.value['progress_saved']==false" (click)="confirm()" class="confirm">{{'Confirm all'|translate}}</button>										
										</div>
									</ng-container>

									<ng-container *ngIf="!edit_task && !viewAddTask"> <!-- here -->
										<div *ngIf="!showTaskComments" class="task_comments">
											<ng-container *ngFor="let comment of activity['comments-task']?.rows">																
												<div *ngIf="removeLetters(item.value['assign'])==comment['object_id']" class="task_comment">
													<span  class="user_bubble_small" style="background:{{comment.author_hex}}">
														{{ShowUserInicials(comment.username)[0]['initials']}}
														<div class="tooltip">{{ShowUserInicials(comment.username)[0]['fullname']}}</div>
													</span>
													<strong>{{ShowUserInicials(comment.username)[0]['fullname']}}</strong> | 
													<span *ngIf="DateOffset" class="show_date_type" (click)="toggleDateOffset()">{{cFunction.localDateFormat(comment.created)}}</span>
													<span *ngIf="!DateOffset" class="show_offset_type" (click)="toggleDateOffset()">{{test(comment.created)}}</span>	
													<fa-icon *ngIf="calculateOffsetInSecconds(null, comment.created) < 3600" [icon]="['fad', 'edit']" (click)="fixComment(comment.entity, comment.comment)"></fa-icon>	
													<fa-icon *ngIf="calculateOffsetInSecconds(null, comment.created) < 3600" [icon]="['fad', 'trash']" (click)="deleteComment(comment.entity)"></fa-icon>	
													<br /><span [innerHTML]="cFunction.URLtoHYPERLINK(comment.comment)"></span>

													<div class="comment_attachments">
														<ng-container *ngFor='let attachment of commentsAttachments'>														
															<ng-container *ngIf="comment['entity']==attachment['comment_id']">															
																<div class="comment_attachment" (click)="download(attachment['attachment'],attachment['attachment'])" style="background: url({{showAttachment(attachment['attachment'])}});"></div>
															</ng-container>
														</ng-container>
													</div>
												</div>
											</ng-container>
										</div>
									</ng-container>
								</div>
							<!--</ng-container>-->
							
							<fa-icon *ngIf="(edit_task && group_item.key == selectedGroup) || (viewAddTask && i==countObject(tasks_groupped[group_item.key]['tasks'])-1 && group_item.key == selectedGroup)" [icon]="['fad', 'times']" (click)="removeTask(item.key)" style="float:left"></fa-icon>
							
							<div class="task_edit_text" *ngIf="group_item.key == selectedGroup">													
								<ng-container *ngIf="edit_task || (viewAddTask && i==countObject(tasks_groupped[group_item.key]['tasks'])-1)">
									<ng-container *ngTemplateOutlet="task_assigned_template; context:{ $implicit: item.value }"></ng-container>
								</ng-container>												
							</div>
																		
							<ng-container *ngIf="group_item.key == selectedGroup">
								<ng-container *ngIf="i==countObject(tasks_groupped[group_item.key]['tasks'])-1 || (viewAddTask && i==countObject(tasks_groupped[group_item.key]['tasks'])-1)">															
									<input *ngIf="edit_task || viewAddTask" class="autofocus" autofocus placeholder="{{'New task description...'|translate}}" (keyup)="changeTaskText($event, item.key)" type="text" value="{{item.value['text']}}" style="margin-left: 16px; margin-top: 10px; margin-bottom: 12px;" />
									<fa-icon *ngIf="edit_task || viewAddTask" class="icon_medium microphone" [icon]="['fad', 'microphone']"></fa-icon>
								</ng-container>

								<ng-container *ngIf="i!=countObject(tasks_groupped[group_item.key]['tasks'])-1 && !viewAddTask">									
									<input *ngIf="edit_task" placeholder="{{'New task description...'|translate}}" (keyup)="changeTaskText($event, item.key)" type="text" value="{{item.value['text']}}" style="margin-left: 16px; margin-top: 10px; margin-bottom: 12px;" />
								</ng-container>
							</ng-container>											
						</div>
					</ng-container>
				</ng-container>									

				<!-- group controls -->			
				<div *ngIf="(edit_task || viewAddTask) && group_item.key == selectedGroup" style="float:right; width: auto;margin-bottom: 2px;" [ngStyle]="{'margin-right.px': countObject(tasks_groupped) > 1 ? 24 : 0}">
					<button class="right accept" style="margin-top:0" (click)="saveTasks(); edit_task=false; viewAddTask=false;">
						{{'Save'|translate}}
					</button>
					<button class="right" style="margin-top:0" (click)="cancelTaskChange();">
						{{'Cancel'|translate}}
					</button>
				</div>		
												
				<ng-container *ngIf="activity.canEdit=='true' && !viewAddTask">
					<div *ngIf="(!new_activity_show) && activity.entity!=0 && !edit_task && (tasks | keyvalue)?.length" class="new_task nomargin" (click)="selectedGroupText = tasks_groupped[group_item.key]['title'];selectedGroup = group_item.key;addTask(toInt(group_item.key), tasks_groupped[group_item.key]['title']);">
						<fa-icon [icon]="['fad', 'plus-circle']" style="margin-right: 8px;"></fa-icon>{{'Add item'|translate}}
					</div>
				</ng-container>			
				
				<ng-container *ngIf="activity.canEdit=='true' && !viewAddTask">
					<div *ngIf="(!new_activity_show) && activity.entity!=0 && !edit_task && (tasks | keyvalue)?.length" class="new_task nomargin" (click)="selectedGroup = group_item.key; edit_task=!edit_task;">
						<fa-icon [icon]="['fad', 'pen-to-square']" style="margin-right: 8px;"></fa-icon>{{'Add/Edit Checklist'|translate}}
					</div>
				</ng-container>
				
				<!-- each task group -->
			</div>
		</ng-container>
		<!-- task group wrapper -->

		<!-- if not tasks yet -->
		<div *ngIf="tasks_count == 0" class="noTasksYet">
			<ng-container *ngIf="activity.canEdit=='true'">
				<div class="new_task notopmargin" (click)="addTask();">
					<fa-icon [icon]="['fad', 'plus-circle']" style="margin-right: 8px;"></fa-icon>{{'Add item'|translate}}
				</div>
			</ng-container>
		</div>
		<!-- if not tasks yet -->

		<ng-container *ngIf="activity.canEdit=='true'">
			<div class="new_task" [ngClass]="{'new_task_groupped' : countObject(tasks_groupped)>1}" (click)="addNewTaskGroup();">
				<fa-icon [icon]="['fad', 'layer-plus']" style="margin-right: 8px;"></fa-icon>{{'Add group'|translate}}
			</div>

			<div class="new_task" [ngClass]="{'new_task_groupped' : countObject(tasks_groupped)>1}" (click)="openTaskWizzard();">
				<fa-icon [icon]="['fad', 'wand-magic-sparkles']" style="margin-right: 8px;"></fa-icon>{{'Task wizzard'|translate}}
			</div>								
		</ng-container>
				
		<div *ngIf="(!new_activity_show) && activity.entity!=0 && !edit_task && (hideFinishedTasks != false || onlymine == true)" [ngClass]="{'new_task_groupped' : countObject(tasks_groupped)>1}" class="new_task right" (click)="SwitchShowAllTasks();">
			<fa-icon [icon]="['fad', 'eye']" style="margin-right: 8px;"></fa-icon>{{'Show all'|translate}}
		</div>

		<div *ngIf="(!new_activity_show) && activity.entity!=0 && !edit_task && hideFinishedTasks == false" class="new_task right" [ngClass]="{'new_task_groupped' : countObject(tasks_groupped)>1}" (click)="SwitchHideFinishedTasks();">
			<fa-icon [icon]="['fad', 'eye']" style="margin-right: 12px;"></fa-icon>{{'Hide finished'|translate}}
		</div>

		<div *ngIf="(!new_activity_show) && activity.entity!=0 && !edit_task && onlymine != true" class="new_task right" [ngClass]="{'new_task_groupped' : countObject(tasks_groupped)>1}" (click)="SwitchOnlyMine();">
			<fa-icon [icon]="['fad', 'bullseye-pointer']" style="margin-right: 12px;"></fa-icon>{{'Only mine'|translate}}
		</div>
	</div>
</ng-template>