<div class="inner_wrapper document_center_wrapper">		
    <div>
		<img *ngIf="imageSrc" [src]="imageSrc" alt="Converted PDF Page" />
	  </div>

	<!--<app-document-edit></app-document-edit>-->
	<div class="connection_data_extended" [ngClass]="{'open_mobile': toggleFoldersView}">

		<div class="swipeFolders mobile" (click)="toggleFoldersView=!toggleFoldersView">
			<div class="collapseSwitch" name="collapseSwitch" (click)="toggleFoldersView;">
				<fa-icon *ngIf="toggleFoldersView==false" [icon]="['fas', 'chevron-right']"></fa-icon>
				<fa-icon *ngIf="toggleFoldersView==true" [icon]="['fas', 'chevron-left']"></fa-icon>
			</div>
		</div>
			
		<div class="folder_tree_outer_wrapper" [style.width.%]="leftPaneWidth">			
			<div class="folder_tree_group">
				<div class="folder_tree" [ngClass]="{'activeTree':systemGroup}">
					<div class="title" (click)="systemGroup=!systemGroup">	
						{{'Basic folders'|translate}}	

						<fa-icon *ngIf="!systemGroup" [icon]="['fad', 'chevron-down']"></fa-icon>
						<fa-icon *ngIf="systemGroup" [icon]="['fad', 'chevron-up']"></fa-icon>
						
						<fa-icon style="margin-right: 12px;" [icon]="['far', 'arrow-up-1-9']" *ngIf="sortType=='up_19'" (click)="setFolderSorting();$event.stopPropagation();"></fa-icon>
						<fa-icon style="margin-right: 12px;" [icon]="['far', 'arrow-down-1-9']" *ngIf="sortType=='down_19'" (click)="setFolderSorting();$event.stopPropagation();"></fa-icon>
						<fa-icon style="margin-right: 12px;" [icon]="['far', 'arrow-up-a-z']" *ngIf="sortType=='up_az'" (click)="setFolderSorting();$event.stopPropagation();"></fa-icon>
						<fa-icon style="margin-right: 12px;" [icon]="['far', 'arrow-down-a-z']" *ngIf="sortType=='down_az'" (click)="setFolderSorting();$event.stopPropagation();"></fa-icon>

					</div>
					<div *ngIf="systemGroup" class="folder_tree_wrapper">
						<ng-container *ngTemplateOutlet="foldersTemplate; context:{ $implicit: $any(folders) }"></ng-container>
					</div>						
				</div>				
			</div>

			<div class="folder_tree_group">			
				<div class="folder_tree" [ngClass]="{'activeTree':smartGroup}">
					<div class="title" (click)="smartGroup=!smartGroup">
						{{'Grouped folders'|translate}}			
						<fa-icon *ngIf="!smartGroup" [icon]="['fad', 'chevron-down']"></fa-icon>
						<fa-icon *ngIf="smartGroup" [icon]="['fad', 'chevron-up']"></fa-icon>
					</div>
					<div *ngIf="smartGroup" class="folder_tree_wrapper">
						<ng-container *ngTemplateOutlet="groupTemplate; context:{ $implicit: $any(groupedFolders) }"></ng-container>
					</div>						
				</div>				
			</div>
		</div>

		<div class="panelDivider" (mousedown)="onDragStartPanels($event)" (mousemove)="onDragMovePanels($event)"><div class="inner"></div></div>

		<div class="file_folder_toolbar">
			<fa-icon [icon]="['fad', 'grid-2']" [ngClass]="{'selected': grid==null }" (click)="grid=null;fileViewType(null);"></fa-icon>
			<!--<fa-icon class="desktop" [icon]="['fad', 'grid']" [ngClass]="{'selected': grid==true }" (click)="grid=true;fileViewType(true);"></fa-icon>-->
			<fa-icon [icon]="['fad', 'bars']" [ngClass]="{'selected': grid==false }" (click)="grid=false;fileViewType(false);"></fa-icon>	
			
			<fa-icon *ngIf="order=='asc'" class="sorter" [icon]="['fad', 'arrow-up-a-z']" (click)="order='desc';refreshFiles(refreshPreData_field, refreshPreData_value, refreshPreData_items, refreshPreData_search);" style="margin-left: 12px !important;"></fa-icon>
			<fa-icon *ngIf="order=='desc'" class="sorter" [icon]="['fad', 'arrow-down-z-a']" (click)="order='asc';refreshFiles(refreshPreData_field, refreshPreData_value, refreshPreData_items, refreshPreData_search);" style="margin-left: 12px !important;"></fa-icon>

			<fa-icon *ngIf="refreshPreData_field=='folder'" [icon]="['fad', 'folder-plus']" (click)="$event.stopPropagation();addFolder();" style="margin-left: 24px; padding-left:24px; border-left:1px solid var(--dark-lvl3)"></fa-icon>
			<fa-icon *ngIf="refreshPreData_field=='folder'" [icon]="['fad', 'trash-xmark']" (click)="$event.stopPropagation();deleteFolder();"></fa-icon>

			<fa-icon *ngIf="refreshPreData_field=='folder'" [icon]="['fad', 'folder-gear']" (click)="$event.stopPropagation();setupFolder();"></fa-icon>
			
			
			<select id="sortby" [(ngModel)]="sortBy" (change)="refreshFiles(refreshPreData_field, refreshPreData_value, refreshPreData_items, refreshPreData_search)">
				<option value="doc_title">{{'By Document Title'|translate}}</option>
				<option value="original_filename">{{'By Original Document name'|translate}}</option>
				<option value="doc_title|decimal">{{'By Decimal Clasification'|translate}}</option>
				<option value="updated">{{'By Date'|translate}}</option>
			</select>

			<!--
			<fa-icon [icon]="['fad', 'magnifying-glass']" class="sorter" (click)="switchSearch()"></fa-icon>
			-->

			<!--
			<div *ngIf="searchOpen" class="searchContainer">
				<input class="searchInput" type="text" (keyup)="searchForFile($event)" placeholder="{{'Search for file...'|translate}}"/>
			</div>
			-->

			<div *ngIf="!searchOpen" class="current_folder" (click)="showFilesOrder();">
				{{folder}}
				{{group}}
			</div>				
		</div>


		<!-- list view -->	
		<div *ngIf="grid==false" class="file_folder">
			<div class="table">
				<div class="row header">
					<div class="col">{{'Document name'|translate}}</div>
					<div class="col">{{'Date created'|translate}}</div>
					<!--<div class="col">{{'Assigned'|translate}}</div>-->
					<div class="col">{{'File Size'|translate}}</div>
					<div class="col">{{'Tools'|translate}}</div>
				</div>

				<div class="row header forSticky">
					<div class="col">{{'Document name'|translate}}</div>
					<div class="col">{{'Date created'|translate}}</div>
					<!--<div class="col">{{'Assigned'|translate}}</div>-->
					<div class="col">{{'File Size'|translate}}</div>
					<div class="col">{{'Tools'|translate}}</div>
				</div>

				<ng-container *ngIf="files.length > 0">
					<ng-container *ngFor="let file of files; let i = index">
						<div class="row each_file_list">
							<div class="col name" (click)="docPreview(file['filename'])">
								<fa-icon [icon]="['fas', cFunction.showFileIcon(file['ext'])]" (click)="docPreview(file['filename'])"></fa-icon>
								<strong>{{file['original_filename']}}</strong><br>
								<span style="font-size:var(--extrasmall-font-size);color:var(--dark-lvl3)">{{file['title']}}</span>
							</div>
							<div class="col" (click)="docPreview(file['filename'])">
								<span>{{cFunction.localDateFormat(file['created'])}}</span>
							</div>
							<div class="col" (click)="docPreview(file['filename'])">
								<span>{{formatBytes(file['filesize'], 0)}}</span>
							</div>
							<div class="col">
								<fa-icon [icon]="['fad', 'download']" (click)="$event.stopPropagation();downloadFile(file['filename'],file['original_filename'])" style="margin-right:12px"></fa-icon>								
								<fa-icon *ngIf="editable" [icon]="['fad', 'file-pen']" (click)="$event.stopPropagation();editFile(file['entity']);$event.preventDefault();"></fa-icon>
								<fa-icon *ngIf="editable" [icon]="['fad', 'trash-alt']" (click)="$event.stopPropagation();deleteFile(i);$event.preventDefault();"></fa-icon>
							</div>		
						</div>
					</ng-container>
					<!--					
					<ng-container *ngFor="let file of files | keyvalue: keepOrder">
						<div class="row each_file_list">
							<div class="col name" (click)="docPreview(file.value.filename)">
								<fa-icon [icon]="['fas', cFunction.showFileIcon(file.value.ext)]" (click)="docPreview(file.value.filename)"></fa-icon>
								<strong>{{file.value.original_filename}}</strong><br>
							</div>
							<div class="col" (click)="docPreview(file.value.filename)">
								<span>{{cFunction.localDateFormat(file.value.created)}}</span>
							</div>
							<div class="col" (click)="docPreview(file.value.filename)">
								<span>{{formatBytes(file.value.filesize, 0)}}</span>
							</div>
							<div class="col">
								<fa-icon [icon]="['fad', 'download']" (click)="$event.stopPropagation();downloadFile(file.value.filename,file.value.original_filename)" style="margin-right:12px"></fa-icon>								
								<fa-icon *ngIf="editable" [icon]="['fad', 'file-pen']" (click)="$event.stopPropagation();editFile(file.value.entity);$event.preventDefault();"></fa-icon>
								<fa-icon *ngIf="editable" [icon]="['fad', 'trash-alt']" (click)="$event.stopPropagation();deleteFile(file.key);$event.preventDefault();"></fa-icon>	
							</div>		
						</div>
					</ng-container>
					-->
				</ng-container>	
			</div>			
		</div>

		<!-- start: grid view -->
		<div *ngIf="grid==true || grid==null" class="file_folder">			
			<ng-container *ngIf="files.length > 0">
				<div cdkDropList [cdkDropListData]="files" (cdkDropListDropped)="drop($event)">
					<ng-container *ngFor="let file of files | keyvalue: keepOrder">

						<div class="each_file" [ngClass]="{'big': grid==null }" cdkDrag [cdkDragData]="files" (cdkDragMoved)="onDragMove($event)" (click)="docPreview(file.value.filename)">
							<div class="file_icon"><fa-icon [icon]="['fas', cFunction.showFileIcon(file.value.ext)]" (click)="docPreview(file.value.filename)"></fa-icon></div>		
							<div class="file_title" (click)="docPreview(file.value.filename)"><strong>{{file.value.original_filename}}</strong></div>							
							<div class="file_photo filePreviewClick jpgPreview">
								<ng-container *ngIf="previewAvailable(file.value.ext)">
									<div *ngIf="!chekcForDocumentPreview(file.value.ext)" class="file_photo imgPreviewClick" style="background:url({{displayImage(file.value.filename)}})"></div>							
									<img *ngIf="chekcForDocumentPreview(file.value.ext)" src="{{displayThumbnail(file.value.filename)}}" />
								</ng-container>
								<div *ngIf="!previewAvailable(file.value.ext)" class="iconContainer">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" style="position: absolute;top:0;bottom:0;left:0;right:0;">
										<line x1="0" y1="0" x2="300" y2="300" stroke="#fff" stroke-width="1"/>
										<line x1="0" y1="300" x2="300" y2="0" stroke="#fff" stroke-width="1"/>
									</svg>
									<fa-icon class="extraBig" style="z-index:1" [icon]="['fas', cFunction.showFileIcon(file.value.ext)]" (click)="$event.stopPropagation();downloadFile(file.value.filename,file.value.original_filename)"></fa-icon>
								</div>
							</div>							
							<!--{{file.key}}-->
							<div class="file_hover">
								<div class="tools">
									<fa-icon [icon]="['fad', 'download']" style="padding-right:12px;" (click)="$event.stopPropagation();downloadFile(file.value.filename,file.value.original_filename)"></fa-icon>
									<!--
									<fa-icon [icon]="['far', 'star']" (click)="favorite(file.value.entity)" style="margin-right:12px;margin-left:6px;"></fa-icon>
									<div *ngIf="editable" class="small add_bubble">
										<fa-icon [icon]="['fad', 'users']"></fa-icon>
										<div class="bubble_add_on">+</div>
									</div>
									-->									
									<fa-icon *ngIf="editable" [icon]="['fad', 'trash-alt']" class="trash" (click)="$event.stopPropagation();deleteFile(file.key);"></fa-icon>
									<fa-icon *ngIf="editable" [icon]="['fad', 'file-pen']" (click)="$event.stopPropagation();editFile(file.value.entity);"></fa-icon>
								</div>								
							</div>
							<div class="file_date"><span>{{cFunction.localDateFormat(file.value.created)}}</span></div>
						</div>

					</ng-container>					
				</div>
			</ng-container>		
			
			
			<ng-container *ngIf="files.length == 0">
				<div class="each_file nohover" style="width: 100%; padding-left: 0px; margin-left: 0px;border:none;box-shadow: none;">
					<div class="file_icon">
						<fa-icon [icon]="['fad', 'file-exclamation']"></fa-icon>
					</div>
					<div class="file_title">
						{{'No files in this folder'|translate}}<br />
					</div>
				</div>
			</ng-container>			
		</div><!-- end: grid view -->
	</div>
</div><!-- end: inner_wrapper -->

<!-- Preview -->
<div *ngIf="imgUrl != null" class="imgPreview">
	<img src="{{imgUrl}}" />
	<div class="closeImgPreview" (click)="closeImgPreview()"><fa-icon [icon]="['fad', 'times']" class="big"></fa-icon></div>
</div>

<div *ngIf="docUrl != null" class="docPreview" (click)="closeDocPreview()" style="box-shadow: 0px 0px 24px #000;">	
	<embed [src]="docUrl" type="application/pdf" width="100%" height="100%" />
	<div class="closeDocPreview" (click)="closeDocPreview()"><fa-icon [icon]="['fad', 'times']" class="big"></fa-icon></div>
</div>

<!-- Folder template --> 
<ng-template #foldersTemplate let-folders>	
	<ul class="directory-list" [ngClass]="{ 'component-list': asComponent }">

		<ng-container *ngFor="let folder of folders | keyvalue: keepOrder">			
			<li *ngIf="folder.key!='' && checkIfParameter(folder.key)" class="folder" [attr.path]="folder.value['_path']" [ngClass]="{'selectedFolder':folder.value['_selected']==true,'openedFolder':folder.value['_opened']==true}">
				<span [ngClass]="{'selectedFolder':folder.value['_selected']==true,'openedFolder':folder.value['_opened']==true}" (mouseover)="mouseOverFolder(folder.value['_path'])" (mouseout)="mouseOverFolder(null)" (click)="onFolderClick(folder.value['_path'],folder.value['_opened']);$event.stopPropagation();">
					<ng-container *ngIf="folder.value['_edit']!=true">
						<fa-icon *ngIf="folder.value['_icon']!=undefined && !folder.value['_creatable']!=true && folder.value['_editable']==true" [icon]="['fad', folder.value['_icon']]" class="map"></fa-icon>
						<fa-icon *ngIf="folder.value['_icon']==undefined && !folder.value['_creatable']!=true" [icon]="['fad', 'folder-grid']" class="map"></fa-icon>
						<fa-icon *ngIf="folder.value['_creatable']!=true && folder.value['_editable']!=true" [ngStyle]="{'color':'var(--cta-hover)'}" [icon]="['fas', 'folder']" class="map"></fa-icon>
						<fa-icon *ngIf="folder.value['_creatable']==true && folder.value['_editable']!=true" [icon]="['fad', 'folder-arrow-down']" class="map"></fa-icon>
						<fa-icon *ngIf="folder.value['_creatable']!=true && folder.value['_editable']==true" [icon]="['fad', 'folder-gear']" class="map"></fa-icon>						
						{{matchDocType(folder.key, originalCategories)}}
						<fa-icon *ngIf="folder.value['_opened']==true && haveSubFolders(folder)" style="position: absolute; right: 0px; top: 0px;" class="openclose" [icon]="['fas', 'chevron-up']" (click)="onFolderClick(folder.value['_path']);$event.stopPropagation();"></fa-icon>
					</ng-container>					
				</span>							
				<ng-container *ngIf="folder.value['_opened']">
					<ng-container *ngIf="countObject(folder)>0">
						<ng-container *ngFor="let sub_folder of folder | keyvalue: keepOrder">				
							<ng-container *ngTemplateOutlet="foldersTemplate; context:{ $implicit: sub_folder.value }"></ng-container>
						</ng-container>
					</ng-container>			
				</ng-container>				
			</li>
		</ng-container>
	</ul>
</ng-template>

<!-- Group template -->
<ng-template #groupTemplate let-folders>
	<ul class="directory-list" [ngClass]="{ 'component-list': asComponent }">
		<ng-container *ngFor="let folder of folders | keyvalue: keepOrder">		
			
				<li *ngIf="folder.key!='' && checkIfParameter(folder.key)" class="folder" [attr.path]="folder.value['_path']" [ngClass]="{'selectedFolder':folder.value['_selected']==true,'openedFolder':folder.value['_opened']==true}">				
					<span [ngClass]="{'selectedFolder':folder.value['_selected']==true,'openedFolder':folder.value['_opened']==true}" (click)="onGroupClick(folder.value['_path'], folder.value['_function'], folder.value['_entity']);$event.stopPropagation();">
						<ng-container *ngIf="folder.value['_edit']!=true">
							<fa-icon [icon]="['fad', folder.value['_icon']]" class="map"></fa-icon>
							{{toString(folder.key)|translate}} 
							<div *ngIf="folder.value['_opened'] && folder.key == 'by activity'" class="search" (click)="$event.stopPropagation()">
								<input type="text" (click)="$event.stopPropagation()" [(ngModel)]="folderFilterObject['by activity']" id="activity" (keyup)="folderFilter('by activity', $event)" placeholder="serch: by activity...">
							</div>
							<div *ngIf="folder.value['_opened'] && folder.key == 'by customer'" class="search" (click)="$event.stopPropagation()">
								<input type="text" (click)="$event.stopPropagation()" [(ngModel)]="folderFilterObject['by customer']" id="customer" (keyup)="folderFilter('by customer', $event)" placeholder="serch: by customer...">
							</div>
							<div *ngIf="folder.value['_opened'] && folder.key == 'by document'" class="search" (click)="$event.stopPropagation()">
								<input type="text" (click)="$event.stopPropagation()" [(ngModel)]="folderFilterObject['by document']" id="document" (keyup)="folderFilter('by document', $event)" placeholder="serch: by document...">
							</div>
						</ng-container>					
					</span>				
					<ng-container *ngIf="folder.value['_opened']">
						<ng-container *ngFor="let sub_folder of folder | keyvalue: keepOrder">				
							<ng-container *ngTemplateOutlet="groupTemplate; context:{ $implicit: sub_folder.value }"></ng-container>
						</ng-container>
					</ng-container>				
				</li>
			
		</ng-container>
	</ul>	
</ng-template>
