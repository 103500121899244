import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { NavigationService } from '../services/navigation.service';
import { MembersettingsService } from '../services/membersettings.service';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../services/data.globals';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})

export class NavComponent implements OnInit {
	
	public topNavigation = [];	
	public topNavigationShortcuts = {};
	public localFunction = [];
	public topSearch = "";
	public globalSearch = false;
	public memberSettingsGlobal = [];
	public username = GlobalVariables.username;
	
	/*******************************************************/

	constructor (
		private auth_svc: AuthService,
		private router: Router,
		public translate: TranslateService,
		private data: DataService,
		private NavigationService: NavigationService,
		private MembersettingsService: MembersettingsService
	) { 		
		translate.addLangs(['en', 'si']);
		translate.setDefaultLang('en');		
	}
	
	/*******************************************************/
		
	toggleSideBar() {

		this.memberSettingsGlobal['showSidebar'] = this.memberSettingsGlobal['showSidebar'] > 1 ? 0 : this.memberSettingsGlobal['showSidebar'] + 1;
		if (isNaN(this.memberSettingsGlobal['showSidebar'])) this.memberSettingsGlobal['showSidebar'] = 0;		
		this.NavigationService.updateShowSidebar(this.memberSettingsGlobal['showSidebar']);

	}

	/*******************************************************/

	icon(value) {

		if (value == null) return null;
		let output = value.split(",");
		if (output.length != 2) {
			output = ['fad', 'circle-xmark'];
		}		
		return output;

	}
		
	/*******************************************************/

	navigate(type, action, component, data) {
		this.NavigationService.navigate(type, action, component, data);
	}

	toggleOpenCloseSub(entity) {
		/* close any drop down menu */
		const elementNav = document.querySelector('#appTopNavigation .topNavigationButton .submenuWrapper');
  		if (elementNav) {(elementNav as HTMLElement).style.display = 'none'; }

		/**/
		let element = document.getElementById(entity);
		let isOpened = element.classList.contains("activeSubNavigation");

		let elements = document.querySelectorAll('.activeSubNavigation');
		for (var i = 0; i < elements.length; i++) { elements[i].classList.remove('activeSubNavigation'); }
		
		if (!isOpened) element.classList.add('activeSubNavigation');
	}

	closeAll() {
		let elements = document.querySelectorAll('.activeSubNavigation');
		for (var i = 0; i < elements.length; i++) { elements[i].classList.remove('activeSubNavigation'); }
	}

	/*******************************************************/

	ngOnInit() {	

		/* Navigation Service */
		this.NavigationService.topNavigation.subscribe(message => this.topNavigation = message);		
				
		/* MemberSettings Service */		
		//this.MembersettingsService.refreshMemberSettings(); //sometimes is not needed as is expected to be already called
		this.MembersettingsService.memberSettingsGlobal.subscribe(message => this.memberSettingsGlobal = message);	

		const mouseTarget = document.querySelector("#navWrapper");
		mouseTarget.addEventListener("mouseleave", (e) => {
			this.closeAll();
		});

	}
	
} 