<div class="inner_wrapper"> 
    <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="left"><h1>{{'Choose contacts for activity'|translate}}</h1></div>
        <div class="right">
            <fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
        </div>
    </div>

    <div class="body">
        <ng-container *ngFor='let eachitem of items | keyvalue; let i = index'>
            <div class="item" [ngClass]="{'selected' : items[i]['status']}" (click)="items[i]['status']=!items[i]['status']">
                <div class="item_left">
                    <fa-icon [icon]="['fad', 'phone']"></fa-icon>
                    <div style="float:left;width:100%;height:12px;"></div>
                    <fa-icon *ngIf="items[i]['status']" [icon]="['fad', 'check']"></fa-icon>
                    <fa-icon *ngIf="!items[i]['status']" [icon]="['fad', 'plus-circle']"></fa-icon>
                </div>
                <div class="item_right">
                    <div class="name">{{items[i]['name']}} {{items[i]['surename']}}&nbsp;</div>
                    <div class="function">{{items[i]['contact_type_name']}}&nbsp;</div>
                    <div class="phone">{{items[i]['phone']}}&nbsp;</div>
                    <div class="mobilephone">{{items[i]['mobile']}}&nbsp;</div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="modalActions">
        <button class="left cancel" (click)="cancel()">{{'Cancel'|translate}}</button>
        <button class="right action" (click)="close()">{{'Confirm'|translate}}</button>        
    </div>
</div>