/* Angular */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/* Data Services */
import { DataService } from '../services/data.service';

/* Other services */
import { GlobalVariables } from '../services/data.globals';


@Injectable({
  providedIn: 'root'
})
export class LatestChangesService {

  constructor(
    private data: DataService
  ) { }

  private lastActivitiesSource = new BehaviorSubject<Array<any>>([]);
	public lastActivities = this.lastActivitiesSource.asObservable();

  update() {
    var total_items = 0;		
    var date = <any>new Date();
    date.setDate(date.getDate() - 7);
    var date_format = date.toISOString().split("T")[0];
      
    var parameters_activities = { updated : date_format, items : 5, order : 'updated', orderby : 'desc', viewed : 1, days : 7, assigned: 1, last_change : 1};
    var parameters_comments = { created : date_format, items : 5, order : 'updated', orderby : 'desc', viewed : 1, days : 7, assigned: 1, last_change : 1};
    
    this.data.getActivities(parameters_activities).subscribe(
      (res: Response) => {	  
        //if (res['rows'] != undefined) total_items = total_items + (res['rows']).length;

        //this.items = res['rows'];

        this.lastActivitiesSource.next(res['rows']);
        
        //console.log(this.items);
        
        /*
        this.data.getLastComments(parameters_comments).subscribe(
          (res: Response) => {
            total_items = total_items + (res['rows']).length;
            if (this.unreadItems < total_items && this.unreadItems != -1 && this.mute == false) {
              var audio = new Audio();
              audio.src = "/assets/sounds/newItem.mp3";
              audio.load();
              audio.play();
            }
            this.unreadItems = total_items;
          }
        )
        */
      }
    )
  }


}
