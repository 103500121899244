<div class="widget_wrapper" id="{{id}}" [attr.refresh]="refresh" [attr.parameters]="parameters_json">
	<div class="widget_header" cdkDrag cdkDragRootElement=".dashboard_widget" cdkDragHandle>
		<div class="widget_refresh_bar">
			<!--<div class="widget_refresh_bar_progress" style="width:{{refresh_percent}}%"></div>-->
		</div>
		<div class="widget_title">
			{{parameters?.title}}
		</div>
		<div class="widget_tools">			
			<fa-icon class="icon_medium" [icon]="['fad', 'times']" (click)="remove(id)" style="margin-top: 5px;"></fa-icon>
			<div class="divider_header"></div>
			<fa-icon [icon]="['fad', 'cog']" (click)="settings(id, parameters_json)"></fa-icon>
		</div>
	</div>

	<div class="widget_content" [ngClass]="{'simple_activity_view' : parameters?.extended != true, 'grouped':parameters?.combinecustomer==true}">		
        
        
		
		<div *ngIf="chartOn" echarts class="demo-chart" [theme]="CoolTheme" [options]="options"></div>

		

		

	</div>
</div>

