/* Angular */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* Translations */
import { TranslateService } from '@ngx-translate/core';

/* Services */ 
import { GlobalVariables } from '../../../services/data.globals';

/* Common Functions */
import { CommonFunctions } from '../../../services/common.functions';

@Component({
  selector: 'app-assign-category',
  templateUrl: './assign-category.component.html',
  styleUrls: ['./assign-category.component.css']
})

export class AssignCategoryComponent implements OnInit {

	constructor(
		public dialog: MatDialogRef<AssignCategoryComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		public translate: TranslateService,
		public CommonFunction: CommonFunctions
	) {
	  translate.addLangs(['en', 'si']);
	}

	ngOnInit(): void {
		var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    	this.translate.use(lang);		
	}

	assignCategory(entity, hex, description) {
		this.data.currentCategory = entity;
		this.data.hex = hex;
		this.data.description = description;
		delete this.data.categories;
		this.dialog.close(this.data);
	}

	close() {
		delete this.data.categories;
		delete this.data.activity;
		this.data.currentCategory = null;
		this.dialog.close(this.data);
	}

}
