<form [formGroup]="formData" (ngSubmit)="save()" novalidate>	
	
    <div class="widget_settings">
    	<div class="innerPopup header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
			<div class="left">
					<h1>{{'Widget settings'|translate}}</h1>
			</div>
			<div class="right">
				<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
			</div>
		</div>

		<div class="inner_content">
			<div class="body">
				<div class="line">
					<div class="field">
						<input type="text" formControlName="title" name="title" [(ngModel)]="settings.title" />
						<div class="label" style="padding-top:6px;">{{'Widget title'|translate}}</div>
					</div>
				</div>
				
				<div class="line">
					<div class="field">
						<label>{{'Content display'|translate}}</label>
					    <!--
						<select style="margin-left:8px;float:left;margin-top:-6px;" formControlName="orderby" name="orderby" [(ngModel)]="settings.orderby">
					    	<option value="asc">Ascending</option>
					    	<option value="desc">Descending</option>
					    </select>
						-->
						&nbsp;&nbsp;{{'Last'|translate}}
					    <select style="margin-left:0px;float:left;margin-top:-6px;" formControlName="days" name="days" [(ngModel)]="settings.days">
					    	<option value="1">1</option>
							<option value="2">2</option>
							<option value="5">5</option>
							<option value="7">7</option>
							<option value="14">14</option>
							<option value="30">30</option>
						</select>
						{{'days'|translate}}
					</div>
				</div>

				<div class="multi_line" style="margin-top:6px;padding-bottom: 0px;padding-top: 16px;border-top: 1px solid var(--border-color)">
					<label>{{'Hide last activities by'|translate}}</label>
				
					<div class="field">
						<label class="switch">
							<input type="checkbox" formControlName="viewed" name="viewed" [(ngModel)]="settings.viewed">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Viewed'|translate}}</div>
					</div>
		        </div>

				<div class="multi_line" style="margin-top:6px;padding-bottom: 0px;padding-top: 16px;border-top: 1px solid var(--border-color)">
					<label>{{'Show last activities by'|translate}}</label>
				
					<div class="field">
						<label class="switch">
							<input type="checkbox" formControlName="assigned" name="assigned" [(ngModel)]="settings.assigned">
							<span class="slider round"></span>
						</label>
						<div class="label label-checkbox">{{'Assigned only'|translate}}</div>				
					</div>
		        </div>
		    </div>

			<div class="footer">
				<button class="confirm" type="submit" mat-button>{{'Confirm'|translate}}</button>
				<button class="cancel" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
			</div>
		</div>
	</div>
</form>