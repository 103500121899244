<div class="inner_wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
	<div class="header">
		<div class="left">
			<h1><fa-icon [icon]="['fad', 'list']"></fa-icon> | {{'Customer filters'|translate}}</h1>
		</div>
		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
		</div>
	</div>

	<form>
        <div class="body">
            <div class="left_data">
                <ng-container *ngFor='let item of item_types'>
                    <div class="line status_line">
                        <div class="field">
                            <label class="switch" style="float:left;">
                                <input *ngIf="item['selected']" type="checkbox" id="{{item.entity}}" name="{{item.entity}}" (click)="togleItemRestriction(item.entity);" checked>
                                <input *ngIf="!item['selected']" type="checkbox" id="{{item.entity}}" name="{{item.entity}}" (click)="togleItemRestriction(item.entity);">
                                <span class="slider round"></span>
                            </label>
                            <div class="label label-checkbox item_label" style="float:left; background:{{item.hex}}">{{item.description}}</div>            
                        </div>			
                    </div>
                </ng-container>        
            </div>
        </div>

        <div class="footer" mat-dialog-actions>
            <button class="confirm" (click)="confirm()">{{'Confirm'|translate}}</button>            
            <button class="cancel" mat-button mat-dialog-close>{{'Close'|translate}}</button>
        </div>
    </form>

</div>