/* Angular */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router'; 

/* Material */
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* Services */
import { DataService } from './data.service';
import { MembersettingsService } from './membersettings.service';

/* Components */
import { ActivityComponent } from '../activities/activity/activity.component';
import { CustomerComponent } from '../customers/customer/customer.component';
import { TimetrackingEditComponent } from '../timetracking/timetracking-edit/timetracking-edit.component'
import { UserSettingsComponent } from '../users/user-settings/user-settings.component';
import { SystemComponent } from '../system/system.component';

///* Gloab variables */
//import { GlobalVariables } from './data.globals';

@Injectable({ 
  providedIn: 'root'
})

export class NavigationService {
		
	public splitter = { entity: null, exposed: false, component: null, title: null, subTitle: null, subTitleColor: null, type: "splitter", action: null, icon: null, iconColor: null, pinnable: false, submenu: null };

	public memberAllowance = [];
	public defaultTopNavigation = [];

	private topNavigationSource = new BehaviorSubject<Array<any>>([]);
	public topNavigation = this.topNavigationSource.asObservable();

	private showSidebarSource = new BehaviorSubject<number>(0);
	public showSidebar = this.showSidebarSource.asObservable();

	private localFunctionSource = new BehaviorSubject<Array<any>>([]);
	public localFunction = this.localFunctionSource.asObservable();

	private localSearchSource = new BehaviorSubject<String>("");
	public localSearch = this.localSearchSource.asObservable();

	constructor(
		private router: Router,
		private data: DataService,
		private MembersettingsService: MembersettingsService, 
		public dialog: MatDialog,
	) { 	
	this.MembersettingsService.getMemberAllowance();
	this.MembersettingsService.memberAllowance.subscribe(message => this.memberAllowance = message);		
	}

	/*******************************************************/
	/* common functions */

	removeObjectWithProperty(arr, property, propertyName) {
		const objWithIdIndex = arr.findIndex(x => x.entity === 'apps');	
		if (objWithIdIndex != -1) arr.splice(objWithIdIndex, 1);
		return arr;	
	}

	replaceDataTopNavigation (entity, property, value, object) {
		for(let i = 0; i < object.length; i++) {
		object[i][property] = object[i].entity == entity ? value : object[i][property];
		object[i].submenu != null ? this.replaceDataTopNavigation(entity, property, value, object[i].submenu) : undefined;
		}
		return object
	}

	/*
	getMenuItem(itemEntity, itemObject) {
		let helper;
		for(let i = 0; i < itemObject.length; i++) {			
			if (itemObject[i]['entity'] == itemEntity) return itemObject[i];
			if (itemObject[i].submenu != null) {
				helper = this.getMenuItem(itemEntity, itemObject[i].submenu);
				if (helper['entity'] == itemEntity) return helper;		
			}
		}		
	}
	*/

	getMenuItem(itemEntity, itemObject) {
		let helper;
		for(let i = 0; i < itemObject.length; i++) {            
			if (itemObject[i]['entity'] == itemEntity) return itemObject[i];
			if (itemObject[i].submenu != null) {
				helper = this.getMenuItem(itemEntity, itemObject[i].submenu);
				if (helper && helper['entity'] == itemEntity) return helper;        
			}
		}
		return null; // Explicitly return null if item not found
	}

	/*******************************************************/
	/* localsearch */

	localSearchFunction(data) {    	
		this.localSearchSource.next(data);
	} 

	/*******************************************************/
	/* shared navigation */

	updateTopNavigation (data: Array<any>) {  

		////console.log('### Member Allowance');
		////console.log(this.memberAllowance);

		let helper = null;
		let topNavigationHelper = [];

		////console.log(data);
		////console.log(this.defaultTopNavigation);
		////console.log(this.defaultTopNavigation.length);

		/********************************************/
		/* Navigation.Level.2 */
		if (!this.defaultTopNavigation.length) {
			////console.log('Pass #1');
			this.defaultTopNavigation.push(
				{ entity: "apps", exposed: false, title: "Apps", subTitle: null, subTitleColor: null, type: null, action: null, icon: null,  iconColor: null, pinnable: false, refreshable: false, submenu: [] }
			);

			/* app.summary */
			this.defaultTopNavigation[0].submenu.push(
				{ entity: "app.summary", exposed: false, component: null, title: "Summary", subTitle: "", subTitleColor: "", type: "link", action: "/", icon: "fad,home",  iconColor: null, pinnable: false, refreshable: false, submenu: null }	
			);

			/* app splitter */
			this.defaultTopNavigation[0].submenu.push( this.splitter );

			/* app.calendar */
			this.defaultTopNavigation[0].submenu.push(
				{ entity: "app.calendar", exposed: false, component: null, title: "Calendar", subTitle: "", subTitleColor: "", type: "link", action: "/calendar", icon: "fad,calendar",  iconColor: null, pinnable: false, refreshable: false, submenu: null }
			);

			/* app.activities */
			if (this.memberAllowance['apps']?.activities?.view || this.memberAllowance['admin_rights']) {
				this.defaultTopNavigation[0].submenu.push(
					{ entity: "app.activities", exposed: false, component: null, title: "Activities", subTitle: "", subTitleColor: "", type: null, action: null, icon: "fad,list",  iconColor: null, pinnable: false, refreshable: false, submenu: null }
				);
			}

			/* app.customer */
			if (this.memberAllowance['apps']?.customers?.view || this.memberAllowance['admin_rights']) {
				this.defaultTopNavigation[0].submenu.push(
					{ entity: "app.customers", exposed: false, component: null, title: "Customers", subTitle: "", subTitleColor: "", type: null, action: null, icon: "fad,users",  iconColor: null, pinnable: false, refreshable: false, submenu: null }
				);
			}

			/* app.eArchive */
			if (this.memberAllowance['apps']?.archives?.view || this.memberAllowance['admin_rights']) {
				this.defaultTopNavigation[0].submenu.push(
					{ entity: "app.eArchive", exposed: false, component: null, title: "e-Archive", subTitle: "", subTitleColor: "", type: null, action: null, icon: "fad,archive",  iconColor: null, pinnable: false, refreshable: false, submenu: null }
				);
			}

			/* app.knowledgeCenter */
			if (this.memberAllowance['apps']?.knowledge?.view || this.memberAllowance['admin_rights']) {
				this.defaultTopNavigation[0].submenu.push(
					{ entity: "app.knowledgeCenter", exposed: false, component: null, title: "Knowledge Center", subTitle: "", subTitleColor: "", type: "link", action: "/knowledge", icon: "fad,books",  iconColor: null, pinnable: false, refreshable: false, submenu: null }
				);
			}

			/* app.timeTracking */
			if (this.memberAllowance['apps']?.timetracking?.view || this.memberAllowance['admin_rights']) {
				this.defaultTopNavigation[0].submenu.push(
					{ entity: "app.timeTracking", exposed: false, component: 'timetracking', title: "Time Tracking", subTitle: "", subTitleColor: "", type: null, action: null, icon: "fad,user-clock",  iconColor: null, pinnable: false, refreshable: true, submenu: null }
				);
			}

			

			/* app.erpDocument */
			/* potrebno dodelati glede na invoice, quote, creditnote */
			if (this.memberAllowance['apps']?.erp?.view || this.memberAllowance['admin_rights']) {
				this.defaultTopNavigation[0].submenu.push(
					{ entity: "app.erpDocument", exposed: false, component: null, title: "Erp Documents", subTitle: "", subTitleColor: "", type: null, action: null, icon: "fad,file", iconColor: null, pinnable: false, refreshable: false, submenu: [] }
				);

				topNavigationHelper = [];

				if (this.memberAllowance['apps']?.erp_invoices?.view || this.memberAllowance['admin_rights']) {
					topNavigationHelper.push(
						{ entity: "app.erpdocument.invoices", exposed: false,component: null, title: "Invoices", subTitle: "", subTitleColor: "", type: "link", action: "/office_erp_documents/invoice", icon: "fad,file-invoice", iconColor: null, pinnable: true, submenu: null }
					);
				}

				if (this.memberAllowance['apps']?.erp_credit_notes?.view || this.memberAllowance['admin_rights']) {
					topNavigationHelper.push(
						{ entity: "app.erpdocument.creditnotes", exposed: false, component: null, title: "Credit notes", subTitle: "",subTitleColor: "", type: "link", action: "/office_erp_documents/creditnote", icon: "fad,file-minus", iconColor: null, pinnable: true, submenu: null }
					);
				}
				
				if (this.memberAllowance['apps']?.erp_quotes?.view || this.memberAllowance['admin_rights']) {
					topNavigationHelper.push(
						{ entity: "app.erpdocument.quotes", exposed: false, component: null, title: "Quotes", subTitle: "",subTitleColor: "", type: "link", action: "/office_erp_addon/richquote", icon: "fad,file-lines", iconColor: null, pinnable: true, submenu: null}
					);
				}
				
				if (topNavigationHelper.length > 0) {
					topNavigationHelper.push( this.splitter );
				}

				if (this.memberAllowance['apps']?.erp?.settings || this.memberAllowance['admin_rights']) {
					topNavigationHelper.push(
						{ entity: "app.erpdocument.items", exposed: false, component: null, title: "Erp Items", subTitle: "",subTitleColor: "", type: "link", action: "/office_erp_addon/richitems", icon: "fad,gear", iconColor: null, pinnable: false, submenu: null }
					);

					topNavigationHelper.push(
						{ entity: "app.erpdocument.itemsgroups", exposed: false, component: null, title: "Erp Items Category", subTitle: "",subTitleColor: "", type: "link", action: "/office_erp_addon/richitemcategory", icon: "fad,gear", iconColor: null, pinnable: false, submenu: null }
					);
				}

				this.replaceDataTopNavigation ('app.erpDocument', 'submenu', topNavigationHelper, this.defaultTopNavigation);
			}

			

			/* app.users */			
			if (this.memberAllowance['apps']?.users?.view || this.memberAllowance['admin_rights']) {
				/* app splitter */
				this.defaultTopNavigation[0].submenu.push( this.splitter );
				this.defaultTopNavigation[0].submenu.push(
					{ entity: "app.users", exposed: false, component: null, title: "Users", subTitle: "", subTitleColor: "", type: "link", action: "/users", icon: "fad,users-gear",  iconColor: null, pinnable: false, refreshable: false, submenu: null }
				);
			}
			
			

			/* app.system */
			if (this.memberAllowance['apps']?.system_settings?.view || this.memberAllowance['admin_rights']) {
				/* app splitter */
				this.defaultTopNavigation[0].submenu.push( this.splitter );
				this.defaultTopNavigation[0].submenu.push(
					{ entity: "app.system", exposed: false, component: null, title: "System Settings/Logs", subTitle: "", subTitleColor: "", type: null, action: null, icon: "fad,computer", iconColor: null, pinnable: false, refreshable: false, submenu: [] }
				);

				topNavigationHelper = [];
				topNavigationHelper.push({ entity: "app.systemSettings", exposed: false, component: null, title: "System settings", subTitle: "", subTitleColor: "", type: 'popup', action: 'systemSettings', icon: "fad,cog", iconColor: null, pinnable: false, refreshable: false, submenu: null });
				
				if (this.memberAllowance['admin_rights']) {
					topNavigationHelper.push({ entity: "app.mailLog", exposed: false, component: null, title: "Email Log", subTitle: "", subTitleColor: "", type: 'link', action: '/mail_logs', icon: "fad,envelope-open-text", iconColor: null, pinnable: false, refreshable: false, submenu: null });
				}

				if (this.memberAllowance['apps']?.recovery?.view || this.memberAllowance['admin_rights']) {
					topNavigationHelper.push({ entity: "app.dataRecovery", exposed: false, component: null, title: "Data Recovery", subTitle: "", subTitleColor: "", type: 'link', action: '/recovery', icon: "fad,trash-undo", iconColor: null, pinnable: false, refreshable: false, submenu: null });
				}				
				this.replaceDataTopNavigation('app.system', 'submenu', topNavigationHelper, this.defaultTopNavigation);
			}

			/* app splitter */
			this.defaultTopNavigation[0].submenu.push( this.splitter );

			/* app.me */
			this.defaultTopNavigation[0].submenu.push(
				{ entity: "app.me", exposed: false, component: null, title: "Me (Not defined)", subTitle: "", subTitleColor: "", type: null, action: null, icon: "fad,users-crown", iconColor: null, pinnable: false, refreshable: false, submenu: [
					{ entity: "app.userSettings", exposed: false, component: null, title: "User settings", subTitle: "", subTitleColor: "", type: 'popup', action: 'userSettings', icon: "fad,user-gear", iconColor: null, pinnable: false, refreshable: false, submenu: null }
				] }
			);			

			/* logout */			
			this.defaultTopNavigation[0].submenu.push(
				{ entity: "app.logout", exposed: false, component: null, title: "Logout", subTitle: "", subTitleColor: "", type: 'logout', action: null, icon: "fad,right-from-bracket", iconColor: '#FF3333', pinnable: false, refreshable: false, submenu: null }
			);	
			
			
		}

		/********************************************/
		/********************************************/
		/* Navigation.Level.3 */

		/********************************************/
		/* Activities */
				
		if (this.memberAllowance['apps']?.activities?.view || this.memberAllowance['admin_rights']) {
			
			let parent = this.getMenuItem('app.activities', this.defaultTopNavigation);		
			if (parent && (parent['submenu'] == null || parent['refreshable'])) {
				this.data.getCategories({ items: 99 }).subscribe(
					(res: Response) => {
						helper = (<any>res).rows;
						topNavigationHelper = [];
						
						topNavigationHelper.push({ entity: "app.activities.add", exposed: true, component: null, title: "Add Activity", subTitle: "", subTitleColor: "", type: "popup", action: "activity", icon: "fad,circle-plus", iconColor: null, pinnable: true, submenu: null });	
									
						for (let i=0; i<helper.length; i++) {				
							topNavigationHelper.push({ entity: "app.activities."+(i), exposed: false, component: null, title: helper[i].description, subTitle: "", subTitleColor: "", type: "link", action: "/activities/" + helper[i].entity, icon: "fad,list", iconColor: helper[i].hex, pinnable: false, submenu: null });
						}

						topNavigationHelper.push(this.splitter);
						
						if (this.memberAllowance['apps']?.activities?.settings || this.memberAllowance['admin_rights']) {
							topNavigationHelper.push({ entity: "app.activities.types", exposed: false, component: null, title: "Activity Types", subTitle: "", subTitleColor: "", type: "link", action: "/activity-types", icon: "fad,gear", iconColor: null, pinnable: false, submenu: null });
						}

						this.replaceDataTopNavigation('app.activities', 'submenu', topNavigationHelper, this.defaultTopNavigation);
					}
				);
			}
		}
		
		

		/********************************************/
		/* Customers */

		this.data.getCustomers({ items: 5, page: 0, order: 't1.entity', orderby: 'desc', search: null, subactivity: false }).subscribe(
			(res: Response) => {
				let helper = (<any>res).rows;
				let topNavigationHelper = [];

				topNavigationHelper.push(
					{ entity: "app.customers.add", exposed: false, component: null, title: "Add Customer", subTitle: "", subTitleColor: "", type: "popup", action: "customer", icon: "fad,user-plus", iconColor: null, pinnable: true, submenu: null },
					{ entity: "app.customers.all", exposed: false, component: null, title: "All Customers", subTitle: "", subTitleColor: "", type: "link", action: "/customers", icon: "fad,users", iconColor: null, pinnable: true, submenu: null },
					{ entity: "app.customers.allcontacts", exposed: false, component: null, title: "All Contacts", subTitle: "", subTitleColor: "", type: "link", action: "/customer-contacts", icon: "fad,address-book", iconColor: null, pinnable: false, submenu: null },
					{ entity: null, exposed: false, component: null, title: "Last added customers:", subTitle: null, subTitleColor: null, type: "text", action: null, icon: null, iconColor: null, pinnable: false, submenu: null }
				);

				for (let i=0; i<helper.length; i++) {				
					topNavigationHelper.push(
						{entity: "app.customers."+(i), exposed: false, component: null, title: helper[i].company, subTitle: "", subTitleColor: "", type: "link", action: "/customers/" + helper[i].entity, icon: "fad,user", iconColor: helper[i].status_color, pinnable: false, submenu: null}
					);
				}

				topNavigationHelper.push( this.splitter );

				topNavigationHelper.push(
					{ entity: "app.customers.type", exposed: false, component: null, title: "Customer type", subTitle: "", subTitleColor: "", type: "link", action: "/custom_type", icon: "fad,users-gear", iconColor: null, pinnable: false, submenu: null },
					{ entity: "app.customers.contacttype", exposed: false, component: null, title: "Contact type", subTitle: "", subTitleColor: "", type: "link", action: "/contact_type", icon: "fad,user-tag", iconColor: null, pinnable: false, submenu: null },
					{ entity: "app.customers.customfields", exposed: false, component: null, title: "Custom customer fields", subTitle: "", subTitleColor: "", type: "link", action: "/custom_fileds", icon: "fad,user-tag", iconColor: null, pinnable: false, submenu: null }
				);	

				this.replaceDataTopNavigation ('app.customers', 'submenu', topNavigationHelper, this.defaultTopNavigation);
			}
		);

		

		/********************************************/
		/* E archives */

		this.data.getDocuments({ 'page' : 0, 'items' : 5, 'order' : 't1.updated', 'orderby' : 'asc', search: null }).subscribe(
			(res: Response) => {
				let helper = (<any>res).rows;
				////console.log()
				let topNavigationHelper = [];

				topNavigationHelper.push(
					{ entity: "app.earchive.manager", exposed: false, component: null, title: "Document Manager", subTitle: "", subTitleColor: "", type: "link", action: "/document-center", icon: "fad,box-archive", iconColor: null, pinnable: true, submenu: null },
					{ entity: null, exposed: false, component: null, title: "Last uploaded documents:", subTitle: null, subTitleColor: null, type: "text", action: null, icon: null, iconColor: null, pinnable: false, submenu: null }
				);

				for (let i=0; i<helper.length; i++) {
					topNavigationHelper.push(
						{ entity: "app.earchive."+(i), exposed: false, component: null, title: helper[i].original_filename , subTitle: "/"+helper[i].folder , subTitleColor: "", type: "link", action: "", icon: "fad,file-invoice", iconColor:"", pinnable: false, submenu: null }
					);
				}
				
				if (this.memberAllowance['apps']?.archives?.settings || this.memberAllowance['admin_rights']) {
					topNavigationHelper.push( this.splitter );
					topNavigationHelper.push(
						{ entity: "app.earchive.types", exposed: false, component: null, title: "Document Types", subTitle: "", subTitleColor: "", type: "link", action: "/document-types", icon: "fad,gear", iconColor: null, pinnable: false, submenu: null }
					);
				}

				this.replaceDataTopNavigation ('app.eArchive', 'submenu', topNavigationHelper, this.defaultTopNavigation);
			}
		);

		

		/********************************************/
		/* Time trackings */
		this.updateSubmenuOnRequest('timetracking');

		/********************************************/
		/* Erp documents */
		/*
		this.data.getErpDocuments({ items : 10, page : 0, order : 't1.entity', orderby : 'desc', search : null }).subscribe(
			(res: Response) => {

			}
		);
		*/
		
		////console.log(topNavigationHelper);
		//console.log(data);
		this.replaceDataTopNavigation('app.me', 'title', this.memberAllowance['username'], this.defaultTopNavigation);
		//console.log(data);
		/* FIX: ni najboljša rešitev, daje potrebno iskati in preverjali ali podatek že obstaja in ga potem brisati... očitno je tažava v tem, da 2x potisnemo podatke v array nekje */
		this.removeObjectWithProperty(data, "entity", "apps");	

		data.unshift(this.defaultTopNavigation[0]);	
		//console.log(data);
		this.topNavigationSource.next(data);	
  	}
   
  /* Menu elements */



  updateShowSidebar(data) {
    this.showSidebarSource.next(data);
	//this.data.setMemberSettings({'parameter': 'showSidebar', 'value': data}).subscribe((res: Response) => {});     
	this.MembersettingsService.setMemberSettings('showSidebar', data);
  }

  /*******************************************************/
  /* navigate procedures */
  updateSubmenuOnRequest(request) {
	console.log("Request for update: " + request);
	if (request == "timetracking") {
		this.data.getMyLastTimetrackings(null).subscribe(
			(res: Response) => {
				let helper = (<any>res).rows;				
				let topNavigationHelper = [];

				topNavigationHelper.push(
					{ entity: "app.timeTracking.add", exposed: false, component: null, title: "Add Tracking", subTitle: "", subTitleColor: "", type: "popup", action: "timetracking", icon: "fad,user-clock", iconColor: null, pinnable: true, submenu: null },
					{ entity: "app.timeTracking.all", exposed: false,component: null, title: "All Trackings", subTitle: "", subTitleColor: "", type: "link", action: "/timetracking", icon: "fad,timer", iconColor: null, pinnable: true, submenu: null },
					{ entity: null, exposed: false, component: null, title: "Your last trackings: ", subTitle: null, subTitleColor: null, type: "text", action: null, icon: null, iconColor: null, pinnable: false, submenu: null }
				);

				for (let i=0; i<helper.length; i++) {										
					let iconColor = helper[i].duration[0] == "-" ? "#ff5454" : null;
					let icon = helper[i].duration[0] == "-" ? "fas,stopwatch" : "fad,stopwatch";
					topNavigationHelper.push(
						{ entity: "app.timeTracking."+(i), exposed: false, component: 'timetracking', title: helper[i].activity_title, subTitle: helper[i].customer + " | " + helper[i].date, subTitleColor: "", type: "popup", action: "timetracking", actionData: helper[i].entity, icon: icon, iconColor:iconColor, pinnable: false, submenu: null }
					);
				}

				topNavigationHelper.push( this.splitter );

				topNavigationHelper.push(
					{ entity: "app.timeTracking.settings", exposed: false, component: null, title: "Clasification settings", subTitle: "", subTitleColor: "", type: "link", action: "/time-settings", icon: "fad,gear", iconColor: null, pinnable: false, submenu: null }
				);

				this.replaceDataTopNavigation ('app.timeTracking', 'submenu', topNavigationHelper, this.defaultTopNavigation);
			}
		);
	}
  }

  /******************************************/

  navigate(type, action, component, data) {	
	console.log('*** Navigation call > ', type, action, component, data);
	if (type == 'link') {		
		if (!action.startsWith('/')) { action = '/' + action; }		
		this.router.navigate([action], {
			state: { 
			  data: data
			}
		});	  
	} else if (type == 'localFunction') {		
		this.prepareObserverForLocalFunction([component, type, action, data]);		
	} else if (type == 'popup') {
		/* not best solution, find better dynamic way */
		//////console.log(type, action, component, data);
		if (action == "activity") {			
			var dialogActivity = this.dialog.open(ActivityComponent, { panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true, data : { entity : data } });
			//dialogActivity.componentInstance.entity = data;
		}
		if (action == "customer") {
			var dialogCustomer = this.dialog.open(CustomerComponent, { disableClose: true });
	    	dialogCustomer.componentInstance['entity'] = data;
		}
		if (action == "timetracking") {			
			var dialogTimeTracking = this.dialog.open(TimetrackingEditComponent, { disableClose: true });
	    	dialogTimeTracking.componentInstance['entity'] = data;
		}
		if (action == "userSettings") {
			var dialogUserSettings = this.dialog.open(UserSettingsComponent, {});
		}
		if (action == "systemSettings") {
			var dialogSystemSettings = this.dialog.open(SystemComponent, { panelClass: 'systemsettings'});
		}
	} else if (type == "logout") {		
		sessionStorage.clear();
		localStorage.removeItem('AUTH_TOKEN');		
		window.location.reload();
	}

  }

  prepareObserverForLocalFunction (data: Array<any>) {    	
	this.localFunctionSource.next(data);
  }

  /*******************************************************/
  /* onInit */

  
}