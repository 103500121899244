import { Component } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-erp-form-edit',
  templateUrl: './erp-form-edit.component.html',
  styleUrls: ['./erp-form-edit.component.css']  
})

export class ErpFormEditComponent {

  constructor(
		public translate: TranslateService,
	) { 
		translate.addLangs(['en', 'si']);
	}

  public editSettings = false;

  formSaved = false;

  formValidated = false;

  formContent = [
    {
      title: "custom title",
      subtitle: "custom subtitle",
      function: "text",
      defaultValues: null,
      defaultValuesText: null
    },
    {
      title: "custom title 2",
      subtitle: "custom subtitle 3",
      function: "textarea",
      defaultValues: null,
      defaultValuesText: null
    },
    {
      title: "custom title 3",
      subtitle: "custom subtitle 3",
      function: "checkbox",
      defaultValues: [
        {id:1234324, text:"value text1"},
        {id:1234324, text:"value text2"},
        {id:1234324, text:"value text3"},
        {id:1234324, text:"value text4"}
      ],
      defaultValuesText: null
    },
    {
      title: "custom title 4",
      subtitle: "custom subtitle 4",
      function: "options",
      defaultValues: [
        {id:1234324, text:"value text1"},
        {id:1234324, text:"value text2"},
        {id:1234324, text:"value text3"},
        {id:1234324, text:"value text4"}
      ],
      defaultValuesText: null
    },
    {
      title: "custom title 5",
      subtitle: "custom subtitle 5",
      function: "dropdown",
      defaultValues: [
        {id:1234324, text:"value text1"},
        {id:1234324, text:"value text2"},
        {id:1234324, text:"value text3"},
        {id:1234324, text:"value text4"}
      ],
      defaultValuesText: null
    }    
  ];

  addItem() {
    this.formContent.push({ title: "", subtitle: "", function: "text", defaultValues: null, defaultValuesText: null });
  }

  removeItem(i) {
    var helper = [];
    for (var index = 0; index < (this.formContent).length; index++) {
      if (index != i) helper.push(this.formContent[index]);
    }    
    this.formContent = helper;
  }

  validate() {
    this.formValidated = true;
  }

  save() {
    this.formSaved = true;
    console.log( JSON.stringify(this.formContent) );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.formContent, event.previousIndex, event.currentIndex);
  }

}
