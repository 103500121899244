import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ActivitySelectComponent } from '../activities/activity-select/activity-select.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivityComponent } from '../activities/activity/activity.component';


import { GlobalVariables } from '../services/data.globals';
import { CommonFunctions } from '../services/common.functions';
import { TranslateService } from '@ngx-translate/core';  //translate:START:END

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  constructor(
    private data: DataService,
    public Dashboard: DashboardComponent,
    public dialog: MatDialog,	
    public cFunction: CommonFunctions,
    public translate: TranslateService 
  ) { }

  public selected_month = {};
  public week_eu = new Array('Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Sunday');
  public month = new Array('January','February','March','April','May','June','July','August','September','October','November','December');
  public week = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');    
  public hours = ['07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00'];
  public prev_month;  
  public this_month;
  public next_month;
  public current_month;
  public current_month_raw;
  public current_year;
  public current_date = new Date();
  public activities = [];
  public expand = false;
  public CalendarFilter = false;
  public categories = [];
  public settings = {category:null,view:null};
  public sorting = "all";
  public range = "monthly";
  public todaysDay = null;
  public todaysMonth = null;
  public todaysYear = null;
  public todaysWeek = null;
  public showMenu = false;
  

  countObject(objectVar) {
		return Object.keys(objectVar).length
	}

  compareHours(timeA, timeB) {
    let helperA = timeA.split(":");
    let helperB = timeB.split(":");
    return helperA[0] == helperB[0];
  }

  activity(entity) {
    var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
		editDialog.componentInstance.entity = entity;
		
		editDialog.afterClosed().subscribe(result => {
			this.generateCalendar();
		});
  }

  selectDate(dayDate, activity_count) {   
    if (activity_count != null) {
      var data = { 'date_start': dayDate, 'date_end': dayDate, 'autosearch': true, 'calendar_restriction' : true };
      var ActivitySelectComponentDialog = this.dialog.open(ActivitySelectComponent, { data : data });
      ActivitySelectComponentDialog.afterClosed().subscribe(result => {
        this.loadCalendar({ month : this.current_month, year : this.current_year });
      });
    } else {
      this.add_activity(dayDate);    
    }
  }

  add_activity(date) {		
		var editDialog = this.dialog.open(ActivityComponent, {panelClass: 'dialog-activity',  width: '92vw', maxWidth: '92vw', disableClose: true });
		editDialog.componentInstance.entity = null;
    //editDialog.componentInstance.date = date;
		editDialog.afterClosed().subscribe(result => {
      this.loadCalendar({ month : this.current_month, year : this.current_year });
    });		
  }

  loadCalendar(parameters) {          
    this.data.getCalendar(parameters).subscribe(
      (res: Response) => {        
        console.log(<any>res);
        this.activities = <any>res
      }
    );
  } 

  change_to_prev_month() {
    this.current_date = this.prev_month;
    this.selected_month = {};
    this.generateCalendar();
  }

  change_to_next_month() {
    this.current_date = this.next_month;
    this.selected_month = {};
    this.generateCalendar();
  }

  generateCalendar() {
    var date = this.current_date;
    var day = date.getDate();
    var requested_month = date.getMonth()+1;    
    var year = date.getFullYear();

    this.prev_month = new Date(year, requested_month - 2, 1);
    this.this_month = new Date(year, requested_month - 1, 1);
    this.next_month = new Date(year, requested_month, 1);

    this.current_year = year;
    this.current_month = this.month[requested_month - 1];
    this.current_month_raw = requested_month;
    
    var first_week_day = this.this_month.getDay();
    var days_in_this_month = Math.round((this.next_month.getTime() - this.this_month.getTime()) / (1000 * 60 * 60 * 24));
    
    var start = new Date(year + "-" + ("0" + requested_month.toString()).slice(-2) + "-01");
    var first_week_day = <any>start.getDay();
    
    var number_of_days = days_in_this_month + (start.getDay() - 1) + 7;
    start.setDate(start.getDate() - (start.getDay() - 1) - 7);      

    var add_on_end = 0;

    for (var i=0; i<number_of_days+add_on_end; i++) {
      var day = start.getDate();
      var month = start.getMonth() + 1;
      var year = start.getFullYear();

      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)] = [];
      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)]['current'] = requested_month == month ? true : false;
      
      var helper = new Date(year + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2));
      var startHelper = new Date(helper.getFullYear(), 0, 1);
      var days = Math.floor((helper.getTime() - startHelper.getTime()) / (24 * 60 * 60 * 1000))+1; 
      var weekNumber = Math.ceil(days / 7);

      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)]['week'] = weekNumber;
      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)]['day'] = day;
      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)]['wday'] = helper.getDay();
      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)]['wday_name'] = this.week[helper.getDay()];
      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)]['month'] = month;
      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)]['month_name'] = this.month[month];
      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)]['year'] = year;
      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)]['activities'] = {};

      /*
      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)]['activities'][678] = [];
      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)]['activities'][678]['hex'] = '#f0f';
      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)]['activities'][678]['title'] = 'Activity title text';
      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)]['activities'][678]['start'] = '14:30';
      this.selected_month[year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2)]['activities'][678]['end'] = '15:30';
      */

      start.setDate(start.getDate() + 1);

      /* add empty dates on the end */
      if (i == number_of_days-1) {
        console.log(helper.getDay());
        add_on_end = 7 - helper.getDay();
        if (helper.getDay() > 1 && helper.getDay() < 7) add_on_end = add_on_end + 7;
      }
    }   
    
    /* fill calendar with activities */
    
    /* get data */    
    this.data.getCalendar({ month : requested_month, year : year, categories: this.settings['categories'] }).subscribe(
      (res: Response) => {        
        console.log(<any>res);
        this.activities = <any>res;
        
        for (var i=0; i<Object.keys(this.activities['activities']['rows']).length; i++) {
          var message_start = (this.activities['activities']['rows'][i]['message_start']).split(" ");
          var message_end = (this.activities['activities']['rows'][i]['message_end']).split(" ");
          var entity = this.activities['activities']['rows'][i]['entity'];
          var title = this.activities['activities']['rows'][i]['title_raw'];
          var hex = this.activities['activities']['rows'][i]['message_type_hex'];          
          var time_start = message_start[1];
          var time_end = message_end[1];

          var startDate = new Date(message_start[0]);
          startDate.setHours(0, 0, 0, 0);          
          var endDate = new Date(message_end[0]);
          endDate.setHours(0, 0, 0, 0);
          
          let icon = null;
          let date = null;
          
          if (this.settings['sorting']=="start") {
            icon = startDate == endDate ? "circle" : "right-from-line";
            endDate = new Date(message_start[0]);
            endDate.setHours(0, 0, 0, 0);
            date = new Date(startDate.getTime());            
            date.setHours(0, 0, 0, 0);
          } else if (this.settings['sorting']=="last") {
            icon = startDate == endDate ? "circle" : "right-to-line";
            date = new Date(endDate.getTime());
            date.setHours(0, 0, 0, 0);
          } else {
            icon = startDate == endDate ? "circle" : "right";
            date = new Date(startDate.getTime());
            date.setHours(0, 0, 0, 0);
          }
            
          var days = 0;          
          while (date <= endDate) {

            date.setDate(date.getDate() + 1);
            date.setHours(0, 0, 0, 0);  

            var calendar_date = (date.toISOString().slice(0, 19).replace('T', ' ')).split(" ");
            if (days == 0) this.selected_month[calendar_date[0]]['count'] = 0;
            if (this.selected_month.hasOwnProperty(calendar_date[0]) && (this.settings['sorting']!="startstop" || (this.settings['sorting']=="startstop" && (date.getTime() == startDate.getTime()+86400000 || date.getTime() == endDate.getTime()+86400000)))) {
            
              days++;

              if (this.settings['sorting']=="startstop") {
                if (startDate.getTime() == endDate.getTime()) {
                  icon = "circle";
                } else {
                  if (date.getTime() == startDate.getTime()+86400000) {
                    icon = "right-from-line";
                  } else if (date.getTime() == endDate.getTime()+86400000) {
                    icon = "right-to-line";
                  }
                }
              }

              this.selected_month[calendar_date[0]]['count']++;
              this.selected_month[calendar_date[0]]['activities'][entity] = [];
              this.selected_month[calendar_date[0]]['activities'][entity]['icon'] = icon;             
              this.selected_month[calendar_date[0]]['activities'][entity]['hex'] = hex;
              this.selected_month[calendar_date[0]]['activities'][entity]['title'] = title;

              if (days == 1) {
                this.selected_month[calendar_date[0]]['activities'][entity]['start'] = time_start.slice(0,5); //time_end;
              } else {
                this.selected_month[calendar_date[0]]['activities'][entity]['start'] = "All day"; //time_end.slice(0,5); //time_end;
              }              
            }

            if (days > 1) { this.selected_month[calendar_date[0]]['activities'][entity]['start'] = time_end.slice(0,5); }
            
          }          
        }
    });  

  }

  toggleExpand() {
    this.expand = !this.expand;
    console.log(this.expand);
    this.data.setMemberSettings({'parameter': 'calendarExpand', 'value': this.expand}).subscribe((res: Response) => {});     
  }

  openFilter() {
  }

  applyFilter() {
    this.CalendarFilter=!this.CalendarFilter;
    
    /* get permissions */
    var categories = [];
    $('.multiselect_checkbox.categories .switch').each(function() {
      var rel = $(this).find('input[type=checkbox]').attr('rel');
      var checked = $(this).find('input[type=checkbox]').is(':checked');      
      var category = {'category_id':rel};
      if (checked == true) {
        categories.push(category);
      }
    });
    
    this.settings['categories'] = categories;
    this.settings['sorting'] = this.sorting;
    this.settings['range'] = this.range;
    console.log(this.settings);
    //console.log(JSON.stringify(this.settings));
    this.data.setMemberSettings({'parameter': 'calendarSettings', 'value': JSON.stringify(this.settings)}).subscribe((res: Response) => {});     
    this.generateCalendar();
  }

  ngOnInit(): void {

    var date = new Date();
    this.todaysDay = date.getDate();
    this.todaysMonth = date.getMonth() + 1;
    this.todaysYear = date.getFullYear();
    
    var startHelper = new Date(date.getFullYear(), 0, 1);
    var days = Math.floor((date.getTime() - startHelper.getTime()) / (24 * 60 * 60 * 1000)); 
    this.todaysWeek = Math.ceil(days / 7);

    /* clear top custom navigation */
		$(".header_helper_left").html('');

    this.data.getMemberSettings(null).subscribe(
      (res: Response) => {    
          var memberSettingsArray = [];        
          for (var i=0; i<res['rows'].length; i++) {          
              memberSettingsArray[res['rows'][i].parameter] = res['rows'][i].value;
          }        
      GlobalVariables.memberSettings = memberSettingsArray;    
            
      var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
      this.translate.use(lang);	    
            
      this.expand = GlobalVariables.memberSettings['calendarExpand'] == 1 ? true : false;
      
      if (GlobalVariables.memberSettings['calendarSettings'] != "") {
        if (GlobalVariables.memberSettings['calendarSettings'] != undefined) {
          this.settings = JSON.parse(GlobalVariables.memberSettings['calendarSettings']);
        } 
      }
      this.data.getActivityTypes({}).subscribe(
        (res: Response) => {
          this.categories = (<any>res).rows;        
          
          this.sorting = this.settings['sorting'] ? this.settings['sorting'] : "start";
          this.range = this.settings['range'] ? this.settings['range'] : "monthly";
          
          if (this.settings['categories']) {
            for (var i=0; i<(this.categories).length; i++) {
              for (var j=0; j<(this.settings['categories']).length; j++) {
                //console.log(this.settings['categories'][j]);
                if (this.settings['categories'][j].category_id == this.categories[i].entity) {
                  this.categories[i]['selected'] = true;
                }             
              }
            }
          }
    
          this.generateCalendar();
        }        
      ); //getActivityTypes()
    });   


    

  }

}
