import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { ActivatedRoute } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TopComponent } from '../../../top/top.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {

	public id = null;
	public entity;
	private sub : any;
	public parameters = {};
	public formData = {};

	CustomerTypeForm: UntypedFormGroup;

	constructor(
		private data: DataService,
		private route: ActivatedRoute/*,
		private topmenu: TopComponent*/,
		public translate: TranslateService 
	) {
		this.CustomerTypeForm = this.createFormGroup();
		translate.addLangs(['en', 'si']);
	}

	createFormGroup() {
		return new UntypedFormGroup({
			entity: new UntypedFormControl(),
			description: new UntypedFormControl(),
			hex: new UntypedFormControl()
		});
	}

	get(id) {    
		this.parameters = { id: id };
		this.data.getCustomerType(this.parameters).subscribe(
			(res: Response) => {
				this.formData = (<any>res).rows[0];        
				//this.author = (<any>this.activity).author;
				//this.author_inicialke = this.getInitials(this.author);
			}
		); 	
	}

	save() {
	    //const result: ContactRequest = Object.assign({}, this.CustomerTypeForm.value);
	    this.parameters = { data: Object.assign({}, this.CustomerTypeForm.value) };
	    this.data.saveCustomerType(this.parameters).subscribe(
	      (res: Response) => {}
	    ); 
	 }

	delete() {
	    //const result: ContactRequest = Object.assign({}, this.CustomerTypeForm.value);
	    this.parameters = { data: Object.assign({}, this.CustomerTypeForm.value) };
	    this.data.deleteCustomerType(this.parameters).subscribe(
	      (res: Response) => {}
	    );
	}

	ngOnInit(): void {
		var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);
    
		this.sub = this.route.params.subscribe(params => {
			this.id = params['id'];
			if (this.id != null) {				
				this.get(this.id);
			}
			if (this.entity != null) {				
				this.get(this.entity);	
			}
		}); 		
	}

}
