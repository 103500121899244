import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { ActivatedRoute } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TopComponent } from '../../../top/top.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-type-edit',
  templateUrl: './contact-type-edit.component.html',
  styleUrls: ['./contact-type-edit.component.css']
})
export class ContactTypeEditComponent implements OnInit {

	private sub : any;

	public entity;		
	public rows = {};
	//public parameters = {};
	
	formData: UntypedFormGroup; 

	/*******************************/

	constructor(
		private data: DataService,
		private route: ActivatedRoute,
		public translate: TranslateService
	) {
		this.formData = this.createFormGroup();
		translate.addLangs(['en', 'si']);
	}

	/*******************************/
	
	createFormGroup() {
		return new UntypedFormGroup({
			entity: new UntypedFormControl(),
			description: new UntypedFormControl()			
		});
	}

	/*******************************/

	get(id) {    
		//this.parameters = { id: id };
		this.data.getContactType({id:id}).subscribe(
			(res: Response) => { this.rows = (<any>res).rows[0]; }
		);
	}

	save() {
		//const result: ContactRequest = Object.assign({}, this.formData.value);
	    //this.parameters = { data: Object.assign({}, this.formData.value) };
	    this.data.saveContactType({data: Object.assign({}, this.formData.value)}).subscribe(
	      (res: Response) => {}
	    );	  
	 }

	delete() {
	    //const result: ContactRequest = Object.assign({}, this.formData.value);
	    //this.parameters = { data: Object.assign({}, this.formData.value) };
	    this.data.deleteContactType({data: Object.assign({}, this.formData.value)}).subscribe(
	      (res: Response) => {}
	    );
	}

	/*******************************/

	ngOnInit(): void {
		var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);
    
		this.sub = this.route.params.subscribe(params => {
			var id = params['id'];
			if (id != null) {				
				this.get(id);
			}
			if (this.entity != null) {				
				this.get(this.entity);	
			}
		}); 		
	}

}