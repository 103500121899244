/* Angular */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap, switchMap, catchError } from 'rxjs/operators';

import { GlobalVariables } from './services/data.globals';
import { HttpClient } from '@angular/common/http'; 

/* services */
import { AuthService } from './services/auth.service';
//import { MembersettingsService } from './services/membersettings.service';

interface JWT_user {
  logged_in: boolean;
  user_name: string;
  name: string;
  user_id: string;
}

@Injectable({
  providedIn: 'root'
})

export class AuthGuard  {
  
  private cached_token;
  public memberAllowance = [];

  constructor(
    public auth_svc: AuthService, 
    public router: Router,
    //public MembersettingsService: MembersettingsService,
    private http: HttpClient
  ){}

  auth_info$: Observable<JWT_user>;
    
  getmemberAllowance(entity): Observable<any> {
    var headers = { Authorization: `Bearer ${this.token}` };
    var parameters = { items : 1, page : 0, order : 't1.entity', orderby : 'asc', search : null, field: 't1.entity', value : entity };
    return this.http.post('https://api.biliz.com/api/members/getMembers.php', JSON.stringify(parameters), { headers }).pipe(
      switchMap((response) => {	
        ////console.log(response);	
        return of(response);
        }),
        catchError((err) => {
          //if (1==1) //console.log('Error > Get Members > ' + err);
          return of(err);
        })
    )
  }


/*
  this.data.getMembers({ items : 1, page : 0, order : 't1.entity', orderby : 'asc', search : null, field: 't1.entity', value : entity }).subscribe(
    (res: Response) => {       
      let user = (<any>res).rows[0];

      if (user == undefined) {
        user = [];
      } else {
        data['admin_rights'] = user['admin_rights'] == 'true' ? true : false;
        data['user_group'] = user['user_group'] == null ? null : (user['user_group']).split(',');
        data['username'] = user['username'];
        data['apps'] = JSON.parse(user['apps']);
      }   
        
      this.memberAllowanceSource.next(data);        
    }
  );

  getMembers(parameters) {
		//AuthService.token();

		var headers = { Authorization: `Bearer ${this.token}` };
		return this.http.post('https://api.biliz.com/api/members/getMembers.php', JSON.stringify(parameters), { headers }).pipe(
			switchMap((response) => {					
				return of(response);
	    	}),
		    catchError((err) => {
		      
		      return of(err);
		    })
		)	
	}
*/



  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | boolean {
      
    this.auth_info$ = this.auth_svc.get_user_details();

    return this.auth_info$.pipe(
      switchMap((data: JWT_user) => {
        if (data.logged_in) {
          // Če je uporabnik prijavljen, izvedite drugi API klic
          //console.log(data.user_id);
          return this.getmemberAllowance(data.user_id).pipe(
            map((allowanceData: any) => {
              //console.log(allowanceData);

              let allowanceDataCheck = false;
              let user = (<any>allowanceData).rows[0];
              if (user == undefined) {
                user = [];
              } else {
                this.memberAllowance['admin_rights'] = user['admin_rights'] == 'true' ? true : false;
                this.memberAllowance['user_group'] = user['user_group'] == null ? null : (user['user_group']).split(',');
                this.memberAllowance['username'] = user['username'];
                this.memberAllowance['apps'] = JSON.parse(user['apps']);
              }   

              //console.log(this.memberAllowance);
              /***********************************/
              /***********************************/
              /***********************************/
              /* START: check for allowance */

              if (this.memberAllowance['admin_rights']!=true) {
                /* if use ris not admin, then check allowance rights per app */
                allowanceDataCheck = false;
                if (route.routeConfig.path!='') {

                  if ((route.routeConfig.path).includes('activities') || (route.routeConfig.path).includes('activity')) {
                    if (this.memberAllowance['apps']?.activities?.view == true)  { allowanceDataCheck = true; }

                  } else if ((route.routeConfig.path).includes('activity-types')) {
                    if (this.memberAllowance['apps']?.activities?.settings == true)  { allowanceDataCheck = true; }

                  } else if ((route.routeConfig.path).includes('document-center')) {
                    if (this.memberAllowance['apps']?.archives?.view == true)  { allowanceDataCheck = true; }

                  } else if ((route.routeConfig.path).includes('calendar')) {
                    allowanceDataCheck = true;

                  } else if ((route.routeConfig.path).includes('document-types')) {
                    if (this.memberAllowance['apps']?.archives?.settings == true) { allowanceDataCheck = true; }

                  } else if ((route.routeConfig.path).includes('customer')) {
                    if (this.memberAllowance['apps']?.customers?.view == true) { allowanceDataCheck = true; }

                  } else if ((route.routeConfig.path).includes('custom_type') || (route.routeConfig.path).includes('custom_fileds') || (route.routeConfig.path).includes('contact_type')) {
                    if (this.memberAllowance['apps']?.customers?.settings == true) { allowanceDataCheck = true; }

                  } else if ((route.routeConfig.path).includes('timetracking')) {
                    if (this.memberAllowance['apps']?.timetracking?.view == true) { allowanceDataCheck = true; }

                  } else if ((route.routeConfig.path).includes('time-settings')) {
                    if (this.memberAllowance['apps']?.timetracking?.settings == true) { allowanceDataCheck = true; }

                  } else if ((route.routeConfig.path).includes('office')) {                    
                    if (this.memberAllowance['apps']?.erp?.view == true) {            
                      if ((route.routeConfig.path).includes('office_erp_document')) {
                        if (this.memberAllowance['apps']?.erp_invoices?.view == true) { allowanceDataCheck = true; }

                      } else if ((route.routeConfig.path).includes('office_erp_document')) { 
                        if (this.memberAllowance['apps']?.erp_credit_note?.view == true) { allowanceDataCheck = true; }

                      } else if ((route.routeConfig.path).includes('office_erp_addon/richquote')) {                        
                        if (this.memberAllowance['apps']?.erp_quotes?.view == true) { allowanceDataCheck = true; }

                      } else if ((route.routeConfig.path).includes('office_erp_addon/richitem')) {
                        if (this.memberAllowance['apps']?.erp?.settings == true) { allowanceDataCheck = true; }
                      }
                    } else { allowanceDataCheck = false; }

                  } else if ((route.routeConfig.path).includes('users')) {            
                    if (this.memberAllowance['apps']?.users?.view == true) { allowanceDataCheck = true; }

                  } else if ((route.routeConfig.path).includes('knowledge')) {
                    if (this.memberAllowance['apps']?.knowledge?.view == true) { allowanceDataCheck = true; }

                  } else if ((route.routeConfig.path).includes('recovery')) {
                    if (this.memberAllowance['apps']?.recovery?.view == true) { allowanceDataCheck = true; }

                  } else {
                    allowanceDataCheck = false;
                  }
                } else {
                  allowanceDataCheck = true; //allow always if path is "/"
                }
              } else {
                allowanceDataCheck = true; //allow if user is admin
              }

              /* END: check for allowance */
              /**********************************/
              /***********************************/
              /***********************************/

              if (allowanceDataCheck) {                
                return true;
              } else {                
                this.router.navigate(['/']);
                return false;
              }
            })
          );
          
        } else {
          //console.log('User is not logged in, redirecting to login page');
          this.router.navigate(['/login']);
          return of(false);
        }
      }),
      catchError((error) => {
        console.error('Error occurred during API call', error);
        this.router.navigate(['/login']);
        return of(false);
      })
    );

    





/*
    
      this.MembersettingsService.getMemberAllowance();
		  this.MembersettingsService.memberAllowance.subscribe(message => {
        this.memberAllowance = message
      });

      console.log('-------------------------------');
      console.log('Router MemberAllowance check...');
      console.log('Route > ' + route.routeConfig.path);
     

      if (this.memberAllowance['admin_rights']!=true) {
        if (route.routeConfig.path!='') {
          if ((route.routeConfig.path).includes('activities') || (route.routeConfig.path).includes('activity')) {
            if (this.memberAllowance['apps']?.activities?.view != true)  {
              console.log('Redirect to Dashboard (Restricted View)...');
              this.router.navigate(['/']);              
            }
          } else if ((route.routeConfig.path).includes('activity-types')) {
            if (this.memberAllowance['apps']?.activities?.settings != true) {            
              console.log('Redirect to Dashboard (Restricted Settings)...'); 
              this.router.navigate(['/']); 
            }
          } else if ((route.routeConfig.path).includes('document-center')) {
            if (this.memberAllowance['apps']?.archives?.view != true) {            
              console.log('Redirect to Dashboard (Restricted View)...'); 
              this.router.navigate(['/']); 
            }
          } else if ((route.routeConfig.path).includes('calendar')) {
            console.log('Calendar granted');
          } else if ((route.routeConfig.path).includes('document-types')) {
            if (this.memberAllowance['apps']?.archives?.settings != true) {            
              console.log('Redirect to Dashboard (Restricted Settings)...'); 
              this.router.navigate(['/']); 
            }
          } else if ((route.routeConfig.path).includes('customer')) {
            if (this.memberAllowance['apps']?.customers?.view != true) {            
              console.log('Redirect to Dashboard (Restricted View)...'); 
              this.router.navigate(['/']); 
            }
          } else if ((route.routeConfig.path).includes('custom_type') || (route.routeConfig.path).includes('custom_fileds') || (route.routeConfig.path).includes('contact_type')) {
            if (this.memberAllowance['apps']?.customers?.settings != true) {            
              console.log('Redirect to Dashboard (Restricted Settings)...'); 
              this.router.navigate(['/']); 
            }
          } else if ((route.routeConfig.path).includes('timetracking')) {
            if (this.memberAllowance['apps']?.timetracking?.view != true) {            
              console.log('Redirect to Dashboard (Restricted View)...'); 
              this.router.navigate(['/']); 
            }
          } else if ((route.routeConfig.path).includes('time-settings')) {
            if (this.memberAllowance['apps']?.timetracking?.settings != true) {            
              console.log('Redirect to Dashboard (Restricted View)...'); 
              this.router.navigate(['/']); 
            }
          } else if ((route.routeConfig.path).includes('office')) {
            console.log('#0', route.routeConfig.path, this.memberAllowance['apps']?.erp?.view);
            if (this.memberAllowance['apps']?.erp?.view == true) {            
              if ((route.routeConfig.path).includes('office_erp_document')) {
                if (this.memberAllowance['apps']?.erp_invoices?.view != true) {            
                  console.log('Redirect to Dashboard (Restricted View)...'); 
                  this.router.navigate(['/']); 
                }
              } else if ((route.routeConfig.path).includes('office_erp_document')) { 
                if (this.memberAllowance['apps']?.erp_credit_note?.view != true) {            
                  console.log('Redirect to Dashboard (Restricted View)...'); 
                  this.router.navigate(['/']); 
                }
              } else if ((route.routeConfig.path).includes('office_erp_addon/richquote')) {
                console.log('#1');
                console.log(this.memberAllowance['apps']?.erp_quotes);
                if (this.memberAllowance['apps']?.erp_quotes?.view != true) {            
                  console.log('Redirect to Dashboard (Restricted View)...'); 
                  this.router.navigate(['/']); 
                }
              } else if ((route.routeConfig.path).includes('office_erp_addon/richitem')) {
                if (this.memberAllowance['apps']?.erp?.settings != true) {            
                  console.log('Redirect to Dashboard (Restricted Settings)...'); 
                  this.router.navigate(['/']); 
                }
              }
            } else {
              console.log('Redirect to Dashboard (Restricted View)...'); 
              this.router.navigate(['/']); 
            }
          } else if ((route.routeConfig.path).includes('users')) {            
            if (this.memberAllowance['apps']?.users?.view != true) {            
              console.log('Redirect to Dashboard (Restricted View)...'); 
              this.router.navigate(['/']); 
            }
          } else if ((route.routeConfig.path).includes('knowledge')) {
            if (this.memberAllowance['apps']?.knowledge?.view != true) {            
              console.log('Redirect to Dashboard (Restricted View)...'); 
              this.router.navigate(['/']); 
            }
          } else if ((route.routeConfig.path).includes('recovery')) {
            if (this.memberAllowance['apps']?.recovery?.view != true) {            
              console.log('Redirect to Dashboard (Restricted View)...'); 
              this.router.navigate(['/']); 
            }
          } else {
            console.log('Redirect to Dashboard (Called)...');  
            this.router.navigate(['/']);
          }
        } else {
          
        }
      }

      this.auth_info$ = this.auth_svc.get_user_details();            
      return this.auth_info$.pipe(
        map(
          (data: JWT_user) => {
            if (data.logged_in) {
              return true;
            } else {       
              this.router.navigate(['/login']);
              return false;
            }
          }
        )
      )
      */
   }

  /******************************/

	get token() {
    //if (this.cached_token) {
    if (this.hasOwnProperty('cached_token')) {
      return this.cached_token;
    }
    
    const storage_token = localStorage.getItem('AUTH_TOKEN');
    
    if (storage_token) {
       this.cached_token = storage_token;
       return storage_token;
    }
  }

}

