<input type="hidden" id="parent_object" value="data.parent_object" />
<input type="hidden" id="parent_object_id" value="data.parent_object_id" />
<input type="hidden" id="object" value="data.object" />
<input type="hidden" id="object_id" value="data.object_id" />

<div class="inner_wrapper"> 
    <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="left">
            <h1>{{'Add Comment'|translate}}</h1>
        </div>
        <div class="right">
            <fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
        </div>
    </div>

	<div class="body">
		<mat-form-field appearance="outline">
			<mat-label>{{'Comment...'|translate}}</mat-label>
			<textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10" [(ngModel)]="data.comment"></textarea>
			<!--<mat-hint>Error</mat-hint>-->
		</mat-form-field>
		
		<!--<fa-icon class="icon_medium microphone" [icon]="['fad', 'microphone']"></fa-icon>-->
		
		<div class="inline">			
			<div class="comment_attachments">
				<ng-container *ngFor='let attachment of attachmentsFullPath; let i = index'>		
					<div class="comment_attachment" (click)="removeAttachment(i)" style="background: url({{attachment}});">
						<fa-icon class="fa-2xl" [icon]="['fad', 'times']"></fa-icon>
					</div>
				</ng-container>
			</div>
		</div>
	</div>

    <div class="modalActions">
        <button class="left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>		
		<button class="right accept" mat-button (click)="save()">{{'Save'|translate}}</button>
		<button class="right action" mat-button>{{'Add File...'|translate}}</button>
    </div>
</div>
 