<div class="inner_wrapper">
	<div class="header">
	  <div class="left">
		<h1><fa-icon [icon]="['fad', 'calendar-day']"></fa-icon> | {{'Select date'|translate}} <ng-container *ngIf="time!=false">/ {{'time'|translate}}</ng-container></h1>
	  </div>
	  <div class="right">
		<fa-icon *ngIf="!expand_view" class="icon_medium" [icon]="['fad', 'up-right-and-down-left-from-center']" (click)="resize();"></fa-icon>
			<fa-icon *ngIf="expand_view" class="icon_medium" [icon]="['fad', 'down-left-and-up-right-to-center']" (click)="resize();"></fa-icon>
	  </div>
	</div>
	<div class="body">
		<div class="left_panel">
			<div class="inner_content">
				<div class="current_month_header">
					<div class="prev_month">
						<fa-icon class="icon_medium" [icon]="['fad', 'chevron-circle-left']" (click)="prevMonth()"></fa-icon>
					</div>
					<div class="current_month">
						{{months[current_month]}}, {{current_year}}
					</div>
					<div class="next_month">
						<fa-icon class="icon_medium" [icon]="['fad', 'chevron-circle-right']" (click)="nextMonth()"></fa-icon>
					</div>
				</div>
	
				<div class="selected_month_header" style="display:none">
					{{selected_month_start}} - {{selected_month_end}}
				</div>
	
				<div class="calendar_day_legend"><div class="day">{{'Mon'|translate}}</div></div>
				<div class="calendar_day_legend"><div class="day">{{'Tue'|translate}}</div></div>
				<div class="calendar_day_legend"><div class="day">{{'Wed'|translate}}</div></div>
				<div class="calendar_day_legend"><div class="day">{{'Thu'|translate}}</div></div>
				<div class="calendar_day_legend"><div class="day">{{'Fri'|translate}}</div></div>
				<div class="calendar_day_legend"><div class="day">{{'Sat'|translate}}</div></div>
				<div class="calendar_day_legend"><div class="day">{{'Sun'|translate}}</div></div>
	
				<div class="calendar_day" *ngFor='let day of calendar?.data'>
					<div class="day" [ngClass]="{'0':'notselected','1':'selectedStart','2':'selected', '3':'selectedEnd', '4':'selectedStartEnd'}[day.selected]" [attr.date]="day.date" (click)="selectDate(day.date)">
						<div>
							{{day.day}}
							<div>
								<span>.</span>
								<span>.</span>
								<span>.</span>
							</div>
						</div>
					</div>
				</div>
	
				<div *ngIf="time!=false" class="time">	
					<div class="time_from">
						<div class="date_time_title">{{'From'|translate}}<ng-container *ngIf="time_start_defined"> {{selected_time_start}} <fa-icon [icon]="['fad', 'times']" (click)="timeStartClear()"></fa-icon></ng-container></div>
						<div class="left">
							<ng-container *ngFor='let hour of hour_start'>
								<div *ngIf="hour.display" class="calendar_hours">
									<div class="hour" [ngClass]="{'0':'notselected','1':'selected'}[hour.selected]">
										<div (click)="selectTimeHourStart(hour.hour)">{{hour.hour}}</div>
									</div>
								</div>
							</ng-container>
						</div>
	
						<div class="right">
							<ng-container *ngFor='let minute of minutes_start'>
								<div *ngIf="minute.display" class="calendar_minutes">
									<div class="minute" [ngClass]="{'0':'notselected','1':'selected'}[minute.selected]">
										<div (click)="selectTimeMinuteStart(minute.minute)">{{minute.minute}}</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
	
					<div class="time_to">
						<div class="date_time_title">{{'To'|translate}}<ng-container *ngIf="time_end_defined"> {{selected_time_end}} <fa-icon [icon]="['fad', 'times']" (click)="timeEndClear()"></fa-icon></ng-container></div>
						<div class="left">
							<ng-container *ngFor='let hour of hour_end'>
								<div *ngIf="hour.display" class="calendar_hours">
									<div class="hour" [ngClass]="{'0':'notselected','1':'selected'}[hour.selected]">
										<div (click)="selectTimeHourEnd(hour.hour)">{{hour.hour}}</div>
									</div>
								</div>
							</ng-container>
						</div>
	
						<div class="right">
							<ng-container *ngFor='let minute of minutes_end'>	
								<div *ngIf="minute.display" class="calendar_minutes">
									<div class="minute" [ngClass]="{'0':'notselected','1':'selected'}[minute.selected]">
										<div (click)="selectTimeMinuteEnd(minute.minute)">{{minute.minute}}</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	
		<div class="right_panel inline w100" [ngClass]="{'active' : expand_view}">
			<div class="button" (click)="setSelectedDate(0)">
				<fa-icon [icon]="['fad', 'calendar-day']"></fa-icon>
				<span>
					{{'Today'|translate}}	
				</span>
			</div>
			<div class="button" (click)="setSelectedDate(1)">
				<fa-icon [icon]="['fad', 'calendar-day']"></fa-icon>
				<span>
					{{'Tomorrow'|translate}}	
				</span>
			</div>
			<div class="button"	(click)="setSelectedDate(7)">
				<fa-icon [icon]="['fad', 'calendar-week']"></fa-icon>
				<span>
					{{'Next week'|translate}}	
				</span>
			</div>
			<div class="button" (click)="setSelectedDate(30)">
				<fa-icon [icon]="['fad', 'calendar']"></fa-icon>
				<span>
					{{'Next month'|translate}}	
				</span>
			</div>
			<div class="button" (click)="setSelectedDate(92)">
				<fa-icon [icon]="['fad', 'calendar']"></fa-icon>
				<span>
					{{'Next 3 months'|translate}}	
				</span>
			</div>
			<div class="button" (click)="setSelectedDate(365)">
				<fa-icon [icon]="['fad', 'calendar']"></fa-icon>
				<span>
					{{'Next year'|translate}}	
				</span>
			</div>
	
	
			<hr />
	
			<div class="button" (click)="setSelectedOffset(7)">
				<fa-icon [icon]="['fad', 'arrows-left-right-to-line']"></fa-icon>
				<span>
					{{'Week'|translate}}	
				</span>
			</div>
	
			<div class="button" (click)="setSelectedOffset(14)">
				<fa-icon [icon]="['fad', 'arrows-left-right-to-line']"></fa-icon>
				<span>
					{{'2 Weeks'|translate}}	
				</span>
			</div>
	
			<div class="button" (click)="setSelectedOffset(30)">
				<fa-icon [icon]="['fad', 'arrows-left-right-to-line']"></fa-icon>
				<span>
					{{'Month'|translate}}	
				</span>
			</div>
	
		</div>
	</div>
	<div class="modalActions">
		<button class="action left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
		<button class="accept right" mat-button mat-dialog-close (click)="confirm()">{{'Confirm'|translate}}</button>
	</div>
  </div>