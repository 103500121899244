/* Angular */
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* Components */
import { EditComponent } from './edit/edit.component'

/* Framework */
import { GridConfiguratorComponent } from '../../framework/grid-configurator/grid-configurator.component';
import { GridPdfComponent } from '../../framework/grid-pdf/grid-pdf.component';

/* Common Functions */
import { CommonFunctions } from '../../services/common.functions';

/* Services */
import { NavigationService } from '../../services/navigation.service';
import { MembersettingsService } from '../../services/membersettings.service';
import { DataService } from '../../services/data.service';

/* Translate.Service */
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.css']
})
export class TypeComponent implements OnInit {

	public component = 'type';
	public items = [];
	public itemsSelected = [];
 	public total_items;
	public page = 0;
	public pages = 1;
	public itemsLimit = 25;
	public order = 'entity';
	public orderby = 'asc';
	public itemsLimit_display;
	public search = ''; 
	public parameters = {};
	public pagination = [5, 10, 25, 50, 100, 250];
	public filter;
	public lastSelected = -1;
	public orderGlobal = null;
	public columnsGlobal = null;	

	public memberAllowance = null;
	public lastFunctionCallAt = Date.now(); 
	public memberSettingsGlobal = null;

	/*************************/

	public topNavigation = [
		{ entity: "type", exposed: false, component: "type", title: "Customer types", subTitle: null, subTitleColor: null, type: "link", action: "", actionData: null, icon: "", iconColor: null, pinnable: false, refreshable: false,
			submenu: [
				{ entity: "type.add", exposed: false, component: "type", title: "Add new ...", subTitle: null, subTitleColor: null, type: "localFunction", action: "edit", actionData: null, icon: "fad,plus-circle", iconColor: null, pinnable: true, submenu: null }
			]
		},
		{ entity: "type.view", exposed: false, component: "type", title: "View", subTitle: null, subTitleColor: null, type: "link", action: "", actionData: null, icon: "", iconColor: null, pinnable: false, refreshable: false,
			submenu: [
				{ entity: "type.filter", exposed: false, component: "type", title: "Filter", subTitle: null, subTitleColor: null, type: "localFunction", action: "setFilter", actionData: null, icon: "fad,filter", iconColor: null, pinnable: true, submenu: null },
				{ entity: "type.configurator", exposed: false, component: "type", title: "Grid preferences", subTitle: null, subTitleColor: null, type: "localFunction", action: "gridPreferences", actionData: null, icon: "fad,grid-4", iconColor: null, pinnable: true, submenu: null }
			]
		},
		{ entity: "type.export", exposed: false, component: "type", title: "Export", subTitle: null, subTitleColor: null, type: "link", action: "", actionData: null, icon: "", iconColor: null, pinnable: false, refreshable: false,
			submenu: [
				{ entity: "type.csv", exposed: false, component: "type", title: "Export to CSV", subTitle: null, subTitleColor: null, type: "localFunction", action: "exportCSV", actionData: null, icon: "fad,file-csv", iconColor: null, pinnable: true, submenu: null },
				{ entity: "type.pdf", exposed: false, component: "type", title: "Export to PDF", subTitle: null, subTitleColor: null, type: "localFunction", action: "exportPDF", actionData: null, icon: "fad,file-pdf", iconColor: null, pinnable: true, submenu: null }
			]
		},
		{ entity: 'function:localSearch', enabled: true }
	];

	/*************************/

	public tableConstructor = [
		{ entity: 'description', sort: 0, enabled: true, item: 'description', title: 'Customer tpe', headerClass: 'col', rowClass: 'col', type: 'number' },
		{ entity: 'counter', sort: 1, enabled: true, item: 'counter', title: '#', headerClass: 'col', rowClass: 'col', type: 'number' },
		{ entity: 'description', sort: 2, enabled: true, item: 'description', title: 'Color indicator', headerClass: 'col', rowClass: 'col w50', type: 'badge' }
	  ]

	/*************************/

	constructor(
		private data: DataService,
		public CommonFunction: CommonFunctions, 
		public dialog: MatDialog,    
		public translate: TranslateService,
		public NavigationService: NavigationService,
		public MembersettingsService: MembersettingsService		
		) {  
		translate.addLangs(['en', 'si']);
	} 
	
	/*************************/

	get updateParametersFunc() { return this.updateParameters.bind(this); }
	updateParameters() {
		/* Get table settings from local storage */    
		this.order = localStorage.getItem(this.component + '_tableOrder') != null ? localStorage.getItem(this.component + '_tableOrder') : this.order;
		this.orderby = localStorage.getItem(this.component + '_tableOrderBy') != null ? localStorage.getItem(this.component + '_tableOrderBy') : this.orderby;
		this.itemsLimit = localStorage.getItem(this.component + '_tableLimit') != null ? parseInt(localStorage.getItem(this.component + '_tableLimit')) : this.itemsLimit;
		this.page = localStorage.getItem(this.component + '_tablePage') != null ? parseInt(localStorage.getItem(this.component + '_tablePage')) : this.page;
		if (this.page < 1) this.page = 1;
		let page = (this.page - 1) * this.itemsLimit;
		this.search = localStorage.getItem(this.component + '_tableSearch') != null ? localStorage.getItem(this.component + '_tableSearch') : this.search;		
		this.parameters = { 'page' : page, 'items' : this.itemsLimit, 'order' : this.order, 'orderby' : this.orderby, search: this.search };		
	}

	onSearch(search) { 
		this.page = 1;
		this.search = search;		
		this.updateParameters();		
		this.refreshData();		
	}

	/*************************/
	/* data manipulation     */

	get refreshDataFunc() { return this.refreshData.bind(this); }
	refreshData() {
		this.data.getCustomerTypes(this.parameters).subscribe(
			(res: Response) => {	
				this.items = (<any>res).rows;				
				this.total_items = (<any>res).total_rows;      				  
        		this.itemsLimit_display = this.itemsLimit > this.total_items ? this.total_items : this.itemsLimit;                  
        		this.pages = Math.ceil(this.total_items / this.itemsLimit);		
				console.log(this.items);				
			}
		);
	}

	setFilter() {
		alert("Set filter");
		/*
		var filterDialog = this.dialog.open(EditComponent, {
			data : this.parameters['filter'], maxWidth: '500px', width: '95vw'
		});

		filterDialog.afterClosed().subscribe(result => {			
			if (result == "") result = null;
			this.updateParameters();
			this.parameters['filter'] = result;				
			this.refreshData();			
			this.filter = this.parameters;
			localStorage.setItem(this.component + '_filter', JSON.stringify(this.filter));
		});
		*/
	}

	get priceRangeFunc() { return this.priceRange.bind(this); }
	priceRange(regular_price, special_price, low_price) {		
		let helperRegularPrice = this.CommonFunction.localizeStringToNumber(regular_price);
		let helperSpecialPrice = this.CommonFunction.localizeStringToNumber(special_price);
		let helperLowPrice = this.CommonFunction.localizeStringToNumber(low_price);	
		return this.CommonFunction.localizeAnyNumberToString(regular_price);
	}

	/*************************************/

	get editFunc() { return this.edit.bind(this); }
	edit(entity) {
		let editDialog = this.dialog.open(EditComponent, {});
		editDialog.componentInstance.entity = entity;
		editDialog.afterClosed().subscribe(result => {		  
			this.refreshData();  
		});
	}

	/*************************************/

	gridPreferences() {		
		var configuratorDialog = this.dialog.open(GridConfiguratorComponent, {
			data : { tableConstructor: this.tableConstructor, component: this.component },
			width: '100%',
        	maxWidth: '450px'
		});

		configuratorDialog.afterClosed().subscribe(result => {			
			if (result['tableConstructor'] != null) {
				this.tableConstructor = result['tableConstructor'];
				this.MembersettingsService.setMemberSettings(this.component + "_gridorder", JSON.stringify(result['order']));
				this.MembersettingsService.setMemberSettings(this.component + "_gridcolumns", JSON.stringify(result['columns']));				
			}			
		});
	}

	/*************************************/
	/* Exports                           */

	exportCSV() {
		let head = [];
		let data = this.itemsSelected.length > 0 ? this.itemsSelected : this.items;		
		for (let i=0; i<(this.tableConstructor).length; i++) { head.push(this.tableConstructor[i]['entity']); }		
		this.CommonFunction.downloadFile(data, head, this.component+".csv");
	}

	exportPDF() {
		let head = [];
		let data = [];
		let settings = {documentTitle: "Customer types", printDate: true, companyData: true, pagination: true, orientation: 'landscape', format: 'A4' };
		let dataHelper = this.itemsSelected.length > 0 ? this.itemsSelected : this.items;
		for (let i=0; i<(this.tableConstructor).length; i++) { head.push(this.tableConstructor[i]['entity']); }		
		let pdfData = {
			head: head,
			data: dataHelper,
			summarizedRow: false,
			settings: settings
		}
		var gridPDFDialog = this.dialog.open(GridPdfComponent, {
			data : pdfData
		});

		gridPDFDialog.afterClosed().subscribe(result => {			
		});
	}

	/*************************************/

	ngOnInit(): void {		
		
		/*******************************************/
		/* START: Common procedures fro table view */
		/*******************************************/
		/* Navigation.Service */
		/* Handle local function called form navigation service */
		this.NavigationService.localFunction.subscribe(callLocalFunction => {
			if (callLocalFunction[0] == this.component && callLocalFunction[1] == "localFunction" && this.lastFunctionCallAt < Date.now()) {
				this[callLocalFunction[2]](callLocalFunction[3]);
				//this.NavigationService.prepareObserverForLocalFunction([]);        
				this.lastFunctionCallAt = Date.now()+500; //prevent calling function too fast by default 500mS between call
			}
		});
		
		/* Handle search called from navigation service */
		this.NavigationService.localSearch.subscribe(search => {
			if (this.lastFunctionCallAt < Date.now()) {
				if (search.length > 0)	this.onSearch(search);
				this.lastFunctionCallAt = Date.now()+100; //prevent calling function too fast by default 100mS between call
			}
		});

		/* Member.Service */
		/* Get and Wait for allowance */
		this.MembersettingsService.getMemberAllowance();
		this.MembersettingsService.memberAllowance.subscribe(message => this.memberAllowance = message);

		this.MembersettingsService.refreshMemberSettings();
		this.MembersettingsService.memberSettingsGlobal.subscribe(message => {
			this.memberSettingsGlobal = message;
			this.orderGlobal = this.memberSettingsGlobal[this.component + '_gridorder'] != undefined ? JSON.parse(this.memberSettingsGlobal[this.component + '_gridorder']) : null;
			this.columnsGlobal = this.memberSettingsGlobal[this.component + '_gridcolumns'] != undefined ? JSON.parse(this.memberSettingsGlobal[this.component + '_gridcolumns']) : null;
			for (let i=0; i<(this.tableConstructor).length; i++) {			
				if (this.orderGlobal != null) this.tableConstructor[i].sort = this.orderGlobal.indexOf(this.tableConstructor[i].entity);
				if (this.columnsGlobal != null) this.tableConstructor[i].enabled = this.columnsGlobal.indexOf(this.tableConstructor[i].entity) > -1 ? false : true;
			}
			this.tableConstructor.sort((a, b) => a.sort - b.sort);						
		});
	
		/* Wait for memberAllowance to be ready with timeout function  */    
		var maxExecutionTime = 5000;
		var executionInterval = 100;
		var navigationInterval = setInterval(() => {      
		  maxExecutionTime = maxExecutionTime - executionInterval;      
		  if (maxExecutionTime < 1) {
			clearInterval(navigationInterval);
			alert(this.component + '.component: Can not retrieve data (memberAllowance) from API. Please refresh application.');
		  }
		  if (this.memberAllowance != null) {
			clearInterval(navigationInterval);
			this.NavigationService.updateTopNavigation(this.topNavigation);
		  }
		}, executionInterval);
				
		/* Finally, get data */
		this.updateParameters();
		this.refreshData();
		/*******************************************/
		/* END: Common procedures fro table view   */
		/*******************************************/
	}

}
