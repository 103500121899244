import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import { GlobalVariables } from '../../services/data.globals';
import { CommonFunctions } from '../../services/common.functions';
import { jsPDF } from "jspdf";
import { TranslateService } from '@ngx-translate/core'; 

@Component({
  selector: 'app-erp-print',
  templateUrl: './erp-print.component.html',
  styleUrls: ['./erp-print.component.css']
})
export class ErpPrintComponent implements OnInit {

  public colPDF = {pos: true, sku: true, description: true, qty: true, unit: true, tax: true, price: true, priceTax: true, discount: true, priceWithDiscount: true, priceTaxWithDiscount: true, total: true, totalWithTax: true};
  public currentDocumemntToPrint = "";

  public entity;
  public company_vat = "";
  public company_organisation = "";
  public company_street = "";
  public company_zip = "";
  public company_city = "";
  public bank_account = "";
  public bank_swift = "";
  public company_logo = "";
  public company_logo_64code = null;
  public logoWidth = null;
  public logoHeight = null;
  
  constructor (
    @Inject(MAT_DIALOG_DATA) public inputData,
    private data: DataService,
    public translate: TranslateService, 
    public dialog: MatDialogRef<ErpPrintComponent>
  ) {
    translate.addLangs(['en', 'si']);
  }

  localDateFormat(date) {
    var datetime = new Date(date);   
    var options = { hour12: false };   
    return (datetime.toLocaleString("sl-SI", options).replace('. ', '.').replace('. ', '.')).split(',')[0];
  }

  PDFFooter(doc, page, lMargin) {        
    var footer_text = this.company_organisation + ' | ' + this.company_street + ' | ' + this.company_zip + ' ' + this.company_city + ' | VAT: ' + this.company_vat + ' | IBAN: ' + this.bank_account;  
    
    doc.setFontSize(8);
    doc.setTextColor('#999999');
    doc.text("???Page #"+page, 200, 290, { align: 'right'});
    
    var datetime = new Date();   
    var options = { hour12: false };       
    doc.text(footer_text, lMargin, 290);  
    return doc;
  } //PDFFooter


  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }


  generatePDF(documents) {

   
    console.log('Generate PDF');

    /* all dimensions in mm */
    /* initial variables and function */
    var leftMargin = 25;
    var lineSpace = 1.3;
    var maxPrintablePageHeight = 265;
    var maxPrintablePageWidth = 180;
    
    /* initial content start coordinates */
    var currentContentHeight = 100;

    /* create PDF document */
    var doc = new jsPDF("p","mm",[210, 297]);
        
    /* set line height factor */
    doc.setLineHeightFactor(1.4);
    
    /* load fonts */
    doc.addFont("./assets/fonts/Roboto-Regular.ttf", "Roboto", "normal");
    doc.addFont("./assets/fonts/Roboto-Bold.ttf", "Roboto-Bold", "normal");

    /* set current font */
    doc.setFont("Roboto");

    /* set current font size */
    doc.setFontSize(9);
    
    /* set font color */
    doc.setTextColor('#000000');
    
    /***************************************************/
    /* content */

    /****************************/
    /* footer */

    /* main document loop */
    for (var idoc=0; idoc < documents.length; idoc++) {
    
      if (idoc > 0) { doc.addPage(); }

      var currentContentHeight = 100;
      var document = documents[idoc];     

      /***************************************************/
      /* set PDF language for each document              */
      //this.translate.use(lang); 
      var defaultLanguage = this.translate.getDefaultLang();
      //console.log(document['lang']);
      if (document['lang'] != 'si' && document['lang'] != 'en' && document['lang'] != 'de') { document['lang'] = 'si'; }
      this.translate.use(document['lang']);
      //console.log(document['lang']);
      
      this.currentDocumemntToPrint = document['document_number_text'];

      document['items_formated'] = JSON.parse(document['items_formated']);
      document['summary_formated'] = JSON.parse(document['summary_formated']);

      var page = idoc + 1;
      this.PDFFooter(doc, page, leftMargin);
      doc.setTextColor('#000000');
      text = this.company_organisation + ' - ' + this.company_street + ' - ' + this.company_zip + ' ' + this.company_city + ' - VAT: ' + this.company_vat + ' - IBAN: ' + this.bank_account;
      
      /* neccessary variables */
      var text, formatedText, formatedTextHeight, currentLeftPrintOffset, percentWidth, containerWidthMM, MaxPrintHeightPerLine;
      
      /***********************************************************************************************************/
      /***********************************************************************************************************/
      /* print erp document address & VAT */   
      
      currentContentHeight = 49.5;
      doc.setFontSize(12);
      
      doc.setLineWidth(0.3);
      doc.line(leftMargin, currentContentHeight-7, leftMargin + 50, currentContentHeight-7);
      doc.setLineWidth(0.1);

      /* draw rectangle with rounded corners*/
      //doc.roundedRect(leftMargin, currentContentHeight, 80, currentContentHeight+10, 5, 5, 'S');
      /***************************/
      text = document['address']; //set text to print
      if (text == null || text == undefined) text = " "; //if is null or undefined, set " "
      /*-------------------------*/
      /* splitTextToSize : force text to bne displayed in certain width */
      formatedText = doc.splitTextToSize(text, 80); //split text into multiple lines in case if text width is higher that acceptible
      formatedTextHeight = doc.getTextDimensions(formatedText).h; //get dimension (h as height) of printed text      
      doc.text(formatedText, leftMargin, currentContentHeight); //print out formted text on x and y coordinates
      currentContentHeight += formatedTextHeight;
          
      currentContentHeight += 0;
      doc.setFontSize(10);
      text = this.translate.instant('Customer VAT number: ') + document['vat']; //set text to print
      /*-------------------------*/
      /* splitTextToSize : force text to bne displayed in certain width */
      formatedText = doc.splitTextToSize(text, 80); //split text into multiple lines in case if text width is higher that acceptible
      formatedTextHeight = doc.getTextDimensions(formatedText).h; //get dimension (h as height) of printed text      
      doc.text(formatedText, leftMargin, currentContentHeight+23.5); //print out formted text on x and y coordinates
      currentContentHeight += formatedTextHeight + lineSpace;





      /***********************************************************************************************************/
      /***********************************************************************************************************/
      /* print erp document dates & doc number */   
      currentContentHeight = 45;
      doc.setFontSize(12);
      var dateTextMargin = leftMargin + 110;
      var dateContentMargin = leftMargin + 150;

      /* add logo */
      var img = new Image();
      var imgMaxWidth = 60;
      var imgMaxHeight = 40;
      var width = 0;
      var height = 0;
      var helper = 0;
      
      /* calculate maximum dimension of logo */
      helper = imgMaxWidth / this.logoWidth; 
      if (helper * this.logoHeight <= imgMaxHeight) {
        width = 60;
        height = helper * this.logoHeight
      } else {
        helper = imgMaxHeight / this.logoHeight;
        width = helper * this.logoWidth;
        height = 40;         
      }
           
      //console.log(width, height);
      //doc.addImage(this.company_logo_64code, "PNG", dateTextMargin, 0, width, height);
      doc.addImage(this.company_logo_64code, "PNG", leftMargin, 0, width, height);
      
      /************************/
      /* company detail right */
      //text = this.company_organisation + ' - ' + this.company_street + ' - ' + this.company_zip + ' ' + this.company_city + ' - VAT: ' + this.company_vat + ' - IBAN: ' + this.bank_account;
      doc.setFont("Roboto-Bold");
      doc.setFontSize(10);
      text = this.company_organisation;
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, 12);
      /***************************/
      doc.setFont("Roboto");
      text = this.company_street;
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, 19);
      /***************************/
      doc.setFont("Roboto");
      text = this.company_zip + ' ' + this.company_city;
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, 24);
      /***************************/
      doc.setFont("Roboto");
      text = this.translate.instant('VAT') + ': ' + this.company_vat;
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, 31);
      /***************************/
      doc.setFont("Roboto");
      text = this.translate.instant('IBAN') + ': ' + this.bank_account;
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, 36);


      /***************************/
      currentContentHeight += 4;
      doc.setFont("Roboto-Bold");
      //text = document['type'];
      text = ( this.translate.instant(document['type']) ).toUpperCase();
     
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, currentContentHeight);    
      /***************************/
      doc.setFont("Roboto-Bold");
      doc.setFontSize(16);
      text = document['document_number_text'];
      if (text == null || text == undefined) text = " ";
      /*-------------------------*/    
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateContentMargin, currentContentHeight);
      currentContentHeight += MaxPrintHeightPerLine + lineSpace;
      
      doc.setDrawColor(0,0,0);  
      currentContentHeight -= 2;
      doc.setLineWidth(0.6);
      doc.line(dateTextMargin, currentContentHeight, leftMargin + maxPrintablePageWidth, currentContentHeight);
      doc.setLineWidth(0.1);
      currentContentHeight += 7;

      /***********************************************************************************************************/
      /***********************************************************************************************************/
      doc.setFontSize(10);

      /* background dates */
      doc.setFillColor(220,220,220);
      doc.rect(dateTextMargin, currentContentHeight-7, 70, 36, 'F');
      doc.setFillColor(255,255,255);

      /***************************/
      doc.setFont("Roboto");
      text = this.translate.instant('Document date');
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, currentContentHeight);    
      /***************************/
      doc.setFont("Roboto-Bold");
      text = this.localDateFormat(document['document_date']);
      if (text == null || text == undefined) text = " ";
      /*-------------------------*/    
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateContentMargin, currentContentHeight);
      currentContentHeight += MaxPrintHeightPerLine + lineSpace;

      /***************************/
      doc.setFont("Roboto");
      text = this.translate.instant('Service date');
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, currentContentHeight);    
      /***************************/
      doc.setFont("Roboto-Bold");
      text = this.localDateFormat(document['document_service']);
      if (text == null || text == undefined) text = " ";
      /*-------------------------*/    
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateContentMargin, currentContentHeight);
      currentContentHeight += MaxPrintHeightPerLine + lineSpace;

      /***************************/
      doc.setFont("Roboto");
      text = this.translate.instant('Payment date');
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, currentContentHeight);    
      /***************************/
      doc.setFont("Roboto-Bold");
      text = this.localDateFormat(document['document_payment']);
      if (text == null || text == undefined) text = " ";
      /*-------------------------*/    
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateContentMargin, currentContentHeight);
      currentContentHeight += MaxPrintHeightPerLine + lineSpace;

      /***************************/
      doc.setFont("Roboto");
      text = this.translate.instant('Place of service');
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, currentContentHeight);    
      /***************************/
      doc.setFont("Roboto-Bold");
      text = document['place'];
      if (text == null || text == undefined) text = " ";
      /*-------------------------*/    
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateContentMargin, currentContentHeight);
      currentContentHeight += MaxPrintHeightPerLine + lineSpace;


      /***************************/
      doc.setFont("Roboto");
      text = this.translate.instant('Payment code');
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      currentContentHeight += 4;
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, currentContentHeight);    
      /***************************/
      doc.setFont("Roboto-Bold");
      text = "00-" + document['document_number_text'].replace('/', '-');
      if (text == null || text == undefined) text = " ";
      /*-------------------------*/    
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateContentMargin, currentContentHeight);
      currentContentHeight += MaxPrintHeightPerLine + lineSpace;

      doc.setFont("Roboto");


      /***********************************************************************************************************/
      /***********************************************************************************************************/
      /* print erp document content items */
        
      var colWidth = {
        pos: 4, 
        sku: 6, 
        description: 22, 
        qty: 5, 
        unit: 5, 
        tax: 4, 
        price: 8,
        priceTax: 8, 
        discount: 4, 
        priceWithDiscount: 8, 
        priceTaxWithDiscount: 8, 
        total: 9, 
        totalWithTax: 9
      };

      var totalVisibleCols = 0;    
      var VisibleCols =  0;
      for (const key in this.colPDF) {
        if (this.colPDF[key] != false) {
          totalVisibleCols += colWidth[key];
          VisibleCols++;
        }
      }
      
      var VisibleColsSpace = 100 - totalVisibleCols;
      var descriptionAddOnWidth = VisibleColsSpace / 2;
      var colsAddOn = (descriptionAddOnWidth*2) / VisibleCols;

      /***********************************************/
      /* Content Table header                        */
      currentContentHeight = 105;
      currentLeftPrintOffset = 0;
      doc.setFontSize(10);

      for (const key in this.colPDF) {  
        if (this.colPDF[key] != false) {    
          /***************************/
          text = this.translate.instant(key);
          percentWidth = colWidth[key]+colsAddOn;
          /*-------------------------*/
          containerWidthMM = (maxPrintablePageWidth-3)/100*percentWidth;
          formatedText = doc.splitTextToSize(text, containerWidthMM);   
          formatedTextHeight = doc.getTextDimensions(formatedText).h;         
          if (key != 'pos' && key != 'sku' && key != 'description') {          
            doc.text(formatedText, leftMargin + currentLeftPrintOffset + (maxPrintablePageWidth/100*percentWidth) + 1.5, currentContentHeight,{align: 'right'});
          } else {
            doc.text(formatedText, leftMargin + currentLeftPrintOffset + 1.5, currentContentHeight);
          }
          MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
          currentLeftPrintOffset += containerWidthMM;
        }
      }
      
      currentContentHeight += MaxPrintHeightPerLine;  
      doc.setDrawColor(0,0,0);  
      doc.setLineWidth(0.3);
      doc.line(leftMargin, currentContentHeight, leftMargin + maxPrintablePageWidth, currentContentHeight);
      doc.setLineWidth(0.1);

      /***********************************************/
      /* Content Table Dynamic Content               */
      currentContentHeight += 4;
      doc.setFontSize(10);

      for (var i = 0; i < document.items_formated.length; i++) { //loop through all lines

        currentLeftPrintOffset = 0;
        MaxPrintHeightPerLine = 0;

        for (const key in this.colPDF) {
          if (this.colPDF[key] != false) {
            /***************************/
            text = document.items_formated[i][key];
            if (text == null || text == undefined) text = " ";
            percentWidth = colWidth[key]+colsAddOn;
            /*-------------------------*/
            containerWidthMM = (maxPrintablePageWidth-3)/100*percentWidth;
            formatedText = doc.splitTextToSize(text, maxPrintablePageWidth/100*percentWidth);
            formatedTextHeight = doc.getTextDimensions(formatedText).h;
            var currency = "";
            if (key == 'tax') { 
              currency = " %"; 
            } else if (key != 'pos' && key != 'sku' && key != 'description' && key != 'unit' && key != 'tax') {
              currency = " €"; 
            }
            if (key != 'pos' && key != 'sku' && key != 'description') {              
              doc.text(formatedText + currency, leftMargin + currentLeftPrintOffset + (maxPrintablePageWidth/100*percentWidth) + 1.5, currentContentHeight + 1,{align: 'right'});
            } else {
              doc.text(formatedText, leftMargin + currentLeftPrintOffset + 1.5, currentContentHeight + 1);
            }
            //console.log(MaxPrintHeightPerLine);
            MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
            currentLeftPrintOffset += containerWidthMM;
          }
        }

        currentContentHeight += MaxPrintHeightPerLine;

        /* Line Background */        
        /*
        if (i % 2 == 0) { 
          console.log(i);
          doc.setFillColor(220,220,220);
          doc.rect(leftMargin, currentContentHeight, maxPrintablePageWidth, formatedTextHeight + lineSpace * 3 - 1, 'F');          
          doc.setFillColor(255,255,255);
        }
        */
        /*-------------------------*/

        if (i == document.items_formated.length - 1) {
          doc.setLineWidth(0.3);
          doc.setDrawColor(0,0,0);
        } else {
          doc.setDrawColor(127,127,127);
        }
        doc.line(leftMargin, currentContentHeight, leftMargin + maxPrintablePageWidth, currentContentHeight);
        //doc.setLineWidth(0.1);
        currentContentHeight += lineSpace * 3;
      }


      /**********************************************************************************************************/
      /**********************************************************************************************************/
      /* rekapitulacija */
      currentContentHeight += lineSpace * 3;
      var currentContentHeight_forTotals = currentContentHeight;
      doc.setFontSize(10);

      /***************************/
      doc.setFont("Roboto");
      text = this.translate.instant('Tax');
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 20);        
      doc.text(formatedText, leftMargin+1.5, currentContentHeight);    

      /***************************/
      doc.setFont("Roboto");
      text = this.translate.instant('Base');
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 25);        
      doc.text(formatedText, leftMargin+35, currentContentHeight, {align: 'right'});

      /***************************/
      doc.setFont("Roboto");
      text = this.translate.instant('Amount');
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 25);        
      doc.text(formatedText, leftMargin+60, currentContentHeight, {align: 'right'});
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
          
      doc.setLineWidth(0.3);
      doc.line(leftMargin, currentContentHeight+2, leftMargin + 60, currentContentHeight+2);
      doc.setLineWidth(0.1);
      currentContentHeight += formatedTextHeight + lineSpace + 1;

      

      var taxDetails = JSON.parse(document['tax']);
      console.log(taxDetails);

      for (const key in taxDetails) {
        if (taxDetails[key]['base'] != 0) {
          /***************************/
          doc.setFont("Roboto");
          text = (taxDetails[key]['index']).toLocaleString('sl-SI', {minimumFractionDigits: 1}) + " %";
          /*-------------------------*/  
          MaxPrintHeightPerLine = 0;  
          formatedText = doc.splitTextToSize(text, 20);        
          doc.text(formatedText, leftMargin+1.5, currentContentHeight);    

          /***************************/
          doc.setFont("Roboto");
          text = (taxDetails[key]['base']).toLocaleString('sl-SI', {minimumFractionDigits: 2}) + " €"
          /*-------------------------*/  
          MaxPrintHeightPerLine = 0;  
          formatedText = doc.splitTextToSize(text, 25);        
          doc.text(formatedText, leftMargin+35, currentContentHeight, {align: 'right'});

          /***************************/
          doc.setFont("Roboto");
          text = (taxDetails[key]['tax']).toLocaleString('sl-SI', {minimumFractionDigits: 2}) + " €";
          /*-------------------------*/  
          MaxPrintHeightPerLine = 0;  
          formatedText = doc.splitTextToSize(text, 25);        
          doc.text(formatedText, leftMargin+60, currentContentHeight, {align: 'right'});
          formatedTextHeight = doc.getTextDimensions(formatedText).h;    
          
          doc.line(leftMargin, currentContentHeight+2, leftMargin + 60, currentContentHeight+2);
          currentContentHeight += formatedTextHeight + lineSpace + 1;
        }
      }

      /***********************************************************************************************************/
      /***********************************************************************************************************/
      /* print comment */

      /***************************/
      currentContentHeight += 15;
      doc.setFontSize(10);
      doc.setFont("Roboto");
      text = document['document_comment'];
      /*-------------------------*/      
      formatedText = doc.splitTextToSize(text, 80);        
      doc.text(formatedText, leftMargin, currentContentHeight);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      
          

      /***********************************************************************************************************/
      /***********************************************************************************************************/
      /* print erp document summary */

      currentContentHeight = currentContentHeight_forTotals;
      doc.setFontSize(10);

      /***************************/
      doc.setFont("Roboto");
      text = this.translate.instant('Total without tax');
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, currentContentHeight);    
      /***************************/
      doc.setFont("Roboto-Bold");
      text = document['summary_formated'][0]['total_without_tax'] + " €";
      if (text == null || text == undefined) text = " ";
      /*-------------------------*/    
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateContentMargin+30-1, currentContentHeight, {align: 'right'});
      currentContentHeight += MaxPrintHeightPerLine + lineSpace;

      /***************************/
      doc.setFont("Roboto");
      text = this.translate.instant('Tax');
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, currentContentHeight);    
      /***************************/
      doc.setFont("Roboto-Bold");    
      text = document['summary_formated'][0]['tax_total'] + " €";
      if (text == null || text == undefined) text = " ";
      /*-------------------------*/    
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateContentMargin+30-1, currentContentHeight, {align: 'right'});
      currentContentHeight += MaxPrintHeightPerLine + lineSpace;

      /***************************/
      doc.setFont("Roboto");
      text = this.translate.instant('Total with tax');
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, currentContentHeight);    
      /***************************/
      doc.setFont("Roboto-Bold");
      text = document['summary_formated'][0]['total_with_tax'] + " €";
      if (text == null || text == undefined) text = " ";
      /*-------------------------*/    
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateContentMargin+30-1, currentContentHeight, {align: 'right'});
      currentContentHeight += MaxPrintHeightPerLine + lineSpace;

      doc.setDrawColor(0,0,0);  
      currentContentHeight -= 2;
      doc.setLineWidth(0.6);
      doc.line(dateTextMargin, currentContentHeight, leftMargin + maxPrintablePageWidth, currentContentHeight);
      doc.setLineWidth(0.3);

      doc.setFillColor(220,220,220);
      doc.rect(dateTextMargin, currentContentHeight, 70, MaxPrintHeightPerLine + lineSpace * 4 + 0.6, 'F');
      doc.setFillColor(255,255,255);
      currentContentHeight += 6;

      doc.setFontSize(12);

      /***************************/    
      doc.setFont("Roboto-Bold");
      text = this.translate.instant('For payment');
      /*-------------------------*/  
      MaxPrintHeightPerLine = 0;  
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateTextMargin+3, currentContentHeight);    
      /***************************/
      doc.setFont("Roboto-Bold");
      text = document['summary_formated'][0]['for_payment'] + " €";
      if (text == null || text == undefined) text = " ";
      /*-------------------------*/    
      formatedText = doc.splitTextToSize(text, 80);
      formatedTextHeight = doc.getTextDimensions(formatedText).h;
      MaxPrintHeightPerLine = MaxPrintHeightPerLine > formatedTextHeight ? MaxPrintHeightPerLine : formatedTextHeight;
      doc.text(formatedText, dateContentMargin+30-1, currentContentHeight, {align: 'right'});
      currentContentHeight += MaxPrintHeightPerLine + lineSpace;

      /***********************************************************************************************************/
      /***********************************************************************************************************/
      /* folding marks */
      doc.setLineWidth(0.1);
      doc.line(0, 90, 6, 90);
      doc.line(0, 197, 6, 197);
      doc.line(210, 90, 206, 90);
      doc.line(210, 197, 206, 197);

      //this.translate.use(defaultLanguage); 
    }

    /***********************************************************************************************************/
    /***********************************************************************************************************/
    /* download document */
    doc.save('erp_document_' + document['document_number_text'].replace('/', '-') + '.pdf');
    this.dialog.close(); 
  }

  
  ngOnInit(): void {
  

    var entity = this.inputData.toString();

    //GlobalVariables.memberSettings = memberSettingsArray;        
    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);
    
    this.data.getSettings({entity : "erp_colPDF"}).subscribe((res: Response) => { 
      this.colPDF = res['rows'][0]['value'] != null && res['rows'][0]['value'] != ''? JSON.parse(res['rows'][0]['value']) : this.colPDF; 
      
      this.data.getSettings({entity : '%'}).subscribe((res: Response) => { 
        
        //console.log(res);
        for(var i=0; i<res['rows'].length; i++) {
          if (res['rows'][i]['entity'] == "company_vat") this.company_vat = res['rows'][i]['value'];
          if (res['rows'][i]['entity'] == "company_organisation") this.company_organisation = res['rows'][i]['value'];
          if (res['rows'][i]['entity'] == "company_street") this.company_street = res['rows'][i]['value'];
          if (res['rows'][i]['entity'] == "company_zip") this.company_zip = res['rows'][i]['value'];
          if (res['rows'][i]['entity'] == "company_city") this.company_city = res['rows'][i]['value'];
          if (res['rows'][i]['entity'] == "bank_account") this.bank_account = res['rows'][i]['value'];
          if (res['rows'][i]['entity'] == "bank_swift") this.bank_swift = res['rows'][i]['value'];
        }
      
        console.log(entity);
        this.data.getErpDocuments({ items : 250, page : 0, order : 't1.entity', orderby : 'desc', search : null, entity: entity }).subscribe(
          async (res: Response) => {        
            console.log(res);
            var document = (<any>res).rows;      
            
            console.log(document[0].items);

            this.data.getStorageID({}).subscribe((res: Response) => {
              var storage = res['storage'];
              entity = "company_logo"; 
              this.company_logo = null;
              this.data.getSettings({entity : entity}).subscribe((res: Response) => {         
                this.company_logo = res['rows'][0]['value']; 
                if (this.company_logo != null) {
                  this.company_logo = "https://api.biliz.com/storage/" + storage + "/" + this.company_logo.charAt(0) + "/" + this.company_logo.charAt(1) + "/" + this.company_logo;	                 
                  if (this.company_logo != '' && this.company_logo != null) {
                    this.data.getBase64DataImage({url:this.company_logo}).subscribe(
                      (res: Response) => {
                        console.log((<any>res)); 
                        this.company_logo_64code = <any>res['base64'];                        
                        var img = new Image();
                        img.src = this.company_logo_64code;                  
                        img.onload = () => {
                          this.logoWidth = img.width;
                          this.logoHeight = img.height;                          
                          console.log(`The image is ${this.logoWidth} pixels wide and ${this.logoHeight} pixels tall.`);
                          this.generatePDF(document);
                        };                        
                      }
                    );
                  } else {
                    this.generatePDF(document);
                  }
                  
                }
              });
            });
          }
        );

      });
    });    
	}

}
