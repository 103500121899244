<div class="widget_wrapper" id="{{id}}" [attr.refresh]="refresh" [attr.parameters]="parameters_json">
	<div class="widget_content">
        <div class="current">{{weather['hourly']?.temperature_2m[hour]}} <span>{{weather['hourly_units']?.temperature_2m}}</span></div>

        <div class="widget_tools">			
			<fa-icon class="icon_medium" [icon]="['fad', 'times']" (click)="remove(id)" style="margin-right: 0px;"></fa-icon>			
		</div>

        <div class="hourly_forecast">
            <div class="hour hour_legend">
                <div class="clock"><fa-icon [icon]="['fad', 'clock']"></fa-icon></div>
                <div class="temperature"><fa-icon [icon]="['fad', 'temperature-three-quarters']"></fa-icon></div>
                <div class="participation"><fa-icon [icon]="['fad', 'raindrops']"></fa-icon></div> 
            </div>
            <ng-container *ngFor="let hour of hours">
                <div class="hour hour_data">                    
                    <div class="clock">{{TwoDigits(hour)}}</div>
                    <div class="temperature">{{Round(weather['hourly']['temperature_2m'][hour])}}</div> 
                    <div class="participation">{{Round(weather['hourly']['precipitation'][hour])}}</div>                     
                </div>
            </ng-container>
        </div>
    </div>
</div>
