/* Angular */
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* Components */
import { ErpDocumentComponent } from '../erp-document/erp-document.component';
import { ErpFilterComponent } from '../erp-documents/erp-filter/erp-filter.component';
import { ErpPrintComponent } from '../../erp/erp-print/erp-print.component';
import { ErpPaymentsComponent } from '../../erp/erp-payments/erp-payments.component';
/* Framework */
import { GridConfiguratorComponent } from '../../framework/grid-configurator/grid-configurator.component';
import { GridPdfComponent } from '../../framework/grid-pdf/grid-pdf.component';

/* Common Functions */
import { CommonFunctions } from '../../services/common.functions';

/* Services */
import { NavigationService } from '../../services/navigation.service';
import { MembersettingsService } from '../../services/membersettings.service';
import { DataService } from '../../services/data.service';

/* Translate.Service */
import { TranslateService } from '@ngx-translate/core';



import { GlobalVariables } from '../../services/data.globals';
import { ActivatedRoute } from "@angular/router";
import { ModalComponent } from '../../modal/modal.component';
import { fontRoboto } from "../../services/fontRoboto";
import jsPDF from 'jspdf';
import 'jspdf-autotable';


@Component({
  selector: 'app-erp-documents',
  templateUrl: './erp-documents.component.html',
  styleUrls: ['./erp-documents.component.css']
})

export class ErpDocumentsComponent implements OnInit {
  
	public erpType = "invoice";
	public component = 'erpdocuments';
	public items = [];
	public itemsSelected = [];
	public total_items;
	public page = 0;
	public pages = 1;
	public itemsLimit = 25;
	public order = 't1.entity';
	public orderby = 'asc';
	public itemsLimit_display;
	public search = ''; 
	public parameters = {};
	public pagination = [5, 10, 25, 50, 100, 250];
	public filter;
	public lastSelected = -1;
	public orderGlobal = null;
	public columnsGlobal = null;	

	public documentService = null;
	public documentDate = null;
	public documentToCopy = null;
	public printOptions = false;
	public document_number = null;

	public memberAllowance = null;
	public lastFunctionCallAt = Date.now(); 
	public memberSettingsGlobal = null;

	/*************************/

	public topNavigation = [
		{ entity: "erpdocuments", exposed: false, component: "erpdocuments", title: this.erpType, subTitle: null, subTitleColor: null, type: "link", action: "", actionData: null, icon: "", iconColor: null, pinnable: false, refreshable: false,
			submenu: [
				{ entity: "erpdocuments.add", exposed: false, component: "erpdocuments", title: "Add new ...", subTitle: null, subTitleColor: null, type: "localFunction", action: "edit", actionData: null, icon: "fad,plus-circle", iconColor: null, pinnable: true, submenu: null },
				{ entity: "erpdocuments.generate", exposed: false, component: "erpdocuments", title: "Auto generate", subTitle: null, subTitleColor: null, type: "localFunction", action: "openPrintWindow", actionData: null, icon: "fad,bolt-auto", iconColor: null, pinnable: true, submenu: null }
			]
		},
		{ entity: "erpdocuments.view", exposed: false, component: "erpdocuments", title: "View", subTitle: null, subTitleColor: null, type: "link", action: "", actionData: null, icon: "", iconColor: null, pinnable: false, refreshable: false,
			submenu: [
				{ entity: "erpdocuments.filter", exposed: false, component: "erpdocuments", title: "Filter", subTitle: null, subTitleColor: null, type: "localFunction", action: "setFilter", actionData: null, icon: "fad,filter", iconColor: null, pinnable: true, submenu: null },
				{ entity: "erpdocuments.configurator", exposed: false, component: "erpdocuments", title: "Grid preferences", subTitle: null, subTitleColor: null, type: "localFunction", action: "gridPreferences", actionData: null, icon: "fad,grid-4", iconColor: null, pinnable: true, submenu: null }
			]
		},
		{ entity: "erpdocuments.export", exposed: false, component: "erpdocuments", title: "Export", subTitle: null, subTitleColor: null, type: "link", action: "", actionData: null, icon: "", iconColor: null, pinnable: false, refreshable: false,
			submenu: [
				{ entity: "erpdocuments.csv", exposed: false, component: "erpdocuments", title: "Export to CSV", subTitle: null, subTitleColor: null, type: "localFunction", action: "exportCSV", actionData: null, icon: "fad,file-csv", iconColor: null, pinnable: true, submenu: null },
				{ entity: "erpdocuments.pdf", exposed: false, component: "erpdocuments", title: "Export to PDF", subTitle: null, subTitleColor: null, type: "localFunction", action: "exportPDF", actionData: null, icon: "fad,file-pdf", iconColor: null, pinnable: true, submenu: null }
			]
		},
		{ entity: 'function:localSearch', enabled: true }
	];

	/*************************/

	public tableConstructor = [
		{ entity: 'entity', sort: 0, enabled: true, item: 'entity', title: 'Entity', headerClass: 'col', rowClass: 'col', type: 'text' },
		{ entity: 'document_number_text', sort: 1, enabled: true, item: 'document_number_text', title: 'Document number', headerClass: 'col', rowClass: 'col', type: 'text' },
		{ entity: 'vat', sort: 2, enabled: true, item: 'vat', title: 'VAT', headerClass: 'col', rowClass: 'col', type: 'text' },
		{ entity: 'customer', sort: 3, enabled: true, item: 'customer', title: 'Customer', headerClass: 'col', rowClass: 'col', type: 'text' },
		{ entity: 'document_service', sort: 4, enabled: true, item: 'document_service', title: 'Document date', headerClass: 'col', rowClass: 'col', type: 'text' },
		{ entity: 'document_payment', sort: 5, enabled: true, item: 'document_payment', title: 'Document payment', headerClass: 'col', rowClass: 'col', type: 'text' },
		{ entity: 'total_with_tax', sort: 6, enabled: true, item: 'total_with_tax', title: 'Total with tax', headerClass: 'col', rowClass: 'col', type: 'text' },

		{ entity: 'sendMail', sort: 7, enabled: true, item: 'sendMail', title: 'Email', headerClass: 'col', rowClass: 'col w50', type: 'boolean' },
		{ entity: 'sendDifferentAddress', sort: 8, enabled: true, item: 'sendDifferentAddress', title: 'Second Address', headerClass: 'col', rowClass: 'col w50', type: 'boolean' },	

		{ entity: 'paid', sort: 9, enabled: true, item: 'paid', title: 'paid', headerClass: 'col', rowClass: 'col', type: 'text' },
		/*{ entity: 'category', sort: 8, enabled: true, item: 'category', title: 'Actions', headerClass: 'col', rowClass: 'col', type: 'text' }*/
	]

	/*************************/

	constructor(
		private data: DataService,
		public dialog: MatDialog,
		public translate: TranslateService,
		private route: ActivatedRoute,
		public cFunction: CommonFunctions,
		public MembersettingsService: MembersettingsService,
		public NavigationService: NavigationService,
		public CommonFunction: CommonFunctions, 
	) {
  		translate.addLangs(['en', 'si']);
 	}

	//@Input() erpType: string;  

	/*************************/

	get updateParametersFunc() { return this.updateParameters.bind(this); }
	updateParameters() {
		/* Get table settings from local storage */    
		this.order = localStorage.getItem(this.component + '_tableOrder') != null ? localStorage.getItem(this.component + '_tableOrder') : this.order;
		this.orderby = localStorage.getItem(this.component + '_tableOrderBy') != null ? localStorage.getItem(this.component + '_tableOrderBy') : this.orderby;
		this.itemsLimit = localStorage.getItem(this.component + '_tableLimit') != null ? parseInt(localStorage.getItem(this.component + '_tableLimit')) : this.itemsLimit;
		this.page = localStorage.getItem(this.component + '_tablePage') != null ? parseInt(localStorage.getItem(this.component + '_tablePage')) : this.page;
		if (this.page < 1) this.page = 0;
		this.search = localStorage.getItem(this.component + '_tableSearch') != null ? localStorage.getItem(this.component + '_tableSearch') : this.search;	
		this.erpType = localStorage.getItem(this.component + '_erpType') != null ? localStorage.getItem(this.component + '_erpType') : this.erpType;
		this.parameters = { 'page' : this.page, 'items' : this.itemsLimit, 'order' : this.order, 'orderby' : this.orderby, search: this.search, 'type' : this.erpType };		
	}

	onSearch(search) { 
		this.page = 0;
		this.search = search;		
		this.updateParameters();		
		this.refreshData();		
	}

	/*************************/
	/* data manipulation     */

	get refreshDataFunc() { return this.refreshData.bind(this); }
	refreshData() {
		console.log("Parameters pushed into API", this.parameters)
		this.data.getErpDocuments(this.parameters).subscribe(
			(res: Response) => {	
				console.log("API response", res)
				this.items = (<any>res).rows;				
				this.total_items = (<any>res).total_rows;      				  
        		this.itemsLimit_display = this.itemsLimit > this.total_items ? this.total_items : this.itemsLimit;                  
        		this.pages = Math.ceil(this.total_items / this.itemsLimit);		

				for (let i=0; i<this.items.length; i++) {
					this.items[i]['sendMail'] = this.items[i]['email'] == null ? false : true;
					this.items[i]['sendDifferentAddress'] = this.items[i]['address2'] == null ? false : true;
				}
			}
		);
	}
	
	gridPreferences() {		
		console.log("GRID PREFERENCES")
		var configuratorDialog = this.dialog.open(GridConfiguratorComponent, {
			data : { tableConstructor: this.tableConstructor, component: this.component },
			width: '100%',
        	maxWidth: '450px'
		});

		configuratorDialog.afterClosed().subscribe(result => {			
			if (result['tableConstructor'] != null) {
				this.tableConstructor = result['tableConstructor'];
				this.MembersettingsService.setMemberSettings(this.component + "_gridorder", JSON.stringify(result['order']));
				this.MembersettingsService.setMemberSettings(this.component + "_gridcolumns", JSON.stringify(result['columns']));				
			}			
		});
	}

	get editFunc() { return this.edit.bind(this); }
	edit(entity) {
		let editDialog = this.dialog.open(ErpDocumentComponent, {});
		editDialog.componentInstance.entity = entity;
		editDialog.componentInstance.type = this.erpType;
		editDialog.afterClosed().subscribe(result => {		  
			this.refreshData();  
		});
	}

	get priceRangeFunc() { return this.priceRange.bind(this); }
	priceRange(regular_price, special_price, low_price) {		
		let helperRegularPrice = this.CommonFunction.localizeStringToNumber(regular_price);
		let helperSpecialPrice = this.CommonFunction.localizeStringToNumber(special_price);
		let helperLowPrice = this.CommonFunction.localizeStringToNumber(low_price);	
		return this.CommonFunction.localizeAnyNumberToString(regular_price);
	}

	setFilter() {
		alert("Set filter")
		/*
		var filterDialog = this.dialog.open(ErpFilterComponent, {
			data : this.parameters['filter']
		});

		filterDialog.afterClosed().subscribe(result => {		  
			console.log(result);
			if (result != '' && result != null) {
				this.updateParameters();
				this.parameters['filter'] = result;
				this.refreshData();
				
				this.filter_values = this.parameters;
				localStorage.setItem('erpDocuments_filter', JSON.stringify(this.filter_values));
			}		  
		}); 
		*/
	}

	paymentEditor(id) {		
		//console.log(this.items[id]);
		//var paymentDetails = [ {date:'2023-12-30',description:'kr neki 1',amount:123.99}, {date:'2023-12-31',description:'kr neki 2',amount:123.99}, {date:'2023-12-29',description:'kr neki 3',amount:123.99}, {date:'2023-12-28',description:'kr neki 4',amount:123.99} ];
		var paymentDetails = this.items[id]['paid_details'] != undefined ? JSON.parse(this.items[id]['paid_details']) : [];
		var data = { entity: this.items[id]['entity'], totalAmount: parseFloat(this.items[id]['total_with_tax']), paymentDetails: paymentDetails };
		//console.log(data);
		var erpPaymentsDialog = this.dialog.open(ErpPaymentsComponent, {
			'width' : '600px',
			data : data
		}); 


		erpPaymentsDialog.afterClosed().subscribe(result => {      
			if (result != "") {
				console.log(result);
				//console.log({data:{'paid':result.totalPaid,'paid_details':JSON.stringify(result.paymentDetails)}});				
				this.data.setErpPayment({data:{'entity':result.entity, 'paid':result.totalPaid,'paid_details':JSON.stringify(result.paymentDetails)}}).subscribe(
					(res: Response) => {
						this.refreshData();
					}
				);				
			}
		});  
	}

	updateMenuItemTitle(itemEntity, newTitle, itemObject) {
		for (let i = 0; i < itemObject.length; i++) {
		  if (itemObject[i]['entity'] === itemEntity) {
			itemObject[i]['title'] = newTitle;
			return true; // Indicate that the title was updated
		  }
		  if (itemObject[i].submenu != null) {
			let updated = this.updateMenuItemTitle(itemEntity, newTitle, itemObject[i].submenu);
			if (updated) return true; // Indicate that the title was updated
		  }
		}
		return false; // Indicate that the entity was not found
	}

	isValidDate(dateString) {
		return !isNaN(Date.parse(dateString));
	}

	duplicateErpDocuments(documentDate, documentService) {
		var erpEntitiesSelected = [];
		var erpEntitiesAll = [];
		var erpEntities = [];
		var j = 0;
		
		if (!this.isValidDate(documentDate)) {
			var content = 'Please select valid date for duplicating documents!';
			var modalDialog = this.dialog.open(ModalComponent, {
				data: { content: content, yes: null , no: null, cancel: 'Close' }
			});
			return false;
		}

		if (!this.isValidDate(documentService)) {
			var content = 'Please select valid service date for duplicating documents!';
			var modalDialog = this.dialog.open(ModalComponent, {
				data: { content: content, yes: null , no: null, cancel: 'Close' }
			});
			return false;
		}

		var document_year = (documentDate.split('-'))[0];
		
		for(var i=0; i<this.items.length; i++) {
			erpEntitiesAll[i] = this.items[i]['entity'];
			if (this.items[i]['selected']) {
				erpEntitiesSelected[j] = this.items[i]['entity'];
				j++;
			}				
		}

		if (j > 0) {
			erpEntities = erpEntitiesSelected;
		} else {
			erpEntities = erpEntitiesAll;
		}	

		this.data.getErpDocuments({ items : 250, page : 0, order : 't1.entity', orderby : 'desc', search : null, entity: erpEntities.toString() }).subscribe(
			async (res: Response) => {        
				this.documentToCopy = <any>res['rows'];
				this.document_number = 0;
				
				var parameters = {year: new Date().getFullYear()};      
				parameters['type'] = this.documentToCopy[0]['type'];

				//console.log(parameters);

				this.data.getErpLastDocumentDate(parameters).subscribe(
					(res: Response) => {

						var last_document_date = <any>res['rows'][0]['last_document_date'];
						
						if (this.cFunction.calculateOffsetInDays(documentDate, last_document_date) > -1) {

							var content = 'You started procedure for duplicate ' + this.documentToCopy.length + ' documents and replacing its documents date with ' + documentDate + ' and service date ' + documentService + '. <br /><br />' + 'Are you sure to continue?' + '<b>' + ' There will be no undo option!' + '</b>';
							var modalDialog = this.dialog.open(ModalComponent, {
								data: { content: content, yes: 'Continue' , no: null, cancel: 'Close' }
							});
					 														
							modalDialog.afterClosed().subscribe(result => {
							
								if (result.yes != undefined) {

									this.data.getErpLastDocumentNumber(parameters).subscribe(
										(res: Response) => {  
											this.document_number = <any>res['rows'][0]['last_document_number'];

											for ( var i=0; i<this.documentToCopy.length; i++ ) {

												this.document_number++;
												var paymentOffset = this.cFunction.calculateOffsetInDays(this.documentToCopy[i]['document_payment'], this.documentToCopy[i]['document_date']);
												
												var paymentDate = new Date(documentDate);
												paymentDate.setDate(paymentDate.getDate() + paymentOffset);
												var paymentDateFormat = paymentDate.toISOString().split('T')[0];
												
												this.documentToCopy[i]['entity'] = null;
												this.documentToCopy[i]['document_number'] = this.document_number;							
												this.documentToCopy[i]['document_date'] = documentDate;
												this.documentToCopy[i]['document_service'] = documentService;
												this.documentToCopy[i]['document_payment'] = paymentDateFormat;
												this.documentToCopy[i]['document_year'] = document_year;

												var document_number_text = '0000' + (this.document_number).toString();
												var document_number_text = document_number_text.substr(document_number_text.length - 4);
												this.documentToCopy[i]['document_number_text'] = document_year + "/" + document_number_text;
												
												var parameters = { data: this.documentToCopy[i] };
												
												/*
												this.data_svc.saveErpDocument(parameters).subscribe(
													(res: Response) => { }
												);
												*/
																			
											}

											this.printOptions = false;

										}

									);

								}

							});	

						} else {

							var content = 'Document date must be the same as ' + last_document_date + ' or later.';
							var modalDialog = this.dialog.open(ModalComponent, {
								data: { content: content, yes: null , no: null, cancel: 'Close' }
							  });				

						}

				});				

			}			
		);

		return true;
	}

	generateSinglePDF() {
		var erpEntitiesSelected = [];
		var erpEntitiesAll = [];
		var erpEntities = [];
		var j = 0;
		
		for(var i=0; i<this.items.length; i++) {
			erpEntitiesAll[i] = this.items[i]['entity'];
			if (this.items[i]['selected']) {
				erpEntitiesSelected[j] = this.items[i]['entity'];
				j++;
			}				
		}

		if (j > 0) {
			erpEntities = erpEntitiesSelected;
		} else {
			erpEntities = erpEntitiesAll;
		}	

		var ErpPrintComponentDialog = this.dialog.open(ErpPrintComponent, {
		  'width' : '450px',      
		  data: erpEntities
		});
		
		ErpPrintComponentDialog.afterClosed().subscribe(result => {});
	}

	openPrintWindow() {
		this.printOptions = true;
	}

	/*************************************/
	/* Exports                           */

	exportCSV() {
		let head = [];
		let data = this.itemsSelected.length > 0 ? this.itemsSelected : this.items;		
		for (let i=0; i<(this.tableConstructor).length; i++) { head.push(this.tableConstructor[i]['entity']); }		
		this.CommonFunction.downloadFile(data, head, this.erpType+".csv");
	}

	exportPDF() {
		let head = [];
		let data = [];
		let settings = {documentTitle: this.erpType, printDate: true, companyData: true, pagination: true, orientation: 'landscape', format: 'A4' };
		let dataHelper = this.itemsSelected.length > 0 ? this.itemsSelected : this.items;
		for (let i=0; i<(this.tableConstructor).length; i++) { head.push(this.tableConstructor[i]['entity']); }		
		let pdfData = {
			head: head,
			data: dataHelper,
			summarizedRow: false,
			settings: settings
		}
		var gridPDFDialog = this.dialog.open(GridPdfComponent, {
			data : pdfData
		});

		gridPDFDialog.afterClosed().subscribe(result => {			
		});
	}

	/*************************/
	ngOnInit(): void {	
	
		/*******************************************/
		/* START: Common procedures fro table view */
		/*******************************************/
		/* Navigation.Service */
		/* Handle local function called form navigation service */
		this.NavigationService.localFunction.subscribe(callLocalFunction => {
			if (callLocalFunction[0] == this.component && callLocalFunction[1] == "localFunction" && this.lastFunctionCallAt < Date.now()) {
				this[callLocalFunction[2]](callLocalFunction[3]);
				this.NavigationService.prepareObserverForLocalFunction([]);        
				this.lastFunctionCallAt = Date.now()+500; //prevent calling function too fast by default 500mS between call
			}
		});
		
		/* Handle search called from navigation service */
		this.NavigationService.localSearch.subscribe(search => {
			if (this.lastFunctionCallAt < Date.now()) {
				if (search.length > 0)	this.onSearch(search);
				this.lastFunctionCallAt = Date.now()+100; //prevent calling function too fast by default 100mS between call
			}
		});

		/* Member.Service */
		/* Get and Wait for allowance */
		this.MembersettingsService.getMemberAllowance();
		this.MembersettingsService.memberAllowance.subscribe(message => this.memberAllowance = message);

		this.MembersettingsService.refreshMemberSettings();
		this.MembersettingsService.memberSettingsGlobal.subscribe(message => {
			this.memberSettingsGlobal = message;
			this.orderGlobal = this.memberSettingsGlobal[this.component + '_gridorder'] != undefined ? JSON.parse(this.memberSettingsGlobal[this.component + '_gridorder']) : null;
			this.columnsGlobal = this.memberSettingsGlobal[this.component + '_gridcolumns'] != undefined ? JSON.parse(this.memberSettingsGlobal[this.component + '_gridcolumns']) : null;
			for (let i=0; i<(this.tableConstructor).length; i++) {			
				if (this.orderGlobal != null) this.tableConstructor[i].sort = this.orderGlobal.indexOf(this.tableConstructor[i].entity);
				if (this.columnsGlobal != null) this.tableConstructor[i].enabled = this.columnsGlobal.indexOf(this.tableConstructor[i].entity) > -1 ? false : true;
			}
			this.tableConstructor.sort((a, b) => a.sort - b.sort);						
		});
	
		/* Wait for memberAllowance to be ready with timeout function  */    
		var maxExecutionTime = 5000;
		var executionInterval = 100;
		var navigationInterval = setInterval(() => {      
		  maxExecutionTime = maxExecutionTime - executionInterval;      
		  if (maxExecutionTime < 1) {
			clearInterval(navigationInterval);
			alert(this.component + '.component: Can not retrieve data (memberAllowance) from API. Please refresh application.');
		  }
		  if (this.memberAllowance != null) {
			clearInterval(navigationInterval);
			this.NavigationService.updateTopNavigation(this.topNavigation);
		  }
		}, executionInterval);
				
		/* Finally, get data */
		this.updateParameters();
		this.refreshData();

		this.route.params.subscribe(params=>{
			this.erpType = params['erpType'];
			this.updateParameters();
			this.refreshData();
			this.updateMenuItemTitle('erpdocuments', this.erpType.charAt(0).toUpperCase() + this.erpType.slice(1), this.topNavigation)
		})

		/*******************************************/
		/* END: Common procedures fro table view   */
		/*******************************************/
	}
}