<div class="table">
    <div class="row header">
        <ng-container *ngFor="let item of tableConstructor">
            <div *ngIf="item['enabled']!=false" class="{{item['headerClass']}} order_{{orderby}}" [ngClass]="{'selected':order==item['item'], 'notSortable':item['notsortable']==true}" (click)="changeOrder(item['item'],item?.['notsortable'])">
                {{item['title']|translate}}
                {{item?.['sortable']}}
                <!--<div class="orderIcon"></div>-->
                <div *ngIf="order==item['item']" class="orderFaIcon">                    
                    <fa-icon *ngIf="orderby=='asc'" [icon]="['far', 'arrow-down-a-z']"></fa-icon>
                    <fa-icon *ngIf="orderby=='desc'" [icon]="['far', 'arrow-down-z-a']"></fa-icon>
                    <fa-icon *ngIf="orderby=='asc_123'" [icon]="['far', 'arrow-down-1-9']"></fa-icon>                    
                    <fa-icon *ngIf="orderby=='asc_last'" style="margin-right:6px;" [icon]="['fas', 'square-2']"></fa-icon>
                    <fa-icon *ngIf="orderby=='asc_last'" [icon]="['far', 'arrow-down-a-z']"></fa-icon>                    
                    <fa-icon *ngIf="orderby=='desc_last'" style="margin-right:6px;" [icon]="['fas', 'square-2']"></fa-icon>
                    <fa-icon *ngIf="orderby=='desc_last'" [icon]="['far', 'arrow-down-z-a']"></fa-icon>
                </div>                
            </div>
        </ng-container>
    </div>
    
    <div *ngFor="let item of items; let i = index" class="row" [ngClass]="{'selected':item['selected']}">
        <ng-container *ngFor="let cell of tableConstructor">
            <ng-container *ngIf="cell['enabled']!=false">
                
                <ng-container *ngIf="cell['type']=='customBadge'">                    
                    <div class="{{cell['rowClass']}}" (click)="preEdit(item['entity'], i, $event)">
                        <div class="tableStatus" [ngStyle]="{'background':cell['badgeColor'][item[cell['item']]], 'color':CommonFunctions.textColorFromBG(cell['badgeColor'][item[cell['item']]])}">
                            {{item[cell['item']]|translate}}
                        </div>                        
                    </div>
                </ng-container>                
                
                <ng-container *ngIf="cell['type']=='badge'">                    
                        <div class="{{cell['rowClass']}}" (click)="preEdit(item['entity'], i, $event)">
                            <div *ngIf="!(cell['typeParameter']).startsWith('#')" class="tableStatus" [ngStyle]="{'background':item[cell['typeParameter']], 'color':CommonFunctions.textColorFromBG(item[cell['typeParameter']])}">
                                {{item[cell['item']]}}
                            </div>
                            <div *ngIf="(cell['typeParameter']).startsWith('#') && item[cell['item']] != '0'" class="tableStatus" [ngStyle]="{'background':cell['typeParameter'], 'color':'#333'}">
                                {{item[cell['item']]}}
                            </div>
                        </div>
                </ng-container>

                <ng-container *ngIf="cell['type']=='priority'">                    
                    <div class="{{cell['rowClass']}}" (click)="preEdit(item['entity'], i, $event)">
                        <div class="tableStatus">
                            <fa-icon *ngIf="item[cell['item']]==0" [icon]="['fas', 'flag']" style="color:var(--status_0)"></fa-icon>
                            <fa-icon *ngIf="item[cell['item']]==1" [icon]="['fas', 'flag']" style="color:var(--status_1)"></fa-icon>
                            <fa-icon *ngIf="item[cell['item']]==2" [icon]="['fas', 'flag']" style="color:var(--status_2)"></fa-icon>
                            <fa-icon *ngIf="item[cell['item']]==3" [icon]="['fas', 'flag']" style="color:var(--status_3)"></fa-icon>                            
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="cell['type']=='color'">                    
                    <div class="{{cell['rowClass']}}" (click)="preEdit(item['entity'], i, $event)">
                        <div class="tableColor" [ngStyle]="{'background':item[cell['typeParameter']]}">
                            &nbsp;                     
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="cell['type']=='assigned_users'">
                    <div class="{{cell['rowClass']}}" (click)="preEdit(item['entity'], i, $event)">
                        <div *ngFor="let assigned_users of item['assigned_users_array']; let i = index" class="user_bubble" [ngStyle]="{'background':item['assigned_users_names_hex_array'][i], color:CommonFunctions.textColorFromBG(item['assigned_users_names_hex_array'][i])}">
                            {{getInitials(item['assigned_users_names_array'][i])}}
							<div class="tooltip">{{item['assigned_users_names_array'][i]}}</div>
                        </div>

                        <!--
                        this.items[i]['assigned_users_array']  = this.items[i]['assigned_users'] != null ? this.items[i]['assigned_users'].split(', ') : [];
                        this.items[i]['assigned_users_names_array'] = this.items[i]['assigned_users_names'] != null ? this.items[i]['assigned_users_names'].split(', ') : [];
                        this.items[i]['assigned_users_names_hex_array'] = this.items[i]['assigned_users_names_hex'] != null ? this.items[i]['assigned_users_names_hex'].split(', ') : [];
                        -->
                    </div>
                </ng-container>
                
                <!--
                <ng-container *ngIf="cell['type']=='text'">                
                    <div class="{{cell['rowClass']}}" (click)="preEdit(item['entity'], i, $event)">
                        {{item[cell['item']]}}
                    </div>
                </ng-container>
                -->

                <ng-container *ngIf="cell['type']=='text'">
                    <div class="{{cell['rowClass']}}" (click)="preEdit(item['entity'], i, $event)" [innerHTML]="item[cell['item']]">                        
                    </div>
                </ng-container>

                <ng-container *ngIf="cell['type']=='html'">
                    <div class="{{cell['rowClass']}}" (click)="preEdit(item['entity'], i, $event)" [innerHTML]="item[cell['item']]">                        
                    </div>
                </ng-container>

                <ng-container *ngIf="cell['type']=='number'">
                    <div class="{{cell['rowClass']}}" (click)="preEdit(item['entity'], i, $event)">
                        {{item[cell['item']]}}
                    </div>
                </ng-container>

                <ng-container *ngIf="cell['type']=='currency'">
                    <div class="{{cell['rowClass']}}" (click)="preEdit(item['entity'], i, $event)">
                        {{CommonFunctions.localizeAnyNumberToString(CommonFunctions.parseStringToFloat(item[cell['item']]))}} €
                    </div>
                </ng-container>

                <ng-container *ngIf="cell['type']=='boolean'">            
                    <div class="{{cell['rowClass']}} icon_yes" (click)="preEdit(item['entity'], i, $event)" *ngIf="item[cell['item']]=='true'||item[cell['item']]=='1'"><span><fa-icon [icon]="['fad', 'check-circle']"></fa-icon></span></div>
                    <div class="{{cell['rowClass']}} icon_no" (click)="preEdit(item['entity'], i, $event)" *ngIf="item[cell['item']]!='true'&&item[cell['item']]!='1'"><span><fa-icon [icon]="['fad', 'times-circle']"></fa-icon></span></div>           
                </ng-container>
            </ng-container>
        </ng-container>        
    </div>
</div>