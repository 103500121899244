<div class="inner_wrapper">
	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="left">
			<h1>{{'Activity type'|translate}}</h1>
		</div>
		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
		</div>
	</div>

	<div class="body">
		<input type="hidden" name="entity" [(ngModel)]="rows['entity']" />
		<div class="main_data" class="w25 left">
			<div class="inline w100 wPadding" style="padding-top:12px">               
				<mat-form-field appearance="outline">
					<mat-label>{{'Activity type description'|translate}}</mat-label>
					<input type="text" matInput name="description" [(ngModel)]="rows['description']">					
				</mat-form-field>
			</div>				
			<div class="inline w100">				
				<input type="hidden" name="hex" [(ngModel)]="rows['hex']" />
				<ngx-colors ngx-colors-trigger [format]="'hex'" [(ngModel)]="rows['hex']"></ngx-colors>
				<div class="colorPickerText">{{'Activity color'|translate}}</div>				
			</div>							
		</div>

		<div class="options" class="w33 left" style="padding-left:24px;margin-left:24px;border-left:1px solid var(--border-color)">
			<div class="line">				
				<div class="line-title">{{'Activity options'|translate}}</div>				
			</div>

			<div class="line">				
				<div class="field">
					<label class="switch">
						<input type="checkbox" name="master" [(ngModel)]="rows['master']">
						<span class="slider round"></span>
					</label>
					<div class="label label-checkbox">{{'Master Activity'|translate}}</div>						
				</div>			
			</div>

			<div class="line">				
				<div class="field">
					<label class="switch">
						<input type="checkbox" name="assign_to" [(ngModel)]="rows['assign_to']">
						<span class="slider round"></span>
					</label>
					<div class="label label-checkbox">{{'Force assignation to person'|translate}}</div>
				</div>			
			</div>

			<div class="line">				
				<div class="field">
					<label class="switch">
						<input type="checkbox" name="comments" [(ngModel)]="rows['comments']">
						<span class="slider round"></span>
					</label>
					<div class="label label-checkbox">{{'Allow Comments'|translate}}</div>
				</div>			
			</div>

			<div class="line">				
				<div class="field">
					<label class="switch">
						<input type="checkbox" name="confirm" [(ngModel)]="rows['confirm']">
						<span class="slider round"></span>
					</label>
					<div class="label label-checkbox">{{'Must be Confirmed'|translate}}</div>						
				</div>			
			</div>

			<div class="line">				
				<div class="field">
					<label class="switch">
						<input type="checkbox" name="value" [(ngModel)]="rows['value']">
						<span class="slider round"></span>
					</label>
					<div class="label label-checkbox">{{'Has Value'|translate}}</div>						
				</div>			
			</div>

			<div class="line">
				<div class="field">
					<label class="switch">
						<input type="checkbox" name="attachment" [(ngModel)]="rows['attachment']">
						<span class="slider round"></span>
					</label>
					<div class="label label-checkbox">{{'Allow Attachments'|translate}}</div>
				</div>			
			</div>

			<div class="line">
				<div class="field">
					<label class="switch">
						<input type="checkbox" name="subactivity" [(ngModel)]="rows['subactivity']">
						<span class="slider round"></span>
					</label>
					<div class="label label-checkbox">{{'as sub Activity only'|translate}}</div>
				</div>			
			</div>
		</div>

		<div class="options" class="w33 left" style="padding-left:24px;margin-left:24px;border-left:1px solid var(--border-color)">
			<div class="line">				
				<div class="line-title">{{'Activity statuses'|translate}}</div>				
			</div>

			<div class="line">				
				<div class="field">
					<label class="switch">
						<input type="checkbox" name="status" [(ngModel)]="rows['status']">
						<span class="slider round"></span>
					</label>
					<div class="label label-checkbox">{{'Use statuses'|translate}}</div>						
				</div>			
			</div>

			<div id="statuses" *ngIf="rows['status']">
				<div class="line">				
					<div class="line-title">{{'Activity statuses'|translate}}</div>	
				</div>
				<ng-container *ngFor="let status of statuses; let rowIndex = index">
					<div class="line">				
						<div class="field">
							<div class="input"><input class="status_id" type="text" style="display: none" value="{{status.id}}"/></div>
							<div class="input"><input type="text" class="status_text" value="{{status.label}}"/></div>								
							<div class="input" style="width:auto">
								<input type="hidden" name="hex" class="status_color" [(ngModel)]="status.hex" />
								<ngx-colors ngx-colors-trigger [format]="'hex'" [(ngModel)]="status.hex"></ngx-colors>
							</div>
							<div class="icon" style="padding-top: 10px;padding-left: 8px;"><fa-icon [icon]="['fad', 'minus-circle']" class="big hover" (click)="removeStatus(rowIndex)"></fa-icon></div>								
						</div>			
					</div>
				</ng-container>

				<div class="line hover" style="padding-top:14px;" (click)="addStatus()">
					<div class="field" style="width: auto;">
						<fa-icon [icon]="['fad', 'plus-circle']" class="big"></fa-icon>							
					</div>			
					<div style="float:left; padding:3px 12px">
						{{'Add new status'|translate}}
					</div>
				</div>
			</div>
		</div>

		<div class="hr"></div>
	
		<div class="assigns w45" style="float:left;padding-left: 6px;">
			<div class="line" style="padding-bottom: 16px">				
				<div class="line-title" style="width:auto">{{'Groups access'|translate}}</div>
				<div style="width:150px;float:right;margin-top:0px;">
					<input type="text" name="filterGroups" [(ngModel)]="filterGroups" placeholder="{{'Filter groups...'|translate}}">
				</div>		
			</div>

			<div class="line">
				<div class="access_title">{{'Member group'|translate}}</div>
				<div class="access_view">{{'Allow View'|translate}}</div>
				<div class="access_create">{{'Allow Create'|translate}}</div>
				<div class="access_edit">{{'Allow Edit'|translate}}</div>
			</div>			

			<ng-container *ngFor="let member_group of member_groups">					
				<div *ngIf="filterGroups=='' || ((member_group.member_group).toUpperCase()).includes(filterGroups.toUpperCase())" class="line access_line hover">
					<div class="access_title">
						<span style="background:{{member_group.hex}}"></span>{{member_group.member_group}}
					</div>
					<div class="access_view">
						<!--<input class="content_access" allow="view" [attr.type_entity]="member_group.entity" accesstype="group" type="checkbox" />-->
						<label class="switch">
							<input type="checkbox" allow="view" [attr.type_entity]="member_group.entity" accesstype="group">
							<span class="slider round"></span>
						</label>
					</div>
					<div class="access_create">
						<!--<input class="content_access" allow="create" [attr.type_entity]="member_group.entity" accesstype="group" type="checkbox" />-->
						<label class="switch">
							<input type="checkbox" allow="create" [attr.type_entity]="member_group.entity" accesstype="group">
							<span class="slider round"></span>
						</label>
					</div>
					<div class="access_edit">
						<!--<input class="content_access" allow="edit" [attr.type_entity]="member_group.entity" accesstype="group" type="checkbox" />-->
						<label class="switch">
							<input type="checkbox" allow="edit" [attr.type_entity]="member_group.entity" accesstype="group">
							<span class="slider round"></span>
						</label>
					</div>
				</div>
			</ng-container>
		</div>

		<div class="assigns w50" style="float:left;padding-left:24px;margin-left:38px;border-left:1px solid var(--border-color)">
			<div class="line">
				<div class="line-title">
					{{'Members Access'|translate}}
					<div style="width:150px;float:right;margin-top:-10px;">
						<input type="text" name="filterMembers" [(ngModel)]="filterMembers" placeholder="{{'Filter members...'|translate}}">
					</div>
				</div>
			</div>

			<div class="line">
				<div class="access_title">{{'Member'|translate}}</div>
				<div class="access_view">{{'Allow View'|translate}}</div>
				<div class="access_create">{{'Allow Create'|translate}}</div>
				<div class="access_edit">{{'Allow Edit'|translate}}</div>
			</div>

			<ng-container *ngFor="let member of members">
				<div *ngIf="filterMembers=='' || ((member.username).toUpperCase()).includes(filterMembers.toUpperCase())" class="line access_line hover">
					<div class="access_title">
						{{member.username}}
					</div>
					<div class="access_view">
						<!--<input class="content_access" allow="view" [attr.type_entity]="member.entity" accesstype="user" type="checkbox" />-->
						<label class="switch">
							<input type="checkbox" allow="view" [attr.type_entity]="member.entity" accesstype="user">
							<span class="slider round"></span>
						</label>
					</div>
					<div class="access_create">
						<!--<input class="content_access" allow="create" [attr.type_entity]="member.entity" accesstype="user" type="checkbox" />-->
						<label class="switch">
							<input type="checkbox" allow="create" [attr.type_entity]="member.entity" accesstype="user">
							<span class="slider round"></span>
						</label>
					</div>
					<div class="access_edit">
						<!--<input class="content_access" allow="edit" [attr.type_entity]="member.entity" accesstype="user" type="checkbox" />-->
						<label class="switch">
							<input type="checkbox" allow="edit" [attr.type_entity]="member.entity" accesstype="user">
							<span class="slider round"></span>
						</label>
					</div>
				</div>
			</ng-container>
		</div>
	</div>

	<div class="modalActions" mat-dialog-actions>
		<button class="accept right" type="submit" (click)="save()">{{'Save'|translate}}</button>
		<button class="action left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
		<button class="warrning right" (click)="delete()">{{'Delete'|translate}}</button>
	</div>

	
	<!--
	<div id="colorPicker">
		<ng-container *ngFor="let color of colors">
			<div class="colorPickerChild" [attr.hex]="color" style="background:{{color}}"></div>		
		</ng-container>
	</div>
	-->
</div>