<ng-container *ngFor="let item of items; let i = index">
    <div class="item_last" (click)="navigate('popup', item['type'], null, item['entity']);removeItem(i);">
        <div class="category">
            <div class="small" [ngStyle]="{'background':item.message_type_hex,'color':commonFunctions.textColorFromBG(item.message_type_hex)}">{{item.message_type_name}}</div>
            <div class="when">{{commonFunctions.timeDifference(now, item.updated, null, false)}}</div>
        </div>   
        <div class="title">
            {{item.title_raw}}
        </div>
        <div class="change">
            {{item.last_change}}
        </div>
        <div class="edited">
            {{commonFunctions.localDateFormat(item.updated)}}<br />
            {{item.editorname}}
            <span class="dismiss" (click)="$event.stopPropagation(); $event.preventDefault(); markAsRead(item['entity']); removeItem(i)">{{'Mark as Read'|translate}}</span>        
        </div>
    </div>
</ng-container>

<div *ngIf="(items || []).length == 0" class="noResoults">
    {{'Currently, there are no new unseen activities in the last 7 days.'|translate}}
</div>