import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { GlobalVariables } from '../../services/data.globals';
import { ActivatedRoute } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-timetracking-settings-edit',
  templateUrl: './timetracking-settings-edit.component.html',
  styleUrls: ['./timetracking-settings-edit.component.css']
})
export class TimetrackingSettingsEditComponent implements OnInit {

	public entity;
  
	formData: UntypedFormGroup; 

	/*******************************/

	constructor(
		private data: DataService,
		private route: ActivatedRoute,
		public translate: TranslateService
	) {
		this.formData = this.createFormGroup();
		translate.addLangs(['en', 'si']);
	}

	/*******************************/
	
	createFormGroup() {
		return new UntypedFormGroup({
			entity: new UntypedFormControl(),
			description: new UntypedFormControl(),
			contract_hours: new UntypedFormControl()			
		});
	}

	/*******************************/

	load(entity) {
    this.data.getTimeTrackingCategories({'entity': entity}).subscribe(
			(res: Response) => {				
        //console.log((<any>res).rows[0]);
				this.formData['entity'] = (<any>res).rows[0]['entity'];
        this.formData['description'] = (<any>res).rows[0]['description'];
        this.formData['contract_hours'] = (<any>res).rows[0]['contract_hours'];
        //this.formData['contract_hours'] = (<any>res).rows[0]['contract_hours'] == 1 ? true : false;
        console.log(this.formData);
			}
		);	
	}
	
	save() {
    var parameters = {data: Object.assign({}, this.formData.value)};
    console.log(parameters);
    /*
    this.data.saveContact({data: Object.assign({}, this.formData.value)}).subscribe(
      (res: Response) => {}
    );
    */
	}

	delete() {
    /* 
    this.data.deleteContact({data: Object.assign({}, this.formData.value)}).subscribe(
      (res: Response) => {}
    );
    */
	}

	/*******************************/

	ngOnInit(): void {
		var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);
    this.load(this.entity);
	}

}