import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { ActivatedRoute } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TopComponent } from '../../../top/top.component';
import { TranslateService } from '@ngx-translate/core'; 

@Component({
  selector: 'app-custom-fields-edit',
  templateUrl: './custom-fields-edit.component.html',
  styleUrls: ['./custom-fields-edit.component.css']
})
export class CustomFieldsEditComponent implements OnInit {

  private sub : any;

	public entity;		
	public rows = {};
	
	formData: UntypedFormGroup; 

	/*******************************/

	constructor(
		private data: DataService,
		private route: ActivatedRoute,
		public translate: TranslateService
	) {
		this.formData = this.createFormGroup();
		translate.addLangs(['en', 'si']);
	}

	/*******************************/
	
	createFormGroup() {
		return new UntypedFormGroup({
			entity: new UntypedFormControl(),
			id_addon: new UntypedFormControl(),      
			description: new UntypedFormControl(),
			field_type: new UntypedFormControl(),
			field_default: new UntypedFormControl(),
			position: new UntypedFormControl()
		});
	}

	/*******************************/

	get(id) {
		this.data.getCustomField({id:id}).subscribe(
			(res: Response) => { this.rows = (<any>res).rows[0]; }
		);
	}

	save() {
		this.data.saveCustomField({data: Object.assign({}, this.formData.value)}).subscribe(
			(res: Response) => {
				
			}
		);	  
	}

	delete() {
		this.data.deleteCustomField({data: Object.assign({}, this.formData.value)}).subscribe(
		(res: Response) => {}
		);
	}

	/*******************************/

	ngOnInit(): void {
		var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);
    
		this.sub = this.route.params.subscribe(params => {
			var id = params['id'];
			if (id != null) {				
				this.get(id);
			}
			if (this.entity != null) {				
				this.get(this.entity);	
			}
		}); 		
	}

}