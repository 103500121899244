<div class="inner_wrapper">
    <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="left">
            <h1>{{'Grid preferences'|translate}}</h1>
        </div>
        <div class="right">
            <fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
        </div>
    </div>

    
    <div class="body">
        <div cdkDropList (cdkDropListDropped)="drop($event)" class="items">
            <ng-container *ngFor="let item of injected_data['tableConstructor']; let i = index">
                <div class="fileritem inline w100 field right wPadding toggle"> 
                    <fa-icon cdkDrag cdkDragRootElement=".fileritem" cdkDragHandle class="icon_move" [icon]="['fad', 'grip-lines']"></fa-icon>
                    <div class="label label-checkbox">{{item['title']|translate}}</div>
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="injected_data['tableConstructor'][i]['enabled']">
                        <span class="slider round"></span>
                    </label>             
                </div>
            </ng-container>
        </div>
    </div>

    <div class="modalActions" style="text-align: center;">   
        <button class="action right" (click)="confirm();">{{'Close'|translate}}</button>
    </div>
</div>