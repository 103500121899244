<div class="inner_wrapper"  [ngClass]="{'approved' : extra == true}">
	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="left">
			<h1>{{'Time tracking'|translate}}</h1>
		</div>
		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
		</div>
	</div>

	<div class="body" style="padding-bottom:0px;">	
	
		<input type="hidden" name="entity" [(ngModel)]="entity" />
		<div class="main_data">
			<span class="blockTitle" style="padding-top: 0px;">{{'About tracking'|translate}}</span>

			<div class="inline w100 wPadding0">
				<mat-form-field appearance="outline">
					<mat-label>{{'Attached to user'|translate}}</mat-label>
					<input [(ngModel)]="user_id" type="hidden" />
					<input type="text" matInput (click)="selectUser()" [(ngModel)]="user" readonly />
					<div class="iconTooltipWrapperInput">
						<fa-icon class="right" [icon]="['fad', 'user']" (click)="selectUser()"></fa-icon>					
						<div class="tooltip">{{'Choose user'|translate}}</div>
					</div>
					<!--<mat-hint>Error</mat-hint>-->
				</mat-form-field>
			</div>
				 
			<div class="inline w100 wPadding0">
				<mat-form-field appearance="outline">
					<mat-label>{{'Attached to activity'|translate}}</mat-label>
					<input [(ngModel)]="activity_id" type="hidden" />
					<input type="text" matInput (click)="selectActivity()" [(ngModel)]="activity" readonly />
					<div class="iconTooltipWrapperInput">
						<fa-icon class="right" [icon]="['fad', 'list']" (click)="selectActivity()"></fa-icon>
						<div class="tooltip">{{'Choose activity'|translate}}</div>
					</div>					
					<!--<mat-hint>Error</mat-hint>-->
				</mat-form-field>
			</div>

			<div class="inline w100 wPadding0">
				<mat-form-field appearance="outline">
					<mat-label>{{'Attached to customer'|translate}}</mat-label>
					<input [(ngModel)]="customer_id" type="hidden" />
					<input type="text" matInput (click)="selectCustomer()" [(ngModel)]="customer" readonly />
					<div class="iconTooltipWrapperInput">
						<fa-icon class="right" [icon]="['fad', 'users']" (click)="selectCustomer()"></fa-icon>
						<div class="tooltip">{{'Choose customer'|translate}}</div>
					</div>
					<!--<mat-hint>Error</mat-hint>-->
				</mat-form-field>
			</div>

			<ng-container *ngIf="time_tracking_stat['defaults']!=undefined">
				<ng-container *ngIf="this.time_tracking_stat['defaults'][0]['hoursMonth']!=undefined">
					<div class="line line_statistics">	
						<div class="label">
							<div class="w100 blockTitle">Customer statistics</div>
							<div class="wMonth left">
								<span class="title">{{priorMonth}}<br/><span class="legend">[hours]</span></span><br />
								<div class="pie animate no-round" style="--p:{{time_tracking_stat['percentMonthPrior_Pie']}};--c:{{time_tracking_stat['percentMonthPrior_PieColor']}};--w:75px;--b:5px">{{time_tracking_stat['percentMonthPrior']}}%<br /><span>{{time_tracking_stat['rows_prev_month'][0]['minutes']}}/{{time_tracking_stat['defaults']['hoursMonth']}}</span></div>
							</div>
							<div class="wMonth left">
								<span class="title">{{month}}<br/><span class="legend">[hours]</span></span><br />
								<div class="pie animate no-round" style="--p:{{time_tracking_stat['percentMonth_Pie']}};--c:{{time_tracking_stat['percentMonth_PieColor']}};--w:75px;--b:5px">{{time_tracking_stat['percentMonth']}}%<br /><span>{{time_tracking_stat['rows_current_month'][0]['minutes']}}/{{time_tracking_stat['defaults']['hoursMonth']}}</span></div>
							</div>
							<div class="wYear left">
								<span class="title">{{year}}<br/><span class="legend">[hours]</span></span><br/>
								<div class="pie animate no-round" style="--p:{{time_tracking_stat['percentYear_Pie']}};--c:{{time_tracking_stat['percentYear_PieColor']}};--w:75px;--b:5px">{{time_tracking_stat['percentYear']}}%<br /><span>{{time_tracking_stat['rows_current_year'][0]['minutes']}}/{{time_tracking_stat['defaults']['hoursYear']}}</span></div>
							</div>
							<div class="wComment left">
								<div class="w100 blockTitle" style="padding-left: 0px;">Customer statistics</div>
								{{time_tracking_stat['defaults'][0]['timeTrackingComment']}}
							</div>
						</div>
					</div>
				</ng-container>
			</ng-container>

			<div class="inline w100 wPadding0">
				<mat-form-field appearance="outline">
					<mat-label>{{'Comment' | translate}}</mat-label>
					<textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" name="comment" [(ngModel)]="comment"></textarea>
					<!--<mat-hint>Error</mat-hint>-->
				</mat-form-field>
			</div>
		</div>

		<div class="time_data">
			<span class="blockTitle" style="padding-top:0px;">{{'Time tracking'|translate}}</span>

			<div class="inline w36 wPadding0">
				<mat-form-field appearance="outline">
					<mat-label>{{ 'Date' | translate }}</mat-label>
					<input matInput name="date" [(ngModel)]="date" (click)="assignDateTime(null, start_full, end_full, null, start_full_raw, end_full_raw)" readonly />					
					<div class="iconTooltipWrapperInput">
						<fa-icon class="right" [icon]="['fad', 'timer']" (click)="assignDateTime(null, start_full, end_full, null, start_full_raw, end_full_raw)"></fa-icon>
						<div class="tooltip">{{'Choose date/range'|translate}}</div>
					</div>
				</mat-form-field>
			</div>

			<div class="inline w2 wPadding0">&nbsp;</div>

			<div class="inline w30 wPadding0">
				<mat-form-field appearance="outline">
					<mat-label>{{'Start'|translate}}</mat-label>
					<input matInput name="start" [(ngModel)]="start" (click)="assignDateTime(null, start_full, end_full, null, start_full_raw, end_full_raw)" readonly />					
					<div class="iconTooltipWrapperInput">
						<fa-icon class="right" [icon]="['fad', 'right-from-line']" (click)="setStart(0)"></fa-icon>
						<div class="tooltip">{{'Start now'|translate}}</div>
					</div>
				</mat-form-field>
			</div>

			<div class="inline w2 wPadding0">&nbsp;</div>

			<div class="inline w30 wPadding0">
				<mat-form-field appearance="outline">
					<mat-label>{{'Stop'|translate}}</mat-label>
					<input matInput name="end" [(ngModel)]="end" (click)="assignDateTime(null, start_full, end_full, null, start_full_raw, end_full_raw)" readonly />					
					<div class="iconTooltipWrapperInput">
						<fa-icon class="right" [icon]="['fad', 'right-to-line']" (click)="setEnd(0)"></fa-icon>
						<div class="tooltip">{{'Stop now'|translate}}</div>
					</div>
				</mat-form-field>
			</div>

			<div class="inline w100 wPadding0"></div>

			<div class="inline w100 wPadding0 timerControls">
				<mat-form-field appearance="outline">
					<mat-label>{{'Pause tracking'|translate}}</mat-label>
					<input matInput name="pause" [(ngModel)]="pause" readonly />
					<div class="iconTooltipWrapperInput" style="right: 72px;">
						<fa-icon class="right" [icon]="['fad', 'ellipsis']" (click)="manualPauseInput=!manualPauseInput"></fa-icon>
						<div class="tooltip">{{'Show/Hide Time presets'|translate}}</div>
					</div>
					<div class="iconTooltipWrapperInput" style="right: 36px;">
						<fa-icon class="right" [icon]="['fad', 'times']" (click)="clearPause()"></fa-icon>
						<div class="tooltip">{{'Clear pause'|translate}}</div>
					</div>
					<div class="iconTooltipWrapperInput">
						<fa-icon class="right" [icon]="['fad', 'play']" (click)="startPause()"></fa-icon>
						<div class="tooltip">{{'Start pause'|translate}}</div>
					</div>
				</mat-form-field>

				<ng-container *ngIf="manualPauseInput">
					<button type="button" style="padding: 6px 6px; margin:0 6px 24px 0" (click)="setPause(5);">+5 min</button>
					<button type="button" style="padding: 6px 6px; margin:0 6px 24px 0" (click)="setPause(10);">+10 min</button>
					<button type="button" style="padding: 6px 6px; margin:0 6px 24px 0" (click)="setPause(15);">+15 min</button>												
					<button type="button" style="padding: 6px 6px; margin:0 6px 24px 0" (click)="setPause(30);">+30 min</button>
					<button type="button" style="padding: 6px 6px; margin:0 6px 24px 0" (click)="setPause(60);">+60 min</button>
					<button type="button" style="padding: 6px 6px; margin:0 6px 24px 0" (click)="setPause(120);">+120 min</button>
				</ng-container>
			</div>

			<div class="categories">
				<div class="line" style="margin-top: 16px;">
					<span class="blockTitle" style="padding-bottom:24px;">{{'Time tracking clasification'|translate}}</span>
					<br />
					<div class="line">
						<div *ngFor="let category of categories; let rowIndex = index" class="field"> 
							<label class="switch">							
								<input type="checkbox" (click)="switch(rowIndex)" [checked]="categories[rowIndex]['checked']">
								<span class="slider round"></span>
							</label>
							<div class="label label-checkbox">{{category['description']}}</div>
						</div>				
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="desktop modalActions">
		<button *ngIf="extra" class="left" (click)="approveCancel()">{{'Cancel approval'|translate}}</button>
		<button class="left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>		
		<button *ngIf="entity!=''" class="left" (click)="delete()">{{'Delete'|translate}}</button>		

		<button *ngIf="start&&user" class="right accept" (click)="save();">{{'Save'|translate}}</button>	
		<button *ngIf="entity!=''&&start&&end&&end!='-'" class="right" (click)="copyTimeTracking();">{{'Duplicate'|translate}}</button>
		<!--<button *ngIf="!extra" class="accept right" (click)="approve()">{{'Approve'|translate}}</button>-->		
	</div>

	<div class="mobile footer">
		<!--fa-icon class="big" (click)="delete()" mat-button mat-dialog-close [icon]="['fad', 'trash']"></fa-icon> 
		<fa-icon class="big" *ngIf="!extra" (click)="approve()" [icon]="['fad', 'circle-check']"></fa-icon> 
		
		<button *ngIf="extra" class="approveCancel" (click)="approveCancel()">{{'Cancel approval'|translate}}</button>
		
		<fa-icon class="big" mat-button mat-dialog-close [icon]="['fad', 'xmark']"></fa-icon> 
		<fa-icon class="big" (click)="save();" type="submit" mat-button mat-dialog-close [icon]="['fad', 'floppy-disk']"></fa-icon> 
		<fa-icon class="big" (click)="copyTimeTracking();" [icon]="['fad', 'copy']"></fa-icon--> 

		<button class="submit" (click)="save();" type="submit" mat-button mat-dialog-close>{{'Save'|translate}}</button>
		<button class="more" (click)="moreOptions=!moreOptions;">{{'More options'|translate}}</button>
	</div>

	<div *ngIf="onPause" class="pauseShow">
		<div class="pauseShow_inner">
			<div class="title">
				<fa-icon class="icon_snooze" [icon]="['fad', 'snooze']"></fa-icon> 
				<h1>{{'Pause'|translate}}</h1>
			</div>
			{{onPauseTimer}}
			<button type="button" (click)="endPause();">{{'End pause'|translate}}</button><br />
		</div>
	</div>

	<div *ngIf="moreOptions" class="moreOptions" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="inner_wrapper">
			<div class="right">
				<fa-icon class="icon_close icon_medium" [icon]="['fad', 'times']" (click)="moreOptions=!moreOptions;"></fa-icon>
			</div>
			
			<span (click)="copyTimeTracking();">
				<fa-icon [icon]="['fad', 'copy']"></fa-icon>{{'Duplicate'|translate}}	
			</span>

			<span (click)="delete();moreOptions=false;" mat-button mat-dialog-close>
				<fa-icon [icon]="['fad', 'trash']"></fa-icon>{{'Delete'|translate}}	
			</span>

			<!--
			<span *ngIf="!extra" (click)="approve()">
				<fa-icon [icon]="['fad', 'check']"></fa-icon>{{'Approve'|translate}}
			</span>

			<span *ngIf="extra" (click)="approveCancel()">
				<fa-icon [icon]="['fad', 'xmark']"></fa-icon>{{'Cancel approval'|translate}}
			</span>
			-->

			<span mat-button mat-dialog-close>
				<fa-icon [icon]="['fad', 'xmark']"></fa-icon>{{'Cancel'|translate}}
			</span>
		</div>
	</div>

</div>
