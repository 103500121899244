<div class="inner_wrapper">
    <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="left">
        <h1>
            {{'Payment details'|translate}}
        </h1>
        </div>

        <div class="right">            
            <fa-icon class="icon_close" mat-button mat-dialog-close [icon]="['fad', 'times']"></fa-icon>
        </div>
    </div>

    <div class="body">  

        <div class="item edit w100">
            <div class="inline w33 wPadding">
                <mat-form-field appearance="outline">
                  <mat-label>{{'Date'|translate}}</mat-label>
                  <mat-icon (click)="onDateKey($event)"></mat-icon>
                  <input matInput [matDatepicker]="pickerValid" [(ngModel)]="date" (keyup)="onDateKey($event)">
                  <mat-datepicker-toggle matSuffix [for]="pickerValid"></mat-datepicker-toggle>
                  <mat-datepicker #pickerValid></mat-datepicker>
                  <!--<mat-hint>Error</mat-hint>-->                    
                </mat-form-field>
            </div>

            <div class="inline w33 wPadding">               
                <mat-form-field appearance="outline" class="important">
                  <mat-label>{{'Description'|translate}}</mat-label>
                  <input type="text" matInput id="description" [(ngModel)]="description" (keyup)="onDescriptionKey($event)">
                  <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>

            <div class="inline w33 wPadding">               
                <mat-form-field appearance="outline" class="important">
                  <mat-label>{{'Amount'|translate}}</mat-label>
                  <input type="text" matInput id="description" id="amount" [(ngModel)]="amount" (keyup)="onAmountKey($event)">
                  <!--<mat-hint>Error</mat-hint>-->
                </mat-form-field>
            </div>
        </div>  

        <div class="inner_scroll w50">
            <div *ngFor="let item of paymentDetails; let i = index" class="item amounts">
                <div class="date">{{cFunction.localDateOnlyFormat(item.date)}}</div>
                <div class="description">{{item.description}}</div>
                <div class="amount">
                    {{toCurrency(item.amount)}}
                    <fa-icon class="small" [icon]="['fad', 'times']" (click)="removePaymentItem(i)"></fa-icon>
                </div>
            </div>  
    
            <div class="item totals totalpaid">
                <div class="date">&nbsp;</div> 
                <div class="description">{{'Total paid'|translate}}</div>
                <div class="amount">{{toCurrency(totalPaid)}}</div>
            </div>  
    
            <div class="item totals totalamount">
                <div class="date">&nbsp;</div>
                <div class="description">{{'Total amount'|translate}}</div>
                <div class="amount">{{toCurrency(totalAmount)}}</div>
            </div>
            
            <div class="item totals totaldue">
                <div class="date">&nbsp;</div>
                <div class="description">{{'Total due'|translate}}</div>
                <div class="amount">{{toCurrency(totalDue)}}</div>
            </div>   
        </div>     
    </div>
 
    <div class="modalActions">
        <button class="action left" (click)="cancel()">{{'Cancel'|translate}}</button>
        <button class="accept right" (click)="save()">{{'Save'|translate}}</button>        
        <button class="action right" style="margin-right:0" (click)="fillPayment()">{{'Full paid'|translate}}</button>
    </div>

</div>