<div id="appWrapper">
	<div *ngIf="isLogged" id="appTopNavigation">
		<div class="vertical-center">
			<div class="navLeft">
				<div *ngIf="memberSettingsGlobal['showSidebar'] != 2" class="logo">
					<img src="/assets/logo/co.png" (click)="navigate('link', '/', null, null)" />
				</div>
				<div *ngIf="memberSettingsGlobal['showSidebar'] == 2" class="logo wide">
					<img src="/assets/logo/colabrity.png" (click)="navigate('link', '/', null, null)" />
				</div>
				<div class="content" [ngClass]="{'wide':memberSettingsGlobal['showSidebar']==2}">
					<ng-container *ngFor="let item of topNavigation">
						<ng-container *ngIf="item['entity']!='function:localSearch'">
							<div id="{{item.entity}}" *ngIf="memberSettingsGlobal['showSidebar']==0 || (memberSettingsGlobal['showSidebar']!=0 && item.entity!='apps')" class="topNavigationButton" [ngClass]="{'exposed': item.exposed}"  (click)="toggleOpenCloseMain(item.entity, item)">
							<!--<div class="topNavigationButton" [ngClass]="{'exposed': item.exposed}">-->
								<div class="topNavigationButtonInner">
									{{item.title|translate}}
									<span *ngIf="item.subTitle!=null" class="subTitle" [ngStyle]="{'background':item.subTitleColor}">{{item.subTitle}}</span>
									<div *ngIf="(item.submenu).length > 0" class="submenuWrapper">
										<ng-container *ngFor="let subItem of item.submenu">
											<div id="{{subItem.entity}}" class="submenuItem" class="{{subItem.type}}" [ngClass]="{'itemLeft':subItem.pinnable, 'exposed': item.exposed, 'function':subItem.submenu == null}">
												<div class="submenuItemInner" (click)="$event.stopPropagation();toggleOpenCloseSub(subItem.entity, subItem);navigate(subItem.type, subItem.action, subItem.component, subItem.actionData)" [ngClass]="{'subMenus':subItem.submenu != null}">
													<ng-container *ngIf="subItem.type != 'splitter' && subItem.type != 'text'">
														<fa-icon *ngIf="subItem.icon!=''" class="mainIcon" [icon]="[icon(subItem.icon)[0], icon(subItem.icon)[1]]" [ngStyle]="{'color': subItem.iconColor}"></fa-icon>
														<!--<fa-icon *ngIf="subItem.icon==''" class="mainIcon" [icon]="['fad', 'minus']" style="opacity:0.5"></fa-icon>-->
														<span>{{subItem.title|translate}}<!--<fa-icon *ngIf="subItem.submenu != null" class="mainIcon subItemIcon" [icon]="['fad', 'chevron-right']"></fa-icon>--></span>
														<ng-container *ngIf="subItem.submenu!=null">
															<div *ngIf="(subItem.submenu) != null" class="subSubMenuWrapper">
																<ng-container *ngFor="let subSubItem of subItem.submenu">
																	<div id="{{subSubItem.entity}}" class="submenuItem" (click)="$event.stopPropagation();toggleOpenCloseSub(subSubItem.entity);navigate(subSubItem.type, subSubItem.action, subSubItem.component, subSubItem.actionData)" class="{{subSubItem.type}}"  [ngClass]="{'itemLeft':subSubItem.pinnable, 'exposed': subSubItem.exposed, 'function':subSubItem.submenu == null}">
																		<div class="submenuItemInner subSubItemInner" [ngClass]="{'function':subSubItem.submenu == null}">
																			<fa-icon *ngIf="subSubItem.icon!=null" class="mainIcon subSubItemIcon" [icon]="[icon(subSubItem.icon)[0], icon(subSubItem.icon)[1]]" [ngStyle]="{'color': subSubItem.iconColor}"></fa-icon>
																			<span>{{subSubItem.title|translate}}</span>
																			<span *ngIf="subSubItem.subTitle !== ''" class="subTitleItem">{{subSubItem.subTitle}}</span>
																		</div>
																	</div>
																	<div *ngIf="subSubItem.pinnable" class="submenuItem right" [ngClass]="{'itemRight':subSubItem.pinnable}">
																		<fa-icon *ngIf="!isIconQuickSelected(subSubItem.entity)" style="position:absolute;top:-2px;right:10px !important;" [icon]="['far', 'star']" (click)="$event.stopPropagation();pinQuickNavigationItem(subSubItem.entity, 'add')"></fa-icon>
																		<fa-icon *ngIf="isIconQuickSelected(subSubItem.entity)" style="position:absolute;top:-2px;right:10px !important;" class="selected" [icon]="['far', 'star']" (click)="$event.stopPropagation();pinQuickNavigationItem(subSubItem.entity, 'remove')"></fa-icon>
																	</div>
																</ng-container>
															</div>
														</ng-container>										
													</ng-container>
												</div>
											</div>
											<div *ngIf="subItem.pinnable" class="submenuItem right" [ngClass]="{'itemRight':subItem.pinnable}">												
												<fa-icon *ngIf="!isIconSelected(subItem.entity)" [icon]="['fad', 'square-up-right']" (click)="pinTopNavigationItem(subItem.entity, 'add')"></fa-icon>
												<fa-icon *ngIf="isIconSelected(subItem.entity)" class="selected" [icon]="['fad', 'square-down-left']" (click)="pinTopNavigationItem(subItem.entity, 'remove')"></fa-icon>												
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</ng-container>
					</ng-container>
					<div class="topNavigationShortCuts">
						<ng-container *ngFor="let shortcut of topNavigationShortcuts | keyvalue">
							<ng-container *ngFor="let item of topNavigation">
								<ng-container *ngIf="item['entity']!='function:localSearch'">
									<ng-container *ngIf="(item.submenu).length > 0">
										<ng-container *ngFor="let subItem of item.submenu">	
											<div class="topNavigationShortcut" *ngIf="shortcut.key == subItem.entity && shortcut.value">
												<fa-icon *ngIf="subItem.icon!=''" class="big" [icon]="[icon(subItem.icon)[0], icon(subItem.icon)[1]]" (click)="navigate(subItem.type, subItem.action, subItem.component, subItem.actionData)"></fa-icon>
												<div class="tooltip">{{subItem.title|translate}}</div>
											</div>
										</ng-container>
									</ng-container>
								</ng-container>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</div>			
			<div class="navRight">
				<div class="topSearchWrapper">
					<!--<fa-icon class="big" (click)="prepareToExecuteSimulator();" [icon]="['fad', 'comment-text']"></fa-icon>-->

					<fa-icon *ngIf="!isLogged" class="big trigger" [icon]="['fad', 'user']" id="userTrigger" rel="userPanel"></fa-icon>
	 	        	<fa-icon *ngIf="isLogged" class="big trigger" [icon]="['fad', 'user-crown']" id="userTrigger" rel="userPanel"></fa-icon>
					<fa-icon class="big" style="margin-left: 36px;" [ngClass]="{'selected':memberSettingsGlobal['showSidebar']>0}" [icon]="['fad', 'sidebar']" (click)="toggleSideBar();"></fa-icon>
					

					<fa-icon *ngIf="!voiceRecognition" class="big" (click)="voiceRecognition=true;voiceRecognitionStart();" [icon]="['fad', 'microphone-slash']"></fa-icon>
					<fa-icon *ngIf="voiceRecognition" class="big blink" style="color:var(--cta); padding:0px 7px 0px 6px;" (click)="voiceRecognition=false;voiceRecognitionStop()" [icon]="['fad', 'microphone']"></fa-icon>					

					<!----------->				

					<span style="float:right; position: relative;">
						<fa-icon #trigger class="big trigger" id="unprocessedEmailsTrigger" rel="emailServicePanel" style="margin-top:16px;" [icon]="['fad', 'envelope-open-text']"></fa-icon>
						<span *ngIf="unprocessedEmails" class="counter">{{unprocessedEmails}}</span>
						<div  *ngIf="processingEmails" class="progresContainer"><div class="progressStatus" style="width:{{processingPercent}}%;"></div></div>
					</span>
					
					<span style="float:right; position: relative;margin-left:18px;">
						<fa-icon #trigger class="big trigger" id="latestActivityTrigger" rel="latestActivityPanel" style="margin-top:16px;" (click)="new=!new" [icon]="['fad', 'bell']"></fa-icon>
						<span *ngIf="unreadLatestCount" class="counter">{{unreadLatestCount}}</span>
					</span>

					<!----------->

					<fa-icon *ngIf="!globalSearch" class="big" id="globalSearchIcon" style="margin-top:16px;" [ngClass]="{'selected':globalSearch}" (click)="globalSearch=!globalSearch" [icon]="['fad', 'building-magnifying-glass']"></fa-icon>										
					<fa-icon *ngIf="globalSearch" class="big" id="globalSearchIcon_close" style="margin-top:16px;margin-left:36px;" [ngClass]="{'selected':globalSearch}" (click)="globalSearch=!globalSearch" [icon]="['fad', 'building-magnifying-glass']"></fa-icon>

					<div *ngIf="localSearchOption" style="float:right; position: relative; margin-right: 24px;">
						<input class="topSearch" (keyup)="onSearch($event)" [(ngModel)]="topSearch"/>				
						<div id="localSearchIcon"><fa-icon [icon]="['fad', 'magnifying-glass']"></fa-icon></div>
					</div>
				</div>						
			</div>
		</div>
		
	</div>

	<div id="latestActivityPanel" class="panel">
		<app-new></app-new>
	</div>

	<div id="userPanel" class=panel>
		<div class="panelButton" (click)="navigate('popup','userSettings',null,null)">
			<fa-icon class="big" [icon]="['fad', 'user-cog']"></fa-icon>
			<span>{{'My settings'|translate}}</span>
		</div>		
		<div class="splitter"></div>

		<div class="panelButton" (click)="navigate('logout',null,null,null)">
			<fa-icon class="big" style="color: #f00;" [icon]="['fad', 'right-from-bracket']"></fa-icon>
			<span>{{'Log out'|translate}}</span>
		</div>		
	</div>

	<div id="emailServicePanel" class=panel>
		<div *ngIf="processingEmails" class="progress">
			<span>{{'Processing emails...'}}</span>
			<div class="fullBar">
				<div style="width:{{processingPercent}}%;" class="statusBar"></div>
			</div>
			
		</div>
		<button *ngIf="unprocessedEmails>0 && !processingEmails" (click)="sendScheduledEmails();" style="width:100%;margin-top:0px;">{{'Send scheduled emails now'|translate}}</button>

		<div style="float:left;margin: 12px;" *ngIf="unprocessedEmails==0">{{'Currently, there are no scheduled emails to be processed'|translate}}</div>
	</div>

	<div id="appSearch" [ngClass]="{'active':globalSearch}">
		<app-search></app-search>
	</div>

	<div id="appSideNavigation" [ngClass]="{'collapse':memberSettingsGlobal['showSidebar']==1, 'collapseExtended':memberSettingsGlobal['showSidebar']==2}">
		<app-nav></app-nav>
	</div>

	<div id="appContent" class="{{direction}}" [ngClass]="{'collapse':memberSettingsGlobal['showSidebar']==1, 'collapseExtended':memberSettingsGlobal['showSidebar']==2, 'aiAgentActivateLog':aiAgentActivate}" (scroll)="onScroll($event)" (click)="closeCloseAll()">
		<router-outlet></router-outlet>	
	</div>

	<div class="file_container" appDragAndDrop (fileDropped)="onFileDropped($event)" style="visibility:hidden; opacity:0">		
		<div class="dropdown_infocontainer center">
			<div *ngIf="files" class="info dropdowninfo" style="text-align: center;">
				
				<ng-container *ngIf="fileChooser">
					{{'Choose or Drop files Here.'|translate}}<br /><br />
				</ng-container>
				<!--<input type="file" #fileDropRef id="fileDropRef" multiple [ngClass]="{'show':fileChooser}" (change)="fileBrowseHandler($event.target['files'])" />-->
				<input type="file" #fileDropRef id="fileDropRef" multiple [ngClass]="{'show':fileChooser}" (change)="onFileDropped($event.target['files'])" />
				
				<ng-container *ngIf="!fileChooser">
					{{'Drop files here.'|translate}}<br /><br />
				</ng-container>
				<fa-icon [icon]="['fad', 'times']" class="closeDropField" style="font-size: 52px" (click)="closeDropField()"></fa-icon>
			</div>			
			<div class="progress_container">
				<div *ngFor="let file of files" class="uploading_progress">
					<div class="progress_filename">
						{{file.name}} - {{formatBytes(file.size, 1)}}
					</div>
					<div class="progress">
						<div class="progress-value" style="width:{{file.progress}}%"></div>
					</div>
				</div>
			</div>
		</div> 
	</div>
	
</div> <!-- appwrapper -->

<ng-container *ngIf="isLogged">
	<div #tabs class="quickTab">
		<div class="iconWrapper"><fa-icon [icon]="['fas', 'hexagon-nodes']"></fa-icon><span>{{'AI Assistant (Beta)'|translate}}</span></div>	
		<div class="quickTabBody" style="width:320px;">
			<div *ngIf="aiAgentActivateLog!=null" class="aiAgentLog" [class.visible]="showLog">
				<span *ngFor="let item of aiLog" class="color_{{item.type}}" [innerHTML]="item.text"></span>
			</div>
			<input type="text" style="width:250px;" placeholder="{{'AI instructions...'|translate}}" [(ngModel)]="aiRequestText" (keydown.enter)="aiRequest()" />
			<fa-icon [icon]="['fad', 'square-question']" style="float:right;padding:10px 12px 0px 0px" class="big" (click)="aiRequestText='show commands';aiRequest();"></fa-icon>		
		</div>	
	</div>


	<div #tabs class="quickTab" *ngIf="dockGlobal.length">	
		<div class="iconWrapper"><fa-icon [icon]="['fas', 'thumbtack']"></fa-icon><span>{{'Dock'|translate}}</span></div>
		<div class="quickTabBody dockWrapper">
			<ng-container *ngFor="let item of dockGlobal">
				<div class="dockItem">
					<div class="dockItemLeft" (click)="navigate('popup', item['type'], null, item['entity'])">
						<div class="dockItemIcon">
							<fa-icon [ngStyle]="{'color':item['color']}" [icon]="[icon(item['icon'])[0], icon(item['icon'])[1]]"></fa-icon>
						</div>
						<div class="dockItemType">
							{{item['type']}}
						</div>
						<div class="dockItemText">
							{{item['title']}}
						</div>
					</div>
					<div class="dockItemRight" (click)="removeFromDock(item['type'], item['entity'])">
						<fa-icon [icon]="['fad', 'times']"></fa-icon>
					</div>
				</div> 				
			</ng-container>
		</div>	
	</div>

	<div #tabs class="quickTab">
		<div class="iconWrapper"><fa-icon [icon]="['fas', 'thumbtack']"></fa-icon><span>{{'Quick Functions'|translate}}</span></div>
		<div class="quickTabBody" style="padding: 18px;">
			<app-quick-functions></app-quick-functions>
		</div>	
	</div>
</ng-container>
