<div class="output_field">
  <div *ngFor="let message of output; let i = index">        
      <div class="output" [innerHTML]="message"></div>
  </div>
</div>

<div class="command">
  <div class="w80" style="float:left;">
    <input #commandInput type="text" placeholder="Vnesite ukaz" [(ngModel)]="chat" (keyup.enter)="onExecute(commandInput.value)" />
  </div>
  <div class="w20" style="float:left;padding-top:12px;text-align: right;">
    <label class="switch" style="float:right">
      <input type="checkbox" [(ngModel)]="simulate">
      <span class="slider round"></span>
    </label>
    <div class="label label-checkbox" style="padding-right:12px;">{{'Execute command'|translate}}</div>
  </div>
</div>

