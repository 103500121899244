import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { DataService } from '../services/data.service';
import { GlobalVariables } from '../services/data.globals';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WidgetsComponent } from '../widgets/widgets.component';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

/* navigation and member services | start */
import { NavigationService } from '../services/navigation.service';
import { MembersettingsService } from '../services/membersettings.service';
import { SafeKeyedRead, SafePropertyRead } from '@angular/compiler';
/* navigation and member services | end */


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html', 
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

  /* navigation and member services | start */
  public memberAllowance = null;
  public lastFunctionCallAt = Date.now();
  
  /* topNavigtion components default */
  public topNavigation = [{
    entity: "dashboard", exposed: false, component: "dashboard", title: "View", subTitle: null, subTitleColor: null, type: null, action: null, actionData: null, icon: null, iconColor: null, pinnable: false, refreshable: false, submenu: [
        { entity: "dashboard.1", exposed: false, component: "dashboard", title: "1 column layout", subTitle: null, subTitleColor: null, type: "localFunction", action: "changeLayout", actionData: 100, icon: "fad,objects-column", iconColor: null, pinnable: false, refreshable: false, submenu: null },
        { entity: "dashboard.2", exposed: false, component: "dashboard", title: "2 columns layout", subTitle: null, subTitleColor: null, type: "localFunction", action: "changeLayout", actionData: 50, icon: "fad,objects-column", iconColor: null, pinnable: false, refreshable: false, submenu: null },
        { entity: "dashboard.3", exposed: false, component: "dashboard", title: "3 columns layout", subTitle: null, subTitleColor: null, type: "localFunction", action: "changeLayout", actionData: 33, icon: "fad,objects-column", iconColor: null, pinnable: false, refreshable: false, submenu: null },
        { entity: null, exposed: false, component: "dashboard", title: null, subTitle: null, subTitleColor: null, type: "splitter", action: null, actionData: null, icon: null, iconColor: null, pinnable: false, refreshable: false, submenu: null },
        { entity: "dashboard.4", exposed: false, component: "dashboard", title: "Reload Layout", subTitle: null, subTitleColor: null, type: "localFunction", action: "", actionData: null, icon: "fad,rotate-left", iconColor: null, pinnable: false, refreshable: false, submenu: null }
    ]}
  ]
  /* navigation and member services | end */



  public layer = 1;
  public layer_position = 1;
  public layer_max = 2;  
  public layer_movement_percent = '0%';
  public dashboard_extended = false;
  public layer_container_width = "";
  public edit_layout_settings = false;
  public layerTitle = "";
  public dashboardBG = "";
  
  public default_dashboard = { "layer1" : { "title" : "Start HERE", "columns" : { "column1" : {}, "column2" : {}, "column3" : {} } } }; 
  public dashboard = {};
 
  /******************************************************************/
  /******************************************************************/
  /******************************************************************/
  
  constructor(
    public dialog: MatDialog,
    private data: DataService,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private NavigationService: NavigationService,
    private MembersettingsService: MembersettingsService
  ) {
    translate.addLangs(['en', 'si']);
  }

  /* functions */
  public makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) { result += characters.charAt(Math.floor(Math.random() * charactersLength)); }
    return result;
  }

  onCounterChange(input) {
    console.log(input);
  }


  /***************************/
  /* Layers */
  saveLayerTitle() {
    /* get layer id from position */
    var counter = 0; var layer = null;
    for (var key in this.dashboard) { counter++; if (counter == this.layer) layer = key; }
    this.dashboard[layer]['title'] = this.layerTitle;
    this.edit_layout_settings=!this.edit_layout_settings;
    this.cleanUpDashboard();
    this.saveDashboardSettings();
  }

  changeLayout(type) {   
    /* get layer id from position */
    var counter = 0; var layer = null;
    for (var key in this.dashboard) { counter++; if (counter == this.layer) layer = key; }
    
    var count_columns = Object.keys(this.dashboard[layer]['columns']).length;
    var last_column = null;
    var max_columns = 3;
    var final_columns = 3;

    switch (type) {
      case 100:
        max_columns = 1;
        break;
      case 50:
        max_columns = 2;
        break;
      case 33:
        max_columns = 3;
        break;
      default:
        max_columns = 2;
        break;
    }
    
    final_columns = max_columns;
    
    if (count_columns > max_columns) {      
      for (var column in this.dashboard[layer]['columns']) {
        if (max_columns <= 0) { 
          for (var widget in this.dashboard[layer]['columns'][column]) {          
            this.dashboard[layer]['columns'][last_column][Object.keys(this.dashboard[layer]['columns'][last_column]).length] = this.dashboard[layer]['columns'][column][widget];            
          }        
          delete this.dashboard[layer]['columns'][column];
        } else {
          last_column = column;
        }
        max_columns--;
      }
    }
    
    for(var i = count_columns+1; i<final_columns+1; i++) {      
      this.dashboard[layer]['columns']['column'+i] = {};
    }
    this.cleanUpDashboard();
    this.saveDashboardSettings();
  }

  /* Layer */
  addLayout() {    
    //console.log('add layout');
    this.cleanUpDashboard();
    var count_layer = 0;
    for (var key in this.dashboard) {       
      var layer_id = parseInt(key.replace('layer', ''));      
      if (layer_id >= count_layer) count_layer = layer_id + 1;
      //console.log(layer_id + "|" + count_layer);
    }    

    var helper = {      
      "title" : "New...",
      "columns" : { "column1" : {}, "column2" : {}, "column3" : {} }
    };
    //
    //this.dashboard['layer'+count_layer] = helper;    
    GlobalVariables.memberSettings['dashboard'] = JSON.stringify(this.dashboard);
    this.saveDashboardSettings();
    this.updateLayers();    
  }

  removeLayout() {
    this.cleanUpDashboard();
    var counter = 0;
    for (var key in this.dashboard) { 
      counter++;
      if (counter == this.layer) delete this.dashboard[key];
    }
    this.layer = 1;
////

    GlobalVariables.memberSettings['dashboard'] = JSON.stringify(this.dashboard);
    this.saveDashboardSettings();
    this.updateLayers();    
  }

  moveLayer(position) {
    var margin = "-" + (position * 100) + "%";
    this.layer_movement_percent = margin;   
    this.layer_position = position;
    position++;
    this.layer = position;    
    
    /* get layer id from position */
    var counter = 0; var layer = null;
    for (var key in this.dashboard) { counter++; if (counter == this.layer) layer = key; }
    this.layerTitle = this.dashboard[layer]['title'];
    //console.log(this.layerTitle);
  }

  moveLeft() {    
    this.layer_position--;
    this.layer_position = this.layer_position < 2 ? 1 : this.layer_position;
    this.layer_movement_percent = "-" + ((this.layer_position - 1) * 100) + "%";
    this.layer = this.layer_position;

    /* get layer id from position */
    var counter = 0; var layer = null;
    for (var key in this.dashboard) { counter++; if (counter == this.layer) layer = key; }
    this.layerTitle = this.dashboard[layer]['title'];
  }

  moveRight() {
    this.layer_position++;
    this.layer_position = this.layer_position > this.layer_max ? this.layer_max : this.layer_position;
    this.layer_movement_percent = "-" + ((this.layer_position - 1) * 100) + "%";
    this.layer = this.layer_position;    

    /* get layer id from position */
    var counter = 0; var layer = null;
    for (var key in this.dashboard) { counter++; if (counter == this.layer) layer = key; }
    this.layerTitle = this.dashboard[layer]['title'];
  }


  /***************************/
  /* cleanup dashboard */
  cleanUpDashboard() {
    for (var keyLvl1 in this.dashboard) {      
      for (var keyLvl2 in this.dashboard[keyLvl1]) {        
        for (var keyLvl3 in this.dashboard[keyLvl1][keyLvl2]) {          
          var helper = {};
          var i = 0;          
          if (keyLvl2 == 'columns') {
            for (var keyLvl4 in this.dashboard[keyLvl1][keyLvl2][keyLvl3]) {
              if (this.dashboard[keyLvl1][keyLvl2][keyLvl3][keyLvl4]['type'] != '') {
                helper[i] = this.dashboard[keyLvl1][keyLvl2][keyLvl3][keyLvl4];
                i++;
              }
            }
            this.dashboard[keyLvl1][keyLvl2][keyLvl3] = helper;
          }          
        }
      }
    }
  }

  /***************************/
  /* widgets */
  add(column) {
    var editDialog = this.dialog.open(WidgetsComponent, {});    
    this.cleanUpDashboard();

    /* get layer id from position */    
    var counter = 0; var layer = null;
    for (var key in this.dashboard) { counter++; if (counter == this.layer) layer = key; }
    
    if (layer != null) {
      editDialog.afterClosed().subscribe(result => {      
        if (result != undefined && result != "") {
          var count = Object.keys(this.dashboard[layer]['columns'][column]).length;
          var id = this.makeid(4) + "-" + this.makeid(4) + "-" + this.makeid(4);
          var temp = {
            "id" : id,
            "type" : result
          };                 
          this.dashboard[layer]['columns'][column][count] = temp;
          this.saveDashboardSettings();
        }
      });    
      this.saveDashboardSettings();
    }     
  }

  layerKey() {
    /* get layer id from position */
    var counter = 0; var layer = null;
    for (var key in this.dashboard) { counter++; if (counter == this.layer) layer = key; }
    return layer;
  }

  removeWidget(id) {
    /* get layer id from position */
    this.cleanUpDashboard();
    var counter = 0; var layer = null;
    for (var key in this.dashboard) { counter++; if (counter == this.layer) layer = key; }

    var delete_key = null;
    var delete_item = null;    
    for (var key in this.dashboard[layer]['columns']) { 
      for (var item in this.dashboard[layer]['columns'][key]) { 
        if (this.dashboard[layer]['columns'][key][item].id == id) {
          delete_key = key;
          delete_item = item;
        }
      }
    }
    delete this.dashboard[layer]['columns'][delete_key][delete_item];
    this.saveDashboardSettings();
  }

  drop(event: CdkDragDrop<any>) {
    /* get layer id from position */

    this.cleanUpDashboard();

    var counter = 0; var layer = null;
    for (var key in this.dashboard) { counter++; if (counter == this.layer) layer = key; }

    var temp_object = this.dashboard[layer]['columns'][event.previousContainer.data][event.previousIndex];
    delete this.dashboard[layer]['columns'][event.previousContainer.data][event.previousIndex];

    var new_object = {};
    var i = 0;

    for (var item in this.dashboard[layer]['columns'][event.container.data]) {
      if (event.currentIndex == i) {
        new_object[i] = temp_object;  
        i++;
      }
      new_object[i] = this.dashboard[layer]['columns'][event.container.data][item];
      i++;        
    }

    if (i == event.currentIndex) {
      new_object[i] = temp_object;  
    }
    
    this.dashboard[layer]['columns'][event.container.data] = new_object;
    this.saveDashboardSettings();
  }
 
  /******************/
  /* Common */
  updateLayers() {       
    console.log('*** Update Layers ***');    
    //console.log(GlobalVariables.memberSettings);    
    //console.log(GlobalVariables.memberSettings['dashboard']);    
    this.dashboard = GlobalVariables.memberSettings['dashboard'] != '' && GlobalVariables.memberSettings['dashboard'] != undefined ? JSON.parse(GlobalVariables.memberSettings['dashboard']) : this.default_dashboard;
    this.cleanUpDashboard();

    //console.log(this.dashboard);
    /*
    this.layer_position = 1;
    this.layer_max = Object.keys(this.dashboard).length;  
    this.layer_movement_percent = '0%';
    this.dashboard_extended = false;
    this.layer_container_width = "calc(" + ((Object.keys(this.dashboard).length+1)*100) + "vw - 580px)";
    this.layer = 1;    
    */
  }

  /* Save SetUp */
  saveDashboardSettings() {
    var parameters_json = JSON.stringify(this.dashboard);  
    GlobalVariables.memberSettings['dashboard'] = parameters_json;
    this.data.setMemberSettings({'parameter': 'dashboard', 'value': parameters_json}).subscribe((res: Response) => {});    
  }

  loadStyle(styleName: string) {
		const head = this.document.getElementsByTagName('head')[0];
	
		let themeLink = this.document.getElementById(
		  'client-theme'
		) as HTMLLinkElement;

		if (themeLink) {
		  themeLink.href = styleName;
		} else {
		  const style = this.document.createElement('link');
		  style.id = 'client-theme';
		  style.rel = 'stylesheet';
		  style.href = `${styleName}`;	
		  head.appendChild(style);
		}

    //set background of dashboard
    //this.dashboardBG = GlobalVariables.memberSettings['dashboard-bg'];

    this.data.getStorageID({}).subscribe((res: Response) => {
      var url = "https://api.biliz.com/storage/" + res['storage'] + "/" + this.dashboardBG.charAt(0) + "/" + this.dashboardBG.charAt(1) + "/" + this.dashboardBG;
      //document.getElementById('mainView').style.backgroundImage = "url('"+url+"')";

      if (this.dashboardBG != "") document.getElementsByTagName('html')[0].style.backgroundImage = 'url('+url+')';
    });    

    
    //document.getElementById('mainView').style.backgroundImage = "url('img_tree.png')";
	}

  refreshMemberSettings() {
    console.log('*** RefreshMemberSettings ***');
    this.data.getMemberSettings(null).subscribe(
      (res: Response) => {
        //console.log(res);
        var memberSettingsArray = [];        
        for (var i=0; i<res['rows'].length; i++) {          
          memberSettingsArray[res['rows'][i].parameter] = res['rows'][i].value;
        }        
        GlobalVariables.memberSettings = memberSettingsArray;        
        //console.log(GlobalVariables.memberSettings);
        this.updateLayers();
        
        //console.log(GlobalVariables.memberSettings['dashboard']);

        var theme = GlobalVariables.memberSettings['theme'] == undefined ? 'dark' : GlobalVariables.memberSettings['theme'];
        this.dashboardBG = GlobalVariables.memberSettings['dashboard-bg'] == undefined ? '' : GlobalVariables.memberSettings['dashboard-bg'];	
        this.loadStyle(theme + '.css');
      }
    );
  }
  
  /**********************************/

  emptyObject(obj): number {
    let count = 0;    
    if (obj && typeof obj === 'object') {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (key === 'id') { count++; }                
                count += this.emptyObject(obj[key]);
            }
        }
    }
    return count;
  }

  /******************************************************************/
  /******************************************************************/
  
  ngOnInit(): void {

    /* Language.Service */
    var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);

    /* Navigation.Service */    
    /* Listen for local function call */
    this.NavigationService.localFunction.subscribe(callLocalFunction => {
      if (callLocalFunction[0] == "dashboard" && callLocalFunction[1] == "localFunction" && this.lastFunctionCallAt < Date.now()) {        
        this[callLocalFunction[2]](callLocalFunction[3]);
        //this.NavigationService.prepareObserverForLocalFunction([]);        
        this.lastFunctionCallAt = Date.now()+500; //prevent calling function too fast by default 500mS between call
      }
    });
    
    /* Member.Service */
    this.MembersettingsService.getMemberAllowance();
    this.MembersettingsService.memberAllowance.subscribe(message => this.memberAllowance = message);

    /* Wait for memberAllowance to be ready with timeout function  */    
    var maxExecutionTime = 5000;
    var executionInterval = 100;
    var navigationInterval = setInterval(() => {      
      maxExecutionTime = maxExecutionTime - executionInterval;      
      if (maxExecutionTime < 1) {
        clearInterval(navigationInterval);
        //timeout: Can not retrieve data from API. Please refresh application.
      }
      if (this.memberAllowance != null) {        
        this.NavigationService.updateTopNavigation(this.topNavigation);
        clearInterval(navigationInterval);
      }
    }, executionInterval);

    /***/
    this.refreshMemberSettings();            
  }

}
