<div class="inner_wrapper">
	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="left">
			<h1><fa-icon [icon]="['fad', 'user-clock']"></fa-icon> | {{'Time tracking'|translate}} | {{'Filter'|translate}}</h1>
		</div>
		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
		</div>
	</div>

	<form [formGroup]="formData" novalidate style="width:500px">
	
		<!--<input type="hidden" formControlName="entity" name="entity" [(ngModel)]="entity" />-->
		<div class="body">

			<div class="main_data" style="float:left;width:50%;">
				<div class="line">				
					<div class="field">
						<input formControlName="user_id" [(ngModel)]="user_id" type="hidden" />
						<input class="stickToRight" formControlName="user" name="user" [(ngModel)]="user" (click)="selectUser()" readonly />
						<button type="button" class="inlineButton stickToLeft">
							<fa-icon [icon]="['fad', 'user']" (click)="selectUser()"></fa-icon>
						</button>
						<fa-icon class="remove_icon" [icon]="['fad', 'times']" (click)="removeUser()"></fa-icon>
					</div>
					<div class="label">
						{{'Filter by user'|translate}}
					</div>
				</div>

				<div class="line">				
					<div class="field">
						<!--<input formControlName="activity" name="activity" [(ngModel)]="activity" />-->
						<input formControlName="activity_id" [(ngModel)]="activity_id" type="hidden" />
						<input class="stickToRight" formControlName="activity" name="activity" [(ngModel)]="activity" (click)="selectActivity()" readonly />
						<button type="button" class="inlineButton stickToLeft">
							<fa-icon [icon]="['fad', 'list']" (click)="selectActivity()"></fa-icon>
						</button>
						<fa-icon class="remove_icon" [icon]="['fad', 'times']" (click)="removeActivity()"></fa-icon>
					</div>
					<div class="label">
						{{'Filter by activity'|translate}}
					</div>
				</div>	

				<div class="line">				
					<div class="field">
						<input formControlName="customer_id" [(ngModel)]="customer_id" type="hidden" />
						<input class="stickToRight" formControlName="customer" name="customer" [(ngModel)]="customer" (click)="selectCustomer()" readonly />
						<button type="button" class="inlineButton stickToLeft">
							<fa-icon [icon]="['fad', 'users']" (click)="selectCustomer()"></fa-icon>
						</button>
						<fa-icon class="remove_icon" [icon]="['fad', 'times']" (click)="removeCustomer()"></fa-icon>
					</div>
					<div class="label">
						{{'Filter by customer'|translate}}
					</div>
				</div>		
			</div>

			<div class="time_data">
				<div class="line">				
					<div class="field">
						<input type="hidden" formControlName="start_date_raw" name="start_date_raw" [(ngModel)]="start_date_raw" readonly />
						<input class="stickToRight" formControlName="start_date" name="start_date" (click)="assignDateTime(null, start_date, end_date, null, start_date_raw, end_date_raw)" [(ngModel)]="start_date" readonly />
						<button type="button" class="inlineButton stickToLeft">
							<fa-icon [icon]="['fad', 'clock']" class="clock" (click)="assignDateTime(null, start_date, end_date, null, start_date_raw, end_date_raw)"></fa-icon>
						</button>
						<fa-icon class="remove_icon" [icon]="['fad', 'times']" (click)="removeDateTime()"></fa-icon>
					</div>
					<div class="label">
						{{'From date'|translate}}
					</div>
				</div>

				<div class="line">				
					<div class="field">
						<input type="hidden" formControlName="end_date_raw" name="end_date_raw" [(ngModel)]="end_date_raw" readonly />
						<input class="stickToRight" formControlName="end_date" name="end_date" (click)="assignDateTime(null, start_date, end_date, null, start_date_raw, end_date_raw)" [(ngModel)]="end_date" readonly />
						<button type="button" class="inlineButton stickToLeft">
							<fa-icon [icon]="['fad', 'clock']" class="clock" (click)="assignDateTime(null, start_date, end_date, null, start_date_raw, end_date_raw)"></fa-icon>
						</button>
						<fa-icon class="remove_icon" [icon]="['fad', 'times']" (click)="removeDateTime()"></fa-icon>
					</div>
					<div class="label">
						{{'To date'|translate}}
					</div>
				</div>
			</div>
		</div>


		<div class="footer" mat-dialog-actions>		
			<button class="cancel" mat-button mat-dialog-close>{{'Cancel/Clear'|translate}}</button>
			<button class="confirm" (click)="confirm()">{{'Confirm'|translate}}</button>	
		</div>

	</form>

</div>