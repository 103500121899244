<div class="inner_wrapper">
	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<!-- Title -->
        <div class="left"> 
			<h1>{{'Edit Quote Item'|translate}}</h1>
		</div>        
        <!-- Close -->
		<div class="right">                     
            <fa-icon class="icon_close" [icon]="['fad', 'times']"  mat-button mat-dialog-close></fa-icon>
		</div>        
	</div>	

	<div class="body">    
        <div class="inline w30 wPadding">
            <mat-form-field appearance="outline">
                <mat-label>{{'Category'|translate}}</mat-label>
                <mat-select [(ngModel)]="injected_data['category']">
                    <mat-option *ngFor="let category of categoriesLocal" value="{{category}}">{{category}}</mat-option>
                </mat-select>
                <!--<mat-hint>Error</mat-hint>-->
            </mat-form-field>
        </div>

        <div class="inline w30 wPadding"> 
            <mat-form-field appearance="outline">
                <mat-label>{{'Type'|translate}}</mat-label>
                <mat-select [(ngModel)]="injected_data['type']">                    
                    <mat-option *ngFor="let item of types['Media']" [value]="item">{{item}}</mat-option>
                </mat-select>
                <!--<mat-hint>{{injected_data['category']}}</mat-hint>-->
            </mat-form-field>
        </div>

        <div class="inline w15 wPadding right">
            <mat-form-field appearance="outline">
                <mat-label>{{'Price'|translate}}</mat-label>
                <input type="text" matInput class="right" [(ngModel)]="injected_data['regular_price_formated']">
                <!--<mat-hint>Error</mat-hint>-->
            </mat-form-field>            
        </div>

        <div class="inline w15 wPadding right">
            <mat-form-field appearance="outline">
                <mat-label>{{'ID'|translate}}</mat-label>
                <input type="text" matInput [(ngModel)]="injected_data['poz']">
                <!--<mat-hint>Error</mat-hint>-->
            </mat-form-field>
        </div>

        <div class="inline w100 divider"></div>

        <div class="inline w100 wPadding">
            <mat-form-field appearance="outline">
                <mat-label>{{'Title'|translate}}</mat-label>
                <input type="text" matInput [(ngModel)]="injected_data['title']">
                <!--<mat-hint>Error</mat-hint>-->
            </mat-form-field>            
        </div>

        <div class="inline w100 wPadding">
            <mat-form-field appearance="outline">
                <mat-label>{{'Short Description' | translate}}</mat-label>
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" [(ngModel)]="injected_data['short_description']"></textarea>
                <!--<mat-hint>Error</mat-hint>-->
            </mat-form-field>
        </div>
    </div>

    <div class="modalActions">
        <button class="left" (click)="cancel()">{{'Close'|translate}}</button>
        <button class="right accept" (click)="save()">{{'Save'|translate}}</button>   
    </div>
</div>