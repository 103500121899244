import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../../../services/data.globals';

@Component({
  selector: 'app-assign-user-group',
  templateUrl: './assign-user-group.component.html',
  styleUrls: ['./assign-user-group.component.css']
})
export class AssignUserGroupComponent implements OnInit {

	public groupEntitySelected = null;
	public groupIndex = null;
	public data2;

	constructor(
		public dialog: MatDialogRef<AssignUserGroupComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		public translate: TranslateService
	) { 
	  translate.addLangs(['en', 'si']);
	}

  	ngOnInit(): void {
		this.data2 = this.data;
		console.log(this.data);
  		var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    	this.translate.use(lang);

		$(document).ready(function() {
       
			$(document).on('keyup', '#member_filter', function() {
			  var search = $(this).val();
			  var member_count = 0;
			  var group_count = 0;
			  $('.assign_users_groups_body .member').each(function() {
				var full_name = $(this).find('.user_fullname').text();
				if (full_name.toString().toLowerCase().includes(search.toString().toLowerCase())) {
				  $(this).removeClass('filtered');  
				  member_count++;          
				} else {
				  $(this).addClass('filtered');
				}
			  });
			  $('.assign_users_groups_body .group').each(function() {
				var full_name = $(this).find('.group_fullname').text();
				if (full_name.toString().toLowerCase().includes(search.toString().toLowerCase())) {
				  $(this).removeClass('filtered');  
				  group_count++;
				} else {
				  $(this).addClass('filtered');
				}
			  });
			  if (member_count == 0) { $('.member_title').hide(); } else { $('.member_title').show(); }
			  if (group_count == 0) { $('.group_title').hide(); } else { $('.group_title').show(); }
			}); 
			/*
			$(document).on('click', '.member, .group', function() {
				if ($('.showUsers:hover').length == 0) {
					if ($(this).hasClass('selected')) {
						$(this).removeClass('selected');
					} else {
						$(this).addClass('selected');
					}
				}
			});
			*/
	  
		  });
  	}

	showUsers(groupEntity, index) {
		this.groupEntitySelected = groupEntity;
		this.groupIndex = index;

		console.log(this.groupEntitySelected);
		console.log(this.groupIndex);
	}

  	toggleMember(entity) {

		entity = parseInt(entity);
		var ids = this.data.membersSelected;

		console.log(ids);
		console.log(entity);
		console.log(this.data2.members);
		var index = ids.indexOf(entity);

		if (index > -1) {
	      	ids.splice(index, 1);      		
		} else {  
	      	/* fix for empty array */
	      	if (ids[0] == "") { ids = []; }
			ids.push(entity);
    	}

    	this.data.membersSelected = ids;
		this.data2.membersSelected = ids;

		console.log(this.data2.membersSelected);

	}

	checkForSelectedGroup(member) {
		var user_group = member.user_group.split(',');
		if ((member.user_group).includes(this.groupEntitySelected)) return 1;
		return -1;
	}

	toggleGroup(entity) {
		
		entity = parseInt(entity);
		var ids = this.data.groupsSelected;
		var index = ids.indexOf(entity);

		if (index > -1) {
	      	ids.splice(index, 1);      		
		} else {  
	      	/* fix for empty array */
	      	if (ids[0] == "") { ids = []; }
			ids.push(entity);
    	}

    	this.data.groupsSelected = ids;
		this.data2.groupsSelected = ids;
    	
	}

	assignMemberGroup() {
		this.dialog.close(this.data);
	}

	assignMemberGroup_cancel() {
		this.dialog.close(false);
	}

	close() {
		delete this.data.membersSelected;
		delete this.data.groupsSelected;
		delete this.data.members;
		delete this.data.groups;
		this.data.activity = null;
		this.dialog.close(this.data);
	}

	/*************************************************/
	/*************************************************/
	/*************************************************/

	getInitials(str) {
		var FirstName = '';
		var LastName = '';

		if (str != undefined) {      
			FirstName = str.split(' ')[0];
			LastName = str.split(' ').reduceRight(a => a);
		}

		if (FirstName === LastName) { return FirstName.trim().substring(0, 2).toUpperCase(); }
		return [FirstName, LastName].map(name => name[0]).join('').toUpperCase();
	}

	ShowUserInicials(users) {
		var updated_users = [];
		if (users) {
			users = users.split(',');
			for (var i=0; i<users.length; i++) {
				var initials = this.getInitials(users[i]);
				var helper_users: any = {};        
				helper_users.initials = this.getInitials(users[i]);
				helper_users.fullname = users[i];
				updated_users.push(helper_users);
			}
		}    
		return updated_users;
	}

}