<div class="inner_wrapper">
	<div class="header">
	  <div class="left">
		<h1>{{'Select user'|translate}}</h1>
	  </div>
	  <div class="right">
		<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
	  </div>
	</div>
	<div class="body">
		<div class="user-search search">
			<input type="text" id="search" (keyup)="onSearchUser($event)" placeholder="{{'Search for users...'|translate}}"/>
		</div>
		<div class="user-results results wPadding">
			<div *ngFor='let item of items' class="user" (click)="onSelect({'entity':item.entity, 'user': item.username})">
				<div class="color" [ngStyle]="{'background':item.hex}"></div>
				<div class="name">{{item.username}}</div>
				<div class="vat">{{item.email}}</div>
				<div *ngIf="item.admin_rights=='true'" class="admin">
					<fa-icon class="icon_close" [icon]="['fad', 'user-crown']"></fa-icon>
				</div>
			</div>		
		</div>
	</div> 
	<div class="modalActions">
		<button class="action left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
	</div>
  </div>