<div class="inner_wrapper">
	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="left"> 
			<h1>{{'System settings'|translate}}</h1>
		</div>
		<div class="right">
            <fa-icon class="icon_close" [icon]="['fad', 'times']"  mat-button mat-dialog-close></fa-icon>
		</div>
	</div>	

	<div class="body">
        <div class="tabs tabsHeader">
            <div class="tab" [ngClass]="{'active':selectedTab == 'organisation'}" (click)="switchTab('organisation')">
                <span>{{'Organisation'|translate}}</span>
            </div>            
            <div class="tab" [ngClass]="{'active':selectedTab == 'email'}" (click)="switchTab('email')">
                <span>{{'Email server details'|translate}}</span>
            </div>            
            <!--
            <div class="tab" [ngClass]="{'active':selectedTab == 'etalk'}" (click)="switchTab('etalk')">
                <span>{{'eTalk'|translate}}</span>
            </div>
            -->
            <!--
            <div class="tab" [ngClass]="{'active':selectedTab == 'google'}" (click)="switchTab('google')">
                <span>{{'Google services'|translate}}</span>
            </div>            
            -->
        </div>

        <!------------------------------------------------------->
        <div id="organisation" *ngIf="selectedTab=='organisation'" class="tab-container">
            <div class="block">
                
                <div class="inline">
                    <div class="field">
                        <div class="logo_container" id="logo_container"></div>
                    </div>
                    <div class="label">{{'Company logo'|translate}} (JPG, PNG - {{'supported'|translate}}) - {{'Drop image to container'|translate}}</div>                
                </div>

                <hr />

                <div class="inline w33">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'VAT'|translate}}</mat-label>
                        <input type="text" matInput [(ngModel)]="company_vat">
                        <!--<mat-hint>Error</mat-hint>-->
                    </mat-form-field>
                </div>

                <hr />

                <div class="inline w100">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Company/Organization'|translate}}</mat-label>
                        <input type="text" matInput [(ngModel)]="company_organisation">
                        <!--<mat-hint>Error</mat-hint>-->
                    </mat-form-field>
                </div>

                <div class="inline w33">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Street'|translate}}</mat-label>
                        <input type="text" matInput [(ngModel)]="company_street">
                        <!--<mat-hint>Error</mat-hint>-->
                    </mat-form-field>
                </div>

                <div class="inline w33" style="margin-left: 20px;">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'ZIP'|translate}}</mat-label>
                        <input type="text" matInput [(ngModel)]="company_zip">
                        <!--<mat-hint>Error</mat-hint>-->
                    </mat-form-field>                
                </div>

                <div class="inline w33" style="margin-left: 20px;">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'City'|translate}}</mat-label>
                        <input type="text" matInput [(ngModel)]="company_city">
                        <!--<mat-hint>Error</mat-hint>-->
                    </mat-form-field>    
                </div>

                <hr />

                <div class="inline w33">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Bank account'|translate}}</mat-label>
                        <input type="text" matInput [(ngModel)]="bank_account">
                        <!--<mat-hint>Error</mat-hint>-->
                    </mat-form-field>                    
                </div>

                <div class="inline w33" style="margin-left: 20px;">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'SWIFT'|translate}}</mat-label>
                        <input type="text" matInput [(ngModel)]="bank_swift">
                        <!--<mat-hint>Error</mat-hint>-->
                    </mat-form-field> 
                </div>

                <hr />

                <div class="inline w33">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Phone'|translate}}</mat-label>
                        <input type="text" matInput [(ngModel)]="company_phone">
                        <!--<mat-hint>Error</mat-hint>-->
                    </mat-form-field> 
                </div>

                <div class="inline w33" style="margin-left: 20px;">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Email'|translate}}</mat-label>
                        <input type="text" matInput [(ngModel)]="company_email">
                        <!--<mat-hint>Error</mat-hint>-->
                    </mat-form-field> 
                </div>

                <div class="inline w33" style="margin-left: 20px;">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'URL'|translate}}</mat-label>
                        <input type="text" matInput [(ngModel)]="company_url">
                        <!--<mat-hint>Error</mat-hint>-->
                    </mat-form-field> 
                </div>                
            </div>
        </div>

        <!------------------------------------------------------->
        
        <div id="email" *ngIf="selectedTab=='email'" class="tab-container">
            <div class="block">
                <div class="inline w100">
                    <label class="switch" style="margin-right:12px;">
                        <input type="checkbox" [(ngModel)]="emailServer['enabled']">
                        <span class="slider round"></span>
                    </label>                    
                    {{'Use email system for documents and communications'|translate}}
                </div>		
            
                <div class="inline w50" style="margin-top: 24px; padding-right: 12px;">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'From Email'|translate}}</mat-label>
                        <input matInput [(ngModel)]="emailServer['from_email']">
                    </mat-form-field>
                </div>

                <div class="inline w50" style="margin-top: 24px;">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'From Name'|translate}}</mat-label>
                        <input matInput [(ngModel)]="emailServer['from_name']">
                    </mat-form-field>
                </div>


                <div class="inline w50" style="padding-right: 12px;">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Reply To Email'|translate}}</mat-label>
                        <input matInput [(ngModel)]="emailServer['replay_to_email']">
                    </mat-form-field>
                </div>

                <div class="inline w50">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Reply To Name'|translate}}</mat-label>
                        <input matInput [(ngModel)]="emailServer['replay_to_name']">
                    </mat-form-field>
                </div>

                <hr />

                <div class="inline w100" style="padding-bottom: 24px;">
                    <span class="title" style="font-weight: 500;">{{'Sending emails (Activity, Erp Documents... etc)'|translate}}</span>
                </div>

                <div class="inline w80" style="padding-right: 12px;">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'SMTP Host'|translate}}</mat-label>
                        <input matInput [(ngModel)]="emailServer['smtp_host']">
                    </mat-form-field>
                </div>

                <div class="inline w20">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'SMTP Port'|translate}}</mat-label>
                        <input matInput [(ngModel)]="emailServer['smtp_port']">
                    </mat-form-field>
                </div>

                <div class="inline w60">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'SMTP Security'|translate}}</mat-label>
                        <mat-select [(ngModel)]="emailServer['smtp_security']">
                            <mat-option value="STARTTLS">{{'STARTTLS'|translate}}</mat-option>                            
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="inline w50" style="padding-right: 12px;">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'SMTP Username'|translate}}</mat-label>
                        <input matInput [(ngModel)]="emailServer['smtp_username']">
                    </mat-form-field>
                </div>

                <div class="inline w50">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'SMTP Password'|translate}}</mat-label>
                        <input type="password" matInput [(ngModel)]="emailServer['smtp_password']">
                    </mat-form-field>
                </div>
                       
                <hr />

                <div class="inline w100" style="padding-bottom: 24px;">
                    <span class="title" style="font-weight: 500;">{{'For Activity communications'|translate}}</span>
                </div>

                <div class="inline w80" style="padding-right: 12px;">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'IMAP Host'|translate}}</mat-label>
                        <input matInput [(ngModel)]="emailServer['imap_host']">
                    </mat-form-field>
                </div>

                <div class="inline w20">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'IMAP Port'|translate}}</mat-label>
                        <input matInput [(ngModel)]="emailServer['imap_port']">
                    </mat-form-field>
                </div>

                <div class="inline w60">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'IMAP Security'|translate}}</mat-label>
                        <mat-select [(ngModel)]="emailServer['imap_security']">
                            <mat-option value="STARTTLS">{{'STARTTLS'|translate}}</mat-option>                            
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="inline w50" style="padding-right: 12px;">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'IMAP Username'|translate}}</mat-label>
                        <input matInput [(ngModel)]="emailServer['imap_username']">
                    </mat-form-field>
                </div>

                <div class="inline w50">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'IMAP Password'|translate}}</mat-label>
                        <input type="password" matInput [(ngModel)]="emailServer['imap_password']">
                    </mat-form-field>
                </div>

                <button class="accept right" (click)="sendTestEmail()" mat-button mat-dialog-close>{{'Send test email'|translate}}</button>
            </div>
        </div>
        

        <!------------------------------------------------------->
        <!--
        <div id="etalk" *ngIf="selectedTab=='etalk'" class="tab-container"></div>
            <div class="inline">
                <div class="field">
                    <textarea name="etalk_footer" [(ngModel)]="etalk_footer" style="min-height: 100px;"></textarea>                    
                </div>
                <div class="label">{{'eTalk Footer'|translate}}</div>
            </div>
        </div>
        -->

        <!------------------------------------------------------->
        
        <!--
        <div id="google" *ngIf="selectedTab=='google'" class="tab-container">
            <div class="inline">
                <div class="field">
                    <input type="text" name="api_GoogleMap" [(ngModel)]="api_GoogleMap" />
                </div>
                <div class="label">{{'GoogleMap API Key'|translate}}</div>
            </div>
        </div>
        -->          
        
    </div>

    <div class="modalActions">
        <button class="action left" type="button" mat-button mat-dialog-close>{{'Close'|translate}}</button>        
        <button class="accept right" type="submit" (click)="save()" mat-button mat-dialog-close>{{'Save And Close'|translate}}</button>        
    </div>
</div>