import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GlobalVariables } from '../../services/data.globals';
import { DataService } from '../../services/data.service';
import { DashboardComponent } from '../../dashboard/dashboard.component'

@Component({
  selector: 'app-widget-weather',
  templateUrl: './widget-weather.component.html',
  styleUrls: ['./widget-weather.component.css']
})
export class WidgetWeatherComponent implements OnInit {

  public weather = {};
  public rows = [];
  public refresh_percent;
  public parameters;
  public parameters_json;
  public mouseover = false;
  public hours = [];
  public hour = 0;
  public lat : any;
  public lng : any;

  @Input() id: string;  

  @Input() set refresh(refresh: string) {    
    if (parseInt(refresh) == 1000000) {      
      //if (!this.mouseover) this.prepareDataAndLoad();
    }        
    this.refresh_percent = refresh;    
  }

  constructor(
    private data: DataService,
    public Dashboard: DashboardComponent 
  ) {}

  remove(id) {
    this.Dashboard.removeWidget(id);
  }

  TwoDigits(value) {
    return ("00" + value.toString()).slice(-2);
  }

  Round(value) {
    return Math.round(value);
  }

  
  ngOnInit(): void {
  
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((values) => { 
        //alert('hey ya');
        ////console.log(values);
        this.lat = values?.coords?.latitude
        this.lng = values?.coords?.longitude;
          
        const d = new Date();
        this.hour = d.getHours();

        for (var i=this.hour; i<this.hour+12; i++) {
          this.hours.push(i);
        }

        const date = new Date().toJSON().slice(0, 10);

        var parameters = { date : date, hour : this.hour, lat : this.lat, lng : this.lng};

        this.data.GetWeather(parameters).subscribe(
          (res: Response) => {
            ////console.log(res['rows'][0]);
            if (res['rows'][0]==undefined) {
              //console.log('Retrieve weather info from metheo server');              
              this.data.RetrieveWeatherFromOpenMeteo(parameters).subscribe(
                (res: Response) => {                  
                  this.weather = res;
                  //console.log(this.weather);
                  parameters['json'] = JSON.stringify(res);                  
                  this.data.SetWeather(parameters).subscribe( (res: Response) => {} ); 
                }
              )                
            } else {
              this.weather = JSON.parse(res['rows'][0]['json']);
              //console.log(this.weather);
            }
          }
        );

      });
    }

    


    /*
    this.data.RetrieveWeatherFromOpenMeteo(null).subscribe(
      (res: Response) => {
        //console.log(res);
      }
    );
    */   

  }

}