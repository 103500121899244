import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* Common Functions */
import { CommonFunctions } from '../../../services/common.functions';

@Component({
  selector: 'app-assign-status',
  templateUrl: './assign-status.component.html',
  styleUrls: ['./assign-status.component.css']
})
export class AssignStatusComponent implements OnInit {

	constructor(
		public dialog: MatDialogRef<AssignStatusComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		public CommonFunction: CommonFunctions
	) { }

	ngOnInit(): void {
	}

	assignStatus(status, status_title) {
		this.data.status = status;
		this.data.status_title = status_title;
		delete this.data.statuses;		
		this.dialog.close(this.data);
	}


	close() {
		delete this.data.statuses;
		delete this.data.status;
		this.data.activity = null;
		this.dialog.close(this.data);
	}

}