import { AuthGuard } from './../auth.guard';
import { AuthService } from './../services/auth.service';
import { Component, OnInit } from '@angular/core';
/*import { User } from './User';*/
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';

/* Translate.Service */
import { TranslateService } from '@ngx-translate/core';

//import * as sha512 from 'js-sha512';
import { sha512 } from 'js-sha512';

interface User {
	name: string;
	password: string;
	p: string;
	token: string;
	domain: string;
}

interface JWT_user {
  logged_in: boolean;
  user_name: string;
  name: string;
  user_id: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

	public model: User = <User> {};
	private auth_info$: Observable<JWT_user>;
	public token: string | null = null;
	public lostpass: boolean = false;
	public sendtoken: boolean = false;
	public wait = false;
	public showPassword = false;
	private isLogged = false;
	private backgrounds = ['playfull.png'];
	//public translate: TranslateService;
	public current_background = "";

	constructor (
		private auth_svc: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		public translate: TranslateService
	) { 
		translate.addLangs(['en', 'si']);
		this.router.events.subscribe((ev) => {
			if (ev instanceof NavigationEnd) {
				this.auth_info$ = this.auth_svc.get_user_details();
				this.auth_info$.subscribe(data => {					
					if (data.logged_in) {
						console.info('You are already logged in. There is no need to log in again.')
						//this.router.navigate(['/']);
					}
				});
			}
		});
	}


	togglePasswordVisibility() {
		this.showPassword = !this.showPassword;
	}

	loginWithToken() {		
		console.log('Hey');
		console.log(this.model);				
		this.auth_svc.loginWithToken(this.model).subscribe(
			(data) => {				
				this.router.navigate(['/']);
			},
			(err) => {			
				console.log(err);	
				const element = document.querySelector('.login_wrapper');
				if (!element) return;			
				element.classList.add('shake');			
				setTimeout(() => {
					element.classList.remove('shake');
					this.model.name = "";
					this.model.p = "";
					this.model.password = "";
				}, 1000);
			}
		);		
	}

	login() {
		this.auth_svc.login(this.model).subscribe(
			(data) => {
				this.router.navigate(['/']);
			},
			(err) => {
				const element = document.querySelector('.login_wrapper');
				if (!element) return;			
				element.classList.add('shake');				
				setTimeout(() => {
					element.classList.remove('shake');
					this.model.name = "";
					this.model.p = "";
					this.model.password = "";
				}, 1000);
			}
		);
	}

	mailToken() {
		console.warn(this.model);
		this.sendtoken = true;
		this.lostpass = false;
		
		this.auth_svc.checkUser(this.model).subscribe(			
			(data) => {
				this.wait = false;
				if (data['action']) {
					if (data['action']=='wait') {
						this.wait = true;
					}
				}
			},
			(err) => {}
		);
	}

	changePassword() {}

	hashPwd() {		
		this.model.p = sha512(this.model.password);		
	}

	ngOnInit(): void {
		this.current_background = this.backgrounds[Math.floor(Math.random() * this.backgrounds.length)];
		this.model.domain = window.location.origin;		
		this.route.queryParamMap.subscribe(params => {

			if (params.get('lang')=='en') {
				this.translate.use('en');
			} else {
				this.translate.use('si');
			}

			this.token = params.get('token');
			this.model.token = this.token;
			this.lostpass = params.get('lostpass') === 'true';
		});
	}

}
