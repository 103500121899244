<div class="inner_wrapper"> 
    <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="left">
            <h1>{{'Available widgets'|translate}}</h1>
        </div>
        <div class="right">
            <fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
        </div>
    </div>

    <div class="body">
        <div class="widget first" (click)="add('widget-activities')">
            <fa-icon [icon]="['fad', 'list']"></fa-icon>    
            <span>{{'Activities'|translate}}<br />{{'Widget'|translate}}</span>
        </div>

        <!--
        <div class="widget" (click)="add('widget-activities-charts')">
            <fa-icon [icon]="['fad', 'chart-pie']"></fa-icon>    
            <span>{{'Activities Charts'|translate}}<br />{{'Widget'|translate}}</span>
        </div>
        -->
        
        <div class="widget" (click)="add('widget-last')">
            <fa-icon [icon]="['fad', 'list']"></fa-icon>
            <div class="widgetAddOnIcon">
                <fa-icon class="widgetSmall" [icon]="['fad', 'timer']"></fa-icon>
            </div>
            <span>{{'Last on'|translate}}<br />{{'Widget'|translate}}</span>
        </div>

        <div class="widget" (click)="add('widget-customers')">
            <fa-icon [icon]="['fad', 'users']"></fa-icon>    
            <span>{{'Customers'|translate}}<br />{{'Widget'|translate}}</span>
        </div>

        <div class="widget" (click)="add('widget-postit')">
            <fa-icon [icon]="['fad', 'sticky-note']"></fa-icon>    
            <span>{{'Sticky'|translate}}<br />{{'Note'|translate}}</span>
        </div>

        <div class="widget" (click)="add('widget-calendar')">
            <fa-icon [icon]="['fad', 'calendar-alt']"></fa-icon>    
            <span>{{'Calendar'|translate}}<br />{{'Widget'|translate}}</span>
        </div>

        <div class="widget" (click)="add('widget-next')">
            <fa-icon [icon]="['fad', 'timer']"></fa-icon>
            <span>{{'Next on'|translate}}<br />{{'Widget'|translate}}</span>
        </div>

        <!--
        <div class="widget last" (click)="add('widget-weather')">
            <fa-icon [icon]="['fad', 'cloud-bolt-sun']"></fa-icon>    
            <span>{{'Weather'|translate}}<br />{{'Widget'|translate}}</span>
        </div>
        -->
    </div>

    <div class="modalActions">
        <button class="left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
        <button class="left disabled" disabled mat-button mat-dialog-close>{{'Disabled'|translate}}</button>
        <button class="right warrning" mat-button mat-dialog-close>{{'Delete'|translate}}</button>
        <button class="right action" mat-button mat-dialog-close>{{'OK'|translate}}</button>
        <button class="right accept" mat-button mat-dialog-close>{{'Accept'|translate}}</button>
    </div>
</div>