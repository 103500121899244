/****************************************************** 
Colabrity.AddOn.Component: V1.0

Author: A. Motore
Date: 2024-09-17
To-Do: Theme implementation
Last activity: Cleaned code

Comment: Need to implement theme service/sollution
*******************************************************/

/* Angular */
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

/* Modal */
import { ModalComponent } from '../../modal/modal.component';

/* Services */
import { MembersettingsService } from '../../services/membersettings.service';
import { DataService } from '../../services/data.service';

/* Translation */
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})

export class UserSettingsComponent implements OnInit { 
  
	public memberSettingsGlobal = {};
	public memberSettingsLocal = {};
	public theme = "";
	public selectedTab = "aboutme";

	constructor(
		private data: DataService,
		private dialog: MatDialog,
		public MembersettingsService: MembersettingsService,
		public translate: TranslateService,
		private cdr: ChangeDetectorRef		
	) { 
		translate.addLangs(['en', 'si']);
	}
  
	/*
 	loadStyle(styleName: string) {
		const head = this.document.getElementsByTagName('head')[0];
	
		let themeLink = this.document.getElementById(
		  'client-theme'
		) as HTMLLinkElement;

		if (themeLink) {
		  themeLink.href = styleName;
		} else {
		  const style = this.document.createElement('link');
		  style.id = 'client-theme';
		  style.rel = 'stylesheet';
		  style.href = `${styleName}`;	
		  head.appendChild(style);
		}
	}
	*/
  	
	/**************************************/
	switchTab(value) {
		this.selectedTab = value;
	}   

	/**************************************/
	setMemberSettings(parameter, value) {
		this.MembersettingsService.setMemberSettings(parameter, value);		
	}

	reset() {	
		let content = this.translate.instant("Do you want parmenently delete all your user settings?<br /><b>You will also be logged out!</b>");
		let modalDialog = this.dialog.open(ModalComponent, {
			data: { content: content, yes: this.translate.instant("Delete") , no: null, cancel: this.translate.instant("Cancel") }
		});

		modalDialog.afterClosed().subscribe(result => {      
			if (result['yes']!=undefined) {
				this.data.resetMemberSettings(null).subscribe( (res: Response) => {} );
				localStorage.clear();
			}
		});  
	}

	/**************************************/
	ngOnInit(): void {    		
		/* Member.Service */
		this.MembersettingsService.refreshMemberSettings();
		this.MembersettingsService.memberSettingsGlobal.subscribe(message => {						
			
			console.log(message);
			/* angular material display fix - not good solution!!! */
			setTimeout(() => { this.memberSettingsGlobal = message;	}, 100);
			//this.memberSettingsGlobal = message;

			/* Set Language */
			if (this.memberSettingsGlobal['lang'] != undefined) this.translate.use(this.memberSettingsGlobal['lang']);

		});


	}

}
