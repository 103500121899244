<div class="inner_wrapper">
	<div class="inner_outer">		
		<div class="apps">
			<fa-icon class="big" [icon]="['fad', 'list']" (click)="toggleApps('activity')" [ngClass]="{'selected': apps['activity']}"></fa-icon>
			<fa-icon class="big" style="margin-left:16px" [icon]="['fad', 'users']" (click)="toggleApps('customers')" [ngClass]="{'selected': apps['customers']}"></fa-icon>
			<fa-icon class="big" style="margin-left:16px" [icon]="['fad', 'clock']" (click)="toggleApps('timetracking')" [ngClass]="{'selected': apps['timetracking']}"></fa-icon>
			<fa-icon class="big" style="margin-left:16px" [icon]="['fad', 'box-archive']" (click)="toggleApps('archive')" [ngClass]="{'selected': apps['archive']}"></fa-icon>
		</div>		
		<div class="itemsCollection">
			<fa-icon class="big" [icon]="['far', 'hashtag']"></fa-icon>
			<div class="itemCollection" [ngClass]="{'selected':numberOfItems==5}" (click)="setResults(5)">5</div>
			<div class="itemCollection" [ngClass]="{'selected':numberOfItems==10}" (click)="setResults(10)">10</div>
			<div class="itemCollection" [ngClass]="{'selected':numberOfItems==25}" (click)="setResults(25)">25</div>
		</div>
		<div class="actions"> 			
			<fa-icon class="big" [icon]="['fad', 'bookmark']" (click)="bookmark()"></fa-icon>
			<fa-icon class="big" style="margin-left:24px" (click)="close()" [icon]="['fas', 'times']"></fa-icon>
		</div>
	</div> 

	<div class="search_form">
		<mat-form-field appearance="outline">
			<mat-label>{{'Global Search'|translate}}</mat-label>
			<input class="globalSearch" id="globalSearch" type="text" matInput (keyup)="search()" [(ngModel)]="search_term">
			<!--<mat-hint>Hint</mat-hint>-->
		</mat-form-field>		
		<div class="bookmarks">
			<div *ngFor="let item of bookmarks; let i = index" (click)="useBookmark(i)">
				{{item.name}}
				<fa-icon style="margin-left:8px;padding-top:2px;" (click)="$event.stopPropagation();removeBookmark(item.name)" [icon]="['fas', 'times']"></fa-icon>
			</div>
		</div>		
	</div>


	<div class="search_results">
		<div *ngIf="searchResults.length == 0 && search_term!=''" class="noresults">{{'No results for'|translate}} "{{search_term}}".</div>
		<div *ngIf="searchResults.length == 0 && search_term==''" class="noresults">{{'Use Global Search field to search Colabrity Content.'|translate}}</div>
		<ngx-masonry>
			<ng-container *ngFor="let item of searchResults; let i = index">
				<div *ngIf="item.entity!=null" ngxMasonryItem class="masonry-item" (click)="navigate(item.type, item.component, null, item.actionData)">
					<div class="left" style="width: calc(100% - 32px)">
						<div class="badges">
							<fa-icon [icon]="[icon(item.icon)[0], icon(item.icon)[1]]"></fa-icon>							
							<div *ngIf="item.customer!=null && item.customer!=''" style="background:{{item.customerColor}}">
								{{item.customer}}
							</div>
							<div *ngIf="item.category!=null && item.category!=''" style="background:{{item.categoryColor}}">
								{{item.category}}
							</div>									
						</div>
					</div>
					<div class="right">
						<fa-icon [icon]="['fad', 'times']" (click)="removeItem(i)"></fa-icon>
					</div>										
					<div class="text">					
						<span class="title">{{item.title}}</span>
						<span class="subTitle">{{item.subTitle}}</span>
					</div>

					<div class="foundItems">
						{{item.resultNumber}} {{'of'|translate}} {{item.numberOfResults}}						
						<fa-icon class="small right" [icon]="[this.CommonFunctions.icon(item.weightIcon)[0], this.CommonFunctions.icon(item.weightIcon)[1]]"></fa-icon>
						<span class="right" style="padding-right:6px;">{{CommonFunctions.localizeAnyNumberToString(item.weight)}}% match</span>
					</div>
				</div>
			</ng-container>
		</ngx-masonry>			
	</div> 
</div>