<div class="inner_wrapper">
	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="left">
			<h1>{{'Filter'|translate}}</h1>
		</div>
		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
		</div>
	</div>
    
    <div class="body">

        <div class="inline w100">
            <mat-form-field appearance="outline">
                <mat-label>{{'Filter by category'|translate}}</mat-label>
                <mat-select name="category" id="category-select" (change)="updateType($event)" [(ngModel)]="filter.category">
                    <mat-option *ngFor="let category of categories" value="{{category}}">{{category}}</mat-option>                      
                </mat-select>
                <!--<mat-hint>Error</mat-hint>-->
              </mat-form-field>
        </div>

        <div class="inline w100">
            <mat-form-field appearance="outline">
                <mat-label>{{'Filter by type'|translate}}</mat-label>
                <mat-select name="category" id="category-select" (change)="updateType($event)" [(ngModel)]="filter.category">
                    <mat-option *ngFor="let type of types[filter.category]" value="{{type}}">{{type}}</mat-option>                      
                </mat-select>
                <!--<mat-hint>Error</mat-hint>-->
              </mat-form-field>
        </div>

        <div class="inline w100 divider"></div>

        <div class="inline w100 wPadding" style="padding-bottom: 0px; padding-top: 24px;">            
            <label class="switch">
                <input type="checkbox" [(ngModel)]="filter.enabled">
                <span class="slider round"></span>
            </label>  
            <div class="label label-checkbox" style="padding-bottom: 0px;">{{'Enable erp item'|translate}}</div>
        </div>
    </div>

    <div class="modalActions">			
        <button class="left" mat-button mat-dialog-close>{{'Close'|translate}}</button>        
        <button class="accept right" (click)="confirm()">{{'Confirm'|translate}}</button>
        <button class="right" (click)="clear()">{{'Clear'|translate}}</button>
    </div>    
</div>
