/* Angular */
import { Component, Input, OnInit } from '@angular/core';

/* Services */
import { MembersettingsService } from '../../services/membersettings.service';

@Component({
  selector: 'app-internalmenu',
  templateUrl: './internalmenu.component.html',
  styleUrl: './internalmenu.component.css'
})
export class InternalmenuComponent implements OnInit {

  @Input() entity: any;
  @Input() localInternalMenu: any;
  @Input() component: any;
  @Input() executeLocalFunction: Function;
  public open = false;
  public id = '';
  public localFuctionShortcuts = {};
  public localQuickShortcuts = {};
  public memberSettingsGlobal; 

  constructor(
		public MembersettingsService: MembersettingsService		
	) {} 

  generateRandomCode(length=4): string {
	const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
	return Array.from({ length: length }, () => letters[Math.floor(Math.random() * letters.length)]).join('');
  }	  

  icon(value) {
		if (value == null) return ['fad', 'circle-xmark'];
		let output = value.split(",");
		if (output.length != 2) {
			output = ['fad', 'circle-xmark'];
		}		
		return output;
	}

  toggleMenu() {
    this.open = !this.open;    
  }

  closeMenu() {
    this.open = !this.open;    
  }

  pinLocalFunctionIcon(component, entity) {
		let currentState = this.localFuctionShortcuts[entity] == false || this.localFuctionShortcuts[entity] == undefined ? true : false;
		this.localFuctionShortcuts[entity] = currentState;        
		this.MembersettingsService.setMemberSettings(component+'_shortCutsIcons', JSON.stringify(this.localFuctionShortcuts));
    //console.log(this.localFuctionShortcuts);
	}

  isIconSelected(entity) {
		let selected = false;
		for (let key in this.localFuctionShortcuts) {			
			if (key == entity && this.localFuctionShortcuts[key]) selected = true;			
		}
		return selected;
	} 
  
  isIconQuickSelected(entity) {
		let selected = false;
		for (let key in this.localQuickShortcuts) {			
			if (key == entity && this.localQuickShortcuts[key]) selected = true;			
		}
		return selected;
	}


  initializeTriggers() {
		document.querySelectorAll<HTMLElement>('.popupTrigger').forEach((trigger: HTMLElement) => {      
			trigger.addEventListener('click', function () {        
				const panelId = trigger.getAttribute('rel');				
				const panel = document.getElementById(panelId || '');

				if (!panel) {
					console.error(`Panel z ID "${panelId}" ne obstaja.`);
					return;
				}

				if ((panel as HTMLElement).style.display === 'block') {
					hideAllPanels();
				} else {
					showPanel(trigger);
				}
			});
		});

		document.addEventListener('click', function (event) {			
			const target = event.target as HTMLElement;
			const triggers = Array.from(document.querySelectorAll<HTMLElement>('.popupTrigger'));
			const panels = Array.from(document.querySelectorAll<HTMLElement>('.itemRight'));
			//const items = Array.from(document.querySelectorAll<HTMLElement>('.itemLeft'));
					
			if (![...triggers, ...panels].some(el => el.contains(target))) {
				hideAllPanels();				
			}
		});

		function hideAllPanels() {
			document.querySelectorAll<HTMLElement>('.popupPanel').forEach((panel: HTMLElement) => {
				panel.style.display = 'none';
			});
		}

		function showPanel(trigger: HTMLElement) {
			const panelId = trigger.getAttribute('rel');
			const panel = document.getElementById(panelId || '');

			if (!panel) {
				console.error(`Panel z ID "${panelId}" ne obstaja.`);
				return;
			}

			document.querySelectorAll<HTMLElement>('.popupPanel').forEach((p: HTMLElement) => {
				p.style.display = 'none';
			});

			const triggerRect = trigger.getBoundingClientRect();

			panel.style.visibility = 'hidden';
			panel.style.display = 'block';
			const panelWidth = panel.offsetWidth;
			panel.style.top = `${triggerRect.bottom-triggerRect.top + 20}px`;
			panel.style.right = `${triggerRect.right - triggerRect.left + 32 }px`;
			panel.style.visibility = 'visible';
		}
	}

  ngOnInit():void {
	this.id = this.generateRandomCode(6);
    this.MembersettingsService.memberSettingsGlobal.subscribe(message => {
      this.memberSettingsGlobal = message;    
      if (this.memberSettingsGlobal[this.component+'_shortCutsIcons']!=undefined) {  
        this.localFuctionShortcuts = JSON.parse(this.memberSettingsGlobal[this.component+'_shortCutsIcons']);
      }
      if (this.memberSettingsGlobal['shortCutsIcons']!=undefined) {  
        this.localQuickShortcuts = JSON.parse(this.memberSettingsGlobal['shortCutsIcons']);
      }
    });
    
    this.initializeTriggers();
  }

}
