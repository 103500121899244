import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/* Data Services */
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  constructor(
    private data: DataService
  ) { }

  //private sherableDataSource = new BehaviorSubject<Array<any>>([]);
  private sherableDataSource = new BehaviorSubject<{ [key: string]: any }>({});
  public sherableData = this.sherableDataSource.asObservable();

  updateAll() {
    this.updateActivityCategories();
  }

  updateActivityCategories(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.data.getCategories({ categories: null, items: 100, page: 0, order: 't1.description', orderby: 'asc', search: null}).subscribe(
        (res: Response) => {       
          let currentData = this.sherableDataSource.getValue();      
          currentData['activityCategories'] = (<any>res).rows;          
          this.sherableDataSource.next(currentData);
          resolve(true);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

}




  