import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../services/data.service';
import { GlobalVariables } from '../../services/data.globals';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectCustomerComponent } from '../../customers/select-customer/select-customer.component';
import { UserSelectComponent } from '../../users/user-select/user-select.component';
import { AssignDateTimeComponent } from '../../activities/activity/assign-date-time/assign-date-time.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

/* Common Functions */
import { CommonFunctions } from '../../services/common.functions';

@Component({
  selector: 'app-activities-filter',
  templateUrl: './activities-filter.component.html',
  styleUrls: ['./activities-filter.component.css']
})
export class ActivitiesFilterComponent implements OnInit {

	public customer;
	public customer_id;
	public assigned;
	public assigned_id;
	public user;
	public user_id;
	public author;
	public category_id;
	public statuses;
	public featured;
	public approved;
	public hidden;
	public approvedbyme;

	public start_from_date_raw;
	public start_to_date_raw;
	public start_from_date;
	public start_to_date;

	public end_from_date_raw;
	public end_to_date_raw;
	public end_from_date;
	public end_to_date;

	public created_from_date_raw;
	public created_to_date_raw;
	public created_from_date;
	public created_to_date;

	formData: UntypedFormGroup; 

  	constructor(
		private data: DataService,
		public dialog: MatDialog,
		public assignDateTimeDialog: MatDialog,
		public dialogRef: MatDialogRef<ActivitiesFilterComponent>,
		@Inject(MAT_DIALOG_DATA) public injected_data: any,
		public translate: TranslateService,
		public CommonFunctions: CommonFunctions
	) { 
		this.formData = this.createFormGroup();
		translate.addLangs(['en', 'si']);
	}

	createFormGroup() {
		return new UntypedFormGroup({
			//entity: new FormControl(),
			user: new UntypedFormControl(),
			user_id: new UntypedFormControl(),
			//activity: new FormControl(),
			//activity_id: new FormControl(),
			//comment: new FormControl(),
			customer: new UntypedFormControl(),
			customer_id: new UntypedFormControl(),
			assigned: new UntypedFormControl(),
			assigned_id: new UntypedFormControl(),
			start_to_date: new UntypedFormControl(),
			start_to_date_raw: new UntypedFormControl(),
			start_from_date: new UntypedFormControl(),
			start_from_date_raw: new UntypedFormControl(),
			end_to_date: new UntypedFormControl(),
			end_to_date_raw: new UntypedFormControl(),
			end_from_date: new UntypedFormControl(),
			end_from_date_raw: new UntypedFormControl(),
			created_to_date: new UntypedFormControl(),
			created_to_date_raw: new UntypedFormControl(),
			created_from_date: new UntypedFormControl(),
			created_from_date_raw: new UntypedFormControl(),
			featured: new UntypedFormControl(),
			approved: new UntypedFormControl(),
			approvedbyme: new UntypedFormControl(),
			author: new UntypedFormControl(),
			hidden: new UntypedFormControl(),
			//start: new FormControl(),
			//end: new FormControl(),
			//pause: new FormControl()
		});
	}

  	selectCustomer() {
		var selectCustomerDialog = this.dialog.open(SelectCustomerComponent, {});		
		selectCustomerDialog.afterClosed().subscribe(result => {			
			if (!isNaN(result.entity)) {  
				this.customer_id = result.entity;
				this.customer = result.customer;
			}
		});		
	}

	removeCustomer() {
		this.customer_id = null;
		this.customer = null;
	}

	selectUser() {
		var UserSelectDialog = this.dialog.open(UserSelectComponent, {});
		UserSelectDialog.afterClosed().subscribe(result => {
			if (!isNaN(result.entity)) {  
				this.user_id = result.entity;
				this.user = result.user;
			}
		});       
	}

	removeUser() {
		this.user_id = null;
		this.user = null;
	}

	localDateFormat(date) {
		var datetime = new Date(date);   
		var options = { hour12: false };   
		return datetime.toLocaleString("sl-SI", options).replace('. ', '.').replace('. ', '.');
	}

	assignDateTimeStart(entity, start, end, refresh, start_raw, end_raw) {
		var assignDateTimeDialog = this.dialog.open(AssignDateTimeComponent, {
			'width' : '400px',			
			data: { activity: entity, start_date : start, end_date : end, start_date_raw : start_raw, end_date_raw : end_raw, time : false }
		});    

		assignDateTimeDialog.afterClosed().subscribe(result => {
			var entity = result.activity;
			var start_date = (result.start_date).split(' ');
			var end_date = (result.end_date).split(' ');
			var date = new Date(end_date[0]);
			this.start_from_date_raw = start_date[0];
			this.start_from_date = (this.localDateFormat(start_date[0]+"T00:00:00")).split(' ')[0];
			this.start_to_date_raw = end_date[0];
			this.start_to_date = (this.localDateFormat(end_date[0]+"T00:00:00")).split(' ')[0];
		});			
	}

	removeDateTimeStart() {
		this.start_from_date_raw = null;
		this.start_from_date = null;
		this.start_to_date_raw = null;
		this.start_to_date = null;
	}


	assignDateTimeEnd(entity, start, end, refresh, start_raw, end_raw) {
		var assignDateTimeDialog = this.dialog.open(AssignDateTimeComponent, {
			'width' : '400px',			
			data: { activity: entity, start_date : start, end_date : end, start_date_raw : start_raw, end_date_raw : end_raw, time : false }
		});    

		assignDateTimeDialog.afterClosed().subscribe(result => {
			var entity = result.activity;
			var start_date = (result.start_date).split(' ');
			var end_date = (result.end_date).split(' ');
			var date = new Date(end_date[0]);
			this.end_from_date_raw = start_date[0];
			this.end_from_date = (this.localDateFormat(start_date[0]+"T00:00:00")).split(' ')[0];
			this.end_to_date_raw = end_date[0];
			this.end_to_date = (this.localDateFormat(end_date[0]+"T00:00:00")).split(' ')[0];
		});			
	}

	removeDateTimeEnd() {
		this.end_from_date_raw = null;
		this.end_from_date = null;
		this.end_to_date_raw = null;
		this.end_to_date = null;
	}


	assignDateTimeCreated(entity, start, end, refresh, start_raw, end_raw) {
		var assignDateTimeDialog = this.dialog.open(AssignDateTimeComponent, {
			'width' : '400px',			
			data: { activity: entity, start_date : start, end_date : end, start_date_raw : start_raw, end_date_raw : end_raw, time : false }
		});    

		assignDateTimeDialog.afterClosed().subscribe(result => {
			var entity = result.activity;
			var start_date = (result.start_date).split(' ');
			var end_date = (result.end_date).split(' ');
			var date = new Date(end_date[0]);
			this.created_from_date_raw = start_date[0];
			this.created_from_date = (this.localDateFormat(start_date[0]+"T00:00:00")).split(' ')[0];
			this.created_to_date_raw = end_date[0];
			this.created_to_date = (this.localDateFormat(end_date[0]+"T00:00:00")).split(' ')[0];
		});			
	}

	removeDateTimeCreated() {
		this.created_from_date_raw = null;
		this.created_from_date = null;
		this.created_to_date_raw = null;
		this.created_to_date = null;
	}

	togleStatusRestriction(statusId) {
		for(var i=0; i<this.statuses.length; i++) {			
			if (this.statuses[i].id == statusId) {
				if (this.statuses[i]['selected'] == true) {
					this.statuses[i]['selected'] = false;
				} else {
					this.statuses[i]['selected'] = true;
				}
			}
		}		
	}

	confirm() {
		var data = {};

		if (this.start_from_date_raw != null) {
			data['start_from_date_raw'] = this.start_from_date_raw;
			data['start_to_date_raw'] = this.start_to_date_raw;
			data['start_from_date'] = this.start_from_date;
			data['start_to_date'] = this.start_to_date;
		}

		if (this.end_from_date_raw != null) {
			data['end_from_date_raw'] = this.end_from_date_raw;
			data['end_to_date_raw'] = this.end_to_date_raw;
			data['end_from_date'] = this.end_from_date;
			data['end_to_date'] = this.end_to_date;
		}

		if (this.created_from_date_raw != null) {
			data['created_from_date_raw'] = this.created_from_date_raw;
			data['created_to_date_raw'] = this.created_to_date_raw;
			data['created_from_date'] = this.created_from_date;
			data['created_to_date'] = this.created_to_date;
		}
		
		if (this.user_id != null) {
			//data['user'] = this.user;
			//data['user_id'] = this.user_id;
			data['assigned'] = this.user;
			data['assigned_id'] = this.user_id;
		}

		if (this.customer_id != null) {
			data['customer'] = this.customer;
			data['customer_id'] = this.customer_id;
		}

		if (this.featured == true) {
			data['featured'] = 1;
		}

		if (this.author == true) {
			data['author'] = 1;
		}

		if (this.hidden == true) {
			data['hidden'] = 1;
		}
 		
		/* status restrictions */
		for(var i=0; i<this.statuses?.length; i++) {			
			if (this.statuses[i]['selected'] == true) {
				if (data['hide_status']==undefined) data['hide_status'] = "";
				data['hide_status'] += data['hide_status'] == "" ? "'" + this.statuses[i].id + "'" : ",'" + this.statuses[i].id + "'";
			} 	
		}

		if (this.approved == true) {
			data['approved'] = 1;
		}

		if (this.approvedbyme == true) {
			data['approvedbyme'] = 1;
		}

		data['category_id'] = this.category_id;

		this.dialogRef.close(data);   
	}

	close() {
		this.dialogRef.close(null);   
	}

	/*********************************/
	updateStatuses(category_id) {		
		
	    this.data.getActivityStatuses({ id : category_id }).subscribe(
	      (res: Response) => {
	        this.statuses = (<any>res).rows; 
	        	        
	        if (this.injected_data['hide_status']!=undefined) {
		        var hide_status = (this.injected_data['hide_status']).replaceAll("'", "").split(',');
		        for (var i=0; i<this.statuses.length; i++) {		        	
		        	for (var j=0; j<hide_status.length; j++) {		        		
						if (this.statuses[i].id == hide_status[j]) {
							this.statuses[i]['selected'] = true;
						}
			        }  
		        }
		
	    	}
	      }
	    );
	  }

	ngOnInit(): void {
		console.log('Activities Filter');
		var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    	this.translate.use(lang);    
		this.updateStatuses(this.injected_data['category_id']);		
		/* retrieve parameter settings */
		this.category_id = this.injected_data['category_id'];
		this.hidden = this.injected_data['hidden'];
		this.approvedbyme = this.injected_data['approvedbyme'];
		this.approved = this.injected_data['approved'];
		this.author = this.injected_data['author'];
		this.featured = this.injected_data['featured'];
		this.assigned = this.injected_data['assigned'];
		this.assigned_id = this.injected_data['assigned_id'];
		this.customer = this.injected_data['customer'];
		this.customer_id = this.injected_data['customer_id'];
		this.user = this.injected_data['assigned'];
		this.user_id = this.injected_data['assigned_id'];
		this.start_from_date_raw = this.injected_data['start_from_date_raw'];
		this.start_to_date_raw = this.injected_data['start_to_date_raw'];
		this.start_from_date = this.injected_data['start_from_date'];
		this.start_to_date = this.injected_data['start_to_date'];
		this.end_from_date_raw = this.injected_data['end_from_date_raw'];
		this.end_to_date_raw = this.injected_data['end_to_date_raw'];
		this.end_from_date = this.injected_data['end_from_date'];
		this.end_to_date = this.injected_data['end_to_date'];
		this.created_from_date_raw = this.injected_data['created_from_date_raw'];
		this.created_to_date_raw = this.injected_data['created_to_date_raw'];
		this.created_from_date = this.injected_data['created_from_date'];
		this.created_to_date = this.injected_data['created_to_date'];
	}

}
