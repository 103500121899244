<div class="inner_wrapper"> 
    <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="left">
            <h1>{{'Choose status'|translate}}</h1>
        </div>
        <div class="right">
            <fa-icon class="icon_close" (click)="close()" [icon]="['fad', 'times']"></fa-icon>
        </div>
    </div>

    <div class="body">
		<ng-container *ngFor="let status of data.statuses">
			<span class="activity_status" [ngStyle]="{'background':status.color, 'color':CommonFunction.textColorFromBG(status.color)}" (click)="assignStatus(status.id, status.title);">
				{{status.title}}
			</span>
    	</ng-container> 
	</div>

    <div class="modalActions">
        <button class="left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>        
    </div>
</div>