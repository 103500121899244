import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../../services/data.service';
import { GlobalVariables } from '../../../services/data.globals';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activity-functions',
  templateUrl: './activity-functions.component.html',
  styleUrls: ['./activity-functions.component.css']
})
export class ActivityFunctionsComponent implements OnInit {

	public entity = null;

	constructor(
	public dialog: MatDialogRef<ActivityFunctionsComponent>,
	@Inject(MAT_DIALOG_DATA) public data,
	private dataService: DataService,
	public translate: TranslateService
	) {
	  translate.addLangs(['en', 'si']); 
	}

	ngOnInit(): void {
		var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    this.translate.use(lang);
		this.entity = this.data.activity;
	}

	deleteActivity(entity) {
    	this.dataService.deleteActivity({ entity: this.entity }).subscribe((res: Response) => {});
    	this.dialog.close(true);
  	}

	unlinkActivity(entity) {
    	this.dataService.unlinkActivity({ entity: this.entity }).subscribe((res: Response) => {});
    	this.dialog.close(true);
  	}

	close() {		
		this.dialog.close(false);
	}

}
