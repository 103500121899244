import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { GlobalVariables } from '../../services/data.globals';
import { ActivatedRoute } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TopComponent } from '../../top/top.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.css']
})
export class ContactEditComponent implements OnInit {

	private sub : any;

	public entity;
	public customer_id;		
	public rows = {};
	public contact_types: any[] = [];
	
	formData: UntypedFormGroup; 

	/*******************************/

	constructor(
		private data: DataService,
		private route: ActivatedRoute,
		public translate: TranslateService
	) {
		this.formData = this.createFormGroup();
		translate.addLangs(['en', 'si']);
	}

	/*******************************/
	
	createFormGroup() {
		return new UntypedFormGroup({
			entity: new UntypedFormControl(),
			customer_id: new UntypedFormControl(),
			contact_type: new UntypedFormControl(),
			name: new UntypedFormControl(),
			surename: new UntypedFormControl(),
			email: new UntypedFormControl(),
			phone: new UntypedFormControl(),
			mobile: new UntypedFormControl(),
			comment: new UntypedFormControl()
		});
	}

	/*******************************/

	get(id) {    
		this.data.getContact({id:id}).subscribe(
			(res: Response) => { 				
				this.rows = (<any>res).rows[0]; 
				this.customer_id = this.rows['customer_id'];
				
				//this.contact_types = (<any>res).contact_types;
			}
		);		
	}
	
	save() {
		//const result: ContactRequest = Object.assign({}, this.formData.value);
	    //this.parameters = { data: Object.assign({}, this.formData.value) };	    
	    this.data.saveContact({data: Object.assign({}, this.formData.value)}).subscribe(
	      (res: Response) => {}
	    );	  
	 }

	delete() {
	    //const result: ContactRequest = Object.assign({}, this.formData.value);
	    //this.parameters = { data: Object.assign({}, this.formData.value) };
	    this.data.deleteContact({data: Object.assign({}, this.formData.value)}).subscribe(
	      (res: Response) => {}
	    );
	}

	/*******************************/

	ngOnInit(): void {
		var lang = GlobalVariables.memberSettings['lang'] == undefined ? 'en' : GlobalVariables.memberSettings['lang'];
    	this.translate.use(lang);

		this.data.getContactTypes({id:null}).subscribe(
			(res: Response) => {
				this.contact_types = (<any>res).rows;							
			}
		);

		this.sub = this.route.params.subscribe(params => {
			var id = params['id'];
			if (id != null) {				
				this.get(id);
			}
			if (this.entity != null) {				
				this.get(this.entity);	
			}
		}); 			
	}

}