<div class="inner_wrapper">

	<div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
		<div class="left">
		    <h1>
			    {{'Contact'|translate}}
		    </h1>
		</div>

		<div class="right">
			<fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
		</div>
		
	</div>

	<form [formGroup]="formData" (ngSubmit)="save()" novalidate>
		<div class="body">	
			<input type="hidden" formControlName="entity" name="entity" [(ngModel)]="rows['entity']" />
			<input type="hidden" formControlName="customer_id" name="customer_id" [(ngModel)]="customer_id" />

			<div class="inline w100 wPadding">               
				<mat-form-field appearance="outline">
				  <mat-label>{{'Name'|translate}}</mat-label>
				  <input type="text" matInput formControlName="name" name="name" [(ngModel)]="rows['name']">
				  <!--<mat-hint>Error</mat-hint>-->
				</mat-form-field>
			</div>

			<div class="inline w100 wPadding">               
				<mat-form-field appearance="outline">
				  <mat-label>{{'Surename'|translate}}</mat-label>
				  <input type="text" matInput formControlName="surename" name="surename" [(ngModel)]="rows['surename']">
				  <!--<mat-hint>Error</mat-hint>-->
				</mat-form-field>
			</div>

			<div class="inline w100 wPadding">
				<mat-form-field appearance="outline">
				  <mat-label>{{'Contact type'|translate}}</mat-label>
				  <mat-select formControlName="contact_type" name="contact_type" id="contact_type" [(ngModel)]="rows['contact_type']">
					<mat-option value="-">-</mat-option>
					<mat-option *ngFor="let type of contact_types" [value]="type.entity">{{type.description}}</mat-option>                      
				  </mat-select>
				<!--<mat-hint">Error</mat-hint>-->
				</mat-form-field>
			</div>

			<div class="inline w100 wPadding">               
				<mat-form-field appearance="outline">
				  <mat-label>{{'Email'|translate}}</mat-label>
				  <input type="text" matInput formControlName="email" name="email" [(ngModel)]="rows['email']">
				  <!--<mat-hint>Error</mat-hint>-->
				</mat-form-field>
			</div>

			<div class="inline w100 wPadding">               
				<mat-form-field appearance="outline">
				  <mat-label>{{'Phone'|translate}}</mat-label>
				  <input type="text" matInput formControlName="phone" name="phone" [(ngModel)]="rows['phone']">
				  <!--<mat-hint>Error</mat-hint>-->
				</mat-form-field>
			</div>

			<div class="inline w100 wPadding">               
				<mat-form-field appearance="outline">
				  <mat-label>{{'Mobile'|translate}}</mat-label>
				  <input type="text" matInput formControlName="mobile" name="mobile" [(ngModel)]="rows['mobile']">
				  <!--<mat-hint>Error</mat-hint>-->
				</mat-form-field>
			</div>

			<div class="inline w100 wPadding">
				<mat-form-field appearance="outline">
				  <mat-label>{{'Comment' | translate}}</mat-label>
				  <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" formControlName="comment" name="comment" [(ngModel)]="rows['comment']"></textarea>
				  <!--<mat-hint>Error</mat-hint>-->
				</mat-form-field>
			</div>
	    </div>

		<div class="modalActions" mat-dialog-actions>
			<button class="accept right" type="submit" mat-button mat-dialog-close>{{'Save'|translate}}</button>
			<button class="action left" mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
			<button *ngIf="rows['name']!=0 && rows['name']!=undefined && rows['name']!=null" class="warrning right" (click)="delete()" mat-button mat-dialog-close>{{'Delete'|translate}}</button>
		</div>
	</form>

</div>